import { Orange } from '@headspace/web-ui-components';
import * as React from 'react';
import uploadSvg from 'assets/upload-24.svg';
import {
  detailsFileUploadButtonStyle,
  detailsFileUploadButtonTextStyle,
} from './styles';
import { Icon } from '../../../../../../../shared-components/Icon';

interface FileUploadInstructionsProps {
  text: string;
  disabled: boolean;
  disabledMessage?: string;
}

export const FileUploadInstructions: React.FC<FileUploadInstructionsProps> = ({
  text,
  disabled = false,
  disabledMessage,
}) => (
  <div
    css={detailsFileUploadButtonStyle({ disabled })}
    key="fileUploaderInstructions"
  >
    {disabled ? (
      <div>{disabledMessage}</div>
    ) : (
      <>
        <Icon width={24} height={24} fill={Orange[200]} src={uploadSvg} />
        <div css={detailsFileUploadButtonTextStyle}>{text}</div>
      </>
    )}
  </div>
);
