import { take, put, select, call } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import { getPaymentMethod } from '../../../rest-calls';
import { PaymentActionTypes } from '../../../../../state/types/payment';
import { logSentryRequestError } from '../../../../../utils/sentryHelpers';
import {
  getHsUserId,
  getJWT,
} from '../../selectors/memberAuthenticationSelector/memberAuthenticationSelector';

const {
  FETCH_PAYMENT_METHOD,
  FETCH_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHOD_ERROR,
} = PaymentActionTypes;

export function* fetchPaymentMethodSaga(): SagaIterator {
  yield take('@activeSession/COMPLETE_PAYMENT');
  const userId = yield select(getHsUserId);
  const JWT = yield select(getJWT);

  if (JWT && userId) {
    yield put({ type: FETCH_PAYMENT_METHOD });
    try {
      const res = yield call(getPaymentMethod, JWT, userId);
      yield put({ type: FETCH_PAYMENT_METHOD_SUCCESS, payload: res.data });
    } catch (err: any) {
      yield put({ type: FETCH_PAYMENT_METHOD_ERROR, payload: err.response });
      logSentryRequestError(
        err,
        'getPaymentMethod api call in fetchPaymentMethodSaga',
      );
    }
  }
}
