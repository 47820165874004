import { css } from '@emotion/react';
import {
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  fontSizeM,
  fontSizeXS,
  rem,
  WarmGrey,
} from '@headspace/web-ui-components';

const labelStyle = css({
  color: WarmGrey[700],
  fontSize: fontSizeM,
  marginBottom: rem(0.188),
});

const sublabelStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeXS,
});

const containerStyle = css({
  fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.normal,
});

export interface RegularLabelSublabelProps {
  label: string;
  sublabel: string;
}

export function RegularLabelSublabel(
  props: RegularLabelSublabelProps,
): JSX.Element {
  const { label, sublabel } = props;
  return (
    <div css={containerStyle}>
      <div css={labelStyle}>{label}</div>
      <div css={sublabelStyle}>{sublabel}</div>
    </div>
  );
}
