import { css } from '@emotion/react';
import {
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../../constants/breakpoint';

export const headerStyle = css({
  [WebUIComponentsBreakPoint.Mobile]: {
    textAlign: 'center',
  },
});

export const paragraphStyle = css({
  color: WarmGrey[500],
  paddingBottom: rem(1.125),
  width: rem(20.563),
  [WebUIComponentsBreakPoint.Mobile]: {
    textAlign: 'center',
  },
});

export const twoColumStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: rem(90),
});

export const leftPaneStyle = css({
  flex: 1,
  padding: rem(2.25),
  [WebUIComponentsBreakPoint.Mobile]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  [WebUIComponentsBreakPoint.Tablet]: {
    display: 'none',
  },
});

export const rightPaneStyle = css({
  flex: 1,
  padding: rem(2.25),
  display: 'block',
  [WebUIComponentsBreakPoint.Mobile]: {
    display: 'none',
  },
});

export const illustrationStyle = css({
  display: 'none',
  [WebUIComponentsBreakPoint.Mobile]: {
    display: 'block',
    paddingBottom: SpacingInRemBySize.M,
  },
});

export const squareImageStyle = css({
  height: rem(26.813),
  width: rem(26.813),
  textAlign: 'center',

  [WebUIComponentsBreakPoint.Tablet]: {
    height: rem(13.75),
    width: rem(13.75),
    margin: '0 auto',
  },
});

export const nextButtonWrapperStyle = css({
  display: 'none',
  [WebUIComponentsBreakPoint.Mobile]: {
    display: 'inherit',
    justifyContent: 'center',
    width: '100%',
  },
});

export const nextButtonDesktopStyle = css({
  [WebUIComponentsBreakPoint.Desktop]: {
    minWidth: rem(12.75),
  },
});
