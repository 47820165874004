import { FC } from 'react';

import { ChildRestrictedInput } from '../../../../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import { useHealthHubs } from '../../../../../hooks/useHealthHubs';

interface HealthHubSelectProps {
  editMode?: boolean;
  onChange: (event: ExtendedEvent) => void;
  value: string | null;
  disabled?: boolean;
}

const displayHealthHub = (healthHub, healthHubs, isEditMode) => {
  const healthHubSearchValue = healthHub === '' ? null : healthHub;
  const hh = healthHubs?.find((hh) => hh.value === healthHubSearchValue);
  return isEditMode ? hh?.value : hh?.title;
};

export const HealthHubSelect: FC<HealthHubSelectProps> = ({
  editMode = true,
  onChange,
  value,
  disabled = false,
}) => {
  const healthHubs = useHealthHubs();

  return (
    <ChildRestrictedInput
      name="healthHub"
      type={editMode ? 'select' : 'review'}
      label="HEALTH HUB"
      value={displayHealthHub(value, healthHubs.data, editMode)}
      onChange={onChange}
      options={healthHubs.data}
      disabled={disabled}
      showAsTooltip={true}
    />
  );
};
