import { css } from '@emotion/react';
import { rem } from '@headspace/web-ui-components';

export const mobileStyleSectionContainer = (isOpen: boolean) =>
  css({
    alignItems: 'center',
    display: isOpen ? 'flex' : 'none',
    flexDirection: 'column',
    width: '100%',
    background: 'white',
    '@media (min-width: 800px)': {
      display: 'none',
    },
  });

export const joinMessageStyleContainer = css({
  width: rem(22.5),
});

export const buttonContainer = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: rem(1.25),
});
