export enum Family {
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
  TEXT = 'text',
  SLIDER = 'slider',
}

export interface Choice {
  id: string;
  text: string;
  position: number;
  score?: number;
  translations: Translations<'text'>;
}

type Translations<K extends keyof any> = Record<string, Record<K, string>>;

export interface Question {
  id: QuestionId;
  title: string;
  subtitle?: string;
  shorthand?: string;
  answers: {
    choices: Choice[];
  };
  position: number;
  family: Family;
  translations: Record<string, any>;
}

export interface Answer {
  id?: string;
  text?: string;
  choice?: string;
  position?: number;
  score?: number;
}

export interface PostAnswerResponse {
  data: {
    id: string;
    customVariables?: Record<string, unknown>;
    surveyId: string;
    surveyType: string;
    userId: string;
    questions: Question[];
    reflectionNote?: string;
  };
}

export interface QuestionResponse {
  id: string;
  title: string;
  answers: Answer[];
}

export interface SurveyAnswers {
  customVariables?: Record<string, unknown>;
  surveyId: string;
  surveyType: string;
  userId: string;
  questions: QuestionResponse[];
  reflectionNote?: string;
}

export interface GetSurveyQuestionResponse {
  data: [
    {
      id: string;
      customVariables?: Record<string, unknown>;
      surveyId: string;
      surveyType: string;
      userId: string;
      questions: Question[];
      reflectionNote?: string;
    },
  ];
}

export enum QuestionId {
  QUESTION_0_MOOD = 'question-0-mood',
  QUESTION_1_HELP = 'question-1-help',
  QUESTION_2_IMPORTANCE = 'question-2-importance',
  QUESTION_3_LIFE_IMPACT = 'question-3-life_impact',
  QUESTION_4_FEEL = 'question-4-feel',
}

export enum HelpAnswers {
  BE_MORE_PRESENT = 'Be more present / mindful',
  SLEEP_BETTER = 'Sleep better',
  REDUCE_STRESS = 'Reduce stress',
  MANAGE_ANXIETY = 'Manage daily anxiety',
  MANAGE_DEPRESSION = 'Manage depression',
  PROCESS_GRIEF = 'Process grief',
  FEEL_CALM = 'Feel calm and relax',
  SOMETHING_ELSE = 'Something else',
}
export enum MoodAnswers {
  TERRIBLE = 'Terrible',
  BAD = 'Bad',
  OKAY = 'Okay',
  GOOD = 'Good',
  AMAZING = 'Amazing',
}

export const highAnxietyScore = [
  HelpAnswers.MANAGE_DEPRESSION,
  HelpAnswers.PROCESS_GRIEF,
  HelpAnswers.MANAGE_ANXIETY,
];

export const lowMoodScore = [
  MoodAnswers.TERRIBLE,
  MoodAnswers.BAD,
  MoodAnswers.OKAY,
];
