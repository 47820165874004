import { css } from '@emotion/react';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rem } from '@headspace/web-ui-components';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { Input } from '../../../../../../../shared-components/Input';
import {
  updateImplicitState,
  handleChange as handleChangeThunk,
} from '../../../../../../../state/actions/organizationActions';
import { getEnrollmentUrl } from '../../../../../utils/enrollmentUrl';
import { HOST } from '../../../../../../../utils/constants';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { SetByParentLabel } from './SetByParentLabel';
import { CopyToClipboardWrapper } from './CopyToClipboardWrapper';

const host = HOST ?? '';

const container = (hasPadding = false) =>
  css({
    display: 'flex',
    justifyContent: 'start',
    paddingBottom: hasPadding ? rem(0) : rem(1),
  });

interface SlugInputProps {
  view: boolean;
  dataTestId?: string;
  urlSuffix?: string;
}

export const SlugInput: React.FC<SlugInputProps> = ({
  dataTestId,
  urlSuffix,
  view,
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const {
    slug,
    slugError,
    parentOrg,
    useSingleEnrollmentLink,
    childOrgs,
  } = useSelector(getOrgManagement);
  const hintRight = urlSuffix || 'member-enroll';

  const updatedSlug = parentOrg?.useSingleEnrollmentLink
    ? parentOrg?.enrollment?.slug
    : slug;

  const isChildOrg = !!parentOrg;
  const isParentOrg = parentOrg == null && childOrgs && childOrgs.length > 0;

  const enrollmentUrl = getEnrollmentUrl(updatedSlug, hintRight);

  const handleError = React.useCallback(
    (event) => {
      dispatch(updateImplicitState(event));
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    (event) => {
      dispatch(handleChangeThunk(event));
    },
    [dispatch],
  );

  return (
    <div data-testid={dataTestId}>
      {isParentOrg && !useSingleEnrollmentLink ? (
        <div css={container()}>
          <Input
            type="review"
            name="use-child-org"
            data-testid="use-child-org"
            value={translate('organizationSettings.enrollment.useChildUrl')}
            label={translate('organizationSettings.enrollment.slugLabel')}
          />
        </div>
      ) : (
        <div
          css={css({
            paddingBottom: useSingleEnrollmentLink ? rem(0.75) : rem(0),
          })}
        >
          <div css={[container(useSingleEnrollmentLink)]}>
            <Input
              label={translate('organizationSettings.enrollment.slugLabel')}
              type={view ? 'review' : 'url'}
              onChange={handleChange}
              onBlur={handleError}
              hasError={slugError.error}
              errorMessage={slugError.message}
              name="slug"
              value={view ? enrollmentUrl : updatedSlug.toLocaleLowerCase()}
              hintLeft={view ? '' : `${host}/`}
              hintRight={view ? '' : hintRight}
              data-testid="slug"
              disabled={useSingleEnrollmentLink && isChildOrg}
            />

            <CopyToClipboardWrapper view={view} enrollmentUrl={enrollmentUrl} />
          </div>

          <SetByParentLabel display={useSingleEnrollmentLink && isChildOrg} />
        </div>
      )}
    </div>
  );
};
