import axios, { AxiosPromise } from 'axios';
import {
  API_HOST,
  createHeaders,
  responseType,
} from '../../../utils/constants';
import { EnrollmentVerification } from '../constants/enrollmentVerification';

interface SuccessNoContent {}

interface EnrollByWorkId {
  enrollmentType: EnrollmentVerification.ID;
  workId: string;
  lastName: string;
  hsUserAccountEmail: string | null;
  preferredCountry?: string;
  preferredDob?: string;
}

export interface EnrollByWorkEmail {
  enrollmentType: EnrollmentVerification.EMAIL;
  verificationToken: string;
  hsUserAccountEmail: string | null;
  preferredCountry?: string;
  preferredDob?: string;
}

interface EnrollByPii {
  enrollmentType: EnrollmentVerification.PII;
  firstName: string;
  lastName: string;
  preferredDob: string;
  preferredCountry: string;
  zipCode?: string;
  hsUserAccountEmail: string | null;
}

export const postEnrollMember = (
  slug: string,
  JWT: string,
  requestData: EnrollByWorkId | EnrollByWorkEmail | EnrollByPii,
) =>
  axios.post<SuccessNoContent>(
    `${API_HOST}/b2b/v3/orgs/${slug}/enrollments`,
    requestData,
    {
      headers: createHeaders(JWT),
      responseType: responseType.JSON,
    },
  );
