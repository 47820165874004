import { useQuery } from '@tanstack/react-query';
import { getOrganizations } from '../../../rest';

export const getOrganizationsKey = 'getOrganizations';

export const useGetOrganizations = (orgName: string | string[]) => {
  let query = '?';

  if (Array.isArray(orgName)) {
    query += orgName.map((x) => `name=${encodeURIComponent(x)}`).join('&');
  } else {
    query += `name=${encodeURIComponent(orgName)}`;
  }
  query += '&exactMatch=true';

  return useQuery([getOrganizationsKey], async () => getOrganizations(query), {
    enabled: orgName.length > 0,
  });
};
