import { FC } from 'react';
import PaymentModule from './PaymentModule';
import { FormContainer } from '../shared-components/FormContainer/FormContainer';
import PriceComponent from './PaymentModule/Price';

const PaymentPage: FC = () => (
  <div>
    <PriceComponent />
    <FormContainer termsMessage={true} memberPay={true}>
      <PaymentModule />
    </FormContainer>
  </div>
);

export default PaymentPage;
