import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import React from 'react';
import { getActiveOrg } from '../../../../../../state/selectors/organizationSelectors';
import { learnMoreMessageStyle } from '../styles/verificationPageStyleUmdWfm';

export const LearnMoreLinkUmd = () => {
  const activeOrg = useSelector(getActiveOrg);
  const learnMoreUrl = R.pathOr(
    null,
    ['enrollment', 'learnMoreUrl'],
    activeOrg,
  );
  const body = R.pathOr(null, ['enrollment', 'body'], activeOrg);

  return (
    <p css={learnMoreMessageStyle}>
      {learnMoreUrl ? (
        <FormattedMessage
          id="enrollment.landingPage.messageUnder18"
          values={{
            additionalCopy: body,
            link: (chunks) => (
              <a
                href={learnMoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="learn-more-link"
              >
                {chunks}
              </a>
            ),
          }}
        />
      ) : (
        body
      )}
    </p>
  );
};
