import { css } from '@emotion/react';
import {
  borderWidthXS,
  CoolGrey,
  cssWithMq,
  fontSize6XL,
  fontSizeM,
  fontSizeS,
  fontSizeXL,
  letterSpacingTight,
  lineHeightBody,
  Orange,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  columnFlex,
  flex,
  justifyContentCenter,
} from '../../../dashboard/styles/flex';
import { bold } from '../../../dashboard/styles/fonts';

export const containerStyle = cssWithMq(
  columnFlex,
  justifyContentCenter,
  alignItemsCenter,
  {
    color: WarmGrey[500],
    marginTop: [rem(3.5), undefined, undefined],
    maxWidth: ['100vw', undefined, undefined],
    minHeight: [rem(25), rem(31.25), rem(31.25)],
    textAlign: 'center',
    width: rem(37.188),
  },
);

export const headerTextStyle = cssWithMq({
  color: CoolGrey[400],
  fontSize: fontSize6XL,
  lineHeight: '98%',
  margin: '0',
  textAlign: ['left', 'center'],
});

export const subHeaderStyle = css(bold, {
  fontSize: fontSizeXL,
  letterSpacing: letterSpacingTight,
  margin: SpacingInRemBySize.S,
});

export const footerTextStyle = cssWithMq({
  '> p': {
    '> strong': {
      display: 'block',
      overflow: ['hidden', undefined, undefined],
      wordBreak: ['break-all', undefined, undefined],
    },
    marginBottom: 0,
    overflow: 'hidden',
  },
  color: WarmGrey[600],
  fontSize: fontSizeM,
  fontWeight: 100,
  maxWidth: [rem(28.125), undefined, undefined],
});

export const iconContainerStyle = cssWithMq(
  flex,
  justifyContentCenter,
  alignItemsCenter,
  {
    '> div': {
      padding: SpacingInRemBySize.XS,
    },
    flexDirection: ['column', 'row', 'row'],
    margin: `${rem(1.25)} 0 ${rem(0.875)}`,
  },
);

export const webAppLinkStyle = css(
  flex,
  justifyContentCenter,
  alignItemsCenter,
  {
    '> a': {
      color: Orange[300],
      marginLeft: rem(0.375),
      textDecoration: 'none',
    },
    color: WarmGrey[600],
    flexWrap: 'wrap',
    marginBottom: rem(1.25),
  },
);

export const visuallyHidden = css({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: rem(0.063),
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: rem(0.063),
});

export const cardStyle = cssWithMq({
  margin: 'auto',
  width: [rem(21.25), rem(32.813), rem(32.813)],
});

export const mfaRedirectCardStyle = {
  border: `${borderWidthXS} solid ${WarmGrey[200]}`,
  margin: `${rem(1.25)} auto`,
  padding: `${rem(1)} !important`,
  width: rem(16.25),
};

export const mfaCardContent = css({
  color: `${WarmGrey[600]}`,
  fontSize: fontSizeS,
  lineHeight: lineHeightBody,
  margin: 0,
  textAlign: 'center',
});
