import { Dispatch } from 'redux';
import { dashboardMessages } from '../../../i18n/dashboardMessages';
import { getOrganizationById } from '../../../rest';
import { resetError } from '../../../utils/messageDefaults';
import {
  checkEnrollmentTypesMatch,
  isValidOrgId,
  orgHasActiveContract,
} from '../../../utils/validations';
import { initialState } from '../../reducers/manageOrganizationReducer';
import { getLocale } from '../../selectors/getLocale';
import { OrganizationActionsTypes } from '../../types/organization';
import { Organization, RestrictBy } from '../../../types/organization';

export const handleParentOrgIdInput = (
  rawParentOrgId: string,
  restrictBy: RestrictBy,
  editingExistingOrg?: boolean,
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    let parentOrg: Organization | null = null;
    let parentOrgId: string | null = rawParentOrgId;
    let parentOrgIdError: ErrorMessage | null = resetError();
    let orgMappingKey: string | null = null;
    const locale = getLocale(getState());
    const translations = dashboardMessages[locale];

    const noOrgErrorMessage: ErrorMessage = {
      error: true,
      message: translations[`validationErrors.noOrg`],
      validated: true,
    };
    const invalidOrgIdErrorMessage: ErrorMessage = {
      error: true,
      message: translations[`validationErrors.invalidOrgId`],
      validated: true,
    };

    const inactiveOrgErrorMessage: ErrorMessage = {
      error: true,
      message: translations[`validationErrors.inactiveOrgContractError`],
      validated: true,
    };

    const nonMatchingEnrollmentTypesErrorMessage: ErrorMessage = {
      error: true,
      message:
        translations[`validationErrors.nonMatchingEnrollmentTypesErrorMessage`],
      validated: true,
    };

    const missingParentOrgErrorMessage: ErrorMessage = {
      error: true,
      message: translations[`validationErrors.noParentOrgSelectedError`],
      validated: true,
    };

    if (rawParentOrgId === '' || !isValidOrgId(rawParentOrgId)) {
      parentOrgId = null;
      parentOrg = null;
      if (editingExistingOrg) {
        parentOrgIdError = missingParentOrgErrorMessage;
      }
      if (rawParentOrgId !== '' && !isValidOrgId(rawParentOrgId)) {
        parentOrgId = rawParentOrgId;
        parentOrgIdError = invalidOrgIdErrorMessage;
      }

      const payload: Record<string, unknown> = {
        orgMappingValueError: resetError(),
        org_salesforce_id: null,
        org_type: initialState.org_type,
        parentOrg,
        parentOrgId,
        parentOrgIdError,
        workIdFieldPlaceholderText: initialState.workIdFieldPlaceholderText,
      };

      dispatch({
        payload,
        type: OrganizationActionsTypes.HANDLE_CHANGE,
      });
      return;
    }

    try {
      parentOrg = await getOrganizationById(parseInt(rawParentOrgId, 10));
      orgMappingKey = parentOrg.orgMappingKey;

      if (editingExistingOrg && orgHasActiveContract(parentOrg)) {
        parentOrgIdError = inactiveOrgErrorMessage;
      }
      if (
        editingExistingOrg &&
        (await checkEnrollmentTypesMatch(
          parentOrg.enrollment.restrictBy,
          String(restrictBy),
        ))
      ) {
        parentOrgIdError = nonMatchingEnrollmentTypesErrorMessage;
        parentOrg = null;
      }
    } catch (e) {
      parentOrg = null;
      parentOrgIdError = noOrgErrorMessage;
    }

    const payload: Record<string, unknown> = {
      orgMappingKey: orgMappingKey === null ? undefined : orgMappingKey,
      orgMappingValueError: resetError(),
      org_salesforce_id: parentOrg?.salesforceId ?? null,
      org_type: parentOrg?.type || initialState.org_type,
      parentOrg,
      parentOrgId,
      parentOrgIdError,
      workIdFieldPlaceholderText:
        parentOrg?.enrollment?.workIdFieldPlaceholderText ||
        initialState.workIdFieldPlaceholderText,
    };

    if (parentOrg) {
      payload.gingerId = initialState.gingerId;
      payload.restrictBy = parentOrg.enrollment.restrictBy;
      payload.org_salesforce_id = parentOrg.salesforceId;
    }

    dispatch({
      payload,
      type: OrganizationActionsTypes.HANDLE_CHANGE,
    });
  };
};
