import * as React from 'react';
// tslint:disable-next-line:no-duplicate-imports
import { useRef, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import tooltipCss, { targetCss, ARROW_SIZE } from './styles';
import { Portal } from '../Portal';

type offsetType = {
  left?: number;
  top?: number;
};

type coordinateType = {
  left: number;
  top: number;
  marginLeft: number;
};

export interface TooltipParams {
  position?: string;
  message: string | JSX.Element;
  offset?: offsetType | undefined;
  color?: string;
  background?: string;
}

interface TooltipProps extends TooltipParams {
  children: string | JSX.Element[] | JSX.Element;
}

const DEFAULT_COORDINATE = {
  isShown: false,
  left: 0,
  marginLeft: 0,
  top: 0,
};

const toInt = (num: number): number => Math.floor(num);
// @ts-ignore
const zIndex = css({ zIndex: '-1 !important' });

const Tooltip = ({
  offset, // additional offset, will be applied for tooltip positioning
  children, // element, hovering on which the tooltip component will be triggered
  message, // tooltip message
  position = 'top', // position regarding element which triggers tooltip ( takes one of to variants : bottom or top )
  color, // custom css to apply to target
  background, // custom background of template
}: TooltipProps) => {
  const [tooltipState, tooltipSetState] = useState({
    ...DEFAULT_COORDINATE,
  });
  const currentOffset = { ...DEFAULT_COORDINATE, ...offset };
  const targetRef = useRef<HTMLDivElement | null>(null);
  const templateRef = useRef<HTMLDivElement | null | null>(null);

  const getPosition = (tooltipState: coordinateType, offset: coordinateType) =>
    css({
      left: tooltipState.left + offset.left,
      marginLeft: -tooltipState.marginLeft,
      top: tooltipState.top + offset.top,
    });

  const style = tooltipCss(position, background);

  const tooltipHeight: React.MutableRefObject<HTMLDivElement | null> = useRef(
    null,
  );

  useEffect(() => {
    if (templateRef.current) tooltipHeight.current = templateRef.current;
  }, []);

  const show = () => {
    const target = targetRef.current;
    if (target && tooltipHeight.current) {
      const targetRect = target.getBoundingClientRect();
      const tooltipRect = tooltipHeight.current.getBoundingClientRect();
      const left = (targetRect.left + targetRect.right) / 2;
      const top =
        position === 'bottom'
          ? targetRect.bottom + ARROW_SIZE
          : targetRect.top - tooltipRect.height - ARROW_SIZE;

      tooltipSetState({
        isShown: true,
        left: toInt(left),
        marginLeft: toInt(tooltipRect.width / 2) - 1,
        top: toInt(top),
      });
    }
  };

  const hide = () => tooltipSetState({ ...tooltipState, isShown: false });
  const { isShown } = tooltipState;

  useEffect(() => {
    // Hide tooltip when onScroll
    // as the tooltip is fixed positioned, we need hide it on scroll
    isShown && window.addEventListener('scroll', hide);
    return () => window.removeEventListener('scroll', hide);
  }, [isShown]);

  return (
    <span
      onMouseEnter={show}
      onMouseLeave={hide}
      ref={targetRef}
      data-testid="tool-tip"
    >
      {children}
      <Portal>
        <>
          <div
            ref={templateRef}
            css={[
              getPosition(tooltipState, currentOffset),
              zIndex,
              style(false),
            ]}
          >
            <div css={targetCss(color)}>{message}</div>
          </div>
          {isShown && (
            <div
              ref={templateRef}
              css={[getPosition(tooltipState, currentOffset), style(isShown)]}
            >
              <div css={targetCss(color)}>{message}</div>
            </div>
          )}
        </>
      </Portal>
    </span>
  );
};

Tooltip.displayName = 'Tooltip';
export { Tooltip };
