import { AuthClient } from '@headspace/web-auth';

export const auth0ClientConfig = (host?: string) => {
  const ENV_HOST = host || window.location.host;
  if (ENV_HOST === 'b2b.staging.headspace.com') {
    return {
      authEnvironment: 'staging',
      clientID: 'vQMFkRFvqMw2u05Nm6tjdSfM8WylAqKL',
      databaseConnection: 'User-Password-Headspace',
      domain: 'auth.staging.headspace.com',
      audience: 'https://api.staging.headspace.com',
      redirectUri: 'https://b2b.staging.headspace.com/auth',
      returnTo: 'https://staging-www.headspace.com',
    };
  }

  if (ENV_HOST === 'b2b.integration.headspace.com') {
    return {
      authEnvironment: 'integration',
      clientID: 'e8irdMtEkl7NgzCQEVTajdaetghn8Ha3',
      databaseConnection: 'User-Password-Headspace',
      domain: 'auth.integration.headspace.com',
      audience: 'https://api.integration.headspace.com',
      redirectUri: 'https://b2b.integration.headspace.com/auth',
      returnTo: 'https://integration-www.headspace.com',
      reactUrl: 'https://integration-www.headspace.com',
    };
  }

  if (ENV_HOST === 'work.headspace.com') {
    return {
      authEnvironment: 'production',
      clientID: 'xkQY1I0RgfxPhCNtJZ70cd8oTzymDT1r',
      databaseConnection: 'User-Password-Headspace',
      domain: 'auth.headspace.com',
      audience: 'https://api.prod.headspace.com',
      redirectUri: 'https://work.headspace.com/auth',
      returnTo: 'https://www.headspace.com',
      reactUrl: 'https://www.headspace.com',
    };
  }

  return {
    authEnvironment: 'development',
    clientID: 'e8irdMtEkl7NgzCQEVTajdaetghn8Ha3',
    databaseConnection: 'User-Password-Headspace',
    domain: 'auth.integration.headspace.com',
    audience: 'https://api.integration.headspace.com',
    redirectUri: `https://web.dev.headspace.com:4200/auth`,
    returnTo: 'https://web.dev.headspace.com:4200/',
  };
};

export const auth0Client = new AuthClient(auth0ClientConfig());
