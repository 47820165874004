import * as R from 'ramda';
import { Dispatch } from 'redux';
import { ThunkMiddleware } from 'redux-thunk';

import {
  OrganizationActionsTypes,
  HandleEligibilityFileInputAction,
} from '../../types/organization';
import { GetState } from '../../types';
import { resetError, resetProcessing } from '../../../utils/messageDefaults';
import { uploadEligibilityFile } from '../../../rest';
import { ErrorResponse, parseError } from '../../../utils/parseError';
import { checkForEncryptedFile } from '../../utils/fileFormatters';
import { TranslateFn } from '../../../hooks/useTranslate';

const { HANDLE_ELIGIBILITY_FILE } = OrganizationActionsTypes;

export const handleEligibilityFileInput = (
  files: FileList,
  t: TranslateFn,
): ThunkMiddleware & HandleEligibilityFileInputAction => {
  // @ts-ignore
  return async (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: { orgId, eligibilityFiles, eligibilityFileError },
    } = getState();
    const eligibilityFile = files[0] || {};
    const eligibilityFileName = R.pathOr(
      'eligibility file name',
      [0, 'name'],
      files,
    );
    const dryRun = eligibilityFileName.includes('__test');
    const locale = 'organizationSettings.eligibility.';
    const formData = new FormData();
    const file = checkForEncryptedFile(eligibilityFile);
    formData.append('el_file', file, eligibilityFileName);
    formData.append('dryRun', JSON.stringify(dryRun));

    dispatch({
      type: HANDLE_ELIGIBILITY_FILE,
      payload: {
        eligibilityFileProcessingMessage: {
          processing: true,
          message: `${t(
            `${locale}uploadStatus.uploading`,
          )} ${eligibilityFileName}`,
        },
        eligibilityFileError: resetError(),
      },
    });
    try {
      const {
        data: { data },
      } = await uploadEligibilityFile({ orgId, formData });
      const nextEligibilityFiles = [
        {
          createdAt: new Date().toISOString(),
          filename: eligibilityFileName,
          status: 'UPLOADED',
        },
      ].concat(eligibilityFiles);
      const message =
        data.result === 'SUCCESS'
          ? `${eligibilityFileName} ${t(`${locale}uploadStatus.success`)}`
          : '';
      dispatch({
        type: HANDLE_ELIGIBILITY_FILE,
        payload: {
          eligibilityFileName,
          eligibilityFileProcessingMessage: { message, processing: false },
          eligibilityFileError: resetError(),
          eligibilityFiles: nextEligibilityFiles,
        },
      });
    } catch (error) {
      const { message } = parseError(error as ErrorResponse);

      dispatch({
        type: HANDLE_ELIGIBILITY_FILE,
        payload: {
          eligibilityFileProcessingMessage: resetProcessing(),
          eligibilityFileError: { error: true, message },
        },
      });
    }
  };
};
