import { css } from '@emotion/react';
import { rem, SpacingInRemBySize } from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../../constants/breakpoint';

export const twoColumStyle = css({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  [WebUIComponentsBreakPoint.Mobile]: {
    flexDirection: 'column',
  },
});

export const leftPaneStyle = css({
  flex: 1,
  padding: rem(2.25),
  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    minWidth: rem(25),
  },
});

export const rightPaneStyle = css({
  flex: 1,
  display: 'block',
  [WebUIComponentsBreakPoint.Mobile]: {
    padding: 0,
    display: 'flex',
  },
  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    minWidth: rem(46.875),
  },
});

export const choiceButtonStyle = css({
  padding: SpacingInRemBySize.S,
});
