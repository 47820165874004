import { useWebAnalyticsContext } from '@headspace/web-analytics';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectOrganization } from '../../../../state/actions/organizationActions';
import { getActiveOrg } from '../../../../state/selectors/organizationSelectors';
import { onboardingAppQrScanEvent } from '../../../../analytics/events/onboardingEvents';
import { useTrackableAppStoreLink } from '../../../../hooks/useAppStoreLinks';
import { Organization } from '../../../../types/organization';

/**
 * The UMD flow has a qrcode that redirects a user to the app store
 * to downloand the headspace app. Since there are two urls for the
 * andriod and iOS app stores, this component determines which URL
 * to redirect the user to when they scan the qrcode on their phone.
 *
 * If hsUserId is included in the URL, an event action is dispatched
 * before redirecting. If not present, GDPR cookie value !== 'accept'
 * when the URL was generated.
 * - !!customerId: wait for user to be logged into mParticle, to
 *   ensure the event is associated with the hsUserId
 * - !!orgIdState || !orgId: if orgID is present in trackable URL, wait
 *   for it to be in state to ensure it is attached to the event.
 *
 * The `else` timeout ensures redirect even if `customerId` or
 * `orgIdState` fails to update.
 */
export const RedirectToAppStore = () => {
  const dispatch = useDispatch();
  const { id: orgIdState } = useSelector(getActiveOrg) ?? {};
  const { customerId } = useWebAnalyticsContext();
  const { app, appStoreLink, hsUserId, orgId } = useTrackableAppStoreLink();

  const redirect = useCallback(() => {
    window.location.href = appStoreLink;
  }, [appStoreLink]);

  useEffect(() => {
    if (orgId) {
      dispatch(selectOrganization({ id: +orgId } as Organization));
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    if (!hsUserId) {
      redirect();
    } else if (customerId && (orgIdState || !orgId)) {
      dispatch(onboardingAppQrScanEvent(app));
      redirect();
    } else {
      setTimeout(redirect, 3000);
    }
  }, [app, customerId, dispatch, hsUserId, orgId, orgIdState, redirect]);

  return <div>Redirecting...</div>;
};
