import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Red } from '@headspace/web-ui-components';
import { Input } from '../../../shared-components/Input';
import {
  challengeDurationOptions,
  challengeGoalTypeOptions,
  challengeMemberStatusesOptions,
  challengeTypeOptions,
} from '../utils/challenge';
import { FormFields, getOptions } from '../utils/challenge/fieldsConfig';
import { Toast } from '../../../shared-components/Toast';
import { getError } from '../../../state/selectors/challengesSelectors';
import {
  createChallengeFlexContainer,
  createChallengeLeftContainer,
  createChallengeRightContainer,
  createChallengeWrapper,
} from './styles';
import { useTranslate } from '../../../hooks/useTranslate';
import { challengeReset } from '../../../state/actions/challengesActions';

interface ChallengeCreateProps {
  fields: FormFields;
  handleChange: (event: ExtendedEvent) => void;
  handleError: (event: React.FocusEvent<any>) => void;
}

export const ChallengeCreate: React.FC<ChallengeCreateProps> = ({
  handleChange,
  handleError,
  fields,
}) => {
  const createError = useSelector(getError);
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const handleCloseToast = () => {
    dispatch(challengeReset());
  };

  return (
    <div className="inputGroup" css={createChallengeWrapper}>
      <div>
        <Input
          name="challengeType"
          data-testid="challenge-details-challenge-type"
          type="select"
          label={translate(fields.challengeType.label)}
          placeholder={translate(fields.challengeType.placeholder)}
          value={fields.challengeType.value}
          onChange={handleChange}
          options={getOptions(challengeTypeOptions)}
        />
      </div>
      <div>
        <Input
          name="memberStatuses"
          data-testid="challenge-details-memberStatuses"
          type="select"
          label={translate(fields.memberStatuses.label)}
          placeholder={translate(fields.memberStatuses.placeholder)}
          value={fields.memberStatuses.value}
          onChange={handleChange}
          options={getOptions(challengeMemberStatusesOptions)}
        />
      </div>
      <div css={createChallengeFlexContainer}>
        <div css={createChallengeLeftContainer}>
          <Input
            type="date"
            data-testid="challenge-details-start-date"
            name="startDate"
            label={translate(fields.startDate.label)}
            placeholder={translate(fields.startDate.placeholder)}
            onChange={handleChange}
            onBlur={handleError}
            value={fields.startDate.value}
            hasError={fields.startDate.hasError}
            errorMessage={fields.startDate.errorMessage}
          />
        </div>
        <div css={createChallengeRightContainer}>
          <Input
            name="durationInWeeks"
            data-testid="challenge-details-duration-in-weeks"
            type="select"
            label={translate(fields.durationInWeeks.label)}
            placeholder={translate(fields.durationInWeeks.placeholder)}
            onChange={handleChange}
            value={fields.durationInWeeks.value}
            options={getOptions(challengeDurationOptions)}
          />
        </div>
      </div>
      <div css={createChallengeFlexContainer}>
        <div css={createChallengeLeftContainer}>
          <Input
            type="select"
            data-testid="challenge-details-goal-type"
            name="goalType"
            label={translate(fields.goalType.label)}
            placeholder={translate(fields.goalType.placeholder)}
            onChange={handleChange}
            value={fields.goalType.value}
            options={getOptions(challengeGoalTypeOptions)}
          />
        </div>
        <div css={createChallengeRightContainer}>
          <Input
            type="text"
            data-testid="challenge-details-goal-target"
            name="goalTarget"
            label={translate(fields.goalTarget.label)}
            placeholder={translate(fields.goalTarget.placeholder)}
            onChange={handleChange}
            onBlur={handleError}
            value={fields.goalTarget.value}
            hasError={fields.goalTarget.hasError}
            errorMessage={fields.goalTarget.errorMessage}
          />
          {createError && (
            <Toast
              text={createError}
              backgroundColor={Red[200]}
              callback={handleCloseToast}
            />
          )}
        </div>
      </div>
    </div>
  );
};
