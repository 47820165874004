import { css } from '@emotion/react';
import * as React from 'react';
import { SpacingInRemBySize } from '@headspace/web-ui-components';
import { useLocation } from 'react-router-dom';
import { format } from '../../../../utils/dates';
import { TabNavigation } from '../../../../shared-components/TabNavigation';
import { TitleM, LabelRegularS } from '../../../../shared-components/text';
import { getLinks } from './getLinks';
import { getLogoEnv } from '../../../../utils/getLogo';
import {
  colStyle,
  dateStyle,
  membersTitleStyle,
  membersTitleInfoStyle,
  membersTitleLogoStyle,
  membersTitleNavigationStyle,
  membersTitleInfoWrapperStyle,
  gridStyle,
} from './styles';
import { bold } from '../../styles/fonts';
import { useTranslate } from '../../../../hooks/useTranslate';

const nameStyle = css({
  marginBottom: SpacingInRemBySize['2XS'],
});

export interface OrganizationDetailHeaderProps {
  orgId?: number;
  hasChallenges: boolean;
  name?: string;
  logo?: string;
  startDate?: string;
  endDate?: string;
  membersSelected?: boolean;
  isPilot?: boolean;
  isCSM?: boolean;
}

export const OrganizationDetailHeader: React.FC<OrganizationDetailHeaderProps> = ({
  isCSM,
  hasChallenges,
  name = 'Org Name',
  startDate = '',
  endDate = '',
  logo,
  isPilot = false,
  orgId,
}) => {
  const { translate } = useTranslate();
  const { pathname } = useLocation();
  const links = getLinks({ orgId, hasChallenges, isCSM, pathname });
  const contractDate = `${format(startDate, 'MMM D, YYYY')}-${format(
    endDate,
    'MMM D, YYYY',
  )}`;
  const RenderLogo = () =>
    logo ? (
      <img
        css={membersTitleLogoStyle}
        src={getLogoEnv(logo)}
        alt="Headspace logo"
        data-testid="org-logo"
      />
    ) : null;
  return (
    <div css={membersTitleStyle}>
      <div css={gridStyle}>
        <div css={colStyle}>
          <div css={membersTitleInfoWrapperStyle}>
            <RenderLogo />
            <div css={membersTitleInfoStyle}>
              <TitleM css={nameStyle}>{name}</TitleM>
              <div css={[bold, dateStyle]}>
                {startDate && endDate
                  ? contractDate
                  : `${translate(
                      'dashboardHeader.organizationHeader.noContract',
                    )}`}
              </div>
              {isPilot ? (
                <LabelRegularS>
                  {translate('dashboardHeader.organizationHeader.pilot')}
                </LabelRegularS>
              ) : null}
            </div>
          </div>

          <div css={membersTitleNavigationStyle}>
            <TabNavigation links={links} />
          </div>
        </div>
      </div>
    </div>
  );
};
