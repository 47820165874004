import { FC, useCallback } from 'react';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import { useTranslate } from '../hooks/useTranslate';
import { Input, InputProps } from './Input';
import { InputGroup, InputGroupProps } from './Input/InputGroup';
import { handleInviteBasedDependentsChange } from '../state/actions/organizationActions/familyMembers/handleInviteBasedDependentsChange';
import { handleElfBasedDependentsChange } from '../state/actions/organizationActions/familyMembers/handleElfBasedDependentsChange';

export interface DependentsInputGroupProps extends InputGroupProps {
  inviteBasedDependents: InputProps['value'];
  eligibilityFileBasedDependents: InputProps['value'];
  isViewMode?: boolean;
  isDisabled?: boolean;
  contract: 'current' | 'expired' | 'future';
  showTooltip?: boolean;
}

export const DependentsInputGroup: FC<DependentsInputGroupProps> = ({
  inviteBasedDependents,
  eligibilityFileBasedDependents,
  isViewMode = false,
  isDisabled = false,
  contract,
  showTooltip = true,
  ...props
}) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const handleCurrentInviteBasedDependentsChange = useCallback(
    (event: ExtendedEvent) =>
      dispatch(handleInviteBasedDependentsChange(event, contract)),
    [dispatch],
  );

  const handleCurrentElfBasedDependentsChange = useCallback(
    (event: ExtendedEvent) =>
      dispatch(handleElfBasedDependentsChange(event, contract)),
    [dispatch],
  );

  return (
    <InputGroup
      inputGroupCss={css({
        alignItems: 'left',
        flexDirection: 'column',
      })}
      {...props}
      label={translate('organizationSettings.contract.dependentsGroupLabel')}
      tooltip={
        showTooltip
          ? {
              message: translate(
                'organizationSettings.contract.dependentsGroupTooltip',
              ),
            }
          : undefined
      }
    >
      {isViewMode ? (
        <>
          {inviteBasedDependents && (
            <Input
              type="review"
              dataTestId="invite_based_dependents_checkbox"
              name={`${contract}_contract_invite_based_dependents`}
              value={translate(
                'organizationSettings.contract.inviteBaseDependentsLabel',
              )}
              noLabel={true}
              label={translate(
                'organizationSettings.contract.inviteBaseDependentsLabel',
              )}
            />
          )}
          {eligibilityFileBasedDependents && (
            <Input
              type="review"
              dataTestId="eligibility_file_based_dependents_checkbox"
              name={`${contract}_contract_eligibility_file_based_dependents`}
              value={translate(
                'organizationSettings.contract.elfDependentsLabel',
              )}
              noLabel={true}
              label={translate(
                'organizationSettings.contract.elfDependentsLabel',
              )}
            />
          )}
          {!inviteBasedDependents && !eligibilityFileBasedDependents && (
            <Input
              type="review"
              dataTestId="no_dependents_checkbox"
              name="no_dependents_checkbox"
              value={translate('organizationSettings.contract.noDependents')}
              noLabel={true}
            />
          )}
        </>
      ) : (
        <>
          <Input
            type="checkbox"
            dataTestId="invite_based_dependents_checkbox"
            name={`${contract}_contract_invite_based_dependents`}
            value={!!inviteBasedDependents}
            noLabel={true}
            label={translate(
              'organizationSettings.contract.inviteBaseDependentsLabel',
            )}
            disabled={isDisabled}
            onChange={handleCurrentInviteBasedDependentsChange}
          />
          <Input
            type="checkbox"
            dataTestId="eligibility_file_based_dependents_checkbox"
            name={`${contract}_contract_eligibility_file_based_dependents`}
            value={!!eligibilityFileBasedDependents}
            noLabel={true}
            label={translate(
              'organizationSettings.contract.elfDependentsLabel',
            )}
            disabled={isDisabled}
            onChange={handleCurrentElfBasedDependentsChange}
          />
        </>
      )}
    </InputGroup>
  );
};
