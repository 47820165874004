import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';
import { GetState } from '../../types';
import {
  DeleteDomainAction,
  OrganizationActionsTypes,
} from '../../types/organization';

const { DELETE_DOMAIN } = OrganizationActionsTypes;

export const deleteDomain = (
  event: any,
): ThunkMiddleware & DeleteDomainAction => {
  // @ts-ignore
  return (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: { restrictedDomains },
    } = getState();
    const {
      target: { id },
    } = event;
    const index = parseInt(id, 10);
    const newDomains = restrictedDomains
      .slice(0, index)
      .concat(restrictedDomains.slice(index + 1));
    dispatch({
      type: DELETE_DOMAIN,
      payload: {
        restrictedDomains: newDomains,
      },
    });
  };
};
