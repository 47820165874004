import {
  authComponentsFactory,
  AuthConnections,
  FormType,
} from '@headspace/web-auth';
import { memo, useState, useCallback, ReactElement, useContext } from 'react';
import { connect } from 'react-redux';
import {
  authConfig,
  LOGIN_THIRD_PARTY_CONNECTIONS,
  SIGNUP_THIRD_PARTY_CONNECTIONS,
  VIRGIN_PULSE_CONNECTIONS,
} from '../../enrollment-config/authConfig';
import { WEBSITE_HOST, SLUG } from '../../../../utils/constants';
import { getCountryCode } from '../../state/selectors/countryCodeSelector/countryCodeSelector';
import { HubLangContext } from '../../../../root-component/IntlProvider';

interface AuthComponentAppDependencies {
  countryCode: string;
}

function connectAuthComponentsWithAppDependencies(Component: any) {
  const mapStateToProps = (state: any) => {
    return {
      countryCode: getCountryCode(state),
    };
  };

  const actionCreators = {};

  const mergeProps = (stateProps: any, dispatchProps: any, ownProps: any) => {
    return {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
    };
  };

  return connect(mapStateToProps, actionCreators, mergeProps)(Component);
}

const {
  LinkIdentitiesForm,
  LoginAndSignupForm,
  RequiredUserProfileFieldsForm,
  SignupForm,
  SSOLoginCard,
} = authComponentsFactory(authConfig);

export const ConfiguredLinkIdentitiesForm = connectAuthComponentsWithAppDependencies(
  memo<AuthComponentAppDependencies>((props) => {
    return (
      <LinkIdentitiesForm
        {...props}
        isUnlinkingDisabled={true}
        thirdPartyConnections={VIRGIN_PULSE_CONNECTIONS}
      />
    );
  }),
);

export const ConfiguredRequiredUserProfileFieldsForm = RequiredUserProfileFieldsForm;

export const ConfiguredSignupForm = connectAuthComponentsWithAppDependencies(
  memo<AuthComponentAppDependencies>((props: any) => {
    return (
      <SignupForm
        {...props}
        pathToLoginPage={`${SLUG}/member-enroll/login`}
        pathToPrivacyPolicy={`${WEBSITE_HOST}/privacy-policy`}
        pathToTermsAndConditions={`${WEBSITE_HOST}/terms-and-conditions`}
        thirdPartyConnections={SIGNUP_THIRD_PARTY_CONNECTIONS}
      />
    );
  }),
);

interface ConfiguredLoginAndSignupFormProps
  extends AuthComponentAppDependencies {
  initialFormToShow: FormType;
  onFormChange?: (nextFormToShow: FormType) => void;
  termsComponentOverride?: ReactElement;
}

export const ConfiguredLoginAndSignupForm = connectAuthComponentsWithAppDependencies(
  memo<ConfiguredLoginAndSignupFormProps>(
    ({
      countryCode,
      initialFormToShow,
      onFormChange,
      termsComponentOverride,
      ...otherProps
    }: ConfiguredLoginAndSignupFormProps) => {
      const [formToShow, setFormToShow] = useState(initialFormToShow);

      const { hubLangCookie } = useContext(HubLangContext);

      const handleFormChange = useCallback(
        (nextFormToShow: FormType) => {
          setFormToShow(nextFormToShow);
          if (onFormChange) {
            onFormChange(nextFormToShow);
          }
        },
        [onFormChange],
      );

      return (
        <LoginAndSignupForm
          {...otherProps}
          // @ts-ignore
          language={hubLangCookie}
          novalidate={true}
          hideFormHeader={false}
          hideExistingAccountPrompt={false}
          countryCode={countryCode}
          formToShow={formToShow}
          loginThirdPartyConnections={LOGIN_THIRD_PARTY_CONNECTIONS}
          onChangeForm={handleFormChange}
          pathToForgotPasswordPage={`${WEBSITE_HOST}/login/forgot-password`}
          pathToLoginPage={`${SLUG}/member-enroll/login`}
          pathToPrivacyPolicy={`${WEBSITE_HOST}/privacy-policy`}
          pathToRegisterPage={`${WEBSITE_HOST}/register`}
          pathToTermsAndConditions={`${WEBSITE_HOST}/terms-and-conditions`}
          signupThirdPartyConnections={SIGNUP_THIRD_PARTY_CONNECTIONS}
          hideLegalAcknowledgements={!!termsComponentOverride}
          customLegalAcknowledgements={termsComponentOverride}
          shouldShowExperimentLegalAcknowledgements={true}
        />
      );
    },
  ),
);

interface ConfiguredSsoLoginProps extends AuthComponentAppDependencies {
  connection: AuthConnections;
}

export const ConfiguredSsoLogin = connectAuthComponentsWithAppDependencies(
  memo<ConfiguredSsoLoginProps>(({ connection }) => {
    return <SSOLoginCard connection={connection} />;
  }),
);
