import { Question } from '../../../../../models/OnboardingQuestions';
import { CLARIFICATION_QUESTIONS } from '../constants/navigation';

export const isClarificationQuestion = (
  nextQuestionNumber: number,
  questions: Question[],
) => {
  if (!nextQuestionNumber) {
    return false;
  }

  // Position index starts from 0, why is not the case for question number
  // Decrementing value by one to get the right position
  const nextQuestionPosition = nextQuestionNumber - 1;

  if (nextQuestionPosition >= questions.length) {
    return false;
  }

  const nextQuestion: Question = questions.find(
    ({ position }) => position === nextQuestionPosition,
  ) as Question;
  const { id: nextQuestionId } = nextQuestion;

  return CLARIFICATION_QUESTIONS.includes(nextQuestionId);
};
