import * as React from 'react';
import { STEP_STATUS } from '../../apps/dashboard/constants';
import statusActive from '../../assets/status-active.svg';
import statusComplete from '../../assets/status-complete.svg';
import statusIncomplete from '../../assets/status-incomplete.svg';
import { Icon } from '../Icon';

export interface StatusIndicatorProps {
  status: STEP_STATUS;
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => {
  let src = statusActive;
  if (status === STEP_STATUS.COMPLETE) {
    src = statusComplete;
  } else if (status === STEP_STATUS.INCOMPLETE) {
    src = statusIncomplete;
  }

  return <Icon width={16} height={16} src={src} />;
};
