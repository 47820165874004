import { FC } from 'react';
import smileV2 from 'assets/headspace-smile-v2.png';
import headspaceLogo from 'assets/headspace-logo.svg';
import { BundleCard } from './BundleCard';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface HeadspaceCardProps {
  onHandleClick: () => void;
}

export const HeadspaceCard: FC<HeadspaceCardProps> = ({ onHandleClick }) => {
  const { translate } = useTranslate();

  const bulletList = [
    {
      message: translate('gingerBundle.headspaceCard.bullet1.message'),
      topic: translate('gingerBundle.headspaceCard.bullet1.topic'),
    },
    {
      message: translate('gingerBundle.headspaceCard.bullet2.message'),
      topic: translate('gingerBundle.headspaceCard.bullet2.topic'),
    },
    {
      message: translate('gingerBundle.headspaceCard.bullet3.message'),
      topic: translate('gingerBundle.headspaceCard.bullet3.topic'),
    },
  ];

  return (
    <BundleCard
      buttonValue={translate('gingerBundle.headspaceCard.buttonValue')}
      logo={headspaceLogo}
      imageSrc={smileV2}
      title={translate('gingerBundle.headspaceCard.title')}
      bulletList={bulletList}
      onHandleClick={onHandleClick}
    />
  );
};
