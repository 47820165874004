import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Choice, QuestionId } from '../../../../../models/OnboardingQuestions';
import { RootState } from '../../../../../../../state/types';
import { choicesContainerStyle } from '../styles/choiceCardStyle';
import { CHOICE_ICONS } from './constants';
import { getQuestionsAnswer } from '../../../../../state/slice/onboarding/onboardingSelector';
import { getLocalizedAnswerText } from '../utils/localization';
import { OnboardingMultipleChoiceCheckbox } from './OnboardingMultipleChoiceCheckbox';
import { getLanguage } from '../../../../../state/selectors/languageSelector/languageSelector';

interface OnboardingMultipleChoiceCardProps {
  choices: Choice[];
  questionId: QuestionId;
  title: string;
  onSelectAnswer: (questionId: string, title: string, id: string) => void;
}

export const OnboardingMultipleChoiceCard: FC<OnboardingMultipleChoiceCardProps> = ({
  choices,
  title,
  questionId,
  onSelectAnswer,
}) => {
  const q1Answers = useSelector((state: RootState) =>
    getQuestionsAnswer(state, questionId),
  ) as string[];
  const language = useSelector(getLanguage);

  const handleCheckBoxSelect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { id } = event.currentTarget;
      onSelectAnswer(questionId, title, id);
    },
    [onSelectAnswer, questionId, title],
  );

  return (
    <div
      css={choicesContainerStyle}
      data-testid="onboarding-multiple-choice-card-container"
    >
      {choices.map((choice) => {
        const { text, id } = choice;

        return (
          <OnboardingMultipleChoiceCheckbox
            text={getLocalizedAnswerText(choice, language)}
            isSelected={q1Answers.includes(text)}
            key={id}
            id={id}
            handleCheckBoxSelect={handleCheckBoxSelect}
            choicesIcons={CHOICE_ICONS}
          />
        );
      })}
    </div>
  );
};
