import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';
import { GetState } from '../../types';
import {
  OrganizationActionsTypes,
  UpdateAdminAction,
} from '../../types/organization';
import { OrganizationAdministratorProps } from '../../../apps/dashboard/types';
import { resetError } from '../../../utils/messageDefaults';

const { UPDATE_ADMIN } = OrganizationActionsTypes;

export const updateAdmin = (
  event: any,
): ThunkMiddleware & UpdateAdminAction => {
  const { id: reference } = event.target;
  // @ts-ignore
  return (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: { org_admins },
    } = getState();
    const admin = org_admins.find(
      (admin: OrganizationAdministratorProps) => admin.email === reference,
    );
    dispatch({
      payload: {
        adminEditExisting: true,
        adminReviewView: false,
        admin_email: admin?.email,
        admin_emailError: resetError(true),
        admin_name: admin?.name,
        admin_nameError: resetError(true),
        admin_title: admin?.title,
        admin_titleError: resetError(true),
      },
      type: UPDATE_ADMIN,
    });
  };
};
