import { Spinner } from '@headspace/web-ui-components';
import { memo } from 'react';
import { containerCentering } from '../../../styles';

const SpinnerWrapper = () => {
  return (
    <div css={containerCentering}>
      <Spinner />
    </div>
  );
};

export default memo(SpinnerWrapper);
