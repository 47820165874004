import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

import { B2bContract } from '../types';
import { getActiveOrg } from '../../state/selectors/organizationSelectors';
import { ActiveOrgState } from '../../state/types/organization';

export function* b2bContractSaga(): SagaIterator<B2bContract> {
  const activeOrg: ActiveOrgState = yield select(getActiveOrg);

  return {
    org_id: activeOrg?.id || -1,
    org_name: activeOrg?.name || '',
    enrollment_flow: activeOrg?.primaryProduct || '',
  };
}
