import * as React from 'react';
import { useSelector } from 'react-redux';
import Finish from '../../FinishPage/FinishPage';
import { PageWrapper } from '../VirginPulse/PageWrapper/PageWrapper';
import { getHealthProvider } from '../../../state/selectors/healthProviderSelector/healthProviderSelector';

const HealthProviderSuccessPage: React.FC = () => {
  const healthProvider = useSelector(getHealthProvider);
  return (
    <PageWrapper healthProvider={healthProvider} showJoinMessage={false}>
      <Finish />
    </PageWrapper>
  );
};

export default HealthProviderSuccessPage;
