import * as R from 'ramda';
import { Dispatch } from 'redux';
import { ThunkMiddleware } from 'redux-thunk';

import {
  CURRENT,
  FUTURE,
  OPTIONAL_FIELDS,
  ORG_CREATION_STEPS,
  OVERLAP_DATE_INPUTS,
  PRIMARY_PRODUCT,
} from '../../../apps/dashboard/constants';
import { RestrictBy } from '../../../types/organization';
import { resetError } from '../../../utils/messageDefaults';
import {
  futureFusionContractError,
  futureUnifiedContractError,
  isAcceptableSlug,
  isAvailableOrgMappingValue as isAvailableOrgMappingValuePromise,
  isAvailableUniqueOrgValue,
  isEmail,
  isValidBodyLength,
  isNotBlank,
  isReasonableDomain,
  isReasonableSalesforceAccountId,
  isReasonableSalesforceOpportunityId,
  isReasonableUrl,
  isValidCampaignDisplayName,
  isValidCampaignLaunchDate,
  isValidContractSpan,
  isValidContractTerm,
  isValidCoverageTotal,
  isValidDate,
  isValidDateSequence,
  isValidDependentPerMemberCount,
  isValidEligibilityFile,
  isValidGingerId,
  isValidLogoResolution,
  isValidLogoSize,
  isValidLogoType,
  isValidOrgMappingValue,
  isValidPhoneNumber,
  isValidSeatCount,
  isValidStartDate,
  downgradedContractError,
} from '../../../utils/validations';
import { GetState } from '../../types';
import {
  OrganizationActionsTypes,
  ProcessErrorsAction,
} from '../../types/organization';

import {
  isContractFusionOrUnified,
  isContractProductTypeAllowed,
} from '../../../apps/dashboard/utils/manage-organization';
import { debouncePromise } from '../../../utils';
import { translateError } from '../../../utils/translateError';
import { getLocale } from '../../selectors/getLocale';

const { PROCESS_ERRORS } = OrganizationActionsTypes;
const isAvailableOrgMappingValue = debouncePromise<any, Error | undefined>(
  isAvailableOrgMappingValuePromise,
  150,
);

export const processErrors = (
  event: any,
): ThunkMiddleware & ProcessErrorsAction => {
  // @ts-ignore
  return async (dispatch: Dispatch, getState: GetState) => {
    const { target: field } = event;
    const state = getState();
    const {
      manageOrganization,
      user: { isCSM },
    } = state;
    const {
      cachedOrg: { contracts, expiredContracts } = {},
      orgId,
      currentStep,
      restrictBy,
    } = manageOrganization;
    const locale = getLocale(state);

    const getError = async (field: any) => {
      const { id, type, value, logoFile, eligibilityFile } = field;
      const idParticles = id.split('_');
      let error: Error | undefined;
      const term = idParticles.includes(CURRENT) ? CURRENT : FUTURE;
      const fieldDependentOnFields = R.pick(
        [`${term}_contract_family_members_per_employee`],
        manageOrganization,
      );

      const familyMemberSeatsPerEmployee = +fieldDependentOnFields[
        `${term}_contract_family_members_per_employee`
      ];

      if (fieldDependentOnFields && familyMemberSeatsPerEmployee === 0) {
        OPTIONAL_FIELDS.push(`${term}_contract_number_of_family_members_seats`);
      }

      const contractPrimaryProduct =
        term === CURRENT
          ? manageOrganization.current_contract_primary_product
          : manageOrganization.future_contract_primary_product;
      const isUnifiedOrBundledOrg =
        isContractFusionOrUnified(contractPrimaryProduct) ||
        manageOrganization.gingerBundlingEnabled;
      const hasParent = !!manageOrganization.parentOrgId;

      const isFutureUnifiedContractUpgradeEligible = [
        RestrictBy.EMAIL_ELIGIBILITY_FILE,
        RestrictBy.BUNDLED_EMAIL_ELIGIBILITY_FILE,
        RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
        RestrictBy.EMPLOYEE_ID,
      ].includes(restrictBy);

      /* The goal of this pattern is to introduce a base case at the earliest
      and cheapest moment. First error wins, harder to eliminate errors come
      last in the order. */
      if (logoFile) {
        error =
          (await isValidLogoType(logoFile)) ||
          (await isValidLogoSize(logoFile)) ||
          (await isValidLogoResolution(logoFile));
      }
      if (eligibilityFile) {
        error = await isValidEligibilityFile(eligibilityFile);
      }
      if (!error && id === 'workIdFieldPlaceholderText') {
        error = await isNotBlank(value);
      }
      if (!error && hasParent && id === 'orgMappingValue') {
        error =
          (await isValidOrgMappingValue(
            manageOrganization.orgMappingKey ?? '',
            value,
          )) ??
          (await isAvailableOrgMappingValue('orgMappingValue', value, [
            manageOrganization.orgId,
            manageOrganization.parentOrgId,
          ]));
      }
      if (!error && !OPTIONAL_FIELDS.includes(id)) {
        error = await isNotBlank(value);
      }
      if (!error && id === 'org_salesforce_id') {
        error = await isReasonableSalesforceAccountId(value);
      }
      if (!error && idParticles.includes('opportunity')) {
        error = await isReasonableSalesforceOpportunityId(value);
      }
      if (!error && type === 'email') {
        error = await isEmail(value);
      }
      if (!error && id === `${term}_contract_seats`) {
        error = await isValidSeatCount(value);
      }

      if (
        !error &&
        id === `${term}_contract_family_members_per_employee` &&
        (isUnifiedOrBundledOrg ||
          contractPrimaryProduct === PRIMARY_PRODUCT.FUSION)
      ) {
        error = await isValidDependentPerMemberCount(value);
      }

      const familyMemberSeatsFutureLogic =
        term === 'future' ? familyMemberSeatsPerEmployee > 0 : true;

      if (
        !error &&
        id === `${term}_contract_number_of_family_members_seats` &&
        familyMemberSeatsFutureLogic
      ) {
        error = await isValidSeatCount(value);
      }
      if (isCSM && !error && value && id === 'overflow_url') {
        error = await isReasonableUrl(value);
      }
      if (!error && idParticles.includes('date')) {
        const {
          current_contract_start_date: currentStart,
          current_contract_end_date: currentEnd,
          future_contract_start_date: futureStart,
          future_contract_id: futureId,
        } = manageOrganization;
        error = await isValidDate(value);
        if (
          !error &&
          Date.parse(manageOrganization[`${term}_contract_start_date`]) &&
          Date.parse(manageOrganization[`${term}_contract_end_date`])
        ) {
          error = await isValidDateSequence(
            manageOrganization[`${term}_contract_start_date`],
            manageOrganization[`${term}_contract_end_date`],
          );
        }
        if (
          !error &&
          currentStep !== ORG_CREATION_STEPS.CONTRACT &&
          /* currentStep is a property of org creation
          and since we are uninterested in the distinction
          between current and future in this flow, we use
          this prop's default state of 1 to dismiss this
          validation */
          ((term === FUTURE && futureStart) ||
            (term === CURRENT && currentStart && currentEnd))
        ) {
          error = await isValidContractTerm(
            manageOrganization[`${term}_contract_start_date`],
            manageOrganization[`${term}_contract_end_date`],
            term,
            event.overrideValidationField,
          );
        }
        if (
          !error &&
          currentStep === ORG_CREATION_STEPS.CONTRACT &&
          term === CURRENT &&
          currentStart
        ) {
          error = await isValidStartDate(
            manageOrganization.current_contract_start_date,
          );
        }
        if (
          !error &&
          currentStep === ORG_CREATION_STEPS.CONTRACT &&
          /* Now we'll validate with a slight variation—only verifying
          that the new contract is not already expired */
          currentStart &&
          currentEnd /* Create flow uses current term by default, so for
          this validation we'll just want to know the dates are selected */
        ) {
          error = await isValidContractTerm(
            manageOrganization.current_contract_start_date,
            manageOrganization.current_contract_end_date,
            'any',
          );
        }
        if (
          !error &&
          currentEnd &&
          futureStart &&
          OVERLAP_DATE_INPUTS.includes(id) &&
          !(term === CURRENT && !futureId) // If the future contract is not yet created, we don't need to validate the overlap
        ) {
          error = await isValidContractSpan(currentEnd, futureStart);
        }
      }
      if (isCSM && !error && id === 'org_name') {
        error = await isAvailableUniqueOrgValue('name', value, orgId);
      }

      if (isCSM && !error && id === 'slug') {
        error =
          (await isAcceptableSlug(value)) ||
          (await isAvailableUniqueOrgValue('slug', value, orgId));
      }
      if (!error && id === 'body') {
        error = await isValidBodyLength(value);
      }

      if (!error && value && id === 'learn_more_url_path') {
        error = await isReasonableUrl(value);
      }

      if (!error && value && id === 'gingerId') {
        error = await isValidGingerId(value, orgId);
      }

      if (!error && value && id === 'add_domain') {
        error = await isReasonableDomain(value);
      }

      if (
        !error &&
        !isFutureUnifiedContractUpgradeEligible &&
        id === 'future_contract_primary_product'
      ) {
        if (value === PRIMARY_PRODUCT.UNIFIED) {
          error = futureUnifiedContractError;
        } else if (value === PRIMARY_PRODUCT.FUSION) {
          error = futureFusionContractError;
        }
      }

      if (
        !error &&
        id === 'future_contract_primary_product' &&
        !isContractProductTypeAllowed(PRIMARY_PRODUCT[value], {
          contracts,
          expiredContracts,
        })
      ) {
        error = downgradedContractError;
      }

      if (!error && value && id === 'campaignDisplayName') {
        error = await isValidCampaignDisplayName(value);
      }

      if (!error && value && id === 'campaignLaunchDate') {
        const {
          current_contract_start_date: currentStart,
          current_contract_end_date: currentEnd,
        } = manageOrganization;
        const currDate = new Date();
        error = await isValidCampaignLaunchDate(
          value,
          currentStart,
          currentEnd,
          currDate,
        );
      }

      if (!error && value && id === 'phoneNumber') {
        error = await isValidPhoneNumber(value);
      }

      if (!error && value && id === 'coverageTotal') {
        error = await isValidCoverageTotal(value);
      }

      return error;
    };

    const error = await getError(field);
    dispatch({
      payload: {
        [`${field.id}Error`]: error
          ? {
              error: true,
              message: translateError(error, locale, field.id, field.value),
              validated: false,
            }
          : resetError(!!field.value),
      },
      type: PROCESS_ERRORS,
    });
  };
};
