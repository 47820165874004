import { useOrgListFiltering } from '../../../featureFlags/orgListFiltering';
import { createFilterDropdown } from '../../../shared-components/tables/FilterDropdown';

export const OrganizationFilterDropdown = ({
  filterProps,
  filterSelectedValues,
  setFilterSelectedValues,
}) => {
  const [withOrgListFiltering] = useOrgListFiltering();

  if (!withOrgListFiltering) return null;

  return (
    <>
      {createFilterDropdown({
        filterProps,
        filterSelectedValues,
        setFilterSelectedValues,
        testId: 'organization-table-filter-primary-product',
      })}
    </>
  );
};
