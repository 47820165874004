import { getCookie } from '@headspace/web-auth';
import Statsig from 'statsig-js';
import { CookieKeys } from './constants';
import { SupportedLanguageCode } from '../types/language';
import env from '../config/env';
import { statsigOptions } from './statsig';
import { FEATURE_FLAG } from '../constants/featureFlags';

export const checkLanguageSupport = () => {
  Statsig.initialize(env.STATSIG_SDK_KEY, null, statsigOptions);
  const isLanguageExpansionEnabled = Statsig.checkGate(
    FEATURE_FLAG.LANGUAGE_EXPANSION,
  );

  if (!isLanguageExpansionEnabled) {
    return true;
  }

  const hubLang = getCookie(CookieKeys.HUB_LANGUAGE);
  return hubLang === SupportedLanguageCode[hubLang];
};
