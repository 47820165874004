import axios from 'axios';
import { API_HOST } from '../../../utils/constants';

export interface GetVerifyDependentInviteTokenPayload {}

export interface GetVerifyDependentInviteTokenResponse {}

export const getVerifyDependentInviteToken = (token: string) =>
  axios
    .get<GetVerifyDependentInviteTokenResponse>(
      `${API_HOST}/community/v2/invites/token/${token}`,
    )
    .then((response) => response.data);
