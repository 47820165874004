import { useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rem } from '@headspace/web-ui-components';
import { FileUploader } from '../../../../../../shared-components/FileUploader';
import { Panel } from '../../../../../../shared-components/Panel';
import { Container } from '../../../../../../shared-components/container/Container';
import { BasicTable } from '../../../../../../shared-components/tables/BasicTable';
import { BasicTableCell } from '../../../../../../shared-components/tables/BasicTable/BasicTableCell';
import { BasicTableHeaderRow } from '../../../../../../shared-components/tables/BasicTable/BasicTableHeaderRow';
import {
  eligibilityPanelStyle,
  eligibilityTableStyles,
  eligibilityUploadWrapperStyle,
} from './styles';
import { getOrgManagement } from '../../../../../../state/selectors/organizationSelectors';
import { handleEligibilityFileInput as handleEligibilityFileInputAction } from '../../../../../../state/actions/organizationActions';
import { VIEW_PANEL_COUNT } from '../../../../constants';
import { FileUploadInstructions } from '../shared-components/FileUploadInstructions/FileUploadInstructions';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { EligibiltyFileRow } from './components/EligibilityFileRow';
import { EligibilityFile } from '../../../../../../types/organization';

export const EligibilityFilesPanel: FC = () => {
  const {
    eligibilityFileName,
    eligibilityFileError,
    eligibilityFileProcessingMessage,
    eligibilityFiles,
    eligibilityFileFormatType,
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
    cachedOrg,
    orgMappingKey,
    parentOrgId,
    childOrgs,
  } = useSelector(getOrgManagement);
  const currentRestrictBy = cachedOrg?.enrollment?.restrictBy;

  const disableForHierarchy =
    !orgMappingKey && (!!parentOrgId || childOrgs.length > 0);

  const uploadSuspended =
    viewPanels.indexOf(false) >= 0 ||
    eligibilityFileProcessingMessage.processing;

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const handleEligibilityFileInput = useCallback(
    (files) => dispatch(handleEligibilityFileInputAction(files, translate)),
    [dispatch, translate],
  );

  const requiredFileExt =
    eligibilityFileFormatType === 'FIXED_WIDTH' ? 'TXT' : 'CSV';

  return (
    <Container>
      <Panel
        title={translate(`organizationSettings.eligibility.title`)}
        css={eligibilityPanelStyle}
        dataTestId="eligibility-file-panel"
      >
        <div>
          <div className="inputGroup">
            <FileUploader
              css={eligibilityUploadWrapperStyle}
              fileError={eligibilityFileError}
              fileStatus={eligibilityFileProcessingMessage}
              view={uploadSuspended}
              loading={eligibilityFileProcessingMessage.processing}
              disabled={uploadSuspended || disableForHierarchy}
              requirements={
                disableForHierarchy
                  ? ''
                  : translate(
                      'organizationSettings.eligibility.fileUploadRequirements',
                      { elfExtension: requiredFileExt },
                    )
              }
              instructions={
                <FileUploadInstructions
                  text={translate(
                    'organizationSettings.eligibility.addEligibilityFileCta',
                  )}
                  disabled={disableForHierarchy}
                  disabledMessage={translate(
                    'organizationSettings.eligibility.disabled',
                  )}
                />
              }
              handleFileInput={handleEligibilityFileInput}
              fileName={eligibilityFileName}
              options={{
                height: rem(7),
              }}
            />
          </div>
          {!!eligibilityFiles.length && (
            <BasicTable dataTestId="eligibility-files">
              <BasicTableHeaderRow css={eligibilityTableStyles}>
                <BasicTableCell>
                  {translate(`organizationSettings.eligibility.fileName`)}
                </BasicTableCell>
                <BasicTableCell>
                  {translate(`organizationSettings.eligibility.status`)}
                </BasicTableCell>
                <BasicTableCell>
                  {translate(`organizationSettings.eligibility.dateCreated`)}
                </BasicTableCell>
                <BasicTableCell dataTestId="report-header">
                  {translate(`organizationSettings.eligibility.report`)}
                </BasicTableCell>
              </BasicTableHeaderRow>
              {eligibilityFiles
                .filter(
                  ({ restrictBy = '' }) => restrictBy === currentRestrictBy,
                )
                .map((eligibilityFile: EligibilityFile) => (
                  <EligibiltyFileRow
                    key={eligibilityFile.createdAt}
                    eligibilityFile={eligibilityFile}
                  />
                ))}
            </BasicTable>
          )}
        </div>
      </Panel>
    </Container>
  );
};
