import { css } from '@emotion/react';
import { SpacingInRemBySize } from '@headspace/web-ui-components';

export const wrapperStyle = css({
  margin: `${SpacingInRemBySize.M} 0`,
});
export const copyStyle = css({
  marginTop: SpacingInRemBySize.S,
  maxWidth: '50%',
});
