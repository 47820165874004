import { FC, ReactNode } from 'react';
import {
  Orange,
  ProgressBar,
  WarmGrey,
  titleMCss,
} from '@headspace/web-ui-components';
import {
  contentContainerStyle,
  progressBarContainer,
  titleSectionContainer,
  titleSectionStyles,
  footerSectionStyles,
  secondColumnStyle,
  subHeaderStyles,
} from './styles/pageWrapperStyle';
import { pageContainerStyle } from '../../styles/pageStyles';

interface PageWrapperProps {
  children?: ReactNode;
  progress?: number;
  header?: string;
  subHeader?: string;
  subHeaderElement?: ReactNode;
  hideTitleSection?: boolean;
  footer?: ReactNode;
  icon?: ReactNode;
}

export const PageWrapper: FC<PageWrapperProps> = ({
  children,
  progress,
  header,
  subHeader,
  subHeaderElement,
  hideTitleSection,
  footer,
  icon,
}) => {
  return (
    <div>
      <div css={pageContainerStyle()}>
        <div css={contentContainerStyle}>
          {hideTitleSection ? null : (
            <div css={titleSectionContainer}>
              {progress && (
                <div css={progressBarContainer}>
                  <ProgressBar
                    progress={progress}
                    trackColor={WarmGrey[600]}
                    progressColor={Orange[200]}
                  />
                </div>
              )}
              {icon}
              <div css={titleSectionStyles}>
                <h1 css={titleMCss}>{header}</h1>
                <p css={subHeaderStyles}>{subHeader}</p>
                {subHeaderElement}
              </div>
            </div>
          )}
          <div css={secondColumnStyle}>{children}</div>
        </div>
      </div>
      <div css={footerSectionStyles}>{footer}</div>
    </div>
  );
};
