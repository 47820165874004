import { call, put, select, take } from 'redux-saga/effects';
import { SSOEnrollmentActionTypes } from '../../actions/ssoEnrollmentActions/ssoEnrollmentActionsTypes';
import { getEnrollmentStatus } from '../../../rest-calls';
import { getJWT } from '../../selectors/memberAuthenticationSelector/memberAuthenticationSelector';

export function* getSSOEnrollmentStatusSaga() {
  yield take(SSOEnrollmentActionTypes.FETCH_SSO_ENROLLMENT_STATUS);
  const JWT = yield select(getJWT);
  const enrollmentStatus = yield call(getEnrollmentStatus, JWT);
  const {
    data: {
      data: { enrolled, healthHubId },
    },
  } = enrollmentStatus;

  yield put({
    type: SSOEnrollmentActionTypes.CHECK_ENROLLMENT_STATUS,
    payload: {
      enrolled,
      healthHubId,
    },
  });
}
