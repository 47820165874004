import { css } from '@emotion/react';
import { rem, SpacingInRemBySize } from '@headspace/web-ui-components';

export const createChallengePanel = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: `${SpacingInRemBySize.XL} ${SpacingInRemBySize['2XL']}`,
});

export const buttonContainer = css({
  alignItems: 'center',
  display: 'flex',
});

export const createChallengeTextContainer = css({
  maxWidth: rem(30),
});
