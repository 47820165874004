export enum MemberEnrollActionTypes {
  VERIFY_EMAIL_TOKEN_SUCCESS = 'VERIFY_EMAIL_TOKEN_SUCCESS',
  VERIFY_EMAIL_TOKEN_FAILED = 'VERIFY_EMAIL_TOKEN_FAILED',
  SET_EMAIL_TOKEN = 'SET_EMAIL_TOKEN',
  INITIALIZE_LOGIN_PAGE = 'INITIALIZE_LOGIN_PAGE',
  INITIALIZE_VERIFY_PAGE = 'INITIALIZE_VERIFY_PAGE',
  USER_IS_LOGGED_IN = 'USER_IS_LOGGED_IN',
  FETCH_USER_PROFILE = '@user/FETCH_USER_PROFILE',
  FAILED_USER_IS_LOG_IN = 'FAILED_USER_IS_LOG_IN',
  MEMBER_SUBSCRIPTION_DETAILS_IS_LOADING = 'MEMBER_SUBSCRIPTION_DETAILS_IS_LOADING',
  MEMBER_SUBSCRIPTION_DETAILS_SUCCESS = 'MEMBER_SUBSCRIPTION_DETAILS_SUCCESS',
  MEMBER_SUBSCRIPTION_DETAILS_ERROR = 'MEMBER_SUBSCRIPTION_DETAILS_ERROR',
  SET_COUNTRY_CODE = 'SET_COUNTRY_CODE',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_HEALTH_PROVIDER = 'SET_HEALTH_PROVIDER',
  SET_ENROLLMENT_VERIFICATION = 'SET_ENROLLMENT_VERIFICATION',
  CLEAR_ENROLLMENT_VERIFICATION = 'CLEAR_ENROLLMENT_VERIFICATION',
  SET_MEMBER_DOB_AND_COUNTRY_CODE = 'SET_MEMBER_DOB_AND_COUNTRY_CODE',
  SET_PREFERRED_SLUG = 'SET_PREFERRED_SLUG',
}
