import { FC } from 'react';
import { Blue, Spinner, White } from '@headspace/web-ui-components';
import { buttonContainerStyle } from '../../styles/verificationStyles';
import { TextButton } from '../../../../../../shared-components/buttons/TextButton';

interface SubmitButtonProps {
  value: string;
  disabled?: boolean;
  dataTestId: string;
  onClick: (event: MouseEvent) => void;
  isLoading?: boolean;
  suppressLetterSpacing?: boolean;
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  value,
  disabled = false,
  dataTestId,
  onClick,
  isLoading = false,
  suppressLetterSpacing = false,
}) => {
  return (
    <div css={buttonContainerStyle}>
      <TextButton
        textColor={White['000']}
        hoverColor={Blue[200]}
        backgroundColor={Blue[200]}
        value={value}
        pill={true}
        suppressLetterSpacing={suppressLetterSpacing}
        width="100%"
        disabled={disabled}
        dataTestId={dataTestId}
        onClick={onClick}
        type="submit"
        icon={isLoading ? <Spinner progressColor={White['000']} /> : undefined}
      />
    </div>
  );
};
