import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { getHealthProvider } from '../../state/selectors/healthProviderSelector/healthProviderSelector';
import { HealthProvider } from '../../constants/healthProvider';
import { EnrollmentRoutes } from '../../EnrollmentRoutes';
import Geolocation from '../Geolocation/Geolocation';
import JoinMessage from '../shared-components/JoinMessage/JoinMessage';
import { OutOfRoom } from '../OutOfRoomPage/OutOfRoom';
import { RegistrationClosed } from '../RegistrationClosedPage/RegistrationClosed';
import { containerStyle, joinContainerStyle, messageStyle } from './style';
import { WEBSITE_HOST } from '../../../../utils/constants';
import { matchPathname } from '../../../../utils/matchPathname';
import { Routes, getUnifiedEligibilityRoutes } from '../../constants/routes';
import { setEnrollmentVerificationConfirmation } from '../../state/actions/memberEnrollActions/memberEnrollActions';
import { EnrollmentVerification } from '../../constants/enrollmentVerification';
import { PRIMARY_PRODUCT } from '../../../dashboard/constants';
import { ActiveOrganization } from '../../../../state/types/organization';
import { getVerifiedByWorkId } from '../../state/selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';
import {
  getIsV3Enrollment,
  getPrimaryProduct,
} from '../../state/selectors/orgSectionSelector/orgSectionSelector';

interface ConditionalRenderProps {
  activeOrg: ActiveOrganization;
  isVerified?: boolean;
}

const ConditionalRender: React.FC<ConditionalRenderProps> = ({
  activeOrg,
  isVerified = false,
}) => {
  const {
    hasSeats,
    subStarted,
    subExpired,
    enrollment: { overflowUrl, slug },
  } = activeOrg;
  const geolocationSlugs = ['lacdmhresidents'];
  const pathsToExclude = ['finish', 'existing-paid-subscription'];
  const { search, pathname } = useLocation();
  const { verification } = queryString.parse(search);
  const urlParsedToken = decodeURIComponent(verification);
  const verifiedByWorkId = useSelector(getVerifiedByWorkId);
  const dispatch = useDispatch();
  const isV3Enrollment = useSelector(getIsV3Enrollment);
  const primaryProduct = useSelector(getPrimaryProduct);
  const healthProvider = useSelector(getHealthProvider);

  if (isV3Enrollment) {
    const {
      EXPIRED_CONTRACT,
      LOADING_PATH,
      NO_SEATS,
      VERIFY_IDENTITY_PATH,
      VERIFY_PATH,
    } = getUnifiedEligibilityRoutes(slug);

    if (subExpired) {
      return <Navigate to={EXPIRED_CONTRACT} replace={true} />;
    }

    if (subStarted && !hasSeats) {
      return <Navigate to={NO_SEATS} replace={true} />;
    }

    if (!verifiedByWorkId) {
      if (urlParsedToken === 'undefined') {
        return <Navigate to={`${VERIFY_PATH}${search}`} replace={true} />;
      }
      dispatch(
        setEnrollmentVerificationConfirmation({
          method: EnrollmentVerification.EMAIL,
          verificationToken: urlParsedToken,
        }),
      );
    }

    if (primaryProduct === PRIMARY_PRODUCT.FUSION) {
      if (healthProvider === HealthProvider.VPCARE) {
        return <Navigate to={Routes.VP_CARE_CONFIRMATION} replace={true} />;
      }
      return <Navigate to={VERIFY_IDENTITY_PATH} replace={true} />;
    }

    return <Navigate to={`${LOADING_PATH}${search}`} replace={true} />;
  }

  if (subStarted && !hasSeats) {
    return (
      <div css={messageStyle}>
        <OutOfRoom overflowUrl={overflowUrl} />
      </div>
    );
  }

  if (!subStarted) {
    return (
      <div css={messageStyle}>
        <RegistrationClosed />
      </div>
    );
  }

  if (
    geolocationSlugs.includes(slug) &&
    !isVerified &&
    urlParsedToken === 'undefined'
  ) {
    return (
      <Geolocation
        organizationSlug={slug}
        errorPageUrl={`${WEBSITE_HOST}/lacounty/browser-error`}
        locationErrorPageUrl={`${WEBSITE_HOST}/lacounty/location-error`}
      />
    );
  }

  return (
    <div css={containerStyle}>
      {matchPathname(pathname, pathsToExclude) ? null : (
        <div css={joinContainerStyle}>
          <JoinMessage activeOrg={activeOrg} />
        </div>
      )}
      <EnrollmentRoutes />
    </div>
  );
};

export default ConditionalRender;
