import {
  Modal,
  PrimaryButton,
  TertiaryButton,
} from '@headspace/web-ui-components';
import queryString from 'query-string';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useDispatch } from 'react-redux';
import { dobCountryScreenViewAction } from '../../../../../../analytics/events/enrollmentEvents';
import { CountryCode } from '../../../../../../constants/country';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { CountrySelect } from '../../../../../../shared-components/CountrySelect';
import { FormContainer } from '../../../shared-components/FormContainer/FormContainer';
import { FormHeader } from '../../VerificationPage/components/FormHeader';
import { SubmitButton } from '../../VerificationPage/components/SubmitButton';
import { Terms } from '../../VerificationPage/components/Terms';
import { FAQ_PAGE_LINK_DOB_AND_COUNTRY } from '../../constants';
import { useModal } from '../../hooks/useModal';
import { containerStyle, subTitleStyle } from '../../styles/verificationStyles';
import {
  countryInputContainerStyle,
  dobInputContainerStyle,
  modalBodyStyle,
  modalContainerStyle,
  modalFooterStyle,
} from '../styles';
import { DobInput } from './DobInput';

interface DobAndCountryFormProps {
  title: string;
  subtitle: string;
  handleConfirm(confirmation: { countryCode: CountryCode; dob: string });
}

export const DobAndCountryForm: FC<DobAndCountryFormProps> = ({
  title,
  subtitle,
  handleConfirm,
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { isOpen, closeModal, openModal } = useModal();
  const [countryCode, setCountryCode] = useState<CountryCode | null>(null);
  const [dob, setDob] = useState({ isValid: false, value: '' });
  const { search } = useLocation();
  const { dependent_token } = queryString.parse(search);

  useEffect(() => {
    dispatch(dobCountryScreenViewAction());
  }, [dispatch]);

  const handleChangeCountry = useCallback(
    (country) => setCountryCode(country),
    [],
  );

  const handleChangeDob = useCallback((result) => setDob(result), []);

  const handleSubmit = useCallback(() => {
    openModal();
  }, [openModal]);

  const onConfirm = useCallback(() => {
    closeModal();
    if (countryCode) {
      handleConfirm({ countryCode, dob: dob.value });
    }
  }, [handleConfirm, countryCode, dob, closeModal]);

  const submitDisabled = !dob.isValid || countryCode === null;
  return (
    <div>
      <FormContainer
        headerComponentOverride={<FormHeader title={title} />}
        termsComponentOverride={
          <Terms
            faqLinkOverride={FAQ_PAGE_LINK_DOB_AND_COUNTRY}
            showPrivacyAndTerms={!!dependent_token}
          />
        }
      >
        <div css={containerStyle}>
          <div css={subTitleStyle}>
            <p>{subtitle}</p>
          </div>
          <div css={dobInputContainerStyle}>
            <DobInput onChange={handleChangeDob} />
          </div>
          <div css={countryInputContainerStyle}>
            <CountrySelect onChange={handleChangeCountry} />
          </div>
          <SubmitButton
            value={translate('enrollment.verify.workId.button')}
            onClick={handleSubmit}
            dataTestId="submit-button"
            disabled={submitDisabled}
          />
        </div>
      </FormContainer>
      {isOpen ? (
        <Modal
          css={modalContainerStyle}
          body={
            <h2 css={modalBodyStyle}>
              {translate('enrollment.verifyIdentity.modalTitle', {
                dateOfBirth: dob.value,
              })}
            </h2>
          }
          footer={
            <div css={modalFooterStyle}>
              <PrimaryButton onClick={onConfirm} dataTestIdPrefix="yes">
                {translate('yes')}
              </PrimaryButton>
              <TertiaryButton onClick={closeModal} dataTestIdPrefix="no">
                {translate('no')}
              </TertiaryButton>
            </div>
          }
          onClose={closeModal}
        />
      ) : null}
    </div>
  );
};
