import axios from 'axios';
import { API_HOST, POST, createHeaders } from '../../../utils/constants';

export const postDependentEnroll = (token, JWT, requestData) =>
  axios({
    data: requestData,
    headers: createHeaders(JWT),
    method: POST,
    url: `${API_HOST}/community/v2/invites/token/${token}`,
  });
