import { memo } from 'react';
import { Spinner } from '@headspace/web-ui-components';
import { FormContainer } from '../../shared-components/FormContainer/FormContainer';

import { errorContainerStyle } from '../styles';

const LoadingBox = () => {
  return (
    <FormContainer termsMessage={true}>
      <div css={errorContainerStyle} data-testid="loading-box">
        <Spinner />
      </div>
    </FormContainer>
  );
};

export default memo(LoadingBox);
