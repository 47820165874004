import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ELF_STATUS,
  ELF_STATUS_POLLING_INTERVAL,
} from '../../../apps/dashboard/constants';
import { uploadEligibilityFile } from '../../../rest';
import { getEligibilityFileStatusById } from '../../../rest/eligibillityFile/eligibilityFile.api';
import { ErrorResponse, parseError } from '../../../utils/parseError';
import { openSettingsModal } from '../settingsModal';
import { RESTRICT_BY_MIGRATION_REDUCER_NAME } from './constants';
import { ElFileProcessingReport, ElFileReport } from './index';
import { preUpdateRestrictByChangeWithPolling } from './utils';

interface UploadELFileThunkProps {
  eligibilityFile: File;
  restrictBy: string;
  orgId: number;
  useChangeOfEnrollmentWithHierarchy: boolean;
}

const getUploadFileFormData = (eligibilityFile, restrictBy, dryRun = true) => {
  const formData = new FormData();

  formData.append('el_file', eligibilityFile);
  formData.append('restrictBy', restrictBy);
  formData.append('dryRun', JSON.stringify(dryRun));

  return formData;
};

const pollFileProcessingReport = async (orgId, elfId) =>
  new Promise<ElFileProcessingReport>((resolve, reject) => {
    const getELFStatus = async () => {
      try {
        const elfFileStatusResponse = await getEligibilityFileStatusById(
          orgId,
          elfId,
        );

        const { status } = elfFileStatusResponse.data;

        if (ELF_STATUS.PROCESSED === status) {
          resolve(elfFileStatusResponse.data);
          return;
        }
        if (
          ELF_STATUS.FAILED === status ||
          ELF_STATUS.VALIDATION_FAILED === status
        ) {
          reject(
            new Error(
              "There was an issue processing the uploaded file. An email has been sent to the organization's administrators with details.",
            ),
          );
          return;
        }

        setTimeout(() => getELFStatus(), ELF_STATUS_POLLING_INTERVAL);
      } catch (err) {
        reject(err);
      }
    };
    setTimeout(() => getELFStatus());
  });

export const uploadELFFile = createAsyncThunk(
  `${RESTRICT_BY_MIGRATION_REDUCER_NAME}/uploadFile`,
  async (
    {
      eligibilityFile,
      restrictBy,
      orgId,
      useChangeOfEnrollmentWithHierarchy,
    }: UploadELFileThunkProps,
    { dispatch },
  ): Promise<ElFileReport> => {
    try {
      const {
        data: {
          data: { id: elfId },
        },
      } = await uploadEligibilityFile({
        formData: getUploadFileFormData(eligibilityFile, restrictBy),
        orgId,
      });

      const fileProcessingReport = await pollFileProcessingReport(orgId, elfId);

      const preUpdateRestrictByChangeReport = await preUpdateRestrictByChangeWithPolling(
        orgId,
        restrictBy,
        true,
        {
          useChangeOfEnrollmentWithHierarchy,
        },
      );

      return {
        ...fileProcessingReport,
        ...preUpdateRestrictByChangeReport,
      };
    } catch (error) {
      const { message, title = '' } = parseError(error as ErrorResponse);
      dispatch(openSettingsModal({ message, title }));

      throw error;
    }
  },
);
