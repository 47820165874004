import { css } from '@emotion/react';

import {
  borderRadiusS,
  borderWidthXS,
  cssWithMq,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

export const cardStyle = cssWithMq({
  border: `${borderWidthXS} solid ${WarmGrey[300]}`,
  borderRadius: borderRadiusS,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: ['100%', rem(53.125), rem(53.125)],
  margin: [rem(0.313), rem(1.875), rem(1.875)],
  maxWidth: rem(36.25),
  paddingBottom: rem(1.875),
  position: 'relative',
  width: '100%',
});

export const imageStyle = css({
  borderTopLeftRadius: borderRadiusS,
  borderTopRightRadius: borderRadiusS,
  paddingBottom: rem(1.25),
  maxWidth: '100%',
});

export const listStyle = css({
  li: {
    alignItems: 'flex-start',
    display: 'flex',
    div: {
      marginLeft: rem(0.625),
    },
    listStyle: 'none',
  },
  listStyle: 'none',
  margin: [rem(0.625), SpacingInRemBySize['2XL'], SpacingInRemBySize['2XL']],
  paddingLeft: 0,
  textAlign: 'left',
});

export const buttonContainerStyle = css({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-end',
});

export const imageContainerStyles = css({
  width: rem(12),
  maxHeight: rem(2.5),
});
