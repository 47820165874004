import landingPageIcon1 from 'assets/umd-landing-page-icon-1.svg';
import landingPageIcon2 from 'assets/umd-landing-page-icon-2.svg';
import landingPageIcon3 from 'assets/umd-landing-page-icon-3.svg';
import React, { FC, useMemo } from 'react';
import {
  bodyLCss,
  cssWithMq,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  centeredContainerStyle,
  iconGroupContainerStyle,
  iconGroupStyle,
  iconStyle,
} from '../styles/verificationPageStyleUmdWfm';
import { LearnMoreLinkUmd } from './LearnMoreLinkUmd';
import { getHealthProvider } from '../../../../state/selectors/healthProviderSelector/healthProviderSelector';
import { HealthProvider } from '../../../../constants/healthProvider';
import {
  getEAPCoverageProvider,
  getEAPCoverages,
} from '../../../../../../state/selectors/organizationSelectors';
import isSupportedCountryForCoaching from '../../../../utils/isSupportedCountryForCoaching';
import { useGetCountryCode } from '../../../../../../hooks/useGetCountryCode';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../constants/featureFlags';

const ICON_ID_MAP = {
  icon1: 1,
  icon2: 2,
  icon3: 3,
};

const iconGroup = (showCoachingAndClinicalMessage = false) => {
  return [
    {
      icon: landingPageIcon1,
      id: ICON_ID_MAP.icon1,
      messageKey: 'enrollment.landingPage.list.message1',
    },
    {
      icon: landingPageIcon2,
      id: ICON_ID_MAP.icon2,
      messageKey: showCoachingAndClinicalMessage
        ? 'enrollment.landingPage.eap.globalEnrollment.list.message2.coachingAndClinical'
        : 'enrollment.landingPage.list.message2Umd',
    },
    {
      icon: landingPageIcon3,
      id: ICON_ID_MAP.icon3,
      messageKey: 'enrollment.landingPage.list.message3',
    },
  ];
};

const landingCardsContainerStyle = cssWithMq(centeredContainerStyle, {
  alignItems: 'center',
  margin: [`0 auto ${SpacingInRemBySize.XL} auto`, `0 auto ${rem(5.625)} auto`],
  paddingTop: [SpacingInRemBySize.XL, SpacingInRemBySize['2XL']],
});

const landingCardsItemStyle = cssWithMq(bodyLCss, {
  margin: [0, 'inherit'],
});

type LandingCardSetProps = {
  isMobileView: boolean;
};

export const LandingCardSet: FC<LandingCardSetProps> = ({ isMobileView }) => {
  const { translate } = useTranslate();
  const healthProvider = useSelector(getHealthProvider);
  const provider = useSelector(getEAPCoverageProvider);
  const eapCoverages = useSelector(getEAPCoverages);
  const hasEapCoverage = eapCoverages.length > 0 || provider;
  const { data: countryCode } = useGetCountryCode();
  const [isHubAccessForIntlEnable] = useFeatureFlag(
    FEATURE_FLAG.HUB_ACCESS_FOR_INTL_CARE_PACKAGE,
  );

  const iconGroupSet = useMemo(() => {
    let result;
    if (healthProvider === HealthProvider.VPCARE || hasEapCoverage) {
      result = iconGroup(true);
    } else if (
      isHubAccessForIntlEnable &&
      countryCode &&
      !isSupportedCountryForCoaching(countryCode)
    ) {
      result = iconGroup(true).filter((icon) => icon.id !== ICON_ID_MAP.icon2);
    } else {
      result = iconGroup(false);
    }
    return result;
  }, [hasEapCoverage, healthProvider, countryCode, isHubAccessForIntlEnable]);

  return (
    <div css={landingCardsContainerStyle}>
      <ul css={iconGroupContainerStyle}>
        {iconGroupSet.map(({ icon, messageKey }) => (
          <li
            css={iconGroupStyle}
            key={messageKey}
            data-testid="landing-cart-icon-item"
          >
            <img css={iconStyle} src={icon} alt="" role="presentation" />
          </li>
        ))}
      </ul>
      <ul css={iconGroupContainerStyle}>
        {iconGroupSet.map(({ messageKey }) => (
          <li
            css={iconGroupStyle}
            key={messageKey}
            data-testid="landing-cart-text-item"
          >
            <p css={landingCardsItemStyle}>{translate(messageKey)}</p>
          </li>
        ))}
      </ul>
      {isMobileView && <LearnMoreLinkUmd />}
    </div>
  );
};
