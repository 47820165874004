import { memo, ReactNode, useMemo } from 'react';

import { cssWithMq, rem } from '@headspace/web-ui-components';
import { joinDataTestIdPrefixes } from '../../../../../utils/joinDataTestIdPrefixes';

interface ContentWrapperProps {
  children: ReactNode;
  className?: string;
  dataTestIdPrefix?: string;
}

const wrapperCss = cssWithMq({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: [rem(18), rem(33.75), rem(54)],
  maxWidth: '100%',
  height: '100%',
  margin: '0 auto',
  padding: [0, undefined, undefined],
});

const ContentWrapper = memo<ContentWrapperProps>(
  ({ className, children, dataTestIdPrefix }) => {
    const dataTestId = useMemo(
      () => joinDataTestIdPrefixes(dataTestIdPrefix, 'content-wrapper'),
      [dataTestIdPrefix],
    );

    return (
      <div className={`${wrapperCss} ${className}`} data-testid={dataTestId}>
        {children}
      </div>
    );
  },
);

export default ContentWrapper;
