import { FC } from 'react';
import { connect } from 'react-redux';
import {
  outerContainerStyle,
  pricingContainerStyle,
  priceStyle,
  currencyStyle,
  subLabel,
} from './styles';
import { RootState } from '../../../../../state/types';
import { useTranslate } from '../../../../../hooks/useTranslate';
import {
  getCurrency,
  getVoucherPrice,
  getVoucherSymbol,
} from '../../../state/selectors/paymentSectionSelelector/paymentSectionSelector';

interface PriceComponentProps {
  symbol: string;
  price: number | string;
  currency: string;
}

const PriceComponent: FC<PriceComponentProps> = ({
  price,
  symbol,
  currency,
}) => {
  const { translate } = useTranslate();
  return (
    <div css={outerContainerStyle}>
      <h4>{translate('payment.details')}</h4>
      <div css={pricingContainerStyle}>
        <div>
          <div css={priceStyle}>{`${decodeURIComponent(symbol)} ${price}`}</div>
          <div css={subLabel}>{translate('payment.total')}</div>
        </div>
        <div css={currencyStyle}>
          <div>{`${currency} ${decodeURIComponent(symbol)}`}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currency: getCurrency(state),
  price: getVoucherPrice(state),
  symbol: getVoucherSymbol(state),
});

const TranslatedPriceComponent = connect(mapStateToProps)(PriceComponent);

export default TranslatedPriceComponent;
