import * as React from 'react';

import { White } from '@headspace/web-ui-components';
import {
  containerStyle,
  cardStyle,
  topHalfStyle,
  bottomHalfStyle,
  circleDiv,
  titleStyle,
  informationStyle,
  imageStyle,
} from '../styles/cardStyle';

import { DownLoadIcon } from '../../../../../../shared-components/Icons/DownloadIcon';

export interface DownloadableAsset {
  fields: {
    file: {
      url: string;
      fileName: string;
    };
  };
}

export interface CardProps {
  children?: React.ReactNode;
  title: string;
  information: string;
  imageUrl: string;
  downloadableAsset: DownloadableAsset;
}

export const Card: React.FC<CardProps> = ({
  title,
  information,
  imageUrl,
  downloadableAsset,
  children,
}) => {
  // TODO: We need a dig method for traversing nested objects safely
  const assetUrl = downloadableAsset && downloadableAsset.fields.file.url;
  const fileName = downloadableAsset && downloadableAsset.fields.file.fileName;

  return (
    <div css={containerStyle}>
      <div css={cardStyle}>
        {imageUrl ? (
          <div>
            <img
              css={imageStyle}
              src={`https:${imageUrl}`}
              alt=""
              role="presentation"
            />
          </div>
        ) : (
          <div css={topHalfStyle} />
        )}
        <div css={bottomHalfStyle}>
          <p css={titleStyle}>{title}</p>
          <p css={informationStyle}>{information}</p>
          <a href={`https:${assetUrl}`} target="blank" download={`${fileName}`}>
            {
              // @ts-ignore
              <div css={circleDiv} role="button" tabIndex={0}>
                <DownLoadIcon color={White['000']} />
              </div>
            }
          </a>
        </div>
      </div>
    </div>
  );
};
