import { css } from '@emotion/react';
import {
  fontSizeM,
  fontSizeXS,
  rem,
  WarmGrey,
} from '@headspace/web-ui-components';

export const outerContainerStyle = css({
  '@media(max-width: 550px)': {
    maxWidth: rem(15.625),
  },
  color: WarmGrey[500],
  fontSize: fontSizeM,
  margin: rem(3.125),
  marginTop: 0,
  textAlign: 'center',
});

export const termsTextStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeXS,
  textAlign: 'center',
  width: rem(15),
});

export const textAlign = css({
  display: 'flex',
  justifyContent: 'center',
});
