import { HealthProvider } from '../apps/memberEnrollmentV2/constants/healthProvider';
import { Routes } from '../apps/memberEnrollmentV2/constants/routes';

export const determineHealthProviderByPathname = (
  pathname: string,
): HealthProvider | undefined => {
  let formattedPathname = pathname;

  if (pathname.endsWith('/')) {
    formattedPathname = pathname.slice(0, -1);
  }

  if (
    formattedPathname === Routes.SOLERA_LAND ||
    formattedPathname === Routes.GINGER_SOLERA_LAND ||
    formattedPathname === Routes.HEADSPACE_CARE_SOLERA_LAND
  ) {
    return HealthProvider.SOLERA;
  }

  if (formattedPathname === Routes.GYMPASS_LAND) {
    return HealthProvider.GYMPASS;
  }

  if (formattedPathname === Routes.SSO_LAND) {
    return HealthProvider.VP;
  }

  if (formattedPathname === Routes.VP_CARE_LAND) {
    return HealthProvider.VPCARE;
  }
  return undefined;
};
