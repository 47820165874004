import { STEP_STATUS } from '../../apps/dashboard/constants';

interface StepConstant {
  [step: number]: string;
}

type Step = {
  status: STEP_STATUS;
  step: number;
};

export const getStepStatus = (activeStep: number, step: number) => {
  if (activeStep === step) return STEP_STATUS.ACTIVE;
  return activeStep > step ? STEP_STATUS.COMPLETE : STEP_STATUS.INCOMPLETE;
};

export const buildStepStatuses = (
  steps: StepConstant,
  activeStep: number,
): Step[] =>
  Object.keys(steps).map((step) => ({
    status: getStepStatus(activeStep, +step),
    step: +step,
  }));
