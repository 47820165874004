import { css } from '@emotion/react';
import {
  Modal,
  PrimaryButton,
  TertiaryButton,
  WarmGrey,
  fontSizeXS,
} from '@headspace/web-ui-components';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dependentPiiEnrollmentScreenViewAction } from '../../../../../../analytics/events/enrollmentEvents';
import { CountryCode } from '../../../../../../constants/country';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { CountrySelect } from '../../../../../../shared-components/CountrySelect';
import { Input } from '../../../../../../shared-components/Input';
import { errorStyles } from '../../../../../../shared-components/Input/styles';
import { ErrorResponse, parseError } from '../../../../../../utils/parseError';
import { EnrollmentVerification } from '../../../../constants/enrollmentVerification';
import { getUnifiedEligibilityRoutes } from '../../../../constants/routes';
import {
  EnrollmentByPiiPayload,
  postPiiEnrollmentVerification,
} from '../../../../rest-calls';
import { setEnrollmentVerificationConfirmation } from '../../../../state/actions/memberEnrollActions/memberEnrollActions';
import { getSlug } from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { FormContainer } from '../../../shared-components/FormContainer/FormContainer';
import {
  containerStyle,
  subTitleStyle,
} from '../../../shared-components/VerifyBy/styles';
import { DobInput } from '../../IdentityVerificationPage/components/DobInput';
import {
  modalBodyStyle,
  modalFooterStyle,
} from '../../IdentityVerificationPage/styles';
import { FormHeader } from '../../VerificationPage/components/FormHeader';
import { SubmitButton } from '../../VerificationPage/components/SubmitButton';
import { Terms } from '../../VerificationPage/components/Terms';
import { FAQ_PAGE_ELF_DEPENDENTS_LINK } from '../../constants';
import {
  dependentEnrollmentInputStyle,
  dependentEnrollmentModalStyle,
} from '../../styles/verificationStyles';

const dobContainerStyle = css({
  marginTop: '25px',
  label: {
    color: WarmGrey[500],
  },
  p: {
    fontSize: fontSizeXS,
  },
});

export const EligibilityVerifyDependent: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const slug = useSelector(getSlug);
  const {
    VERIFY_IDENTITY_ERROR_PATH,
    LOADING_PATH,
  } = getUnifiedEligibilityRoutes(slug);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState({ isValid: false, value: '' });
  const [country, setCountry] = useState<string>();
  const [zipCode, setZipCode] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({
    country: false,
    dateOfBirth: false,
    firstName: false,
    lastName: false,
    zipCode: false,
  });

  const handleChange = useCallback((event: ExtendedEvent) => {
    switch (event.target.id) {
      case 'first_name': {
        setFirstName(event.target.value);
        break;
      }
      case 'last_name': {
        setLastName(event.target.value);
        break;
      }
      case 'dob': {
        setDateOfBirth(event.target.value);
        break;
      }
      case 'country': {
        setCountry(event.target.value);
        break;
      }
      case 'zip_code': {
        setZipCode(event.target.value);
        break;
      }
      default:
        break;
    }
  }, []);

  const handleChangeDob = useCallback((result) => {
    setDateOfBirth(result);
  }, []);

  const verifyForm = useCallback(() => {
    const firstNameError = !firstName;
    const lastNameError = !lastName;
    const dobError = !dateOfBirth.isValid;
    const countryError = !country;
    const zipCodeError = country === 'US' && !zipCode;
    setErrors({
      country: countryError,
      dateOfBirth: dobError,
      firstName: firstNameError,
      lastName: lastNameError,
      zipCode: zipCodeError,
    });
    return !(
      firstNameError ||
      lastNameError ||
      dobError ||
      countryError ||
      zipCodeError
    );
  }, [firstName, lastName, dateOfBirth, country, zipCode]);

  const onSubmit = async () => {
    const isValidForm = verifyForm();
    if (isValidForm) {
      dispatch(dependentPiiEnrollmentScreenViewAction('confirmation modal'));
      setShowModal(true);
    }
  };

  const closeModal = useCallback(() => setShowModal(false), []);

  const mutation = useMutation({
    mutationFn: async () => {
      const params: EnrollmentByPiiPayload = {
        country: country!,
        dateOfBirth: dayjs(dateOfBirth.value).format('YYYY-MM-DD'),
        firstName: firstName!,
        lastName: lastName!,
      };
      if (country === 'US') {
        params.zipCode = zipCode;
      }

      try {
        await postPiiEnrollmentVerification(slug, params);
        dispatch(
          setEnrollmentVerificationConfirmation({
            method: EnrollmentVerification.PII,
            ...params,
          }),
        );
        dispatch(dependentPiiEnrollmentScreenViewAction('success'));
        navigate(LOADING_PATH);
      } catch (err) {
        const parsedError = parseError(err as ErrorResponse);
        const codeString = encodeURIComponent(
          parsedError.errorStringCode ?? '',
        );
        dispatch(dependentPiiEnrollmentScreenViewAction('error'));
        navigate(VERIFY_IDENTITY_ERROR_PATH, {
          state: {
            search: `code=${codeString}`,
          },
        });
      }
    },
  });

  const confirmSubmit = useCallback(() => {
    mutation.mutate();
    closeModal();
  }, [mutation]);

  const faqComponent = useMemo(() => {
    return <Terms faqLinkOverride={FAQ_PAGE_ELF_DEPENDENTS_LINK} />;
  }, []);

  useEffect(() => {
    dispatch(dependentPiiEnrollmentScreenViewAction('initial'));
  }, []);

  return (
    <div data-testid="elf-dependent-flow">
      {showModal && (
        <Modal
          css={dependentEnrollmentModalStyle}
          body={
            <h2 css={modalBodyStyle}>
              {translate('enrollment.elfDependent.confirmationModal.title', {
                dateOfBirth: dateOfBirth.value,
              })}
            </h2>
          }
          footer={
            <div css={modalFooterStyle}>
              <PrimaryButton onClick={confirmSubmit} dataTestIdPrefix="yes">
                {translate('yes')}
              </PrimaryButton>
              <TertiaryButton onClick={closeModal} dataTestIdPrefix="no">
                {translate('no')}
              </TertiaryButton>
            </div>
          }
          onClose={closeModal}
        />
      )}
      <FormContainer termsComponentOverride={faqComponent}>
        <FormHeader title={translate('enrollment.verify.workId.headline')} />
        <div
          css={css(containerStyle(true), {
            textAlign: 'left',
          })}
        >
          <div css={css(subTitleStyle(), { textAlign: 'center' })}>
            <p>{translate('enrollment.elfDependent.subtitle')}</p>
          </div>
          <Input
            placeholder={translate(
              'enrollment.elfDependent.firstName.placeholder',
            )}
            aria-label={translate(
              'enrollment.elfDependent.firstName.placeholder',
            )}
            name="first_name"
            onChange={handleChange}
            customInputStyles={dependentEnrollmentInputStyle}
            hasError={errors.firstName}
            errorMessage={translate('enrollment.elfDependent.firstName.error')}
          />
          <Input
            placeholder={translate(
              'enrollment.elfDependent.lastName.placeholder',
            )}
            aria-label={translate(
              'enrollment.elfDependent.lastName.placeholder',
            )}
            name="last_name"
            onChange={handleChange}
            customInputStyles={dependentEnrollmentInputStyle}
            hasError={errors.lastName}
            errorMessage={translate('enrollment.elfDependent.lastName.error')}
          />
          <div css={dobContainerStyle}>
            <DobInput
              onChange={handleChangeDob}
              overrideErrorMessage={
                errors.dateOfBirth
                  ? translate('enrollment.elfDependent.dateOfBirth.error')
                  : undefined
              }
            />
          </div>
          <div css={{ marginTop: '25px' }}>
            <CountrySelect
              css={{ backgroundColor: WarmGrey[200], color: WarmGrey[500] }}
              onChange={(country: CountryCode | null) =>
                country && setCountry(country)
              }
            />
            {errors.country && (
              <span
                data-testid="country-error"
                css={errorStyles}
                tabIndex={-1}
                role="alert"
              >
                {translate('enrollment.elfDependent.country.error')}
              </span>
            )}
          </div>
          {country === 'US' && (
            <Input
              placeholder={translate(
                'enrollment.elfDependent.zipCode.placeholder',
              )}
              aria-label={translate(
                'enrollment.elfDependent.zipCode.placeholder',
              )}
              name="zip_code"
              onChange={handleChange}
              customInputStyles={dependentEnrollmentInputStyle}
              hasError={errors.zipCode}
              errorMessage={translate('enrollment.elfDependent.zipCode.error')}
            />
          )}
          <SubmitButton
            value={translate('enrollment.elfDependent.submit')}
            dataTestId="elf-dependent-enrollment-submit"
            onClick={onSubmit}
            isLoading={mutation.isLoading}
          />
        </div>
      </FormContainer>
    </div>
  );
};
