import * as React from 'react';
import { containerStyle, headerTextStyle } from '../styles';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface FinishPageWrapperProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const FinishPageWrapper: React.FC<FinishPageWrapperProps> = ({
  children,
}) => {
  const { translate } = useTranslate();
  return (
    <div css={containerStyle} data-testid="finish-page">
      <div>
        <h1 css={headerTextStyle}>{translate('finish.header')}</h1>
      </div>
      <div>{children}</div>
    </div>
  );
};
