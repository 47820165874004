import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { EligibilityVerifyPrimary } from './components/EligibilityVerifyPrimary';
import { VerificationPage } from '../VerificationPage';
import { ElfMemberTypeChooser } from '../../../components/elf-member-type-chooser';
import { getActiveOrg } from '../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { EligibilityVerifyDependent } from './components/EligibilityVerifyDependent';

export const EligibilityVerificationPage: FC = () => {
  const { elfDependentsEnabled, dependentsPiiEnrollmentEnabled } = useSelector(
    getActiveOrg,
  );
  const [isDependent, setIsDependent] = useState(false);
  const [hasChosen, setHasChosen] = useState(false);

  const onElfMemberTypeChoice = (choice: string) => {
    setIsDependent(choice === 'dependent');
    setHasChosen(true);
  };
  const showElfMemberTypeChoice =
    elfDependentsEnabled && dependentsPiiEnrollmentEnabled && !hasChosen;

  return (
    <VerificationPage skipMobileHero={false}>
      {showElfMemberTypeChoice ? (
        <ElfMemberTypeChooser onChoice={onElfMemberTypeChoice} />
      ) : (
        <>
          {isDependent ? (
            <EligibilityVerifyDependent />
          ) : (
            <EligibilityVerifyPrimary />
          )}
        </>
      )}
    </VerificationPage>
  );
};
