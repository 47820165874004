export enum SSOEnrollmentActionTypes {
  LOGIN = '@token/LOGIN',
  LINK_IDENTITIES = '@token/LINK_IDENTITIES',
  LINKED_VP_SSO_IDENTITY = 'LINKED_VP_SSO_IDENTITY',
  CHECK_ENROLLMENT_STATUS = 'CHECK_ENROLLMENT_STATUS',
  SET_SLUGS = 'SET_SLUGS',
  FETCH_SSO_ENROLLMENT_STATUS = 'FETCH_SSO_ENROLLMENT_STATUS',
}

export interface OrgSlugAndName {
  name: string;
  slug: string;
}

export type SSOOrgs = Array<OrgSlugAndName>;

export interface LinkedIdentitiesAction {
  type: SSOEnrollmentActionTypes.LINKED_VP_SSO_IDENTITY;
  payload: {
    isIdentityConnected: boolean;
  };
}

export interface EnrollmentStatusAction {
  type: SSOEnrollmentActionTypes.CHECK_ENROLLMENT_STATUS;
  payload: {
    enrolled: boolean;
    healthHubId?: string;
  };
}

export interface EnrollmentSetSlugsAction {
  type: SSOEnrollmentActionTypes.SET_SLUGS;
  payload: {
    orgs: SSOOrgs;
  };
}
