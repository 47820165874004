import { Blue, White } from '@headspace/web-ui-components';
import VirginPulseLogo from 'assets/virgin-pulse-logo.png';
import { FC } from 'react';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import { linkStyles } from '../../../../dashboard/LoginView/styles';
import { START_USING_HS_LINK } from '../../../constants';
import {
  logo,
  logoContainer,
} from '../../HealthHubViews/VirginPulse/LinkPage/styles/preLinkPageStyle';
import { FormContainer } from '../../shared-components/FormContainer/FormContainer';
import { numericListStyle, outerContainerStyle } from '../styles';

interface VPCareEnrollmentComponentProps {
  onClick?();
}

export const VPCareEnrollmentComponent: FC<VPCareEnrollmentComponentProps> = ({
  onClick,
}) => {
  const { translate } = useTranslate();

  return (
    <FormContainer
      headerTag="h4"
      title={translate('ssoEnrollment.landingPage.header')}
      showTerms={false}
    >
      <div css={outerContainerStyle} data-testid="vp-care-component-container">
        <p>{translate('enrollment.vpCareComponent.subHeader')}</p>
        <div
          css={logoContainer(true)}
          data-testid="vp-care-component-virgin-pulse-logo"
        >
          <img css={logo} src={VirginPulseLogo} alt="virgin-pulse-logo" />
        </div>
        <ol css={numericListStyle} data-testid="vp-care-component-list">
          <li>{translate('enrollment.vpCareComponent.list.message1')}</li>
          <li>{translate('enrollment.vpCareComponent.list.message2')}</li>
          <li>{translate('enrollment.vpCareComponent.list.message3')}</li>
        </ol>
        <TextButton
          dataTestId="vp-care-component-start-now-button"
          textColor={White['000']}
          hoverColor={Blue[200]}
          backgroundColor={Blue[200]}
          pill={true}
          width="100%"
          lg={true}
          value={translate('enrollment.vpCareComponent.button')}
          onClick={onClick}
          suppressLetterSpacing={true}
        />
        <p>
          {translate('enrollment.vpCareComponent.alreadyEnrolled')}
          <a
            css={linkStyles}
            data-testid="vp-care-start-using-link"
            href={START_USING_HS_LINK}
          >
            {translate('enrollment.vpCareComponent.startUsing')}
          </a>
        </p>
      </div>
    </FormContainer>
  );
};
