import { dayPickerStyle } from './styles';

export const DateInputOverlay = (props: any): JSX.Element => {
  const { children, classNames, selectedDay, ...passedProps } = props;
  return (
    <div css={dayPickerStyle} {...passedProps}>
      {children}
    </div>
  );
};
