import { AxiosPromise } from 'axios';

export interface CreateMember {
  slug: string;
  email: string;
}

export interface CreateMemberByEmail {
  ({ slug, email }): AxiosPromise<CreateMember>;
}

export interface MembersSearchResult {
  total: number;
  members: MemberSearchEntry[];
}

export interface MemberSearchEntry {
  activeSince?: string | null;
  firstName?: string;
  hsUserId: string | null;
  lastActivity?: string | null;
  lastName?: string;
  memberId?: number;
  id?: number;
  orgId?: number | null;
  status?: string;
  stillEligible?: boolean;
  workEmail?: string;
  workId?: string | null;
  email: string;
}

export enum MembersExportSortableFields {
  NAME = 'firstName',
  STATUS = 'status',
  ACTIVESINCE = 'activeSince',
}

export interface MembersExportQueryParams {
  status: Exclude<MemberStatus, MemberStatus.REMOVED>[];
  includeIds?: number[];
  orderField: MembersExportSortableFields;
  orderDirection: SortDirection;
  search?: SearchSpec['input'];
  bypassStatusFilter: boolean;
}
export interface MembersSearchQuery {
  orgId: number;
  term?: string | null;
  status?: MemberStatus[];
  offset?: number;
  limit?: number;
  orderDirection?: SortDirection;
  orderField?: MemberSortableFields;
  bypassStatusFilter?: boolean;
}

export enum MemberStatus {
  ACTIVE = 'ACTIVE',
  ELIGIBLE = 'ELIGIBLE',
  EXPIRED = 'EXPIRED',
  INVITED = 'INVITED',
  ORPHAN = 'ORPHAN',
  PENDING = 'PENDING',
  PENDING_REMOVAL = 'PENDING_REMOVAL',
  REMOVED = 'REMOVED',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SearchSpec {
  input: string | undefined;
  fields: string[];
}

export enum MemberSortableFields {
  NAME = 'name',
  STATUS = 'status',
  ACTIVESINCE = 'activeSince',
  FULLNAME = 'fullName',
}

export type MembersSortIndex = { [key in MemberSortableFields]: any };
