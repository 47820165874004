import {
  cssWithMq,
  fontFamilyCss,
  fontSizeL,
  fontSizeM,
  headingLCss,
  PrimaryButton,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import { FC } from 'react';
import {
  landingPageFooterStyle,
  landingPageFooterIcon,
} from '../styles/verificationPageStyle';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import landingPageFooter from '../../../../../../assets/umd-landing-page-footer.svg';
import landingPageFooterMobile from '../../../../../../assets/umd-landing-page-footer-mobile.svg';
import { onlyOnMobile } from '../../Onboarding/RecommendationView/styles';

const signButtonStyle = cssWithMq(fontFamilyCss, {
  alignSelf: 'center',
  fontSize: [fontSizeM, fontSizeL],
  height: SpacingInRemBySize['4XL'],
  marginBottom: [rem(2.375), rem(3.125), rem(3.75)],
  minWidth: [rem(6.125), rem(17)],
  padding: [`0 ${rem(1)}`, 0],
  width: 'auto',
});

const footerHeaderStyle = cssWithMq(headingLCss, {
  marginBottom: SpacingInRemBySize.L,
  marginTop: rem(3.75),
  textAlign: 'center',
});

interface LandingFooterProps {
  isMobile: boolean;
}

export const LandingFooter: FC<LandingFooterProps> = ({ isMobile }) => {
  const { translate } = useTranslate();

  const footerCardHeader = 'enrollment.landingPage.footerCardHeaderUmd';
  const footerCardButtonUMD = isMobile
    ? 'enrollment.verify.email.headlineUmd'
    : 'enrollment.landingPage.footerCardButton';

  return (
    <div css={landingPageFooterStyle} data-testid="landing-footer">
      <h2 data-testid="landingFooter-header" css={footerHeaderStyle}>
        {translate(footerCardHeader)}
      </h2>
      <PrimaryButton
        css={signButtonStyle}
        onClick={() => {
          window.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: 0,
          });
        }}
        isBlock={true}
      >
        {translate(footerCardButtonUMD)}
      </PrimaryButton>
      <img
        css={landingPageFooterIcon}
        src={landingPageFooter}
        alt=""
        role="presentation"
      />
      <img
        css={onlyOnMobile}
        src={landingPageFooterMobile}
        alt=""
        role="presentation"
      />
    </div>
  );
};
