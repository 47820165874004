import { Dispatch } from 'redux';
import { OrganizationActionsTypes } from '../../types/organization';
import { processErrors } from './processErrors';
import { resetError } from '../../../utils/messageDefaults';

enum TYPE {
  NUMBER_OF_SEATS = 'current_contract_seats',
  FAMILY_MEMBERS_PER_EMPLOYEE = 'current_contract_family_members_per_employee',
  NUMBER_OF_FAMILY_MEMBER_SEATS = 'current_contract_number_of_family_members_seats',
  FUTURE_NUMBER_OF_SEATS = 'future_contract_seats',
  FUTURE_FAMILY_MEMBERS_PER_EMPLOYEE = 'future_contract_family_members_per_employee',
  FUTURE_NUMBER_OF_FAMILY_MEMBER_SEATS = 'future_contract_number_of_family_members_seats',
}

enum MULTIPLIER_VALUE {
  current_contract_family_members_per_employee = 'current_contract_seats',
  current_contract_seats = 'current_contract_family_members_per_employee',
  future_contract_family_members_per_employee = 'future_contract_seats',
  future_contract_seats = 'future_contract_family_members_per_employee',
}

export const handleChange = (
  event: ExtendedEvent,
  suppressValidation?: boolean,
  dependantInputType?: string,
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (event.persist) event.persist();
    const { manageOrganization } = getState();
    const {
      target: { id, value, type },
    } = event;

    let nextValue = value;
    if (type === 'checkbox') nextValue = !manageOrganization[id];
    const payload = { [id]: nextValue };

    dispatch({
      payload,
      type: OrganizationActionsTypes.HANDLE_CHANGE,
    });
    if (suppressValidation) {
      return;
    }

    dispatch(processErrors(event));

    if (dependantInputType) {
      dispatch({
        payload: {
          [`${dependantInputType}Error`]: resetError(true),
        },
        type: OrganizationActionsTypes.PROCESS_ERRORS,
      });
    }

    const multiplier = manageOrganization[MULTIPLIER_VALUE[id]];
    const shouldMultiply =
      id === TYPE.FAMILY_MEMBERS_PER_EMPLOYEE ||
      id === TYPE.NUMBER_OF_SEATS ||
      id === TYPE.FUTURE_FAMILY_MEMBERS_PER_EMPLOYEE ||
      id === TYPE.FUTURE_NUMBER_OF_SEATS;

    if (shouldMultiply) {
      const type = id.match('future')
        ? TYPE.FUTURE_NUMBER_OF_FAMILY_MEMBER_SEATS
        : TYPE.NUMBER_OF_FAMILY_MEMBER_SEATS;

      dispatch({
        payload: {
          [type]: value * multiplier,
        },
        type: OrganizationActionsTypes.HANDLE_CHANGE,
      });

      dispatch({
        payload: {
          [`${type}Error`]: resetError(true),
        },
        type: OrganizationActionsTypes.PROCESS_ERRORS,
      });
    }
  };
};
