import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';
import { GetState } from '../../types';
import {
  OrganizationActionsTypes,
  ToggleEditPanelViewAction,
} from '../../types/organization';

const { TOGGLE_EDIT_PANEL_VIEW } = OrganizationActionsTypes;

export const toggleEditPanelView = (
  index: number,
): ThunkMiddleware & ToggleEditPanelViewAction => {
  // @ts-ignore
  return (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: { viewPanels = [] },
    } = getState();
    const nextViewPanels = viewPanels.slice();
    nextViewPanels[index] = !nextViewPanels[index];
    dispatch({
      type: TOGGLE_EDIT_PANEL_VIEW,
      payload: {
        viewPanels: nextViewPanels,
      },
    });
  };
};
