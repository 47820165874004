import { createAuthorizationHeader } from '../../utils/auth';
import { HTTPMethod, responseType } from '../../utils/constants';
import { B2B_API, getB2BOrgSFTPUserUrl } from '../../utils/urls';
import { orgAPIAxios } from '../axios';
import {
  SFTPUserPromise,
  CreateSFTPUserProps,
  UpdateSFTPUserProps,
  GetSFTPUsersProps,
  GetSFTPUsersPromise,
} from './sftp-user.types';

export const createSFTPUser = ({
  orgId,
  username,
  key,
}: CreateSFTPUserProps): SFTPUserPromise => {
  const data = new FormData();
  data.append('username', username);
  data.append('file', key);

  return orgAPIAxios({
    data,
    headers: createAuthorizationHeader(),
    method: HTTPMethod.POST,
    url: getB2BOrgSFTPUserUrl(orgId).CREATE_SFTP_USER,
  });
};

export const updateSFTPUser = ({
  orgId,
  username,
  key,
}: UpdateSFTPUserProps): SFTPUserPromise => {
  const data = new FormData();
  data.append('file', key);

  return orgAPIAxios({
    data,
    headers: createAuthorizationHeader(),
    method: HTTPMethod.PATCH,
    url: getB2BOrgSFTPUserUrl(orgId, username).UPDATE_SFTP_USER,
  });
};

export const getSFTPUsers = ({
  orgId,
  username,
}: GetSFTPUsersProps): GetSFTPUsersPromise =>
  orgAPIAxios({
    data: { username },
    headers: createAuthorizationHeader(),
    method: HTTPMethod.GET,
    responseType: responseType.JSON,
    url: getB2BOrgSFTPUserUrl(orgId).GET_SFTP_USERS,
  });

export const getPgpKey = () =>
  orgAPIAxios({
    headers: createAuthorizationHeader(),
    method: HTTPMethod.GET,
    url: B2B_API.GET_PGP_KEY,
  });
