import { AnyAction } from 'redux';
import {
  OrganizationActionsTypes,
  ActiveOrgState,
  GetOrgByIdAction,
  GetOrgByIdState,
  AddMemberByEmailAction,
  AddMemberByEmailState,
  MemberEnrollmentAction,
  MemberEnrollmentState,
} from '../types/organization';

export const activeOrg = (state: ActiveOrgState = null, action: AnyAction) =>
  action.type === OrganizationActionsTypes.ORG_SELECTED
    ? action.payload
    : state;

export function getOrgById(
  state: GetOrgByIdState = null,
  action: GetOrgByIdAction | AnyAction,
) {
  switch (action.type) {
    case OrganizationActionsTypes.GET_ORG_BY_ID:
      return action.payload;
    default:
      return state;
  }
}

export function addMemberByEmail(
  state: AddMemberByEmailState = null,
  action: AddMemberByEmailAction | AnyAction,
) {
  switch (action.type) {
    case OrganizationActionsTypes.ADD_MEMBER_BY_EMAIL:
      return action.payload;
    default:
      return state;
  }
}

export function memberEnrollment(
  state: MemberEnrollmentState = null,
  action: MemberEnrollmentAction | AnyAction,
) {
  switch (action.type) {
    case OrganizationActionsTypes.MEMBER_ENROLLMENT:
      return action.payload;
    default:
      return state;
  }
}
