import { css } from '@emotion/react';
import {
  borderRadiusXS,
  CoolGrey,
  fontSizeS,
  Orange,
  Red,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import { justifyContentCenter, rowFlex } from '../../../../../styles/flex';
import { bold } from '../../../../../styles/fonts';

export const detailsFileUploadButtonStyle = ({
  disabled,
}: {
  disabled?: boolean;
}) =>
  css(rowFlex, bold, justifyContentCenter, {
    ':hover': {
      backgroundColor: disabled ? 'inherit' : CoolGrey[100],
    },
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    borderRadius: borderRadiusXS,
    color: disabled ? Red[200] : Orange[200],
    cursor: disabled ? 'not-allowed' : 'pointer',
    fontSize: fontSizeS,
    letterSpacing: rem(0.156),
    outline: 'none',
    padding: `${SpacingInRemBySize.XS} ${rem(1.375)}`,
    textTransform: 'uppercase',
  });

export const detailsFileUploadButtonTextStyle = css({
  marginLeft: SpacingInRemBySize.S,
});
