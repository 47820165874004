import * as React from 'react';
import { Divider, Orange } from '@headspace/web-ui-components';
import plus from 'assets/plus.svg';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { createButtonSectionStyle, createButtonStyle } from '../styles';
import { HollowButton } from '../../../../../../../shared-components/buttons/HollowButton';

interface ChallengeCreateButtonPropTypes {
  createChallengeHandler: () => void;
}

export const ChallengeCreateButton: React.FC<ChallengeCreateButtonPropTypes> = ({
  createChallengeHandler,
}) => {
  const { translate } = useTranslate();

  return (
    <div>
      <Divider />
      <div css={createButtonSectionStyle}>
        <HollowButton
          dataTestId="create-challenges-btn"
          color={Orange[200]}
          onClick={createChallengeHandler}
          icon={{ iconSrc: plus }}
          css={createButtonStyle}
        >
          {translate('challenges.view.createNew')}
        </HollowButton>
      </div>
    </div>
  );
};
