import { useQuery } from '@tanstack/react-query';
import { getTotalActiveMembers } from '../../../../../rest';

export const useFetchTotalMembers = (orgId: number) =>
  useQuery({
    initialData: 0,
    queryFn: () => getTotalActiveMembers(orgId),
    queryKey: ['totalMembers'],
    refetchOnWindowFocus: false,
  });
