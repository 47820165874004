import { css } from '@emotion/react';
import { cssWithMq, rem } from '@headspace/web-ui-components';

export const containerStyle = cssWithMq({
  display: 'flex',
  flexDirection: 'row',
  margin: 'auto',
  paddingTop: rem(1.25),
  width: [rem(35), rem(64.5), rem(70.5)],
});

export const colStyle = css({
  '> div': {
    width: 'inherit',
  },
  width: 'inherit',
});
