import * as Sentry from '@sentry/browser';
import { ThunkDispatch } from '../../types';
import { ModalActionsTypes } from '../../types/modal';
import {
  OrganizationActionsTypes,
  FetchSFTPUsersAction,
} from '../../types/organization';
import { receiveSFTPUsers } from './receiveSFTPUsers';
import { parseError, ErrorResponse } from '../../../utils/parseError';
import { getSFTPUsers } from '../../../rest/sftp-user/sftp-user.api';

export const fetchSFTPUsers = (id: number) => async (
  dispatch: ThunkDispatch,
  getState: GetState,
) => {
  const {
    manageOrganization: { orgId },
  } = getState();

  dispatch({
    type: OrganizationActionsTypes.FETCH_SFTP_USERS,
    payload: null,
  } as FetchSFTPUsersAction);

  try {
    const result = await getSFTPUsers({
      orgId: Number.isNaN(orgId) ? id : orgId,
    });

    dispatch(receiveSFTPUsers(result.data));
  } catch (error) {
    Sentry.captureException(error);
    const { message, title } = parseError(error as ErrorResponse);
    dispatch({
      payload: {
        message,
        title,
      },
      type: ModalActionsTypes.OPEN_MODAL,
    });
  }
};
