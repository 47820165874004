import { css } from '@emotion/react';
import {
  borderWidthXS,
  CoolGrey,
  fontSizeS,
  Grey,
  LegacyColors,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { BreakPoint } from '../../../constants/breakpoint';
import { bold } from '../../../../dashboard/styles/fonts';

export const stepperStyle = css({
  display: 'flex',
  justifyContent: 'center',
  margin: rem(0.938),
  paddingTop: rem(1.563),
  [BreakPoint.Mobile]: {
    width: rem(20),
  },
});

export const circleContainer = (isActive: boolean) =>
  css({
    alignItems: 'center',
    borderRadius: rem(1.25),
    color: isActive ? WarmGrey[500] : WarmGrey[400],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: rem(1.25),
    paddingRight: rem(1.25),
    textAlign: 'center',
    [BreakPoint.Mobile]: {
      minWidth: rem(5.625),
      paddingLeft: rem(0.625),
      paddingRight: rem(0.625),
    },
  });

export const circleStyle = (isActive: boolean) =>
  css({
    alignItems: 'center',
    border: isActive ? `${borderWidthXS} solid ${CoolGrey[100]}` : 'none',
    borderRadius: rem(1.25),
    color: isActive ? WarmGrey[500] : 'transparent',
    display: 'flex',
    height: SpacingInRemBySize.L,
    justifyContent: 'center',
    width: SpacingInRemBySize.L,
  });

export const labelStyle = (isActive: boolean) =>
  css({
    color: isActive ? WarmGrey[500] : WarmGrey[400],
    marginTop: SpacingInRemBySize['2XS'],
  });

export const numberStyle = (isActive: boolean) =>
  css(bold, {
    color: isActive ? WarmGrey[500] : WarmGrey[400],
    fontSize: fontSizeS,
    fontStyle: 'normal',
    lineHeight: '100%',
  });
