import {
  OrganizationActionsTypes,
  AddMemberByEmailAction,
} from '../../types/organization';

const { ADD_MEMBER_BY_EMAIL } = OrganizationActionsTypes;

export const addMemberByEmail = (
  slug: string,
  email: string,
): AddMemberByEmailAction => {
  return {
    type: ADD_MEMBER_BY_EMAIL,
    payload: {
      slug,
      email,
    },
  };
};
