import {
  White,
  bodyXSCss,
  bodyLCss,
  headingSCss,
  rem,
  cssWithMq,
  SpacingInRemBySize,
  fontSizeM,
  fontSizeXL,
  fontSize2XL,
  fontSizeXS,
  fontSizeL,
  fontSize2XS,
} from '@headspace/web-ui-components';
import soleraSuccessImage from 'assets/solera-success.png';
import { HealthProvider } from '../../constants/healthProvider';
import { PageWrapper } from './PageWrapper';
import { contentCss, linkCss, titleCss } from './styles';
import { HEADSPACE_CARE, HEADSPACE_CARE_HELP_EMAIL } from './utils/utils';

const footerLayoutCss = cssWithMq({
  color: White['000'],
  marginBottom: [0, rem(1.625)],
  maxWidth: rem(40.3125),
  padding: [
    `${SpacingInRemBySize['4XL']} ${SpacingInRemBySize.L} ${SpacingInRemBySize.L} ${SpacingInRemBySize.L}`,
    0,
    0,
  ],
});

const footerTitleCss = cssWithMq(headingSCss, {
  fontSize: [fontSizeM, fontSize2XL, fontSize2XL],
});

const footerDescriptionCss = cssWithMq(bodyLCss, {
  fontSize: [fontSizeXS, fontSizeXL, fontSizeXL],
  margin: [
    `0 0 ${SpacingInRemBySize.L} 0`,
    `0 0 ${SpacingInRemBySize['4XL']} 0`,
    `0 0 ${SpacingInRemBySize['4XL']} 0`,
  ],
});

const noteCss = cssWithMq(bodyXSCss, {
  fontSize: [fontSize2XS, fontSizeXS, fontSizeXS],
  margin: 0,
});

const descriptionCss = cssWithMq(bodyLCss, {
  fontSize: [fontSizeM, fontSizeL, fontSizeL],
});

const GingerSoleraSuccessPage = () => {
  const footerDescription = `Great! We have your information and you should soon receive an email from ${HEADSPACE_CARE} with your unique code.`;
  const renderFooter = () => (
    <div css={footerLayoutCss}>
      <h3 css={footerTitleCss}>Have you already seen this page?</h3>
      <p
        css={footerDescriptionCss}
        data-testid="solera-success-page-footer-description"
      >
        {footerDescription}
      </p>
      <p css={noteCss}>
        Your network providers may offer telehealth and in-office services.
        Contact your primary care provider, treating specialist, facility, or
        other health professional to learn whether telehealth is an option.
        Network telehealth and in-person services are subject to the same timely
        access to care standards. If your plan has out-of-network benefits they
        are subject to your plan’s cost sharing obligations and balance billing
        protections.
      </p>
    </div>
  );

  return (
    <PageWrapper
      healthProvider={HealthProvider.SOLERA}
      footerLayout={renderFooter()}
    >
      <div css={contentCss}>
        <h1 css={titleCss}>Your access code has been sent.</h1>
        <p css={descriptionCss}>
          Check your inbox (don’t forget your spam folder) for your unique
          access code and instructions on how to get started with the mobile
          app.
        </p>
        <p css={descriptionCss}>
          If you do not receive an email or you can’t find it, please email
          &nbsp;
          <a
            css={linkCss}
            href={`mailto:${HEADSPACE_CARE_HELP_EMAIL}`}
            data-testid="solera-success-page-children-help-email-link"
          >
            {HEADSPACE_CARE_HELP_EMAIL}
          </a>
        </p>
      </div>
    </PageWrapper>
  );
};

export default GingerSoleraSuccessPage;
