import { css } from '@emotion/react';
import {
  borderWidthXS,
  cssWithMq,
  fontFamilyCss,
  fontSizeM,
  fontWeightBold,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';

export const buttonStyle = css(fontFamilyCss, {
  ':hover': {
    cursor: 'pointer',
  },
  '@media (max-width: 650px)': {
    '> p': {
      display: 'none',
    },
    width: rem(3.25),
  },
  alignItems: 'center',
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  border: 'none',
  color: WarmGrey[700],
  cursor: 'pointer',
  display: 'flex',
  fontSize: fontSizeM,
  fontWeight: fontWeightBold,
  height: rem(3.25),
  justifyContent: 'center',
  outline: 'none',
  overflow: 'hidden',
  padding: '0',
  width: rem(7.5),
});

export const languageTabStyle = (isOpen: boolean) =>
  css({
    '> li': {
      listStyle: 'none',
      margin: '0',
      padding: '0',
    },
    background: White['000'],
    border: `${borderWidthXS} solid ${WarmGrey[300]}`,
    borderRadius: rem(0.313),
    boxSizing: 'border-box',
    display: !isOpen ? 'none' : 'block',
    margin: '0',
    padding: '0',
    position: 'absolute',
    right: 0,
    top: rem(4.375),
    width: rem(7.5),
    zIndex: 2,
  });

export const languageButtonStyle = (isActive = false) =>
  css({
    '> button': {
      ...fontFamilyCss,
      backgroundColor: isActive ? WarmGrey[300] : White['000'],
      border: 'none',
      cursor: 'pointer',
      fontSize: fontSizeM,
      padding: `${SpacingInRemBySize['3XS']} 0 ${
        SpacingInRemBySize['3XS']
      } ${rem(1.375)}`,
      textAlign: 'left',
      width: '100%',
    },
  });

export const caretPadding = css({
  padding: SpacingInRemBySize.XS,
});

export const mobileSignOutStyle = (userId: string) =>
  css({
    '@media(min-width: 650px)': {
      display: 'none',
    },
    borderBottom: `${borderWidthXS} solid ${WarmGrey[100]}`,
    display: userId ? 'flex' : 'none',
  });

export const languageMenuContainer = cssWithMq({
  top: [rem(1.25), rem(2.5), rem(1.25)],
});
