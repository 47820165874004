import Select from 'react-select';
import { FC, useCallback } from 'react';
import { useTranslate } from '../../hooks/useTranslate';
import { selectStyles } from './styles';
import { CountryCode, CountryCodes } from '../../constants/country';

interface CountrySelectProps {
  id?: string;
  onChange: (value: CountryCode | null) => void;
  required?: boolean;
}

// Noop to remove the seperator
const IndicatorSeparator = () => null;

export const CountrySelect: FC<CountrySelectProps> = ({
  id = 'country-select',
  onChange,
  required = true,
}) => {
  const { translate } = useTranslate();
  const handleChange = useCallback(
    (option: Option | null) => {
      onChange(option?.value || null);
    },
    [onChange],
  );
  const getOption = (countryCode) => ({
    label: translate(`country.${countryCode}`),
    value: countryCode,
  });

  // Alphabetically sorted, but with US first.
  const options: Option[] = [
    getOption(CountryCode.US),
    ...CountryCodes.filter(
      (countryCode) =>
        // Prevent United Kingdom from showing up twice
        countryCode !== CountryCode.US && countryCode !== CountryCode.UK,
    )
      .map(getOption)
      .sort((a, b) => (a.label < b.label ? -1 : 1)),
  ];

  return (
    <Select
      onChange={handleChange}
      options={options}
      placeholder={translate('country')}
      isSearchable={false}
      components={{ IndicatorSeparator }}
      styles={selectStyles}
      id={id}
      required={required}
    />
  );
};
