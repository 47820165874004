import { useGate } from 'statsig-react';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_fusion_immediate_crisis_support
// Feature flag to enable new immediate crisis support as part of Fusion Project
export const KEY = 'b2b_dashboard_fusion_immediate_crisis_support';

export const useFusionImmediateCrisisSupport = (): boolean[] => {
  const { value, isLoading } = useGate(KEY);

  return [value, isLoading];
};
