import {
  WarmGrey,
  borderRadiusM,
  rem,
  cssWithMq,
  Orange,
  letterSpacingTight,
  SpacingInRemBySize,
  lineHeightBody,
  fontSizeXL,
  fontSize5XL,
  fontSizeS,
  fontSize3XL,
} from '@headspace/web-ui-components';

export const mainContainerCss = cssWithMq({
  alignItems: 'center',
  backgroundColor: [WarmGrey[100], 'white', 'white'],
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: ['column', 'column', 'row'],
  gap: [0, '32px', rem(15.75)],
  justifyContent: 'center',
  padding: `${rem(1.25)} ${rem(1.25)} 0`,
  width: '100vw',
});

export const moduleContainerCss = cssWithMq({
  backgroundColor: [WarmGrey[100]],
  borderRadius: ['none', borderRadiusM],
  boxSizing: 'border-box',
  padding: [SpacingInRemBySize.L, rem(2.25)],
  width: ['100%', rem(26.313)],
  marginBottom: [0, rem(3.375), rem(3)],
});

export const moduleHeaderCss = cssWithMq({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: SpacingInRemBySize.M,
  h2: {
    color: WarmGrey['700'],
    margin: 0,
  },

  marginBottom: SpacingInRemBySize.L,
});

export const dividerCss = cssWithMq({
  margin: `${SpacingInRemBySize.XL} 0`,
});

export const sectionBodyCss = cssWithMq({
  display: 'flex',
  gap: `${SpacingInRemBySize.S} ${SpacingInRemBySize.M}`,
  marginTop: SpacingInRemBySize.M,
});

export const sectionBoxCss = cssWithMq({
  width: rem(16.688),
});

export const sectionTextCss = cssWithMq({
  color: WarmGrey['700'],
  lineHeight: lineHeightBody,
  margin: 0,
});

export const sectionDisclaimerCss = cssWithMq(sectionTextCss, {
  fontSize: fontSizeS,
  marginBottom: SpacingInRemBySize.M,
  marginTop: SpacingInRemBySize.L,
});

export const ctaButtonCss = cssWithMq({
  letterSpacing: letterSpacingTight,
});

export const desktopContainerCss = cssWithMq({
  display: ['none', 'block', 'block'],
  maxWidth: ['auto', rem(34.563), rem(26.875)],
  textAlign: ['center', 'center', 'left'],
});

export const desktopTitleStyle = cssWithMq({
  '::before': {
    backgroundColor: Orange['200'],
    borderRadius: rem(1.875),
    content: '""',
    display: ['none', 'none', 'block'],
    height: SpacingInRemBySize['2XS'],
    left: 0,
    position: 'absolute',
    top: `-${SpacingInRemBySize['2XL']}`,
    width: rem(19.938),
  },
  color: WarmGrey['800'],
  fontSize: [undefined, fontSize3XL, fontSize5XL],
  fontWeight: 'bold',
  letterSpacing: letterSpacingTight,
  margin: 0,
  position: 'relative',
});

export const desktopSubtitleStyle = cssWithMq({
  color: WarmGrey['600'],
  fontSize: fontSizeXL,
  lineHeight: lineHeightBody,
  marginBottom: 0,
  marginTop: [undefined, SpacingInRemBySize.M, SpacingInRemBySize.L],
});
