import { Action, ThunkAction } from '@reduxjs/toolkit';

import { resetError } from '../../utils/messageDefaults';
import { RootState } from '../types';
import {
  ManageOrganizationState,
  OrganizationActionsTypes,
} from '../types/organization';
import { handleChange } from './organizationActions';
import { orgInputsToSync } from './organizationActions/handleGingerIdInput';

export const handlePrimaryProductInput = (
  event: ExtendedEvent,
): ThunkAction<Promise<void>, RootState, void, Action> => {
  return async (dispatch, getState) => {
    const {
      manageOrganization: { gingerIdSynced },
    } = getState();

    if (gingerIdSynced) {
      const payload: Partial<ManageOrganizationState> = {
        gingerIdSynced: false,
      };

      [...orgInputsToSync, 'gingerId'].forEach((input) => {
        payload[input] = '';
        payload[`${input}Error`] = resetError();
      });

      dispatch({
        payload,
        type: OrganizationActionsTypes.HANDLE_CHANGE,
      });
    }

    dispatch(handleChange(event));
  };
};
