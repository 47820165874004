import { useState, useCallback } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { Spinner } from '@headspace/web-ui-components';
import { Container } from '../../../../../../shared-components/container/Container';
import { Panel } from '../../../../../../shared-components/Panel';
import { containerCentering, panelStyle } from '../../styles/styles';
import { PanelTitleSection } from './PanelTitleSection';
import { PanelTableSection } from './PanelTableSection';
import { SurveyConfirmationModal } from './SurveyModal/SurveyConfirmationModal';
import { SurveySentModal } from './SurveyModal/SurveySentModal';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../constants/featureFlags';
import { useFetchSurveys } from '../../../hooks/surveys/useFetchSurveys';
import { getOrgManagement } from '../../../../../../state/selectors/organizationSelectors';
import { useSendSurvey } from '../../../hooks/surveys/useSendSurvey';
import { useFetchTotalMembers } from '../../../hooks/surveys/useFetchTotalMembers';
import { translate } from '../../../../../../i18n/utils/translate';

export const Survey: React.FC = () => {
  const { orgId } = useSelector(getOrgManagement);
  const [surveyId, setSurveyId] = useState<null | string>(null);
  const [isConfirmationModalOpen, setConfirmationModal] = useState(false);
  const [isSurveySentModalOpen, setSurveySentModal] = useState(false);
  const [isSurveysEnable] = useFeatureFlag(FEATURE_FLAG.ENABLE_SURVEY);
  const { data: surveys, isFetching } = useFetchSurveys(orgId);
  const { data: totalActiveMembers } = useFetchTotalMembers(orgId);
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSuccess = async () => {
    // reset surveyId and modal
    setSurveyId(null);
    setConfirmationModal(false);

    // open survey sent modal
    setSurveySentModal(true);

    // refetch surveys
    await queryClient.fetchQuery({ queryKey: ['surveys'] });
  };

  const handleError = () => {
    setErrorMessage(
      translate('organizationSettings.survey.confirmationModal.errorMessage'),
    );
    setConfirmationModal(true);
  };

  const { mutate: sendSurvey } = useSendSurvey(handleSuccess, handleError);

  const handleSelectSurvey = useCallback(
    (selectedSurveyId) => {
      setSurveyId(selectedSurveyId);
      setConfirmationModal(true);
    },
    [setSurveyId],
  );

  const handleConfirmCloseModal = useCallback(() => {
    setSurveyId(null);
    setConfirmationModal(false);
    setErrorMessage('');
  }, [setConfirmationModal]);

  const handleSendSurvey = useCallback(() => {
    sendSurvey({ orgId, surveyId: surveyId as string });
  }, [orgId, surveyId, sendSurvey]);

  const handleCloseSurveySentModal = useCallback(() => {
    setSurveySentModal(false);
  }, [setSurveySentModal]);

  const hasSurveys = !!surveys.length;

  const selectedSurvey = hasSurveys
    ? surveys.find((survey) => survey.id === surveyId)
    : null;

  return (
    <div data-testid="survey-panel-container">
      {selectedSurvey && (
        <SurveyConfirmationModal
          survey={selectedSurvey}
          isOpen={isConfirmationModalOpen}
          onCloseModal={handleConfirmCloseModal}
          onSendSurvey={handleSendSurvey}
          totalActiveMembers={totalActiveMembers}
          errorMessage={errorMessage}
        />
      )}
      <SurveySentModal
        isOpen={isSurveySentModalOpen}
        onCloseModal={handleCloseSurveySentModal}
      />
      <Container>
        <Panel css={panelStyle}>
          {isFetching ? (
            <div css={containerCentering}>
              <Spinner />
            </div>
          ) : (
            <>
              <PanelTitleSection hasSurveys={hasSurveys} />
              {isSurveysEnable ? (
                <PanelTableSection
                  surveys={surveys}
                  handleSelectSurvey={handleSelectSurvey}
                  totalActiveMembers={totalActiveMembers}
                  isInflight={isFetching}
                />
              ) : null}
            </>
          )}
        </Panel>
      </Container>
    </div>
  );
};
