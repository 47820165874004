import { takeEvery } from 'redux-saga/effects';
import { MemberEnrollActionTypes } from '../actions/memberEnrollActions/memberEnrollActionTypes';
import { verifyEmailTokenSaga } from './verifyEmailSaga';

export function* waitToInitializeEmailTokenValidation() {
  yield takeEvery(
    MemberEnrollActionTypes.SET_EMAIL_TOKEN,
    verifyEmailTokenSaga,
  );
}
