import { FC } from 'react';
import {
  headerBoxContainer,
  headerStyle,
} from '../../styles/verificationStyles';

interface FormHeaderProps {
  title: string;
}

export const FormHeader: FC<FormHeaderProps> = ({ title }) => {
  return (
    <div css={headerBoxContainer}>
      <h3 css={headerStyle} data-testid="header-title">
        {title}
      </h3>
    </div>
  );
};
