import { ThunkAction } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { Dispatch } from 'redux';

import { OrganizationSettingsRoutes } from '../../apps/dashboard/constants/routes';
import { create } from '../../auth/Auth';
import { translate } from '../../i18n/utils/translate';
import { getOrganizations } from '../../rest';
import { FETCH_ORGANIZATION_LIMIT } from '../../utils/constants';
import { GetState, RootState } from '../types';
import { ModalActionsTypes } from '../types/modal';
import {
  CreateOrganizationAction,
  FetchOrganizationsAction,
  FetchOrganizationsQuery,
  OrganizationsActionsTypes,
  UpdateOrganizationsAction,
} from '../types/organizations';
import { Organization } from '../../types/organization';
import { router } from '../../root-component/router';

const auth = create();
const getErrStatus = R.path(['response', 'status']);

export const error = ({
  result,
  dispatch,
}: {
  result: any;
  dispatch: Dispatch;
}) => {
  const CODE_UNAUTHORIZED = 401;
  const is401 = getErrStatus(result) === CODE_UNAUTHORIZED;
  // TODO rewrite it with different text for correspondent error code
  const errorText = is401
    ? translate('validationErrors.unauthorized')
    : result.message;
  // TODO need refactor. Move error handling on separate function and apply it on component layer
  dispatch({
    type: ModalActionsTypes.OPEN_MODAL,
    payload: { message: errorText },
  });
  dispatch({
    type: OrganizationsActionsTypes.RECEIVED_ORGANIZATIONS,
    payload: { OrganizationList: [] },
  });
  dispatch({
    type: OrganizationsActionsTypes.IS_FETCHING_ORGANIZATIONS,
    payload: { isFetching: false },
  });

  is401 && auth.logout();
};

export const fetchOrganizationsAction = (options: {
  query?: FetchOrganizationsQuery;
  refresh: boolean;
}): ThunkAction<Promise<void>, RootState, void, FetchOrganizationsAction> => {
  const { refresh, query = {} } = options;

  // eslint-disable-next-line func-names
  return async (dispatch: Dispatch, getState: GetState) => {
    const {
      organizations: { OrganizationList, totalOrgCount },
    } = getState();
    if (refresh || !OrganizationList.length) {
      dispatch(isFetchingOrganizationsAction(true));

      const { offset, search = '', primaryProduct } = query;
      let newQuery = `?limit=${FETCH_ORGANIZATION_LIMIT}`;
      if (offset) newQuery += `&offset=${offset}`;
      if (search) newQuery += `&name=${encodeURIComponent(search)}`;
      if (primaryProduct?.length)
        newQuery += `&primaryProduct=${encodeURIComponent(
          primaryProduct.join(','),
        )}`;

      let result;
      try {
        result = await getOrganizations(newQuery);
      } catch (err) {
        error({ dispatch, result });
      }

      if (result.data) {
        const { orgs, total } = result.data;

        dispatch(isFetchingOrganizationsAction(false));
        dispatch(receivedOrganizationsAction(orgs, total, query.offset));
      } else {
        error({ dispatch, result });
      }
    } else {
      dispatch(receivedOrganizationsAction(OrganizationList, totalOrgCount));
    }
  };
};

export const UpdateOrganizations = (
  OrganizationList: Organization[],
): UpdateOrganizationsAction => {
  return {
    type: OrganizationsActionsTypes.UPDATE_ORGANIZATIONS,
    payload: { OrganizationList },
  };
};

export const createOrganizationAction = (): CreateOrganizationAction => {
  router.navigate(`/create-org`);
  return {
    type: OrganizationsActionsTypes.CREATE_ORGANIZATION,
    payload: null,
  };
};

export const selectOrganizationAction = (
  orgId: number,
): CreateOrganizationAction => {
  router.navigate(OrganizationSettingsRoutes.MEMBERS(orgId));
  return {
    type: OrganizationsActionsTypes.SELECT_ORGANIZATION,
    payload: null,
  };
};

export const searchOrganizationsAction = (searchTerm: string) => {
  return {
    payload: { searchTerm },
    type: OrganizationsActionsTypes.SEARCH_ORGANIZATIONS,
  };
};

export const isFetchingOrganizationsAction = (isFetching: boolean) => {
  return {
    type: OrganizationsActionsTypes.IS_FETCHING_ORGANIZATIONS,
    payload: { isFetching },
  };
};
export const receivedOrganizationsAction = (orgs, total, offset?) => {
  return {
    type: OrganizationsActionsTypes.RECEIVED_ORGANIZATIONS,
    payload: {
      OrganizationList: orgs,
      offset,
      totalOrgCount: total,
    },
  };
};
