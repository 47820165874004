import { Admin } from '../../models/organization/Admin';
import {
  OrganizationActionsTypes,
  ClearAdminAction,
} from '../../types/organization';

const { CLEAR_ADMIN } = OrganizationActionsTypes;

export const clearAdmin = (payload: {
  org_admins: Admin[];
  adminReviewView: boolean;
}): ClearAdminAction => {
  return {
    type: CLEAR_ADMIN,
    payload: {
      admin_email: '',
      admin_name: '',
      admin_title: '',
      adminEditExisting: false,
      ...payload,
    },
  };
};
