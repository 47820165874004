import { useEffect } from 'react';

export const useOutsideClick = (handler: (arg0: any) => void) => {
  useEffect(() => {
    document.addEventListener('mouseup', handler);
    return () => {
      document.removeEventListener('mouseup', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
