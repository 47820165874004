import { PaymentActionTypes } from '../../actions/paymentActions/paymentActionTypes';

const INITIAL_STATE = {
  data: null,
  error: null,
  isLoading: false,
};

export function voucherInfoReducer(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case PaymentActionTypes.GET_VOUCHER_INFO:
      return { ...state, isLoading: true };
    case PaymentActionTypes.GET_VOUCHER_INFO_SUCCESS:
      return { ...state, data: action.payload.data.data, isLoading: false };
    case PaymentActionTypes.GET_VOUCHER_INFO_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
}

export type VoucherInfoState = ReturnType<typeof voucherInfoReducer>;
