import * as R from 'ramda';
import { noop } from '../../utils';
import { rowStyle } from './styles';
import { TableRow } from './Row';

const stringToLower = (val: string): string => {
  if (typeof val !== 'string') return val;
  return R.toLower(val === void 0 || val === null ? '' : val);
};

export const sortRows = (sort: string[], rows: RowProps[]): RowProps[] => {
  const [key, dir] = sort;
  const nestedIndex = R.compose(
    stringToLower,
    // @ts-ignore
    R.prop(key),
    R.prop('sortIndex'),
  );
  const sortByKeyDir = R.sortBy(nestedIndex);
  const sortedRows = sortByKeyDir(rows);
  return dir === 'ASC' ? sortedRows : R.reverse(sortedRows);
};

export const filterRowsByColumnValues = (
  columnFilterValues: any[],
  rows: RowProps[],
): RowProps[] => {
  const columnFilters = R.toPairs(columnFilterValues);
  if (!columnFilters.length) return rows;
  return filterRowsByColumnValues(
    columnFilters.slice(1),
    rows.filter((row: any) => {
      const { sortIndex } = row;
      const [column, include] = columnFilters[0];
      const filterWith = R.prop(column, sortIndex);
      return include.includes(filterWith);
    }),
  );
};

export const filterRowsByString = (
  filterOn: string,
  rows: RowProps[],
): RowProps[] => {
  return rows.filter((row) => {
    // @ts-ignore
    const { sortIndex } = row;
    return (
      R.prop('name', sortIndex) &&
      sortIndex.name
        .toLowerCase()
        .match(
          filterOn.toLowerCase().replace(/[\[\\\^\$\.\|\?\*\+\(\)]/g, '\\$&'),
        )
    );
  });
};

export const createIdArray = R.pluck('id');

export const rendererRow = (data: any) => {
  const {
    rowData: { onRowClick, icon },
  } = data;

  return (
    <div
      className="rowWrapper"
      onClick={onRowClick}
      role="button"
      onKeyDown={noop}
      tabIndex={0}
      key={data.key}
      css={rowStyle({ icon })}
      data-testid={data.rowData.sortIndex.name}
    >
      <TableRow {...data} />
    </div>
  );
};

export const selectRow = (selected: number[], id: number) => {
  return selected.includes(id)
    ? selected.filter((selectedId) => selectedId !== id)
    : selected.concat(id);
};

export const selectAllRows = (
  allRowIds: string[],
  selectedRowIds: string[],
) => {
  return allRowIds.length === selectedRowIds.length ? [] : allRowIds.slice();
};

export interface AllyProps {
  [key: string]: string | number | ((event: Event) => void);
}

interface BuildAllyPropsOptions {
  index: number;
  rowData: { [key: string]: any | unknown };
  onRowClick?({
    event,
    index,
    rowData,
  }: {
    event: Event;
    index: number;
    rowData: any;
  }): void;
  onRowDoubleClick?({
    event,
    index,
    rowData,
  }: {
    event: Event;
    index: number;
    rowData: any;
  }): void;
  onRowMouseOut?({
    event,
    index,
    rowData,
  }: {
    event: Event;
    index: number;
    rowData: any;
  }): void;
  onRowMouseOver?({
    event,
    index,
    rowData,
  }: {
    event: Event;
    index: number;
    rowData: any;
  }): void;
  onRowRightClick?({
    event,
    index,
    rowData,
  }: {
    event: Event;
    index: number;
    rowData: any;
  }): void;
}

/**
 * Helper to build props for a11y and handlers on table rows.
 * @param {Object} BuildAllyPropsOptions
 */
export const buildAllyProps = ({
  index,
  rowData,
  onRowClick,
  onRowDoubleClick,
  onRowMouseOut,
  onRowMouseOver,
  onRowRightClick,
}: BuildAllyPropsOptions): AllyProps => {
  const a11yProps: AllyProps = { 'aria-rowindex': index + 1 };

  if (
    onRowClick ||
    onRowDoubleClick ||
    onRowMouseOut ||
    onRowMouseOver ||
    onRowRightClick
  ) {
    a11yProps['aria-label'] = 'row';
    a11yProps.tabIndex = 0;
  }

  if (onRowClick) {
    a11yProps.onClick = (event: Event) => onRowClick({ event, index, rowData });
  }
  if (onRowDoubleClick) {
    a11yProps.onDoubleClick = (event: Event) =>
      onRowDoubleClick({ event, index, rowData });
  }
  if (onRowMouseOut) {
    a11yProps.onMouseOut = (event: Event) =>
      onRowMouseOut({ event, index, rowData });
  }
  if (onRowMouseOver) {
    a11yProps.onMouseOver = (event: Event) =>
      onRowMouseOver({ event, index, rowData });
  }
  if (onRowRightClick) {
    a11yProps.onContextMenu = (event: Event) =>
      onRowRightClick({ event, index, rowData });
  }

  return a11yProps;
};
