import * as React from 'react';
import { Container } from '../../../../shared-components/container/Container';
import { ComposedRightPanel } from './RightPanel';

export interface ToolkitProps {
  children?: any;
}

export const Toolkit: React.SFC<ToolkitProps> = () => (
  <Container data-testid="toolkit-view">
    {
      // @ts-ignore
      <ComposedRightPanel />
    }
  </Container>
);
