import { css as emotionCss, css } from '@emotion/react';
import {
  borderRadiusXS,
  borderWidthXS,
  CoolGrey,
  fontSizeM,
  LegacyColors,
  lineHeightBody,
  Orange,
  rem,
  rgba,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  columnFlex,
  justifyContentCenter,
  justifyContentFlexEnd,
  justifyContentSpaceBetween,
  rowFlex,
} from '../../../../../styles/flex';

export const panelDisabledStyle = css({
  backgroundColor: rgba(White['000'], 0.4),
  borderRadius: borderRadiusXS,
  bottom: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
});

export const panelConfirmStyle = css(
  columnFlex,
  alignItemsCenter,
  justifyContentCenter,
  {
    ' .goBack button': {
      backgroundColor: WarmGrey[100],
    },
    backgroundColor: rgba(White['000'], 0.9),
    borderRadius: borderRadiusXS,
    bottom: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 2,
  },
);

export const panelEditContainerStyle = css(
  rowFlex,
  justifyContentSpaceBetween,
  {
    borderTop: `${borderWidthXS} solid ${CoolGrey[100]}`,
    marginTop: SpacingInRemBySize.L,
    paddingTop: rem(1.625),
  },
);

export const panelEditCancelStyle = css({
  ':hover': {
    backgroundColor: `${WarmGrey[100]} !important`,
    color: `${Orange[200]} !important`,
  },
  backgroundColor: `${White['000']} !important`,
  color: `${Orange[200]} !important`,
  marginRight: SpacingInRemBySize.M,
});

export const panelEditActionsStyle = css(
  rowFlex,
  alignItemsCenter,
  justifyContentFlexEnd,
);

export const panelConfirmButtonsStyle = css(
  rowFlex,
  alignItemsCenter,
  justifyContentCenter,
  {
    marginTop: SpacingInRemBySize['2XL'],
  },
);

export const panelConfirmMessageStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeM,
  lineHeight: lineHeightBody,
  margin: SpacingInRemBySize.XL,
  marginTop: SpacingInRemBySize.XS,
  textAlign: 'center',
});

export const disablePointerEvents = emotionCss({
  pointerEvents: 'none',
});

export const panelCancelStyle = emotionCss({
  ':hover': {
    backgroundColor: `${WarmGrey['100']} !important`,
    color: `${Orange[200]} !important`,
  },
  backgroundColor: `${White['000']} !important`,
  color: `${Orange[200]} !important`,
  marginRight: SpacingInRemBySize.M,
});
