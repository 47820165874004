import { flatten } from 'flat';
import { en } from './en';
import { fr } from './fr';
import { de } from './de';
import { es_419 } from './es_419';
import { pt_br } from './pt_BR';
import { cs } from './cs';
import { hi } from './hi';
import { it } from './it';
import { hu } from './hu';
import { ja } from './ja';
import { ms } from './ms';
import { nl } from './nl';
import { pl } from './pl';
import { ru } from './ru';
import { zh_CN } from './zh_CN';

export interface DashboardMessagesKeys {
  [key: string]: Record<string, string>;
}

// Note: react-intl doesn't support nested messages
export const dashboardMessages: DashboardMessagesKeys = {
  de: flatten({
    ...de.translation,
    ...de.memberEnrollmentV2,
    ...de.memberEnrollmentV3,
    ...de.headspaceHub,
    ...de.webAuth,
  }),
  en: flatten({
    ...en.translation,
    ...en.memberEnrollmentV2,
    ...en.memberEnrollmentV3,
    ...en.headspaceHub,
    ...en.webAuth,
  }),
  es: flatten({
    ...es_419.translation,
    ...es_419.memberEnrollmentV2,
    ...es_419.memberEnrollmentV3,
    ...es_419.headspaceHub,
    ...es_419.webAuth,
  }),
  fr: flatten({
    ...fr.translation,
    ...fr.memberEnrollmentV2,
    ...fr.memberEnrollmentV3,
    ...fr.headspaceHub,
    ...fr.webAuth,
  }),
  pt: flatten({
    ...pt_br.translation,
    ...pt_br.memberEnrollmentV2,
    ...pt_br.memberEnrollmentV3,
    ...pt_br.headspaceHub,
    ...pt_br.webAuth,
  }),
  cs: {
    ...cs.translation,
    ...cs.memberEnrollmentV2,
    ...cs.memberEnrollmentV3,
    ...cs.headspaceHub,
    ...cs.webAuth,
  },
  hi: {
    ...hi.translation,
    ...hi.memberEnrollmentV2,
    ...hi.memberEnrollmentV3,
    ...hi.headspaceHub,
    ...hi.webAuth,
  },
  hu: {
    ...hu.translation,
    ...hu.memberEnrollmentV2,
    ...hu.memberEnrollmentV3,
    ...hu.headspaceHub,
    ...hu.webAuth,
  },
  it: {
    ...it.translation,
    ...it.memberEnrollmentV2,
    ...it.memberEnrollmentV3,
    ...it.headspaceHub,
    ...it.webAuth,
  },
  ja: {
    ...ja.translation,
    ...ja.memberEnrollmentV2,
    ...ja.memberEnrollmentV3,
    ...ja.headspaceHub,
    ...ja.webAuth,
  },
  ms: {
    ...ms.translation,
    ...ms.memberEnrollmentV2,
    ...ms.memberEnrollmentV3,
    ...ms.headspaceHub,
    ...ms.webAuth,
  },
  nl: {
    ...nl.translation,
    ...nl.memberEnrollmentV2,
    ...nl.memberEnrollmentV3,
    ...nl.headspaceHub,
    ...nl.webAuth,
  },
  pl: {
    ...pl.translation,
    ...pl.memberEnrollmentV2,
    ...pl.memberEnrollmentV3,
    ...pl.headspaceHub,
    ...pl.webAuth,
  },
  ru: {
    ...ru.translation,
    ...ru.memberEnrollmentV2,
    ...ru.memberEnrollmentV3,
    ...ru.headspaceHub,
    ...ru.webAuth,
  },
  zh_CN: {
    ...zh_CN.translation,
    ...zh_CN.memberEnrollmentV2,
    ...zh_CN.memberEnrollmentV3,
    ...zh_CN.headspaceHub,
    ...zh_CN.webAuth,
  },
};

export const webAuthMessages: DashboardMessagesKeys = {
  en: en.webAuth,
  cs: cs.webAuth,
  de: de.webAuth,
  es: es_419.webAuth,
  fr: fr.webAuth,
  hi: hi.webAuth,
  hu: hu.webAuth,
  it: it.webAuth,
  ja: ja.webAuth,
  ms: ms.webAuth,
  nl: nl.webAuth,
  pl: pl.webAuth,
  pt: pt_br.webAuth,
  ru: ru.webAuth,
  zh_CN: zh_CN.webAuth,
};
