import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { headspaceHubRoutes } from './constants/routes';
import SpinnerWrapper from './views/shared-components/Spinner/Spinner';
import { PrivateHubRoute } from './PrivateHubRoute';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../constants/featureFlags';

const HeadspaceHubHomepage = lazy(
  () => import('./views/HeadspaceHubHomepage/HeadspaceHubHomepage'),
);

const HomepageErrorPage = lazy(
  () => import('./views/ExceptionPages/HomepageErrorPage'),
);

const HsAppDetailsPage = lazy(
  () => import('./views/HsAppDetailsPage/HsAppDetailsPage'),
);

const CrisisSupportPage = lazy(
  () => import('./views/CrisisSupportPage/CrisisSupportPage'),
);

const CrisisSupportErrorPage = lazy(
  () => import('./views/ExceptionPages/CrisisSupportErrorPage'),
);

const FamilyResourcePage = lazy(
  () => import('./views/FamilyResourcesPage/FamilyResourcesPage'),
);

const InPersonTherapyPage = lazy(
  () => import('./views/TherapyPages/InPersonTherapy'),
);

const VirtualTherapyPage = lazy(
  () => import('./views/TherapyPages/VirtualTherapy'),
);

const SupportForManagersPage = lazy(
  () => import('./views/SupportForManagersPage/SupportForManagersPage'),
);

const ActivityPage = lazy(() => import('./views/ActivityPage/ActivityPage'));

const ActivityErrorPage = lazy(
  () => import('./views/ExceptionPages/ActivityErrorPage'),
);

const WorkLifeServicesPage = lazy(
  () => import('./views/WorkLifeServicesPage/WorkLifeServicesPage'),
);

const FormPage = lazy(() => import('./views/Form/FormPage/FormPage'));

const IntroPage = lazy(() => import('./views/Form/IntroPage/IntroPage'));

const ServiceTypePage = lazy(
  () => import('./views/Form/ServiceTypePage/ServiceTypePage'),
);

const FinishPage = lazy(() => import('./views/Form/FinishPage/FinishPage'));

const ServiceIntroPage = lazy(
  () => import('./views/Form/IntroPage/ServiceIntroPage'),
);

const NoAccessErrorPage = lazy(
  () => import('./views/ExceptionPages/NoAccessErrorPage'),
);

const PageNotFound = lazy(() => import('../dashboard/PageNotFoundView'));

const DetailErrorPage = lazy(
  () => import('./views/ExceptionPages/DetailErrorPage'),
);

export const HeadspaceHubRoutes = () => {
  const { pathname } = useLocation();
  const [isHubAccessForIntlEnable] = useFeatureFlag(
    FEATURE_FLAG.HUB_ACCESS_FOR_INTL_CARE_PACKAGE,
  );

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<SpinnerWrapper />}>
      <Routes>
        {/* Homepage Routes */}
        <Route
          path={headspaceHubRoutes.HOME_PATH}
          element={
            <PrivateHubRoute>
              <HeadspaceHubHomepage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.HOMEPAGE_ERROR_PATH}
          element={
            <PrivateHubRoute>
              <HomepageErrorPage />
            </PrivateHubRoute>
          }
        />
        {/* App Details Routes */}
        <Route
          path={headspaceHubRoutes.APP_DETAILS_PATH}
          element={
            <PrivateHubRoute>
              <HsAppDetailsPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.APP_DETAILS_ERROR_PATH}
          element={
            <PrivateHubRoute>
              <DetailErrorPage />
            </PrivateHubRoute>
          }
        />

        {/* Crisis Support Routes */}
        <Route
          path={headspaceHubRoutes.CRISIS_SUPPORT_PATH}
          element={
            <PrivateHubRoute>
              <CrisisSupportPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.CRISIS_SUPPORT_ERROR_PATH}
          element={
            <PrivateHubRoute>
              <CrisisSupportErrorPage />
            </PrivateHubRoute>
          }
        />

        {/* Family Resources Routes */}
        <Route
          path={headspaceHubRoutes.FAMILY_RESOURCES_PATH}
          element={
            <PrivateHubRoute>
              <FamilyResourcePage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.FAMILY_RESOURCES_ERROR_PATH}
          element={
            <PrivateHubRoute>
              <DetailErrorPage />
            </PrivateHubRoute>
          }
        />

        {/* Therapy and Manager Services Routes */}
        <Route
          path={headspaceHubRoutes.IN_PERSON_THERAPY_PATH}
          element={
            <PrivateHubRoute>
              <InPersonTherapyPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.IN_PERSON_THERAPY_ERROR_PATH}
          element={
            <PrivateHubRoute>
              <DetailErrorPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.MANAGER_SERVICES_PATH}
          element={
            <PrivateHubRoute>
              <SupportForManagersPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.MANAGER_SERVICES_ERROR_PATH}
          element={
            <PrivateHubRoute>
              <DetailErrorPage />
            </PrivateHubRoute>
          }
        />

        {/* Activity and Work-Life Services Routes */}
        <Route
          path={headspaceHubRoutes.ACTIVITY_PATH}
          element={
            <PrivateHubRoute>
              <ActivityPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.ACTIVITY_ERROR_PATH}
          element={
            <PrivateHubRoute>
              <ActivityErrorPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.WORK_LIFE_SERVICES_PATH}
          element={
            <PrivateHubRoute>
              <WorkLifeServicesPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.WORK_LIFE_SERVICES_ERROR_PATH}
          element={
            <PrivateHubRoute>
              <DetailErrorPage />
            </PrivateHubRoute>
          }
        />

        {/* Service Request Routes */}
        <Route
          path={headspaceHubRoutes.SERVICE_REQUEST_PATH}
          element={
            <PrivateHubRoute>
              <FormPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.INTAKE_FORM_PATH}
          element={
            <PrivateHubRoute>
              <IntroPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.SERVICE_REQUEST_PAGE_PATH}
          element={
            <PrivateHubRoute>
              <ServiceTypePage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.SERVICE_REQUEST_FINISH_PATH}
          element={
            <PrivateHubRoute>
              <FinishPage />
            </PrivateHubRoute>
          }
        />
        <Route
          path={headspaceHubRoutes.WORK_LIFE_SERVICES_INTAKE_PATH}
          element={
            <PrivateHubRoute>
              <ServiceIntroPage />
            </PrivateHubRoute>
          }
        />

        {/* Other Routes */}
        <Route
          path={headspaceHubRoutes.NO_ACCESS_ERROR_PATH}
          element={
            <PrivateHubRoute>
              <NoAccessErrorPage />
            </PrivateHubRoute>
          }
        />

        <Route element={<PageNotFound />} />
        {isHubAccessForIntlEnable && (
          <>
            <Route
              path={headspaceHubRoutes.VIRTUAL_THERAPY_PATH}
              element={
                <PrivateHubRoute>
                  <VirtualTherapyPage />
                </PrivateHubRoute>
              }
            />
            <Route
              path={headspaceHubRoutes.VIRTUAL_THERAPY_ERROR_PATH}
              element={
                <PrivateHubRoute>
                  <DetailErrorPage />
                </PrivateHubRoute>
              }
            />
            <Route
              path={headspaceHubRoutes.INTAKE_VIRTUAL_FORM_PATH}
              element={
                <PrivateHubRoute>
                  <IntroPage />
                </PrivateHubRoute>
              }
            />
          </>
        )}
      </Routes>
    </Suspense>
  );
};
