import { router } from '../../../../root-component/router';

export const getLinks = ({ orgId, hasChallenges, isCSM, pathname }) => {
  const featureFlaggedTab =
    isCSM || hasChallenges
      ? [
          {
            display: 'Challenges',
            action: () => router.navigate(`/orgs/${orgId}/challenges`),
            selected: pathname === `/orgs/${orgId}/challenges`,
          },
        ]
      : [];

  const tabs = [
    {
      display: 'Members',
      action: () => router.navigate(`/orgs/${orgId}/members`),
      selected: pathname === `/orgs/${orgId}/members`,
    },
    {
      display: 'Insights',
      action: () => router.navigate(`/orgs/${orgId}/insights`),
      selected: pathname === `/orgs/${orgId}/insights`,
    },
    {
      display: 'Settings',
      action: () => router.navigate(`/orgs/${orgId}/settings`),
      selected: pathname === `/orgs/${orgId}/settings`,
    },
    {
      display: 'Toolkit',
      action: () => router.navigate(`/orgs/${orgId}/toolkit`),
      selected: pathname === `/orgs/${orgId}/toolkit`,
    },
  ];

  return tabs.concat(featureFlaggedTab);
};
