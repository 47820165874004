import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../types';
import {
  initialContractFamilyMembersPerEmployeeValue,
  initialContractNumberOfFamilyMembersSeatsValue,
} from '../../../reducers/manageOrganizationReducer';
import { handleChange } from '../handleChange';
import { processErrors } from '../processErrors';

export const handleFamilyMembersPerEmployeeChangeFactory = (
  handleChangeThunk: typeof handleChange,
  term: string,
) => (event: ExtendedEvent): ThunkAction<void, RootState, unknown, Action> => (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
): void => {
  const { value: updatedValue } = event.target;

  dispatch(handleChangeThunk(event));
  if (updatedValue === initialContractFamilyMembersPerEmployeeValue) {
    dispatch(
      handleChangeThunk({
        target: {
          id: `${term}_contract_number_of_family_members_seats`,
          value: initialContractNumberOfFamilyMembersSeatsValue,
        },
      }),
    );
  }
  dispatch(processErrors(event));
};
