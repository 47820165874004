import axios, { AxiosPromise } from 'axios';
import {
  API_HOST,
  GET,
  POST,
  ONBOARDING_SURVEY_ID,
  createHeaders,
} from '../../../utils/constants';
import {
  GetSurveyQuestionResponse,
  SurveyAnswers,
  PostAnswerResponse,
} from '../models/OnboardingQuestions';
import { EnrollmentVerification } from '../constants/enrollmentVerification';
import { OfferingType } from '../../../types/organization';

export interface GetOnboardingQuestions {
  (JWT: string): AxiosPromise<GetSurveyQuestionResponse>;
}

export const getOnboardingQuestions: GetOnboardingQuestions = (JWT) =>
  axios({
    headers: createHeaders(JWT),
    method: GET,
    url: `${API_HOST}/survey/v1/surveys/${ONBOARDING_SURVEY_ID}`,
  });

export interface PostOnboardingAnswers {
  (
    JWT: string,
    onboardingResponse: SurveyAnswers,
  ): AxiosPromise<PostAnswerResponse>;
}

export const postOnboardingAnswers: PostOnboardingAnswers = (
  JWT,
  onboardingResponse,
) =>
  axios({
    headers: createHeaders(JWT),
    method: POST,
    url: `${API_HOST}/survey/v1/surveys/${ONBOARDING_SURVEY_ID}/responses`,
    data: onboardingResponse,
  });

export interface PostOnboardingComplete {
  (
    JWT: string,
    slug: string,
    onboardingResult: {
      hsUserId: string;
      enrollmentType: EnrollmentVerification;
      // this should be whichever product was recommended
      recommendedOffering: OfferingType;
    },
  ): AxiosPromise<unknown>;
}

export const postOnboardingComplete: PostOnboardingComplete = (
  JWT,
  slug,
  onboardingResult,
) =>
  axios({
    headers: createHeaders(JWT),
    method: POST,
    url: `${API_HOST}/b2b/v3/orgs/${slug}/enrollments/onboarding-results`,
    data: onboardingResult,
  });
