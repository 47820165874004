import * as React from 'react';
import Finish from '../../FinishPage/FinishPage';
import { HealthProvider } from '../../../constants/healthProvider';
import { PageWrapper } from '../VirginPulse/PageWrapper/PageWrapper';

export const SoleraFinishPage: React.FC = () => {
  return (
    <PageWrapper healthProvider={HealthProvider.SOLERA} showJoinMessage={false}>
      <Finish />
    </PageWrapper>
  );
};
