import { css } from '@emotion/core';
import {
  cssWithMq,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { BreakPoint } from '../constants';

export const challengeCreateWrapperCss = cssWithMq({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: [SpacingInRemBySize.M, 0, 0],
  width: '100%',
  [BreakPoint.Tablet]: {
    backgroundImage: `none`,
  },
});

export const challengeCreateHeaderCss = cssWithMq({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: [
    SpacingInRemBySize.L,
    SpacingInRemBySize.XL,
    SpacingInRemBySize.XL,
  ],
  width: '100%',
});

export const challengeCreateLogoCss = cssWithMq({
  paddingLeft: [0, SpacingInRemBySize.L, SpacingInRemBySize.L],
  paddingTop: [0, SpacingInRemBySize.L, SpacingInRemBySize.L],
});

export const challengeCreateCrossCss = cssWithMq({
  marginRight: [0, SpacingInRemBySize.L, SpacingInRemBySize.L],
  marginTop: [0, SpacingInRemBySize.M, SpacingInRemBySize.M],
});

export const challengeCreateFormCss = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  maxWidth: rem(40),
});

export const challengeCreateFormTitleCss = css({
  display: 'flex',
  justifyContent: 'space-around',
});

export const challengeCreateFormSubtitleCss = cssWithMq({
  color: WarmGrey[600],
  fontSize: [rem(0.9), rem(1), rem(1)],
  lineHeight: 1.2,
  margin: `${SpacingInRemBySize.XS} 0`,
  textAlign: 'center',
});

export const challengeCreateFormButtonCss = cssWithMq({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: [0, SpacingInRemBySize.L, SpacingInRemBySize.L],
  width: ['100%', 'inherit', 'inherit'],
});

export const challengeCreateContainer = css({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
});

export const createChallengeWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: SpacingInRemBySize['3XL'],
});

export const createChallengeFlexContainer = css({
  display: 'flex',
});

export const createChallengeLeftContainer = css({
  boxSizing: 'border-box',
  flexBasis: '50%',
  paddingRight: SpacingInRemBySize['2XS'],
});

export const createChallengeRightContainer = css({
  boxSizing: 'border-box',
  flexBasis: '50%',
  paddingLeft: SpacingInRemBySize['2XS'],
});
