import { requestDeauthenticate } from '@headspace/web-auth';
import { setCookie } from '@headspace/web-auth/dist/utils/cookie';
import { MemberEnrollActionTypes } from './memberEnrollActionTypes';
import { parseError } from '../../../../../utils/parseError';
import { HealthProvider } from '../../../constants/healthProvider';
import { Routes } from '../../../constants/routes';
import { Confirmation } from '../../reducers/enrollmentVerification/enrollmentVerificationReducer';
import { SupportedLanguageCode } from '../../../../../types/language';
import { getConfig } from '../../../../../config';

export const setEmailToken = (payload: string) => ({
  payload,
  type: MemberEnrollActionTypes.SET_EMAIL_TOKEN,
});

export const verifyTokenSuccess = (payload: any) => ({
  payload,
  type: MemberEnrollActionTypes.VERIFY_EMAIL_TOKEN_SUCCESS,
});

export const verifyTokenEmailFailed = (
  payload: ReturnType<typeof parseError>,
) => ({
  payload,
  type: MemberEnrollActionTypes.VERIFY_EMAIL_TOKEN_FAILED,
});

export const determineLoginRedirectLocation = (
  isSSOEnrollment = false,
  healthProvider?: string,
  pageName?: string,
) => ({
  type: MemberEnrollActionTypes.INITIALIZE_LOGIN_PAGE,
  payload: {
    healthProvider,
    isSSOEnrollment,
    pageName,
  },
});

export const initializeVerifyPage = () => ({
  type: MemberEnrollActionTypes.INITIALIZE_VERIFY_PAGE,
});

export const subscriptionDetailsSuccess = (res: any) => {
  return {
    payload: res,
    type: MemberEnrollActionTypes.MEMBER_SUBSCRIPTION_DETAILS_SUCCESS,
  };
};

export const setLanguage = (lang: SupportedLanguageCode) => {
  setCookie(
    'lang',
    lang,
    null,
    getConfig().ENROLLMENT_AUTH0_COOKIE.COOKIE_CONFIGURATION,
  );

  return {
    payload: {
      language: lang,
    },
    type: MemberEnrollActionTypes.SET_LANGUAGE,
  };
};

export const setHealthProvider = (
  healthProvider: HealthProvider | undefined,
) => {
  return {
    payload: {
      healthProvider,
    },
    type: MemberEnrollActionTypes.SET_HEALTH_PROVIDER,
  };
};

export const logoutAndRedirect = (url: string = Routes.HEADSPACE_LOGIN) =>
  requestDeauthenticate(url);

export const setEnrollmentVerificationConfirmation = (
  confirmation: Confirmation,
) => ({
  payload: {
    confirmation,
    loadedAt: new Date().toJSON(),
  },
  type: MemberEnrollActionTypes.SET_ENROLLMENT_VERIFICATION,
});

export const clearEnrollmentVerificationConfirmation = () => ({
  type: MemberEnrollActionTypes.CLEAR_ENROLLMENT_VERIFICATION,
});

export const setMemberDobAndCountryCode = (
  preferredDateOfBirth,
  preferredCountryCode,
) => ({
  payload: {
    preferredDateOfBirth,
    preferredCountryCode,
  },
  type: MemberEnrollActionTypes.SET_MEMBER_DOB_AND_COUNTRY_CODE,
});
