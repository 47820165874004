export const getSFTPProgress = (orgId) => {
  const item = localStorage.getItem(`SFTPUser:${orgId}`);

  return item ? +item : null;
};

export const setSFTPProgress = (orgId, step) => {
  localStorage.setItem(`SFTPUser:${orgId}`, step);
};

export const removeSFTPProgress = (orgId) => {
  localStorage.removeItem(`SFTPUser:${orgId}`);
};
