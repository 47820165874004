import {
  Blue,
  borderRadiusXS,
  borderWidthS,
  borderWidthXS,
  cssWithMq,
  fontFamilyCss,
  fontSize3XL,
  fontSizeM,
  fontSizeL,
  fontWeightBold,
  labelLCss,
  letterSpacingTight,
  lineHeightHeading,
  Orange,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
  rgba,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { boxSizingBorderBox } from '../../../../dashboard/styles';

const buttonFocusableStyle = css({
  ':focus': {
    outline: 'none',
  },
  ':not([disabled]):active': {
    backgroundColor: ['transparent !important'],
  },
  ':not([disabled]):hover': {
    backgroundColor: ['transparent'],
  },
});

export const navbarContainerStyles = cssWithMq(boxSizingBorderBox, {
  alignItems: 'center',
  backgroundColor: White['000'],
  display: 'flex',
  height: [rem(3.688), rem(5.125)],
  justifyContent: 'space-between',
  padding: [
    `0 ${SpacingInRemBySize.M} 0 ${SpacingInRemBySize.L}`,
    `0 ${SpacingInRemBySize.XL}`,
    `0 ${SpacingInRemBySize['3XL']}`,
  ],
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 10,
  boxShadow: `0 ${SpacingInRemBySize['3XS']} ${
    SpacingInRemBySize['2XS']
  } ${rgba(WarmGrey[800], 0.08)}`,
});

export const logoButtonStyle = css(buttonFocusableStyle, {
  ':focus': {
    border: `${borderWidthS} solid ${Blue[200]}`,
    borderRadius: borderRadiusXS,
  },
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  outline: 'none',
});

export const headSpaceLogoStyle = css({
  borderRight: `${borderWidthXS} solid ${WarmGrey[300]}`,
  paddingRight: SpacingInRemBySize.M,
});

export const orgContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: SpacingInRemBySize.M,
});

export const orgLogoStyle = css({
  maxHeight: rem(1.375),
  maxWidth: rem(6.25),
});

export const menuButtonStyle = css({
  backgroundColor: White['000'],
  borderRadius: 'initial',
  height: SpacingInRemBySize.L,
  width: SpacingInRemBySize.L,
});

export const menuContainerStyle = css({
  '*': {
    boxSizing: 'border-box',
  },
  backgroundColor: White['000'],
  bottom: 0,
  height: `calc(100vh - ${rem(1.5)})`,
  left: 0,
  overflow: 'scroll',
  position: 'absolute',
  right: 0,
  top: rem(3.688),
});

export const menuStyle = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: SpacingInRemBySize.M,
});

export const titleStyle = css(labelLCss, {
  color: WarmGrey[500],
  margin: 0,
});

export const navContainerStyle = css({
  padding: SpacingInRemBySize.L,
});

export const buttonStyle = cssWithMq(fontFamilyCss, {
  ':focus': {
    borderRadius: 'initial',
  },
  ':hover': {
    cursor: 'pointer',
  },
  alignItems: 'center',
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  border: 'none',
  borderRadius: 'initial',
  color: [WarmGrey[600], WarmGrey[700]],
  cursor: 'pointer',
  display: 'flex',
  fontSize: [fontSize3XL, fontSizeM, fontSizeL],
  fontWeight: fontWeightBold,
  height: '100%',
  justifyContent: 'center',
  letterSpacing: letterSpacingTight,
  lineHeight: lineHeightHeading,
  marginTop: [SpacingInRemBySize.M, 0],
  overflow: 'hidden',
  padding: 0,
  textAlign: 'left',
});

export const navPanelStyle = cssWithMq({
  alignItems: ['flex-start', 'center'],
  columnGap: [undefined, SpacingInRemBySize.L, SpacingInRemBySize['2XL']],
  display: 'flex',
  flexDirection: ['column', 'row'],
  paddingRight: SpacingInRemBySize.L,
});

export const tabsLinksStyle = (isSelected: boolean) =>
  cssWithMq({
    '::after': isSelected
      ? {
          backgroundColor: Orange[200],
          bottom: [0, `-${SpacingInRemBySize['2XS']}`],
          content: '""',
          height: SpacingInRemBySize['2XS'],
          left: 0,
          position: 'absolute',
          right: 0,
          width: '100%',
        }
      : undefined,
    position: 'relative',
  });

export const languageContainerStyle = css({
  alignItems: 'baseline',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
});

export const languageTitleStyle = (isLanguageMenuSeparated: boolean) =>
  css({
    color: WarmGrey[600],
    fontSize: fontSize3XL,
    fontWeight: fontWeightBold,
    letterSpacing: letterSpacingTight,
    marginTop: isLanguageMenuSeparated ? rem(1.813) : 0,
    marginBottom: '0 !important',
  });
