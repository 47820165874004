import { ChangeEventHandler, FC, FocusEventHandler, useCallback } from 'react';

import { ChildRestrictedInput } from '../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import { PRIMARY_PRODUCT } from '../../constants';

export interface GingerInputProps {
  label: string;
  gingerId: string;
  gingerIdError;
  handleChange: ChangeEventHandler;
  handleBlur: FocusEventHandler;
  handleError: (e: any) => void;
  placeholder: string;
  primaryProduct: string;
}

export const GingerIdInput: FC<GingerInputProps> = ({
  label,
  gingerId,
  gingerIdError,
  handleChange,
  handleBlur,
  handleError,
  placeholder,
  primaryProduct,
}) => {
  const onBlur = useCallback(
    (e) => {
      handleBlur(e);
      handleError(e);
    },
    [handleBlur, handleError],
  );

  if (primaryProduct !== PRIMARY_PRODUCT.FUSION) return null;

  return (
    <ChildRestrictedInput
      type="text"
      name="gingerId"
      placeholder={placeholder}
      label={label}
      onChange={handleChange}
      onBlur={onBlur}
      value={gingerId || ''}
      hasError={gingerIdError.error}
      errorMessage={gingerIdError.message}
      tabIndex="0"
      maxLength="255"
    />
  );
};
