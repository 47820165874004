import * as React from 'react';
import { Modal } from '@headspace/web-ui-components';
import { modalStyle, modalBodyStyle } from './styles';
import { useTranslate } from '../../../../../../hooks/useTranslate';

interface SFTPCompletionModalPropTypes {
  handleClose(): void;
}

export const SFTPCompletionModal: React.FC<SFTPCompletionModalPropTypes> = ({
  handleClose,
}) => {
  const { translate } = useTranslate();
  const body = (
    <div css={modalBodyStyle}>
      <div className="title">
        {translate('organizationSettingsSFTP.completionModal.title')}
      </div>
      <div className="copy">
        {translate('organizationSettingsSFTP.completionModal.body1')}
      </div>
      <div className="copy">
        {translate('organizationSettingsSFTP.completionModal.body2')}
      </div>
    </div>
  );

  return <Modal css={modalStyle} body={body} onClose={handleClose} />;
};
