export interface ApiError {
  title: string;
  status: number;
  detail: string;
  data: string | null;
}

export interface ErrorData {
  code?: string;
}

export interface ApiErrors {
  message: string;
  title?: string;
  response: {
    data: {
      errors: ApiError[];
    };
  };
}
export interface NetworkError {
  message: string;
  title?: string;
}

export type ErrorResponse = ApiErrors & NetworkError;

export interface ParsedError {
  message: string;
  errorStringCode: string | null;
  errorCode?: ErrorData | null;
  title?: string | null;
  status?: number;
}

export const parseError = (error: ErrorResponse): ParsedError => {
  // Potentially a good place to filter error types for Sentry Error logs.

  if (error.response) {
    const {
      detail,
      title,
      data = null,
      status,
    } = error.response.data.errors[0];

    return {
      errorStringCode: data,
      errorCode: (data as ErrorData) ?? null,
      message: detail,
      status,
      title,
    };
  }

  // Network error
  if (!error.response && error.message) {
    return {
      errorStringCode: null,
      message: error.message,
      title: error.title,
    };
  }

  return {
    errorStringCode: null,
    message: 'An Error has occurred.',
  };
};
