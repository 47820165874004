import { css } from '@emotion/react';
import {
  APERCU_FONT_NAME,
  borderWidthXS,
  CoolGrey,
  fontSizeM,
  fontSizeS,
  fontSizeXL,
  fontSizeXS,
  fontWeightBold,
  lineHeightBody,
  lineHeightHeading,
  Orange,
  Red,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { flex, justifyContentFlexEnd } from '../../../../../styles/flex';

export const modalBodyStyle = css({
  '& .content .copy': {
    fontSize: fontSizeS,
    lineHeight: lineHeightHeading,
    marginBottom: SpacingInRemBySize.L,
  },
  '& .content .title': {
    color: WarmGrey['800'],
    fontSize: fontSizeXS,
    lineHeight: SpacingInRemBySize.M,
    marginBottom: SpacingInRemBySize.XS,
  },
  '& .copy': {
    color: WarmGrey['700'],
    fontSize: fontSizeM,
    lineHeight: lineHeightBody,
    margin: 0,
  },
  '& .title': {
    color: CoolGrey[500],
    fontSize: fontSizeXL,
    fontWeight: fontWeightBold,
    lineHeight: rem(1.75),
    marginBottom: SpacingInRemBySize.XS,
    marginTop: 0,
  },
  fontFamily: APERCU_FONT_NAME,
  marginTop: SpacingInRemBySize.L,
  maxWidth: rem(22.75),
  minHeight: rem(26.875),
});

export const modalStyle = css({
  '> div:first-of-type': {
    backgroundColor: WarmGrey['600'],
    opacity: '0.53',
    position: 'fixed',
  },
});

export const modalFooterStyle = css(flex, justifyContentFlexEnd, {
  '& button': {
    border: `${borderWidthXS} solid ${Orange[200]}`,
    height: rem(1.75),
    width: rem(7.188),
  },
});

export const modalErrorMessageStyle = css({
  color: Red['300'],
  fontSize: rem(1),
  fontWeight: fontWeightBold,
  marginBottom: SpacingInRemBySize.M,
});
