import { css, SerializedStyles } from '@emotion/react';
import {
  APERCU_FONT_NAME,
  borderRadiusXS,
  borderWidthNone,
  borderWidthS,
  CoolGrey,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  Orange,
  rem,
  rgba,
  SpacingInRemBySize,
  White,
} from '@headspace/web-ui-components';

interface ButtonSizeStyle {
  topBottomPadding: number;
  leftRightPadding: number;
  lineHeight: number;
  fontSize: number;
}

export const buttonSizeStyle = (options: ButtonSizeStyle) => {
  const { topBottomPadding, leftRightPadding, lineHeight, fontSize } = options;
  return css({
    fontSize,
    lineHeight: `${lineHeight}px`,
    padding: `${topBottomPadding}px ${leftRightPadding}px`,
  });
};

interface ButtonStyle {
  width?: string;
  textColor?: string;
  backgroundColor?: string;
  hoverColor?: string;
}
export const buttonStyle = ({
  backgroundColor,
  hoverColor,
  textColor,
  width,
}: ButtonStyle): SerializedStyles =>
  css({
    ':disabled': {
      color: rgba(White['000'], 0.4),
    },
    ':focus': {
      outline: '0',
    },
    ':hover': {
      backgroundColor: hoverColor ?? Orange[300],
    },
    alignItems: 'center',
    backgroundColor: backgroundColor ?? Orange[200],
    border: borderWidthNone,
    borderRadius: borderRadiusXS,
    color: textColor || White['000'],
    cursor: 'pointer',
    display: 'flex',
    fontFamily: APERCU_FONT_NAME,
    fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.bold,
    justifyContent: 'center',
    letterSpacing: rem(0.156),
    textTransform: 'uppercase',
    width,
  });

interface PillStyleCss {
  (borderColor): SerializedStyles;
}

export const pillStyleCss: PillStyleCss = (borderColor) =>
  css({
    border: borderColor && `${borderWidthS} solid ${borderColor}`,
    borderRadius: 52,
    letterSpacing: 1,
    textTransform: 'none',
  });

interface HollowStyleCss {
  ({ textColor, backgroundColor }): SerializedStyles;
}

export const hollowStyleCss: HollowStyleCss = ({
  textColor,
  backgroundColor,
}) =>
  css({
    ':hover': {
      borderColor: Orange[300],
      color: Orange[300],
    },
    backgroundColor: 'transparent',
    border: `${borderWidthS} solid ${backgroundColor || CoolGrey[500]}`,
    color: textColor || CoolGrey[500],
    padding: `${SpacingInRemBySize['2XS']} ${rem(0.625)}`,
  });

export const suppressLetterSpacingStyleCss = css({
  letterSpacing: '0',
});
