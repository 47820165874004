import { Organization } from '../../types/organization';
import type { Action } from '../types';
import { OrganizationsActionsTypes } from '../types/organizations';

export const initialState = {
  OrganizationList: [],
  isFetching: false,
  searchTerm: '',
  totalOrgCount: 0,
};

export interface OrganizationsState {
  OrganizationList: Organization[];
  totalOrgCount: number;
  searchTerm: string;
  isFetching?: boolean;
}

export const reducer = (
  state: OrganizationsState = initialState,
  { type, payload }: Action,
): OrganizationsState => {
  switch (type) {
    case OrganizationsActionsTypes.SEARCH_ORGANIZATIONS:
      return {
        ...state,
        searchTerm: payload.searchTerm,
      };

    case OrganizationsActionsTypes.IS_FETCHING_ORGANIZATIONS:
    case OrganizationsActionsTypes.UPDATE_ORGANIZATIONS:
      return {
        ...state,
        ...payload,
      };

    case OrganizationsActionsTypes.RECEIVED_ORGANIZATIONS: {
      const prevOrgs: Organization[] = payload.offset
        ? state.OrganizationList
        : [];
      const OrganizationList = [...prevOrgs, ...payload.OrganizationList];
      return {
        ...state,
        OrganizationList,
        totalOrgCount: payload.totalOrgCount,
      };
    }
    case OrganizationsActionsTypes.CREATE_ORGANIZATION:
    case OrganizationsActionsTypes.SELECT_ORGANIZATION:
    default:
      return state;
  }
};
