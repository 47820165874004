import {
  ReceiveSFTPUsersAction,
  OrganizationActionsTypes,
} from '../../types/organization';

export const receiveSFTPUsers = (
  sftpUsers: SFTPUser[],
): ReceiveSFTPUsersAction => ({
  type: OrganizationActionsTypes.RECEIVE_SFTP_USER,
  payload: { sftpUsers, sftpFinished: sftpUsers.length > 0 },
});
