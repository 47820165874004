import { Spinner } from '@headspace/web-ui-components';
import { spinnerBackgroundStyle, spinnerWrapperCss } from './styles';

export const SpinnerFullBackGround = () => (
  <div css={spinnerWrapperCss}>
    <div css={spinnerBackgroundStyle('100%', '100%')}>
      <Spinner />
    </div>
  </div>
);
