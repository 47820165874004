import { GeolocationActionTypes } from '../../actions/geolocationActions/geolocationActionTypes';

const INITIAL_STATE = {
  isVerified: false,
};

export const geolocationVerificationReducer = (
  state = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
    case GeolocationActionTypes.UPDATE_GEOLOCATION_VERIFICATION:
      return { ...state, isVerified: action.payload.isVerified };
    default:
      return state;
  }
};

export type GeolocationState = ReturnType<
  typeof geolocationVerificationReducer
>;
