/**
 * @returns number
 * Number inputs treat values as strings. Likewise APIs
 * dont always return the intended type. This lets us
 * avoid the additional type errors arising from parsing
 * strings on declared number types.
 */
export const assureNumberValue = (value: number | string): number => {
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }
  return value;
};
