import {
  Divider,
  fontSizeM,
  rem,
  TextLink,
} from '@headspace/web-ui-components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import Collapsible from 'react-collapsible';
import { FC, useState } from 'react';
import {
  FAQ_COMMON_QUESTIONS_V2,
  FAQ_FUSION_QUESTIONS,
  FAQ_PAGE_LINK_V2,
  FaqCommonQuestionsProps,
} from '../../constants';
import {
  TranslateFn,
  useTranslate,
} from '../../../../../../hooks/useTranslate';
import {
  bundledInfoCopyAltText,
  bundledInfoCopyLink,
  centeredButtonStyle,
  expandComponentStyle,
  faqContainerStyle,
  faqMessageStyle,
  faqTitleStyle,
  faqWrapperStyle,
  resetStyle,
} from '../styles/verificationPageStyleUmdWfm';
import {
  getIsElfDependentsEnabled,
  getPrimaryProduct,
} from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { PRIMARY_PRODUCT } from '../../../../../dashboard/constants';

interface FaqItemProps {
  translate: TranslateFn;
  item: FaqCommonQuestionsProps;
}

const FaqItem: FC<FaqItemProps> = ({ translate, item }) => {
  const [open, setOpen] = useState(false);

  const handleTriggerClick = () => {
    setOpen(!open);
  };

  return (
    <li css={faqMessageStyle}>
      <Collapsible
        transitionTime={200}
        trigger={
          <p css={expandComponentStyle} data-testid="expand-component">
            {translate(item.question)}
          </p>
        }
        tabIndex={0}
        open={open}
        handleTriggerClick={handleTriggerClick}
      >
        <div
          data-testid={item.answer}
          css={css(resetStyle, {
            marginTop: rem(1.875),
          })}
        >
          <FormattedMessage
            id={item.answer}
            values={{
              a: (chunks) =>
                open ? (
                  <TextLink to={item.link ?? ''} isNewWindow={true}>
                    {chunks}
                  </TextLink>
                ) : (
                  <div css={bundledInfoCopyLink}>{chunks}</div>
                ),
              b: (chunks) => <b css={bundledInfoCopyAltText}>{chunks}</b>,
              span: (chunks) => (
                <span css={bundledInfoCopyAltText}>{chunks}</span>
              ),
            }}
          />
        </div>
      </Collapsible>
      <Divider />
    </li>
  );
};

export const LandingFAQ = () => {
  const { translate } = useTranslate();
  const primaryProduct = useSelector(getPrimaryProduct);
  const areDependentsInvited = !useSelector(getIsElfDependentsEnabled);
  // Return as early as possible, so we don't calculate/fetch things we won't be using anyways.
  // Hooks need to come first though.
  if (!areDependentsInvited) {
    // TODO: In theory we should also not show the FAQ if the org has no invite based dependents
    // (dependentsPerMember == 0) even if they don't have elf based dependents as well.
    // Waiting on product to decide.
    return null;
  }

  let faqCommonQuestions: FaqCommonQuestionsProps[];
  if (primaryProduct === PRIMARY_PRODUCT.FUSION) {
    faqCommonQuestions = FAQ_FUSION_QUESTIONS;
  } else {
    faqCommonQuestions = FAQ_COMMON_QUESTIONS_V2;
  }

  const faqLink = FAQ_PAGE_LINK_V2;

  return (
    <div css={faqWrapperStyle}>
      <div css={faqContainerStyle}>
        <div css={faqTitleStyle}>
          {translate('enrollment.landingPage.faqButton')}
        </div>
        <ul css={resetStyle}>
          {faqCommonQuestions.map((item) => (
            <FaqItem translate={translate} item={item} key={item.question} />
          ))}
        </ul>
        <div css={centeredButtonStyle} data-testid="landing-faq-button">
          <TextLink
            css={{ fontSize: fontSizeM }}
            to={faqLink}
            isNewWindow={true}
          >
            {translate('enrollment.landingPage.faq.moreQuestions')}
          </TextLink>
        </div>
      </div>
    </div>
  );
};
