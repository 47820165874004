import Check from '../../../../../assets/plumc-check.svg';
import { Icon } from '../../../../../shared-components/Icon';
import {
  circleContainer,
  circleStyle,
  labelStyle,
  numberStyle,
} from './styles';

interface CircleComponentProps {
  step: string;
  label: string;
  isActive: boolean;
  isDone?: boolean;
  dataTestId?: string;
}

export const CircleComponent = ({
  step,
  label,
  isActive,
  isDone,
  dataTestId,
}: CircleComponentProps) => (
  <div css={circleContainer(isActive)}>
    <div css={circleStyle(isActive)}>
      <div>
        {isDone ? (
          <Icon
            src={Check}
            width={12}
            height={12}
            fill="#8F8C94"
            dataTestid={`check-icon-${step}`}
          />
        ) : (
          <div css={numberStyle(isActive)}>{step}</div>
        )}
      </div>
    </div>
    <p css={labelStyle(isActive)} data-testid={`${dataTestId}`}>
      {label}
    </p>
  </div>
);
