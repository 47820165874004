import { css } from '@emotion/react';
import * as React from 'react';
import { SpacingInRemBySize } from '@headspace/web-ui-components';
import {
  OrganizationSetupAdministratorsDisplayProps,
  OrganizationAdministratorProps,
} from '../types';
import { BasicTable } from '../../../shared-components/tables/BasicTable';
import { BasicTableCell } from '../../../shared-components/tables/BasicTable/BasicTableCell';
import { BasicTableRow } from '../../../shared-components/tables/BasicTable/BasicTableRow';
import { BasicTableHeaderRow } from '../../../shared-components/tables/BasicTable/BasicTableHeaderRow';
import { useTranslate } from '../../../hooks/useTranslate';

const locale = 'organizationSettings.administrators.';
export const reviewTableStyles = css({
  marginTop: SpacingInRemBySize.L,
});

const reviewTableRowStyles = css({
  padding: SpacingInRemBySize.S,
});

export const OrganizationAdministratorsDisplay: React.FC<OrganizationSetupAdministratorsDisplayProps> = ({
  admins,
}) => {
  const { translate } = useTranslate();
  return (
    <BasicTable>
      <BasicTableHeaderRow css={reviewTableStyles}>
        <BasicTableCell>{translate(`${locale}nameLabel`)}</BasicTableCell>
        <BasicTableCell>{translate(`${locale}emailLabel`)}</BasicTableCell>
        <BasicTableCell>{translate(`${locale}titleLabel`)}</BasicTableCell>
      </BasicTableHeaderRow>
      {admins.map((admin: OrganizationAdministratorProps, i: number) => {
        const { name, title = '', email } = admin;
        return (
          <BasicTableRow key={email} css={reviewTableRowStyles}>
            <BasicTableCell>{name}</BasicTableCell>
            <BasicTableCell>{email}</BasicTableCell>
            <BasicTableCell>{title}</BasicTableCell>
          </BasicTableRow>
        );
      })}
    </BasicTable>
  );
};
