import { Dispatch } from 'redux';
import { getAdminOrganizations } from '../../rest';
import {
  error,
  isFetchingOrganizationsAction,
  receivedOrganizationsAction,
} from './organizationsActions';

export const fetchAdminOrganizationsAction = (options: {
  refresh: boolean;
}) => {
  const { refresh } = options;

  return async (dispatch: Dispatch, getState: GetState) => {
    const {
      organizations: { OrganizationList, totalOrgCount },
    } = getState();
    if (refresh || !OrganizationList.length) {
      dispatch(isFetchingOrganizationsAction(true));

      let result;
      try {
        result = await getAdminOrganizations();
      } catch (err) {
        error({ result, dispatch });
      }

      if (result.data) {
        const { orgs, total } = result.data;

        dispatch(isFetchingOrganizationsAction(false));

        dispatch(receivedOrganizationsAction(orgs, total));
      } else {
        error({ result, dispatch });
      }
    } else {
      dispatch(receivedOrganizationsAction(OrganizationList, totalOrgCount));
    }
  };
};
