import { authSelectorsFactory, JwtToken } from '@headspace/web-auth';
import { createSelector } from 'reselect';
import { authConfig } from '../../../enrollment-config/authConfig';

// This file cannot be imported from the index file.
export const {
  getAccessToken,
  getAccessTokenFetchStatus,
} = authSelectorsFactory(authConfig);

export const getRawAccessToken = createSelector(
  getAccessToken,
  (accessToken = new JwtToken()) => {
    return accessToken.token;
  },
);
