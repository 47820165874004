import { useGate } from 'statsig-react';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/braze_campaign_display
// Feature flag to SSO for headspace accounts and universal login for every org admin
const HS_ADMIN_PORTAL_OKTA_SSO = 'hs_admin_portal_okta_sso';

export const useHsOktaSSO = (): boolean[] => {
  const { value, isLoading } = useGate(HS_ADMIN_PORTAL_OKTA_SSO);
  return [value, isLoading];
};
