import { FC } from 'react';
import { radioInputStyle, radioInputTitleStyle } from './styles';

type RadioInputProps = {
  choice: string;
  title: string;
  subtitle: string;
  namespace: string;
  'data-testid': string;
  onChange?: (selectedChoice: string) => void;
};

export const RadioInput: FC<RadioInputProps> = ({
  'data-testid': testId,
  choice,
  namespace,
  subtitle,
  title,
  onChange,
}) => {
  const radioInputId = `${namespace}-${choice}`;
  return (
    <div css={radioInputStyle}>
      <label htmlFor={radioInputId}>
        <div css={radioInputTitleStyle}>{title}</div>
        <div>{subtitle}</div>
      </label>
      <input
        onChange={() => onChange?.call(null, choice)}
        type="radio"
        id={radioInputId}
        data-testid={testId}
        value={choice}
        name={namespace}
      />
    </div>
  );
};
