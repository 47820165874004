import { css } from '@emotion/react';
import {
  alignItemsCenter,
  justifyContentFlexEnd,
  rowFlex,
} from '../../../../../styles/flex';

export const panelAction = css(
  rowFlex,
  justifyContentFlexEnd,
  alignItemsCenter,
);
