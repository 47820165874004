import { Action } from '../types';

const INITIAL_STATE = {
  data: [],
  isLoading: false,
  error: null,
};

export function reducer(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case 'FETCH_TOOLKIT_ASSETS_IS_LOADING':
      return { ...state, isLoading: true };
    case 'FETCH_TOOLKIT_ASSETS_SUCCESS':
      return { ...state, data: action.payload, isLoading: false };
    case 'FETCH_TOOLKIT_FETCH_ASSETS_FAIL':
      return { ...state, error: action.payload, isLoading: false };
    default:
      return INITIAL_STATE;
  }
}
