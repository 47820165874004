import { css, Interpolation } from '@emotion/core';
import {
  fontFamilyCss,
  FontStyle,
  fontWeightBold,
  fontWeightRegular,
} from '@headspace/web-ui-components';

export const regular = css(fontFamilyCss as Interpolation, {
  fontStyle: FontStyle.NORMAL,
  fontWeight: fontWeightRegular,
});

export const bold = css(fontFamilyCss as Interpolation, {
  fontWeight: fontWeightBold,
});
