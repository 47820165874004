export enum GingerSoleraActionTypes {
  SET_GINGER_SOLERA_INIT = 'SET_GINGER_SOLERA_INIT',
  SET_GINGER_SOLERA_CONNECTION_ERROR = 'SET_GINGER_SOLERA_CONNECTION_ERROR',
  SET_GINGER_SOLERA_CONNECTION_STARTED = 'SET_GINGER_SOLERA_CONNECTION_STARTED',
  SET_GINGER_SOLERA_CONNECTION_FINISHED = 'SET_GINGER_SOLERA_CONNECTION_FINISHED',
}

export interface SetGingerSoleraConnectionActionErrorPayload {
  error: {
    code: string | null;
  };
}

export interface SetGingerSoleraConnectionErrorAction {
  type: GingerSoleraActionTypes.SET_GINGER_SOLERA_CONNECTION_ERROR;
  payload: SetGingerSoleraConnectionActionErrorPayload;
}

export interface SetGingerSoleraConnectionStartedAction {
  type: GingerSoleraActionTypes.SET_GINGER_SOLERA_CONNECTION_STARTED;
}

export interface SetGingerSoleraConnectionFinishedAction {
  type: GingerSoleraActionTypes.SET_GINGER_SOLERA_CONNECTION_FINISHED;
}

export interface SetGingerSoleraInitAction {
  type: GingerSoleraActionTypes.SET_GINGER_SOLERA_INIT;
}

export type GingerSoleraActions =
  | SetGingerSoleraConnectionErrorAction
  | SetGingerSoleraConnectionStartedAction
  | SetGingerSoleraConnectionFinishedAction;
