import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Card, WarmGrey } from '@headspace/web-ui-components';
import { buttonClickthroughAction } from '../../../../../analytics/events/enrollmentEvents';
import env from '../../../../../config/env';
import { footerTextStyle, webAppLinkStyle, cardStyle } from '../styles';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface InfoCardProps {
  email: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({ email }) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const handleClickDesktop = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(buttonClickthroughAction(translate('finish.link')));
      setTimeout(() => window.location.replace(env.WEB_APP), 500);
    },
    [buttonClickthroughAction],
  );

  return (
    <div css={cardStyle} data-testid="info-card">
      <Card backgroundColor={WarmGrey[100]}>
        <div css={webAppLinkStyle}>
          <span>{translate('finish.goTo')}</span>
          <a
            onClick={handleClickDesktop}
            data-testid="finish-link"
            href={env.WEB_APP}
          >
            {` ${translate('finish.link')} `}
          </a>
        </div>
        <div css={footerTextStyle}>
          <p>
            {translate('finish.footerText1')}
            <strong>{` ${email} `}</strong>
            {translate('finish.footerText2')}
          </p>
        </div>
      </Card>
    </div>
  );
};
