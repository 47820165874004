import { css } from '@emotion/react';
import {
  cssWithMq,
  Blue,
  SpacingInRemBySize,
  rem,
  fontSizeXS,
  White,
  fontSizeL,
  fontSizeXL,
  fontSize2XL,
  WarmGrey,
  letterSpacingTight,
} from '@headspace/web-ui-components';

export const containerStyle = cssWithMq({
  color: WarmGrey[700],
  padding: [
    `0 ${SpacingInRemBySize.L} 0`,
    `0 ${rem(2.25)} 0`,
    `0 ${rem(2.25)} 0`,
  ],
  textAlign: 'center',
});

export const buttonContainerStyle = cssWithMq({
  marginTop: [rem(5.313), rem(2)],
});

export const buttonDescriptionStyle = cssWithMq({
  display: 'block',
  fontSize: fontSizeXS,
  marginBottom: SpacingInRemBySize.S,
  marginTop: [rem(6.875), 0],
});

export const resendButtonStyle = css({
  ':disabled': {
    color: White['000'],
    opacity: '0.4',
  },
});

export const inputStyle = css({
  marginTop: SpacingInRemBySize.L,
});

export const subTitleStyle = css({
  fontSize: fontSizeL,
});

export const termsComponentStyles = css({
  '& a': {
    color: Blue[200],
  },
  color: WarmGrey[600],
  fontSize: fontSizeXS,
  width: rem(15),
});

export const faqComponentStyles = css({
  '& a': {
    color: Blue[200],
  },
  color: WarmGrey[600],
  fontSize: fontSizeXS,
  width: rem(18.25),
});

export const headerBoxContainer = css({
  color: WarmGrey[800],
  fontSize: fontSizeXL,
  fontWeight: 'bolder',
  lineHeight: rem(1.563),
  margin: 'auto',
  textAlign: 'center',
});

export const headerStyle = cssWithMq({
  fontSize: fontSize2XL,
  letterSpacing: letterSpacingTight,
  margin: [
    `${SpacingInRemBySize['2XL']} ${SpacingInRemBySize.L} ${rem(1)}`,
    `${SpacingInRemBySize['2XL']} ${rem(2.25)} ${SpacingInRemBySize.M}`,
    `${SpacingInRemBySize['2XL']} ${rem(2.25)} ${SpacingInRemBySize.M}`,
  ],
});

export const listMarker = cssWithMq({
  '::before': {
    backgroundColor: WarmGrey[700],
    borderRadius: '50%',
    content: '""',
    height: SpacingInRemBySize['3XS'],
    left: `-${SpacingInRemBySize.S}`,
    position: 'absolute',
    top: rem(0.6),
    width: SpacingInRemBySize['3XS'],
  },
});

export const dependentEnrollmentInputStyle = { color: WarmGrey[500] };
export const dependentEnrollmentModalStyle = css({
  'div[role="dialog"]': {
    margin: '10px',
  },
  'div[data-testid="modal-background"]': {
    backgroundColor: 'black',
  },
});
