import { HeadspaceHubMemberDetailsActionTypes } from '../actions/headspaceHubMemberDetailsActions/actionTypes';

const INITIAL_STATE = {
  fetchedUserStatus: false,
};

export const eapAuthReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case HeadspaceHubMemberDetailsActionTypes.EAP_USER_LOG_IN_FAILED:
      return { ...state, fetchedUserStatus: true };
    case HeadspaceHubMemberDetailsActionTypes.EAP_USER_IS_LOGGED_IN:
      return { ...state, fetchedUserStatus: true };
    default:
      return state;
  }
};
