import {
  borderRadiusXS,
  CoolGrey,
  fontSizeS,
  LegacyColors,
  Orange,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import {
  alignItemsCenter,
  justifyContentCenter,
  rowFlex,
} from '../../../../styles/flex';

export const detailsUploadWrapperStyle = css(
  rowFlex,
  alignItemsCenter,
  justifyContentCenter,
);

export const detailsFileUploadButtonStyle = css(
  rowFlex,
  alignItemsCenter,
  justifyContentCenter,
  {
    ':hover': {
      backgroundColor: CoolGrey[100],
    },
    background: 'transparent',
    border: 'none',
    borderRadius: borderRadiusXS,
    color: Orange[200],
    cursor: 'pointer',
    fontSize: fontSizeS,
    letterSpacing: rem(0.156),
    outline: 'none',
    padding: `${SpacingInRemBySize.XS} ${rem(1.375)}`,
    textTransform: 'uppercase',
  },
);

export const phoneNumberPanelStyle = css({
  height: rem(4.89),
});
