import { css } from '@emotion/react';
import {
  rem,
  SpacingInRemBySize,
  bodyXXSCss,
} from '@headspace/web-ui-components';

import env from '../../../../../config/env';
import { LinkTo } from '../../components/LinkTo';

const containerStyle = (isVisible: boolean) =>
  css({
    display: isVisible ? 'flex' : 'none',
    justifyContent: 'right',
    paddingLeft: rem(0.625),
    flexDirection: 'column',
    marginTop: SpacingInRemBySize.XS,
  });

interface LinkProps {
  isCSM: boolean;
  hsUserId: string | null;
}

export const LinkToDashboards = ({ isCSM, hsUserId }: LinkProps) => {
  const isVisible = isCSM && !!hsUserId;

  return (
    <div css={containerStyle(isVisible)} data-testid="link-to-dashboards">
      <div css={bodyXXSCss}>{hsUserId}</div>
      <div
        css={css({
          display: 'flex',
        })}
      >
        <LinkTo
          dataTestId="crocpit-link-tag"
          href={`${env.CROCPIT_WEB_HOST}/user/#/customer/${hsUserId}/dashboard`}
          isVisible={isVisible}
          text="Crocpit"
        />
        <LinkTo
          dataTestId="django-link-tag"
          href={`${env.GINGER_WEB_API.url}/admin/webmerge/user/?q-l=on&q=headspace_user_id+%3D+"${hsUserId}"`}
          isVisible={isVisible}
          text="Django"
        />
      </div>
    </div>
  );
};
