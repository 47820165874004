export interface CheckIconProps {
  color: string;
  width: number;
  height: number;
  dataTestId?: string;
}

export const CheckIcon = ({
  color,
  width,
  height,
  dataTestId,
}: CheckIconProps) => (
  <div data-testid={dataTestId}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 17"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.74652 16.4772L0.179853 9.27719C0.0647459 9.15202 0 8.98162 0 8.80386C0 8.6261 0.0647459 8.45571 0.179853 8.33053L2.74572 5.50386C2.85988 5.37766 3.01529 5.30666 3.17741 5.30666C3.33954 5.30666 3.49495 5.37766 3.60911 5.50386L7.42751 9.73053L15.04 0.197199C15.1541 0.0709904 15.3096 0 15.4717 0C15.6338 0 15.7892 0.0709904 15.9034 0.197199L18.6516 2.86386C18.7668 2.98904 18.8315 3.15944 18.8315 3.3372C18.8315 3.51496 18.7668 3.68535 18.6516 3.81053L8.50979 16.3972C8.29037 16.6729 7.97727 16.8376 7.64384 16.8527C7.31041 16.8678 6.98596 16.7321 6.74652 16.4772Z"
        fill={color}
      />
    </svg>
  </div>
);
