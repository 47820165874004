import {
  createTrackableUrl,
  parseTrackableUrl,
} from '@headspace/web-analytics';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import { Product } from '../apps/memberEnrollmentV2/constants';
import { dashboardRoutes } from '../root-component/Routes';
import {
  getAuthConnections,
  getHsUserId,
} from '../apps/memberEnrollmentV2/state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getActiveOrg } from '../apps/memberEnrollmentV2/state/selectors/orgSectionSelector/orgSectionSelector';
import { getAppStoreLink } from '../apps/memberEnrollmentV2/views/UnifiedEligibilityViews/utils/deviceUtils';

export const ORG_ID_KEY = 'org-id';
export const DEEPLINK_KEY = 'deeplink';
export const SOCIAL_DEEPLINK = 'gingerio://login/social';
export const EMAIL_DEEPLINK = 'gingerio://login/email';
export const GINGER_APP_LINK = 'https://ginger.app.link/blLXPZW5xmb';

const getDirectUrl = (app: Product | null) =>
  new URL(app === Product.GINGER ? GINGER_APP_LINK : getAppStoreLink());

/**
 * This hook provides two versions of the app store link: direct (for mobile
 * users who are presented with a button) and via /redirect-to-app-store (for
 * desktop users who are presented with a QR code). The latter exists so that
 * QR code scans can be tracked before redirecting.
 */
export const useGetAppStoreLinks = ({
  app,
}: {
  app: Product | null;
}): {
  appStoreLink: string;
  trackableAppStoreLink: string;
} => {
  const hsUserId = useSelector(getHsUserId);
  const { id: orgId } = useSelector(getActiveOrg) ?? {};
  const connections = useSelector(getAuthConnections);

  const directUrl = getDirectUrl(app);

  const redirectUrl = new URL(
    `${window.location.origin}${dashboardRoutes.REDIRECT_TO_APP_STORE}/${
      app ?? ''
    }`,
  );

  if (app === Product.GINGER) {
    const deeplink = connections?.includes('User-Password-Headspace')
      ? EMAIL_DEEPLINK
      : SOCIAL_DEEPLINK;
    directUrl.searchParams.set(DEEPLINK_KEY, deeplink);
    redirectUrl.searchParams.set(DEEPLINK_KEY, deeplink);
  }

  if (orgId) {
    redirectUrl.searchParams.set(ORG_ID_KEY, orgId);
  }

  const appStoreLink = directUrl.toString();
  const trackableAppStoreLink = createTrackableUrl(
    redirectUrl.toString(),
    hsUserId,
  );

  return {
    appStoreLink,
    trackableAppStoreLink,
  };
};

/**
 * This hook interprets the trackableAppStoreLink generated above, providing
 * /redirect-to-app-store with the analytics attributes it needs, in addition
 * to the destination URL.
 */
export const useTrackableAppStoreLink = (): {
  app: Product;
  appStoreLink: string;
  hsUserId?: string;
  orgId: string | null;
} => {
  const { app = Product.HEADSPACE } = useParams<{ app: Product }>() as {
    app: Product;
  };

  const { hsUserId } = parseTrackableUrl(window.location.href);
  const orgId = useSearchParam(ORG_ID_KEY);

  const directUrl = getDirectUrl(app);

  const deeplink = useSearchParam(DEEPLINK_KEY);

  if (deeplink) {
    // prevent double uri encoding
    const decodedDeeplink = decodeURIComponent(deeplink);
    directUrl.searchParams.set(DEEPLINK_KEY, decodedDeeplink);
  }

  const appStoreLink = directUrl.toString();

  return {
    app,
    appStoreLink,
    hsUserId,
    orgId,
  };
};
