import { FC } from 'react';
import { css } from '@emotion/react';
import {
  Orange,
  rem,
  SpacingInRemBySize,
  White,
} from '@headspace/web-ui-components';

import logoSvg from 'assets/new-logo.svg';
import { Icon } from './Icon';
import { LinkDropdown } from './dropdowns/LinkDropdown';
import {
  SmallUppercaseLink,
  SmallUppercaseLinkProps,
} from './SmallUppercaseLink';
import { uniqueKey } from '../utils/keyCounter';
import {
  alignItemsCenter,
  justifyContentSpaceBetween,
  rowFlex,
} from '../apps/dashboard/styles/flex';

export interface HeaderDropdownProps {
  dropdownLinks: SmallUppercaseLinkProps[];
  dropdownText: string;
  renderDropdown?: () => React.ReactNode;
}

export interface MainHeaderOwnProps {
  middleLinks: SmallUppercaseLinkProps[];
  logoAriaLabel: string;
}

export interface MainHeaderState {}
const mainHeaderStyle = css(
  rowFlex,
  justifyContentSpaceBetween,
  alignItemsCenter,
  {
    backgroundColor: White['000'],
    height: rem(4.5),
    width: '100%',
  },
);

const middleLinkStyle = css({
  display: 'inline-block',
});

const middleLinkRightMarginStyle = css({
  marginRight: SpacingInRemBySize['2XL'],
});

const logoLink = css({
  display: 'block',
  textDecoration: 'none',
  width: rem(10),
  height: rem(2.125),
});

const HeaderDropdown: React.FC<HeaderDropdownProps> = ({
  renderDropdown,
  dropdownLinks,
  dropdownText,
}) => (
  <div>
    {renderDropdown ? (
      renderDropdown()
    ) : (
      <LinkDropdown
        links={dropdownLinks}
        position="right"
        text={dropdownText}
      />
    )}
  </div>
);

const isActive = (url: string) => {
  const paths = url.split('/');
  return paths.some((p) => window.location.pathname.includes(p));
};

export type MainHeaderProps = MainHeaderOwnProps & Partial<HeaderDropdownProps>;

export const MainHeader: FC<MainHeaderProps> = ({
  middleLinks,
  logoAriaLabel,
  dropdownText = '',
  dropdownLinks = [],
  renderDropdown = () => null,
}) => (
  <header css={mainHeaderStyle}>
    <div>
      <div aria-label={logoAriaLabel} css={logoLink}>
        <Icon width={160} height={34} fill={Orange[200]} src={logoSvg} />
      </div>
    </div>
    <div>
      {middleLinks.map((link: any, index: number) => (
        <div
          key={uniqueKey()}
          css={[
            middleLinkStyle,
            index < middleLinks.length - 1 ? middleLinkRightMarginStyle : '',
          ]}
        >
          <SmallUppercaseLink
            name={link.name}
            url={link.url}
            active={isActive(link.url)}
          />
        </div>
      ))}
    </div>
    <HeaderDropdown
      dropdownText={dropdownText}
      dropdownLinks={dropdownLinks}
      renderDropdown={renderDropdown}
    />
  </header>
);
