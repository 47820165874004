import { useState, FC } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { FormContainer } from '../FormContainer/FormContainer';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import { Input } from '../../../../../shared-components/Input';
import { VerificationMessage } from './VerificationMessage';
import {
  buttonContainerStyle,
  containerStyle,
  inputStyle,
  subTitleStyle,
} from './styles';
import {
  buttonClickthroughAction,
  EnrollmentEventNames,
} from '../../../../../analytics/events/enrollmentEvents';
import { ContractActivityStatus } from '../../../../../analytics/types';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface VerifyByEmailProps {
  companyName: string;
  handleVerify(email: string): void;
}

const initialState = {
  email: '',
};

const VerifyByEmail: FC<VerifyByEmailProps> = ({
  companyName,
  handleVerify,
}) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [emailState, setEmailState] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [verified, setVerified] = useState(false);
  const { email } = emailState;
  const verifyButtonLabel =
    verified && !errorMessage
      ? translate('verify.byEmail.checkYourEmail')
      : translate('verify.sendVerificationEmail');

  const handleVerification = async () => {
    dispatch(buttonClickthroughAction(verifyButtonLabel));

    if (email === '') {
      dispatch(
        buttonClickthroughAction(
          verifyButtonLabel,
          EnrollmentEventNames.verificationErrorView,
          ContractActivityStatus.failed,
        ),
      );
      setErrorMessage(translate('errors.EMPTY_EMAIL'));
      return;
    }

    try {
      await handleVerify(email);

      setVerified(true);
    } catch (e) {
      const responseErrorMessage = R.pathOr(
        e,
        ['response', 'data', 'errors', '0', 'data'],
        e,
      );

      dispatch(
        buttonClickthroughAction(
          verifyButtonLabel,
          EnrollmentEventNames.verificationErrorView,
          ContractActivityStatus.failed,
        ),
      );

      setErrorMessage(responseErrorMessage);
    }
  };

  const handleChange = (e: any) => {
    const {
      target: { id, value },
    } = e;
    setEmailState({ ...emailState, [id]: value });
    setErrorMessage(null);
  };

  return (
    <FormContainer
      headerTag="h3"
      title={translate('verify.verifyYourAccess')}
      termsMessage={true}
      isTestVariant={true}
    >
      <div css={containerStyle(true)}>
        <div css={subTitleStyle()}>
          <p>
            {`${translate('verify.weNeedToVerifyAccess', {
              ORG_NAME: companyName,
            })} ${translate('verify.enterEligibleEmail')}`}
          </p>
        </div>
        <div>
          <div css={inputStyle}>
            <Input
              type="text"
              name="email"
              data-testid="verify-email-input"
              placeholder={translate('verify.byEmail.emailAddress')}
              value={email}
              hasError={!!errorMessage}
              onChange={handleChange}
              shouldFocusError={true}
              noLabel={true}
              aria-labelledby="verifyEmail"
            />
          </div>
          <VerificationMessage
            errorMessage={errorMessage}
            verified={verified}
          />
          <div css={buttonContainerStyle(true)}>
            <TextButton
              textColor="#FFFF"
              hoverColor="#0884FF"
              backgroundColor="#0884FF"
              value={verifyButtonLabel}
              disabled={verified}
              pill={true}
              width="100%"
              dataTestId="send-verification-submit-button"
              onClick={handleVerification}
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default VerifyByEmail;
