export enum SupportedLanguageCode {
  en = 'en',
  es = 'es',
  de = 'de',
  fr = 'fr',
  pt = 'pt',
}

export enum HubSupportedLanguageCode {
  zh_CN = 'zh_CN',
  de = 'de',
  ms = 'ms',
  cs = 'cs',
  hi = 'hi',
  pl = 'pl',
  nl = 'nl',
  hu = 'hu',
  pt = 'pt',
  en = 'en',
  it = 'it',
  ru = 'ru',
  fr = 'fr',
  ja = 'ja',
  es = 'es',
}

export type SupportedLanguagesType = {
  en: string;
  es: string;
  fr: string;
  de: string;
  pt: string;
};

export type HubSupportedLanguagesType = SupportedLanguagesType & {
  cs: string;
  hi: string;
  hu: string;
  it: string;
  ja: string;
  ms: string;
  nl: string;
  pl: string;
  ru: string;
  zh_CN: string;
};

export type AvailableLanguagesType =
  | SupportedLanguagesType
  | HubSupportedLanguagesType;

export const getAllAvailableLanguages = (
  shouldShowExpansionLanguages: boolean,
): AvailableLanguagesType => {
  const isOnboarding = /onboarding/.test(window.location.pathname);

  if (shouldShowExpansionLanguages && !isOnboarding) {
    return HubSupportedLanguageCode;
  }
  return SupportedLanguageCode;
};

export const CountryCodeToLanguageCode = {
  AR: SupportedLanguageCode.en,
  BR: SupportedLanguageCode.pt,
  CL: SupportedLanguageCode.es,
  DE: SupportedLanguageCode.en,
  ES: SupportedLanguageCode.es,
  GB: SupportedLanguageCode.en,
  IE: SupportedLanguageCode.en,
  IT: SupportedLanguageCode.en,
  MX: SupportedLanguageCode.es,
  US: SupportedLanguageCode.en,
} as const;
