import { css } from '@emotion/react';
import { ExternalLink } from '@headspace/react-icons';
import {
  Orange,
  SpacingInRemBySize,
  White,
  borderWidthM,
  borderWidthXS,
  fontSize2XS,
  rem,
} from '@headspace/web-ui-components';
import { FC } from 'react';

const linkStyle = (isVisible: boolean) =>
  css({
    ':focus': {
      boxShadow: 'none',
      outline: 'none',
    },
    ':hover': {
      background: Orange[200],
      color: White['000'],
      cursor: 'pointer',
    },
    border: `${borderWidthXS} solid ${Orange[200]}`,
    borderRadius: borderWidthM,
    color: Orange[200],
    display: isVisible ? 'flex' : 'none',
    fontSize: fontSize2XS,
    marginRight: SpacingInRemBySize['2XS'],
    padding: SpacingInRemBySize['2XS'],
    textDecoration: 'none',
  });

const labelStyle = css({
  paddingRight: rem(0.25),
});

interface LinkToProps {
  isVisible?: boolean;
  href: string;
  dataTestId: string;
  text: string;
}

export const LinkTo: FC<LinkToProps> = ({
  isVisible = true,
  href,
  dataTestId,
  text,
}) => (
  <a
    css={linkStyle(isVisible)}
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    data-testid={dataTestId}
  >
    <span css={labelStyle}>{text}</span>
    <ExternalLink />
  </a>
);
