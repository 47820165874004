import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as R from 'ramda';
import { getActiveOrg } from '../state/selectors/organizationSelectors';
import { AddOns } from '../types/organization';

export const useGetResourcesSupported = (): boolean | null => {
  const [isResourcesSupported, setResourcesSupported] = useState<
    null | boolean
  >(null);

  const activeOrg = useSelector(getActiveOrg);
  const addOns = R.pathOr([], ['enrollment', 'addOns'], activeOrg);
  const eapCoverages = R.pathOr([], ['eapCoverages'], activeOrg);
  const hasEap = eapCoverages.length > 0;
  const isWFMOrgs = (addOns as Array<string>).includes(AddOns.EAP);

  useEffect(() => {
    if (hasEap || isWFMOrgs) {
      // showing Global Enrollment updates
      setResourcesSupported(true);
    } else {
      setResourcesSupported(null);
    }
  }, [isWFMOrgs, hasEap]);

  return isResourcesSupported;
};
