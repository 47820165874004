export enum ChallengeActionsTypes {
  CHALLENGES_RECEIVED = 'CHALLENGES_RECEIVED',
  CHALLENGES_CREATED = 'CHALLENGES_CREATED',
  CHALLENGES_CREATE_FAILED = 'CHALLENGES_CREATE_FAILED',
  CHALLENGES_FETCHING = 'CHALLENGES_FETCHING',
  CHALLENGES_CANCELING = 'CHALLENGES_CANCELING',
  CHALLENGES_RESET = 'CHALLENGES_RESET',
}

export interface ChallengeValuesToParams {
  challengeType: { label: string; value: string };
  goalType: { label: string; value: string };
  durationInWeeks: { label: string; value: string };
  isCreatingChallenge: boolean;
  startDate: { label: string; value: string };
  goalTarget: { label: string; value: string };
  timeZone: { label: string; value: string };
  memberStatuses: { label: string; value: string };
}

export interface ChallengeParamsToValue {
  goalType: string;
  durationInWeeks: number;
  startDate: string;
  challengeType: string;
  goalTarget: number;
}

export interface CreateChallenge extends ChallengeParamsToValue {
  orgId: string;
}

export interface FetchingChallenge extends ChallengeParamsToValue {
  id: number;
  status: string;
}

export interface CancelingChallenge {
  id: string;
}
