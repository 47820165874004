import React, { createContext, memo } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { dashboardMessages } from '../i18n/dashboardMessages';
import { useGetHubLangCookie } from '../hooks/useGetHubLangCookie';
import { hasEapCoverage } from '../state/selectors/organizationSelectors';
import { getLocale } from '../state/selectors/getLocale';
import { HubSupportedLanguageCode } from '../types/language';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../constants/featureFlags';

type HubLangContextType = {
  hubLangCookie: HubSupportedLanguageCode;
  setHubLangCookie: (lang: string) => void;
};
export const HubLangContext = createContext<HubLangContextType>({
  hubLangCookie: HubSupportedLanguageCode.en,
  setHubLangCookie: () => {},
});

export const IntlProvider = memo(({ children }) => {
  const [hubLangCookie, setHubLangCookie] = useGetHubLangCookie();
  const [isLanguageExpansionEnabled] = useFeatureFlag(
    FEATURE_FLAG.LANGUAGE_EXPANSION,
  );
  const hasEap = useSelector(hasEapCoverage);
  const supportedLocale = useSelector(getLocale);
  const isNotAdminPortal = /(\/member-enroll|\/hub)/.test(
    window.location.pathname,
  );

  const isHubLocale = isLanguageExpansionEnabled && hasEap && isNotAdminPortal;

  const locale = isHubLocale ? hubLangCookie : supportedLocale;
  const formattedLocale = locale.replace('_', '-');

  const translationsForLocale = dashboardMessages[locale];

  return (
    <HubLangContext.Provider value={{ hubLangCookie, setHubLangCookie }}>
      <ReactIntlProvider
        locale={formattedLocale}
        messages={translationsForLocale}
      >
        {children}
      </ReactIntlProvider>
    </HubLangContext.Provider>
  );
});
