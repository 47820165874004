import { FC, useCallback } from 'react';
import { Red } from '@headspace/web-ui-components';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import { usePostOrganizationRollover } from '../../../../hooks/usePostOrganizationRollover';
import { Toast } from '../../../../../../../shared-components/Toast';
import { copyStyle, wrapperStyle } from './styles';

interface ForceRolloverProps {
  orgId: number;
}

export const ForceRollover: FC<ForceRolloverProps> = ({ orgId }) => {
  const { mutate, isLoading, isSuccess, isError } = usePostOrganizationRollover(
    orgId,
  );
  const handleClick = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <div css={wrapperStyle}>
      <TextButton
        value="Force Rollover"
        disabled={isLoading || isSuccess}
        onClick={handleClick}
      />
      <div css={copyStyle}>
        Instantly trigger a rollover job for this organization instead of
        waiting for the hourly job
      </div>

      {isSuccess ? (
        <Toast text={`Rollover triggered for org ${orgId}`} />
      ) : null}
      {isError ? (
        <Toast
          backgroundColor={Red['300']}
          text={`Error occured while triggering rollover for org ${orgId}`}
        />
      ) : null}
    </div>
  );
};
