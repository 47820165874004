import VirginPulseLogo from 'assets/virgin-pulse-logo.png';
import { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HealthProvider } from '../../../../constants/healthProvider';
import { setHealthProvider } from '../../../../state/actions/memberEnrollActions/memberEnrollActions';
import { EnrollmentHeader } from '../../../shared-components/Header';
import { VerificationPage } from '../../../UnifiedEligibilityViews/VerificationPage';
import { ConfirmationPage } from '../../../VPCare/ConfirmationPage';
import { Footer } from '../../../../../../shared-components/Footer/Footer';

interface VPCareWrapperProps {
  showConfirmationPage?: boolean;
  skipMobileHero?: boolean;
  skipLogout?: boolean;
  children: ReactNode;
}

export const VPCareWrapper: FC<VPCareWrapperProps> = ({
  showConfirmationPage = false,
  children,
  skipMobileHero,
  skipLogout,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHealthProvider(HealthProvider.VPCARE));
  }, [dispatch]);

  return (
    <div>
      <EnrollmentHeader
        showStepComponent={false}
        healthProviderLogo={VirginPulseLogo}
      />
      {showConfirmationPage ? (
        <ConfirmationPage>{children}</ConfirmationPage>
      ) : (
        <VerificationPage
          showFAQ={false}
          showVPCareContent={true}
          skipMobileHero={!!skipMobileHero}
          skipLogout={!!skipLogout}
        >
          {children}
        </VerificationPage>
      )}
      <Footer />
    </div>
  );
};
