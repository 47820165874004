import { useState, useCallback } from 'react';

export const useOpenVerificationLinkModal = () => {
  const [isOpen, setOpen] = useState(false);
  const [defaultEmail, setEmail] = useState('');

  const openModal = useCallback(
    (email = '') => {
      setOpen(true);
      setEmail(email);
    },
    [setOpen, setEmail],
  );
  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return { closeModal, defaultEmail, isOpen, openModal };
};
