import { css } from '@emotion/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  CoolGrey,
  fontSizeXS,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import { Input } from '../../../../../../../shared-components/Input';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

const descriptionCss = css({
  fontSize: fontSizeXS,
  lineHeight: '130%',
  color: CoolGrey[500],
  marginTop: SpacingInRemBySize.S,
});

interface CampaignDisplayNameInputProps {
  editMode?: boolean;
  displayDescription?: boolean;
  onChange: (event: ExtendedEvent) => void;
  onError: (event: ExtendedEvent) => void;
}

export const CampaignDisplayNameInput: React.FC<CampaignDisplayNameInputProps> = ({
  editMode = false,
  displayDescription = false,
  onChange,
  onError,
}) => {
  const {
    campaignDisplayName,
    campaignDisplayNameError,
    overflowDisabled,
  } = useSelector(getOrgManagement);
  const { translate } = useTranslate();

  return (
    <div>
      <Input
        type={editMode ? 'text' : 'review'}
        name="campaignDisplayName"
        placeholder={translate(
          `organizationSettings.enrollment.campaignDisplayNamePlaceholder`,
        )}
        label={translate(
          `organizationSettings.enrollment.campaignDisplayNameLabel`,
        )}
        value={campaignDisplayName || ''}
        onChange={onChange}
        onBlur={onError}
        hasError={campaignDisplayNameError.error}
        errorMessage={campaignDisplayNameError.message}
        disabled={overflowDisabled}
      />
      {displayDescription && (
        <div
          css={descriptionCss}
          data-testid="campaign-display-name-input-description"
        >
          {translate(
            'organizationSettings.enrollment.campaignDisplayNameDescription',
          )}
        </div>
      )}
    </div>
  );
};
