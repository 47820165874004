import * as R from 'ramda';
import { RootState } from '../../../../../state/types';
import { OnboardingState } from './onboardingReducer';
import { RequestStatus } from '../../../../../utils/constants';
import {
  Question,
  SurveyAnswers,
  lowMoodScore,
  highAnxietyScore,
  HelpAnswers,
  QuestionId,
  Choice,
} from '../../../models/OnboardingQuestions';
import { OfferingType } from '../../../../../types/organization';
import { AgeCohort } from '../../../constants/ageCohort';
import isSupportedCountryForCoaching from '../../../utils/isSupportedCountryForCoaching';
import { checkFusionCoachingEligibility } from '../../../../../featureFlags/fusionCoachingEligibility';

export const getOnboardingSection = (state: RootState): OnboardingState => {
  return R.path(['onboarding'], state) as OnboardingState;
};

export const getOnboardingQuestions = (state: RootState): Question[] => {
  return R.pathOr(
    [],
    ['onboarding', 'onBoardingQuestions', 'data', 'questions'],
    state,
  );
};

export const getOnboardingAnswers = (state: RootState): SurveyAnswers => {
  return R.pathOr([], ['onboarding', 'onBoardingResponse'], state);
};

export const getOnboardingRequestStatus = (state: RootState): RequestStatus => {
  return R.pathOr(RequestStatus.IDLE, ['onboarding', 'loading'], state);
};

export const getOnboardingCohort = (state: RootState): AgeCohort | null => {
  return R.pathOr(null, ['onboarding', 'cohort'], state);
};

export const getOnboardingCountry = (state: RootState): string | null => {
  return R.pathOr(null, ['onboarding', 'country'], state);
};

export const getDependentToken = (state: RootState): string | null => {
  return R.pathOr(null, ['onboarding', 'dependentToken'], state);
};

export const getSingleChoiceQuestionAnswer = (
  state: RootState,
  questionId: QuestionId,
): Choice =>
  (R.compose(
    R.head,
    R.pathOr([], ['0', 'answers']),
    R.filter(({ id }) => id === questionId),
    R.pathOr([], ['onboarding', 'onBoardingResponse', 'questions']),
  )(state) as unknown) as Choice;

export const getMultipleChoiceQuestionAnswers = (
  state: RootState,
  questionId: QuestionId,
): Choice[] =>
  (R.compose(
    R.pathOr([], ['0', 'answers']),
    R.filter(({ id }) => id === questionId),
    R.pathOr([], ['onboarding', 'onBoardingResponse', 'questions']),
  )(state) as unknown) as Choice[];

export const getQuestionsAnswer = (
  state: RootState,
  questionId: QuestionId,
): string[] | [] => {
  const getQuestion = ({ id }) => id === questionId;
  const answers = R.compose(
    R.map(({ text }) => text),
    R.pathOr([], ['0', 'answers']),
    R.filter(getQuestion),
    R.pathOr([], ['onboarding', 'onBoardingResponse', 'questions']),
  )(state) as string[];

  return answers;
};

export const getShouldRecommendCoaching = (state: RootState): boolean => {
  const moodAnswer = getQuestionsAnswer(
    state,
    QuestionId.QUESTION_0_MOOD,
  ) as string[];
  const q1Answers = getQuestionsAnswer(state, QuestionId.QUESTION_1_HELP);

  const isEligibleForCoaching = getEligibleCoaching(state);

  const hasLowMoodScore = lowMoodScore
    .map((answer) => moodAnswer.includes(answer))
    .includes(true);
  const hasHighAnxietyScore = q1Answers
    .map((answer) => highAnxietyScore.includes(answer as HelpAnswers))
    .includes(true);

  const shouldRecommendGingerCoaching =
    hasLowMoodScore && hasHighAnxietyScore && isEligibleForCoaching;

  return shouldRecommendGingerCoaching;
};

export const getEligibleCoaching = (state: RootState): boolean => {
  const fusionCoachingEligibility = checkFusionCoachingEligibility();
  const cohort = getOnboardingCohort(state);
  const country = getOnboardingCountry(state);

  if ((!cohort && !country) || !fusionCoachingEligibility) {
    return true;
  }

  return (
    cohort === AgeCohort.ADULT && isSupportedCountryForCoaching(country ?? '')
  );
};

export const getProductOfferingRecommendation = (
  state: RootState,
): OfferingType => {
  const recommendation = getShouldRecommendCoaching(state)
    ? OfferingType.GINGER
    : OfferingType.HEADSPACE;

  return recommendation;
};

export const getTopGoalAnswer = (state: RootState): any => {
  return getQuestionsAnswer(state, QuestionId.QUESTION_2_IMPORTANCE);
};
