import { Spinner } from '@headspace/web-ui-components';
import { spinnerBackgroundStyle, spinnerWrapperCss } from './styles';

interface SpinnerWithBackgroundProps {
  height: string | number;
  width: string | number;
}

export const SpinnerWithBackGround = ({
  height,
  width,
}: SpinnerWithBackgroundProps) => (
  <div css={spinnerWrapperCss}>
    <div css={spinnerBackgroundStyle(height, width)}>
      <Spinner />
    </div>
  </div>
);
