import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../../utils/constants';

interface ExtendedError extends SerializedError {
  errorStringCode: string;
}

export interface EnrollmentState {
  enrollmentResponse: Record<string, unknown> | null;
  loading: RequestStatus;
  error: null | ExtendedError;
}

const initialState = {
  enrollmentResponse: {},
  loading: RequestStatus.IDLE,
  error: null,
} as EnrollmentState;

const enrollmentSlice = createSlice({
  initialState,
  name: 'enrollment',
  reducers: {
    setEnrollmentError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setEnrollmentError } = enrollmentSlice.actions;
export default enrollmentSlice.reducer;
