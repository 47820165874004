import { CommunityContract } from '../types';

export interface CommunityContractAttributes {
  groupType?: string;
}

export function communityContractSaga({
  groupType,
}: CommunityContractAttributes): CommunityContract {
  return {
    group_type: groupType,
  };
}
