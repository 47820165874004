import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../types';

const SESSION_TIMEOUT_MODAL_REDUCER_TIME = 'sessionTimeoutModal';

interface SessionTimeoutModal {
  isOpen: boolean;
}

const initialState = {
  isOpen: false,
};

export const sessionTimeoutModalSlice = createSlice({
  initialState,
  name: SESSION_TIMEOUT_MODAL_REDUCER_TIME,
  reducers: {
    closeSessionTimeoutModal: () => initialState,
    openSessionTimeoutModal: () => ({ ...initialState, isOpen: true }),
  },
});

export const {
  closeSessionTimeoutModal,
  openSessionTimeoutModal,
} = sessionTimeoutModalSlice.actions;

export const selectSessionTimeoutModal = (
  state: RootState,
): SessionTimeoutModal => state.sessionTimeoutModal;
