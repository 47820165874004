import * as React from 'react';
import { FC, useCallback } from 'react';
import {
  containerStyle,
  inputStyle,
  subTitleStyle,
} from '../../styles/verificationStyles';
import { Input } from '../../../../../../shared-components/Input';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { SubmitButton } from '../../VerificationPage/components/SubmitButton';
import { VerificationMessage } from '../../../shared-components/VerifyBy/VerificationMessage';

interface EligibilityVerifyByEmailInputStepProps {
  email: string;
  errorMessage: string | null;
  handleChange: (event) => void;
  handleBlur: (event) => void;
  handleVerification: (event) => void;
}

export const EligibilityVerifyByEmailInputStep: FC<EligibilityVerifyByEmailInputStepProps> = ({
  email,
  errorMessage,
  handleChange,
  handleBlur,
  handleVerification,
}: EligibilityVerifyByEmailInputStepProps) => {
  const { translate } = useTranslate();

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleVerification(e);
      }
    },
    [handleVerification],
  );

  return (
    <>
      <div
        css={containerStyle}
        data-testid="eligibility-verify-by-email-input-step-container"
      >
        <div css={subTitleStyle}>
          <span>{translate('enrollment.verify.email.message')}</span>
        </div>
        <div>
          <div css={inputStyle}>
            <Input
              type="text"
              name="email"
              data-testid="verify-email-input"
              placeholder={translate('enrollment.verify.email.placeholder')}
              value={email}
              hasError={!!errorMessage}
              onChange={handleChange}
              onBlur={handleBlur}
              shouldFocusError={true}
              noLabel={true}
              aria-labelledby="verifyEmail"
              onKeyDown={handleKeyPress}
            />
          </div>
          <VerificationMessage errorMessage={errorMessage} verified={false} />
          <SubmitButton
            dataTestId="send-verification-submit-button"
            disabled={!!errorMessage}
            onClick={handleVerification}
            value={translate('enrollment.verify.email.button')}
          />
        </div>
      </div>
    </>
  );
};
