import * as React from 'react';
import { Divider, fontSizeM, Red, rem } from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { ElFileReport } from '../../../../../../../state/slices/restrictByMigration';
import { flex } from '../../../../../styles/flex';
import { bold } from '../../../../../styles/fonts';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

const reportRowStyle = css(flex, {
  padding: `${rem(0.625)} 0`,
});

const reportRowTitleStyle = css(bold, {
  marginLeft: '1%',
  width: '15%',
});

const errorReportButtonStyle = css({
  background: 'none',
  border: 'none',
  color: Red[300],
  cursor: 'pointer',
  fontSize: fontSizeM,
  padding: 0,
  textDecorationLine: 'underline',
});

interface RestrictByMigrationReportProps {
  elFileReport?: ElFileReport;
  openErrorReportModal: () => void;
}

interface RestrictByMigrationReportRowProps {
  title: string;
  value?: React.ReactNode;
}

interface RestrictByMigrationReportButtonProps {
  onClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  errorCount: number;
}

const RestrictByMigrationReportRow: React.FC<RestrictByMigrationReportRowProps> = ({
  title,
  value,
}) => {
  return (
    <div css={reportRowStyle}>
      <div css={reportRowTitleStyle}>{title}</div>
      <div>{value ?? 0}</div>
    </div>
  );
};

const RestrictByMigrationReportButton: React.FC<RestrictByMigrationReportButtonProps> = ({
  onClick,
  onKeyDown,
  errorCount,
}) => (
  <>
    {errorCount ? (
      <button
        data-testid="restrict-by-migration-report-button"
        type="button"
        onClick={onClick}
        onKeyDown={onKeyDown}
        css={errorReportButtonStyle}
      >
        {`${errorCount} »`}
      </button>
    ) : (
      <div>{errorCount}</div>
    )}
  </>
);

export const RestrictByMigrationReport: React.FC<RestrictByMigrationReportProps> = ({
  elFileReport,
  openErrorReportModal,
}) => {
  const { translate } = useTranslate();
  if (!elFileReport) {
    return null;
  }
  const {
    filename: fileName,
    added: totalAdded,
    updated: totalMatched,
    removed: totalRemoved,
    errorCount,
  } = elFileReport;

  const onKeyDownErrorReportButton = (
    event: React.KeyboardEvent<HTMLElement>,
  ) => {
    if (event.key === 'Enter') {
      openErrorReportModal();
    }
  };

  return (
    <>
      <Divider />
      <div>
        {fileName && (
          <RestrictByMigrationReportRow
            title={translate('restrictByMigrationSteps.errorReport.fileName')}
            value={fileName}
          />
        )}
        <RestrictByMigrationReportRow
          title={translate('restrictByMigrationSteps.errorReport.totalMatched')}
          value={totalMatched}
        />
        <RestrictByMigrationReportRow
          title={translate('restrictByMigrationSteps.errorReport.totalAdded')}
          value={totalAdded}
        />
        <RestrictByMigrationReportRow
          title={translate('restrictByMigrationSteps.errorReport.totalRemoved')}
          value={totalRemoved}
        />
        <RestrictByMigrationReportRow
          title={translate('restrictByMigrationSteps.errorReport.errors')}
          value={
            <RestrictByMigrationReportButton
              onKeyDown={onKeyDownErrorReportButton}
              onClick={openErrorReportModal}
              errorCount={errorCount}
            />
          }
        />
      </div>
      <Divider />
    </>
  );
};
