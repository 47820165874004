import * as R from 'ramda';
import * as Sentry from '@sentry/browser';
import translation from '../i18n/en/member-enrollment-v2.json';

export const logSentryRequestError = (error, failedCallerAndInfo: string) => {
  const errorMessage = R.pathOr(
    error,
    ['response', 'data', 'errors', '0', 'data'],
    error,
  );

  Sentry.addBreadcrumb({
    level: Sentry.Severity.Warning,
    message: `Error: Request failed in ${failedCallerAndInfo} with response ${errorMessage}`,
  });

  const translationKey = `errors.${errorMessage}`;
  if (translation[translationKey]) {
    return Sentry.captureMessage(translation[translationKey]);
  }

  if (error) {
    return Sentry.captureException(error);
  }

  return null;
};
