import { css } from '@emotion/react';
import { fontSizeS, rem, WarmGrey } from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../../constants/breakpoint';

export const immediateCrisisContainerStyle = () =>
  css({
    '& h4': {
      marginRight: rem(1.438),
    },
    alignItems: 'center',
    background: WarmGrey[100],
    border: 0,
    borderRadius: rem(0.75),
    display: 'flex',
    flexDirection: 'row',
    fontSize: fontSizeS,
    height: rem(2),
    justifyContent: 'space-between',
    maxHeight: rem(4),
    padding: `${rem(1)} ${rem(1.25)}`,
    position: 'relative',
    textAlign: 'center',
    width: 'max-content',
    [WebUIComponentsBreakPoint.Mobile]: {
      '& h4': {
        margin: 0,
      },
      flexDirection: 'column',
      height: rem(3.6875),
      margin: `${rem(3)} auto ${rem(2)}`,
      maxHeight: rem(3.6875),
      maxWidth: rem(20.4375),
      minHeight: rem(3.6875),
      padding: `${rem(1.5)} 0`,
      width: '100%',
    },
  });

export const immediateCrisisButtonStyle = () =>
  css({
    '& div': {
      marginLeft: rem(0.5),
    },
    '& span': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: fontSizeS,
    height: rem(2),
    maxHeight: rem(2),
  });

export const immediateCrisisLinkStyle = () =>
  css({
    textDecoration: 'none',
  });
