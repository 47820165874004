import * as React from 'react';
import { css } from '@emotion/react';
import {
  borderWidthXS,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { Icon } from '../../../../../shared-components/Icon';
import Plus from '../../../../../assets/plus.svg';

const dividerContainer = ({ marginTop, marginBottom }) =>
  css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: marginBottom
      ? `${marginBottom}px`
      : SpacingInRemBySize['2XL'],
    marginTop: marginTop ? `${marginTop}px` : rem(1.875),
    width: '100%',
  });

const dividerStyle = css({
  background: WarmGrey[200],
  flex: '2',
  height: rem(0.063),
  width: '40%',
});

const dividerCircle = css({
  alignItems: 'center',
  border: `${WarmGrey[200]} ${borderWidthXS} solid`,
  borderRadius: rem(1.25),
  display: 'flex',
  height: rem(1.563),
  justifyContent: 'center',
  width: rem(1.563),
});

interface HorizontalDividerProps {
  children?: React.ReactNode;
  marginTop?: number;
  marginBottom?: number;
}

export const HorizontalDivider = ({
  children,
  marginTop,
  marginBottom,
}: HorizontalDividerProps) => (
  <div css={dividerContainer({ marginBottom, marginTop })}>
    <div css={dividerStyle} />
    {children}
    <div css={dividerStyle} />
  </div>
);

export const Divider = () => (
  <HorizontalDivider>
    <div css={dividerCircle}>
      <Icon fill={WarmGrey[500]} src={Plus} width={20} height={20} />
    </div>
  </HorizontalDivider>
);
