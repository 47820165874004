import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { Dispatch } from 'redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { QuestionCard } from './QuestionCard';
import { PageWrapper } from '../onboarding-shared-components/OnboardingPageWrapper';
import { useUnifiedEligibilityBasePath } from '../../utils/routeUtils';
import { OnboardingFooter } from '../onboarding-shared-components/OnboardingFooter';
import { getUnifiedEligibilityRoutes } from '../../../../constants/routes';
import { SpinnerFullBackGround } from '../../../../../../shared-components/Spinner/SpinnerFullBackground';
import { RequestStatus } from '../../../../../../utils/constants';

import { RootState } from '../../../../../../state/types';
import {
  getMultipleChoiceQuestionAnswers,
  getOnboardingQuestions,
  getOnboardingRequestStatus,
  getShouldRecommendCoaching,
} from '../../../../state/slice/onboarding/onboardingSelector';
import {
  fetchOnboardingQuestions,
  postAnswers,
  setQuestionAnswer,
  setUserId,
  skipClarificationQuestionIfSingleAnswerSelected,
} from '../../../../state/slice/onboarding/onboardingReducer';
import {
  QuestionNavigation,
  MobileViewQuestionNavigation,
} from './QuestionNavigation';
import type { Choice, Question } from '../../../../models/OnboardingQuestions';
import {
  onboardingButtonClickthrough,
  onboardingQuestionAnsweredEvent,
  onboardingQuestionScreenViewAction,
} from '../../../../../../analytics/events/onboardingEvents';
import {
  getLocalizedQuestionSubtitle,
  getLocalizedQuestionTitle,
} from './utils/localization';
import { isClarificationQuestion } from './utils/navigation';
import {
  getHsUserId,
  getJWT,
} from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getLanguage } from '../../../../state/selectors/languageSelector/languageSelector';

const calculateAndGetProgress = (questionsAmount, currentQuestionNumber) =>
  (currentQuestionNumber / questionsAmount) * 100;

export const QuestionView: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname: relativeQuestionUrlPath } = useLocation();
  const { slug } = useUnifiedEligibilityBasePath();
  const {
    ONBOARDING_QUESTIONS_PATH,
    SCHEDULING_PATH,
    ONBOARDING_HEADSPACE_RECOMMENDED_PATH,
  } = getUnifiedEligibilityRoutes(slug);
  const questions = useSelector(getOnboardingQuestions);
  const requestStatus = useSelector(getOnboardingRequestStatus);
  const questionLength = questions.length;
  const JWT = useSelector(getJWT);
  const userId = useSelector(getHsUserId);
  const shouldRecommendGingerCoaching = useSelector(getShouldRecommendCoaching);
  const language = useSelector(getLanguage);

  const handleRecommendationNavigation = useCallback(() => {
    if (shouldRecommendGingerCoaching) {
      navigate(SCHEDULING_PATH);
    } else {
      navigate(ONBOARDING_HEADSPACE_RECOMMENDED_PATH);
    }
  }, [
    ONBOARDING_HEADSPACE_RECOMMENDED_PATH,
    SCHEDULING_PATH,
    navigate,
    shouldRecommendGingerCoaching,
  ]);

  const handleNextQuestion = useCallback(
    (event, nextQuestion, currentQuestionNumber, currentQuestionId) => {
      dispatch(onboardingButtonClickthrough(event.target.innerText));

      dispatch((dispatch: Dispatch, getState: () => RootState) => {
        const answerIds = getMultipleChoiceQuestionAnswers(
          getState(),
          currentQuestionId,
        ).map(({ text }) => text);
        dispatch(onboardingQuestionAnsweredEvent(currentQuestionId, answerIds));
      });

      if (isClarificationQuestion(nextQuestion, questions)) {
        dispatch(
          skipClarificationQuestionIfSingleAnswerSelected({
            currentQuestionId,
            navigate,
            nextQuestionPath: `${ONBOARDING_QUESTIONS_PATH}/${nextQuestion}`,
            skipQuestionPath: `${ONBOARDING_QUESTIONS_PATH}/${
              nextQuestion + 1
            }`,
          }),
        );
        return;
      }

      // Check if there are any questions left. If not redirect the user to scheduling or download headspace page
      if (currentQuestionNumber === questionLength) {
        dispatch(postAnswers(JWT));
        handleRecommendationNavigation();
      } else {
        navigate(`${ONBOARDING_QUESTIONS_PATH}/${nextQuestion}`);
      }
    },
    [
      JWT,
      ONBOARDING_QUESTIONS_PATH,
      dispatch,
      handleRecommendationNavigation,
      navigate,
      questionLength,
    ],
  );

  const handleSelectAnswer = useCallback(
    (questionId: string, title: string, id: string) => {
      const selectedQuestion = R.find(R.propEq('id', questionId))(
        questions,
      ) as Question;
      const { choices } = selectedQuestion.answers;
      const selectedAnswer = R.find(R.propEq('id', id))(choices) as Choice;
      const answer = R.omit(['translations'], selectedAnswer);

      dispatch(
        setQuestionAnswer({
          answers: [answer],
          family: selectedQuestion.family,
          id: questionId,
          title,
        }),
      );
    },
    [dispatch, questions],
  );

  const handlePreviousQuestion = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    dispatch(fetchOnboardingQuestions(JWT));

    // We only want this to be triggered once hence the empty dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setUserId(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(onboardingQuestionScreenViewAction());
  }, [relativeQuestionUrlPath, dispatch]);

  if (requestStatus === RequestStatus.SUCCEEDED) {
    return (
      <div data-testid="questions-success">
        <Routes>
          {questions.map((question) => {
            const { position, title, id, answers, family } = question;
            const currentQuestionNumber = position + 1;
            const nextQuestion = currentQuestionNumber + 1;

            return (
              <Route
                key={currentQuestionNumber}
                path={`${currentQuestionNumber}`}
                element={
                  <PageWrapper
                    progress={calculateAndGetProgress(
                      questions.length,
                      currentQuestionNumber,
                    )}
                    header={getLocalizedQuestionTitle(question, language)}
                    subHeader={getLocalizedQuestionSubtitle(question, language)}
                    footer={
                      <OnboardingFooter
                        RightColumn={() => (
                          <QuestionNavigation
                            onNextClick={(event) =>
                              handleNextQuestion(
                                event,
                                nextQuestion,
                                currentQuestionNumber,
                                id,
                              )
                            }
                            questionId={id}
                            onPreviousClick={handlePreviousQuestion}
                          />
                        )}
                        questionId={id}
                      />
                    }
                  >
                    <QuestionCard
                      title={title}
                      questionId={id}
                      key={currentQuestionNumber}
                      choices={answers.choices}
                      family={family}
                      onSelectAnswer={handleSelectAnswer}
                    />

                    <MobileViewQuestionNavigation
                      onNextClick={(event) =>
                        handleNextQuestion(
                          event,
                          nextQuestion,
                          currentQuestionNumber,
                          id,
                        )
                      }
                      questionId={id}
                      onPreviousClick={handlePreviousQuestion}
                    />
                  </PageWrapper>
                }
              />
            );
          })}
        </Routes>
      </div>
    );
  }

  if (requestStatus === RequestStatus.FAILED) {
    return (
      <div data-testid="questions-failed-message">
        oops something went wrong
      </div>
    );
  }

  return <SpinnerFullBackGround />;
};
