import axios, { AxiosPromise } from 'axios';
import { B2B_API } from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import { responseType, HTTPMethod } from '../../utils/constants';
import { UserInfoInterface } from '../../state/types/user';

export const queryUserInfo = (): AxiosPromise<UserInfoInterface> =>
  axios({
    method: HTTPMethod.GET,
    url: B2B_API.USER_INFO,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });
