import { css } from '@emotion/react';
import {
  cssWithMq,
  fontSizeM,
  fontSizeXL,
  fontSizeXS,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../../constants/breakpoint';

export const imageContainerStyle = css({
  width: rem(12.5),
  textAlign: 'center',
  color: WarmGrey[600],
});

export const serviceNameImage = css({
  [WebUIComponentsBreakPoint.Mobile]: {
    display: 'none',
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    display: 'none',
  },
});

export const pngContainerStyle = css({
  width: rem(18.75),

  [WebUIComponentsBreakPoint.Mobile]: {
    display: 'none',
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    display: 'none',
  },
});

export const subHeaderStyle = cssWithMq({
  color: WarmGrey[700],
  fontWeight: 400,
  fontSize: [fontSizeM, fontSizeXL, fontSizeXL],
  lineHeight: '150%',
});

export const pageWrapperHeaderIconMobileStyle = css({
  width: '35%',
  margin: '0 auto',

  [WebUIComponentsBreakPoint.Desktop]: {
    display: 'none',
  },
});

export const noteStyle = css({
  color: WarmGrey[700],
  fontWeight: 400,
  fontSize: fontSizeXS,
  lineHeight: '150%',

  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    marginTop: rem(3.125),
  },
});

export const buttonContainerStyle = css({
  width: rem(25),
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: rem(7.5),
  alignItems: 'center',
});

export const submitButtonStyle = css({
  width: '100vw',
});

export const textLinkContainerStyle = css({
  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  [WebUIComponentsBreakPoint.Mobile]: {
    paddingTop: SpacingInRemBySize.L,
    textAlign: 'center',
    '> a': {
      textDecoration: 'none',
    },
  },
});

export const contentContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',

  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    width: rem(37.5),
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    paddingBottom: rem(10),
  },
});
