import { css } from '@emotion/react';
import {
  cssWithMq,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import left from '../../../../../assets/left-side-footer-illustration.png';
import right from '../../../../../assets/right-side-footer-illustration.png';

const footerStyle = css({
  marginTop: SpacingInRemBySize['2XL'],
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  maxHeight: rem(9.375),
  width: '100%',
});

const rightIllustrationStyle = cssWithMq({
  display: ['none', 'block', 'block'],
});

export const Footer = () => {
  return (
    <div css={footerStyle} data-testid="footer-container">
      <img
        src={left}
        height="100px"
        alt=""
        data-testid="left-footer-illustration-graphic"
        role="presentation"
      />
      <img
        css={rightIllustrationStyle}
        src={right}
        height="100px"
        alt=""
        data-testid="right-footer-illustration-graphic"
        role="presentation"
      />
    </div>
  );
};
