import { css } from '@emotion/react';
import { SpacingInRemBySize } from '@headspace/web-ui-components';
import * as React from 'react';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import { useOpenVerificationLinkModal } from '../../../../hooks/useOpenVerificationLinkModal';
import { GenerateVerificationLinkModal } from '../../shared-components/GenerateVerificationLinkModal.tsx/GenerateVerificationLinkModal';

const wrapperStyle = css({ marginTop: SpacingInRemBySize.M });

export const GenerateVerificationLinkButton: React.FC = () => {
  const { translate } = useTranslate();
  const { closeModal, isOpen, openModal } = useOpenVerificationLinkModal();

  return (
    <div css={wrapperStyle} data-testid="verification-link-button">
      <TextButton
        onClick={openModal}
        type="button"
        value={translate(
          'organizationSettings.enrollment.generateActivationLink',
        )}
        dataTestId="verification-email-open"
      />
      {isOpen ? <GenerateVerificationLinkModal onClose={closeModal} /> : null}
    </div>
  );
};
