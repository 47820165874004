import * as React from 'react';
import { css } from '@emotion/react';
import SVG from 'react-inlinesvg';

export const iconStyle = (
  width: number,
  height: number,
  fill?: string,
  disabled?: boolean,
  inline?: boolean,
) =>
  css({
    display: inline ? 'inline-block' : 'block',
    width,
    height,
    '> span': {
      width,
      height,
      display: 'inline-block',
    },
    '> svg': fill
      ? {
          width,
          height,
          fill,
          opacity: disabled ? 0.5 : 1,
        }
      : {
          width,
          height,
        },
  });

export interface IconProps {
  width: number;
  height: number;
  fill?: string;
  src: any;
  ariaLabel?: string;
  disabled?: boolean;
  dataTestid?: string;
  inline?: boolean;
}

export const Icon: React.FC<IconProps> = ({
  width,
  height,
  fill,
  src,
  ariaLabel,
  disabled,
  dataTestid,
  inline,
}) => {
  if (ariaLabel) {
    return (
      <div
        css={iconStyle(width, height, fill, disabled, inline)}
        data-testid={dataTestid}
      >
        <SVG src={src} aria-hidden="true" aria-label={ariaLabel} />
      </div>
    );
  }
  return (
    <div
      css={iconStyle(width, height, fill, disabled, inline)}
      data-testid={dataTestid}
    >
      <SVG src={src} aria-hidden="true" />
    </div>
  );
};
