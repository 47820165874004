import { useGate } from 'statsig-react';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_rollover_bug_bash
// ONLY during a bug bash to test rollover
export const KEY = 'b2b_rollover_bug_bash';

export const useRolloverBugBash = (): [boolean, boolean] => {
  const { value, isLoading } = useGate(KEY);

  return [value, isLoading];
};
