import axios from 'axios';
import { API_HOST } from '../../../utils/constants';
import { EnrollmentVerification } from '../constants/enrollmentVerification';
import { AgeCohort } from '../constants/ageCohort';
import { ApiErrors, parseError } from '../../../utils/parseError';

export interface PostIdentityVerificationsPayload {
  enrollmentType: EnrollmentVerification;
  lastName?: string;
  preferredCountry: string;
  preferredDob: string;
  verificationToken?: string;
  workId?: string;
  isDependent: boolean;
}

export interface PostIdentityVerificationsResponse {
  cohort: AgeCohort;
  hsUserId: string | null;
}

export const postIdentityVerifications = async (
  slug: string,
  payload: PostIdentityVerificationsPayload,
) => {
  try {
    const response = await axios.post<PostIdentityVerificationsResponse>(
      `${API_HOST}/b2b/v3/orgs/${slug}/identity-verifications`,
      payload,
    );
    return response.data;
  } catch (e) {
    return Promise.reject(parseError(e as ApiErrors));
  }
};
