import env from '../../../config/env';

const { VP_HEALTH_HUB } = env;

export const Routes = {
  GINGER_SOLERA_ERROR: '/ginger-solera/land/error',
  GINGER_SOLERA_FINISH: '/ginger-solera/land/finish',
  GINGER_SOLERA_LAND: '/ginger-solera/land',
  GYMPASS_LAND: '/gympass/land',
  HEADSPACE_APP: `${env.WEB_APP}`,
  HEADSPACE_CARE_SOLERA_ERROR: '/headspacecare-solera/land/error',
  HEADSPACE_CARE_SOLERA_FINISH: '/headspacecare-solera/land/finish',
  HEADSPACE_CARE_SOLERA_LAND: '/headspacecare-solera/land',
  HEADSPACE_LOGIN: `${env.WEBSITE_HOST}/login`,
  VP_CARE_ERROR: '/vp/care/error',
  VP_CARE_LAND: '/vp/care/land',
  VP_CARE_CONSENT: '/vp/care/consent',
  VP_CARE_CONFIRMATION: '/vp/care/confirmation',
  LOGOUT_CALLBACK_REDIRECT: (slug: string): string =>
    `${env.HOST}/member-enroll/callback?${slug}`,
  SOLERA_LAND: `/solera/land`,
  SSO_LAND: `/${VP_HEALTH_HUB}/land`,
  SSO_LINK: `/${VP_HEALTH_HUB}/link`,
  SSO_LOGOUT_CALLBACK_REDIRECT: (healthProvider: string): string =>
    `${env.HOST}/member-enroll/callback?healthProvider=${healthProvider}`,
} as const;

interface UnifedEligibilityRoutes {
  [key: string]: string;
}

export const getUnifiedEligibilityRoutes = (
  slug?: string,
): UnifedEligibilityRoutes => {
  const BASE_PATH = `/v3/${slug}/member-enroll`;

  return {
    ENROLLMENT_LOADING_PATH: `${BASE_PATH}/enrollment-loading`,
    ERROR_PATH: `${BASE_PATH}/error`,
    EXISTING_ACCOUNT: `${BASE_PATH}/existing-account`,
    EXPIRED_CONTRACT: `${BASE_PATH}/expired-contract`,
    EXPIRED_DEPENDENT_LINK: `${BASE_PATH}/expired-dependent-link`,
    EXPIRED_LINK: `${BASE_PATH}/expired-link`,
    LOADING_PATH: `${BASE_PATH}/loading`,
    NEXT_STEPS_PATH: `${BASE_PATH}/next-steps`,
    NO_SEATS: `${BASE_PATH}/no-seats`,
    ONBOARDING_GINGER_RECOMMENDED_PATH: `${BASE_PATH}/onboarding/download-ginger`,
    ONBOARDING_HEADSPACE_RECOMMENDED_PATH: `${BASE_PATH}/onboarding/download-headspace`,
    ONBOARDING_PATH: `${BASE_PATH}/onboarding`,
    ONBOARDING_QUESTIONS_PATH: `${BASE_PATH}/onboarding/questions`,
    ONBOARDING_WELCOME_PATH: `${BASE_PATH}/onboarding/welcome`,
    SCHEDULING_PATH: `${BASE_PATH}/onboarding/scheduling`,
    V3_LOGIN_PATH: `${BASE_PATH}/login`,
    VERIFY_IDENTITY_ERROR_PATH: `${BASE_PATH}/verify-identity-error`,
    VERIFY_IDENTITY_PATH: `${BASE_PATH}/verify-identity`,
    VERIFY_PATH: `${BASE_PATH}/verify`,
  };
};
