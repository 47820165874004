import { css } from '@emotion/react';
import {
  borderRadiusXS,
  LegacyColors,
  Orange,
  Red,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

export const fullWidth = css({
  width: '100%',
});

export const twoThirdWidth = css({
  width: '66%',
});

export const marginBottomXlarge = css({
  marginBottom: SpacingInRemBySize['2XL'],
});

export const marginBottomLarge = css({
  marginBottom: SpacingInRemBySize.XL,
});

export const marginBottomMedium = css({
  marginBottom: SpacingInRemBySize.L,
});

export const marginBottomXsmall = css({
  marginBottom: SpacingInRemBySize.XS,
});

export const paddingMedium = css({
  padding: SpacingInRemBySize.L,
});

export const centerAlign = css({
  textAlign: 'center',
});

export const rightAlign = css({
  textAlign: 'center',
});

export const panelStyles = css({
  padding: `${SpacingInRemBySize['2XL']} ${SpacingInRemBySize['3XL']}`,
});

export const centerColumn = css({
  margin: '0 auto',
  width: rem(34),
});

export const linkStyles = css({
  ':hover': {
    textDecoration: 'underline',
  },
  color: Orange[200],
  marginLeft: rem(0.25),
  textDecoration: 'none',
});

export const titleWrapper = css({
  marginBottom: rem(1.5),
});

export const errorText = css({
  color: Red[100],
});

export const errorPanel = css({
  backgroundColor: WarmGrey[100],
  borderRadius: borderRadiusXS,
});

export const flex1 = css({
  flex: 1,
});

export const flex0 = css({
  flex: 0,
});
