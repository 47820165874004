import { SagaIterator } from 'redux-saga';
import { take, select, call, put } from 'redux-saga/effects';
import { requestDeauthenticate } from '@headspace/web-auth';
import env from '../../../../../config/env';
import {
  HOST,
  ORGS_WITH_SUBSCRIPTION_VALIDATION,
  WEBSITE_HOST,
} from '../../../../../utils/constants';
import { MemberEnrollActionTypes } from '../../actions/memberEnrollActions/memberEnrollActionTypes';
import { HealthProvider } from '../../../constants/healthProvider';
import { V3_LOGIN_PAGE } from '../../../constants';
import { getUnifiedEligibilityRoutes } from '../../../constants/routes';
import { getEmailVerificationToken } from '../../selectors/emailVerificationSelector/emailVerificationSelector';
import {
  getEmail,
  getFirstName,
  getJWT,
  getLastName,
  getPrivileges,
} from '../../selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  getConnection,
  getIsV3Enrollment,
  getOrgRestrictedBy,
  getSlug,
  getVoucher,
} from '../../selectors/orgSectionSelector/orgSectionSelector';
import { onBoardingRedirectSaga } from './onBoardingRedirectSaga';
import { ssoRedirectSaga } from './ssoRedirectSaga';
import { checkOrgLoginConditionSaga } from './checkOrgLoginConditionSaga';
import { existingPaidSubscriptionRedirectSaga } from './existingPaidSubscriptionRedirectSaga';
import { redirectToFinishPageSaga } from './redirectToFinishPageSaga';
import { webAppRedirectSaga } from './webAppRedirectSaga';
import { verifiedAccountRedirectSaga } from './verifiedAccountRedirectSaga';
import { enrollVerifyRedirectSaga } from './enrollVerifyRedirectSaga';
import { router } from '../../../../../root-component/router';

export function* memberLoginRedirectSaga(): SagaIterator {
  const {
    payload: { isSSOEnrollment, healthProvider, pageName },
  } = yield take(MemberEnrollActionTypes.INITIALIZE_LOGIN_PAGE);
  yield take(MemberEnrollActionTypes.USER_IS_LOGGED_IN);
  const emailVerificationToken = yield select(getEmailVerificationToken);
  const JWT = yield select(getJWT);
  const email = yield select(getEmail);
  const firstName = yield select(getFirstName);
  const lastName = yield select(getLastName);
  const privileges = yield select(getPrivileges);
  const memberPayVoucher = yield select(getVoucher);
  const slug = yield select(getSlug);
  const connection = yield select(getConnection);
  const isSubscriber = privileges.includes('SUBSCRIBER');
  const hasB2BContent = privileges.includes('B2B_CONTENT');
  const isFreeUser = privileges.includes('FREE_USER');
  const isB2BSubscriber = isSubscriber && hasB2BContent;
  const isB2CSubscriber = isSubscriber && !hasB2BContent;
  const isLoggedIn = JWT && email;
  const isV3Enrollment = yield select(getIsV3Enrollment);
  const { ERROR_PATH } = getUnifiedEligibilityRoutes(slug);

  // TODO: refactor this saga to handle larger chunks of related redirects to it's on saga. // https://headspace.atlassian.net/browse/PTNR-6288

  // handles all redirects from V3 login/UMD login component instance
  if (pageName === V3_LOGIN_PAGE) {
    if (isV3Enrollment) {
      const restrictedBy = yield select(getOrgRestrictedBy);
      yield call(onBoardingRedirectSaga, slug, restrictedBy, JWT);
      return;
    }
    router.navigate(ERROR_PATH);
    return;
  }

  if (isSSOEnrollment) {
    if (
      healthProvider === HealthProvider.VP ||
      healthProvider === HealthProvider.VPCARE
    ) {
      yield call(ssoRedirectSaga, healthProvider);
    }
    return;
  }

  if (isB2CSubscriber && ORGS_WITH_SUBSCRIPTION_VALIDATION.includes(slug)) {
    yield call(checkOrgLoginConditionSaga);
    return;
  }

  if (isB2CSubscriber && memberPayVoucher !== '') {
    yield call(existingPaidSubscriptionRedirectSaga);
    return;
  }

  if (isB2BSubscriber) {
    const hasMissingProfileInfo = !firstName || !lastName;

    if (connection && hasMissingProfileInfo) {
      const finishPageUrl = `${HOST}/${slug}/member-enroll/finish`;
      window.location.href = `${WEBSITE_HOST}/missing-profile-fields?redirectOnSuccess=${finishPageUrl}`;
      return;
    }
    if (connection) {
      yield call(redirectToFinishPageSaga);
      return;
    }
    yield call(webAppRedirectSaga);
    return;
  }

  if (isLoggedIn && emailVerificationToken) {
    yield call(verifiedAccountRedirectSaga, emailVerificationToken);
  }

  if (
    isLoggedIn &&
    !emailVerificationToken &&
    !!window.location.pathname.match('/member-enroll')
  ) {
    /**
     * Logged in Free users should be logged out when enrolling in an sso org.
     * This ensures the user  go though the full enrollment flow
     */
    if (isFreeUser && connection) {
      yield put(
        requestDeauthenticate(`${env.HOST}/member-enroll/callback?${slug}`),
      );
      return;
    }

    yield call(enrollVerifyRedirectSaga);
  }
}
