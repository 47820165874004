import {
  ClearCampaignFieldsAction,
  OrganizationActionsTypes,
} from '../../types/organization';
import { resetError } from '../../../utils/messageDefaults';

const { CLEAR_CAMPAIGN_FIELDS } = OrganizationActionsTypes;

export const clearCampaignFields = (): ClearCampaignFieldsAction => ({
  payload: {
    campaignDisplayName: '',
    campaignDisplayNameError: resetError(),
    campaignLaunchDate: '',
    campaignLaunchDateAfterError: resetError(),
    campaignLaunchDateError: resetError(),
    campaignLaunchDatePastError: resetError(),
  },
  type: CLEAR_CAMPAIGN_FIELDS,
});
