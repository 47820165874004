import axios, { AxiosPromise } from 'axios';
import { B2B_API } from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import { responseType, HTTPMethod } from '../../utils/constants';
import { CreateCSMUser } from '../../state/types/user';

export const createCsm = ({ name, email }): AxiosPromise<CreateCSMUser> =>
  axios({
    method: HTTPMethod.POST,
    url: B2B_API.CREATE_CSM,
    data: { name, email },
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });
