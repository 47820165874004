import { css } from '@emotion/react';
import { rem } from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  alignItemsSpaceBetween,
  columnFlex,
  justifyContentCenter,
  justifyContentSpaceBetween,
  rowFlex,
} from '../../../dashboard/styles/flex';

export const outerContainerStyle = css(
  columnFlex,
  alignItemsSpaceBetween,
  justifyContentSpaceBetween,
  {
    height: '100%',
  },
);

export const containerStyle = css(
  rowFlex,
  alignItemsCenter,
  justifyContentCenter,
  {
    minHeight: rem(40.625),
    '@media (max-width: 560px)': {
      minHeight: rem(11.25),
      margin: 0,
      padding: 0,
    },
  },
);

export const messageStyle = css(
  rowFlex,
  alignItemsCenter,
  justifyContentCenter,
  {
    height: '100%',
  },
);

export const joinContainerStyle = css({
  '@media (max-width: 800px)': {
    display: 'none',
  },
});
