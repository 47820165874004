declare global {
  interface Window {
    [key: string]: unknown;
    mParticle: {
      config: {
        isDevelopmentMode: boolean;
        useCookieStorage: boolean;
      };
      logEvent: (
        eventName: string,
        eventType: MParticleEventType,
        attributes: Record<string, unknown>,
      ) => void;
      logPageView: (
        eventName: string,
        attributes: Record<string, unknown>,
        customFlags?: Record<string, unknown>,
      ) => void;
      Identity: {
        login: any;
      };
    };
  }
}

export enum MParticleEventType {
  Navigation = 1,
  Location = 2,
  Search = 3,
  Transaction = 4,
  UserContent = 5,
  UserPreference = 6,
  Social = 7,
  Other = 8,
}

export enum ContractTypes {
  activity = 'activity',
  b2b = 'b2b',
  experiment = 'experiment',
  phi = 'phi',
  registration = 'registration', // not implmented in b2b-dashboard yet
  screenView = 'screenView',
  subscription = 'subscription', // not implmented in b2b-dashboard yet
  web = 'web',
  community = 'community',
}

// https://github.com/HeadspaceMeditation/hs-events/blob/master/contracts/screenview.json
export interface ScreenViewContract {
  screen_name: string; // This screen name
  screen_variant?: string; // variation of the screen name
  screen_view_source?: string; // The last screen the user saw before getting to this screen. For web use previous page URL excluding query params and https://www.headspace.com. Must populate unless actually null, ex: typing headspace.com directly into nav bar
  survey_cadence?: string; // represents how often this survey is delivered (daily, weekly, monthly, yearly, etc)
  survey_date?: number; // Unix string in milliseconds, of the date the user took this survey
  survey_name?: string; // Unique name for the survey
  page_url?: string;
}

// https://github.com/HeadspaceMeditation/hs-events/blob/master/contracts/web.json
export interface WebContract {
  page_referrer: string; // The Referer request header contains the address of the page making the request. When following a link, this would be the url of the page containing the link. When making AJAX requests to another domain, this would be your page's url.
  page_title: string; // This is the title of the web page; eg: home page, meditation page, headspace starbucks, etc.
  page_url: string; // This is the actual URL for web page
  previous_page?: string; // Previous page URL excluding query params and https://www.headspace.com. Must populate unless actually null, ex: typing headspace.com directly into nav bar
  domain_userid?: string; // session from GTM
}

// https://github.com/HeadspaceMeditation/hs-events/blob/master/contracts/b2b.json
export interface B2bContract {
  dependent_per_employee?: number;
  dependent_seats?: number;
  health_hub_id?: number;
  health_hub_name?: string;
  is_partial_pay?: boolean;
  org_id: number;
  org_member_custom_fields?: string;
  org_member_end_date?: string;
  org_member_start_date?: string;
  org_member_subscription_id?: number;
  org_name: string;
  org_subscription_end_date?: string;
  org_subscription_id?: number;
  org_subscription_start_date?: string;
  org_type?: string;
  plan_id?: number;
  plan_name?: string;
  promo_emails_disabled?: boolean;
  restrict_by?: string;
  salesforce_account_id?: string;
  salesforce_account_name?: string;
  salesforce_opportunity_id?: string;
  seats?: number;
  sso_enabled?: boolean;
  voucher_code?: string;
  enrollment_flow?: string;
}

export interface ExperimentContract {
  experiment_name: string;
  experiment_id: string;
  experiment_variation: string;
  experiment_variation_id: string;
}

// NOTE: rules to not forward PHI are currently built around the string value "True".
// boolean `true` will not work as intended.
export enum StringBoolean {
  True = 'True',
}

// event forwarding rules controlled here: https://app.mparticle.com/connect/app/9856/output
// https://github.com/HeadspaceMeditation/hs-events/blob/master/contracts/phi.json
export interface PHIContract {
  is_phi_data?: StringBoolean.True;
}

export enum ContractActivityType {
  experimentViewed = 'experiment viewed',
  chat = 'chat',
  consultation = 'consultation',
  contentConsumed = 'content_consumed',
  cta = 'cta',
  feedback = 'feedback',
  impression = 'impression',
  onboarding = 'onboarding',
  search = 'search',
  share = 'share',
  survey = 'survey',
  tracking = 'tracking',
  updatedLanguage = 'updated language',
  waitlist = 'waitlist',
}

export enum ContractActivityStatus {
  cameraOff = 'camera off',
  cameraOn = 'camera on',
  complete = 'complete',
  confirm = 'confirm',
  ext = 'ext',
  failed = 'failed',
  feedback = 'feedback',
  next = 'next',
  pause = 'pause',
  previous = 'previous',
  progress = 'progress',
  receive = 'receive',
  send = 'send',
  start = 'start',
  unpause = 'unpause',
  view = 'view',
}

// https://github.com/HeadspaceMeditation/hs-events/blob/master/contracts/activity.json
export interface ActivityContract {
  activity_type: ContractActivityType;
  activity_status: ContractActivityStatus;
  content_start_time?: number; // if content consumed, Unix timestamp in milliseconds of when content started playing
  content_percentage?: number; // if content consumed, value between 0-100 percent of where in the content this event occured
  playback_seconds?: number; // if content consumed, milliseconds of content play time
  playlist_start_time?: number; // if content consumed, Unix timestamp in milliseconds of when content started playing. Will be the same as content_start_time if this is the first (or only) content in the playe
  impression_source?: string; // screen name for impression events
  search_parameters?: string; // user typed keywords as they enter search
  onboarding_question?: string; // if onboarding, question shown to the user, including the screenview name
  onboarding_answer?: string; // if onboarding, the answer the user provided to onboarding question
  onboarding_question_sequence?: number; // order in series of onboarding questions
  cta_label?: string; // label of element that triggered clickthrough event
  share_label?: string; // the label of the UI element that triggered the share event, Android does not have the ability to see if the item was successfully shared so this is just on the tap event
  share_channel?: string; // the system generated name of the share event that has been completed
  buddy_message?: string; // the message sent to a user via the buddy message UI
  survey_question?: string; // A question posed to user in a survey. Will include the screenview name as well
  shorthand_survey_question?: string; // An optional shorthand version of the question to be defined by PM. Used to make creating charts in Amplitude easier, particularly with long questions.
  survey_question_type?: string; // The type of survey question posed here
  survey_question_sequence?: number; // order in series of survey questions
  survey_answer?: string; // if survey, the answer the user provided to survey question
  survey_result?: number; // number value of the survey result
  track_date?: number; // Unix timestamp of he date for which the user is adding a tracking entry. This is NOT always the current date as users often track events from previous days
  track_severity?: 1 | 2 | 3;
  track_impact?: 1 | 2 | 3 | 4;
  track_stress?: 1 | 2 | 3;
  feedback_value?: string; // positive or negative depending on the thumbs up or thumbs down tap
  journey_type?: string; // name of journey based on the user choice, Session Complete, Video, Encouragement
  prop_type?: string; // description of value prop and order in carousel ex: 1_name
  cta_link?: string; // in case of web CTAs, the destination of the button
}

export interface CommunityContract {
  group_type?: string;
}

export interface Contract
  extends Partial<ActivityContract>,
    Partial<B2bContract>,
    Partial<WebContract>,
    Partial<ScreenViewContract>,
    Partial<ExperimentContract>,
    Partial<CommunityContract> {}

export enum EapEventName {
  EapIntakeRequestSubmitted = 'EAP Intake Request Submitted',
}

export enum CaseTypes {
  Clinical = 'clinical',
  WorkLifeServices = 'work life services',
}

export enum CaseSubTypes {
  Childcare = 'childcare',
  Eldercare = 'eldercare',
  LegalServices = 'legal services',
  FinancialServices = 'financial services',
  InPersonTherapy = 'in-person therapy',
  GeneralServices = 'additional services',
}

export const CaseServiceIdNameMapping: { [key: number]: CaseSubTypes } = {
  1: CaseSubTypes.Childcare,
  2: CaseSubTypes.Eldercare,
  4: CaseSubTypes.LegalServices,
  5: CaseSubTypes.FinancialServices,
  6: CaseSubTypes.GeneralServices,
};
