import * as React from 'react';
import { Organizations } from './Organizations';
import { Header } from '../Header/Header';

export const OrganizationsTableView: React.FC = () => (
  <>
    <Header />
    <Organizations />
  </>
);
