import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { OrganizationAdministratorsEdit } from '../OrganizationAdministrators/OrganizationAdministratorsEdit';
import { createCsm } from '../../../rest';
import { resetError } from '../../../utils/messageDefaults';
import { Container } from '../../../shared-components/container/Container';
import { TitleL } from '../../../shared-components/text';
import { Header } from '../Header/Header';
import { columnFlex, flex1 } from '../styles/flex';
import { useHsOktaSSO } from '../../../featureFlags/useHsOktaSSO';

export interface CsmState {
  admin_name: string;
  admin_email: string;
  admin_nameError: Error;
  admin_emailError: Error;
}

const initialState = {
  admin_email: '',
  admin_emailError: resetError,
  admin_name: '',
  admin_nameError: resetError,
};

export const CreateSuperAdministrator = () => {
  const [adminState, setAdminState] = useState(initialState);
  const {
    admin_name,
    admin_email,
    admin_nameError,
    admin_emailError,
  } = adminState;
  const [hsSsoEnabled] = useHsOktaSSO();
  const handleReset = () => setAdminState(initialState);
  const handleSave = () => {
    createCsm({ email: admin_email, name: admin_name });
    handleReset();
  };
  const handleChange = (e: ExtendedEvent) => {
    const {
      target: { id, value },
    } = e;
    setAdminState({ ...adminState, [id]: value });
  };
  // TODO: Handle validation errors; convert to useReducer; and provide user feedback
  if (hsSsoEnabled) {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <>
      <Header />
      <Container>
        <TitleL>Add a CSM</TitleL>
        <div css={[columnFlex, flex1]}>
          <OrganizationAdministratorsEdit
            isEdit={false}
            admin_name={admin_name}
            // @ts-ignore
            admin_nameError={admin_nameError}
            admin_email={admin_email}
            // @ts-ignore
            admin_emailError={admin_emailError}
            adminSaveDisabledStatus={!admin_name && !admin_email}
            adminDeleteDisabledStatus={true}
            handleAdminDelete={() => {}}
            handleAdminCancel={handleReset}
            handleAdminSave={handleSave}
            handleChange={handleChange}
            handleError={() => {}}
            titleEnabled={false}
          />
        </div>
      </Container>
    </>
  );
};
