import * as React from 'react';
import { css } from '@emotion/react';
import {
  fontSizeS,
  fontSizeXS,
  rem,
  WarmGrey,
} from '@headspace/web-ui-components';

const labelStyle = css({
  color: WarmGrey[700],
  fontSize: fontSizeS,
  marginBottom: rem(0.188),
});

const sublabelStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeXS,
});

export interface SmallLabelSublabelProps {
  label: string;
  sublabel: string;
}

export const SmallLabelSublabel: React.FC<SmallLabelSublabelProps> = ({
  label,
  sublabel,
}) => {
  return (
    <div>
      <div css={labelStyle}>{label}</div>
      <div css={sublabelStyle}>{sublabel}</div>
    </div>
  );
};
