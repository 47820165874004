import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { postHealthProviderConnect } from '../rest-calls';
import { HealthProvider } from '../constants/healthProvider';
import { ErrorResponse, parseError } from '../../../utils/parseError';
import { setHealthProviderConnectionError } from '../state/actions/healthProviderActions/healthProviderActions';

interface IConnectHealthProviderData {
  JWT: string;
  data: {
    email?: string;
    countryCode?: string;
    partnerUserId: string | null;
    partnerId: HealthProvider;
    uniqueKey: string | null;
  };
}

interface Response {
  status: number;
}

export const useConnectHealthProvider = () => {
  const dispatch = useDispatch();
  const { mutate, ...mutationResults } = useMutation<
    Response,
    ErrorResponse,
    IConnectHealthProviderData
  >((data) => postHealthProviderConnect(data), {
    onError: (error) => {
      const healthProviderError = error as ErrorResponse;
      const err = parseError(healthProviderError);
      dispatch(setHealthProviderConnectionError(err.errorStringCode));
    },
  });

  return { mutate, ...mutationResults };
};
