import { css } from '@emotion/react';
import {
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

export const outerContainerStyle = css({
  '@media(max-width: 550px)': {
    maxWidth: rem(15.625),
  },
  color: WarmGrey[500],
  fontSize: SpacingInRemBySize.M,
  margin: rem(3.125),
  marginTop: 0,
  textAlign: 'center',
});

export const paddingStyle = css({
  marginBottom: rem(0.938),
  marginTop: rem(0.938),
});

export const continueButtonPaddingStyle = css({
  marginTop: rem(3.125),
});
