import { css } from '@emotion/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  fontSizeXS,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { Input } from '../../../../../../../shared-components/Input';
import { getMfaEnabled } from '../../../../../../../state/selectors/organizationSelectors';
import { FieldLabel } from '../../../../../../../shared-components/text';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

const noop = () => {};
const descriptionCss = css({
  fontSize: fontSizeXS,
  lineHeight: '130%',
  color: WarmGrey[700],
  marginTop: SpacingInRemBySize.M,
});

const labelStyle = css({
  marginBottom: SpacingInRemBySize.XS,
});

interface MfaEnabledInputProps {
  displayHeader?: boolean;
  editMode?: boolean;
  displayDescription?: boolean;
  onChange: (event: ExtendedEvent) => void;
}

export const MfaEnabledInput: React.FC<MfaEnabledInputProps> = ({
  displayHeader = false,
  editMode = false,
  displayDescription = false,
  onChange,
}) => {
  const { translate } = useTranslate();
  const isMfaEnabled = useSelector(getMfaEnabled);

  const value = isMfaEnabled
    ? translate('organizationSettings.enabled')
    : translate('organizationSettings.disabled');

  return (
    <>
      {displayHeader && (
        <div css={labelStyle} data-testid="mfa-enabled-input-header">
          <FieldLabel>
            {translate('organizationSettings.enrollment.mfaSectionLabel')}
          </FieldLabel>
        </div>
      )}
      <Input
        noLabel={true}
        label={translate('organizationSettings.enrollment.mfaEnabledLabel')}
        type={editMode ? 'checkbox' : 'review'}
        onChange={onChange}
        onBlur={noop}
        name="mfaEnabled"
        value={editMode ? isMfaEnabled : value}
        dataTestId="mfa-enabled-input"
      />
      {displayDescription && (
        <div css={descriptionCss} data-testid="mfa-enabled-input-description">
          {translate('organizationSettings.enrollment.mfaEnabledDescription')}
        </div>
      )}
    </>
  );
};
