import { FC, ReactNode } from 'react';
import { getCookie } from '@headspace/web-auth/dist/utils/cookie';
import { WEBSITE_HOST } from '../../../../../utils/constants';
import { translatedLink } from '../../../constants';
import {
  boxTitle,
  outerContainerStyle,
  containerStyle,
  textAlign,
  termsTextStyle,
  headerStyle,
} from './styles/formContainerStyle';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface FormContainerProps {
  children: ReactNode;
  headerTag?: string;
  headerStyleOverrides?: Record<string, any>;
  headerComponentOverride?: React.ReactElement;
  memberPay?: boolean;
  showTerms?: boolean;
  termsMessage?: boolean;
  termsComponentOverride?: React.ReactElement;
  title?: string;
  isTestVariant?: boolean;
}

export const FormContainer: FC<FormContainerProps> = ({
  children,
  headerTag,
  headerComponentOverride,
  headerStyleOverrides = {},
  memberPay = false,
  showTerms = true,
  termsMessage,
  termsComponentOverride,
  title,
  isTestVariant = false,
}) => {
  const { translate } = useTranslate();
  const langCode: string = getCookie('lang') || 'en';
  const HeaderTag = `${headerTag}` as keyof JSX.IntrinsicElements;

  const getHeader = () => {
    return title ? (
      <div css={boxTitle}>
        <HeaderTag
          css={{ ...headerStyle(isTestVariant, headerStyleOverrides) }}
          data-testid="header-title"
        >
          {title}
        </HeaderTag>
      </div>
    ) : null;
  };

  const getTermsMessage = () => {
    return termsMessage ? (
      <div>
        {memberPay &&
          translate('formContainer.teamSupportMessage.autoRenewMessage')}
        {translate('formContainer.teamSupportMessage.message1')}

        <a
          href="mailto: teamsupport@headspace.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' teamsupport@headspace.com '}
        </a>
        {translate('formContainer.teamSupportMessage.orVisit')}
        <a
          href={`https://help.headspace.com/hc/${translatedLink[langCode]}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          FAQ
        </a>
        {translate('formContainer.teamSupportMessage.message2')}
      </div>
    ) : (
      <div>
        {translate('formContainer.formContainer.message1')}
        <a
          href={`${WEBSITE_HOST}/terms-and-conditions`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {` ${translate('formContainer.formContainer.link')} `}
        </a>
        {`${translate('formContainer.formContainer.message2')} `}
        <a
          href={`${WEBSITE_HOST}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {translate('formContainer.formContainer.link2')}
        </a>
      </div>
    );
  };

  return (
    <div css={outerContainerStyle}>
      <div css={containerStyle}>
        {headerComponentOverride ?? getHeader()}
        {children}
        {showTerms ? (
          <div css={textAlign}>
            <div css={termsTextStyle}>
              {termsComponentOverride ?? getTermsMessage()}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
