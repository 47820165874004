import { useMutation } from '@tanstack/react-query';
import { orgAPIAxios } from '../../../rest/axios';
import { responseType, HTTPMethod, API_HOST } from '../../../utils/constants';
import { createAuthorizationHeader } from '../../../utils/auth';

export interface UpdatedChildOrg {
  orgMappingKey: string | null;
  orgMappingValue: string | null;
  parentOrgName: string;
  rootOrgId: number;
  country: string;
  name: string;
  salesforceId: string;
  type: string;
  ssoEnabled: boolean;
  gingerId: string;
  enrollment: {
    slug: string;
    restrictBy: string;
    restrictedDomains: string[];
    workIdFieldPlaceholderText: string;
  };
}

export const createChildOrganizationsKey = 'createChildOrganizations';

const createChildOrganizations = async (
  orgId: number,
  children: UpdatedChildOrg[],
) => {
  const { data } = await orgAPIAxios({
    method: HTTPMethod.POST,
    url: `${API_HOST}/b2b/orgs/${orgId}/children`,
    data: children,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });

  return data;
};

export const useCreateChildOrgs = () =>
  useMutation({
    mutationKey: [createChildOrganizationsKey],
    mutationFn: ({
      orgId,
      children,
    }: {
      orgId: number;
      children: UpdatedChildOrg[];
    }) => createChildOrganizations(orgId, children),
  });
