import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { CircleComponent } from './CircleComponent';
import { matchPathname } from '../../../../../utils/matchPathname';
import { EnrollmentPathname } from '../../../../../utils/constants';
import { stepperStyle } from './styles';
import { useTranslate } from '../../../../../hooks/useTranslate';

const loginOrSignUpPath = [
  EnrollmentPathname.SOLERA_LAND,
  EnrollmentPathname.GYMPASS_LAND,
];
const stepOneCompleted = [
  EnrollmentPathname.SOLERA_FINISH,
  EnrollmentPathname.SOLERA_ERROR,
  EnrollmentPathname.GYMPASS_FINISH,
  EnrollmentPathname.GYMPASS_ERROR,
];
const finishStep = [
  EnrollmentPathname.SOLERA_FINISH,
  EnrollmentPathname.SOLERA_ERROR,
  EnrollmentPathname.GYMPASS_FINISH,
  EnrollmentPathname.GYMPASS_ERROR,
];

const SoleraStepComponent: FC = () => {
  const { pathname } = useLocation();
  const { translate } = useTranslate();

  return (
    <div css={stepperStyle}>
      <CircleComponent
        step="1"
        label={translate('stepper.logIn')}
        isActive={matchPathname(pathname, loginOrSignUpPath)}
        isDone={matchPathname(pathname, stepOneCompleted)}
        dataTestId="login-step"
      />
      <CircleComponent
        step="2"
        label={translate('stepper.finish')}
        isActive={matchPathname(pathname, finishStep)}
        isDone={matchPathname(pathname, finishStep)}
        dataTestId="finish-step"
      />
    </div>
  );
};

export default SoleraStepComponent;
