import * as R from 'ramda';
import { RootState } from '../types';
import { Challenge } from '../models/organization/Challenges';

type GetChallenges = (state: RootState) => Challenge[] | [];
type GetChallengeStatus = (state: RootState) => string;

export const getChallenges: GetChallenges = (state) =>
  R.pathOr([], ['challenges', 'data'], state);

export const getChallengesFetching: GetChallenges = (state) =>
  R.pathOr(null, ['challenges', 'isFetch'], state);

export const getError: GetChallengeStatus = (state) =>
  R.pathOr('', ['challenges', 'error'], state);

export const getChallengeCreated: GetChallengeStatus = (state) =>
  R.pathOr('', ['challenges', 'created'], state);
