import * as R from 'ramda';
import { ChallengeValuesToParams } from '../types/challenges';
import { startDateToISO } from '../../apps/dashboard/utils/challenge';
import { OrganizationContractDependentsValuesToParams } from '../types/organization';
import { assureNumberValue } from '../../utils/typeMassage';

const getValue = R.path(['value']);

export const challengeValuesToParams = ({
  challengeType,
  durationInWeeks,
  goalType,
  isCreatingChallenge,
  startDate,
  goalTarget,
  timeZone,
  memberStatuses,
}: ChallengeValuesToParams) => ({
  challengeType: (getValue(challengeType) as string).trim(),
  durationInWeeks: parseInt(getValue(durationInWeeks) as string, 10),
  goalTarget: parseInt(getValue(goalTarget) as string, 10),
  goalType: (getValue(goalType) as string).trim(),
  memberStatuses: modifyMemberStatuses(getValue(memberStatuses)),
  startDate: startDateToISO(
    getValue(startDate) as string,
    getValue(timeZone) as string,
    isCreatingChallenge,
  ),
});

const modifyMemberStatuses = (value): string[] =>
  value.split(',').map((item) => item.trim());

export const organizationContractDependentValuesToParams = (
  dependentsPerMemberToMap: string,
  dependentsSeatsToMap: string,
): OrganizationContractDependentsValuesToParams => {
  const dependentSeats = !Number.isNaN(assureNumberValue(dependentsSeatsToMap))
    ? assureNumberValue(dependentsSeatsToMap)
    : 0;

  return {
    dependentSeats,
    dependentsPerMember: assureNumberValue(dependentsPerMemberToMap),
  };
};
