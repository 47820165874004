import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import {
  updateImplicitState,
  handleChange as handleChangeThunk,
} from '../../../../../../../state/actions/organizationActions';
import { ChildRestrictedInput } from '../../../../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

interface WorkIdFieldPlaceholderTextProps {
  view: boolean;
}

export const WorkIdFieldPlaceholderTextInput: React.FC<WorkIdFieldPlaceholderTextProps> = ({
  view,
}) => {
  const {
    workIdFieldPlaceholderText,
    workIdFieldPlaceholderTextError,
  } = useSelector(getOrgManagement);
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const handleError = React.useCallback(
    (event) => {
      dispatch(updateImplicitState(event));
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    (event) => {
      dispatch(handleChangeThunk(event));
    },
    [dispatch],
  );

  return (
    <ChildRestrictedInput
      label={translate(
        `organizationSettings.enrollment.customWorkIdFieldPlaceholderTextLabel`,
      )}
      placeholder={translate(
        `organizationSettings.enrollment.customWorkIdFieldPlaceholderTextPlaceHolder`,
      )}
      type={view ? 'review' : 'text'}
      onChange={handleChange}
      onBlur={handleError}
      name="workIdFieldPlaceholderText"
      value={workIdFieldPlaceholderText}
      errorMessage={workIdFieldPlaceholderTextError.message}
      hasError={workIdFieldPlaceholderTextError.error}
      required={true}
    />
  );
};
