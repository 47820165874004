import * as Sentry from '@sentry/browser';
import { ThunkDispatch } from '../../types';
import { ModalActionsTypes } from '../../types/modal';
import {
  CreateSFTPUserAction,
  OrganizationActionsTypes,
} from '../../types/organization';
import { parseError, ErrorResponse } from '../../../utils/parseError';
import { setActiveSFTPStep } from './setActiveSFTPStep';
import { SFTP_STEPS } from '../../../apps/dashboard/constants';
import { createSFTPUser as createSFTPUserApi } from '../../../rest/sftp-user/sftp-user.api';

export const createSFTPUser = (key: File) => {
  return async (dispatch: ThunkDispatch, getState: GetState) => {
    const {
      manageOrganization: { orgId, slug },
    } = getState();

    try {
      await createSFTPUserApi({ orgId, username: slug, key });

      dispatch({
        type: OrganizationActionsTypes.CREATE_SFTP_USER,
        payload: { sftpUser: { orgId, username: slug, fileName: key.name } },
      } as CreateSFTPUserAction);
    } catch (error) {
      Sentry.captureException(error);
      const { message, title } = parseError(error as ErrorResponse);
      dispatch({
        payload: {
          message,
          title,
        },
        type: ModalActionsTypes.OPEN_MODAL,
      });
      dispatch(setActiveSFTPStep(+Object.keys(SFTP_STEPS)[0]));
    }
  };
};
