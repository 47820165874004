import * as R from 'ramda';
import { RootState } from '../../../../../state/types';
import { EnrollmentVerificationState } from '../../reducers/enrollmentVerification/enrollmentVerificationReducer';
import { EnrollmentVerification } from '../../../constants/enrollmentVerification';

export const getEnrollMentVerificationSection = (
  state: RootState,
): EnrollmentVerificationState =>
  R.pathOr(null, ['enrollmentVerification'], state);

export const getVerifiedByWorkId = (state: RootState): boolean =>
  R.pathOr(
    false,
    ['enrollmentVerification', 'confirmation', 'method'],
    state,
  ) === EnrollmentVerification.ID;

export const getEnrollmentVerificationIsVerified = (
  state: RootState,
): boolean => R.pathOr(false, ['enrollmentVerification', 'isVerified'], state);

export const getVerificationMethod = (
  state: RootState,
): EnrollmentVerification =>
  R.pathOr(
    EnrollmentVerification.ID,
    ['enrollmentVerification', 'confirmation', 'method'],
    state,
  );

export const getPreferredEnrollmentSlug = (state: RootState): string | null =>
  R.pathOr(
    null,
    ['enrollmentVerification', 'confirmation', 'preferredSlug'],
    state,
  );

export const getUserIsLoggedIn = (state: RootState): boolean =>
  R.pathOr(null, ['enrollmentVerification', 'userIsLoggedIn'], state);
