import * as React from 'react';
import { useSelector } from 'react-redux';

import { Panel } from '../../../../../../shared-components/Panel';
import { Container } from '../../../../../../shared-components/container/Container';
import { getOrgManagement } from '../../../../../../state/selectors/organizationSelectors';
import { ExpiredContractsList } from './ExpiredContractsList';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { PanelTitle } from '../shared-components/SettingsPanel/PanelTitle';
import { PanelWrapper } from '../shared-components/SettingsPanel/PanelWrapper';

export const ExpiredContractsPanel: React.FC = () => {
  const { translate } = useTranslate();

  const { cachedOrg } = useSelector(getOrgManagement);
  const hasExpiredContracts = cachedOrg?.expiredContracts?.length;
  const expiredContracts = cachedOrg?.expiredContracts ?? [];

  if (hasExpiredContracts) {
    return (
      <Container>
        <ExpiredContractsList expiredContracts={expiredContracts} />
      </Container>
    );
  }

  return (
    <Container>
      <Panel dataTestId="expired-contract-panel">
        <PanelWrapper>
          <PanelTitle>
            {translate('organizationSettings.noExpiredContracts')}
          </PanelTitle>
        </PanelWrapper>
      </Panel>
    </Container>
  );
};
