import { useEffect, useState } from 'react';
import { useGate } from 'statsig-react';
import { FEATURE_FLAG } from '../constants/featureFlags';

/**
 * Asynchronous version of the useGate hook from statsig-react to automatically return true and false once loading the Statsig gate is done
 * @param key enum of the statsig gate id (e.g. FEATURE_FLAG.TERMS_AND_CONDITION)
 * @returns boolean[]
 */
export const useFeatureFlag = (key: FEATURE_FLAG) => {
  const { value, isLoading } = useGate(key);
  const [isFeatureActive, setIsFeatureActive] = useState(false);

  useEffect(() => {
    if (!isLoading && value) setIsFeatureActive(true);
  }, [isLoading, value]);

  return [isFeatureActive];
};
