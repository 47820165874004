import { Reducer } from 'redux';
import {
  GingerSoleraActions,
  GingerSoleraActionTypes,
} from '../../actions/soleraActions/gingerSoleraActionTypes';

interface SoleraState {
  error: {
    code: string;
  } | null;
  connectionInProgress: boolean;
}

const INITIAL_STATE: SoleraState = {
  connectionInProgress: false,
  error: null,
};

export const gingerSoleraReducer: Reducer = (
  state: SoleraState = INITIAL_STATE,
  action: GingerSoleraActions,
) => {
  if (
    action.type === GingerSoleraActionTypes.SET_GINGER_SOLERA_CONNECTION_ERROR
  ) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (
    action.type === GingerSoleraActionTypes.SET_GINGER_SOLERA_CONNECTION_STARTED
  ) {
    return {
      ...state,
      connectionInProgress: true,
    };
  }

  if (
    action.type ===
    GingerSoleraActionTypes.SET_GINGER_SOLERA_CONNECTION_FINISHED
  ) {
    return {
      ...state,
      connectionInProgress: false,
    };
  }

  return state;
};
