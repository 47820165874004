import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verificationSuccessScreenViewAction } from '../../../../../analytics/events/enrollmentEvents';
import { useTranslate } from '../../../../../hooks/useTranslate';
import {
  ErrorResponse,
  ParsedError,
  parseError,
} from '../../../../../utils/parseError';
import { EnrollmentVerification } from '../../../constants/enrollmentVerification';
import { usePostVpEnrollmentVerification } from '../../../hooks/usePostIndentityVerifications';
import { VpEnrollmentVerificationPayload } from '../../../rest-calls';
import { setEnrollmentVerificationConfirmation } from '../../../state/actions/memberEnrollActions/memberEnrollActions';
import EligibilityVerifyByWorkId from '../../UnifiedEligibilityViews/EligibilityVerificationPage/components/EligibilityVerifyByWorkId';
import { B2bErrorCodes } from '../../UnifiedEligibilityViews/ExceptionPages/IdentityVerificationErrorPage';
import { DobAndCountryForm } from '../../UnifiedEligibilityViews/IdentityVerificationPage/components/DobAndCountryForm';

interface VPCareVerificationStepProps {
  onComplete: () => void;
}

export const VPCareVerificationStep: FC<VPCareVerificationStepProps> = ({
  onComplete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const [errorMessage, setErrorMessage] = useState('');
  const [cachedLastName, setCachedLastName] = useState('');
  const [cachedWorkId, setCachedWorkId] = useState('');
  const [orgSlug, setOrgSlug] = useState<string | undefined>();

  const handleSuccess = (
    { data },
    request: VpEnrollmentVerificationPayload,
  ) => {
    if (data) {
      const { slug, member } = data;
      if (slug) setOrgSlug(slug);
      if (member) handleEligibleMember(slug, member.cohort);
    }
    setCachedWorkId(request.workId);
    setCachedLastName(request.lastName);
  };

  const handleError = (
    error: unknown,
    request: VpEnrollmentVerificationPayload,
  ) => {
    const parsedError = parseError(error as ErrorResponse);
    if (parsedError.errorStringCode === 'MEMBER_NOT_FOUND') {
      setErrorMessage(translate('enrollment.errors.MEMBER_NOT_FOUND'));
    }
    if (request.preferredCountry && request.preferredDob) {
      // We've tried as much as we can to match this member.
      handleIneligibleMember(parsedError);
    }
  };

  const mutation = usePostVpEnrollmentVerification(handleSuccess, handleError);

  const handleEligibleMember = useCallback(
    (slug: string, cohort) => {
      dispatch(
        setEnrollmentVerificationConfirmation({
          method: EnrollmentVerification.ID,
          lastName: cachedLastName,
          workId: cachedWorkId,
          cohort,
          preferredSlug: slug,
        }),
      );
      dispatch(verificationSuccessScreenViewAction());
      onComplete();
    },
    [cachedLastName, cachedWorkId, navigate, onComplete],
  );

  const handleIneligibleMember = useCallback(
    (error: ParsedError) => {
      const { errorStringCode, message } = error;
      let code;
      if (!errorStringCode) {
        code = {
          'More than one member found':
            B2bErrorCodes.MORE_THAN_ONE_MEMBER_FOUND,
        }[message];
      } else {
        code = errorStringCode;
      }

      navigate(`/vp/care/error?code=${code}`);
    },
    [navigate],
  );

  const handleVerifyByLastNameAndWorkId = useCallback(
    (workId: string, lastName: string) => {
      mutation.mutate({
        workId,
        lastName,
      });
    },
    [],
  );

  const handleVerifyByDobAndCountry = useCallback(
    ({ countryCode, dob }) => {
      const [mm, dd, yyyy] = dob.split('/');
      const preferredDob = `${yyyy}-${mm}-${dd}`;
      mutation.mutate({
        preferredDob,
        workId: cachedWorkId,
        lastName: cachedLastName,
        preferredCountry: countryCode,
        slug: orgSlug,
      });
    },
    [cachedWorkId, cachedLastName],
  );

  const onChangeHandler = useCallback(() => setErrorMessage(''), []);

  if (!(cachedLastName && cachedWorkId)) {
    return (
      <EligibilityVerifyByWorkId
        handleVerify={handleVerifyByLastNameAndWorkId}
        serverError={errorMessage}
        onChangeHandler={onChangeHandler}
      />
    );
  }
  return (
    <DobAndCountryForm
      title={translate('enrollment.vpCareComponent.verifyIdentity.title')}
      subtitle={translate('enrollment.vpCareComponent.verifyIdentity.subtitle')}
      handleConfirm={handleVerifyByDobAndCountry}
    />
  );
};
