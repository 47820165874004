import { css } from '@emotion/react';
import {
  fontSizeS,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  lineHeightHeading,
  rem,
  SpacingInRemBySize,
  fontFamilyCss,
  WarmGrey,
} from '@headspace/web-ui-components';
import { camelCase } from 'change-case';

export const TOOLTIP_BACKGROUND = WarmGrey[200];
export const TOOLTIP_COLOR = WarmGrey[700];
export const ARROW_SIZE = 10;
const afterSize = 11;

export const targetCss = (color: string = TOOLTIP_COLOR) => ({
  ...css({
    alignItems: 'flex-start',
    color,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: `${SpacingInRemBySize.S} ${rem(0.938)}`,
    maxWidth: rem(18.75),
  }),
});

const tooltipCss = (show: boolean, background: string = TOOLTIP_BACKGROUND) =>
  css(fontFamilyCss, {
    ':after': {
      left: '50%',
    },
    ':before': {
      left: '50%',
    },
    background,
    borderRadius: rem(0.313),
    cursor: 'pointer',
    fontSize: fontSizeS,
    fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.light,
    lineHeight: lineHeightHeading,
    pointerEvents: 'none',
    position: 'fixed', // fixed, to avoid tooltip overlapping with different parent or non-static positioned elements
    visibility: show ? 'visible' : 'hidden',
    whiteSpace: 'normal',
    zIndex: 5,
  });

const arrowCss = (size: number, color: string, position: string) => {
  const side = position === 'top' ? 'bottom' : 'top';

  return {
    border: 'solid transparent',
    borderWidth: size,
    content: "''",
    position: 'absolute',
    [camelCase(`border ${side} Color`)]: color,
  };
};

const topCss = (background: string = TOOLTIP_BACKGROUND) =>
  // @ts-ignore
  css({
    ':after': {
      ...arrowCss(afterSize, background, 'bottom'),
      bottom: -2 * afterSize + 1,
      marginLeft: -afterSize,
    },
    ':before': {
      ...arrowCss(ARROW_SIZE, 'transparent', 'bottom'),
      bottom: -2 * ARROW_SIZE,
      marginLeft: -ARROW_SIZE,
    },
  });

const bottomCss = (background: string = TOOLTIP_BACKGROUND) =>
  // @ts-ignore
  css({
    ':after': {
      ...arrowCss(afterSize, background, 'top'),
      marginLeft: -afterSize,
      top: -2 * afterSize + 1,
    },
    ':before': {
      ...arrowCss(ARROW_SIZE, 'transparent', 'top'),
      marginLeft: -ARROW_SIZE,
      top: -2 * ARROW_SIZE,
    },
  });

const arrow = (position: string, background?: string) =>
  position === 'bottom' ? bottomCss(background) : topCss(background);

export default (position: string, background?: string) => (show: boolean) =>
  css(tooltipCss(show, background), arrow(position, background));
