export enum PaymentActionTypes {
  INITIALIZE_PAYMENT = 'INITIALIZE_PAYMENT',
  GET_VOUCHER_INFO = 'GET_VOUCHER_INFO',
  GET_VOUCHER_INFO_SUCCESS = 'GET_VOUCHER_INFO_SUCCESS',
  GET_VOUCHER_INFO_ERROR = 'GET_VOUCHER_INFO_ERROR',
  FETCH_PAYMENT_METHOD = 'FETCH_PAYMENT_METHOD',
  FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS',
  FETCH_PAYMENT_METHOD_ERROR = 'FETCH_PAYMENT_METHOD_ERROR',
}

export interface InitializePaymentAction {
  type: PaymentActionTypes.INITIALIZE_PAYMENT;
}

export interface GetVoucherInfoAction {
  type: PaymentActionTypes.GET_VOUCHER_INFO;
}
