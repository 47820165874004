import {
  OrganizationActionsTypes,
  MemberEnrollmentAction,
} from '../../types/organization';

const { MEMBER_ENROLLMENT } = OrganizationActionsTypes;

export const memberEnrollment = (response: {
  status: number;
}): MemberEnrollmentAction => {
  return {
    type: MEMBER_ENROLLMENT,
    payload: response,
  };
};
