import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import {
  Button,
  ButtonType,
  White,
  Orange,
  Divider,
} from '@headspace/web-ui-components';
import { UIRegularL, UIBoldS } from '../../../shared-components/text';
import { Input, InputEventHandler } from '../../../shared-components/Input';
import { TextButton } from '../../../shared-components/buttons/TextButton';
import * as Validations from '../../../utils/validations';
import { GlobalError } from './constants';
import {
  marginBottomMedium,
  paddingMedium,
  flex0,
  flex1,
  centerAlign,
  linkStyles,
  errorText,
  errorPanel,
  marginBottomXsmall,
} from './styles';
import { en } from '../../../i18n/en';
import { cleanupUrlEncodedMessage } from './utils';
import { rowFlex } from '../styles/flex';
import { useTranslate } from '../../../hooks/useTranslate';

const buttonStyle = css({
  maxWidth: 'none !important',
  width: '100% !important',
});

interface LoginFormProps {
  errors: Validations.ErrorResp;
  email: string;
  password: string;
  onChange: InputEventHandler;
  onSubmit: (e: React.KeyboardEvent | React.MouseEvent) => void;
  onResetPassword: () => void;
  onEditEmail: InputEventHandler;
  globalError?: GlobalError;
  emailValidated: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  errors,
  email,
  password,
  onChange,
  onSubmit,
  onResetPassword,
  onEditEmail,
  globalError,
  emailValidated,
}) => {
  const { translate } = useTranslate();
  const emailError = Validations.getErrorMesssage(errors.email);
  const passwordError = Validations.getErrorMesssage(errors.password);
  const globalErrorMessage = Validations.getErrorMesssage(
    globalError && [globalError],
  );
  const authErrorMessage =
    globalError && en.translation[`login.${globalErrorMessage || ''}`]
      ? translate(`login.${globalErrorMessage}`)
      : cleanupUrlEncodedMessage(globalErrorMessage) ||
        translate('login.genericAuthError');

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [emailErrorValue, setEmailErrorValue] = useState<string | undefined>(
    '',
  );
  const [passwordErrorValue, setPasswordErrorValue] = useState<
    string | undefined
  >('');

  useEffect(() => {
    if (emailValidated) {
      handleErrors(setPasswordErrorValue, passwordError);
    } else {
      handleErrors(setEmailErrorValue, emailError);
    }
  }, [emailError, emailValidated, passwordError, submitDisabled, translate]);

  const handleErrors = (setValue, error) => {
    setValue(
      submitDisabled
        ? error && translate(`validationErrors.${error}`)
        : undefined,
    );
  };

  const onSubmitDelay = (e) => {
    e.preventDefault();

    if (emailError || (passwordError && emailValidated)) {
      setSubmitDisabled(true);
      return;
    }

    if (emailValidated) {
      onSubmit(e);
      setSubmitDisabled(true);
      setTimeout(() => {
        setSubmitDisabled(false);
      }, 5000);
    } else {
      onSubmit(e);
    }
  };

  const handleOnChange = (e) => {
    setSubmitDisabled(false);
    onChange(e);
  };

  return (
    <form onSubmit={onSubmitDelay} data-testid="login-form">
      <div css={[rowFlex, marginBottomMedium]}>
        <div css={flex1}>
          <Input
            type={emailValidated ? 'review' : 'text'}
            errorMessage={emailErrorValue}
            hasError={!!emailErrorValue}
            name="email"
            label={translate('login.email')}
            noLabel={!!emailValidated}
            value={email}
            onChange={handleOnChange}
            data-testid="login-email"
          />
        </div>
        {emailValidated && (
          <TextButton
            hollow={true}
            pill={true}
            sm={true}
            value={translate('edit')}
            onClick={onEditEmail}
            dataTestId="edit-email-button"
            css={marginBottomMedium}
          />
        )}
      </div>
      {emailValidated && <Divider />}
      {emailValidated && (
        <div>
          <div css={marginBottomXsmall}>
            <Input
              errorMessage={passwordErrorValue}
              hasError={!!passwordErrorValue}
              type="password"
              name="password"
              label={translate('login.password')}
              value={password}
              onChange={handleOnChange}
              data-testid="login-password"
            />
          </div>
          <div css={[rowFlex, marginBottomMedium]}>
            <div css={flex1} />
            <UIBoldS css={flex0}>
              <div
                css={linkStyles}
                className="forgotPassword"
                onClick={onResetPassword}
                onKeyDown={onResetPassword}
                data-testid="login-forgot-password"
                role="button"
                tabIndex={0}
              >
                {translate('login.forgotPassword')}
              </div>
            </UIBoldS>
          </div>
        </div>
      )}
      {globalErrorMessage ? (
        <div css={[marginBottomMedium, centerAlign, paddingMedium, errorPanel]}>
          <UIRegularL css={errorText} dataTestId="login-form-error-panel">
            {authErrorMessage}
          </UIRegularL>
        </div>
      ) : null}
      <Button
        type={ButtonType.SUBMIT}
        isDisabled={submitDisabled}
        fontColor={White['000']}
        backgroundColor={Orange[200]}
        fontColorHover={White['000']}
        backgroundColorHover={Orange[300]}
        isBlock={true}
        css={buttonStyle}
        dataTestIdPrefix="login"
      >
        {translate(emailValidated ? 'logIn' : 'next')}
      </Button>
    </form>
  );
};
