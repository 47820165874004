import * as Sentry from '@sentry/browser';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { UserAction, UserActionsTypes, UserInfoInterface } from '../types/user';
import * as UserActions from '../actions/userActions';
import AuthClass, { create, isValidJwtToken } from '../../auth/Auth';
import { queryUserInfo } from '../../rest';
import { setUserAdminEmail } from '../../utils/statsig';

export interface ErrorResp {
  error_description: string;
}

export const handleError = (
  store: MiddlewareAPI<Dispatch<AnyAction>, any>,
  error: Error & ErrorResp,
  action: UserAction,
) => {
  Sentry.addBreadcrumb({
    message: `Failed in UserActionsTypes.${action.type}`,
  });
  let normalizedError: Error;
  if (action.type === UserActionsTypes.USER_FETCH_INFO) {
    normalizedError = new Error('access_denied');
  } else if (error.error_description) {
    normalizedError = new Error(error.error_description);
  } else {
    normalizedError = error;
  }

  if (error.error_description === 'Wrong email or password.') {
    Sentry.captureMessage(error.error_description, Sentry.Severity.Info);
  } else {
    Sentry.captureException(normalizedError);
  }

  return store.dispatch(UserActions.userLoginError(normalizedError));
};

export const middleware: Middleware = (store) => (next) => (
  action: UserAction,
) => {
  const auth = create();

  next(action);
  switch (action.type) {
    case UserActionsTypes.USER_LOGIN:
      return auth
        .login(action.email, action.password)
        .catch((err) => handleError(store, err, action));
    case UserActionsTypes.USER_LOGIN_V2:
      return auth.loginV2().catch((err) => handleError(store, err, action));

    case UserActionsTypes.USER_AUTHORIZE:
      return auth.authorize(action.connection);

    case UserActionsTypes.USER_PROCESS: {
      const { accessToken } = action;
      if (isValidJwtToken(accessToken)) {
        AuthClass.accessToken = accessToken;
        return store.dispatch(UserActions.userLoginSuccess(accessToken));
      }

      return auth
        .checkSession()
        .then(({ accessToken }) => {
          AuthClass.accessToken = accessToken;
          return store.dispatch(UserActions.userLoginSuccess(accessToken));
        })
        .catch((err) => handleError(store, err, action));
    }

    case UserActionsTypes.USER_PROCESS_V2: {
      return auth
        .handleRedirectAndGetToken()
        .then((token) => {
          return store.dispatch(UserActions.userLoginSuccess(token));
        })
        .catch((err) => handleError(store, err, action));
    }

    case UserActionsTypes.USER_PROCESS_IDP: {
      const { connection } = action;

      if (connection === 'morgan-stanley') {
        return auth
          .getToken()
          .then((token) => {
            return store.dispatch(UserActions.userLoginSuccess(token));
          })
          .catch((err) => handleError(store, err, action));
      }

      return auth
        .loginV2(connection)
        .catch((err) => handleError(store, err, action));
    }

    case UserActionsTypes.USER_LOGIN_SUCCESS:
      return store.dispatch(UserActions.userFetchInfo());

    case UserActionsTypes.USER_FETCH_INFO:
      return queryUserInfo()
        .then((response: { data: UserInfoInterface }) => {
          setUserAdminEmail(response.data.email || null);

          return store.dispatch(UserActions.userInfo(response.data));
        })
        .catch((err) => handleError(store, err, action));

    default:
      break;
  }

  return Promise.resolve();
};
