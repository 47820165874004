import { css } from '@emotion/react';
import {
  borderRadiusM,
  cssWithMq,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';

export const ContainerStyle = cssWithMq({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: ['100vw', rem(30.125), '25vw'],
  padding: `${SpacingInRemBySize.M} ${SpacingInRemBySize.XL}`,
  backgroundColor: WarmGrey[100],
  borderRadius: [0, SpacingInRemBySize.XL, SpacingInRemBySize.XL],
});

export const ListRowStyle = css({
  display: 'flex',
});

export const ListRowItemStyle = css({
  marginLeft: rem(1.25),
});

export const centerElementStyle = css({
  justifyContent: 'center',
  textAlign: 'center',
});

export const downloadGingerMobile = css({
  display: 'block',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto',
  marginTop: rem(1.25),
});

export const downloadGinger = css({
  display: 'flex',
  borderTop: `${rem(0.15)} solid ${WarmGrey[200]}`,
  paddingTop: SpacingInRemBySize.M,
  paddingLeft: SpacingInRemBySize.XS,
});

export const stackedColumns = css({
  display: 'flex',
  flexDirection: 'column',
});

export const downloadHeadspaceMobile = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: `${SpacingInRemBySize.L} 0`,
  textAlign: 'center',
});

export const downloadHeadspace = css({
  display: 'flex',
  paddingTop: SpacingInRemBySize.M,
  margin: `${SpacingInRemBySize.M} 0`,
  paddingLeft: SpacingInRemBySize.XS,
});

export const programCardStyle = css({
  backgroundColor: White['000'],
  borderRadius: borderRadiusM,
  padding: SpacingInRemBySize.XL,
  flexDirection: 'column',
});

export const programIconStyle = css({
  marginRight: SpacingInRemBySize['2XS'],
});

export const headspaceIconContainerStyle = css({
  alignSelf: 'flex-end',
});

export const headspaceIconStyle = cssWithMq({
  height: ['inherit', SpacingInRemBySize['8XL'], SpacingInRemBySize['8XL']],
});

export const meetWithCoachStyle = css({
  borderTop: `${rem(0.15)} solid ${WarmGrey[200]}`,
  textAlign: 'center',
});

export const ListRowItemWithGapStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
});

export const onlyOnMobile = cssWithMq({
  display: ['flex', 'none', 'none'],
});

export const textBottomMargin = css({
  marginBottom: SpacingInRemBySize['2XS'],
});

export const textTopMargin = css({
  marginTop: SpacingInRemBySize['2XS'],
});

export const meetWithCoachTextBodyStyle = css(textTopMargin, {
  maxWidth: '80%',
  margin: 'auto',
  paddingBottom: SpacingInRemBySize.XS,
});
