import { css } from '@emotion/react';
import { WarmGrey, bodyXSCss } from '@headspace/web-ui-components';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

export const SetByParentLabel = ({ display }) => {
  const { translate } = useTranslate();

  return display ? (
    <span
      data-testid="set-by-parent-label"
      css={css(bodyXSCss, { color: WarmGrey[400] })}
    >
      {translate('organizationSettings.enrollment.setByParentOrg')}
    </span>
  ) : null;
};
