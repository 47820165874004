import { WebAuth } from 'auth0-js';
import { auth0ClientConfig } from '../../enrollment-config/models';

function AuthCallbackPage() {
  if (typeof window !== 'undefined') {
    const webAuth = new WebAuth(auth0ClientConfig());

    // Types are incorrect
    // Calling 'callback' with no args will
    // use the initialization options
    (webAuth.popup as any).callback();
  }

  return <div data-testid="auth-callback-page" />;
}

export default AuthCallbackPage;
