import { css } from '@emotion/react';
import {
  borderRadiusXS,
  borderWidthXS,
  CoolGrey,
  fontSizeM,
  fontSizeS,
  Grey,
  LegacyColors,
  letterSpacingWider,
  lineHeightHeading,
  Orange,
  rem,
  rgba,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import { bold, regular } from '../styles/fonts';
import { boxSizingBorderBox } from '../styles';
import { justifyContentFlexEnd } from '../styles/flex';

export const leftColumnPaddingStyle = css(boxSizingBorderBox, {
  paddingRight: rem(0.375),
});

export const rightColumnPaddingStyle = css(boxSizingBorderBox, {
  paddingLeft: rem(0.375),
});

export const inputDisableStyle = css({
  ':disabled': {
    color: rgba(WarmGrey[700], 0.4),
  },
});

export const adminRecordDisplayFrameStyle = css({
  margin: `${rem(1.75)} 0`,
  width: '100%',
});

export const adminRecordDisplayCardStyle = css(bold, {
  '& .subtext': {
    color: WarmGrey[400],
    fontSize: fontSizeS,
    lineHeight: lineHeightHeading,
    regular,
  },
  border: `${borderWidthXS} solid ${CoolGrey[100]}`,
  borderRadius: borderRadiusXS,
  color: WarmGrey[600],
  fontSize: fontSizeM,
  height: rem(5),
  lineHeight: lineHeightHeading,
  margin: `${SpacingInRemBySize['2XS']} 0`,
  padding: `0 ${rem(0.875)}`,
});

export const adminRecordDisplayCardIconStyle = (url: any) => {
  return css({
    ':hover': {
      cursor: 'pointer',
    },
    backgroundColor: White['000'],
    backgroundImage: `url(${url})`,
    backgroundPosition: 'cover',
    backgroundRepeat: 'no-repeat',
    height: rem(1.375),
    margin: `0 ${rem(0.875)}`,
    width: rem(1.375),
  });
};

export const cardNumberStyle = css({
  color: WarmGrey[400],
  margin: `0 ${SpacingInRemBySize.L} 0 ${SpacingInRemBySize.S}`,
});

export const addAnotherTextStyle = css({
  color: WarmGrey[500],
});

export const labelBoldM = css(bold, justifyContentFlexEnd, {
  fontSize: fontSizeS,
  letterSpacing: letterSpacingWider,
  lineHeight: lineHeightHeading,
  textTransform: 'uppercase',
});

export const adminRecordEditControlStyles = css({
  ' button': {
    '&.cancelAdmin': {
      ':hover': {
        ':not(:disabled)': {
          color: Orange[200],
          cursor: 'pointer',
        },
      },
      backgroundColor: White['000'],
      color: rgba(Orange[200], 0.4),
      margin: `0 ${SpacingInRemBySize.XS}`,
    },
    '&.deleteAdmin': {
      ':hover': {
        ':not(:disabled)': {
          color: Orange[200],
          cursor: 'pointer',
        },
      },
      backgroundColor: White['000'],
      color: rgba(Orange[200], 0.4),
    },
    '&.saveAdmin': {
      ':disabled': {
        color: rgba(White['000'], 0.4),
      },
      ':hover': {
        ':not(:disabled)': {
          backgroundColor: Orange[200],
          cursor: 'pointer',
        },
      },
      backgroundColor: Orange[200],
      color: White['000'],
      marginLeft: SpacingInRemBySize.XS,
    },
    height: SpacingInRemBySize['3XL'],
    width: rem(11),
  },
  margin: `${SpacingInRemBySize['3XL']} 0 0 ${SpacingInRemBySize.XS}`,
});
