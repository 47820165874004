import { css } from '@emotion/react';
import { PrimaryButton, WarmGrey } from '@headspace/web-ui-components';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { PageWrapper } from './PageWrapper';

const messageStyle = css({
  color: WarmGrey[600],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  margin: 'auto',
});

const subMessageStyle = css({
  maxWidth: '55%',
});
export const ContractExpiredPage = () => {
  const { translate } = useTranslate();
  return (
    <PageWrapper>
      <div css={messageStyle} data-testid="expired-contract-header">
        <h1>
          {translate('enrollment.exceptionPage.expiredContract.headline')}
        </h1>
        <p css={subMessageStyle} data-testid="expired-contract-sub-header">
          {translate('enrollment.exceptionPage.expiredContract.message')}
        </p>

        <a
          target="_blank"
          href="https://checkout.headspace.com/checkout?voucherCode=B2C14DANNUAL2022&origin=homepage-nav-cta"
          rel="noreferrer"
        >
          <PrimaryButton onClick={(e) => e.stopPropagation()}>
            {translate('enrollment.exceptionPage.expiredContract.button')}
          </PrimaryButton>
        </a>
      </div>
    </PageWrapper>
  );
};
