import { ThunkDispatch, GetState, ThunkResult } from '../../types';
import { hydrate } from './hydrate';

export const cancelOrganizationChanges = (): ThunkResult<void> => {
  // @ts-ignore
  return (dispatch: ThunkDispatch, getState: GetState) => {
    const {
      manageOrganization: { cachedOrg },
    } = getState();
    if (cachedOrg) dispatch(hydrate(cachedOrg));
  };
};
