import { css } from '@emotion/react';
import { SpacingInRemBySize, TextLink } from '@headspace/web-ui-components';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Input } from '../../../../../../../shared-components/Input';
import { isCSM as getIsCSM } from '../../../../../../../state/selectors/userSelectors';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { FieldLabel } from '../../../../../../../shared-components/text';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { OrganizationSettingsRoutes } from '../../../../../constants/routes';
import { labelStyle } from '../../styles';
import { columnFlex } from '../../../../../styles/flex';

const childrenWrapper = css(columnFlex);
const childrenStyle = css({ marginBottom: SpacingInRemBySize.S });

export const ChildOrgIds: React.FC = () => {
  const { translate } = useTranslate();
  const { childOrgs } = useSelector(getOrgManagement);

  return (
    <>
      <div css={labelStyle} data-testid="child-orgs-input-header">
        <FieldLabel>
          {translate('organizationSettings.orgHierarchy.childOrgs')}
        </FieldLabel>
      </div>
      <div css={childrenWrapper}>
        {childOrgs.map(({ orgId, orgName }, idx) => (
          <div key={orgId} css={childrenStyle}>
            <TextLink
              to={OrganizationSettingsRoutes.SETTINGS(orgId)}
              dataTestIdPrefix={`child-org-name-${idx}`}
            >
              {orgName}
            </TextLink>
          </div>
        ))}
      </div>
    </>
  );
};
