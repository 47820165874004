import * as React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import {
  borderRadiusCircle,
  Red,
  WarmGrey,
} from '@headspace/web-ui-components';
import { Icon } from '../Icon';

export interface IconButtonProps {
  disabled?: boolean;
  ariaLabel?: string;
  onClick?: (event: MouseEvent) => void;
  className?: string;
  src: any;
  fill: string;
  sm?: boolean;
  tabIndex?: string;
  background?: string;
  hoverBackGroundColor?: string;
}

const buttonStyle = (
  buttonSize: number,
  background,
  hoverBackGroundColor,
): SerializedStyles =>
  css({
    ':disabled svg': {
      opacity: 0.4,
    },
    ':focus': {
      outline: 0,
    },
    ':hover': {
      background: hoverBackGroundColor || WarmGrey[200],
      color: Red[400],
      cursor: 'pointer',
    },
    alignItems: 'center',
    background: background || WarmGrey[100],
    border: 'none',
    borderRadius: borderRadiusCircle,
    display: 'flex',
    height: buttonSize,
    justifyContent: 'center',
    width: buttonSize,
  });

export const IconButton: React.FC<IconButtonProps> = ({
  src,
  sm,
  ariaLabel,
  fill,
  background,
  hoverBackGroundColor,
  ...otherProps
}) => {
  const buttonSize = sm ? 32 : 48;
  const iconSize = sm ? 16 : 24;
  return (
    <button
      type="button"
      className="iconButton"
      css={buttonStyle(buttonSize, background, hoverBackGroundColor)}
      {...((otherProps as unknown) as any)}
    >
      <Icon
        src={src}
        width={iconSize}
        height={iconSize}
        fill={fill}
        ariaLabel={ariaLabel}
        dataTestid="icon"
      />
    </button>
  );
};
