import {
  ActivityContract,
  ContractActivityStatus,
  ContractActivityType,
} from '../types';

export interface ActivityContractAttributes {
  activityStatus: ContractActivityStatus;
  activityType: ContractActivityType;
  buddyMessage?: string;
  contentPercentage?: number;
  contentStartTime?: number;
  ctaLabel?: string;
  ctaLink?: string;
  feedbackValue?: string;
  impressionSource?: string;
  journeyType?: string;
  onboardingAnswer?: string;
  onboardingQuestion?: string;
  onboardingQuestionSequence?: number;
  playbackSeconds?: number;
  playlistStartTime?: number;
  searchParameters?: string;
  shareChannel?: string;
  shareLabel?: string;
  shorthandSurveyQuestion?: string;
  surveyAnswer?: string;
  surveyQuestion?: string;
  surveyQuestionSequence?: number;
  surveyQuestionType?: string;
  surveyResult?: number;
  trackDate?: number;
  trackImpact?: 1 | 2 | 3 | 4;
  trackSeverity?: 1 | 2 | 3;
  trackStress?: 1 | 2 | 3;
}

export function activityContractSaga({
  activityStatus,
  activityType,
  buddyMessage,
  contentPercentage,
  contentStartTime,
  ctaLabel,
  ctaLink,
  feedbackValue,
  impressionSource,
  journeyType,
  onboardingAnswer,
  onboardingQuestion,
  onboardingQuestionSequence,
  playbackSeconds,
  playlistStartTime,
  searchParameters,
  shareChannel,
  shareLabel,
  shorthandSurveyQuestion,
  surveyAnswer,
  surveyQuestion,
  surveyQuestionSequence,
  surveyQuestionType,
  surveyResult,
  trackDate,
  trackImpact,
  trackSeverity,
  trackStress,
}: ActivityContractAttributes): ActivityContract {
  return {
    activity_status: ContractActivityStatus[activityStatus],
    activity_type: ContractActivityType[activityType],
    buddy_message: buddyMessage,
    content_percentage: contentPercentage,
    content_start_time: contentStartTime,
    cta_label: ctaLabel,
    cta_link: ctaLink,
    feedback_value: feedbackValue,
    impression_source: impressionSource,
    journey_type: journeyType,
    onboarding_answer: onboardingAnswer,
    onboarding_question: onboardingQuestion,
    onboarding_question_sequence: onboardingQuestionSequence,
    playback_seconds: playbackSeconds,
    playlist_start_time: playlistStartTime,
    search_parameters: searchParameters,
    share_channel: shareChannel,
    share_label: shareLabel,
    shorthand_survey_question: shorthandSurveyQuestion,
    survey_answer: surveyAnswer,
    survey_question: surveyQuestion,
    survey_question_sequence: surveyQuestionSequence,
    survey_question_type: surveyQuestionType,
    survey_result: surveyResult,
    track_date: trackDate,
    track_impact: trackImpact,
    track_severity: trackSeverity,
    track_stress: trackStress,
  };
}
