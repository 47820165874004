import { FC } from 'react';
import Check from 'assets/green-circle-check.svg';
import {
  accountStatusContainerStyle,
  accountStatusConnectionStyle,
  accountStatusHolderNameStyle,
  accountStatusPillStyle,
} from '../styles';
import { Icon } from '../../../../../shared-components/Icon';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface AccountStatusProps {
  accountHolderName: string;
}
export const AccountStatus: FC<AccountStatusProps> = ({
  accountHolderName,
}) => {
  const { translate } = useTranslate();
  return (
    <div
      css={accountStatusContainerStyle}
      data-testid="account-status-container"
    >
      <div
        css={accountStatusHolderNameStyle}
        data-testid="account-status-holder-name"
      >
        {accountHolderName}
      </div>
      <div
        css={accountStatusConnectionStyle}
        data-testid="account-status-connection"
      >
        {translate('enrollment.vpCareAccountStatus.connection')}
      </div>
      <div css={accountStatusPillStyle} data-testid="account-status-pill">
        {translate('enrollment.vpCareAccountStatus.pill')}
        <Icon src={Check} width={15} height={15} />
      </div>
    </div>
  );
};
