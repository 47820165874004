import { getCookie } from '@headspace/web-auth/dist/utils/cookie';
import { CountryCode, TaxMode } from '@headspace/web-payments';
import * as R from 'ramda';
import { RootState } from '../../../../../state/types';
import { CookieKeys } from '../../../constants';

export const getCountryCode = (state: RootState) => {
  return (
    getCookie(CookieKeys.COUNTRY_CODE) ??
    R.pathOr('US', ['countryCode', 'countryCode'], state)
  );
};

export const getTaxMode = (state: RootState): TaxMode => {
  const countryCode = getCountryCode(state);
  return countryCode === CountryCode.US
    ? TaxMode.TAX_EXCLUSIVE
    : TaxMode.TAX_INCLUSIVE;
};
