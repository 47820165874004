import { css } from '@emotion/react';
import {
  borderRadiusSharp,
  borderWidthNone,
  borderWidthXS,
  fontSizeL,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { bold } from '../../../../../../dashboard/styles/fonts';
import {
  flex,
  justifyContentCenter,
  justifyContentSpaceBetween,
  alignItemsCenter,
} from '../../../../../../dashboard/styles/flex';

export const containerStyle = css({
  color: WarmGrey[500],
  padding: `0 ${rem(1.25)}`,
  textAlign: 'center',
});

export const subHeaderStyle = (big: boolean) =>
  css({
    fontSize: fontSizeL,
    marginBottom: big ? rem(1.5) : rem(4.375),
  });

export const sectionHeaderStyle = css(bold, {
  color: WarmGrey[800],
  fontSize: fontSizeL,
});

export const activityDataSectionStyle = css(
  flex,
  alignItemsCenter,
  justifyContentSpaceBetween,
  {
    marginLeft: SpacingInRemBySize.XL,
    marginRight: SpacingInRemBySize.XL,
  },
);

export const logoContainer = (big: boolean) => {
  const size = big ? '4XL' : '2XL';
  return css(flex, alignItemsCenter, justifyContentCenter, {
    height: SpacingInRemBySize[size],
  });
};

export const logo = css({
  maxHeight: '100%',
  maxWidth: '100%',
});

export const buttonContainerStyle = css({
  marginBottom: SpacingInRemBySize['2XL'],
  marginTop: rem(3.75),
});

export const pillStyle = css(flex, alignItemsCenter, {
  border: `solid ${WarmGrey[200]} ${borderWidthXS}`,
  borderRadius: rem(2.5),
  height: SpacingInRemBySize['2XL'],
  justifyContent: 'space-around',
  margin: 'auto',
  marginBottom: rem(1.25),
  paddingLeft: SpacingInRemBySize.S,
  paddingRight: SpacingInRemBySize.S,
  width: rem(7.5),
});

export const iconContainerStyle = css(
  flex,
  alignItemsCenter,
  justifyContentCenter,
  {
    background: WarmGrey[400],
    borderRadius: rem(1.25),
    height: rem(1.25),
    width: rem(1.25),
  },
);

export const customIdentitiesComponentStyle = css({
  '> div': {
    background: 'none',
    borderBottom: `${borderWidthNone} solid none`,
    borderRadius: borderRadiusSharp,
    boxShadow: 'none',
  },

  '> div .css-2kqn8c': {
    display: 'none',
  },

  '> div > div > .css-13gmu37': {
    display: 'none',
  },

  '> div > div > h3': {
    display: 'none',
  },
});
