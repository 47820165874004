import * as React from 'react';
import { TitleL } from '../../../../../../../shared-components/text';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import {
  panelCancelStyle,
  panelConfirmButtonsStyle,
  panelConfirmMessageStyle,
  panelConfirmStyle,
} from './styles';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

export interface ShowConfirmProps {
  saveChangesSelected: boolean;
  displaySaveChangesMessage?: string;
  goBack: () => void;
  confirmChanges: () => void;
}

export const ShowConfirmOverLayComponent: React.FC<ShowConfirmProps> = ({
  saveChangesSelected,
  displaySaveChangesMessage,
  goBack,
  confirmChanges,
}) => {
  const { translate } = useTranslate();

  return (
    <div>
      <div className="panelConfirm" css={panelConfirmStyle}>
        <TitleL>
          {saveChangesSelected
            ? `${translate('saveChanges')}?`
            : `${translate('discardChanges')}?`}
        </TitleL>
        <div css={panelConfirmMessageStyle}>
          {saveChangesSelected
            ? displaySaveChangesMessage
            : translate('discardChangesMessage')}
        </div>
        <div css={panelConfirmButtonsStyle}>
          <TextButton
            lg={true}
            className="goBack"
            onClick={goBack}
            value={translate('goBack')}
            css={panelCancelStyle}
          />
          <TextButton
            lg={true}
            className="confirmChanges"
            dataTestId="confirmChanges"
            onClick={confirmChanges}
            value={
              saveChangesSelected
                ? translate('saveChanges')
                : translate('discardChanges')
            }
          />
        </div>
      </div>
    </div>
  );
};
