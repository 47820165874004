import { css } from '@emotion/react';
import {
  Size,
  SpacingInRemBySize,
  fontSizeM,
  APERCU_FONT_NAME,
  letterSpacingTight,
  cssWithMq,
  fontSizeL,
  lineHeightBody,
  WarmGrey,
} from '@headspace/web-ui-components';

export const mediumButtonStyle = css({
  fontFamily: APERCU_FONT_NAME,
  fontSize: fontSizeM,
  height: SpacingInRemBySize[Size['3XL']],
  letterSpacing: letterSpacingTight,
  lineHeight: '120%',
  padding: `0 ${SpacingInRemBySize[Size.L]}`,
});

export const containerCentering = css({
  display: 'grid',
  height: '100%',
  placeItems: 'center',
  width: '100vw',
});

export const baseHSDisclaimerStyle = cssWithMq({
  color: WarmGrey[600],
  fontSize: [fontSizeM, fontSizeM, fontSizeL],
  fontWeight: '400',
  lineHeight: lineHeightBody,
});
