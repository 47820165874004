import * as React from 'react';
import { checkboxStyle, inputOptions } from './styles';

export interface CheckboxInputProps {
  customInputStyles?: any;
  dataTestId?: string;
  disabled?: boolean;
  id: string;
  label: string;
  onBlur(): void;
  onChange(): void;
  tabIndex?: number;
  value: any;
}

export const CheckboxInput: React.SFC<CheckboxInputProps> = ({
  customInputStyles,
  dataTestId,
  disabled,
  id,
  label,
  onBlur,
  onChange,
  tabIndex,
  value,
}) => {
  return (
    <label css={[checkboxStyle, inputOptions(customInputStyles)]}>
      <input
        type="checkbox"
        disabled={disabled}
        id={id}
        data-testid={dataTestId}
        onChange={onChange}
        onBlur={onBlur}
        defaultChecked={value}
        value={value}
        tabIndex={tabIndex}
      />
      <div
        role="checkbox"
        aria-label="Checkbox"
        aria-checked="false"
        className={`checkboxDisplay ${value ? 'checked' : ''}${
          disabled ? ' disabled' : ''
        }`}
      />
      <span className={`checkboxLabel${disabled ? ' disabled' : ''}`}>
        {label}
      </span>
    </label>
  );
};
