import { EnrollmentVerification } from '../../../constants/enrollmentVerification';
import { MemberEnrollActionTypes } from '../../actions/memberEnrollActions/memberEnrollActionTypes';

type PiiConfirmation = {
  method: EnrollmentVerification.PII;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  country: string;
  zipCode?: string;
};

type VPEnrollmentVerification = {
  method: EnrollmentVerification.ID;
  lastName: string;
  workId: string;
  cohort: string;
  preferredSlug: string;
};

export type Confirmation =
  | { method: EnrollmentVerification.EMAIL; verificationToken: string }
  | { method: EnrollmentVerification.ID; workId: string; lastName: string }
  | VPEnrollmentVerification
  | PiiConfirmation;

export interface EnrollmentVerificationState {
  isVerified: boolean;
  confirmation: Confirmation | null;
  error: Error | null;
  loadedAt: null | string;
  verifyIdentity: {
    preferredDateOfBirth: string;
    preferredCountryCode: string;
  } | null;
  userIsLoggedIn: boolean;
}

const INITIAL_STATE: EnrollmentVerificationState = {
  confirmation: null,
  error: null,
  isVerified: false,
  loadedAt: null,
  userIsLoggedIn: false,
  verifyIdentity: null,
};

export const enrollmentVerificationReducer = (
  state = INITIAL_STATE,
  action: Action,
): EnrollmentVerificationState => {
  switch (action.type) {
    case MemberEnrollActionTypes.USER_IS_LOGGED_IN:
      return {
        ...state,
        userIsLoggedIn: true,
      };
    case MemberEnrollActionTypes.SET_ENROLLMENT_VERIFICATION:
      return {
        ...state,
        confirmation: action.payload.confirmation,
        isVerified: true,
        loadedAt: action.payload.loadedAt,
      };
    case MemberEnrollActionTypes.CLEAR_ENROLLMENT_VERIFICATION:
      return { ...state, ...INITIAL_STATE };

    case MemberEnrollActionTypes.SET_MEMBER_DOB_AND_COUNTRY_CODE:
      return {
        ...state,
        verifyIdentity: {
          preferredDateOfBirth: action.payload.preferredDateOfBirth,
          preferredCountryCode: action.payload.preferredCountryCode,
        },
      };
    default:
      return state;
  }
};
