import { useEffect, useState } from 'react';
import { debounce } from 'debounce';

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState({
    windowInnerHeight: window.innerHeight,
    windowInnerWidth: window.innerWidth,
  });

  const resize = debounce(() => {
    setWindowSize({
      windowInnerHeight: window.innerHeight,
      windowInnerWidth: window.innerWidth,
    });
  }, 500);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};
