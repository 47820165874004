import { useCallback, MouseEventHandler } from 'react';
import * as React from 'react';
import { ConfiguredSsoLogin } from '../../AuthComponent';
import { ExistingAccountCard } from './ExistingAccountCard';
import { ConnectedYourSSOCard } from './ConnectedYourSSOCard';

interface SSOCardsProps {
  connection: string;

  isExistingAccountCardOpen: boolean;
  isConnectedYourSSOCardOpen: boolean;
  handleOpenSSOConnectedCard: MouseEventHandler;
  handleCloseQuestionCards: MouseEventHandler;
}

const SSOCards: React.FC<SSOCardsProps> = ({
  connection,

  isExistingAccountCardOpen,
  isConnectedYourSSOCardOpen,
  handleOpenSSOConnectedCard,
  handleCloseQuestionCards,
}) => {
  if (isExistingAccountCardOpen) {
    return (
      <ExistingAccountCard
        handleOpenSSOConnectedCard={handleOpenSSOConnectedCard}
        handleCloseQuestionCards={handleCloseQuestionCards}
      />
    );
  }

  if (isConnectedYourSSOCardOpen) {
    return (
      <ConnectedYourSSOCard
        handleCloseQuestionCards={handleCloseQuestionCards}
      />
    );
  }

  return <ConfiguredSsoLogin connection={connection} />;
};

interface SSOLoginViewProps {
  connection: string;
}

const SSOLogin: React.FC<SSOLoginViewProps> = ({ connection }) => {
  const [
    isExistingAccountCardOpen,
    setCloseExistingAccountCard,
  ] = React.useState(true);
  const [
    isConnectedYourSSOCardOpen,
    setCloseConnectedYourSSOCard,
  ] = React.useState(false);

  const handleCloseQuestionCards = useCallback(() => {
    setCloseExistingAccountCard(false);
    setCloseConnectedYourSSOCard(false);
  }, [setCloseExistingAccountCard]);

  const handleOpenSSOConnectedCard = useCallback(() => {
    setCloseExistingAccountCard(false);
    setCloseConnectedYourSSOCard(true);
  }, []);

  return (
    <SSOCards
      isExistingAccountCardOpen={isExistingAccountCardOpen}
      isConnectedYourSSOCardOpen={isConnectedYourSSOCardOpen}
      handleOpenSSOConnectedCard={handleOpenSSOConnectedCard}
      handleCloseQuestionCards={handleCloseQuestionCards}
      connection={connection}
    />
  );
};

export const SSOLoginView = SSOLogin;
