import { APERCU_FONT_NAME, Modal, rem } from '@headspace/web-ui-components';
import { useCallback } from 'react';
import * as React from 'react';
import { css } from '@emotion/react';
import reactHtmlParser from 'react-html-parser';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import { columnFlex } from '../../../../../styles/flex';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

interface RestrictByMigrationErrorReportModalProps {
  isOpen: boolean;
  closeModal: () => void;
  elFileReport?: any;
}

interface RestrictByMigrationErrorReportModalBodyProps {
  elFileReport?: any;
}

const modalBodyStyle = css(columnFlex, {
  fontFamily: APERCU_FONT_NAME,
  marginTop: rem(3.125),
  maxWidth: rem(22.75),
  maxHeight: rem(28.5),
});

const titleSectionStyle = css({
  textAlign: 'center',
});

const reportSectionStyle = css({
  overflow: 'scroll',
});

const confirmButtonStyle = css({
  margin: '0 auto',
});

const ModalBody: React.FC<RestrictByMigrationErrorReportModalBodyProps> = ({
  elFileReport,
}) => {
  const { translate } = useTranslate();

  const title = translate('restrictByMigrationSteps.errorModal.title');
  const subtitle = translate('restrictByMigrationSteps.errorModal.subtitle');

  const { errors } = elFileReport;

  return (
    <div css={modalBodyStyle}>
      <div css={titleSectionStyle}>
        <h2>{title}</h2>
        <h4>{subtitle}</h4>
      </div>
      <div css={reportSectionStyle}>{reactHtmlParser(errors)}</div>
    </div>
  );
};

export const RestrictByMigrationErrorReportModal: React.FC<RestrictByMigrationErrorReportModalProps> = ({
  isOpen,
  closeModal,
  elFileReport = {},
}) => {
  const { translate } = useTranslate();
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        closeModal();
      }
    },
    [closeModal],
  );

  return isOpen ? (
    <Modal
      body={<ModalBody elFileReport={elFileReport} />}
      showCloseButton={true}
      onClose={closeModal}
      footer={
        <TextButton
          dataTestId="close-error-report-modal-button"
          value={translate(
            'restrictByMigrationSteps.errorModal.confirmButtonText',
          )}
          onClick={closeModal}
          onKeyDown={onKeyDown}
          css={confirmButtonStyle}
        />
      }
    />
  ) : null;
};
