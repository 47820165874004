import {
  SpacingInRemBySize,
  WarmGrey,
  White,
  bodySCss,
  borderRadiusM,
  borderRadiusS,
  borderWidthXS,
  cssWithMq,
  fontSizeM,
  fontSizeS,
} from '@headspace/web-ui-components';

/** react-select allows you to pass in one giant styling object where the keys map to individual
 *  components in the <Select />
 *
 *  Reference: https://react-select.com/styles
 */
export const selectStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: WarmGrey['100'],
    border: `${borderWidthXS} solid ${WarmGrey['100']} `,
    borderRadius: borderRadiusS,
    color: WarmGrey['600'],
    padding: SpacingInRemBySize.M,
    textAlign: 'left',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    '> div': { padding: '0' },
  }),
  menu: (base) => ({
    ...base,

    border: `${borderWidthXS} solid ${WarmGrey['200']}`,
    borderRadius: borderRadiusM,
    boxShadow: `0 ${SpacingInRemBySize['3XS']} ${SpacingInRemBySize['2XS']} 0 rgba(20, 19, 19, 0.20)`,
  }),
  menuList: (base) => ({
    ...base,
    '::-webkit-scrollbar': {
      display: 'none' /* Chrome & Safari */,
    },
    scrollbarWidth: 'none' /* Firefox */,
  }),
  option: (base, state) => ({
    ...base,
    ...cssWithMq({
      '&:hover': { backgroundColor: WarmGrey['200'] },
      backgroundColor: state.isFocused ? WarmGrey['200'] : 'transparent',
      color: WarmGrey['700'],
      fontSize: [fontSizeS, undefined, fontSizeM],
      margin: `${SpacingInRemBySize.S} 0`,
      padding: `${SpacingInRemBySize['3XS']} ${SpacingInRemBySize.M}`,
      textAlign: 'left',
    }),
  }),
  valueContainer: (base) => ({
    ...base,
    '> div': bodySCss,
    margin: '0',
    padding: '0',
  }),
};
