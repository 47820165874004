import * as React from 'react';
import { AutoSizer, Column, Table, InfiniteLoader } from 'react-virtualized';
import { headerRowStyle } from './styles';
import { TableSectionProps } from './types';

export const TableSection: React.FC<TableSectionProps> = ({
  rows,
  headerRow,
  columnWidthOffset,
  loadMoreRows,
  rowRenderer,
}) => {
  return (
    <InfiniteLoader
      threshold={1}
      isRowLoaded={({ index }) => !!rows[index]}
      loadMoreRows={loadMoreRows}
      rowCount={1000000}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ width, height }) => (
            <Table
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              width={width > 0 ? width : 900}
              height={height > 0 ? height : 700}
              headerHeight={76}
              rowHeight={96}
              rowCount={rows.length}
              headerStyle={{ headerRowStyle }}
              rowGetter={({ index }) => rows[index]}
              rowRenderer={rowRenderer}
            >
              {headerRow &&
                headerRow.map((col: any, i: number) => {
                  return (
                    <Column
                      label={col.label}
                      dataKey={col.key}
                      key={col.key}
                      disableSort={!col.sortBy}
                      width={Math.floor(
                        (width - columnWidthOffset) / headerRow.length,
                      )}
                      cellRenderer={({ cellData }) => cellData}
                    />
                  );
                })}
            </Table>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};
