import { css } from '@emotion/react';
import {
  borderWidthM,
  borderWidthXS,
  fontSize2XS,
  Orange,
  rem,
  SpacingInRemBySize,
  White,
} from '@headspace/web-ui-components';
import env from '../../../../../config/env';

const containerStyle = (hsUserId, isCSM) =>
  css({
    display: hsUserId && isCSM ? 'flex' : 'none',
    justifyContent: 'right',
    maxWidth: rem(10),
    paddingLeft: rem(0.625),
  });

const linkStyle = (hsUserId, isCSM) =>
  css({
    ':focus': {
      boxShadow: 'none',
      outline: 'none',
    },
    ':hover': {
      background: Orange[200],
      color: White['000'],
      cursor: 'pointer',
    },
    border: `${borderWidthXS} solid ${Orange[200]}`,
    borderRadius: borderWidthM,
    color: Orange[200],
    display: hsUserId && isCSM ? 'flex' : 'none',
    fontSize: fontSize2XS,
    marginTop: SpacingInRemBySize.XS,
    padding: SpacingInRemBySize['2XS'],
    textDecoration: 'none',
  });

interface LinkProps {
  isCSM: boolean;
  hsUserId: string | null;
}

export const LinkToCrocPit = ({ isCSM, hsUserId }: LinkProps) => {
  return (
    <div css={containerStyle(hsUserId, isCSM)} data-testid="link-to-crocpit">
      <a
        css={linkStyle(hsUserId, isCSM)}
        target="_blank"
        rel="noopener noreferrer"
        href={`${env.CROCPIT_WEB_HOST}/user/#/customer/${hsUserId}/dashboard`}
        data-testid="crocpit-link-tag"
      >
        {isCSM && hsUserId}
      </a>
    </div>
  );
};
