import React, { FC } from 'react';
import { css } from '@emotion/react';
import { columnStyle } from '../../styles/pageStyles';
import {
  marketingMessageStyle,
  titleStyle,
  messageStyle,
} from '../styles/verificationPageStyleUmdWfm';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { WebUIComponentsBreakPoint } from '../../../../constants/breakpoint';
import { LearnMoreLinkUmd } from './LearnMoreLinkUmd';

type MarketingMessageUmdProps = {
  campaignDisplayName: string;
  showVPCareSubHeader?: boolean;
};

const marketingMessageContainerStyle = css(columnStyle, {
  justifyContent: 'stretch',
  [WebUIComponentsBreakPoint.Tablet]: {
    justifyContent: 'center',
  },
});

export const MarketingMessageUmd: FC<MarketingMessageUmdProps> = ({
  campaignDisplayName,
  showVPCareSubHeader = false,
}) => {
  const { translate } = useTranslate();

  return (
    <div css={marketingMessageContainerStyle}>
      <div css={marketingMessageStyle}>
        <h1 css={titleStyle}>
          {translate('enrollment.landingPage.headlineUmd')}
        </h1>
        {showVPCareSubHeader && (
          <p
            css={messageStyle}
            data-testid="marketing-message-umd-vp-care-subheader"
          >
            {translate('enrollment.vpCareLandingPage.subHeader')}
          </p>
        )}
        {campaignDisplayName && (
          <p css={messageStyle}>
            {translate('enrollment.landingPage.subHeadlineUmd', {
              campaignDisplayName,
            })}
          </p>
        )}
        <LearnMoreLinkUmd />
      </div>
    </div>
  );
};
