import { MouseEventHandler } from 'react';
import * as React from 'react';
import { YesOrNoCard } from '../../shared-components/YesOrNoCard';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface ExistingAccountCardProps {
  handleOpenSSOConnectedCard: MouseEventHandler;
  handleCloseQuestionCards: MouseEventHandler;
}

export const ExistingAccountCard: React.FC<ExistingAccountCardProps> = ({
  handleOpenSSOConnectedCard,
  handleCloseQuestionCards,
}) => {
  const { translate } = useTranslate();

  const titleTextValue = translate(
    'ssoEnrollment.accountLinkOrLogin.existing-account-question',
  );
  const yesButtonTextValue = translate(
    'ssoEnrollment.accountLinkOrLogin.yes-existing-account',
  );
  const noButtonTextValue = translate(
    'ssoEnrollment.accountLinkOrLogin.no-login-with-sso',
  );

  return (
    <YesOrNoCard
      titleTextValue={titleTextValue}
      yesButtonTextValue={yesButtonTextValue}
      handleYesButtonClick={handleOpenSSOConnectedCard}
      noButtonTextValue={noButtonTextValue}
      handleNoButtonClick={handleCloseQuestionCards}
    />
  );
};
