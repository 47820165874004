import { css } from '@emotion/react';
import {
  CoolGrey,
  FontWeight,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { flex } from '../../../../styles/flex';

export const sectionStyle = css({
  '@media(max-width: 700px)': {
    boxSizing: 'content-box',
    margin: SpacingInRemBySize.M,
    maxHeight: '100%',
  },
  paddingTop: SpacingInRemBySize['2XL'],
});

export const informationText = css(flex, {
  color: WarmGrey[700],
  marginBottom: SpacingInRemBySize['2XL'],
  marginTop: SpacingInRemBySize.S,
  wordWrap: 'break-word',
});

export const cardRow = css(flex, {
  flex: 1,
  flexWrap: 'wrap',
});

export const titleStyle = css({
  color: CoolGrey[500],
  fontSize: rem(1.75),
  fontWeight: FontWeight.BOLDER,
});
