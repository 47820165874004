import { css } from '@emotion/react';
import * as React from 'react';
import {
  lineHeightHeading,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { LabelBoldS } from '../../text';

export const tableHeaderRowStyle = css({
  alignItems: 'center',
  backgroundColor: WarmGrey[100],
  color: WarmGrey[500],
  display: 'flex',
  flexDirection: 'row',
  lineHeight: lineHeightHeading,
  padding: `${rem(0.438)} ${SpacingInRemBySize.M}`,
});

export interface BasicTableHeaderRowProps {
  children: React.ReactNode;
  className?: string;
}

export const BasicTableHeaderRow: React.FC<BasicTableHeaderRowProps> = ({
  children,
  className,
}) => (
  <LabelBoldS className={className} css={tableHeaderRowStyle}>
    {children}
  </LabelBoldS>
);
