import { MemberEnrollActionTypes } from '../../actions/memberEnrollActions/memberEnrollActionTypes';

const INITIAL_STATE = {
  error: null,
  isLoading: false,
  data: null,
  verificationToken: null,
};

export function emailVerificationTokenReducer(
  state = INITIAL_STATE,
  action: Action,
) {
  switch (action.type) {
    case MemberEnrollActionTypes.SET_EMAIL_TOKEN:
      return { ...state, verificationToken: action.payload };
    case MemberEnrollActionTypes.VERIFY_EMAIL_TOKEN_SUCCESS:
      return { ...state, data: action.payload };
    case MemberEnrollActionTypes.VERIFY_EMAIL_TOKEN_FAILED:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export type EmailVerificationTokenState = ReturnType<
  typeof emailVerificationTokenReducer
>;
