/**
 * Set of orgs with redirects for various reasons eg. slug have change because of org merge or company name change.
 * In which case members going to the old url would be redirected to the new enrollment url.
 * */

export const orgsWithEnrollmentRedirect = {
  cmg: 'ipgdxtra',
  hulu: 'disney',
  microsoftdiscount: 'microsoft',
  viacom: 'viacomcbs',
  j2global: 'ziffdavis',
  bpextended: 'bp',
  datto: 'kaseya',
  marsvhnewhires: 'vca',
  seniorlink: 'careforth',
} as const;
