import * as React from 'react';
import { FormContainer } from '../../shared-components/FormContainer/FormContainer';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import { outerContainerStyle, buttonContainer } from './styles';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface TurnOffAutoRenewMessageProps {
  companyName: string;
  handleVerify: (event: MouseEvent) => void;
}

export const TurnedOffMessage: React.FC<TurnOffAutoRenewMessageProps> = ({
  companyName,
  handleVerify,
}) => {
  const { translate } = useTranslate();

  return (
    <FormContainer
      headerTag="h4"
      title={translate('autoRenewalTurnedoff.formTitle')}
      termsMessage={true}
    >
      <div css={outerContainerStyle}>
        <div>
          <p>{translate('autoRenewalTurnedoff.message', { companyName })}</p>

          <div css={buttonContainer}>
            <TextButton
              dataTestId="next-button"
              textColor="#FFFF"
              hoverColor="#0884FF"
              backgroundColor="#0884FF"
              value={translate('next')}
              pill={true}
              width="100%"
              onClick={handleVerify}
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};
