import { css } from '@emotion/react';
import {
  fontFamilyCss,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';

export const titleSectionStyle = css({
  '& h5': {
    marginBottom: SpacingInRemBySize.XS,
  },
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: SpacingInRemBySize['2XS'],
});

export const buttonStyle = css({
  alignItems: 'center',
  display: 'flex',
});

export const flexCenterStyle = css({
  display: 'flex',
  justifyContent: 'center',
  marginRight: 0,
});

export const cellStyle = css(flexCenterStyle, {
  '> ::first-letter': {
    textTransform: 'uppercase',
  },
  flexGrow: 1,
  minHeight: SpacingInRemBySize['2XL'],
});

export const ctaContainerStyle = css({
  ' *': {
    overflow: 'visible',
  },
  flexGrow: 0.5,
  justifyContent: 'flex-end',
});

export const challengeTableRowStyle = css({
  '&.basicTableRow': {
    paddingRight: '0',
  },
});

export const createButtonSectionStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

export const hollowButtonStyle = css(fontFamilyCss, {
  fontSize: SpacingInRemBySize.S,
  letterSpacing: SpacingInRemBySize['3XS'],
  lineHeight: SpacingInRemBySize.M,
  paddingLeft: SpacingInRemBySize.L,
  paddingRight: SpacingInRemBySize.L,
  textAlign: 'center',
  textTransform: 'uppercase',
});

export const createButtonStyle = css(hollowButtonStyle, {
  paddingLeft: SpacingInRemBySize.M,
  paddingRight: SpacingInRemBySize.M,
});
