import { css } from '@emotion/react';
import * as React from 'react';
import {
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  fontSizeL,
  fontSizeM,
  fontSizeS,
  lineHeightBody,
  lineHeightHeading,
  Orange,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { Tile } from './Tile';

const titleStyles = css({
  color: WarmGrey[800],
  fontSize: fontSizeL,
  fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.bold,
  lineHeight: lineHeightBody,
  margin: `0 0 ${SpacingInRemBySize['2XS']}`,
  padding: '0',
});

const bodyStyles = css({
  fontSize: fontSizeS,
  lineHeight: lineHeightHeading,
  margin: '0',
});

const actionLabelStyles = css({
  ':hover': {
    cursor: 'pointer',
    filter: 'brightness(95%)',
  },
  backgroundColor: 'transparent',
  border: 'none',
  color: Orange[300],
  fontSize: fontSizeM,
  fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.bold,
  lineHeight: lineHeightBody,
  marginTop: SpacingInRemBySize.XL,
  padding: '0',
  textDecoration: 'underline',
});
interface PanelTilePropTypes {
  title: string;
  body: string;
  actionLabel: string;
  onClick: () => void;
}

export const PanelTile: React.FC<PanelTilePropTypes> = ({
  title,
  body,
  actionLabel,
  onClick,
}) => {
  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter') {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <Tile>
      <h3 css={titleStyles} data-testid="tile-header">
        {title}
      </h3>
      <p css={bodyStyles} data-testid="tile-body">
        {body}
      </p>
      <button
        data-testid="action-label"
        type="button"
        css={actionLabelStyles}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        {actionLabel}
      </button>
    </Tile>
  );
};
