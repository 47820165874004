import { css } from '@emotion/react';
import {
  fontSizeL,
  fontSizeS,
  fontSizeXL,
  Green,
  LegacyColors,
  Red,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { BreakPoint } from '../../../constants/breakpoint';
import { bold } from '../../../../dashboard/styles/fonts';
import { HealthProvider } from '../../../constants/healthProvider';

export const containerStyle = (isTestVariant = false) =>
  css({
    color: WarmGrey[500],
    padding: isTestVariant ? `0 ${rem(2.25)} 0` : rem(1.25),
    textAlign: 'center',
    [BreakPoint.Mobile]: {
      width: rem(18.75),
    },
  });

export const emailTextStyle = css(bold, {
  fontSize: fontSizeXL,
  overflowWrap: 'break-word',
  [BreakPoint.Mobile]: {
    width: rem(20),
  },
  paddingTop: SpacingInRemBySize.M,
});

export const emailSubLabelStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeS,
  fontWeight: 200,
  marginBottom: rem(0.625),
  marginTop: rem(0.625),
});

export const verifiedSubLabelStyle = css({
  color: Green[300],
  fontSize: fontSizeS,
  fontWeight: 800,
  marginTop: rem(0.625),
  textAlign: 'left',
});

export const errorSubLabelStyle = css({
  color: Red[100],
  fontSize: fontSizeS,
  fontWeight: 800,
  marginTop: rem(0.625),
  textAlign: 'left',
});

export const buttonContainerStyle = (isTestVariant = false) =>
  css(
    isTestVariant
      ? { height: rem(3.25), margin: `${SpacingInRemBySize['2XL']} 0 0` }
      : {
          height: rem(3.5),
          paddingBottom: rem(0.625),
          paddingTop: rem(3.25),
        },
  );

export const anchorTagStyle = css({
  textDecoration: 'none',
});

export const inputStyle = css({
  marginTop: rem(2.25),
});

export const subTitleStyle = (healthProvider = '') =>
  css(
    healthProvider === HealthProvider.VPCARE
      ? {
          fontSize: fontSizeL,
          justifyContent: 'center',
          textAlign: 'center',
        }
      : {
          fontSize: fontSizeL,
        },
  );
