import env from '../../../config/env';

export const cleanupUrlEncodedMessage = (message?: string) =>
  message && typeof message === 'string' ? message.split('_').join(' ') : '';

export const getConnection = (email: string = ''): string => {
  const ssoClients = env.SSO_CLIENTS;
  const ssoTestMatch = env.SSO_TEST_MATCH;
  const ssoTestConnection = env.SSO_TEST_CONNECTION;
  const domainMatch = email.match(/@.*/);
  const domain = domainMatch && domainMatch[0] && domainMatch[0].slice(1);
  if (email.match(ssoTestMatch)) return ssoTestConnection;
  return domain
    ? ssoClients.reduce(
        (result: string, client: { connection: string; domains: string[] }) => {
          return client.domains.includes(domain) ? client.connection : result;
        },
        '',
      )
    : '';
};
