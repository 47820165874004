import { differenceInCalendarDays } from '../../../../utils/dates';

const MIN_START_DATE_OF_CHALLENGES = 21;
const MAX_END_DATE_OF_CHALLENGES = 90;

export const validators = {
  validateStart: (start: any) => {
    const differenceInDays = differenceInCalendarDays(start, new Date());

    if (differenceInDays < MIN_START_DATE_OF_CHALLENGES) {
      return 'Challenge must be at least 3 weeks in the future!';
    }

    if (differenceInDays > MAX_END_DATE_OF_CHALLENGES) {
      return 'Challenge can be a maximum of 90 days from now!';
    }

    return false;
  },
  required: (value: any) => !value && "You can't leave this blank!",
  isNumber: (value: any) =>
    (Number.isNaN(Math.trunc(value)) || Number(value) <= 0) &&
    'Please, use only positive digits!',
};
