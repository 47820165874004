import * as React from 'react';

import { Input } from '../../../../../../../shared-components/Input';
import { HOST, EnrollmentPathname } from '../../../../../../../utils/constants';
import { PRIMARY_PRODUCT } from '../../../../../constants';
import { CopyToClipboardWrapper } from './CopyToClipboardWrapper';

export interface VPInviteUrlProps {
  healthHub: string | null;
  primaryProduct: string;
}

const host = HOST ?? '';

const createVpLandingUrl = (primaryProduct) =>
  primaryProduct === PRIMARY_PRODUCT.FUSION
    ? `${host}${EnrollmentPathname.VP_CARE_LAND}`
    : `${host}${EnrollmentPathname.VP_LAND}`;

export const VPInviteUrl: React.FC<VPInviteUrlProps> = ({
  healthHub,
  primaryProduct,
}) => {
  const vpLandUrl = createVpLandingUrl(primaryProduct);
  return (
    <div css={{ display: 'flex' }} data-testid="vp-land-url">
      <Input
        label="VP Invite URL"
        type="review"
        name="vp-url"
        value={vpLandUrl}
      />
      <CopyToClipboardWrapper view={true} enrollmentUrl={vpLandUrl} />
    </div>
  );
};
