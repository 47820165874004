import { css } from '@emotion/core';
import {
  SpacingInRemBySize,
  WarmGrey,
  bodyXSCss,
  rem,
} from '@headspace/web-ui-components';
import { FC, useCallback } from 'react';
import reactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import env from '../../../../config/env';
import { useTranslate } from '../../../../hooks/useTranslate';
import { ChildRestrictedInput } from '../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import { FileUploader } from '../../../../shared-components/FileUploader';
import { Input } from '../../../../shared-components/Input';

import { handlePrimaryProductInput } from '../../../../state/actions/handlePrimaryProductInput';
import {
  handleChange as handleChangeAction,
  handleLogoFileInput as handleLogoFileInputAction,
  updateImplicitState,
} from '../../../../state/actions/organizationActions';
import { handleGingerIdInput } from '../../../../state/actions/organizationActions/handleGingerIdInput';
import { getOrgManagement } from '../../../../state/selectors/organizationSelectors';
import { OrganizationActionsTypes } from '../../../../state/types/organization';
import { MfaEnabledInput } from '../../OrganizationView/SettingsView/components/EnrollmentSettingsPanel/components/MfaEnabledInput';
import { HealthHubSelect } from '../../OrganizationView/SettingsView/components/shared-components/HealthHubSelect/HealthHubSelect';
import { OrgCountrySelect } from '../../OrganizationView/SettingsView/components/shared-components/OrgCountrySelect/OrgCountrySelect';
import { PrimaryProductInput } from '../../OrganizationView/SettingsView/components/shared-components/PrimaryProductInput/PrimaryProductInput';
import { ORG_TYPES } from '../../constants';
import {
  formContainerStyle,
  rowStyle,
} from '../styles/createOrganizationStyles';
import { GingerIdInput } from './GingerIdInput';

const noPromoFlagged = env.FEATURE_FLAGS.includes('NO_PROMO_EMAILS');

const optionalSelectionSectionStyle = css({
  label: {
    margin: `${SpacingInRemBySize.XL} 0 0`,
  },
  marginBottom: SpacingInRemBySize.M,
});

export const inputLabelStyle = css(bodyXSCss, {
  color: WarmGrey[500],
  display: 'block',
  letterSpacing: rem(0.156),
  margin: `${SpacingInRemBySize.L} 0 ${SpacingInRemBySize.XS}`,
  textTransform: 'uppercase',
});

export const Details: FC = () => {
  const {
    current_contract_primary_product,
    logoFileName,
    logoFilePreview,
    logoFileError,
    org_name,
    org_nameError,
    org_type,
    no_promo_emails,
    healthHub,
    healthHubExternalId,
    gingerIdSynced,
    gingerId,
    gingerIdError,
    org_salesforce_id,
    org_salesforce_idError,
  } = useSelector(getOrgManagement);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const handleChange = useCallback(
    (event: ExtendedEvent) => dispatch(handleChangeAction(event)),
    [dispatch],
  );

  const handleBlurGingerId = useCallback(
    () => dispatch(handleGingerIdInput()),
    [dispatch],
  );
  const handleChangeGingerId = useCallback(
    (event) =>
      dispatch({
        payload: { gingerId: event.target.value },
        type: OrganizationActionsTypes.HANDLE_CHANGE,
      }),
    [dispatch],
  );
  const handleChangePrimaryProduct = useCallback(
    (event) => dispatch(handlePrimaryProductInput(event)),
    [dispatch],
  );

  const handleLogoFileInput = useCallback(
    (files: FileList) => dispatch(handleLogoFileInputAction(files)),
    [dispatch],
  );

  const handleError = useCallback(
    (event: ExtendedEvent) => dispatch(updateImplicitState(event)),
    [dispatch],
  );

  return (
    <div data-testid="details-page" css={formContainerStyle}>
      <div css={rowStyle}>
        <PrimaryProductInput
          showReview={false}
          onChange={handleChangePrimaryProduct}
          contractStateTerm="current"
        />

        <GingerIdInput
          label={translate(`organizationSettings.basic.gingerIdLabel`)}
          gingerId={gingerId}
          gingerIdError={gingerIdError}
          handleChange={handleChangeGingerId}
          handleBlur={handleBlurGingerId}
          handleError={handleError}
          placeholder={translate(
            `organizationSettings.basic.gingerIdPlaceholder`,
          )}
          primaryProduct={current_contract_primary_product}
        />
      </div>

      <div css={rowStyle}>
        <Input
          type="text"
          name="org_name"
          placeholder={translate(`organizationSettings.basic.namePlaceholder`)}
          label={translate(`organizationSettings.basic.nameLabel`)}
          onChange={handleChange}
          onBlur={handleError}
          value={org_name}
          hasError={org_nameError.error}
          errorMessage={org_nameError.message}
          tabIndex="0"
          autoFocus={!org_name}
          disabled={gingerIdSynced}
        />

        <ChildRestrictedInput
          type="text"
          name="org_salesforce_id"
          placeholder={translate(
            `organizationSettings.basic.salesforceIdPlaceholder`,
          )}
          label={translate(`organizationSettings.basic.salesforceIdLabel`)}
          onChange={handleChange}
          onBlur={handleError}
          value={org_salesforce_id}
          hasError={org_salesforce_idError.error}
          errorMessage={org_salesforce_idError.message}
          tabIndex="0"
          maxLength="18"
          disabled={gingerIdSynced}
        />
      </div>

      <div css={rowStyle}>
        <ChildRestrictedInput
          name="org_type"
          type="select"
          label={translate(`organizationSettings.basic.typeLabel`)}
          value={org_type}
          onChange={handleChange}
          options={ORG_TYPES.map((value: string) => {
            return {
              title: translate(`organizationSettings.basic.types.${value}`),
              value,
            };
          })}
        />
        <OrgCountrySelect
          editMode={true}
          onChange={handleChange}
          disabled={gingerIdSynced}
        />
      </div>
      <div css={optionalSelectionSectionStyle}>
        <MfaEnabledInput editMode={true} onChange={handleChange} />
      </div>
      {!noPromoFlagged && (
        <Input
          type="checkbox"
          name="no_promo_emails"
          label={translate(`organizationSettings.basic.noPromoEmailsLabel`)}
          value={no_promo_emails}
          onChange={handleChange}
          tabIndex="0"
          noLabel={true}
        />
      )}
      <div css={rowStyle}>
        <HealthHubSelect onChange={handleChange} value={healthHub} />
        {!!healthHub && (
          <Input
            type="text"
            name="healthHubExternalId"
            placeholder="ID"
            label="HEALTH HUB EXTERNAL ID"
            value={healthHubExternalId || ''}
            onChange={handleChange}
          />
        )}
      </div>
      <FileUploader
        label={translate(`organizationSettings.basic.logoLabel`)}
        requirements={translate(`organizationSettings.basic.logoDetails`)}
        instructions={reactHtmlParser(
          translate(`organizationSettings.basic.logoInstructions`),
        )}
        handleFileInput={handleLogoFileInput}
        fileName={logoFileName}
        filePreview={logoFilePreview}
        fileError={logoFileError}
      />
    </div>
  );
};
