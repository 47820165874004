import { FC, memo } from 'react';
import { FormContainer } from '../../shared-components/FormContainer/FormContainer';
import { errorContainerStyle } from '../styles';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface ErrorBoxProps {
  verificationError: {
    errorStringCode: string | null | undefined;
    message: string;
  };
}

const ErrorBox: FC<ErrorBoxProps> = ({ verificationError }) => {
  const { errorStringCode, message } = verificationError;
  const { translate } = useTranslate();

  return (
    <FormContainer termsMessage={true}>
      <div css={errorContainerStyle}>
        <h3 data-testid="error-message-text">
          {errorStringCode ? translate(`errors.${errorStringCode}`) : message}
        </h3>
      </div>
    </FormContainer>
  );
};

export default memo(ErrorBox);
