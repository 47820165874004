import * as React from 'react';
import { Panel } from '../../../../../../../shared-components/Panel';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import {
  panelHeadingStyle,
  panelHeadingTitleStyle,
  panelTwoColumnStyle,
} from '../../styles';
import { panelAction } from './styles';
import { PanelWrapper } from '../SettingsPanel/PanelWrapper';

export interface OrganizationActionPanelProps {
  available: boolean;
  index: number;
  isButtonDisabled?: boolean;
  title: string;
  buttonText: string;
  buttonAction(): void;
  buttonTestId: string;
  dataTestId?: string;
}

export const OrganizationActionPanel: React.FC<OrganizationActionPanelProps> = ({
  title,
  available,
  buttonText,
  buttonAction,
  buttonTestId,
  dataTestId,
  isButtonDisabled = false,
}) => {
  return (
    <>
      {available && (
        <Panel dataTestId={dataTestId}>
          <PanelWrapper>
            <div css={panelTwoColumnStyle}>
              <div css={panelHeadingStyle}>
                <div css={panelHeadingTitleStyle}>{title}</div>
              </div>
              <div css={panelAction}>
                <TextButton
                  dataTestId={buttonTestId}
                  disabled={isButtonDisabled}
                  className="confirmChanges"
                  value={buttonText}
                  sm={true}
                  onClick={buttonAction}
                />
              </div>
            </div>
          </PanelWrapper>
        </Panel>
      )}
    </>
  );
};
