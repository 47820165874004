import { orgAPIAxios } from '../axios';
import {
  getB2BOrgIdUrl,
  getB2BOrgUrl,
  getGingerOrgIdUrl,
} from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import { responseType } from '../../utils/constants';
import { GetGingerOrganizationByIdResponse } from '../organization/organization.types';
import { Organization } from '../../types/organization';

const NOT_FOUND = 404;

export const getOrganizationById = async (
  orgId: number,
): Promise<Organization> => {
  try {
    const response = await orgAPIAxios.get<Organization>(
      getB2BOrgIdUrl(orgId).GET_ORGANIZATION_BY_ID,
      {
        headers: createAuthorizationHeader(),
        responseType: responseType.JSON,
      },
    );
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getOrganizationByAttributes = async (
  key: string,
  value: string,
): Promise<Organization | undefined> => {
  try {
    const response = await orgAPIAxios.get<Organization>(
      getB2BOrgUrl().GET_ORGANIZATION_BY_ATTRIBUTES,
      {
        headers: createAuthorizationHeader(),
        params: { fieldName: key, fieldValue: value },
        responseType: responseType.JSON,
      },
    );
    return response.data;
  } catch (e: any) {
    if (e.response?.status === NOT_FOUND) {
      return undefined;
    }
    console.error(e);
    throw e;
  }
};

export const getGingerOrganizationById = async (
  orgId: number,
): Promise<GetGingerOrganizationByIdResponse | undefined> => {
  try {
    const response = await orgAPIAxios.get<GetGingerOrganizationByIdResponse>(
      getGingerOrgIdUrl(orgId).GET_ORGANIZATION_BY_ID,
      {
        headers: createAuthorizationHeader(),
        responseType: responseType.JSON,
      },
    );
    return response.data;
  } catch (e: any) {
    if (e.response) return e.response.data;

    console.error(e);
    throw e;
  }
};
