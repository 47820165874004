import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from '../../../../../../../shared-components/Input';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { handleNullString } from '../../../../../../../state/helpers';
import {
  updateImplicitState,
  handleChange as handleChangeThunk,
} from '../../../../../../../state/actions/organizationActions';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { SetByParentLabel } from './SetByParentLabel';

interface OverFlowURLProps {
  view: boolean;
}

export const OverFlowURLInput: React.FC<OverFlowURLProps> = ({ view }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const {
    overflow_url,
    overflow_urlError,
    overflowDisabled,
    useSingleEnrollmentLink,
    parentOrg,
  } = useSelector(getOrgManagement);

  const isChildOrg = !!parentOrg;

  const updatedOverflowUrl = parentOrg?.useSingleEnrollmentLink
    ? parentOrg?.enrollment?.overflowUrl
    : overflow_url;

  const handleError = React.useCallback(
    (event) => {
      dispatch(updateImplicitState(event));
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    (event) => {
      dispatch(handleChangeThunk(event));
    },
    [dispatch],
  );

  return (
    <div>
      <Input
        label={translate(`organizationSettings.enrollment.overflowLabel`)}
        placeholder={translate(
          `organizationSettings.enrollment.overflowPlaceholder`,
        )}
        type={view ? 'review' : 'url'}
        onChange={handleChange}
        onBlur={handleError}
        name="overflow_url"
        value={
          view && overflowDisabled
            ? translate(`organizationSettings.enrollment.overflowDisabled`)
            : handleNullString(updatedOverflowUrl)
        }
        hasError={overflow_urlError.error}
        errorMessage={overflow_urlError.message}
        disabled={overflowDisabled || (useSingleEnrollmentLink && isChildOrg)}
        data-testid="overflow_url-input"
      />
      <SetByParentLabel display={useSingleEnrollmentLink && isChildOrg} />
    </div>
  );
};
