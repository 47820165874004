import { useCallback, useMemo } from 'react';
import * as React from 'react';
import * as R from 'ramda';
import { Spinner } from '@headspace/web-ui-components';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import {
  dropdownDrawerStyles,
  dropdownStyles,
  inputOptions,
  inputStyles,
  selectItemCardStyles,
  selectStyles,
  width100Style,
  iconStyle,
} from './styles';
import { regular } from '../../apps/dashboard/styles/fonts';

export interface SelectInputProps {
  onChange(event: ExtendedEvent): void;
  onClick(): void;
  id: string;
  label: string;
  tabIndex?: number;
  options: Option[];
  customInputStyles?: any;
  value: any;
  disabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  searchValue?: string;
  handleSearch?(event: any): void;
  searchPlaceholder?: string;
  noResultsFound?: string;
}

export const SelectInput: React.FC<SelectInputProps> = ({
  onChange,
  options,
  id,
  value,
  customInputStyles,
  handleSearch,
  searchPlaceholder,
  disabled = false,
  isLoading = false,
  isSearchable = false,
  searchValue = '',
  noResultsFound = '',
  ...passedProps
}) => {
  const [node, open, setOpen] = useOutsideClickHandler(false);
  const coverValue = useMemo(() => R.find(R.propEq('value', value))(options), [
    options,
    value,
  ]);
  const handleOpenStateChange = useCallback(() => {
    if (!disabled) {
      setOpen(!open);
    }
  }, [disabled, open, setOpen]);

  const handleSelectClick = useCallback(
    (event: ExtendedEvent) => {
      const { target } = event;
      const className = /selectOption/;

      const validTarget =
        target &&
        target.className &&
        className.exec(target.className) &&
        typeof target.id === 'string';

      if (
        target.className &&
        target.className.split &&
        target.className.split(' ').includes('open')
      ) {
        return;
      }

      if (validTarget && node.current && node.current.contains(target)) {
        onChange({ target: { id, value: target.id } });
      }
      setOpen(false);
    },
    [id, node, onChange, setOpen],
  );

  const handleSelectClickIfNotDisabled = useCallback(
    (e) => {
      if (!disabled) {
        handleSelectClick(e);
      }
    },
    [handleSelectClick, disabled],
  );

  const onKeyPressHandleOpenStateChange = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleOpenStateChange();
      }
    },
    [handleOpenStateChange],
  );

  const onKeyPressHandleSelectClick = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (!disabled) {
          handleSelectClick(e);
        }
      }
    },
    [handleSelectClick, disabled],
  );

  return (
    <div data-testid={passedProps['data-testid']} css={width100Style}>
      {!isSearchable ? (
        <button
          css={[
            inputStyles(false, disabled),
            selectStyles(disabled),
            inputOptions(customInputStyles),
          ]}
          className={`select${open ? ' open' : ''}${
            disabled ? ' disabled' : ''
          }`}
          onClick={handleOpenStateChange}
          onKeyPress={onKeyPressHandleOpenStateChange}
          type="button"
          data-testid="dropdown-open-button"
          data-id={`${id}-dropdown-open-button`}
          tabIndex={0}
          value={coverValue?.value}
        >
          {coverValue ? coverValue.title || coverValue.value : ''}
          {coverValue && coverValue.icon ? (
            <img
              css={iconStyle}
              src={coverValue.icon}
              alt=""
              role="presentation"
            />
          ) : null}
        </button>
      ) : (
        <input
          type="search"
          placeholder={searchPlaceholder}
          disabled={disabled}
          onChange={handleSearch}
          onClick={handleOpenStateChange}
          data-testid="dropdown-open-button"
          onKeyPress={onKeyPressHandleOpenStateChange}
          value={!open ? value : searchValue}
          css={[
            inputStyles(false, disabled),
            regular,
            inputOptions(customInputStyles),
          ]}
        />
      )}
      {open && (
        <div css={[dropdownDrawerStyles, dropdownStyles]} ref={node}>
          {isLoading ? (
            <div css={selectItemCardStyles}>
              <Spinner size={30} />
            </div>
          ) : options.length ? (
            options.map(
              ({ value: optionValue, title, disabled, icon }: Option) => (
                <button
                  css={selectItemCardStyles}
                  onClick={handleSelectClickIfNotDisabled}
                  onKeyPress={onKeyPressHandleSelectClick}
                  type="button"
                  tabIndex={0}
                  className={`selectOption${
                    value === optionValue ? ' selected' : ''
                  }${disabled ? ' disabled' : ''}`}
                  id={optionValue}
                  key={optionValue}
                  data-testid={`${id}-${optionValue}-select-option`}
                  value={optionValue}
                >
                  <div className="selectOption" id={optionValue}>
                    {title || optionValue}
                    <img
                      css={iconStyle}
                      src={icon}
                      alt=""
                      role="presentation"
                    />
                  </div>
                </button>
              ),
            )
          ) : (
            <div css={selectItemCardStyles}>{noResultsFound}</div>
          )}
        </div>
      )}
    </div>
  );
};
