import { put, call, take, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import {
  verifyTokenSuccess,
  verifyTokenEmailFailed,
} from '../actions/memberEnrollActions/memberEnrollActions';
import { MemberEnrollActionTypes } from '../actions/memberEnrollActions/memberEnrollActionTypes';
import {
  postLinkMember,
  postCancelV3AutoRenew,
  postCancelV2AutoRenew,
} from '../../rest-calls';
import { ErrorResponse, parseError } from '../../../../utils/parseError';
import { logSentryRequestError } from '../../../../utils/sentryHelpers';
import {
  getEmail,
  getHsUserId,
  getJWT,
} from '../selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  getIsActiveB2CMember,
  getIsV3Subscription,
  getSubscriptionId,
} from '../selectors/memberSubscriptionDetailsSelector/memberSubscriptionDetailsSelector';
import { getSlug } from '../selectors/orgSectionSelector/orgSectionSelector';
import { getEmailVerificationToken } from '../selectors/emailVerificationSelector/emailVerificationSelector';
import { router } from '../../../../root-component/router';

export function* cancelAutoRenew() {
  const userId = yield select(getHsUserId);
  const JWT = yield select(getJWT);
  const isV3Subscription = yield select(getIsV3Subscription);
  const subscriptionId = yield select(getSubscriptionId);

  if (isV3Subscription) {
    try {
      yield call(postCancelV3AutoRenew, JWT, subscriptionId);
    } catch (error) {
      logSentryRequestError(error, 'cancelAutoRenewSaga v3 subscription');
    }
  } else {
    try {
      yield call(postCancelV2AutoRenew, JWT, userId, subscriptionId);
    } catch (error) {
      logSentryRequestError(error, 'cancelAutoRenewSaga v2 subscription');
    }
  }
}

export function* linkMemberSaga(JWT, email, slug) {
  const emailVerificationToken = yield select(getEmailVerificationToken);

  Sentry.addBreadcrumb({
    category: 'linkMemberSaga',
    message: `{
        email: ${email},
        JWT: ${JWT}
      }`,
    level: Sentry.Severity.Info,
  });

  try {
    const res = yield call(postLinkMember, JWT, emailVerificationToken, slug);
    yield put(verifyTokenSuccess(res));
    const isActiveB2CMember = yield select(getIsActiveB2CMember);

    if (isActiveB2CMember) {
      try {
        yield call(cancelAutoRenew);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  } catch (error) {
    const { message, errorStringCode } = parseError(error as ErrorResponse);

    yield put(verifyTokenEmailFailed({ message, errorStringCode }));
  }
}

export function* verifyEmailTokenSaga() {
  const JWT = yield select(getJWT);
  const email = yield select(getEmail);
  const slug = yield select(getSlug);

  if (!JWT && !email) {
    yield router.navigate(`/${slug}/member-enroll/login`);
    return;
  }

  // If email still does not exist wait for USER_IS_LOGGED_IN
  if (!email) {
    yield take(MemberEnrollActionTypes.USER_IS_LOGGED_IN);
  }

  yield call(linkMemberSaga, JWT, email, slug);
}
