import FileSaver from 'file-saver';
import * as Sentry from '@sentry/browser';
import { createAuthorizationHeader } from '../../utils/auth';
import { HTTPMethod, responseType } from '../../utils/constants';
import { getB2BOrgSurveyUrl } from '../../utils/urls';
import { orgAPIAxios } from '../axios';
import {
  GetSurveys,
  SendSurvey,
  GenerateSurvey,
  DownloadSurvey,
} from './survey.types';

export const getSurveys: GetSurveys = (orgId) => {
  return orgAPIAxios({
    headers: createAuthorizationHeader(),
    method: HTTPMethod.GET,
    url: getB2BOrgSurveyUrl(orgId).GET_ORG_SURVEYS,
  });
};

export const sendSurvey: SendSurvey = (orgId, surveyId) => {
  return orgAPIAxios({
    data: { orgId, surveyId },
    headers: createAuthorizationHeader(),
    method: HTTPMethod.POST,
    url: getB2BOrgSurveyUrl(orgId, surveyId).SEND_SURVEY,
  });
};

export const generateSurvey: GenerateSurvey = (orgId) => {
  return orgAPIAxios({
    data: { orgId },
    headers: createAuthorizationHeader(),
    method: HTTPMethod.POST,
    url: getB2BOrgSurveyUrl(orgId).CREATE_SURVEY,
  });
};

export const downloadSurvey: DownloadSurvey = async (
  orgId,
  surveyId,
  surveyName,
) => {
  return orgAPIAxios({
    headers: createAuthorizationHeader(),
    method: HTTPMethod.GET,
    responseType: responseType.BLOB,
    url: getB2BOrgSurveyUrl(orgId, surveyId).DOWNLOAD_SURVEY,
  })
    .then((response) => {
      FileSaver.saveAs(response.data, surveyName);
    })
    .catch((err) => {
      Sentry.addBreadcrumb({
        message: `Failed in downloadSurvey with Error:${err}`,
      });
      if (err.response?.status === 404) {
        throw new Error(
          'Your survey isn’t available yet. Please check again next week.',
        );
      }
      throw err;
    });
};
