import { Spinner } from '@headspace/web-ui-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getOrgBySlug } from '../../../../state/actions/organizationActions';
import { GingerCard } from './ginger-bundle-components/GingerCard';
import { HeadspaceCard } from './ginger-bundle-components/HeadspaceCard';
import { TranslationTab } from '../shared-components/Header/TranslationTab';
import {
  containerStyle,
  spinnerContainerStyle,
  cardContainerStyle,
  headerStyle,
  justifyContentRight,
} from './ginger-bundle-page-style';
import { useTranslate } from '../../../../hooks/useTranslate';
import {
  getActiveOrg,
  getCompanyName,
  getIsGingerBundlingEnabled,
} from '../../state/selectors/orgSectionSelector/orgSectionSelector';

export const GingerBundlePage: FC = () => {
  const params = useParams<{ slug: string }>();
  const { slug } = params;
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const companyName = useSelector(getCompanyName);
  const activeOrg = useSelector(getActiveOrg);
  const gingerBundlingEnabled = useSelector(getIsGingerBundlingEnabled);

  const pageHeaderText = translate('gingerBundle.header.begin_v2');

  useEffect(() => {
    if (slug) {
      dispatch(getOrgBySlug(slug));
    }
    if (activeOrg && gingerBundlingEnabled === false) {
      navigate(`/${slug}/member-enroll`);
    }
  }, [dispatch, slug, gingerBundlingEnabled, navigate, activeOrg]);

  const handleGingerRedirect = useCallback(() => {
    navigate(`/${slug}/ginger-download`);
  }, [navigate, slug]);

  const handleHeadspaceRedirect = useCallback(() => {
    navigate(`/${slug}/member-enroll`);
  }, [navigate, slug]);

  if (activeOrg === null) {
    return (
      <div css={spinnerContainerStyle}>
        <Spinner />
      </div>
    );
  }

  return (
    <div css={containerStyle}>
      <div>
        <header css={headerStyle}>
          <span css={justifyContentRight}>
            <TranslationTab />
          </span>
        </header>
        <p>{translate('gingerBundle.header.learnMore', { companyName })}</p>
        <h1>{pageHeaderText}</h1>
      </div>
      <div css={cardContainerStyle}>
        <HeadspaceCard onHandleClick={handleHeadspaceRedirect} />
        <GingerCard onHandleClick={handleGingerRedirect} />
      </div>
    </div>
  );
};
