import axios from 'axios';
import * as Sentry from '@sentry/browser';

const orgAPIAxios = axios.create();

export const errorHandler = (error): never => {
  const status = error?.response?.status;
  const isUnauthorized =
    status === 401 || status === 403 || error.message === 'Permission Denied!';

  if (isUnauthorized) {
    Sentry.captureMessage('Error: Unauthorized', Sentry.Severity.Error);
    window.location.href = '/logout';
  } else {
    Sentry.captureMessage(`Error: ${error}`, Sentry.Severity.Error);
  }
  throw error;
};

orgAPIAxios.interceptors.response.use((response) => response, errorHandler);

export { orgAPIAxios };
