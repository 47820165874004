import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  ONBAORDING_PAGES_ASSETS,
  usePreCacheImages,
} from '../../../../../hooks/usePreCacheImages';
import { getUnifiedEligibilityRoutes } from '../../../constants/routes';
import LoadingLottie from '../../../../../assets/loading-lottie.json';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { containerStyle, headerStyle, subHeaderStyle } from './styles';
import { PRIVACY_PAGE_LINK } from '../constants';
import { getSlug } from '../../../state/selectors/orgSectionSelector/orgSectionSelector';

const defaultOptions = {
  animationData: LoadingLottie,
  autoplay: true,
  loop: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const SECONDS_DELAY = 1000 * 2;

export const LoadingPage = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const slug = useSelector(getSlug);
  const { V3_LOGIN_PATH } = getUnifiedEligibilityRoutes(slug);
  const { search } = useLocation();

  setTimeout(() => {
    navigate(`${V3_LOGIN_PATH}${search}`);
  }, SECONDS_DELAY);

  // PreCache images during the artificial loading pages to avoid jumpy iamges during onboarding flow.
  usePreCacheImages(ONBAORDING_PAGES_ASSETS);

  return (
    <div css={containerStyle}>
      <h2 css={headerStyle}>{translate('enrollment.loadingPage.headline')}</h2>

      <Lottie options={defaultOptions} />

      <h2 css={subHeaderStyle}>
        {translate('enrollment.loadingPage.subHeader')}
      </h2>
      <p>
        <FormattedMessage
          id="enrollment.loadingPage.message"
          values={{
            link: (chunks) => (
              <a
                href={PRIVACY_PAGE_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </p>
    </div>
  );
};
