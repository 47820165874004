import { css } from '@emotion/react';
import { WarmGrey, rem } from '@headspace/web-ui-components';

export const spinnerWrapperCss = css({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 999,
});

export const spinnerBackgroundStyle = (height, width) =>
  css({
    alignItems: 'center',
    background: `${WarmGrey[800]}1a`,
    borderRadius: rem(0.75),
    display: 'flex',
    height,
    justifyContent: 'center',
    width,
  });
