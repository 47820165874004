export const isTargetChildOfParent = (
  parent: Element | null,
  target: Element | null,
) => {
  if (target === null || parent == null) return false;

  let node = target.parentNode;

  while (node !== null) {
    if (node === parent) return true;
    node = node.parentNode;
  }

  return false;
};
