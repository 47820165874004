import { Dispatch } from 'redux';
import { getOrganizationById } from '../../../rest';

import { hydrate } from './hydrate';
import { GetState } from '../../types';
import { getOrgEAPCoverage } from '../../../rest/organization/organization.api';

export const queryOrganization = (orgId: number, isCSM: boolean) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const org = await getOrganizationById(orgId);
    const eapCoverage = await getOrgEAPCoverage(orgId);

    const {
      manageOrganization: { cachedOrg },
    } = getState();

    const admins = cachedOrg ? cachedOrg.admins : org.admins;

    dispatch(
      hydrate({
        adminReviewView: true,
        ...org,
        ...eapCoverage,
        admins,
      }),
    );
  };
};
