import { MouseEventHandler } from 'react';
import * as React from 'react';
import { css } from '@emotion/react';
import {
  Card,
  CardTitle,
  CardBody,
  Button,
  PrimaryButton,
  rem,
  SpacingInRemBySize,
  fontSize2XL,
  WarmGrey,
} from '@headspace/web-ui-components';

interface YesOrNoCardProps {
  handleYesButtonClick: MouseEventHandler;
  handleNoButtonClick: MouseEventHandler;
  noButtonTextValue: string;
  yesButtonTextValue: string;
  titleTextValue: string;
}

const cardStyle = css({
  marginBottom: rem(2),
  marginTop: rem(2),
});

const buttonStyle = css({
  maxWidth: 'none !important',
  marginBottom: `${SpacingInRemBySize.S} !important`,
  backgroundColor: `${WarmGrey[200]} !important`,
});

const PrimaryButtonStyle = css({
  maxWidth: 'none !important',
  marginBottom: `${SpacingInRemBySize.S} !important`,
});

const titleStyle = css({
  textAlign: 'center',
  marginTop: `${rem(3)} !important`,
  marginBottom: `${rem(2)} !important`,
  fontSize: `${fontSize2XL} !important`,
  padding: `0 ${rem(1)} !important`,
});

export const YesOrNoCard: React.FC<YesOrNoCardProps> = ({
  handleYesButtonClick,
  handleNoButtonClick,
  yesButtonTextValue,
  noButtonTextValue,
  titleTextValue,
}) => {
  return (
    <Card css={cardStyle}>
      <CardTitle css={titleStyle} data-testid="card-title">
        {titleTextValue}
      </CardTitle>

      <CardBody>
        <PrimaryButton
          isBlock={true}
          onClick={handleYesButtonClick}
          css={PrimaryButtonStyle}
          dataTestIdPrefix="yes"
        >
          {yesButtonTextValue}
        </PrimaryButton>
        <Button
          isBlock={true}
          onClick={handleNoButtonClick}
          css={buttonStyle}
          dataTestIdPrefix="no"
        >
          {noButtonTextValue}
        </Button>
      </CardBody>
    </Card>
  );
};
