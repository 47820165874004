export const debounce = (fn: (...args: any) => void, delay: number) => {
  const timer: { current: NodeJS.Timer | null } = { current: null };

  return (...args: any[]) => {
    clearTimeout(timer.current as NodeJS.Timer);
    timer.current = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const debouncePromise = <args = any, response = any>(
  task: (...args: args[]) => Promise<response>,
  delay: number,
): ((...args: args[]) => Promise<response | undefined>) => {
  const timer: {
    cancel: (() => void) | null;
    current: NodeJS.Timer | null;
  } = {
    cancel: null,
    current: null,
  };

  return async (...args: args[]) => {
    if (typeof timer.cancel === 'function') {
      await timer.cancel();
      timer.cancel = null;
      clearTimeout(timer.current as NodeJS.Timer);
    }

    try {
      await (() =>
        new Promise((resolve, reject) => {
          timer.cancel = reject;
          timer.current = setTimeout(resolve, delay);
        }))();
    } catch {
      return undefined;
    }

    const response = await task(...args);

    timer.cancel = null;
    return response;
  };
};

export const noop = () => undefined;

export const identity = (value: any): any => value;

export const getSelectTitle = (selectOptions, selectValue) => {
  const option = selectOptions.find(({ value }) => value === selectValue);
  return option.title;
};
