import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import {
  containerStyle,
  iconStyle,
  iconTextStyle,
  subHeaderStyle,
  lottieContainerStyle,
} from './styles';
import LoadingLottieV2 from '../../../../../assets/enrollment-loading-lottie_v2.json';
import LoadingKeyLottie from '../../../../../assets/enrollment-loading-key-lottie.json';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { useUnifiedEligibilityBasePath } from '../utils/routeUtils';
import { getUnifiedEligibilityRoutes } from '../../../constants/routes';
import { getEnrollmentVerificationIsVerified } from '../../../state/selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';

const getOptions = (lottie) => ({
  loop: true,
  autoplay: true,
  animationData: lottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});

export const EnrollmentLoadingPage: React.FC = () => {
  const navigate = useNavigate();
  const { slug } = useUnifiedEligibilityBasePath();
  const { VERIFY_PATH } = getUnifiedEligibilityRoutes(slug);
  const { translate } = useTranslate();
  const isEnrollmentVerified = useSelector(getEnrollmentVerificationIsVerified);
  const loadingSubHeader = translate('onboarding.loading.subHeader_v2');

  useEffect(() => {
    if (!isEnrollmentVerified) {
      navigate(VERIFY_PATH);
    }
  }, []);

  return (
    <div css={containerStyle}>
      <span css={lottieContainerStyle}>
        <Lottie options={getOptions(LoadingLottieV2)} />
      </span>
      <h2 css={subHeaderStyle}>{translate('onboarding.loading.header')}</h2>

      <div css={iconTextStyle}>
        <div css={iconStyle}>
          <Lottie options={getOptions(LoadingKeyLottie)} />
        </div>
        <p>{loadingSubHeader}</p>
      </div>
    </div>
  );
};
