import { css } from '@emotion/react';
import * as React from 'react';
import { SpacingInRemBySize } from '@headspace/web-ui-components';

const tableStyle = css({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  marginTop: SpacingInRemBySize.L,
  width: '100%',
});

export interface BasicTableProps {
  children: React.ReactNode;
  dataTestId?: string;
  className?: string;
}

export const BasicTable: React.FC<BasicTableProps> = ({
  children,
  dataTestId,
  className,
}) => {
  return (
    <div
      className={`${className} basicTable`}
      data-testid={`basic-table-${dataTestId}`}
      css={tableStyle}
    >
      {children}
    </div>
  );
};
