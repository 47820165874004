import { useEffect } from 'react';

// UMD Landing/Verification page
import landingPageHeroSvg from 'assets/umd-landing-page-hero.svg';
import landingPageHeroSvgMobile from 'assets/umd-landing-page-hero-mobile.svg';
import landingPageHeroSvgTablet from 'assets/umd-landing-page-hero-tablet.svg';
import landingPageIcon1 from 'assets/umd-landing-page-icon-1.svg';
import landingPageIcon2 from 'assets/umd-landing-page-icon-2.svg';
import landingPageIcon3 from 'assets/umd-landing-page-icon-3.svg';

// Error Pages
import UnifiedExceptionFooter from 'assets/unifed-exception-footer.svg';

// Onboarding
import niceToMeetYouPng from 'assets/nice-to-meet-you.png';
import smileIllustration from 'assets/umd-smile-greeting.png';
import umdPlayButton from 'assets/umd-play-button.png';
import umdOnboardingTerribleEmotion from 'assets/umd-onboarding-slider-terrible-emotion.png';
import umdOnboardingBadEmotion from 'assets/umd-onboarding-slider-bad-emotion.png';
import umdOnboardingOkayEmotion from 'assets/umd-onboarding-slider-okay-emotion.png';
import umdOnboardingGreatEmotion from 'assets/umd-onboarding-slider-great-emotion.png';
import umdOnboardingAmazingEmotion from 'assets/umd-onboarding-slider-amazing-emotion.png';
import umdOnboardingBeMindful from 'assets/umd-onboarding-be-mindful.png';
import umdOnboardingFeelCalmAndRelax from 'assets/umd-onboarding-feel-calm-and-relax.png';
import umdOnboardingManageAnxiety from 'assets/umd-onboarding-manage-anxiety.png';
import umdOnboardingManageDepression from 'assets/umd-onboarding-manage-depression.png';
import umdOnboardingProcessGrief from 'assets/umd-onboarding-process-grief.png';
import umdOnboardingReduceStress from 'assets/umd-onboarding-reduce-stress.png';
import umdOnboardingSleepBetter from 'assets/umd-onboarding-sleep-better.png';
import umdOnboardingSomethingElse from 'assets/umd-onboarding-something-else.png';
import umdOnboardingFamily from 'assets/umd-onboarding-family.png';
import umdOnboardingCareer from 'assets/umd-onboarding-career.png';
import umdOnboardingSocialLife from 'assets/umd-onboarding-social-life.png';
import umdOnboardingPhysicalHealth from 'assets/umd-onboarding-physical-health.png';
import umdOnboardingHobbies from 'assets/umd-onboarding-hobbies.png';
import umdOnboardingPersonalFinances from 'assets/umd-onboarding-personal-finances.png';
import umdOnboardingRadiobutton from 'assets/umd-onboarding-radiobutton.png';
import umdOnboardingCheckmark from 'assets/umd-onboarding-checkmark.png';

// Recommendation Pages
import GingerIcon from 'assets/umd-ginger-recommended-ginger.svg';
import TextWithCoachHero from 'assets/umd-ginger-recommended-text-with-coach.svg';
import ThoughtBubbles from 'assets/umd-ginger-recommended-thought-bubbles.svg';
import ClockIcon from 'assets/umd-recommended-clock-icon.svg';
import HeadspaceIcon from 'assets/umd-recommended-headspace.svg';
import ProgramIcon from 'assets/umd-recommended-program-icon.svg';
import FinishStateCoach from 'assets/umd-finish-state-coach.png';
import poweredByGingerPng from 'assets/umd-powered-by-ginger.png';
import privacyNoticeSvg from 'assets/umd-privacy-notice.svg';
import loginCircle from 'assets/umd-login-circle.svg';
import promptCircle from 'assets/umd-login-circle-prompt.svg';

export const LANDING_PAGE_ASSETS = [
  landingPageHeroSvg,
  landingPageHeroSvgMobile,
  landingPageHeroSvgTablet,
  landingPageIcon1,
  landingPageIcon2,
  landingPageIcon3,
  UnifiedExceptionFooter,
  niceToMeetYouPng,
  smileIllustration,
];

export const ONBAORDING_PAGES_ASSETS = [
  umdPlayButton,
  umdOnboardingTerribleEmotion,
  umdOnboardingBadEmotion,
  umdOnboardingOkayEmotion,
  umdOnboardingGreatEmotion,
  umdOnboardingAmazingEmotion,
  umdOnboardingBeMindful,
  umdOnboardingFeelCalmAndRelax,
  umdOnboardingManageAnxiety,
  umdOnboardingManageDepression,
  umdOnboardingProcessGrief,
  umdOnboardingReduceStress,
  umdOnboardingSleepBetter,
  umdOnboardingSomethingElse,
  umdOnboardingFamily,
  umdOnboardingCareer,
  umdOnboardingSocialLife,
  umdOnboardingPhysicalHealth,
  umdOnboardingHobbies,
  umdOnboardingPersonalFinances,
  umdOnboardingRadiobutton,
  umdOnboardingCheckmark,
  GingerIcon,
  TextWithCoachHero,
  ThoughtBubbles,
  ClockIcon,
  HeadspaceIcon,
  ProgramIcon,
  FinishStateCoach,
  poweredByGingerPng,
  privacyNoticeSvg,
  loginCircle,
  promptCircle,
];

/**
 * This hook is intended to be used to precache the umd images to avoid image flickering when loading or navigation.
 * https://stackoverflow.com/questions/57653924/how-to-preload-local-images
 */
export const usePreCacheImages = (imageList: Array<any>) => {
  useEffect(() => {
    imageList.forEach((image) => {
      new Image().src = image;
    });
  }, []);
};
