import { useRef, useState, useCallback } from 'react';
import { AutoSizer, Column, InfiniteLoader, Table } from 'react-virtualized';
import { DEFAULT_ROW_HEIGHT, headerRowStyle, rowStyle } from './styles';
import { selectRow } from './utils';
import { TableRow } from './Row';
import { TableSectionProps, RowRenderer } from './types';

const noop = () => {};

export const TableSection: React.FC<TableSectionProps> = ({
  rows,
  sort,
  selectedRowIds,
  setSelectedRowIds,
  headerRow,
  columnWidthOffset,
  loadMoreRows,
}: TableSectionProps) => {
  const [[sortBy, sortDirection], setSort] = sort;
  const tableRef = useRef<Table | null>(null);
  // InfiniteLoader expects registerChild to be called with the table ref and we need a reference
  // for handleExpandClick to force re-rendering when a row is expanded.
  const setTableRef = (registerChild, ref) => {
    registerChild(ref);
    tableRef.current = ref;
  };

  const [expandedRows, setExpandedRows] = useState({});
  const handleExpandClick = useCallback(
    (index) => {
      const newExpandedRows = { ...expandedRows };
      if (newExpandedRows[index]) {
        delete newExpandedRows[index];
      } else {
        newExpandedRows[index] = true;
      }
      setExpandedRows(newExpandedRows);

      // Expanding or collapsing a row requires the table to force a re-render to get the positioning correct
      // https://github.com/bvaughn/react-virtualized/blob/master/docs/Table.md#recomputerowheights-index-number
      if (tableRef.current !== null) {
        tableRef.current.recomputeRowHeights(index);
      }
    },
    [expandedRows, setExpandedRows, tableRef],
  );

  const rowRenderer = (data: RowRenderer) => {
    const { index, key, rowData, style } = data;
    const { onRowClick, icon, sortIndex } = rowData;
    const rowWrapperStyle = {
      height: style.height,
      left: style.left,
      position: style.position,
      top: style.top,
    };

    return (
      <div
        className="rowWrapper"
        onClick={onRowClick}
        key={key}
        onKeyDown={noop}
        role="button"
        tabIndex={0}
        css={rowStyle({ icon })}
        data-testid={sortIndex.name}
        style={rowWrapperStyle}
      >
        <TableRow
          {...data}
          onExpandClick={handleExpandClick}
          expanded={!!expandedRows[index]}
        />
      </div>
    );
  };

  return (
    <InfiniteLoader
      threshold={1}
      isRowLoaded={({ index }) => !!rows[index]}
      loadMoreRows={loadMoreRows}
      rowCount={1000000}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ width, height }) => {
            const columnWidth = Math.floor(
              (width - columnWidthOffset) / headerRow.length,
            );
            return (
              <Table
                headerHeight={76}
                headerStyle={{ headerRowStyle }}
                height={height ? height - 50 : 620}
                onRowsRendered={onRowsRendered}
                ref={setTableRef.bind(null, registerChild)}
                rowCount={rows.length}
                rowHeight={DEFAULT_ROW_HEIGHT}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={({ sortBy, sortDirection }) =>
                  setSort([sortBy, sortDirection])
                }
                rowGetter={({ index }) => rows[index]}
                rowRenderer={(data) =>
                  rowRenderer({
                    ...data,
                    columnWidth,
                    onSelect: (id: string) =>
                      setSelectedRowIds(selectRow(selectedRowIds, id)),
                    selectedRowIds,
                  })
                }
                width={width > 0 ? width : 900}
              >
                {headerRow &&
                  headerRow.map((col: any) => {
                    return (
                      <Column
                        cellRenderer={({ cellData }) => cellData}
                        dataKey={col.key}
                        disableSort={!col.sortBy}
                        key={col.key}
                        label={col.label}
                        width={columnWidth}
                      />
                    );
                  })}
              </Table>
            );
          }}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};
