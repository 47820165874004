import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Sequence } from '@headspace/call-api-saga';
import {
  SSOEnrollmentActionTypes,
  SSOOrgs,
} from '../../actions/ssoEnrollmentActions/ssoEnrollmentActionsTypes';
import { logSentryRequestError } from '../../../../../utils/sentryHelpers';
import { getSSOEnrollmentData, postSSOComplete } from '../../../rest-calls';
import { ModalActionsTypes } from '../../../../../state/types/modal';
import env from '../../../../../config/env';
import { ErrorResponse, parseError } from '../../../../../utils/parseError';
import { getJWT } from '../../selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  getHealthHubId,
  getIsEnrolled,
  getSSOOrgs,
} from '../../selectors/ssoSelector/ssoSelector';
import { getPreferredEnrollmentSlug } from '../../selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';
import { router } from '../../../../../root-component/router';

const { VP_HEALTH_HUB } = env;

// Determine redirect when login page is rendered
export function* determineSlugRedirect(slug: string, orgs: SSOOrgs) {
  const belongsToMultipleOrg = orgs.length > 1;
  if (belongsToMultipleOrg) {
    yield router.navigate(`/vp/select-org`);
    return;
  }
  yield router.navigate(`/${slug}/member-enroll/verify`);
}

export function* getSSOOrgsSaga(JWT, externalOrgId) {
  try {
    const {
      data: {
        data: { orgs },
      },
    } = yield call(getSSOEnrollmentData, JWT, VP_HEALTH_HUB, externalOrgId);

    yield put({
      type: SSOEnrollmentActionTypes.SET_SLUGS,
      payload: { orgs },
    });
  } catch (error) {
    const { message } = parseError(error as ErrorResponse);

    yield put({
      type: ModalActionsTypes.OPEN_MODAL,
      payload: { message },
    });
    logSentryRequestError(error, 'Failed to fetch SSO enrollment data');
  }
}

export function* redirectToPreferredSlug(slug: string) {
  yield router.navigate(`/${slug}/member-enroll/verify`);
}

export function* getSSOSlugSaga(action) {
  const { sequence, error, payload } = action;
  if (sequence === Sequence.DONE && !error) {
    const { externalOrgId } = payload;
    const preferredSlug = yield select(getPreferredEnrollmentSlug);

    if (preferredSlug) {
      yield call(redirectToPreferredSlug, preferredSlug);
      return;
    }

    const JWT = yield select(getJWT);
    yield call(getSSOOrgsSaga, JWT, externalOrgId);
    const orgs = yield select(getSSOOrgs);
    const { slug } = orgs[0];
    const healthHubId = yield select(getHealthHubId);
    const isEnrolled = yield select(getIsEnrolled);
    const ENROLLED_BUT_HEALTH_HUB_NOT_CONNECTED = isEnrolled && !healthHubId;

    if (ENROLLED_BUT_HEALTH_HUB_NOT_CONNECTED) {
      yield call(postSSOComplete, JWT, VP_HEALTH_HUB, externalOrgId);
      yield router.navigate(`/${slug}/member-enroll/finish`);
      return;
    }

    yield call(determineSlugRedirect, slug, orgs);
  }

  if (sequence === Sequence.DONE && error) {
    const { errors } = payload;
    const { detail } = errors[0];
    const defaultMessage =
      'Failed to link external identity with headspace account.';

    yield put({
      type: ModalActionsTypes.OPEN_MODAL,
      payload: { message: detail || defaultMessage },
    });
    logSentryRequestError(detail, defaultMessage);
  }
}

export function* waitForLinkedIdentitiesSaga() {
  yield takeLatest(SSOEnrollmentActionTypes.LINK_IDENTITIES, getSSOSlugSaga);
}
