import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../types';
import { initialContractFamilyMembersPerEmployeeValue } from '../../../reducers/manageOrganizationReducer';
import { handleChange } from '../handleChange';
import { processErrors } from '../processErrors';

export const handleElfBasedDependentsChange = (
  event: ExtendedEvent,
  term: 'current' | 'expired' | 'future' = 'current',
) => (dispatch: ThunkDispatch<RootState, unknown, Action>): void => {
  const { value: disableElfBasedDependents } = event.target;
  dispatch(handleChange(event));

  if (disableElfBasedDependents === 'false') {
    dispatch(
      handleChange({
        target: {
          id: `${term}_contract_invite_based_dependents`,
          value: false,
        },
      }),
    );
    dispatch(
      handleChange({
        target: {
          id: `${term}_contract_family_members_per_employee`,
          value: initialContractFamilyMembersPerEmployeeValue,
        },
      }),
    );
  }

  dispatch(processErrors(event));
};
