import { useEffect, useRef, useState } from 'react';

export const useOutsideClickHandler = (initialIsVisible) => {
  const [isItemVisible, setIsItemVisible] = useState(initialIsVisible);
  const ref = useRef<HTMLElement>(null);

  const handleClickOutside = ({ target }) => {
    const node = ref.current;

    if (node && !node.contains(target)) {
      setIsItemVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return [ref, isItemVisible, setIsItemVisible];
};
