/**
 * @returns {boolean} Coalesce value if a match is found on the list of strings, it should be a none empty array and vice versa.
 * @param {string} pathname url pathname from location.pathname eg. :slug/member-enroll/login
 * @param {array} checkList a list of string to check against pathname eg. ['/member-enroll', '/join', '/login']
 */

type MatchPathnameType = (
  pathname: string,
  checkList: Array<string>,
) => boolean;

export const matchPathname: MatchPathnameType = (pathname, checkList) => {
  const filteredString = checkList.filter((v) => pathname.includes(v));

  return !!filteredString.length;
};
