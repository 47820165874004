import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Orange } from '@headspace/web-ui-components';
import {
  createSFTPUser,
  updateSFTPUser,
} from '../../../../../../state/actions/organizationActions';
import { TextButton } from '../../../../../../shared-components/buttons/TextButton';
import { visuallyHidden } from '../../../../styles';
import { getSFTPUsers } from '../../../../../../state/selectors/organizationSelectors';
import { stepButtonStyles } from '../../../../../../shared-components/Steps/styles';

export interface SFTPUploadButtonProps {
  text: string;
  thin?: boolean;
  color?: string;
  callback?(): void;
  className?: string;
}

export const SFTPUploadButton: React.FC<SFTPUploadButtonProps> = ({
  text,
  thin = false,
  color = Orange[200],
  callback,
  className,
}) => {
  const sftpUsers = useSelector(getSFTPUsers);
  const hasUpload = sftpUsers.length > 0;
  const dispatch = useDispatch();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        inputRef.current?.click();
      }
    },
    [inputRef],
  );
  const handleClick = React.useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);
  const handleChangeInput = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files && files[0]) {
        hasUpload
          ? dispatch(updateSFTPUser(files[0]))
          : dispatch(createSFTPUser(files[0]));
        if (inputRef && inputRef.current) {
          inputRef.current.value = '';
        }
        if (callback) {
          callback();
        }
      }
    },
    [hasUpload, dispatch],
  );

  return (
    <>
      <label
        htmlFor="sftp-file-input"
        css={stepButtonStyles(color)}
        className={className}
      >
        <TextButton
          dataTestId="sftp-upload-button"
          value={text}
          onClick={handleClick}
          onKeyDown={onKeyDown}
          className={thin ? 'thin' : undefined}
        />
      </label>
      <input
        css={visuallyHidden}
        id="sftp-file-input"
        name="sftp-file-input"
        data-testid="sftp-file-input"
        type="file"
        ref={inputRef}
        onChange={handleChangeInput}
      />
    </>
  );
};
