import { OrganizationsActionsTypes } from '../types/organizations';
import { OrganizationActionsTypes } from '../types/organization';
import {
  ModalActionsTypes,
  OpenModalAction,
  CloseModalAction,
  DeleteCallbackTypes,
} from '../types/modal';
import { removeOrg } from '../../rest';
import { clearAdmin } from './organizationActions/clearAdmin';
import { ErrorResponse, parseError } from '../../utils/parseError';
import { deleteOrganizationContract } from './organizationActions/deleteOrganizationContract';
import { FUTURE } from '../../apps/dashboard/constants';
import { Organization } from '../../types/organization';
import { router } from '../../root-component/router';

const { CLOSE_MODAL, OPEN_MODAL, EXECUTE_CONFIRM } = ModalActionsTypes;
const { DELETE_ORGANIZATION } = OrganizationActionsTypes;
const { UPDATE_ORGANIZATIONS } = OrganizationsActionsTypes;
const {
  CONFIRM_DELETE,
  DELETE_FUTURE_CONTRACT,
  DELETE_ADMIN,
} = DeleteCallbackTypes;

export const OpenModal = (
  title: string,
  message: string,
  navigateTo: string,
  confirmCallback: () => void,
): OpenModalAction => {
  return {
    type: OPEN_MODAL,
    payload: { title, message, navigateTo, confirmCallback },
  };
};

// In the future this could be a middleware that listen for all modal action confirm types
export const executeConfirmAction = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: EXECUTE_CONFIRM,
    });

    const {
      modal: { confirmCallback, executeConfirm, nextAdmins },
      manageOrganization,
      organizations: { OrganizationList },
    } = getState();
    const { orgId } = manageOrganization;

    if (executeConfirm && confirmCallback === DELETE_ADMIN) {
      const payload = {
        org_admins: nextAdmins,
        adminReviewView: true,
      };

      dispatch(clearAdmin(payload));
    }

    if (executeConfirm && confirmCallback === CONFIRM_DELETE) {
      try {
        await removeOrg(orgId);

        if (Array.isArray(OrganizationList)) {
          const nextOrganizationList = OrganizationList.filter(
            (org: Organization) => {
              return org.id !== orgId;
            },
          );
          dispatch({
            type: UPDATE_ORGANIZATIONS,
            payload: { OrganizationList: nextOrganizationList },
          });
        }

        await router.navigate('/orgs');
        dispatch({
          type: DELETE_ORGANIZATION,
          payload: {},
        });
        dispatch({
          type: CLOSE_MODAL,
          payload: {},
        });
      } catch (err) {
        const { message } = parseError(err as ErrorResponse);
        dispatch({
          type: OPEN_MODAL,
          payload: {
            message,
          },
        });
      }
    }

    if (executeConfirm && confirmCallback === DELETE_FUTURE_CONTRACT) {
      dispatch(deleteOrganizationContract(FUTURE));
    }
  };
};

export const CloseModal = (): CloseModalAction => {
  return {
    type: CLOSE_MODAL,
    payload: {},
  };
};
