import { css } from '@emotion/react';
import {
  borderRadiusM,
  cssWithMq,
  fontSizeM,
  fontSizeS,
  fontWeightBold,
  fontWeightRegular,
  letterSpacingTight,
  lineHeightBody,
  lineHeightHeading,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

export const containerStyle = cssWithMq({
  backgroundColor: WarmGrey[100],
  borderRadius: borderRadiusM,
  display: 'flex',
  flexDirection: ['column', 'row'],
  justifyContent: 'center',
  padding: [
    `${SpacingInRemBySize.M} ${SpacingInRemBySize.M}`,
    `${SpacingInRemBySize.M} ${SpacingInRemBySize.XL}`,
  ],
  textAlign: ['center', 'inherit'],
});

export const itemsFontStyle = (
  size = fontSizeS,
  weight = fontWeightRegular,
  height = lineHeightBody,
) =>
  cssWithMq({
    fontSize: size,
    fontWeight: weight,
    letterSpacing: letterSpacingTight,
    lineHeight: height,
  });

export const containerItemStyle = cssWithMq({
  width: ['100%', '30%'],
});

export const rowItem = css({
  display: 'flex',
  margin: `${SpacingInRemBySize.L} 0`,
});

export const iconStyle = css({
  height: SpacingInRemBySize.XL,
  marginRight: SpacingInRemBySize.XS,
  width: SpacingInRemBySize.XL,
});

export const phoneIconStyle = css(iconStyle, {
  paddingTop: rem(0.438),
});

export const phoneTitleStyle = css(
  itemsFontStyle(fontSizeM, fontWeightBold, lineHeightHeading),
  {
    color: WarmGrey[800],
    marginBottom: 0,
    marginTop: 0,
  },
);

export const phoneContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  marginTop: SpacingInRemBySize['2XS'],
});

export const phoneContextStyle = css(itemsFontStyle(), {
  color: WarmGrey[600],
  marginLeft: SpacingInRemBySize['2XS'],
});

export const servicesContainerStyle = cssWithMq({
  backgroundColor: WarmGrey[100],
  borderRadius: borderRadiusM,
  display: 'flex',
  flexDirection: 'column',
  marginLeft: [0, SpacingInRemBySize.XL, SpacingInRemBySize['3XL']],
  padding: `${SpacingInRemBySize.M} ${SpacingInRemBySize['3XL']}`,
  paddingLeft: [null, null, SpacingInRemBySize.XL],
  paddingRight: [null, null, SpacingInRemBySize.XL],
  textAlign: 'initial',
});

export const containerPhoneNumbersStyles = css({
  a: {
    textDecoration: 'none',
  },

  fontSize: fontSizeS,
});

export const childPhoneNumbersStyles = css({
  display: 'flex',
  flexDirection: 'column',
  marginTop: SpacingInRemBySize['2XS'],
});
