import { useQuery } from '@tanstack/react-query';
import { getSurveys } from '../../../../../rest/surveys/survey.api';
import { SurveyResponse } from '../../../../../state/models/organization/Survey';

const fetchSurveys = async (orgId: number) => {
  const response = await getSurveys(orgId);
  const { surveys }: SurveyResponse = response.data;
  return surveys;
};

export const useFetchSurveys = (orgId: number) =>
  useQuery({
    initialData: [],
    queryFn: () => fetchSurveys(orgId),
    queryKey: ['surveys'],
    refetchOnWindowFocus: false,
  });
