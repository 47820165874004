import { all, call } from 'redux-saga/effects';
import { authSaga } from '../../apps/memberEnrollmentV2/state/sagas/enrollment-authentication/factoriedAuthSaga';
import { waitForAuthSaga } from '../../apps/memberEnrollmentV2/state/sagas/enrollment-authentication/waitForAuthSaga';
import { waitToInitializeEmailTokenValidation } from '../../apps/memberEnrollmentV2/state/sagas/waitToInitializeEmailTokenValidationSaga';
import { waitToInitializePayment } from '../../apps/memberEnrollmentV2/state/sagas/payment/waitToInitializePaymentSaga';
import { getSSOEnrollmentStatusSaga } from '../../apps/memberEnrollmentV2/state/sagas/sso/getSSOEnrollmentStatusSaga';
import { verifyGingerSoleraConnectSaga } from '../../apps/memberEnrollmentV2/state/sagas/solera/verifyGingerSoleraConnectSaga';
import { mParticleWatcherSaga } from '../../analytics/mParticleSagas';
import { memberLoginRedirectSaga } from '../../apps/memberEnrollmentV2/state/sagas/redirects/loginRedirectSaga';
import { verifyPageSaga } from '../../apps/memberEnrollmentV2/state/sagas/verifyPageSaga';
import { waitForLinkedIdentitiesSaga } from '../../apps/memberEnrollmentV2/state/sagas/sso/waitForLinkIdentitiesSaga';

export function* rootSaga() {
  yield all([
    call(mParticleWatcherSaga),
    call(authSaga),
    call(waitForAuthSaga),
    call(memberLoginRedirectSaga),
    call(getSSOEnrollmentStatusSaga),
    call(verifyGingerSoleraConnectSaga),
    call(waitForLinkedIdentitiesSaga),
    call(verifyPageSaga),
    call(waitToInitializePayment),
    call(waitToInitializeEmailTokenValidation),
  ]);
}
