import { FC, ReactNode, useMemo } from 'react';
import loginCircle from 'assets/umd-login-circle.svg';
import { Breakpoint, useActiveBreakpoint } from '@headspace/web-ui-components';
import {
  childComponentColumnStyle,
  columnStyle,
  confirmationPageContainerStyle,
  contentContainerStyle,
} from '../../UnifiedEligibilityViews/styles/pageStyles';

interface ConfirmationPageProps {
  children?: ReactNode;
}

export const ConfirmationPage: FC<ConfirmationPageProps> = ({ children }) => {
  const activeBreakpoint = useActiveBreakpoint();
  const isMobileView = useMemo(() => activeBreakpoint === Breakpoint.MOBILE, [
    activeBreakpoint,
  ]);
  return (
    <div css={confirmationPageContainerStyle}>
      <div css={contentContainerStyle}>
        {isMobileView ? null : (
          <div css={[columnStyle]}>
            <img src={loginCircle} alt="" role="presentation" />
          </div>
        )}
        <div css={childComponentColumnStyle}>{children}</div>
      </div>
    </div>
  );
};
