import { css } from '@emotion/react';
import {
  rem,
  SpacingInRemBySize,
  bodyXSCss,
  bodySCss,
  labelXSCss,
  bodyMCss,
  headingLCss,
  headingSCss,
  headingMCss,
  CoolGrey,
  WarmGrey,
} from '@headspace/web-ui-components';

import * as React from 'react';

export const resets = css({
  color: CoolGrey[500],
  margin: 0,
  padding: 0,
});

export const buttonResets = css({
  background: 'none',
  border: 'none',
});

const labelStyle = css({
  color: WarmGrey[500],
  textTransform: 'uppercase',
});

const spaced = css({
  letterSpacing: rem(0.156),
  margin: `${SpacingInRemBySize.L} 0 ${SpacingInRemBySize.XS}`,
});

export interface TextProps {
  dataTestId?: string;
  children: React.ReactNode;
  className?: string; // className is the css prop from the instantiated component eg. <Title css={}> @emotion/react
}

export interface FieldLabelProps extends TextProps {
  htmlFor?: string;
}

export const TitleL: React.FC<TextProps> = ({
  children,
  className,
  dataTestId,
}) => {
  return (
    <h2
      data-testid={dataTestId}
      css={[headingLCss, resets]}
      className={className}
    >
      {children}
    </h2>
  );
};

export const TitleM: React.FC<TextProps> = ({
  children,
  className,
  dataTestId,
}) => {
  return (
    <h3
      data-testid={dataTestId}
      css={[resets, headingMCss]}
      className={className}
    >
      {children}
    </h3>
  );
};

export const TitleXS: React.FC<TextProps> = ({
  children,
  className,
  dataTestId,
}) => {
  return (
    <h5
      data-testid={dataTestId}
      css={[resets, headingSCss]}
      className={className}
    >
      {children}
    </h5>
  );
};

export const UIRegularL: React.FC<TextProps> = ({
  children,
  className,
  dataTestId,
}) => {
  return (
    <p data-testid={dataTestId} css={[resets, bodyMCss]} className={className}>
      {children}
    </p>
  );
};

export const UIRegularM: React.FC<TextProps> = ({
  children,
  className,
  dataTestId,
}) => {
  return (
    <div
      data-testid={dataTestId}
      css={[resets, bodySCss]}
      className={className}
    >
      {children}
    </div>
  );
};

export const UIBoldS: React.FC<TextProps> = ({
  children,
  className,
  dataTestId,
}) => {
  return (
    <div
      data-testid={dataTestId}
      css={[resets, labelXSCss]}
      className={className}
    >
      {children}
    </div>
  );
};

export const LabelBoldS: React.FC<TextProps> = ({
  children,
  className,
  dataTestId,
}) => {
  return (
    <label
      data-testid={dataTestId}
      css={[resets, labelStyle, labelXSCss]}
      className={className}
    >
      {children}
    </label>
  );
};

export const LabelRegularS: React.FC<TextProps> = ({
  children,
  className,
  dataTestId,
}) => {
  return (
    <label
      data-testid={dataTestId}
      css={[resets, labelStyle, bodyMCss]}
      className={className}
    >
      {children}
    </label>
  );
};

export const FieldLabel: React.FC<FieldLabelProps> = ({
  children,
  className,
  dataTestId,
  htmlFor,
}) => {
  return (
    <label
      data-testid={dataTestId}
      css={[resets, labelStyle, bodyXSCss, spaced]}
      className={className}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};
