import * as memberEnrollmentV2 from './member-enrollment-v2.json';
import * as memberEnrollmentV3 from './member-enrollment-v3.json';
import * as headspaceHub from './headspace-hub.json';
import * as webAuth from './web-auth.json';
import * as translation from './translation.json';

export const ru = {
  headspaceHub,
  memberEnrollmentV2,
  memberEnrollmentV3,
  webAuth,
  translation,
};
