export const generateHeaderRow = (t: I18next['t']) => [
  {
    key: 'name',
    label: t('name'),
    sortBy: true,
  },
  {
    key: 'offering',
    label: t('organizations.offering'),
    sortBy: true,
  },
  {
    key: 'launch',
    label: t('organizations.launchDate'),
    sortBy: true,
  },
  {
    key: 'members',
    label: t('organizations.enrolledMembers'),
    sortBy: true,
  },
  {
    key: 'seats',
    label: t('organizations.totalSeats'),
    sortBy: true,
  },
];
