import {
  OrganizationActionsTypes,
  GetOrgByIdAction,
} from '../../types/organization';

const { GET_ORG_BY_ID } = OrganizationActionsTypes;

export const getOrgById = (id: number): GetOrgByIdAction => {
  return {
    type: GET_ORG_BY_ID,
    payload: id,
  };
};
