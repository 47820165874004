import { ErrorResponse } from '../../utils/parseError';

export const errorDownloadChallengeReportParsing = (error: ErrorResponse) => {
  if (error.message) {
    return {
      message: error.message,
      title: error.title,
    };
  }

  return {
    message: 'An Error has occurred.',
  };
};
