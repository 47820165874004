import { cssWithMq, rem, WarmGrey } from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import {
  flex,
  alignItemsCenter,
  columnFlex,
  justifyContentCenter,
} from '../../../../dashboard/styles/flex';

export const buttonContainer = css(
  flex,
  alignItemsCenter,
  justifyContentCenter,
  {
    marginTop: rem(1.25),
    width: '100%',
  },
);

export const containerStyle = cssWithMq(
  columnFlex,
  justifyContentCenter,
  alignItemsCenter,
  {
    color: WarmGrey[500],
    marginTop: [rem(3.5), undefined, undefined],
    maxWidth: ['100vw', undefined, undefined],
    minHeight: [rem(25), rem(31.25), rem(31.25)],
    textAlign: 'center',
    width: rem(37.188),
  },
);
