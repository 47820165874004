import { Dispatch } from 'redux';
import * as Sentry from '@sentry/browser';

import { ChallengeActionsTypes } from '../types/challenges';
import {
  createChallenge,
  downloadChallengeReport,
  getChallenges,
  cancelChallenge,
} from '../../rest';
import { challengeValuesToParams } from '../utils/dataFormatters';
import { ModalActionsTypes } from '../types/modal';
import { ErrorResponse, parseError } from '../../utils/parseError';
import { ThunkDispatch, GetState, ThunkResult } from '../types';
import { errorDownloadChallengeReportParsing } from '../utils/errorParsers';

export const {
  CHALLENGES_CREATED,
  CHALLENGES_CREATE_FAILED,
  CHALLENGES_RECEIVED,
  CHALLENGES_FETCHING,
  CHALLENGES_CANCELING,
  CHALLENGES_RESET,
} = ChallengeActionsTypes;

const { OPEN_MODAL } = ModalActionsTypes;

export const newChallenge = (
  orgId: any,
  { ...options }: any,
  callback: any,
  translate: any,
  setDisableButton,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      await createChallenge({
        orgId,
        ...challengeValuesToParams({ ...options, isCreatingChallenge: true }),
      });
      callback(true);
    } catch (e: any) {
      dispatch(createFailed(translate('challenges.create.failed')));
      setTimeout(() => setDisableButton(false), 500);
    }
  };
};

export const fetchChallenges = (orgId: number) => async (
  dispatch: Dispatch,
) => {
  dispatch(challengesFetching(true));
  try {
    const response = await getChallenges(orgId);
    const resChallenges = response.data.challenges;
    resChallenges.sort((a, b) => b.id - a.id);
    dispatch(receivedSucceed(resChallenges));
  } catch (err) {
    Sentry.captureException(err);
    dispatch({
      payload: {
        message: parseError(err as ErrorResponse).message,
      },
      type: OPEN_MODAL,
    });
  }
  dispatch(challengesFetching(false));
};

export const handleDownloadChallengeReport = (): ThunkResult<
  Promise<void>
> => async (dispatch: ThunkDispatch, getState: GetState) => {
  const {
    manageOrganization: { cachedOrg: { id = -1, name = '' } = {} },
  } = getState();
  const nameWithoutDots = name.split('.').join('');
  try {
    await downloadChallengeReport(id, nameWithoutDots);
  } catch (err) {
    Sentry.captureException(err);
    dispatch({
      payload: {
        message: errorDownloadChallengeReportParsing(err as ErrorResponse)
          .message,
      },
      type: OPEN_MODAL,
    });
  }
};

export const handleCancelChallenge = (
  orgId: number,
  id: number,
): ThunkResult<Promise<void>> => async (dispatch: ThunkDispatch) => {
  try {
    await cancelChallenge(orgId, id);
    dispatch(challengesCanceling(id));
  } catch (err) {
    Sentry.captureException(err);
    dispatch({
      payload: {
        message: parseError(err as ErrorResponse).message,
      },
      type: OPEN_MODAL,
    });
  }
};

export const createSucceed = (payload: any) => ({
  type: CHALLENGES_CREATED,
  payload,
});

export const createFailed = (payload?: any) => ({
  type: CHALLENGES_CREATE_FAILED,
  payload,
});

export const challengeReset = () => ({
  type: CHALLENGES_RESET,
});

export const receivedSucceed = (payload: any) => ({
  type: CHALLENGES_RECEIVED,
  payload,
});

export const challengesFetching = (payload: any) => ({
  type: CHALLENGES_FETCHING,
  payload,
});

export const challengesCanceling = (payload: any) => ({
  type: CHALLENGES_CANCELING,
  payload,
});
