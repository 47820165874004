import { captureException } from '@sentry/browser';
import { MParticleEventType } from './types';

export interface MParticleEvent {
  attributes: Record<string, unknown>;
  eventName: string;
  eventType?: MParticleEventType;
  isScreenViewEvent: boolean;
}

export const pushEventToMParticle = ({
  attributes,
  eventName,
  eventType = MParticleEventType.Other,
  isScreenViewEvent,
}: MParticleEvent): void => {
  try {
    if (typeof window !== 'undefined' && window.mParticle) {
      if (isScreenViewEvent) {
        window.mParticle.logPageView(eventName, attributes);
      } else {
        window.mParticle.logEvent(eventName, eventType, attributes);
      }
    }
  } catch (err) {
    captureException({
      attributes,
      err,
      eventName,
      eventType,
      hsName: 'pushEventToMParticle error',
    });
  }
};
