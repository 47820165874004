import { PRIMARY_PRODUCT } from '../../apps/dashboard/constants';
import { isContractFusionOrUnified } from '../../apps/dashboard/utils/manage-organization';
import { OfferingType } from '../../types/organization';
import { OrganizationContractDependentsParamsToValues } from '../types/organization';

export const organizationPrimaryProductToOfferingTypes = (
  primary_product: string,
): OfferingType[] => {
  return isContractFusionOrUnified(primary_product)
    ? [OfferingType.HEADSPACE, OfferingType.GINGER]
    : [OfferingType[primary_product]];
};

export const organizationOfferingTypesToPrimaryProduct = (
  offerings: OfferingType[],
  fusionEnabled: boolean,
): PRIMARY_PRODUCT => {
  if (fusionEnabled) return PRIMARY_PRODUCT.FUSION;
  return offerings.length === 2
    ? PRIMARY_PRODUCT.UNIFIED
    : PRIMARY_PRODUCT[offerings[0]];
};

export const organizationContractDependentParamsToValues = (
  dependentsPerMemberToMap: number,
  dependentsSeatsToMap: number,
): OrganizationContractDependentsParamsToValues => {
  return {
    dependentSeats:
      dependentsPerMemberToMap === 0 ? '0' : String(dependentsSeatsToMap),
    dependentsPerMember: String(dependentsPerMemberToMap),
  };
};

export const parseOrganizationContractDependentValuesBasedOnInputType = (
  dependentsPerMember: string | number,
  dependentSeats: string | number,
): OrganizationContractDependentsParamsToValues => {
  if (
    typeof dependentsPerMember === 'string' &&
    typeof dependentSeats === 'string'
  ) {
    return {
      dependentsPerMember,
      dependentSeats,
    };
  }

  if (
    typeof dependentsPerMember === 'number' &&
    typeof dependentSeats === 'number'
  ) {
    return organizationContractDependentParamsToValues(
      dependentsPerMember,
      dependentSeats,
    );
  }

  throw new Error(
    `Unsupported type of dependent values. Must be string or number but received: dependentsPerMember=${typeof dependentsPerMember}, dependentSeats=${typeof dependentSeats}`,
  );
};
