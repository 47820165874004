import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ErrorResponse } from '../../../utils/parseError';
import { parseError } from '../../../utils/parseError';
import { openSettingsModal } from '../settingsModal';
import { RESTRICT_BY_MIGRATION_REDUCER_NAME } from './constants';
import { preUpdateRestrictByChangeWithPolling } from './utils';

interface ConfirmRestrictByChangeThunkProps {
  orgId: number;
  restrictBy: string;
  options?: Record<string, unknown> & { restrictedDomains?: string[] };
}

export const confirmRestrictByChange = createAsyncThunk(
  `${RESTRICT_BY_MIGRATION_REDUCER_NAME}/confirmRestrictByChange`,
  async (
    { orgId, restrictBy, options }: ConfirmRestrictByChangeThunkProps,
    { dispatch },
  ): Promise<any> => {
    try {
      const preUpdateRestrictByChangeResponse = await preUpdateRestrictByChangeWithPolling(
        orgId,
        restrictBy,
        false,
        options,
      );
      const {
        data: preUpdateRestrictByChangeReport,
      } = preUpdateRestrictByChangeResponse;

      return preUpdateRestrictByChangeReport;
    } catch (error) {
      const { message, title = '' } = parseError(error as ErrorResponse);
      dispatch(openSettingsModal({ message, title }));
      throw error;
    }
  },
);
