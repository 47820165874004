import { css } from '@emotion/react';
import {
  borderRadiusXS,
  borderWidthXS,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  Orange,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

export const stepButtonStyles = (color = Orange[200] as string) =>
  css({
    '& button': {
      '.thin': {
        backgroundColor: 'transparent',
        color,
        letterSpacing: 'normal',
        padding: 0,
        textDecorationLine: 'underline',
        textTransform: 'none',
      },
    },
  });

export const stepWrapper = (isActive: boolean) =>
  css({
    backgroundColor: isActive ? WarmGrey[100] : 'transparent',
    border: isActive ? `${borderWidthXS} solid ${WarmGrey[200]}` : 'none',
    borderRadius: isActive ? borderRadiusXS : 'none',
    boxShadow: isActive
      ? `${SpacingInRemBySize['3XS']} ${SpacingInRemBySize['3XS']} ${SpacingInRemBySize['2XS']} rgba(${WarmGrey[800]} 0.13)`
      : 'none',
    boxSizing: 'border-box',
    color: isActive ? (WarmGrey[800] as string) : (WarmGrey[600] as string),
    display: 'flex',
    flexDirection: 'row',
    margin: isActive
      ? `${SpacingInRemBySize.L} 0 ${SpacingInRemBySize['2XL']}`
      : 0,
    minHeight: isActive ? rem(8.5) : 'initial',
    padding: isActive
      ? `${SpacingInRemBySize.XL} ${SpacingInRemBySize['2XL']} ${SpacingInRemBySize.M} 0`
      : 0,
    position: 'relative',
  });

export const nextWrapper = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: SpacingInRemBySize.S,
  width: '100%',
});

export const titleStyles = (isActive: boolean) =>
  css({
    fontWeight: isActive
      ? FONT_WEIGHT_TO_NUMERIC_VALUE.bold
      : FONT_WEIGHT_TO_NUMERIC_VALUE.normal,
    marginBottom: SpacingInRemBySize.XS,
  });

export const statusWrapper = css({
  height: SpacingInRemBySize.M,
  margin: `0 ${SpacingInRemBySize.M}`,
  width: SpacingInRemBySize.M,
});

export const stepContentWrapper = css({
  width: '100%',
});
