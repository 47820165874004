import { ChallengeActionsTypes } from '../types/challenges';

const {
  CHALLENGES_RECEIVED,
  CHALLENGES_CREATED,
  CHALLENGES_CREATE_FAILED,
  CHALLENGES_FETCHING,
  CHALLENGES_CANCELING,
  CHALLENGES_RESET,
} = ChallengeActionsTypes;

const INITIAL_STATE: any = {
  data: [],
};

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHALLENGES_RECEIVED:
      return { ...state, data: action.payload };
    case CHALLENGES_CANCELING: {
      state.data.map((challenge) => {
        challenge.status =
          challenge.id === action.payload ? 'CANCELLED' : challenge.status;
      });
      return {
        ...state,
        data: [...state.data],
      };
    }
    case CHALLENGES_FETCHING:
      return { ...state, isFetch: action.payload };
    case CHALLENGES_CREATED:
      return { ...state, created: action.payload };
    case CHALLENGES_CREATE_FAILED:
      return { ...state, error: action.payload };
    case CHALLENGES_RESET:
      return { ...state, error: '', created: '' };
    default:
      return state;
  }
}
