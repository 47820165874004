import { css } from '@emotion/react';
import {
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../../constants/breakpoint';

export const sliderContainerStyle = css({
  width: rem(18.125),
  [WebUIComponentsBreakPoint.Mobile]: {
    width: '70%',
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    marginTop: SpacingInRemBySize['2XL'],
    width: rem(20.5),
  },
});

export const thumbIconStyle = css({
  border: `${SpacingInRemBySize['3XS']} solid ${WarmGrey[400]}`,
  top: rem(-0.75),
  backgroundColor: 'white',
  height: SpacingInRemBySize.XL,
  transform: 'translate(50%) scale(1)',
  width: SpacingInRemBySize.XL,
});

export const stepsMarkingContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: rem(1.25),
  height: SpacingInRemBySize.XS,
  width: '100%',
});

export const stepsMarkingNarrowingContainerStyle = css({
  display: 'flex',
  flex: '1 1 auto',
  justifyContent: 'space-between',
  paddingLeft: SpacingInRemBySize['2XS'],
  paddingRight: SpacingInRemBySize['2XS'],
});

export const markStyle = css({
  display: 'inline-block',
  borderRadius: `${rem(2.5)} ${rem(2.5)}`,
  background: WarmGrey[400],
  height: 'inherit',
  width: rem(0.188),
});

export const emotionImageContainerStyle = css({
  display: 'flex',
  marginBottom: SpacingInRemBySize['5XL'],
  justifyContent: 'center',
  alignItems: 'center',
  '@media(max-width: 768px)': {
    marginBottom: SpacingInRemBySize.XL,
  },
});

export const emotionImageStyle = css({
  height: rem(10.625),
  width: rem(10.625),
  [WebUIComponentsBreakPoint.Mobile]: {
    height: rem(6.875),
    width: rem(6.875),
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    height: rem(13.75),
    width: rem(13.75),
  },
});
