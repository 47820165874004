import { getConfig } from '../../../config';

export const ACTIVE = 'ACTIVE';
export const ZUORA = 'ZUORA';
export const CURRENT_SUBSCRIBER = 'CURRENT_SUBSCRIBER';

/** V3_LOGIN_PAGE refers to the latest version of the login and enrollment flow */
export const V3_LOGIN_PAGE = 'V3_LOGIN_PAGE';

export enum CookieKeys {
  LANGUAGE = 'lang',
  COUNTRY_CODE = 'countryCode',
}

export const cookieConfigWithCustomExpiry = {
  ...getConfig().ENROLLMENT_AUTH0_COOKIE.COOKIE_CONFIGURATION,
  expires: 90,
};

export const ANDROID_APP_STORE = 'Android app store';
export const IOS_APP_STORE = 'iOS app store';

export const ANDROID_LINK =
  'https://play.google.com/store/apps/details?id=com.getsomeheadspace.android';
export const IOS_LINK =
  'https://itunes.apple.com/us/app/headspace.com-meditation-mindfulness/id493145008?mt=8';

export enum Product {
  GINGER = 'ginger',
  HEADSPACE = 'headspace',
}

interface TranslatedLinkTypes {
  [key: string]: string;
}

// Mappings for translated FAQ pages
export const translatedLink: TranslatedLinkTypes = {
  en: 'en-us',
  fr: 'fr',
  es: 'es-419',
  de: 'de',
  pt: 'pt-br',
};

// from https://github.com/HeadspaceMeditation/hs-website/blob/master/app/services/location.js
export const currencyToCountryCodes = {
  EUR: [
    'AS',
    'AD',
    'AT',
    'BE',
    'FI',
    'FR',
    'GF',
    'TF',
    'DE',
    'GR',
    'GP',
    'IE',
    'IT',
    'LU',
    'MQ',
    'YT',
    'MC',
    'NL',
    'PT',
    'RE',
    'WS',
    'SM',
    'SI',
    'ES',
    'VA',
  ],
  USD: [
    'IO',
    'GU',
    'MH',
    'FM',
    'MP',
    'PW',
    'PR',
    'TC',
    'US',
    'UM',
    'VG',
    'VI',
    'USAF',
  ],
  // JPY: ["JP"],
  // CAD: ["CA"],
  GBP: ['GS', 'GB', 'JE', 'IM', 'SH'],
};

const reverseMap = Object.keys(currencyToCountryCodes).reduce((prev, curr) => {
  const codes = currencyToCountryCodes[curr];
  codes.forEach((code) => {
    prev[code] = curr;
  });
  return prev;
}, {});

export function currencyForCountryCode(countryCode = 'US') {
  return reverseMap[countryCode] || 'USD';
}

const getRuntime = () => {
  const { host } = window.location;
  if (host === 'b2b.staging.headspace.com') {
    return 'staging';
  }

  if (host === 'work.headspace.com') {
    return 'production';
  }

  return 'integration';
};

export const ENV = getRuntime();
export const PROD_JWT = 'hsngjwt';
export const ALL_OTHER_ENV_JWT = 'hsjwt';

export const TERMS_AND_CONDITIONS_LINK =
  'https://www.headspace.com/terms-and-conditions';
export const PRIVACY_POLICY_LINK = 'https://www.headspace.com/privacy-policy';
export const START_USING_HS_LINK = {
  integration: 'https://my-headspace.integration.headspace.com/modes/meditate',
  staging: 'https://my-headspace.staging.headspace.com/modes/meditate',
  production: 'https://my.headspace.com/modes/meditate',
}[ENV];
