import * as React from 'react';
import { Modal } from '@headspace/web-ui-components';
import { modalBodyStyle, modalStyle } from './styles';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

const ModalBody: React.FC = () => {
  const { translate } = useTranslate();

  return (
    <div css={modalBodyStyle}>
      <h2 className="title">
        {translate('organizationSettings.survey.surveySentModal.title')}
      </h2>
      <p className="copy">
        {translate('organizationSettings.survey.surveySentModal.body')}
      </p>
      <br />
    </div>
  );
};

const TranslatedModalBody = ModalBody;

interface SurveySentModalPropTypes {
  isOpen: boolean;
  onCloseModal: () => void;
}

export const SurveySentModal: React.FC<SurveySentModalPropTypes> = ({
  isOpen,
  onCloseModal,
}) => {
  return isOpen ? (
    <Modal
      css={modalStyle}
      body={<TranslatedModalBody />}
      onClose={onCloseModal}
    />
  ) : null;
};
