import { AuthConnections } from '@headspace/web-auth';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { flowStartScreenViewAction } from '../../../../../../analytics/events/enrollmentEvents';
import { HealthProvider } from '../../../../constants/healthProvider';
import { Routes } from '../../../../constants/routes';
import {
  getAuthConnections,
  getHsUserId,
} from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getIsEnrolled } from '../../../../state/selectors/ssoSelector/ssoSelector';
import { MemberLogin } from '../../../MemberLoginPage';
import { PageWrapper } from '../PageWrapper/PageWrapper';
import { ConnectVPComponent } from './ConnectVPComponent/ConnectVPComponent';

export const LandingPage = () => {
  const dispatch = useDispatch();
  const [isConnectVPComponentOpen, closeConnectComponent] = useState(true);
  const navigate = useNavigate();
  const HS_USER_ID = useSelector(getHsUserId);
  const isEnrolled = useSelector(getIsEnrolled);
  const userConnections = useSelector(getAuthConnections);

  useEffect(() => {
    if (HS_USER_ID) {
      if (
        userConnections?.includes(AuthConnections.VIRGINPULSE) &&
        isEnrolled
      ) {
        window.location.href = 'https://headspace.app.link/explore';
      } else {
        navigate(Routes.SSO_LINK);
      }
    }
  }, [HS_USER_ID, userConnections, isEnrolled]);

  useEffect(() => {
    dispatch(flowStartScreenViewAction(HealthProvider.VP));
  });

  return (
    <PageWrapper healthProvider={HealthProvider.VP}>
      {isConnectVPComponentOpen ? (
        <ConnectVPComponent closeConnectComponent={closeConnectComponent} />
      ) : (
        <MemberLogin />
      )}
    </PageWrapper>
  );
};
