import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../../../root-component/PrivateRoute';
import { Toolkit } from './ToolkitView';
import { SettingsView } from './SettingsView/SettingsView';
import { ChallengesView } from './ChallengesView';
import { OrganizationMembersWithScrollView } from './MembersView';
import { ComposedInsightsView } from './InsightsView';

export const TabRoutesComponent: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/members"
        element={
          <PrivateRoute data-testid="members-tab">
            <OrganizationMembersWithScrollView />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute data-testid="settings-tab">
            <SettingsView />
          </PrivateRoute>
        }
      />
      <Route
        path="/insights"
        element={
          <PrivateRoute data-testid="insights-tab">
            <ComposedInsightsView />
          </PrivateRoute>
        }
      />
      <Route
        path="/toolkit"
        element={
          <PrivateRoute data-testid="toolkit-tab">
            <Toolkit />
          </PrivateRoute>
        }
      />
      <Route
        path="/challenges"
        element={
          <PrivateRoute data-testid="challenges-tab">
            <ChallengesView />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
