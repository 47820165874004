import { useTranslate } from '../../../../../hooks/useTranslate';
import { NextStepsModule } from './components /NextStepsModule';
import {
  desktopContainerCss,
  desktopSubtitleStyle,
  desktopTitleStyle,
  mainContainerCss,
} from './styles';

export const SkipOnboardingFinishPage = () => {
  const { translate } = useTranslate();
  const desktopTitle = translate(
    'enrollment.skipOnboarding.nextStepsPage.desktopTablet.title',
  );
  const desktopSubtitle = translate(
    'enrollment.skipOnboarding.nextStepsPage.desktopTablet.body',
  );

  return (
    <div css={mainContainerCss}>
      <div css={desktopContainerCss}>
        <h1 css={desktopTitleStyle}>{desktopTitle}</h1>
        <p css={desktopSubtitleStyle}>{desktopSubtitle}</p>
      </div>
      <div>
        <NextStepsModule />
      </div>
    </div>
  );
};
