import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { BaseExceptionPage } from './BaseExceptionPage';

interface ExpiredDependentLinkPageQueryParams {
  error: string;
}

export const expiredDependentLinkPageQueryParams: ExpiredDependentLinkPageQueryParams = {
  error: 'error',
};

export const ExpiredDependentLinkPage: FC = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const showSubtitle =
    queryParams.get(expiredDependentLinkPageQueryParams.error) !== '404';

  return (
    <BaseExceptionPage
      titleKey="enrollment.errors.dependents.title"
      subtitleKey={showSubtitle ? 'enrollment.errors.dependents.subHeader' : ''}
      dataTestIdPrefix="dependents-expired-link"
    />
  );
};
