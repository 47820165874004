import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAndConfirmElfMemberType } from '../../../analytics/events/enrollmentEvents';
import { useTranslate } from '../../../hooks/useTranslate';
import { RadioInput } from '../../../shared-components/Input/RadioInput';
import { getActiveOrg } from '../state/selectors/orgSectionSelector/orgSectionSelector';
import { FormHeader } from '../views/UnifiedEligibilityViews/VerificationPage/components/FormHeader';
import { SubmitButton } from '../views/UnifiedEligibilityViews/VerificationPage/components/SubmitButton';
import { Terms } from '../views/UnifiedEligibilityViews/VerificationPage/components/Terms';
import { FAQ_PAGE_ELF_DEPENDENTS_LINK } from '../views/UnifiedEligibilityViews/constants';
import { containerStyle } from '../views/UnifiedEligibilityViews/styles/verificationStyles';
import { FormContainer } from '../views/shared-components/FormContainer/FormContainer';

export type ElfMemberTypeChooserProps = {
  onChoice: (choice: string) => void;
};

export const ElfMemberTypeChooser: FC<ElfMemberTypeChooserProps> = ({
  onChoice,
}) => {
  const dispatch = useDispatch();
  const activeOrg = useSelector(getActiveOrg);
  const [elfMemberType, setElfMemberType] = useState('');
  const { translate } = useTranslate();
  const setChoice = useCallback(() => {
    dispatch(selectAndConfirmElfMemberType(elfMemberType));
    onChoice(elfMemberType);
  }, [elfMemberType]);

  const choices = ['primary', 'dependent'];
  const {
    campaignDisplayName,
    enrollment: { dependentEnrollmentFlowDisplayName },
  } = activeOrg;
  const choiceDisplayName =
    dependentEnrollmentFlowDisplayName ?? campaignDisplayName ?? '';

  const faqComponent = useMemo(() => {
    return <Terms faqLinkOverride={FAQ_PAGE_ELF_DEPENDENTS_LINK} />;
  }, []);

  return (
    <FormContainer termsComponentOverride={faqComponent}>
      <div css={containerStyle}>
        <FormHeader
          title={translate('enrollment.elfTypeChoice.header.title')}
        />
        {choices.map((choice) => (
          <RadioInput
            key={choice}
            choice={choice}
            title={translate(`enrollment.elfTypeChoice.${choice}.title`, {
              orgName: choiceDisplayName,
            })}
            subtitle={translate(`enrollment.elfTypeChoice.${choice}.subtitle`, {
              orgName: choiceDisplayName,
            })}
            namespace="elf-member-type"
            onChange={setElfMemberType}
            data-testid={`elf-member-type-${choice}`}
          />
        ))}
        <SubmitButton
          value={translate('enrollment.elfTypeChoice.button')}
          dataTestId="elf-member-type-submit"
          onClick={setChoice}
          disabled={!elfMemberType}
        />
      </div>
    </FormContainer>
  );
};
