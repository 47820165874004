import {
  HealthProviderActionTypes,
  SetHealthProviderConnectionErrorAction,
  SetHealthProviderConnectionFinishedAction,
  SetHealthProviderConnectionStartedAction,
} from './healthProviderActionTypes';

export const setHealthProviderConnectionStarted = (): SetHealthProviderConnectionStartedAction => {
  return {
    type: HealthProviderActionTypes.SET_HEALTH_PROVIDER_CONNECTION_STARTED,
  };
};

export const setHealthProviderConnectionFinished = (): SetHealthProviderConnectionFinishedAction => {
  return {
    type: HealthProviderActionTypes.SET_HEALTH_PROVIDER_CONNECTION_FINISHED,
  };
};

export const setHealthProviderConnectionError = (
  errorCode: string | null,
): SetHealthProviderConnectionErrorAction => {
  return {
    type: HealthProviderActionTypes.SET_HEALTH_PROVIDER_CONNECTION_ERROR,
    payload: {
      error: {
        code: errorCode,
      },
    },
  };
};
