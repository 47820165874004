import { useCallback } from 'react';
import * as React from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '../../../../../../shared-components/container/Container';
import { SettingsPanel } from '../shared-components/SettingsPanel/SettingsPanel';
import { OrganizationAdministratorsReview } from '../../../../OrganizationAdministrators/OrganizationAdministratorsReview';
import { OrganizationAdministratorsEdit } from '../../../../OrganizationAdministrators/OrganizationAdministratorsEdit';
import { OrganizationAdministratorsDisplay } from '../../../../OrganizationAdministrators/OrganizationAdministratorsDisplay';
import { TwoColumn } from '../shared-components/TwoColumn/TwoColumn';
import { isCSM as getIsCSM } from '../../../../../../state/selectors/userSelectors';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  getOrgManagement,
  getSettingsChangedStatus,
  getAdminSaveDisabledStatus,
  getAdminDeleteDisabledStatus,
} from '../../../../../../state/selectors/organizationSelectors';
import { getCachedOrgAdmins } from '../../../../../../state/selectors/organization/adminsSelector/adminsSelector';
import {
  cancelUpdateAdmin,
  deleteAdmin,
  handleChange as handleChangeAction,
  newAdmin,
  saveAdmin,
  toggleEditPanelView,
  updateAdmin,
  updateImplicitState,
  saveOrganizationAdministrators,
} from '../../../../../../state/actions/organizationActions';
import { ORGANIZATION_ADMINS, VIEW_PANEL_COUNT } from '../../../../constants';
import { columnFlex, flex1 } from '../../../../styles/flex';
import { Admin } from '../../../../../../state/models/organization/Admin';

const filterParentAdmins = (admins: Admin[]) =>
  admins.filter((admin) => !admin.isParentOrgAdmin);

export const AdminsPanel: React.FC = () => {
  const { translate } = useTranslate();
  const {
    adminReviewView,
    adminEditExisting,
    admin_name,
    admin_title,
    admin_email,
    org_admins,
    admin_nameError,
    admin_titleError,
    admin_emailError,
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
  } = useSelector(getOrgManagement);
  const cachedOrgAdmins = useSelector(getCachedOrgAdmins);
  // We only want to show admins that are not also admins in the parent org
  const filteredOrgAdmins = filterParentAdmins(org_admins);
  const filteredCachedOrgAdmins = filterParentAdmins(cachedOrgAdmins);
  const hasCachedOrgAdmins = !R.isEmpty(filteredCachedOrgAdmins);
  const isPanelInView = viewPanels[ORGANIZATION_ADMINS];
  const dispatch = useDispatch();
  const hasAddedAminInFormState = !R.isEmpty(org_admins);

  const settingsChanged = useSelector(getSettingsChangedStatus);
  const adminSaveDisabledStatus = useSelector(getAdminSaveDisabledStatus);
  const adminDeleteDisabledStatus = useSelector(getAdminDeleteDisabledStatus);
  const isCSM = useSelector(getIsCSM);
  const handleCancelAdminUpdate = useCallback(() => {
    dispatch(cancelUpdateAdmin());
  }, [dispatch]);

  const handleSaveAdministratorChanges = useCallback(() => {
    dispatch(saveOrganizationAdministrators());
  }, [dispatch]);

  const handleToggleView = useCallback(
    (index) => {
      dispatch(toggleEditPanelView(index));
    },
    [dispatch],
  );

  const handleError = useCallback((e) => dispatch(updateImplicitState(e)), [
    dispatch,
  ]);

  const handleChange = useCallback(
    (event) => {
      dispatch(handleChangeAction(event));
    },
    [dispatch],
  );

  const handleAdminAdd = useCallback(() => dispatch(newAdmin()), [dispatch]);
  const handleAdminSave = useCallback(() => dispatch(saveAdmin()), [dispatch]);

  const handleAdminEdit = useCallback((e) => dispatch(updateAdmin(e)), [
    dispatch,
  ]);
  const handleAdminCancel = useCallback(() => dispatch(cancelUpdateAdmin()), [
    dispatch,
  ]);
  const handleAdminDelete = useCallback((e) => dispatch(deleteAdmin(e)), [
    dispatch,
  ]);

  return (
    <Container>
      <SettingsPanel
        title={translate('organizationSettings.administrators.title')}
        view={isPanelInView}
        toggleView={handleToggleView}
        useEditCta={!hasCachedOrgAdmins}
        editCtaLabel={translate(
          'organizationSettings.administrators.addAdministratorCta',
        )}
        cancelChanges={handleCancelAdminUpdate}
        saveChanges={handleSaveAdministratorChanges}
        index={ORGANIZATION_ADMINS}
        displayEditButton={isCSM && viewPanels.indexOf(false) < 0}
        settingsChanged={settingsChanged}
        hideControls={!adminReviewView}
        dataTestId="organization-settings-admins"
      >
        {isPanelInView && hasCachedOrgAdmins && (
          <TwoColumn>
            <OrganizationAdministratorsDisplay
              admins={filteredCachedOrgAdmins}
            />
          </TwoColumn>
        )}
        {!isPanelInView && hasAddedAminInFormState && adminReviewView && (
          <div css={[flex1, columnFlex]}>
            <OrganizationAdministratorsReview
              admins={filteredOrgAdmins}
              handleAdminAdd={handleAdminAdd}
              handleAdminDelete={handleAdminDelete}
              handleAdminEdit={handleAdminEdit}
            />
          </div>
        )}

        {!isPanelInView && (
          <div css={[flex1, columnFlex]}>
            <OrganizationAdministratorsEdit
              isEdit={adminEditExisting}
              admin_name={admin_name}
              admin_nameError={admin_nameError}
              admin_title={admin_title}
              admin_titleError={admin_titleError}
              admin_email={admin_email}
              admin_emailError={admin_emailError}
              adminSaveDisabledStatus={adminSaveDisabledStatus}
              adminDeleteDisabledStatus={adminDeleteDisabledStatus}
              handleAdminDelete={handleAdminDelete}
              handleAdminCancel={handleAdminCancel}
              handleAdminSave={handleAdminSave}
              handleChange={handleChange}
              handleError={handleError}
            />
          </div>
        )}
      </SettingsPanel>
    </Container>
  );
};
