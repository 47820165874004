import { css } from '@emotion/react';
import {
  cssWithMq,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

export const ContainerStyle = cssWithMq({
  backgroundColor: WarmGrey[100],
  borderRadius: [0, SpacingInRemBySize.XL, SpacingInRemBySize.XL],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: [null, SpacingInRemBySize['2XL'], null],
  maxWidth: ['100vw', rem(30.125), '25vw'],
  padding: `${SpacingInRemBySize.M} ${SpacingInRemBySize.XL}`,
});

export const ListRowItemStyle = css({
  marginLeft: rem(1.25),
});

export const centerElementStyle = css({
  justifyContent: 'center',
  textAlign: 'center',
});

export const downloadHeadspaceMobile = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: `${SpacingInRemBySize.L} 0 ${SpacingInRemBySize.M}`,
  textAlign: 'center',
});

export const downloadHeadspace = css({
  display: 'flex',
  margin: `${SpacingInRemBySize.M} 0`,
});

export const onlyOnMobile = cssWithMq({
  display: ['flex', 'none', 'none'],
});

export const supportMobile = cssWithMq(centerElementStyle, {
  display: ['block', 'none', 'none'],
  marginBottom: SpacingInRemBySize['3XL'],
});
