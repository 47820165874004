import * as Sentry from '@sentry/browser';

import { ThunkDispatch, GetState, ThunkResult } from '../../types';
import { ModalActionsTypes } from '../../types/modal';
import { downloadEngagementReport } from '../../../rest';
import { ErrorResponse, parseError } from '../../../utils/parseError';

const { OPEN_MODAL } = ModalActionsTypes;

export const handleEngagementReportDownload = (): ThunkResult<
  Promise<void>
> => async (dispatch: ThunkDispatch, getState: GetState) => {
  const {
    manageOrganization: { cachedOrg: { id = -1, name = '' } = {} },
  } = getState();
  const nameWithoutDots = name.split('.').join('');
  try {
    await downloadEngagementReport(id, nameWithoutDots);
  } catch (err) {
    Sentry.captureException(err);
    dispatch({
      payload: {
        message: parseError(err as ErrorResponse).message,
      },
      type: OPEN_MODAL,
    });
  }
};
