import { useCallback, useEffect } from 'react';
import * as React from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  borderRadiusXS,
  Grey,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { FieldLabel } from '../../../../../../../shared-components/text';
import { Step } from '../../../../../../../shared-components/Steps/Step';
import { STEP_STATUS } from '../../../../../constants';
import { RestrictByMigrationReport } from './RestrictByMigrationReport';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import {
  selectDataUploadFinished,
  selectRestrictByMigration,
} from '../../../../../../../state/slices/restrictByMigration';
import { RestrictByEmailDomainInput } from './RestrictedByEmailDomainInput';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { handleEmptyArray } from '../../../../../../../state/helpers';
import { uploadDomains } from '../../../../../../../state/slices/restrictByMigration/uploadDomainsThunk';
import {
  alignItemsCenter,
  flex,
  justifyContentSpaceBetween,
} from '../../../../../styles/flex';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

const headerWrapper = css(flex, justifyContentSpaceBetween, alignItemsCenter, {
  marginBottom: SpacingInRemBySize.L,
});

const subtitleLinkStyle = css({
  color: WarmGrey[800],
});

const stepsWrapper = css({
  '& .ctaButton': {
    position: 'absolute',
    right: SpacingInRemBySize['2XL'],
  },
});

const saveDomainButtonStyle = css({
  whiteSpace: 'nowrap',
});

const restrictByEmailDomainInputWrapper = css({
  width: '95%',
});

const completeSubtitleWarningStyle = css({
  background: WarmGrey[200],
  borderRadius: borderRadiusXS,
  color: WarmGrey[800],
  padding: `${SpacingInRemBySize.XS} ${SpacingInRemBySize.M}`,
  width: 'max-content',
});

interface RestrictByMigrationEmailDomainStepsProps {
  handleNextStep: () => void;
  steps: Record<string, any>;
  openErrorReportModal: () => void;
  onConfirmButtonClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    options?: { restrictedDomains?: string[] },
  ) => void;
  onConfirmButtonKeyDown: (
    event: React.KeyboardEvent<HTMLButtonElement>,
    options?: { restrictedDomains?: string[] },
  ) => void;
}

export const RestrictByMigrationEmailDomainSteps: React.FC<RestrictByMigrationEmailDomainStepsProps> = ({
  handleNextStep,
  steps,
  openErrorReportModal,
  onConfirmButtonClick,
  onConfirmButtonKeyDown,
}) => {
  const { activeStep, elFileReport } = useSelector(selectRestrictByMigration);
  const { restrictedDomains, restrictBy, orgId } = useSelector(
    getOrgManagement,
  );
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const isDomainUploadingFinished = useSelector(selectDataUploadFinished);
  const restrictedDomainsSanitized = handleEmptyArray(restrictedDomains);

  const handleSaveDomainsButtonClick = useCallback(() => {
    dispatch(
      uploadDomains({
        orgId,
        restrictBy,
        restrictedDomains: restrictedDomainsSanitized,
      }),
    );
  }, [dispatch, orgId, restrictBy, restrictedDomainsSanitized]);

  useEffect(() => {
    if (isDomainUploadingFinished) {
      handleNextStep();
    }
  }, [isDomainUploadingFinished, handleNextStep]);

  const handleOnConfirmButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      onConfirmButtonClick(event, { restrictedDomains }),
    [restrictedDomains, onConfirmButtonClick],
  );
  const handleOnConfirmKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) =>
      onConfirmButtonKeyDown(event, { restrictedDomains }),
    [restrictedDomains, onConfirmButtonKeyDown],
  );

  return (
    <div>
      <div css={headerWrapper}>
        <FieldLabel>
          {translate('restrictByMigrationSteps.steps.emailDomain.header')}
        </FieldLabel>
      </div>
      <div css={stepsWrapper}>
        <Step
          showCTA={false}
          handleNextStep={handleNextStep}
          showDivider={activeStep > 2}
          showSubtitle={steps[0].status !== STEP_STATUS.COMPLETE}
          status={steps[0].status}
          step={steps[0].step}
          title={translate(
            'restrictByMigrationSteps.steps.emailDomain.step_1.title',
          )}
          subtitle={
            <div css={restrictByEmailDomainInputWrapper}>
              <RestrictByEmailDomainInput view={false} />
            </div>
          }
        >
          {steps[0].status === STEP_STATUS.ACTIVE ? (
            <div>
              <TextButton
                value="Save domains"
                id="save-button"
                css={saveDomainButtonStyle}
                disabled={!restrictedDomainsSanitized.length}
                onClick={handleSaveDomainsButtonClick}
              />
            </div>
          ) : null}
        </Step>
        <Step
          activeChildren={
            <RestrictByMigrationReport
              elFileReport={elFileReport}
              openErrorReportModal={openErrorReportModal}
            />
          }
          ctaText={translate('restrictByMigrationSteps.steps.step_2.next_step')}
          showCTA={true}
          handleNextStep={handleNextStep}
          showDivider={activeStep === 1 || activeStep > 3}
          showSubtitle={steps[1].status !== STEP_STATUS.COMPLETE}
          status={steps[1].status}
          step={steps[1].step}
          subtitle={
            <span css={subtitleLinkStyle}>
              {translate('restrictByMigrationSteps.steps.step_2.subtitle')}
            </span>
          }
          title={translate('restrictByMigrationSteps.steps.step_2.title')}
        />
        <Step
          showCTA={false}
          handleNextStep={handleNextStep}
          showDivider={false}
          showSubtitle={steps[2].status !== STEP_STATUS.COMPLETE}
          status={steps[2].status}
          step={steps[2].step}
          subtitle={
            <span css={subtitleLinkStyle}>
              {translate(
                'restrictByMigrationSteps.steps.emailDomain.step_3.subtitle',
              )}
            </span>
          }
          completeSubtitle={
            <>
              <div>
                {translate(
                  'restrictByMigrationSteps.steps.step_3.completeSubtitle.message',
                )}
              </div>
              <div>
                <p css={completeSubtitleWarningStyle}>
                  {translate(
                    'restrictByMigrationSteps.steps.step_3.completeSubtitle.warning',
                  )}
                </p>
              </div>
            </>
          }
          title={translate('restrictByMigrationSteps.steps.step_3.title')}
        >
          {steps[2].status === STEP_STATUS.ACTIVE ? (
            <label htmlFor="confirm-button">
              <TextButton
                dataTestId="confirm-button"
                id="confirm-button"
                value={translate(
                  'restrictByMigrationSteps.steps.step_3.confirm_button_text',
                )}
                onClick={handleOnConfirmButtonClick}
                onKeyDown={handleOnConfirmKeyDown}
              />
            </label>
          ) : null}
        </Step>
      </div>
    </div>
  );
};
