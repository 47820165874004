import { ThunkAction } from '@reduxjs/toolkit';
import reactHtmlParser from 'react-html-parser';

import { slugSafe } from '../../../utils/validations';
import { RootState } from '../../types';
import {
  OrganizationActionsTypes,
  ProcessErrorsAction,
  ProcessSideEffectsAction,
} from '../../types/organization';
import { processErrors } from './processErrors';

const { PROCESS_SIDE_EFFECTS } = OrganizationActionsTypes;

export const updateImplicitState = (
  event: any,
  supressValidation?: boolean,
): ThunkAction<
  void | Promise<void>,
  RootState,
  void,
  ProcessSideEffectsAction | ProcessErrorsAction
> => {
  const {
    target: { id },
  } = event;

  return (dispatch, getState) => {
    const {
      manageOrganization: { org_name, viewPanels },
    } = getState();

    if (
      ['org_name', 'gingerId'].includes(id) &&
      !viewPanels
      /* We only want this side effect if we are creating an org, thus viewPanels,
         which are only present in org edit.
      */
    ) {
      dispatch({
        payload: {
          body: reactHtmlParser(''),
          headline: reactHtmlParser(''),
          slug: org_name.toLocaleLowerCase().replace(slugSafe, ''),
        },
        type: PROCESS_SIDE_EFFECTS,
      });
    }

    if (supressValidation) {
      return;
    }

    dispatch(processErrors(event));
  };
};
