import {
  preUpdateRestrictByChange,
  getPreUpdateRestrictByChangeStatus,
} from '../../../../rest/eligibillityFile/eligibilityFile.api';
import { PreUpdateRestrictByChange } from '../../../../rest/eligibillityFile/elegibilityFile.types';
import {
  ELF_STATUS_POLLING_INTERVAL,
  PRE_UPDATE_STATUS,
} from '../../../../apps/dashboard/constants';

const pollPreUpdateStatus = async (migrationIds: number[]) =>
  new Promise((resolve, reject) => {
    const getPreUpdateState = async () => {
      try {
        const preUpdateStatusResponse = await getPreUpdateRestrictByChangeStatus(
          migrationIds,
        );
        const { status } = preUpdateStatusResponse.data;

        if (PRE_UPDATE_STATUS.COMPLETED === status) {
          resolve(preUpdateStatusResponse.data);
          return;
        }
        if (PRE_UPDATE_STATUS.FAILED === status) {
          reject(preUpdateStatusResponse);
          return;
        }

        setTimeout(() => getPreUpdateState(), ELF_STATUS_POLLING_INTERVAL);
      } catch (error) {
        reject(error);
      }
    };

    setTimeout(() => getPreUpdateState());
  });

export const preUpdateRestrictByChangeWithPolling: PreUpdateRestrictByChange = async (
  orgId,
  restrictBy,
  dryRun = true,
  options = {},
) => {
  const { data }: { data: number | number[] } = await preUpdateRestrictByChange(
    orgId,
    restrictBy,
    dryRun,
    options,
  );

  return pollPreUpdateStatus(Array.isArray(data) ? data : [data]);
};
