import { css, SerializedStyles } from '@emotion/react';
import {
  fontSizeXS,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  rem,
  rgba,
  SpacingInRemBySize,
  White,
  borderRadiusXS,
  borderWidthXS,
  Black,
  borderWidthS,
  fontSizeM,
  fontSizeXL,
  Green,
  Grey,
  Slate,
  CoolGrey,
  WarmGrey,
} from '@headspace/web-ui-components';

export const DEFAULT_ROW_HEIGHT = 96;
export const SUB_ROW_MARGIN = 16;
export const SUB_ROW_HEIGHT = 47;

export const tableStyle = ({ height }) => {
  return css({
    '& .ReactVirtualized__Grid': {
      '.ReactVirtualized__Table__Grid': {
        overflow: 'visible !important',
      },
      outline: 'none',
    },
    '& .ReactVirtualized__Table__headerRow': {
      ...headerRowStyle,
    },
    '.ReactVirtualized__Grid__innerScrollContainer': {
      overflow: 'visible !important',
    },
    display: 'flex',
    flexDirection: 'column',
    height: height || `100vh`,
    minHeight: rem(43.75),
  });
};

export const headerRowStyle = css({
  '.ReactVirtualized__Table__headerColumn': {
    '& .ReactVirtualized__Table__headerTruncatedText': {
      ':not(:only-child)': {
        color: WarmGrey[700],
      },
      display: 'flex',
      whitespace: 'normal !important',
      wordbreak: 'break-word',
    },
    '& span ~ svg': {
      fill: WarmGrey[700],
    },
    ':first-of-type': {
      paddingLeft: rem(6.125),
    },
    ':last-of-type': {
      ' svg': {
        marginRight: 0,
      },
      borderRight: 'none',
      marginRight: 0,
    },
    alignItems: 'center',
    borderRight: `${borderWidthXS} solid ${WarmGrey[200]}`,
    color: WarmGrey[500],
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: fontSizeXS,
    fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.bold,
    height: SpacingInRemBySize.L,
    letterSpacing: rem(0.156),
    marginRight: SpacingInRemBySize.M,
    minWidth: 0,
    outline: 'none',
    textTransform: 'uppercase',
    wordbreak: 'break-all !important',
  },
  alignItems: 'center',
  outline: 'none',
  userSelect: 'none',
});

export const iconColumnStyle = ({ icon }) => {
  return css(
    icon
      ? {
          ':hover': {
            cursor: 'pointer',
          },
          backgroundImage: `url(${icon})`,
          backgroundPosition: `right ${rem(1.75)} center`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: SpacingInRemBySize.XS,
          fill: WarmGrey[700],
          marginRight: 0,
        }
      : {},
  );
};

export const rowStyle = (options) =>
  // @ts-ignore
  css({
    ' .ReactVirtualized__Table__row': {
      ' .subRow': {
        ' .ReactVirtualized__Table__rowColumn > *:not(.dropdown), .ReactVirtualized__Table__rowColumn > *:not(.dropdown) *': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        },
        ' .rightRowMargin': {
          ...iconColumnStyle(options),
        },
        ' .toggle': {
          alignItems: 'center',
          border: `${borderWidthS} solid ${WarmGrey[200]}`,
          borderRadius: rem(0.75),
          boxSizing: 'border-box',
          display: 'flex',
          height: SpacingInRemBySize.L,
          justifyContent: 'center',
          marginRight: SpacingInRemBySize.M,
          transition: '0.5s',
          width: SpacingInRemBySize.L,
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        minHeight: `${SUB_ROW_HEIGHT}px`,
        width: '100%',
      },
      '&.expanded': {
        ' .subRow': {
          ' .toggle': {
            transform: 'rotate(90deg)',
          },
          margin: `${SUB_ROW_MARGIN}px 0`,
        },
        ' .subRow:first-of-type': {
          borderBottom: `${borderRadiusXS} solid ${Grey['200']}`,
          margin: '0',
          padding: `${rem(1.125)} 0`,
        },
        display: 'block',
      },
      '.ReactVirtualized__Table__rowColumn': {
        marginRight: SpacingInRemBySize.M,
      },
      backgroundColor: White['000'],
      border: `${borderWidthXS} solid ${WarmGrey[200]} !important`,
      borderRadius: borderRadiusXS,
      boxShadow: `0 ${SpacingInRemBySize['3XS']} 0 0 rgba(${WarmGrey[800]}, 0.04)`,
      boxSizing: 'border-box',
      color: WarmGrey[700],
      flexDirection: 'column',
      fontSize: fontSizeXS,
      height: `calc(100% - ${SpacingInRemBySize.XS})`,
      justifyContent: 'center',
      outline: 'none',
      overflow: 'visible !important',
      padding: `0 ${SpacingInRemBySize.L}`,
      position: 'relative !important',
      width: 'auto !important',
    },
    height: 0,
    marginBottom: rem(0.375),
    width: '100%',
  });

export const columnStyle = (columnWidth: number): SerializedStyles =>
  css({
    flex: columnWidth ? `0 1 ${columnWidth}px` : `0 1 ${rem(18.75)}`,
    overflow: 'hidden',
  });

export const rowMarginStyle: SerializedStyles = css({
  '.leftRowMargin': {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: `0 0 0 ${SpacingInRemBySize.L}`,
  },
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: rem(4.5),
});

const vistaBlueOpacity10 = rgba(Green[100], 0.1);
export const selectRowStyle: SerializedStyles = css(rowStyle({}), {
  background: `linear-gradient(${vistaBlueOpacity10}, ${vistaBlueOpacity10}), linear-gradient(${White['000']}, ${White['000']})`,
  borderColor: Green[100],
});

export const actionsStyle = css({
  '& button, & .dropdown': {
    marginLeft: SpacingInRemBySize.M,
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

export const clearSearchStyle: SerializedStyles = css({
  outline: 'none',
});

export const searchInputStyle: SerializedStyles = css({
  ':focus': {
    '::-moz-placeholder': {
      color: 'transparent',
    },
    '::-ms-clear': {
      display: 'none',
    },
    '::-ms-input-placeholder': {
      color: 'transparent',
    },
    '::-webkit-input-placeholder': {
      color: 'transparent',
    },
  },
  background: 'transparent',
  border: 'none',
  flexGrow: 1,
  fontSize: fontSizeM,
  marginLeft: SpacingInRemBySize.M,
  outline: 'none',
});

export const noResultsFoundStyle = css({
  alignItems: 'start',
  color: CoolGrey[500],
  display: 'flex',
  flexGrow: 1,
  fontSize: fontSizeXL,
  fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.bold,
  justifyContent: 'center',
  marginTop: rem(1.25),
  width: '100%',
});
