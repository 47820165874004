import * as R from 'ramda';
import { Dispatch } from 'redux';
import { ThunkMiddleware } from 'redux-thunk';

import { GetState } from '../../types';
import {
  OrganizationActionsTypes,
  SaveOrganizationBasicAction,
} from '../../types/organization';
import { ModalActionsTypes } from '../../types/modal';
import {
  uploadOrgLogo,
  updateOrganization,
  updateOrganizationEAPCoverage,
  createOrgEAPCoverage,
} from '../../../rest';
import { hydrate } from './hydrate';
import { getOrgCustomWorkIdFieldPlaceholderText } from '../../selectors/organizationSelectors';

const { SAVE_ORGANIZATION_BASIC } = OrganizationActionsTypes;
const { OPEN_MODAL } = ModalActionsTypes;

export const saveOrganizationBasic = (): ThunkMiddleware &
  SaveOrganizationBasicAction => {
  // @ts-ignore
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const {
      manageOrganization: {
        orgId,
        orgCountry: country,
        org_name: name,
        org_salesforce_id: salesforceId,
        org_type: type,
        sso_enabled: ssoEnabled,
        healthHub,
        healthHubExternalId,
        no_promo_emails: noPromoEmails,
        logoFile,
        logo,
        cachedOrg,
        gingerId,
        gingerBundlingEnabled,
        mfaEnabled,
        parentOrgId,
        orgMappingKey,
        orgMappingValue,
        useSingleEnrollmentLink,
        uploadElfAtChildEnabled,
        phoneNumber,
        externalOrgId,
        pii_enrollment_enabled,
        csvDelimiter,
        serviceType,
        coverageTotal,
        provider,
      },
    } = state;

    const workIdFieldPlaceholderText = getOrgCustomWorkIdFieldPlaceholderText(
      state,
    );
    const formData = new FormData();
    formData.append('logo', logoFile ?? '');
    const updateOrgOptions = {
      country,
      csvDelimiter,
      dependentsPiiEnrollmentEnabled: !!pii_enrollment_enabled,
      gingerBundlingEnabled,
      gingerId: gingerId || null,
      healthHub: healthHub || null,
      healthHubExternalId:
        !!healthHubExternalId && !!healthHub ? healthHubExternalId : null,
      mfaEnabled,
      name: `${name}`,
      noPromoEmails,
      orgMappingKey,
      orgMappingValue: orgMappingValue || null,
      parentOrgId,
      salesforceId: salesforceId ? `${salesforceId}` : null,
      ssoEnabled,
      type,
      uploadElfAtChildEnabled,
      useSingleEnrollmentLink,
    };

    const eapCoverageOptions = {
      coverageTotal,
      externalOrgId,
      phoneNumber,
      provider,
      serviceType,
    };

    await updateOrganization(orgId, updateOrgOptions);

    if (externalOrgId && !cachedOrg?.externalOrgId) {
      await createOrgEAPCoverage(orgId, eapCoverageOptions);
    } else if (externalOrgId) {
      await updateOrganizationEAPCoverage(orgId, eapCoverageOptions);
    }

    const logoResponse = logoFile
      ? await uploadOrgLogo({ formData, orgId })
      : {};

    try {
      let nextLogo = logo;
      if (logoFile && logoResponse) {
        nextLogo = R.pathOr('', ['data', 'data', 'logo'], logoResponse);
      }

      dispatch({
        payload: {
          cachedOrg: {
            ...cachedOrg,
            gingerBundlingEnabled,
            gingerId,
            healthHub,
            healthHubExternalId,
            logo: nextLogo,
            name,
            noPromoEmails,
            orgMappingKey,
            orgMappingValue,
            parentOrgId,
            salesforceId,
            ssoEnabled,
            type,
            uploadElfAtChildEnabled,
            useSingleEnrollmentLink,
            workIdFieldPlaceholderText,
          },
          logo: nextLogo,
          logoFile: null,
          logoFileName: '',
          logoFilePreview: '',
        },
        type: SAVE_ORGANIZATION_BASIC,
      });
    } catch (err) {
      dispatch({
        payload: {
          message: `${err}`,
        },
        type: OPEN_MODAL,
      });
      if (cachedOrg) dispatch(hydrate(cachedOrg));
    }
  };
};
