import type { FC } from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@headspace/web-ui-components';
import { usePrevious } from 'react-use';

import { useHsUploadChildElf } from '../../../../../../featureFlags/hsUploadChildElf';
import { useCsvDelimiter } from '../../../../../../featureFlags/csvDelimiter';
import { SettingsPanel } from '../shared-components/SettingsPanel/SettingsPanel';
import {
  ORGANIZATION_ENROLLMENT,
  VIEW_PANEL_COUNT,
  HEALTH_HUB,
  PRIMARY_PRODUCT,
} from '../../../../constants';
import { ChildRestrictedInput } from '../../../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import { Container } from '../../../../../../shared-components/container/Container';
import { VPInviteUrl } from './components/VPInviteURLInput';
import { SlugInput } from './components/SlugInput';
import { RestrictBySelect } from './components/RestrictBySelect';
import { LearnMoreURLInput } from './components/LearnMoreUrlInput';
import { OverFlowURLInput } from './components/OverFlowURLInput';
import { RestrictByEmailDomainInput } from './components/RestrictedByEmailDomainInput';
import { BodyMessageInput } from './components/BodyMessageInput';
import { WorkIdFieldPlaceholderTextInput } from './components/WorkIdFieldPlaceholderTextInput';
import {
  cancelOrganizationChanges,
  saveOrganizationEnrollment,
  toggleEditPanelView,
  updateImplicitState,
  handleChange as handleChangeAction,
  saveOrganizationBasic,
} from '../../../../../../state/actions/organizationActions';
import { isCSM as getIsCSM } from '../../../../../../state/selectors/userSelectors';
import {
  getOrgManagement,
  getRestrictByChanged,
  getSettingsChangedStatus,
} from '../../../../../../state/selectors/organizationSelectors';
import { GingerBundlingCheckbox } from '../shared-components/GingerBundlingCheckbox/GingerBundlingCheckbox';
import { Toast } from '../../../../../../shared-components/Toast';
import { RestrictByMigrationSteps } from './components/RestrictByMigrationSteps';
import {
  resetMigration,
  selectFinishedStep,
} from '../../../../../../state/slices/restrictByMigration';
import { SettingsConfirmationModal } from './components/SettingsConfirmationModal';
import { MfaEnabledInput } from './components/MfaEnabledInput';
import { CampaignDisplayNameInput } from './components/CampaignDisplayNameInput';
import { QRCode } from '../../../../../../shared-components/qrcode';
import { Input } from '../../../../../../shared-components/Input';
import { getEnrollmentUrl } from '../../../../utils/enrollmentUrl';
import {
  CAMPAIGN_DISPLAY_SUPPORTED_RESTRICTBY_TYPES,
  RestrictBy,
  delimiterOptions,
} from '../../../../../../types/organization';
import { panelTwoColumnStyle } from '../styles';
import { GenerateVerificationLinkButton } from './components/GenerateVerificationLinkButton';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { shouldShowCampaignDisplayFields } from '../../../../utils/manage-organization';
import { makeFriendlyDate } from '../../../../../../utils/dates';
import { getSelectTitle } from '../../../../../../utils';
import { clearCampaignFields } from '../../../../../../state/actions/organizationActions/clearCampaignFields';
import { DependentEnrollmentFlowDisplayNameInput } from './components/DependentEnrollmentFlowDisplayNameInput';

const SUPPORTED_RESTRICT_BY_MIGRATION_STEPS: string[] = [
  RestrictBy.EMAIL_ELIGIBILITY_FILE,
  RestrictBy.EMPLOYEE_ID,
  RestrictBy.EMAIL_DOMAIN,
];

export const OrganizationSettingsEnrollment: FC = () => {
  const {
    restrictBy,
    restrictedDomains = [],
    slug,
    org_name,
    slugError,
    overflow_urlError,
    workIdFieldPlaceholderTextError,
    healthHub,
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
    gingerBundlingEnabled,
    cachedOrg,
    campaignDisplayNameError,
    campaignLaunchDate,
    campaignLaunchDateError,
    campaignLaunchDateAfterError,
    campaignLaunchDatePastError,
    parentOrg,
    useSingleEnrollmentLink,
    childOrgs,
    current_contract_primary_product: currentPrimaryProduct,
    current_contract_eligibility_file_based_dependents: areElfBasedDependentsEnabled,
    uploadElfAtChildEnabled,
    pii_enrollment_enabled,
    csvDelimiter,
  } = useSelector(getOrgManagement);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [showBundlingToast, setShowBundlingToast] = useState(false);
  const isPanelInView = viewPanels[ORGANIZATION_ENROLLMENT];
  const isCSM = useSelector(getIsCSM);
  const isChangeEligibilityFlowFinished = useSelector(selectFinishedStep);
  const settingsChanged = useSelector(getSettingsChangedStatus);
  const isRestrictByChanged = useSelector(getRestrictByChanged);
  const previousRestrictBy = usePrevious(restrictBy);
  const [isHsUploadChildElfEnabled] = useHsUploadChildElf();
  const [csvDelimiterFeatureEnabled] = useCsvDelimiter();
  const isRestrictedByEmpId = [
    RestrictBy.EMPLOYEE_ID,
    RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
  ].includes(restrictBy as RestrictBy);
  const isRestrictedByEmail = restrictBy === RestrictBy.EMAIL_ELIGIBILITY_FILE;
  const isRestrictedByEmailDomain = restrictBy === RestrictBy.EMAIL_DOMAIN;
  const noRestrictedDomains = restrictedDomains.length === 0;
  const friendlyCampaignLaunchDate = makeFriendlyDate(campaignLaunchDate);
  const showCampaignDisplayFields = shouldShowCampaignDisplayFields(
    restrictBy,
    isCSM,
  );
  const hasCampaignLaunchDateError =
    campaignLaunchDateError.error ||
    campaignLaunchDateAfterError.error ||
    campaignLaunchDatePastError.error;
  const campaignLaunchDateErrorMessage =
    campaignLaunchDateError.message ||
    campaignLaunchDateAfterError.message ||
    campaignLaunchDatePastError.message;
  const hasErrors =
    slugError.error ||
    overflow_urlError.error ||
    campaignDisplayNameError.error ||
    hasCampaignLaunchDateError ||
    (isRestrictedByEmpId && workIdFieldPlaceholderTextError.error) ||
    (isRestrictedByEmailDomain && noRestrictedDomains) ||
    (isRestrictByChanged && !isChangeEligibilityFlowFinished);
  const isChildOrg = !!parentOrg;
  const isRootOrg = parentOrg == null && childOrgs && childOrgs.length > 0;
  const updatedSlug = parentOrg?.useSingleEnrollmentLink
    ? parentOrg?.enrollment?.slug
    : slug;

  useEffect(() => {
    if (isRestrictByChanged && previousRestrictBy !== restrictBy) {
      dispatch(resetMigration());
    }
    if (!CAMPAIGN_DISPLAY_SUPPORTED_RESTRICTBY_TYPES.includes(restrictBy)) {
      dispatch(clearCampaignFields());
    }
  }, [restrictBy, dispatch, isRestrictByChanged, previousRestrictBy]);

  const handleCancel = useCallback(() => {
    dispatch(resetMigration());
    dispatch(cancelOrganizationChanges());
  }, [dispatch]);
  const handleSaveChangesChange = useCallback(() => {
    dispatch(saveOrganizationBasic());
    dispatch(saveOrganizationEnrollment());
    if (cachedOrg?.gingerBundlingEnabled === false && gingerBundlingEnabled) {
      setShowBundlingToast(true);
    }
  }, [dispatch, gingerBundlingEnabled, cachedOrg]);
  const handleToggleView = useCallback(
    (index) => {
      dispatch(toggleEditPanelView(index));
      setShowBundlingToast(false);
    },
    [dispatch],
  );
  const handleError = useCallback(
    (event) => {
      dispatch(updateImplicitState(event));
    },
    [dispatch],
  );
  const handleChange = useCallback(
    (event) => {
      dispatch(handleChangeAction(event));
    },
    [dispatch],
  );

  const bundlingToast = showBundlingToast ? (
    <Toast
      text={translate(
        'organizationSettings.basic.connectedEnrollmentEnabledToast',
      )}
    />
  ) : null;

  const showAllEnrollmentComponents =
    useSingleEnrollmentLink ||
    (!isRootOrg && !isChildOrg) ||
    (!useSingleEnrollmentLink && isChildOrg);

  const showForParentOrgOnly = isCSM && isRootOrg && !isChildOrg;

  const showPiiEnrollmentCheckbox =
    areElfBasedDependentsEnabled &&
    restrictBy === RestrictBy.EMPLOYEE_ID &&
    currentPrimaryProduct === PRIMARY_PRODUCT.FUSION;

  return (
    <>
      <Container>
        <SettingsPanel
          title={translate('organizationSettings.enrollment.settingsTitle')}
          view={isPanelInView}
          toggleView={handleToggleView}
          cancelChanges={handleCancel}
          saveChanges={handleSaveChangesChange}
          index={ORGANIZATION_ENROLLMENT}
          displayEditButton={isCSM && viewPanels.indexOf(false) < 0}
          settingsChanged={settingsChanged}
          hasErrors={hasErrors}
          dataTestId="enrollment-panel"
        >
          {showForParentOrgOnly ? (
            <Input
              type="checkbox"
              name="useSingleEnrollmentLink"
              onChange={handleChange}
              value={useSingleEnrollmentLink}
              disabled={isPanelInView}
              label={translate(
                'organizationSettings.enrollment.parentUrlCheckbox',
              )}
              dataTestId="use-parent-url-checkbox"
            />
          ) : null}

          {healthHub === HEALTH_HUB.VIRGIN_PULSE && (
            <VPInviteUrl
              healthHub={healthHub}
              primaryProduct={currentPrimaryProduct}
            />
          )}

          <SlugInput view={isPanelInView} />
          {isCSM && showAllEnrollmentComponents ? (
            <QRCode
              data={getEnrollmentUrl(updatedSlug)}
              id="member-enroll"
              filename={org_name}
              isDownloadable={true}
            />
          ) : null}

          {showAllEnrollmentComponents ? (
            <>
              <OverFlowURLInput view={isPanelInView} />
              <LearnMoreURLInput view={isPanelInView} />
              <BodyMessageInput view={isPanelInView} />
            </>
          ) : null}

          {showCampaignDisplayFields && (
            <div css={panelTwoColumnStyle}>
              <CampaignDisplayNameInput
                displayDescription={!isPanelInView}
                editMode={!isPanelInView}
                onChange={handleChange}
                onError={handleError}
              />
              <Input
                type={isPanelInView ? 'review' : 'date'}
                name="campaignLaunchDate"
                placeholder={translate(
                  `organizationSettings.enrollment.campaignLaunchDatePlaceholder`,
                )}
                label={translate(
                  `organizationSettings.enrollment.campaignLaunchDateLabel`,
                )}
                value={friendlyCampaignLaunchDate}
                onChange={handleChange}
                onBlur={handleError}
                hasError={hasCampaignLaunchDateError}
                errorMessage={campaignLaunchDateErrorMessage}
                css={{ cursor: 'pointer' }}
              />
            </div>
          )}

          {areElfBasedDependentsEnabled && (
            <DependentEnrollmentFlowDisplayNameInput
              editMode={!isPanelInView}
              onChange={handleChange}
            />
          )}

          {isCSM && (
            <>
              <Divider />
              <GingerBundlingCheckbox
                view={isPanelInView}
                handleError={handleError}
                handleChange={handleChange}
              />
            </>
          )}
          {gingerBundlingEnabled && (
            <>
              <SlugInput
                view={isPanelInView}
                urlSuffix="bundled"
                dataTestId="bundled-slug-input"
              />
              {isCSM ? (
                <QRCode
                  data={getEnrollmentUrl(updatedSlug, 'bundled')}
                  id="bundled"
                  filename={`${org_name}_bundled`}
                  isDownloadable={true}
                />
              ) : null}
              {bundlingToast}
            </>
          )}

          <Divider />
          <div css={panelTwoColumnStyle}>
            <RestrictBySelect view={isPanelInView} isCSM={isCSM} />
            <div>
              {isRestrictedByEmpId && (
                <WorkIdFieldPlaceholderTextInput view={isPanelInView} />
              )}
              {isRestrictedByEmailDomain && !isRestrictByChanged && (
                <RestrictByEmailDomainInput view={isPanelInView} />
              )}
            </div>
          </div>
          {csvDelimiterFeatureEnabled &&
          (isRestrictedByEmail || isRestrictedByEmpId) ? (
            <div css={panelTwoColumnStyle}>
              <ChildRestrictedInput
                dataTestId="csvDelimiterSelect"
                name="csvDelimiter"
                type={isPanelInView ? 'review' : 'select'}
                label="delimiter"
                options={delimiterOptions}
                value={
                  isPanelInView
                    ? getSelectTitle(delimiterOptions, csvDelimiter)
                    : csvDelimiter
                }
                onChange={handleChange}
              />
              <div />
            </div>
          ) : null}
          {isCSM && isRestrictedByEmailDomain ? (
            <GenerateVerificationLinkButton />
          ) : null}
          {isRestrictByChanged &&
            SUPPORTED_RESTRICT_BY_MIGRATION_STEPS.includes(restrictBy) && (
              <div>
                <RestrictByMigrationSteps />
              </div>
            )}

          {isHsUploadChildElfEnabled && showForParentOrgOnly ? (
            <Input
              type="checkbox"
              name="uploadElfAtChildEnabled"
              onChange={handleChange}
              value={uploadElfAtChildEnabled}
              disabled={isPanelInView}
              label={translate(
                'organizationSettings.enrollment.uploadElfAtChildCheckbox',
              )}
              dataTestId="child-org-upload-checkbox"
            />
          ) : null}
          {showPiiEnrollmentCheckbox ? (
            <Input
              type="checkbox"
              name="pii_enrollment_enabled"
              onChange={handleChange}
              value={pii_enrollment_enabled}
              disabled={isPanelInView}
              label={translate(
                'organizationSettings.enrollment.dependentsPiiEnrollmentEnabledCheckbox',
              )}
              data-testid="enrollment-settings-dependents-pii-enrollment-enabled"
            />
          ) : null}
          <Divider />
          <MfaEnabledInput
            displayHeader={true}
            displayDescription={true}
            editMode={isCSM && !isPanelInView}
            onChange={handleChange}
          />
        </SettingsPanel>
      </Container>
      <SettingsConfirmationModal />
    </>
  );
};
