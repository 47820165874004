import { FC, memo } from 'react';
import { Blue, White } from '@headspace/web-ui-components';
import { FormContainer } from '../../shared-components/FormContainer/FormContainer';
import { Divider } from '../../shared-components/Divider/Divider';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import { Icon } from '../../../../../shared-components/Icon';
import Check from '../../../../../assets/green-circle-check.svg';
import { getLogoEnv } from '../../../../../utils/getLogo';
import { useTranslate } from '../../../../../hooks/useTranslate';

import {
  containerStyle,
  emailTextStyle,
  emailSubLabelStyle,
  buttonContainerStyle,
  orgLogoStyle,
  logoContainer,
} from '../styles';
import { Organization } from '../../../../../types/organization';

interface SuccessBoxProps {
  email: string;
  activeOrg: Organization;
  handleClick: (event: MouseEvent) => void;
}

const SuccessBox: FC<SuccessBoxProps> = ({ email, activeOrg, handleClick }) => {
  const { name, logo } = activeOrg;
  const { translate } = useTranslate();

  return (
    <FormContainer
      headerTag="h4"
      title={translate('verifiedAccount.formTitle')}
      termsMessage={true}
    >
      <div css={containerStyle}>
        <div>
          <p>{translate('verifiedAccount.onTheTeam', { name })}</p>
          <div css={emailTextStyle}>{email}</div>
          <div css={emailSubLabelStyle}>
            {translate('verifiedAccount.headspaceAccount')}
          </div>
        </div>

        <Divider />
        <div>
          <div css={logoContainer}>
            <img
              css={orgLogoStyle}
              src={getLogoEnv(logo)}
              data-testid="org-logo"
              alt="org-logo"
            />
            <Icon src={Check} width={16} height={16} fill="#49CE7F" />
          </div>

          <div css={emailSubLabelStyle}>
            {translate('verifiedAccount.supportedBy')}
          </div>
          <div css={buttonContainerStyle}>
            <TextButton
              textColor={White['000']}
              hoverColor={Blue[200]}
              backgroundColor={Blue[300]}
              dataTestId="verified-next-button"
              value={translate('next')}
              pill={true}
              width="100%"
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default memo(SuccessBox);
