import * as React from 'react';
import { Red } from '@headspace/web-ui-components';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import {
  panelEditActionsStyle,
  panelEditContainerStyle,
  panelEditCancelStyle,
} from './styles';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

export interface EditingViewProps {
  view: boolean;
  hideControls?: boolean;
  withDeleteButton?: boolean;
  handleDelete?: () => void;
  setSaveChangesSelected: (v: boolean) => void;
  hasErrors?: boolean;
}

export const TranslatedEditingView: React.FC<EditingViewProps> = ({
  view,
  hideControls,
  hasErrors,
  withDeleteButton,
  handleDelete,
  setSaveChangesSelected,
}) => {
  const { translate } = useTranslate();
  const handleSaveChanges = React.useCallback(() => {
    if (!hasErrors) {
      setSaveChangesSelected(true);
    }
  }, [setSaveChangesSelected, hasErrors]);

  const handleCancelChanges = React.useCallback(() => {
    setSaveChangesSelected(false);
  }, [setSaveChangesSelected]);

  return view || hideControls ? null : (
    <div css={panelEditContainerStyle}>
      <div css={panelEditActionsStyle}>
        {withDeleteButton ? (
          <TextButton
            lg={true}
            className="delete-challenge-button"
            dataTestId="deleteChallenge"
            value={translate('delete')}
            onClick={handleDelete}
            backgroundColor="grey"
            hoverColor={Red[200]}
          />
        ) : null}
      </div>
      <div css={panelEditActionsStyle}>
        <TextButton
          lg={true}
          className="cancelChanges"
          dataTestId="cancelChanges"
          value={translate('cancel')}
          css={panelEditCancelStyle}
          onClick={handleCancelChanges}
        />
        <TextButton
          lg={true}
          className="saveChanges"
          dataTestId="saveChanges"
          onClick={handleSaveChanges}
          value={translate('saveChanges')}
          disabled={hasErrors}
        />
      </div>
    </div>
  );
};
