import { css } from '@emotion/react';
import { SpacingInRemBySize } from '@headspace/web-ui-components';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleChange as handleChangeAction } from '../../../../state/actions/organizationActions';
import { getOrgManagement } from '../../../../state/selectors/organizationSelectors';
import { OrgMappingKeyInput } from '../../OrganizationView/SettingsView/components/OrgHierarchyPanel/components/OrgMappingKeyInput';
import { OrgMappingValueInput } from '../../OrganizationView/SettingsView/components/OrgHierarchyPanel/components/OrgMappingValueInput';
import { ParentOrgInput } from '../../OrganizationView/SettingsView/components/shared-components/ParentOrgInput/ParentOrgInput';
import { rowStyle } from '../styles/createOrganizationStyles';

const rowMargin = css({ marginTop: SpacingInRemBySize.L });

export const OrgHierarchyPage: React.FC = () => {
  const { parentOrgId, orgMappingKey } = useSelector(getOrgManagement);
  const dispatch = useDispatch();
  const handleChange = React.useCallback(
    (event: ExtendedEvent) => dispatch(handleChangeAction(event)),
    [dispatch],
  );
  const shouldShowOrgMappingValueInput: boolean =
    Boolean(parentOrgId) && Boolean(orgMappingKey);

  return (
    <div data-testid="org-hierarchy-page">
      <div css={[rowStyle, rowMargin]}>
        <ParentOrgInput editMode={true} />
      </div>
      <div css={[rowStyle, rowMargin]}>
        <OrgMappingKeyInput
          editMode={!parentOrgId}
          onChange={handleChange}
          optional={true}
        />
        {shouldShowOrgMappingValueInput ? (
          <OrgMappingValueInput editMode={true} onChange={handleChange} />
        ) : null}
      </div>
    </div>
  );
};
