import * as React from 'react';
import {
  OrganizationSetupAdministratorsReviewProps,
  OrganizationAdministratorProps,
} from '../types';
import {
  adminRecordDisplayFrameStyle,
  adminRecordDisplayCardStyle,
  adminRecordDisplayCardIconStyle,
  cardNumberStyle,
  addAnotherTextStyle,
  labelBoldM,
} from './styles';
import edit from '../../../assets/pencil.svg';
import close from '../../../assets/closeDoveGrey.svg';
import { useTranslate } from '../../../hooks/useTranslate';
import {
  alignItemsCenter,
  columnFlex,
  flex,
  justifyContentFlexEnd,
  justifyContentSpaceBetween,
} from '../styles/flex';

export const OrganizationAdministratorsReview: React.FC<OrganizationSetupAdministratorsReviewProps> = ({
  admins,
  handleAdminDelete,
  handleAdminEdit,
}) => {
  const { translate } = useTranslate();

  return (
    <div css={[columnFlex, alignItemsCenter]}>
      <div css={adminRecordDisplayFrameStyle}>
        {admins?.map((admin: OrganizationAdministratorProps, i: number) => {
          const { name, email } = admin;
          const onKeyPressHandleAdminEdit = (e) => {
            if (e.key === 'Enter') {
              handleAdminEdit(e);
            }
          };
          const onKeyPressHandleAdminDelete = (e) => {
            if (e.key === 'Enter') {
              handleAdminDelete(e);
            }
          };
          return (
            <div
              css={[
                flex,
                adminRecordDisplayCardStyle,
                justifyContentSpaceBetween,
              ]}
              key={email}
            >
              <div css={[flex, alignItemsCenter]}>
                <div css={[labelBoldM, cardNumberStyle]}>{i + 1}</div>
                <div css={columnFlex}>
                  <div>{name}</div>
                  <div className="subtext">{email}</div>
                </div>
              </div>
              <div css={[flex, justifyContentFlexEnd, alignItemsCenter]}>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <div
                  role="button"
                  id={email}
                  onClick={handleAdminEdit}
                  onKeyPress={onKeyPressHandleAdminEdit}
                  tabIndex={0}
                  css={adminRecordDisplayCardIconStyle(edit)}
                />
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <div
                  role="button"
                  data-testid="organization-administrator-delete-admin"
                  id={email}
                  onClick={handleAdminDelete}
                  onKeyPress={onKeyPressHandleAdminDelete}
                  tabIndex={0}
                  css={adminRecordDisplayCardIconStyle(close)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <h3 css={addAnotherTextStyle}>
        {translate('organizationSettings.administrators.addAnother')}
      </h3>
    </div>
  );
};
