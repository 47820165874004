import * as React from 'react';
import { inputLabelStyle } from '../../apps/dashboard/CreateOrganizationView/DetailsPage/Details';
import { ExtendedInputType, Input } from '../Input';
import { useTranslate } from '../../hooks/useTranslate';

type AddOnInputProps = {
  value: boolean | string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disable?: boolean;
  type?: ExtendedInputType;
  name: string;
};
export const AddOnInput = ({
  value,
  onChange,
  disable,
  type = 'checkbox',
  name,
}: AddOnInputProps) => {
  const { translate } = useTranslate();

  const addOnsValue = translate('organizationSettings.enrollment.addOnsValue');
  const addOnsWithOptional = translate(
    'organizationSettings.enrollment.addOnsWithOptional',
  );

  return (
    <div>
      <span css={inputLabelStyle}>{addOnsWithOptional}</span>
      <Input
        type={type}
        name={name}
        label={addOnsValue}
        value={value}
        onChange={onChange}
        tabIndex="0"
        noLabel={true}
        data-testid="add_onsV2"
        disabled={disable}
      />
    </div>
  );
};
