import { FC } from 'react';
import {
  Choice,
  Family,
  QuestionId,
} from '../../../../models/OnboardingQuestions';
import { OnboardingSelectionControl } from './components/OnboardingSelectionControl';

interface OnboardingQuestionCardProps {
  choices: Choice[];
  questionId: QuestionId;
  title: string;
  onSelectAnswer: (questionId: string, title: string, id: string) => void;
  family: Family;
}

export const QuestionCard: FC<OnboardingQuestionCardProps> = ({
  title,
  questionId,
  choices,
  onSelectAnswer,
  family,
}) => {
  return (
    <OnboardingSelectionControl
      choices={choices}
      questionId={questionId}
      onSelectAnswer={onSelectAnswer}
      title={title}
      family={family}
    />
  );
};
