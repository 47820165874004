export enum SupportedCountriesForCoaching {
  FR = 'FR',
  FM = 'FM',
  PA = 'PA',
  HK = 'HK',
  CL = 'CL',
  GR = 'GR',
  SK = 'SK',
  SA = 'SA',
  BB = 'BB',
  KN = 'KN',
  SX = 'SX',
  PT = 'PT',
  CO = 'CO',
  CZ = 'CZ',
  ZA = 'ZA',
  CR = 'CR',
  CH = 'CH',
  NO = 'NO',
  LU = 'LU',
  DO = 'DO',
  PL = 'PL',
  RS = 'RS',
  FI = 'FI',
  MY = 'MY',
  PH = 'PH',
  VN = 'VN',
  SE = 'SE',
  IL = 'IL',
  DK = 'DK',
  PR = 'PR',
  GU = 'GU',
  MP = 'MP',
  AS = 'AS',
  TH = 'TH',
  KR = 'KR',
  NZ = 'NZ',
  TR = 'TR',
  AR = 'AR',
  HU = 'HU',
  BR = 'BR',
  MX = 'MX',
  IN = 'IN',
  JP = 'JP',
  SG = 'SG',
  TW = 'TW',
  AE = 'AE',
  IE = 'IE',
  AU = 'AU',
  BE = 'BE',
  CA = 'CA',
  DE = 'DE',
  NL = 'NL',
  UK = 'UK',
  ES = 'ES',
  IT = 'IT',
  US = 'US',
  GB = 'GB',
  SV = 'SV',
  PE = 'PE',
  UY = 'UY',
}
