import { css } from '@emotion/react';
import {
  fontSizeS,
  Grey,
  lineHeightHeading,
  rem,
} from '@headspace/web-ui-components';
import { boxSizingBorderBox } from '../../../../styles';
import { columnFlex, flex } from '../../../../styles/flex';

export const organizationColumnElementStyle = css(boxSizingBorderBox, flex, {
  flexBasis: '50%',
});

export const orgContractEditLeftColumnElementStyles = css([
  organizationColumnElementStyle,
  { paddingRight: rem(1.25) },
]);

export const orgContractEditRightColumnElementStyles = css([
  organizationColumnElementStyle,
  { paddingLeft: rem(1.25) },
]);

export const reviewInputSubtextStyles = css({
  color: Grey['200'],
  fontSize: fontSizeS,
  lineHeight: lineHeightHeading,
});

export const contractContainerStyle = css(columnFlex, {
  flex: 1,
});

export const rowStyle = css(flex, {
  width: '100%',
  justifyContent: 'space-between',

  '> div': {
    flexBasis: '48%',
  },
});
