import { PaymentConfig, PaymentType } from '@headspace/web-payments';
import env from '../../../config/env';
import { completePaymentSaga } from '../state/sagas/payment/completePaymentSaga';
import { paymentVerificationErrorSaga } from '../state/sagas/payment/paymentVerificationSaga';
import {
  getAccessToken,
  getRawAccessToken,
} from '../state/selectors/memberAuthenticationSelector/memberAuthSelectorFactory';
import { i18n } from '../../../i18n';
import { getCountryCode } from '../state/selectors/countryCodeSelector/countryCodeSelector';
import {
  getCurrency,
  getPaymentSection,
} from '../state/selectors/paymentSectionSelelector/paymentSectionSelector';
import { getLanguage } from '../state/selectors/languageSelector/languageSelector';

const ENV = env.NODE_ENV || process.env.NODE_ENV;

const paymentConfig: PaymentConfig = {
  enabledPaymentTypes: [PaymentType.ZUORA],
  paymentEnvironment: ENV,

  getAuthTokenSelector: getRawAccessToken,
  getCountryCodeSelector: (state) => getCountryCode(state),
  getCheckoutCurrencySelector: (state) => getCurrency(state),

  getLanguageSelector: (state) => getLanguage(state),

  getPaymentStateSelector: getPaymentSection,
  getUserSelector: getAccessToken,
  i18nInstance: i18n,

  onPaymentVerificationError: paymentVerificationErrorSaga,
  onPaymentVerificationSuccess: completePaymentSaga,

  zuoraScriptSrc: env.ZUORA_SCRIPT_SRC,
};

export default paymentConfig;
