import { setSFTPProgress } from '../../../apps/dashboard/utils/sftp/sftpProgress';
import { OrganizationActionsTypes } from '../../types/organization';
import { ThunkDispatch } from '../../types';

export const setActiveSFTPStep = (step: number) => {
  return (dispatch: ThunkDispatch, getState: GetState) => {
    const {
      manageOrganization: { orgId },
    } = getState();
    setSFTPProgress(orgId, step);
    dispatch({
      type: OrganizationActionsTypes.SET_ACTIVE_SFTP_STEP,
      payload: {
        activeSFTPStep: step,
      },
    });
  };
};
