import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import umdOnboardingRadiobutton from 'assets/umd-onboarding-radiobutton.png';
import { Choice, QuestionId } from '../../../../../models/OnboardingQuestions';
import {
  checkMarkIconStyle,
  choiceContainerStyle,
  choiceIconContainerStyle,
  choiceIconStyle,
  choicesContainerStyle,
  choiceTextStyle,
  labelContainerStyle,
} from '../styles/choiceCardStyle';
import { CHOICE_ICONS } from './constants';
import { RootState } from '../../../../../../../state/types';
import {
  getMultipleChoiceQuestionAnswers,
  getSingleChoiceQuestionAnswer,
} from '../../../../../state/slice/onboarding/onboardingSelector';
import { getLocalizedAnswerText } from '../utils/localization';
import { clearQuestionAnswer } from '../../../../../state/slice/onboarding/onboardingReducer';
import { getLanguage } from '../../../../../state/selectors/languageSelector/languageSelector';

interface OnboardingImportanceSingleChoiceCardProps {
  choices: Choice[];
  questionId: string;
  title: string;
  onSelectAnswer: (questionId: string, title: string, id: string) => void;
}

interface OnboardingSingleChoiceCheckboxProps {
  text: string;
  id: string;
  handleSelect: (id) => void;
  isSelected: boolean;
  choicesIcons: Record<string, string>;
}

const getAvailableChoices = (
  choices: Choice[],
  helpQuestionAnswers: Choice[],
) => {
  const helpQuestionTextAnswers = helpQuestionAnswers.map(({ text }) => text);

  return choices.filter(({ text }) => helpQuestionTextAnswers.includes(text));
};

const shouldClearPreviousAnswer = (
  savedImportanceQuestionAnswer: Choice,
  availableChoices: Choice[],
) =>
  !availableChoices.some(({ id }) => id === savedImportanceQuestionAnswer.id);

const OnboardingSingleChoiceRadioButton = memo(
  ({
    text,
    id,
    handleSelect,
    choicesIcons = CHOICE_ICONS,
    isSelected,
  }: OnboardingSingleChoiceCheckboxProps) => {
    const handleClick = () => handleSelect(id);

    return (
      <button
        css={choiceContainerStyle(isSelected)}
        onClick={handleClick}
        type="button"
        role="radio"
        aria-checked={isSelected}
        data-testid="onboarding-single-choice-card"
      >
        <img
          src={umdOnboardingRadiobutton}
          css={checkMarkIconStyle(isSelected)}
          alt={text}
        />
        <label htmlFor={text} css={labelContainerStyle}>
          <div css={choiceIconContainerStyle}>
            <img src={choicesIcons[id]} css={choiceIconStyle} alt={text} />
          </div>
          <p css={choiceTextStyle}>{text}</p>
        </label>
      </button>
    );
  },
);

export const OnboardingImportanceSingleChoiceCard: FC<OnboardingImportanceSingleChoiceCardProps> = ({
  choices,
  title,
  questionId,
  onSelectAnswer,
}) => {
  const dispatch = useDispatch();
  const language = useSelector(getLanguage);

  const helpQuestionAnswers = useSelector((state: RootState) =>
    getMultipleChoiceQuestionAnswers(state, QuestionId.QUESTION_1_HELP),
  );
  const importanceQuestionAnswer = useSelector(
    (state: RootState) =>
      getSingleChoiceQuestionAnswer(state, QuestionId.QUESTION_2_IMPORTANCE) ||
      {},
  ) as Choice;

  const { text: importanceQuestionAnswerText = '' } = importanceQuestionAnswer;

  const availableChoices = useMemo(
    () => getAvailableChoices(choices, helpQuestionAnswers),
    [choices, helpQuestionAnswers],
  );

  const handleSelect = useCallback(
    (id) => {
      onSelectAnswer(questionId, title, id);
    },
    [onSelectAnswer, questionId, title],
  );

  useEffect(() => {
    if (shouldClearPreviousAnswer(importanceQuestionAnswer, availableChoices)) {
      dispatch(clearQuestionAnswer(questionId));
    }
  }, []);

  return (
    <div
      css={choicesContainerStyle}
      data-testid="onboarding-single-choice-card-container"
    >
      {availableChoices.map((availableChoice) => {
        const { text, id } = availableChoice;

        return (
          <OnboardingSingleChoiceRadioButton
            text={getLocalizedAnswerText(availableChoice, language)}
            key={id}
            id={id}
            isSelected={importanceQuestionAnswerText.includes(text)}
            handleSelect={handleSelect}
            choicesIcons={CHOICE_ICONS}
          />
        );
      })}
    </div>
  );
};
