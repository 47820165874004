import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCookie } from '@headspace/web-auth/dist/utils/cookie';
import * as Sentry from '@sentry/browser';
import { initializeVerifyPage } from '../../state/actions/memberEnrollActions/memberEnrollActions';
import { verificationScreenViewAction } from '../../../../analytics/events/enrollmentEvents';
import { postVerifyByEmail, postVerifyById } from '../../rest-calls';
import { RestrictBy } from '../../../../types/organization';
import {
  getCompanyName,
  getOrgRestrictedBy,
  getSlug,
} from '../../state/selectors/orgSectionSelector/orgSectionSelector';
import {
  getEmail,
  getJWT,
} from '../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import VerifyByEmail from '../shared-components/VerifyBy/VerifyByEmail';
import VerifyById from '../shared-components/VerifyBy/VerifyById';

const Verify: FC = () => {
  const companyName = useSelector(getCompanyName);
  const JWT = useSelector(getJWT);
  const userEmail = useSelector(getEmail);
  const restrictedBy = useSelector(getOrgRestrictedBy);
  const slug = useSelector(getSlug);
  const dispatch = useDispatch();

  const handleEmailVerification = async (email) => {
    const language = getCookie('lang');
    Sentry.addBreadcrumb({
      category: 'ui',
      message: 'handleVerify callback member-enrollment/ postVerifyByEmail',
      level: Sentry.Severity.Info,
    });

    await postVerifyByEmail(
      JWT,
      {
        language,
        registeredEmail: userEmail,
        workEmail: email,
      },
      slug,
    );

    if (userEmail !== email) {
      Sentry.addBreadcrumb({
        category: 'send email verification',
        message: `email:${userEmail} sent email verification to ${email}`,
        level: Sentry.Severity.Info,
      });
    }
  };

  const handleWorkIdVerification = async (employeeId, lastName) => {
    await postVerifyById(JWT, { id: employeeId, name: lastName }, slug);
  };

  useEffect(() => {
    dispatch(initializeVerifyPage());
  }, [initializeVerifyPage]);

  useEffect(() => {
    dispatch(verificationScreenViewAction(restrictedBy));
  }, [verificationScreenViewAction, restrictedBy]);

  return (
    <div>
      {[
        RestrictBy.EMPLOYEE_ID,
        RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
      ].includes(restrictedBy) ? (
        <VerifyById
          companyName={companyName}
          handleVerify={handleWorkIdVerification}
        />
      ) : (
        <VerifyByEmail
          companyName={companyName}
          handleVerify={handleEmailVerification}
        />
      )}
    </div>
  );
};

export default Verify;
