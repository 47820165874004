import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';
import {
  OrganizationActionsTypes,
  DrainAction,
} from '../../types/organization';

const { DRAIN } = OrganizationActionsTypes;

export const drain = (): DrainAction & ThunkMiddleware => {
  // @ts-ignore
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DRAIN,
      payload: {},
    });
  };
};
