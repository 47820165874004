import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { flowStartScreenViewAction } from '../../../../analytics/events/enrollmentEvents';
import { HealthProvider } from '../../constants/healthProvider';
import { Routes } from '../../constants/routes';
import { getHsUserId } from '../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { VPCareWrapper } from '../HealthHubViews/VirginPulse/VPCareWrapper/VPCareWrapper';
import { MemberLogin } from '../MemberLoginPage';
import { VPCareEnrollmentComponent } from './Components/VPCareEnrollmentComponent';
import { VPCareVerificationStep } from './Components/VPCareVerificationStep';

enum VPCareFlow {
  ENROLLMENT = 0,
  VERIFICATION = 1,
  LOGIN = 2,
}

export const VPCareLandingPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HS_USER_ID = useSelector(getHsUserId);
  const [currentStep, setCurrentStep] = useState(VPCareFlow.ENROLLMENT);
  const showNextStep = useCallback(() => setCurrentStep(currentStep + 1), [
    currentStep,
  ]);

  useEffect(() => {
    if (HS_USER_ID && currentStep === 0) {
      navigate(Routes.VP_CARE_CONSENT);
    }
  }, [HS_USER_ID]);

  useEffect(() => {
    dispatch(flowStartScreenViewAction(HealthProvider.VPCARE));
  }, []);

  return (
    <VPCareWrapper skipLogout={currentStep !== VPCareFlow.VERIFICATION}>
      {currentStep === VPCareFlow.ENROLLMENT && (
        <VPCareEnrollmentComponent onClick={showNextStep} />
      )}
      {currentStep === VPCareFlow.VERIFICATION && (
        <VPCareVerificationStep onComplete={showNextStep} />
      )}
      {currentStep === VPCareFlow.LOGIN && <MemberLogin />}
    </VPCareWrapper>
  );
};
