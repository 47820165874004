import { css } from '@emotion/react';
import {
  fontSizeXS,
  letterSpacingWider,
  Orange,
  WarmGrey,
} from '@headspace/web-ui-components';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { bold } from '../apps/dashboard/styles/fonts';

export interface SmallUppercaseLinkProps {
  active?: boolean;
  name: string;
  url: string;
}

const linkStyle = (active = false) =>
  css(bold, {
    ':hover': {
      color: Orange[100],
    },
    color: active ? Orange[300] : WarmGrey[300],
    fontSize: fontSizeXS,
    letterSpacing: letterSpacingWider,
    textDecoration: 'none',
    textTransform: 'uppercase',
  });

export const SmallUppercaseLink: React.FC<SmallUppercaseLinkProps> = ({
  active,
  name,
  url,
}) => {
  return (
    <Link
      to={url}
      css={linkStyle(active)}
      data-testid={`${name}-uppercase-link`}
    >
      {name}
    </Link>
  );
};
