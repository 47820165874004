import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';

import { GetState } from '../../types';
import { SaveAdminAction } from '../../types/organization';
import { OrganizationAdministratorProps } from '../../../apps/dashboard/types';
import { clearAdmin } from './clearAdmin';

export const saveAdmin = (): ThunkMiddleware & SaveAdminAction => {
  // @ts-ignore
  return (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: {
        org_admins,
        admin_email,
        admin_name,
        admin_title,
        adminEditExisting,
      },
    } = getState();
    const nextAdmins = org_admins.slice();
    let index;
    if (adminEditExisting) {
      index = org_admins.findIndex((admin: OrganizationAdministratorProps) => {
        return admin.email === admin_email;
      });
    }
    if (index === void 0 || index < 0) {
      index = org_admins.length;
    }
    nextAdmins[index] = {
      id: (org_admins[index] && org_admins[index].id) || '',
      name: admin_name,
      title: admin_title,
      email: admin_email,
      isParentOrgAdmin: org_admins[index]?.isParentOrgAdmin,
    };
    const payload = {
      org_admins: nextAdmins,
      adminReviewView: true,
    };
    dispatch(clearAdmin(payload));
  };
};
