import * as React from 'react';
import { useSelector } from 'react-redux';

import { Input } from '../../../../../../../shared-components/Input';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { isCSM as getIsCSM } from '../../../../../../../state/selectors/userSelectors';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { isContractFusionOrUnified } from '../../../../../utils/manage-organization';

interface GingerBundlingCheckboxProps {
  view: boolean;
  handleError: (event: any) => void;
  handleChange: (event: any) => void;
}

export const GingerBundlingCheckbox: React.FC<GingerBundlingCheckboxProps> = ({
  view,
  handleChange,
  handleError,
}) => {
  const { translate } = useTranslate();
  const {
    gingerBundlingEnabled,
    current_contract_primary_product,
  } = useSelector(getOrgManagement);
  const isCSM = useSelector(getIsCSM);
  const isUnifiedOrg = isContractFusionOrUnified(
    current_contract_primary_product,
  );

  return (
    <Input
      label={translate(`organizationSettings.enrollment.enableConnected`)}
      type="checkbox"
      onChange={handleChange}
      onBlur={handleError}
      name="gingerBundlingEnabled"
      value={gingerBundlingEnabled}
      disabled={view || !isCSM || isUnifiedOrg}
      dataTestId="ginger-bundling-checkbox"
    />
  );
};
