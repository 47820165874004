import { FC, useCallback, MouseEventHandler } from 'react';
import { WEBSITE_HOST } from '../../../../../utils/constants';
import { YesOrNoCard } from '../../shared-components/YesOrNoCard';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface SSOLoginViewProps {
  handleCloseQuestionCards: MouseEventHandler;
}

export const ConnectedYourSSOCard: FC<SSOLoginViewProps> = ({
  handleCloseQuestionCards,
}) => {
  const { translate } = useTranslate();
  const titleTextValue = translate('ssoEnrollment.haveYouConnectedToSSO.title');
  const yesButtonTextValue = translate(
    'ssoEnrollment.haveYouConnectedToSSO.yes',
  );
  const noButtonTextValue = translate('ssoEnrollment.haveYouConnectedToSSO.no');

  const handleOpenInstructions = useCallback(() => {
    // open instructions
    window.open('https://help.headspace.com/hc/en-us/articles/1260805591470');

    // redirect to hs-react-website login
    window.location.replace(
      `${WEBSITE_HOST}/login?redirectOnSuccess=/manage-identities`,
    );
  }, []);

  return (
    <YesOrNoCard
      titleTextValue={titleTextValue}
      yesButtonTextValue={yesButtonTextValue}
      handleYesButtonClick={handleCloseQuestionCards}
      noButtonTextValue={noButtonTextValue}
      handleNoButtonClick={handleOpenInstructions}
    />
  );
};
