import { FC, useCallback, useRef, isValidElement } from 'react';
import { Spinner } from '@headspace/web-ui-components';
import {
  fileStyle,
  fileNotesStyle,
  fileFileStyle,
  fileUploadButtonStyle,
  fileUploadButtonTextStyle,
  fileUploadTextStyle,
  labelStyle,
  filePreviewStyle,
  baseMessageStyle,
  errorMessageStyle,
  successMessageStyle,
  inputWrapperStyle,
  errorWrapperStyle,
  successWrapperStyle,
} from './styles';

export interface FileUploaderProps {
  fileName: string;
  label?: string;
  view?: boolean;
  loading?: boolean;
  fileError?: ErrorMessage;
  fileStatus?: ProcessingMessage;
  filePreview?: string;
  requirements?: string;
  instructions?: JSX.Element | string;
  disabled?: boolean;
  options?: {
    height?: string;
    width?: string;
    fontSize?: string;
    letterSpacing?: string;
    padding?: string;
    border?: string;
  };
  handleFileInput?(files: FileList | null): void;
}

export const FileUploader: FC<FileUploaderProps> = ({
  handleFileInput,
  disabled,
  fileError,
  fileStatus,
  fileName,
  filePreview,
  label,
  requirements,
  instructions,
  view,
  loading,
  options = {},
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const handleFileBrowse = useCallback(() => {
    fileInput.current && fileInput.current.click();
  }, [fileInput]);
  const onKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleFileBrowse();
      }
    },
    [handleFileBrowse],
  );
  const hasError = !!(fileError && fileError.error);

  return (
    <>
      <label>
        <span css={labelStyle}>{label}</span>
      </label>
      <div>
        <div
          css={[
            fileUploadButtonStyle({ ...options, disabled }),
            view ? filePreviewStyle(filePreview) : null,
            fileStyle(options),
            hasError ? errorWrapperStyle : null,
            fileStatus && !fileStatus.processing && fileStatus.message
              ? successWrapperStyle
              : null,
          ]}
          onClick={handleFileBrowse}
          onKeyPress={onKeyPress}
          role="button"
          tabIndex={0}
        >
          {view ? (
            loading ? (
              <Spinner />
            ) : null
          ) : (
            <div css={inputWrapperStyle}>
              <div css={fileUploadTextStyle}>{fileName}</div>
              {isValidElement(instructions) ? (
                <>{instructions}</>
              ) : (
                <div css={fileUploadButtonTextStyle}>{instructions}</div>
              )}
              <input
                id="file-input"
                type="file"
                name="file-input"
                ref={fileInput}
                onChange={(event) =>
                  handleFileInput ? handleFileInput(event.target.files) : null
                }
                css={fileFileStyle}
                disabled={disabled}
              />
              <div css={fileNotesStyle}>{requirements}</div>
            </div>
          )}
        </div>
      </div>
      {hasError ? (
        <div css={errorMessageStyle}>{fileError?.message}</div>
      ) : undefined}
      {fileStatus && fileStatus.message ? (
        <div
          css={fileStatus.processing ? baseMessageStyle : successMessageStyle}
        >
          {fileStatus.message}
        </div>
      ) : undefined}
    </>
  );
};
