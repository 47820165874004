import {
  createAsyncThunk,
  createSlice,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  getOnboardingQuestions,
  postOnboardingAnswers,
  postOnboardingComplete,
} from '../../../rest-calls/onboardingQuestions';
import {
  GetSurveyQuestionResponse,
  SurveyAnswers,
  Family,
  Choice,
} from '../../../models/OnboardingQuestions';
import {
  ONBOARDING_SURVEY_ID,
  RequestStatus,
} from '../../../../../utils/constants';
import {
  getMultipleChoiceQuestionAnswers,
  getOnboardingAnswers,
  getProductOfferingRecommendation,
  getOnboardingQuestions as selectOnboardingQuestions,
} from './onboardingSelector';
import { RootState } from '../../../../../state/types';
import { AgeCohort } from '../../../constants/ageCohort';
import { CountryCode } from '../../../../../constants/country';
import { getSlug } from '../../selectors/orgSectionSelector/orgSectionSelector';
import { getHsUserId } from '../../selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getVerificationMethod } from '../../selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';

export const fetchOnboardingQuestions = createAsyncThunk(
  'onboarding/fetchOnboardingQuestions',
  async (JWT: string) => {
    const response = await getOnboardingQuestions(JWT);
    return response;
  },
);

/**
 * If the next question is for choices clarification from the previous step
 * and user has selected only single option we should skip the clarification question.
 *
 * This thunk is checking if only single option selected it's skipping the clarification
 * question and preselecting the answer for it (single selected option from previous step)
 */
export const skipClarificationQuestionIfSingleAnswerSelected = createAsyncThunk(
  'onboarding/skipClarificationQuestionIfSingleAnswerSelected',
  async (
    { navigate, currentQuestionId, nextQuestionPath, skipQuestionPath }: any,
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const selectedAnswers: Choice[] = getMultipleChoiceQuestionAnswers(
      state,
      currentQuestionId,
    );

    if (selectedAnswers?.length !== 1) {
      navigate(nextQuestionPath);
      return;
    }

    const selectedAnswer: Choice = selectedAnswers[0];
    const questions = selectOnboardingQuestions(state);
    const currentQuestion = questions.find(
      ({ id }) => id === currentQuestionId,
    );

    if (!currentQuestion) {
      navigate(nextQuestionPath);
      return;
    }

    const nextQuestion = questions.find(
      ({ position }) => currentQuestion.position + 1 === position,
    );

    if (!nextQuestion) {
      navigate(nextQuestionPath);
      return;
    }

    const nextQuestionChoices = nextQuestion.answers.choices;

    dispatch(
      setQuestionAnswer({
        id: nextQuestion.id,
        title: nextQuestion.title,
        family: nextQuestion.family,
        answers: nextQuestionChoices.filter(
          ({ text }) => text === selectedAnswer.text,
        ),
      }),
    );

    navigate(skipQuestionPath);
  },
);

export const postAnswers = createAsyncThunk(
  'onboarding/postOnboardingAnswers',
  async (JWT: string, { getState }) => {
    const state = getState() as RootState;
    const onboardingResponse = getOnboardingAnswers(state);
    const response = await postOnboardingAnswers(JWT, onboardingResponse);

    const slug = getSlug(state);
    const hsUserId = getHsUserId(state);
    const recommendedOffering = getProductOfferingRecommendation(state);
    const enrollmentType = getVerificationMethod(state);

    await postOnboardingComplete(JWT, slug, {
      enrollmentType,
      hsUserId,
      recommendedOffering,
    });

    return response;
  },
);

export const onboardingResponseInitialState: SurveyAnswers = {
  userId: '',
  surveyId: ONBOARDING_SURVEY_ID,
  surveyType: 'B2B',
  questions: [],
};

export interface OnboardingState {
  onBoardingQuestions: GetSurveyQuestionResponse | null;
  onBoardingResponse: SurveyAnswers;
  cohort: AgeCohort | null;
  country: CountryCode | null;
  loading: RequestStatus;
  postAnswersStatus: RequestStatus;
  dependentToken: string | null;
  error: null | SerializedError | Error;
}

export const initialState: OnboardingState = {
  cohort: null,
  country: null,
  dependentToken: null,
  error: null,
  loading: RequestStatus.IDLE,
  onBoardingQuestions: null,
  onBoardingResponse: onboardingResponseInitialState,
  postAnswersStatus: RequestStatus.IDLE,
};

const onboardingSlice = createSlice({
  initialState,
  name: 'onboarding',
  reducers: {
    clearQuestionAnswer(state, action) {
      const questionIdToPurge = action.payload;
      state.onBoardingResponse.questions = state.onBoardingResponse.questions.filter(
        ({ id }) => id !== questionIdToPurge,
      );
    },
    resetEnrollmentOnboardingState(state) {
      return initialState;
    },
    setCohort(state, action: PayloadAction<AgeCohort>) {
      state.cohort = action.payload;
    },
    setCountry(state, action: PayloadAction<CountryCode>) {
      state.country = action.payload;
    },
    setDependentToken(state, action: PayloadAction<string>) {
      state.dependentToken = action.payload;
    },
    clearDependentToken(state) {
      state.dependentToken = null;
    },
    setQuestionAnswer(state, action) {
      const questionId = action.payload.id;

      const answeredQuestions = state.onBoardingResponse.questions;
      const answerExist = !!answeredQuestions.find(
        (question) => question.id === questionId,
      );
      const questionIndex = answeredQuestions.findIndex(
        (el) => el.id === questionId,
      );

      if (answerExist && action.payload.family === Family.MULTIPLE_CHOICE) {
        const savedAnswers =
          state.onBoardingResponse.questions[questionIndex].answers;
        const answerId = action.payload.answers[0].id;

        const duplicateAnswer = !!savedAnswers.find(
          (answer) => answer.id === answerId,
        );

        // if answer already exist and clicked again we should remove from the list otherwise add it back
        if (duplicateAnswer) {
          const newAnswers = savedAnswers.filter((el) => el.id !== answerId);
          state.onBoardingResponse.questions[
            questionIndex
          ].answers = newAnswers;
        } else {
          savedAnswers.push(action.payload.answers[0]);
        }
      } else if (
        answerExist &&
        action.payload.family !== Family.MULTIPLE_CHOICE
      ) {
        answeredQuestions[questionIndex] = action.payload;
      } else {
        state.onBoardingResponse.questions.push(action.payload);
      }
    },
    setUserId(state, action) {
      state.onBoardingResponse.userId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOnboardingQuestions.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.onBoardingQuestions = data;
      state.loading = RequestStatus.SUCCEEDED;
    });

    builder.addCase(fetchOnboardingQuestions.pending, (state) => {
      state.loading = RequestStatus.PENDING;
    });

    builder.addCase(fetchOnboardingQuestions.rejected, (state, action) => {
      state.loading = RequestStatus.FAILED;
      state.error = action.error;
    });

    builder.addCase(postAnswers.fulfilled, (state) => {
      state.postAnswersStatus = RequestStatus.SUCCEEDED;
    });
    builder.addCase(postAnswers.pending, (state) => {
      state.postAnswersStatus = RequestStatus.PENDING;
    });

    builder.addCase(postAnswers.rejected, (state, action) => {
      state.postAnswersStatus = RequestStatus.FAILED;
      state.error = action.error;
    });
  },
});

export const {
  clearQuestionAnswer,
  setQuestionAnswer,
  setUserId,
  resetEnrollmentOnboardingState,
  setCohort,
  setCountry,
  setDependentToken,
  clearDependentToken,
} = onboardingSlice.actions;
export default onboardingSlice.reducer;
