import checkSvg from 'assets/checkThick-16.svg';
import { White } from '@headspace/web-ui-components';
import { Icon } from '../Icon';
import {
  tableCheckboxStyleChecked,
  tableCheckboxStyleUnchecked,
} from './styles';
import { TableCheckboxProps } from './types';

export const TableCheckbox = ({
  selected,
  onSelect,
  id,
}: TableCheckboxProps) => {
  const onKeyPress = (e) => {
    if (e.key === 'Enter' && onSelect) {
      onSelect(e);
    }
  };
  return (
    <div
      role="checkbox"
      id={id}
      aria-checked={selected}
      tabIndex={0}
      // @ts-ignore
      onClick={onSelect}
      onKeyPress={onKeyPress}
      className="checkbox"
      css={selected ? tableCheckboxStyleChecked : tableCheckboxStyleUnchecked}
    >
      {selected ? (
        <Icon src={checkSvg} width={16} height={16} fill={White['000']} />
      ) : null}
    </div>
  );
};
