import {
  PaymentActionTypes,
  InitializePaymentAction,
} from './paymentActionTypes';

const { INITIALIZE_PAYMENT } = PaymentActionTypes;

export const initializePayment = (): InitializePaymentAction => {
  return {
    type: INITIALIZE_PAYMENT,
  };
};
