import { useMemo } from 'react';
import { MEMBERS_ERROR_MESSAGES } from './constants';

const useVerificationErrorMessages = (): Map<string, string> => {
  return useMemo(() => {
    return new Map([
      [
        MEMBERS_ERROR_MESSAGES.memberEnrolled,
        'Member with this email was already enrolled',
      ],
      [
        MEMBERS_ERROR_MESSAGES.memberEnrolledInAnotherOrg,
        'Member with this email is enrolled through another employer sponsored program.',
      ],
      [
        MEMBERS_ERROR_MESSAGES.memberEmailNotWhitelisted,
        "Member with this email doesn't match organization's enrollment rules",
      ],
      [
        MEMBERS_ERROR_MESSAGES.memberEmailNotEligible,
        "Member with this email not present in organization's eligibility data",
      ],
      [
        MEMBERS_ERROR_MESSAGES.memberIdMismatch,
        'A different Member account was invited with this link',
      ],
      [MEMBERS_ERROR_MESSAGES.memberNotFound, 'Member was not found'],
      [MEMBERS_ERROR_MESSAGES.noOpenSeats, 'Organization has no open seats'],
    ]);
  }, []);
};

export default useVerificationErrorMessages;
