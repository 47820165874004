import { css } from '@emotion/react';
import {
  borderRadiusL,
  borderWidthS,
  fontSizeL,
  fontSizeM,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../../constants/breakpoint';

export const textChoicesContainerStyle = css({
  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    flexDirection: 'column',
  },
  [WebUIComponentsBreakPoint.Mobile]: {
    padding: `0 ${SpacingInRemBySize.L}`,
  },
});

export const textChoiceContainerStyle = (isSelected) =>
  css({
    background: isSelected ? WarmGrey[200] : WarmGrey[100],
    backgroundColor: WarmGrey[100],
    border: `${borderWidthS} solid ${WarmGrey[200]}`,
    [WebUIComponentsBreakPoint.Mobile]: {
      height: rem(3.25),
      width: '100%',
    },
    borderRadius: rem(5),
    height: SpacingInRemBySize['4XL'],
    marginBottom: SpacingInRemBySize.S,
    textAlign: 'left',
    width: rem(28.125),
  });

export const textLabelContainerStyle = css({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  lineHeight: '120%',
  paddingLeft: SpacingInRemBySize.XL,
  paddingRight: SpacingInRemBySize.M,
  position: 'relative',
  [WebUIComponentsBreakPoint.Mobile]: {
    lineHeight: '100%',
  },
});

export const stubRadioButtonStyle = css({
  backgroundColor: WarmGrey[100],
  borderRadius: borderRadiusL,
  bottom: 0,
  height: SpacingInRemBySize.L,
  margin: 'auto',
  position: 'absolute',
  right: rem(0.938),
  top: 0,
  width: SpacingInRemBySize.L,
});

export const textCheckMarkIconStyle = (isSelected) =>
  css({
    display: isSelected ? 'block' : 'none',
    height: '100%',
    width: '100%',
    zIndex: 10,
  });

export const textChoiceLabelTextStyle = css({
  color: WarmGrey[800],
  fontSize: fontSizeL,
  fontWeight: 700,
  paddingBottom: SpacingInRemBySize.M,
  paddingTop: SpacingInRemBySize.M,
  [WebUIComponentsBreakPoint.Mobile]: {
    fontSize: fontSizeM,
  },
});
