import { css } from '@emotion/react';
import {
  fontSizeXS,
  fontSizeXL,
  rem,
  SpacingInRemBySize,
  fontSizeL,
  WarmGrey,
} from '@headspace/web-ui-components';

export const outerContainerStyle = css({
  '> div': {
    textAlign: 'center',
  },
  '@media(max-width: 560px)': {
    marginTop: rem(1.875),
    maxWidth: rem(18.125),
  },
  color: WarmGrey[500],
  margin: rem(3.125),
});

export const priceContainer = css({
  paddingBottom: rem(1.25),
  paddingTop: SpacingInRemBySize.S,
});

export const headerText = css({
  color: WarmGrey[500],
  fontSize: fontSizeXL,
});

export const mainMessageText = css({
  fontSize: fontSizeXL,
  margin: 0,
  paddingBottom: SpacingInRemBySize['2XL'],
});

export const label = css({
  color: WarmGrey[500],
  fontSize: fontSizeL,
  margin: 0,
});

export const subLabel = css({
  color: WarmGrey[500],
  fontSize: fontSizeXS,
});
export const buttonContainer = css({
  margin: `${rem(3.75)} 0 ${SpacingInRemBySize['2XL']} 0`,
});

export const containerStyle = css({
  '@media(max-width: 560px)': {
    marginTop: rem(1.875),
    maxWidth: '100vw',
  },
});
