import React from 'react';
import { ConfirmModal, Divider } from '@headspace/web-ui-components';
import { format } from '../../../../../../../utils/dates';
import { Survey } from '../../../../../../../state/models/organization/Survey';
import {
  modalBodyStyle,
  modalStyle,
  modalFooterStyle,
  modalErrorMessageStyle,
} from './styles';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

interface ModalBodyPropTypes {
  survey: Survey;
  totalActiveMembers: number;
  errorMessage?: string;
}

const ModalBody = ({
  survey,
  totalActiveMembers,
  errorMessage,
}: ModalBodyPropTypes) => {
  const { translate } = useTranslate();

  const { name, launchDate } = survey;
  return (
    <div css={modalBodyStyle}>
      {errorMessage && (
        <div css={modalErrorMessageStyle} data-testid="send-survey-error">
          {errorMessage}
        </div>
      )}
      <h2 className="title">
        {translate('organizationSettings.survey.confirmationModal.title')}
      </h2>
      <p className="copy">
        {translate('organizationSettings.survey.confirmationModal.body')}
      </p>
      <Divider />
      <div className="content">
        <h3 className="title">
          {translate(
            'organizationSettings.survey.confirmationModal.surveyName',
          )}
        </h3>
        <p className="copy" data-testid="survey-name">
          {name}
        </p>
        <h3 className="title">
          {translate('organizationSettings.survey.confirmationModal.date')}
        </h3>
        <p className="copy" data-testid="sent-initiated">
          {translate('organizationSettings.survey.dateAtTime', {
            date: format(launchDate, 'MM/DD/YYYY'),
            time: format(launchDate, 'h:mma'),
          })}
        </p>
        <h3 className="title">
          {translate(
            'organizationSettings.survey.confirmationModal.numberOfRecipients',
          )}
        </h3>
        <p className="copy" data-testid="number-of-participants">
          {totalActiveMembers}
        </p>
      </div>
      <Divider />
    </div>
  );
};

interface SurveyConfirmationModalPropTypes {
  survey: Survey;
  isOpen: boolean;
  onCloseModal: () => void;
  onSendSurvey: () => void;
  totalActiveMembers: number;
  errorMessage?: string;
}

interface SurveyConfirmationModalFooterPropTypes {
  onConfirm?: () => void;
  hasActiveMembers: boolean;
}

const ModalFooter = ({
  onConfirm,
  hasActiveMembers,
}: SurveyConfirmationModalFooterPropTypes) => {
  const { translate } = useTranslate();

  if (!hasActiveMembers) {
    return null;
  }
  return (
    <div css={modalFooterStyle}>
      <TextButton
        onClick={onConfirm}
        value={translate('organizationSettings.survey.confirmationModal.send')}
      />
    </div>
  );
};

const ConfirmationModal = ({
  survey,
  isOpen,
  onCloseModal,
  onSendSurvey,
  totalActiveMembers,
  errorMessage,
}: SurveyConfirmationModalPropTypes) => {
  const onConfirm = totalActiveMembers ? onSendSurvey : undefined;
  return isOpen ? (
    <ConfirmModal
      lockParentScroll={true}
      css={modalStyle}
      body={
        <ModalBody
          survey={survey}
          totalActiveMembers={totalActiveMembers}
          errorMessage={errorMessage}
        />
      }
      onDismiss={onCloseModal}
      onConfirm={onConfirm}
      showDismissButton={false}
      footer={() => (
        <ModalFooter
          onConfirm={onConfirm}
          hasActiveMembers={totalActiveMembers > 0}
        />
      )}
    />
  ) : null;
};

export const SurveyConfirmationModal = ConfirmationModal;
