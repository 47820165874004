import * as React from 'react';
import { css } from '@emotion/react';
import {
  borderRadiusXS,
  borderWidthXS,
  Orange,
  rgba,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import { TitleXS } from './text';
import { uniqueKey } from '../utils/keyCounter';

export interface PanelProps {
  dataTestId?: string;
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  actions?: JSX.Element[];
  panelStyle?: any;
}

const panelStyles = css({
  backgroundColor: White['000'],
  border: `${borderWidthXS} solid ${WarmGrey[200]}`,
  borderRadius: borderRadiusXS,
  boxShadow: `0 ${SpacingInRemBySize['3XS']} 0 ${rgba(WarmGrey[800], 0.04)}`,
});

const titleStyles = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: `${SpacingInRemBySize.M} 0`,
});

const actionStyles = css({
  ':focus': {
    outline: 0,
  },
  ':hover': {
    color: Orange[100],
  },
  backgroundColor: 'none',
  border: 'none',
  color: Orange[200],
  marginLeft: SpacingInRemBySize.S,
  padding: 0,
  textDecoration: 'none',
});

const actionsWrapperStyles = css({
  '& a, & button': actionStyles,
});

export const Panel: React.FC<PanelProps> = ({
  title,
  dataTestId,
  actions = [],
  children,
  ...otherProps
}) => {
  return (
    <div data-testid={dataTestId} css={panelStyles} {...otherProps}>
      {(title || actions.length > 0) && (
        <div css={titleStyles}>
          {title && <TitleXS>{title}</TitleXS>}
          {actions.length > 0 && (
            <div css={actionsWrapperStyles}>
              {actions}
              {actions.map((action: Action) => {
                const i = uniqueKey();
                return <React.Fragment key={i}>{action}</React.Fragment>;
              })}
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};
