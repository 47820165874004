import { css } from '@emotion/react';
import {
  borderWidthXS,
  CoolGrey,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

export const outerContainerStyle = css({
  '> div > a': {
    color: CoolGrey[300],
    textDecoration: 'none',
  },
  '>div': {
    textAlign: 'center',
  },
  color: CoolGrey[300],
  margin: rem(3.125),
});

export const rowStyle = css({
  alignItems: 'center',
  borderBottom: `${borderWidthXS} solid ${WarmGrey[100]}`,
  display: 'flex',
  justifyContent: 'space-between',
});

export const instructionsStyle = css({
  textAlign: 'center',
});

export const buttonContainer = css({
  margin: `${rem(3.125)} 0 ${SpacingInRemBySize['2XL']} 0`,
});

export const containerStyle = css({
  '@media(max-width: 560px)': {
    marginTop: rem(1.875),
    maxWidth: '100vw',
  },
});
