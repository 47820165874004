import { useGate } from 'statsig-react';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_force_rollover_button
// Feature flag to enable the "Force Rollover" button in organization settings
export const KEY = 'b2b_dashboard_force_rollover_button';

export const useForceRolloverButton = (): [boolean, boolean] => {
  const { value, isLoading } = useGate(KEY);

  return [value, isLoading];
};
