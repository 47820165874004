import 'react-day-picker/lib/style.css';
import caretDownTundoraSvg from 'assets/caretDownTundora.svg';
import caretUpTundoraSvg from 'assets/caretUpTundora.svg';
import checkThick24WhiteSvg from 'assets/checkThick-24-white.svg';
import checkThick24Svg from 'assets/checkThick-24.svg';
import checkThick24GreySvg from 'assets/checkThick-24-grey.svg';
import {
  White,
  rgba,
  Grey,
  fontSizeM,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  rem,
  fontSizeXS,
  lineHeightHeading,
  fontSizeS,
  SpacingInRemBySize,
  borderWidthXS,
  Orange,
  borderRadiusS,
  borderRadiusXS,
  borderWidthS,
  bodyXSCss,
  Red,
  Green,
  bodyMCss,
  CoolGrey,
  WarmGrey,
  borderRadiusM,
  fontWeightBold,
  colorPrimary,
} from '@headspace/web-ui-components';
import { css, SerializedStyles } from '@emotion/react';

type StyleFn = (hasError?: boolean) => SerializedStyles;
type StyleOptions = (options?: any) => SerializedStyles;
type StylePadding = (side?: string) => SerializedStyles;

export const labelStyles: StyleFn = (hasError) =>
  css({
    color: hasError ? Red[200] : WarmGrey[500],
    display: 'block',
    margin: `${SpacingInRemBySize.L} 0 ${SpacingInRemBySize.XS}`,
    textTransform: 'uppercase',
  });

export const errorStyles = css(bodyXSCss, {
  color: Red[200],
  display: 'block',
  paddingLeft: SpacingInRemBySize['3XS'],
  paddingTop: SpacingInRemBySize['2XS'],
});

export const sharedInputStyles = (hasError: boolean, isDisabled: boolean) =>
  css({
    '&:focus': {
      border: `${borderWidthXS} solid ${CoolGrey[500]}`,
      outline: 'none',
    },
    backgroundColor: isDisabled ? WarmGrey[100] : White['000'],
    border: `${borderWidthXS} solid ${
      hasError === true ? Red[200] : WarmGrey[200]
    }`,
    borderRadius: rem(0.313),
    color: isDisabled ? WarmGrey[400] : WarmGrey[700],
    display: 'block',
    fontSize: fontSizeM,
    minHeight: rem(1.25),
    padding: `${SpacingInRemBySize.S} ${SpacingInRemBySize.M}`,
  });

export const inputStyles = (hasError = false, isDisabled = false) =>
  css(sharedInputStyles(hasError, isDisabled), {
    '&::placeholder': {
      color: WarmGrey[500],
    },
    '&[type=number]': {
      '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      MozAppearance: 'textfield',
    },
    boxSizing: 'border-box',
    height: SpacingInRemBySize['4XL'],
    width: '100%',
  });

export const selectStyles: StyleFn = () =>
  css({
    '&.disabled': {
      backgroundImage: 'none',
      color: rgba(WarmGrey[700], 0.4),
    },
    '&.open': {
      backgroundImage: `url(${caretUpTundoraSvg})`,
    },
    appearance: 'none',
    backgroundImage: `url(${caretDownTundoraSvg})`,
    backgroundPosition: `right ${SpacingInRemBySize.M} bottom 50%`,
    backgroundRepeat: 'no-repeat',
    textAlign: 'left',
  });

export const selectInputStyles: StyleFn = () =>
  css({
    borderWidth: `0 0 ${borderWidthXS} 0`,
    width: '100%',
  });

export const selectInputLabelStyles: SerializedStyles = css({
  '&:hover': {
    ':not(:disabled)': {
      color: Orange[300],
      cursor: 'pointer',
    },
  },
  backgroundColor: White['000'],
  color: Orange[200],
  position: 'absolute',
  right: SpacingInRemBySize.M,
  top: rem(0.875),
  wordBreak: 'break-all',
});

export const textAreaStyles = (hasError: boolean, isDisabled: boolean) =>
  css(sharedInputStyles(hasError, isDisabled), {
    height: rem(9),
    resize: 'none',
    width: '100%',
  });

export const listItemCardStyles: SerializedStyles = css({
  alignItems: 'center',
  color: WarmGrey[700],
  display: 'flex',
  fontSize: fontSizeM,
  height: SpacingInRemBySize['3XL'],
  justifyContent: 'space-between',
  margin: 0,
  padding: `0 ${rem(1.125)}`,
});

export const selectItemCardStyles: SerializedStyles = css(listItemCardStyles, {
  '&.disabled': {
    color: rgba(WarmGrey[700], 0.4),
    pointerEvents: 'none',
  },
  '&.selected': {
    backgroundImage: `url(${checkThick24Svg})`,
    backgroundPosition: `${SpacingInRemBySize.M} 50%`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${rem(0.875)} ${rem(0.875)}`,
  },
  '&:hover': {
    ':not(.disabled)': {
      backgroundColor: WarmGrey[100],
    },
  },
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  padding: `0 0 0 ${SpacingInRemBySize['2XL']}`,
  userSelect: 'none',
  width: '100%',
});

export const listItemIconStyles = (url: any): SerializedStyles => {
  return css({
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: White['000'],
    backgroundImage: `url(${url})`,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: SpacingInRemBySize.M,
    width: SpacingInRemBySize.M,
  });
};

export const dropdownDrawerStyles: SerializedStyles = css({
  backgroundColor: White['000'],
  border: `${borderWidthXS} solid ${WarmGrey[300]}`,
  borderRadius: borderRadiusS,
  marginTop: SpacingInRemBySize.XS,
  position: 'absolute',
  width: '100%',
  zIndex: 1,
});

export const dropdownStyles: SerializedStyles = css({
  height: 'fit-content',
  maxHeight: rem(15.625),
  overflowY: 'auto',
});

export const reviewInputValueStyles: SerializedStyles = css({
  border: 'none',
  color: WarmGrey[700],
  minHeight: rem(1.25),
  padding: 0,
  wordBreak: 'break-all',
});

export const reviewInputSubtextStyles: SerializedStyles = css({
  color: WarmGrey[500],
  fontSize: fontSizeS,
  lineHeight: lineHeightHeading,
});

export const width100Style = css({
  position: 'relative',
  width: '100%',
});

export const hintTextStyles: StylePadding = (side) => {
  let margin;
  switch (side) {
    case 'left':
      margin = `0 ${SpacingInRemBySize.XS} 0 0`;
      break;
    case 'right':
      margin = `0 0 0 ${SpacingInRemBySize.XS}`;
      break;
    default:
      margin = `0 ${SpacingInRemBySize.XS} 0 ${SpacingInRemBySize.XS}`;
  }
  return css(bodyMCss, {
    color: CoolGrey[500],
    margin,
  });
};

export const dayPickerInputStyle = css({
  width: '100%',
});

export const dayPickerStyle = css({
  '& .DayPicker-Day': {
    ':hover': {
      backgroundColor: `${White['000']} !important`,
      border: `${borderWidthXS} solid ${Orange[200]}`,
      color: Orange[200],
    },
    border: `${borderWidthXS} solid ${White['000']}`,
    color: WarmGrey[700],
    fontSize: fontSizeXS,
  },
  '& .DayPicker-Day--outside': {
    color: WarmGrey[500],
    fontSize: fontSizeXS,
  },
  '& .DayPicker-Day--selected': {
    '&:not(:hover)': {
      backgroundColor: `${Orange[200]} !important`,
      color: White['000'],
    },
  },
  '& .DayPicker-Month': {
    borderCollapse: 'inherit',
    margin: `0 ${SpacingInRemBySize.S}`,
  },
  '& .DayPicker-Weekdays': {
    backgroundColor: WarmGrey[100],
    color: WarmGrey[500],
    fontSize: fontSizeXS,
  },
  '& .DayPicker-wrapper': {
    ':focus': {
      outline: 'none',
    },
  },
  backgroundColor: White['000'],
  border: `${borderWidthXS} solid ${WarmGrey[200]}`,
  borderRadius: borderRadiusXS,
  fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.normal,
  marginTop: SpacingInRemBySize['2XS'],
  position: 'absolute',
  zIndex: 1,
});

export const dayPickerNavbarStyle = css({
  '& .isvg': {
    ':hover': {
      cursor: 'pointer',
    },
  },
  color: WarmGrey[700],
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: rem(0.875),
  margin: `${rem(0.938)} ${rem(1.75)} ${SpacingInRemBySize.XS}`,
});

export const checkboxStyle = css({
  '& .checkboxDisplay': {
    '&.checked': {
      '.disabled': {
        backgroundColor: WarmGrey[200],
        backgroundImage: `url(${checkThick24GreySvg})`,
      },
      backgroundColor: Green[100],
      backgroundImage: `url(${checkThick24WhiteSvg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderColor: 'transparent',
    },
    border: `${borderWidthS} solid ${WarmGrey[200]}`,
    borderRadius: rem(0.125),
    fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.normal,
    height: SpacingInRemBySize.M,
    margin: '0',
    width: SpacingInRemBySize.M,
  },
  '& .checkboxLabel': {
    '.disabled': {
      color: rgba(WarmGrey[600], 0.4),
      cursor: 'default',
    },
    color: WarmGrey[600],
    fontSize: fontSizeM,
    marginLeft: rem(0.625),
  },
  '& input': {
    display: 'none',
  },
  '.disabled': {
    color: rgba(WarmGrey[700], 0.4),
    cursor: 'default',
  },
  ':hover': {
    cursor: 'pointer',
  },
  alignItems: 'center',
  color: WarmGrey[700],
  display: 'flex',
  justifyContent: 'flex-start',
  lineHeight: rem(1.75),
  margin: `${rem(1.25)} 0 ${1.438}`,
});

export const inputOptions: StyleOptions = (options) => {
  return css({
    ...options,
  });
};

export const iconStyle = css({
  marginLeft: SpacingInRemBySize.XS,
});

export const hintContainerStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
});

export const inputLabelStyle = css({
  letterSpacing: rem(0.156),
  textTransform: 'uppercase',
});

export const radioInputStyle = css({
  borderRadius: borderRadiusM,
  padding: SpacingInRemBySize.M,
  backgroundColor: WarmGrey[100],
  textAlign: 'left',
  margin: 'auto',
  marginBottom: SpacingInRemBySize.S,
  display: 'flex',
  label: {
    width: '90%',
  },
  'input[type="radio"]': {
    appearance: 'none',
    margin: 'auto',
    font: 'inherit',
    color: 'black',
    width: rem(1.15),
    height: rem(1.15),
    borderWidth: rem(0.15),
    borderStyle: 'solid',
    borderColor: WarmGrey[300],
    borderRadius: '50%',
    '&:checked': {
      borderColor: colorPrimary,
      borderWidth: rem(0.35),
    },
  },
});

export const radioInputTitleStyle = css({
  fontWeight: fontWeightBold,
});
