import { useCallback } from 'react';
import { useIntl } from 'react-intl';

type InterpolationObj = Record<
  string,
  string | number | null | ((str: string) => JSX.Element)
>;

export type TranslateFn = (
  stringPath: string,
  interpolationObj?: InterpolationObj,
) => string;

// Used within react components. If you need to translate outside of a component use ./i18n/utils/translate
export const useTranslate = () => {
  const { formatMessage } = useIntl();

  const translate: TranslateFn = useCallback(
    (stringPath: string, interpolationObj?: InterpolationObj) =>
      formatMessage({ id: stringPath }, interpolationObj) as string,
    [formatMessage],
  );

  return {
    translate,
  };
};
