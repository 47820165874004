import { useState, useCallback } from 'react';
import * as React from 'react';
import { BrandColor, Toast as UiToast } from '@headspace/web-ui-components';
import { useTranslate } from '../../hooks/useTranslate';

interface ToastProps {
  text: string;
  backgroundColor?: BrandColor;
  callback?: () => void;
}

export const Toast: React.FC<ToastProps> = ({
  text,
  backgroundColor,
  callback,
}) => {
  const [showToast, setShowToast] = useState(true);
  const { translate } = useTranslate();
  const handleCloseToast = useCallback(() => {
    setShowToast(false);
    if (callback) {
      callback();
    }
  }, [showToast]);

  if (!showToast) {
    return null;
  }

  return (
    <UiToast
      handleClose={handleCloseToast}
      text={text}
      closeButtonLabel={translate('toast.close')}
      backgroundColor={backgroundColor}
    />
  );
};
