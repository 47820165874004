import { css } from '@emotion/react';
import { Dispatch } from 'redux';
import { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  cssWithMq,
  fontSizeM,
  fontSizeXL,
  Spinner,
} from '@headspace/web-ui-components';
import { useNavigate } from 'react-router-dom';
import { useGetGeolocation } from '../../../../hooks/useGetGeolocation';
import {
  checkWithinBoundaries,
  checkWithinBoundingBox,
} from '../../../../utils/checkBoundaries';
import COUNTY_BOUNDARIES from '../../../../utils/LACountyBoundaries.json';
import { updateGeolocationVerification } from '../../state/actions/geolocationActions/geolocationActions';
import ContentWrapper from '../shared-components/ContentWrapper/ContentWrapper';

import { useTranslate } from '../../../../hooks/useTranslate';

interface GeolocationProps {
  organizationSlug: string;
  errorPageUrl: string;
  locationErrorPageUrl: string;
  updateIsVerified?: (boolean) => void;
}

const geolocationTextCss = cssWithMq({
  '& > p': {
    fontSize: [fontSizeM, fontSizeXL, fontSizeXL],
    textAlign: 'center',
  },
});

const spinnerStyle = css({
  margin: '0 auto',
});

const GeolocationComponent = ({
  organizationSlug,
  errorPageUrl,
  updateIsVerified = undefined,
  locationErrorPageUrl,
}: GeolocationProps) => {
  const GEOLOCATION_SUCCESS_PAGE_URL = useMemo(
    () => `${window.location.pathname}${window.location.search}`,
    [organizationSlug],
  );
  const { translate } = useTranslate();

  const navigate = useNavigate();

  const onError = (error) => {
    updateIsVerified!(false);

    window.location.assign(errorPageUrl);
  };

  const onSuccess = (position: any) => {
    const { coords } = position;
    const userLocation = [coords.longitude, coords.latitude];

    if (
      checkWithinBoundingBox(userLocation, COUNTY_BOUNDARIES.boundingBox) &&
      checkWithinBoundaries(userLocation, COUNTY_BOUNDARIES.boundaries)
    ) {
      updateIsVerified!(true);
      navigate(GEOLOCATION_SUCCESS_PAGE_URL);
    } else {
      updateIsVerified!(false);
      window.location.assign(locationErrorPageUrl);
    }
  };

  useGetGeolocation(onSuccess, onError);

  return (
    <ContentWrapper
      dataTestIdPrefix="geolocation-check"
      css={geolocationTextCss}
    >
      <div>
        <Spinner css={spinnerStyle} />
      </div>
      <p>{translate('geolocation')}</p>
    </ContentWrapper>
  );
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateIsVerified: (isVerified) =>
    dispatch(updateGeolocationVerification(isVerified)),
});

const Geolocation = connect(null, mapDispatchToProps)(GeolocationComponent);

export default Geolocation;
