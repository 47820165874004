import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParam } from 'react-use';
import { PrimaryButton, cssWithMq, rem } from '@headspace/web-ui-components';
import { useNavigate } from 'react-router-dom';
import { BaseExceptionPage } from './BaseExceptionPage';
import { useTranslate } from '../../../../../hooks/useTranslate';
import {
  buttonClickthroughBackToHomeAction,
  dobCountryValidationScreenViewError,
} from '../../../../../analytics/events/enrollmentEvents';

const ctaStyle = (hasAdditionalCopy: boolean) =>
  cssWithMq({
    marginBottom: [
      undefined,
      undefined,
      hasAdditionalCopy ? undefined : rem(4.0625),
    ],
    width: ['100%', rem(21.75), rem(21.75)],
  });

export enum B2bErrorCodes {
  INVALID_DOB = 'INVALID_DOB',
  MEMBER_UNDERAGE = 'MEMBER_UNDERAGE',
  MEMBER_TEEN_AND_OUTSIDE_US = 'MEMBER_TEEN_AND_OUTSIDE_US',
  MEMBER_NON_ADULT_DOB_MISMATCH = 'MEMBER_NON_ADULT_DOB_MISMATCH',
  MEMBER_NOT_FOUND = 'MEMBER_NOT_FOUND',
  MORE_THAN_ONE_MEMBER_FOUND = 'MORE_THAN_ONE_MEMBER_FOUND',
  ALREADY_B2B_SUB = 'ALREADY_B2B_SUB',
}

function getErrorCodes(code: B2bErrorCodes | string) {
  switch (code) {
    case B2bErrorCodes.MEMBER_UNDERAGE:
      return {
        subtitle: 'enrollment.errors.identityVerification.underage.subtitle',
        title: 'enrollment.errors.identityVerification.generic.title',
      };
    case B2bErrorCodes.MEMBER_TEEN_AND_OUTSIDE_US:
      return {
        subtitle:
          'enrollment.errors.identityVerification.teenOutsideUS.subtitle',
        title: 'enrollment.errors.identityVerification.generic.title',
      };
    case B2bErrorCodes.MORE_THAN_ONE_MEMBER_FOUND:
      return {
        subtitle: 'enrollment.errors.identityVerification.tooMany.subtitle',
        title: 'enrollment.errors.identityVerification.tooMany.title',
      };
    case B2bErrorCodes.ALREADY_B2B_SUB:
      return {
        learnMoreLink:
          'https://help.headspace.com/hc/en-us/articles/1260802643490-I-want-to-add-someone-to-my-Family-plan-but-they-already-have-a-Headspace-subscription',
        subtitle:
          'enrollment.errors.identityVerification.existingSubscription.subtitle',
        title:
          'enrollment.errors.identityVerification.existingSubscription.title',
      };
    case B2bErrorCodes.MEMBER_NOT_FOUND:
      return {
        additionalCopy:
          'enrollment.errors.identityVerification.memberNotFound.additionalCopy',
        additionalCopyLink: 'https://my.headspace.com',
        learnMoreLink:
          'https://help.headspace.com/hc/en-us/sections/24891293459355-Enrollment-Troubleshooting',
        subtitle:
          'enrollment.errors.identityVerification.memberNotFound.subtitle',
        title: 'enrollment.errors.identityVerification.memberNotFound.title',
      };
    case B2bErrorCodes.INVALID_DOB:
    case B2bErrorCodes.MEMBER_NON_ADULT_DOB_MISMATCH:
    default:
      return {
        subtitle: 'enrollment.errors.identityVerification.mismatch.subtitle',
        title: 'enrollment.errors.identityVerification.mismatch.title',
      };
  }
}

const errorsToExcludeBackButton = [B2bErrorCodes.ALREADY_B2B_SUB];

export const IdentityVerificationErrorPage: FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { translate } = useTranslate();
  const code = useSearchParam('code') ?? 'default';
  const errorCode = getErrorCodes(code);

  const handleBack = useCallback(() => {
    dispatch(buttonClickthroughBackToHomeAction());
    navigate(-1);
  }, [navigate, dispatch]);

  useEffect(() => {
    dispatch(dobCountryValidationScreenViewError(code.toLowerCase()));
  }, [dispatch, code]);

  const showBackButton = !errorsToExcludeBackButton.includes(
    code as B2bErrorCodes,
  );

  return (
    <BaseExceptionPage
      additionalCopyKey={errorCode.additionalCopy}
      additionalCopyLink={errorCode.additionalCopy}
      titleKey={errorCode.title}
      subtitleKey={errorCode.subtitle}
      subtitleLearnMore={errorCode.learnMoreLink}
      dataTestIdPrefix={code}
    >
      {showBackButton ? (
        <PrimaryButton
          onClick={handleBack}
          css={ctaStyle(!!errorCode.additionalCopy)}
          dataTestIdPrefix="back"
        >
          {translate('enrollment.errors.identityVerification.goBack')}
        </PrimaryButton>
      ) : null}
    </BaseExceptionPage>
  );
};
