import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../constants/routes';
import { getHsUserId } from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { VPCareWrapper } from '../../HealthHubViews/VirginPulse/VPCareWrapper/VPCareWrapper';
import { ConfirmationComponent } from './ConfirmationComponent';

export const VPCareConfirmation: FC = () => {
  const navigate = useNavigate();
  const hsUserId = useSelector(getHsUserId);

  useEffect(() => {
    if (!hsUserId) {
      navigate(Routes.VP_CARE_LAND);
    }
  }, [hsUserId]);
  return (
    <VPCareWrapper showConfirmationPage={true}>
      <ConfirmationComponent />
    </VPCareWrapper>
  );
};
