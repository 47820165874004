import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';

import { GetState } from '../../types';
import {
  OrganizationActionsTypes,
  SaveOrganizationBasicAction,
} from '../../types/organization';
import { ModalActionsTypes } from '../../types/modal';
import { OrganizationAdministratorProps } from '../../../apps/dashboard/types';
import { createAdmin, editAdmin, deleteAdmin } from '../../../rest';
import { parseError } from '../../../utils/parseError';

const { SAVE_ORGANIZATION_ADMINISTRATORS } = OrganizationActionsTypes;
const { OPEN_MODAL } = ModalActionsTypes;

export const saveOrganizationAdministrators = (): ThunkMiddleware &
  SaveOrganizationBasicAction => {
  // @ts-ignore
  return async (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: { org_admins, cachedOrg, ...org },
    } = getState();

    const { id: orgId = org.orgId, admins } = cachedOrg ?? {};
    const refAdmins: Record<
      string,
      OrganizationAdministratorProps
    > = admins?.reduce((refs: any, admin: OrganizationAdministratorProps) => {
      refs[`${admin.email}`] = admin;
      return refs;
    }, {});
    org_admins.forEach((admin) => {
      if (!admin.id) {
        admin.id = refAdmins[admin.email]?.id;
      }
    });
    const nextIds = org_admins.map(
      ({ id }: OrganizationAdministratorProps) => id,
    );
    const toDelete = admins?.filter(
      ({ id }: OrganizationAdministratorProps) => !nextIds.includes(id),
    );
    const toUpdate: OrganizationAdministratorProps[] = [];
    const toAdd: OrganizationAdministratorProps[] = [];
    const nextAdmins: OrganizationAdministratorProps[] = [];
    org_admins.forEach((admin: OrganizationAdministratorProps) => {
      const { email, id } = admin;
      if (!id) {
        toAdd.push(admin);
      } else if (JSON.stringify(admin) !== JSON.stringify(refAdmins?.[email])) {
        toUpdate.push(admin);
      } else {
        nextAdmins.push(admin);
      }
    });
    const work = [
      ...toAdd.map(
        ({ name, email, title = '' }: OrganizationAdministratorProps) => {
          return createAdmin({ orgId, name, email, title });
        },
      ),
      ...toUpdate.map(
        ({
          name,
          email,
          title = '',
          id = '',
        }: OrganizationAdministratorProps) => {
          return editAdmin({ orgId, id, name, email, title });
        },
      ),
    ];
    toDelete?.map(({ id = '' }: OrganizationAdministratorProps) => {
      return deleteAdmin({ orgId, id });
    });

    let error;
    const results = await Promise.all(work);
    results.forEach((result: any) => {
      if (result?.data) {
        nextAdmins.push(result.data);
      } else {
        error = parseError(result);
      }
    });

    dispatch({
      type: SAVE_ORGANIZATION_ADMINISTRATORS,
      payload: {
        cachedOrg: { ...cachedOrg, admins: nextAdmins },
        org_admins: nextAdmins,
      },
    });

    if (error) {
      dispatch({
        type: OPEN_MODAL,
        payload: {
          message: error.message,
        },
      });
    }
  };
};
