import { Reducer } from 'redux';
import { UserState, UserAction, UserActionsTypes } from '../types/user';

export const initialState: UserState = {
  hasFetchedUser: false,
  info: {},
  isCSM: false,
  isLoggingIn: false,
};

export const reducer: Reducer = (
  state: UserState = initialState,
  action: UserAction,
) => {
  switch (action.type) {
    case UserActionsTypes.USER_LOGIN:
      return {
        ...state,
        isLoggingIn: false,
        error: null,
      };
    case UserActionsTypes.USER_LOGIN_V2:
      return {
        ...initialState,
        isLoggingIn: false,
        error: null,
      };
    case UserActionsTypes.USER_AUTHORIZE:
      return {
        ...state,
        isLoggingIn: false,
        error: null,
      };
    case UserActionsTypes.USER_PROCESS:
      return {
        ...state,
        isLoggingIn: false,
        error: null,
      };
    case UserActionsTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: true,
        authToken: action.authToken,
        error: null,
      };
    case UserActionsTypes.USER_LOGIN_ERROR:
      return {
        ...state,
        isLoggingIn: false,
        authToken: null,
        error: action.error,
      };
    case UserActionsTypes.USER_LOGIN_ERROR_RESET:
      return {
        ...state,
        isLoggingIn: false,
        error: null,
      };
    case UserActionsTypes.USER_INFO:
      return {
        ...state,
        isCSM: action.resp.role === 'csm',
        hasFetchedUser: true,
        info: action.resp,
      };
    case UserActionsTypes.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
