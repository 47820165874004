const ENCRYPTED_FILE_TYPES = ['.pgp', '.asc', '.gpg'];

export const checkForEncryptedFile = (
  potentialEncryptedFile: File,
): File | Blob => {
  const fileName = potentialEncryptedFile.name || '';
  const isEncrypted = ENCRYPTED_FILE_TYPES.some((x) => fileName.includes(x));
  const encryptedFile = new Blob([potentialEncryptedFile], {
    type: 'application/pgp-encrypted',
  });
  const file = isEncrypted ? encryptedFile : potentialEncryptedFile;

  return file;
};
