import * as R from 'ramda';
import { RootState } from '../../../../../state/types';
import { HealthProvider } from '../../../constants/healthProvider';
import { HealthProviderErrorTypes } from '../../../constants/errorTypes';

export const getHealthProvider = (state: RootState): HealthProvider =>
  R.pathOr(null, ['healthProvider', 'healthProvider'], state);

export const getHealthProviderConnectionState = (state: RootState): boolean =>
  R.pathOr(false, ['healthProvider', 'connectionInProgress'], state);

export const getHealthProviderCountryCode = () => {
  return new URLSearchParams(window.location.search).get('countryCode');
};

export const getHealthProviderUserId = () => {
  return new URLSearchParams(window.location.search).get('partnerUserId');
};

export const getHealthProviderErrorCode = (
  state: RootState,
): HealthProviderErrorTypes =>
  R.pathOr(
    HealthProviderErrorTypes.SOMETHING_WENT_WRONG_DURING_HEALTH_PROVIDER_CONNECTION,
    ['healthProvider', 'error', 'code'],
    state,
  );
