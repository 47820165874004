import {
  Grey,
  SpacingInRemBySize,
  rem,
  fontSizeM,
  fontSizeXL,
  fontWeightRegular,
  lineHeightBody,
  WarmGrey,
  CoolGrey,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';

export const stepsWrapper = (isActive: boolean) =>
  css({
    marginTop: SpacingInRemBySize['2XL'],
    '& .uploadButton': {
      position: 'absolute',
      right: SpacingInRemBySize['2XL'],
      top: isActive ? '50%' : 0,
      transform: isActive ? 'translateY(-50%)' : 'translateY(50%)',
    },
  });

export const activeChildrenWrapper = css({
  marginTop: SpacingInRemBySize.L,
  paddingInlineStart: rem(1.25),
  '& li': {
    margin: `${rem(1.25)} 0`,
  },
});

export const modalBodyStyle = css({
  margin: `${SpacingInRemBySize['2XL']} 0 ${SpacingInRemBySize['4XL']}`,
  maxWidth: rem(19.75),
  '& .title': {
    color: CoolGrey[500],
    fontSize: fontSizeXL,
    fontWeight: fontWeightRegular,
    lineHeight: rem(1.75),
    marginBottom: SpacingInRemBySize.XL,
  },
  '& .copy': {
    color: WarmGrey[700],
    fontSize: fontSizeM,
    lineHeight: lineHeightBody,
    marginTop: rem(1.25),
  },
});

export const modalStyle = css({
  position: 'fixed !important' as any,
  '> :first-of-type': {
    backgroundColor: WarmGrey[600],
    opacity: 0.53,
  },
});
