type Coordinates = number[];
interface BoundingBox {
  x: Coordinates;
  y: Coordinates;
}

/**
 * Checks that specific point is on the left of the edge (line between two vertices)
 *
 * @param v1 - first vertex
 * @param v2 - second vertex
 * @param point - Point to be checked
 * @returns position - position > 0 - point is on the left of the line
 *                     position < 0 - point is on the right of the line
 *                     position == 0 - point is on the line
 */
export const getPosition = (
  v1: Coordinates,
  v2: Coordinates,
  point: Coordinates,
) => {
  return (
    (v2[0] - v1[0]) * (point[1] - v1[1]) - (point[0] - v1[0]) * (v2[1] - v1[1])
  );
};

/**
 * Check that specific point is within the polygon boundaries
 *
 * @param point - Point with [x, y] coordinates
 * @param boundaries - Array of polygon vertices, boundaries[0] = boundaries[n]
 */
export const checkWithinBoundaries = (
  point: Coordinates,
  boundaries: Coordinates[],
) => {
  /**
   * NOTE: boundaries[0] = boundaries[n]
   */
  const boundariesVertices = boundaries.length - 1;
  /**
   * Winding number counter
   */
  let windingNumber = 0;

  /**
   * Loop through all edges (from boundaries[i] to boundaries[i+1]) of the boundaries
   */
  for (let i = 0; i < boundariesVertices; i++) {
    if (boundaries[i][1] <= point[1]) {
      /**
       * Check for upward crossing
       */
      if (boundaries[i + 1][1] > point[1]) {
        /**
         * Check for intersection
         */
        if (getPosition(boundaries[i], boundaries[i + 1], point) > 0) {
          ++windingNumber;
        }
      }
      /**
       * Check for downward crossing
       */
    } else if (boundaries[i + 1][1] <= point[1]) {
      /**
       * Check for intersection
       */
      if (getPosition(boundaries[i], boundaries[i + 1], point) < 0) {
        --windingNumber;
      }
    }
  }

  return Boolean(windingNumber);
};

export const isInRange = (value: number, min: number, max: number) =>
  value > min && value < max;

export const checkWithinBoundingBox = (
  point: Coordinates,
  boundingBox: BoundingBox,
) => {
  const { x, y } = boundingBox;
  return isInRange(point[0], x[0], x[1]) && isInRange(point[1], y[0], y[1]);
};
