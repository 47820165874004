import {
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { flex } from '../../styles/flex';

export const progressNavStyle = css(flex, {
  color: WarmGrey[600],
  margin: `${SpacingInRemBySize.XL} 0 ${rem(1.25)} 0`,
  paddingBottom: SpacingInRemBySize['2XL'],
  width: rem(61.25),
});

export const stepStyles = (currentStep: number) =>
  css({
    '& .step-progress': {
      '& li': {
        background: WarmGrey[700],
        borderRadius: SpacingInRemBySize['2XS'],
        height: SpacingInRemBySize.XS,
        margin: `0 ${SpacingInRemBySize.XS}`,
        opacity: 0.2,
        width: SpacingInRemBySize.XS,
        [`:nth-of-type(${currentStep})`]: {
          opacity: 1,
        },
      },
    },
    margin: 'auto',
    padding: 'auto',
  });
