import { useMutation } from '@tanstack/react-query';
import {
  PostIdentityVerificationsPayload,
  postIdentityVerifications,
} from '../rest-calls/postIdentityVerifications';
import {
  VpEnrollmentVerificationPayload,
  postVpEnrollmentVerification,
} from '../rest-calls';

export const usePostVpEnrollmentVerification = (onSuccess, onError) =>
  useMutation({
    mutationFn: (payload: VpEnrollmentVerificationPayload) =>
      postVpEnrollmentVerification(payload),
    onSuccess,
    onError,
  });

export const usePostIdentityVerifications = (slug: string, onSuccess) =>
  useMutation({
    mutationFn: (payload: PostIdentityVerificationsPayload) =>
      postIdentityVerifications(slug, payload),
    onSuccess,
  });
