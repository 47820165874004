import { createSlice } from '@reduxjs/toolkit';
import { MEMBER_STATUS } from '../../../apps/dashboard/constants';
import { MemberSearchEntry, MembersSearchResult } from '../../../rest';
import { RestrictBy } from '../../../types/organization';
import { MEMBERS } from './constants';
import { getMembersDataThunk } from './getMembers/getMembersThunk';

interface MembersInitialStateProps {
  filteredMembers: number;
  isFetchingMembers: boolean;
  members: MemberSearchEntry[];
  membersDefault: MemberSearchEntry[];
  totalMembers: number;
}

interface PayloadProps {
  data: MembersSearchResult;
  offset: number;
  filters?: any;
}

export const membersInitialState: MembersInitialStateProps = {
  filteredMembers: 0,
  isFetchingMembers: false,
  members: [],
  membersDefault: [],
  totalMembers: 0,
};

const {
  BUNDLED_EMAIL_ELIGIBILITY_FILE,
  BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
  EMAIL_ELIGIBILITY_FILE,
  EMPLOYEE_ID,
} = RestrictBy;

export const membersSlice = createSlice({
  initialState: membersInitialState,
  name: MEMBERS,
  reducers: {
    fetchMembers: (
      state,
      action: {
        payload: boolean;
      },
    ) => {
      state.isFetchingMembers = action.payload;
    },
    removeMembers: (
      state,
      action: {
        payload: {
          memberIds: number[];
          restrictBy: string;
        };
      },
    ) => {
      const isRestricted = [
        BUNDLED_EMAIL_ELIGIBILITY_FILE,
        BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
        EMAIL_ELIGIBILITY_FILE,
        EMPLOYEE_ID,
      ].includes(action.payload.restrictBy as RestrictBy);

      if (isRestricted) {
        state.members = state.members.map((member) => {
          const memberId = member?.id ?? member?.memberId;
          if (memberId && action.payload.memberIds.includes(memberId)) {
            member.status = MEMBER_STATUS.ELIGIBLE;
          }
          return member;
        });
      } else {
        state.members = state.members.filter((member) => {
          const memberId = member?.id ?? member?.memberId;
          return memberId ? !action.payload.memberIds.includes(memberId) : true;
        });
        state.filteredMembers -= action.payload.memberIds.length;
      }
    },
    resetMembers: (state) => {
      state.isFetchingMembers = false;
      state.filteredMembers = 0;
      state.members = [];
      state.membersDefault = [];
      state.totalMembers = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMembersDataThunk.pending, (state) => {
      state.isFetchingMembers = true;
    });
    builder.addCase(getMembersDataThunk.fulfilled, (state, { payload }) => {
      const { data, offset, filters } = payload as PayloadProps;
      const members = [...(offset ? state.members : []), ...data.members];

      state.isFetchingMembers = false;
      state.filteredMembers = data.total;
      state.members = members;
      state.membersDefault = filters ? state.membersDefault : members;
      if (!state.totalMembers) state.totalMembers = data.total;
    });
    builder.addCase(getMembersDataThunk.rejected, (state) => {
      state.isFetchingMembers = false;
    });
  },
});

export const {
  fetchMembers,
  resetMembers,
  removeMembers,
} = membersSlice.actions;

export const getMembersData = (state) => state.members;
