import {
  SetGingerSoleraConnectionErrorAction,
  SetGingerSoleraConnectionFinishedAction,
  SetGingerSoleraConnectionStartedAction,
  SetGingerSoleraInitAction,
  GingerSoleraActionTypes,
} from './gingerSoleraActionTypes';

export const setGingerSoleraConnectionError = (
  errorCode: string | null,
): SetGingerSoleraConnectionErrorAction => {
  return {
    payload: {
      error: {
        code: errorCode,
      },
    },
    type: GingerSoleraActionTypes.SET_GINGER_SOLERA_CONNECTION_ERROR,
  };
};

export const setGingerSoleraConnectionStarted = (): SetGingerSoleraConnectionStartedAction => {
  return {
    type: GingerSoleraActionTypes.SET_GINGER_SOLERA_CONNECTION_STARTED,
  };
};

export const setGingerSoleraConnectionFinished = (): SetGingerSoleraConnectionFinishedAction => {
  return {
    type: GingerSoleraActionTypes.SET_GINGER_SOLERA_CONNECTION_FINISHED,
  };
};

export const setGingerSoleraConnectionInit = (): SetGingerSoleraInitAction => {
  return {
    type: GingerSoleraActionTypes.SET_GINGER_SOLERA_INIT,
  };
};
