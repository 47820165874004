import { FC, useCallback, useState } from 'react';

import { BasicTableCell } from '../../../../../../../shared-components/tables/BasicTable/BasicTableCell';
import { BasicTableRow } from '../../../../../../../shared-components/tables/BasicTable/BasicTableRow';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { makeFriendlyDate } from '../../../../../../../utils/dates';
import { ReportModal } from './ReportModal';
import { HollowButton } from '../../../../../../../shared-components/buttons/HollowButton';
import { EligibilityFile } from '../../../../../../../types/organization';

interface EligibilityFileRowProps {
  eligibilityFile: EligibilityFile;
}

export const EligibiltyFileRow: FC<EligibilityFileRowProps> = ({
  eligibilityFile,
}) => {
  const { translate } = useTranslate();
  const [showModal, setShowModal] = useState(false);
  const {
    createdAt,
    errors,
    filename,
    status,
    errorCsvUrl,
    totalAdded,
    totalProcessed,
    totalRemoved,
  } = eligibilityFile;
  const dateCreated = makeFriendlyDate(createdAt);
  // errors are encoded with a `<br>` to indicate a newline
  const errorList: string[] = errors?.length ? errors?.split('<br>') : [];

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <>
      <BasicTableRow key={createdAt}>
        <BasicTableCell key="filename">{filename}</BasicTableCell>
        <BasicTableCell key="status">
          {translate(
            `organizationSettings.eligibility.processingStatus.${status}`,
          )}
        </BasicTableCell>
        <BasicTableCell key="date">{dateCreated}</BasicTableCell>
        <BasicTableCell key="report">
          <HollowButton
            dataTestId={`view-report-${createdAt}`}
            onClick={openModal}
          >
            {translate('organizationSettings.eligibility.viewReport')}
          </HollowButton>
        </BasicTableCell>
      </BasicTableRow>
      {showModal ? (
        <ReportModal
          handleClose={closeModal}
          status={status}
          errors={errorList}
          errorCsvUrl={errorCsvUrl}
          totalAdded={totalAdded}
          totalRemoved={totalRemoved}
          totalProcessed={totalProcessed}
        />
      ) : null}
    </>
  );
};
