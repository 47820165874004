import { useQuery } from '@tanstack/react-query';
import { getVerifyDependentInviteToken } from '../rest-calls/getVerifyDependentInviteToken';

export const VERIFY_DEPENDENT_QUERY_KEY = 'verify_dependent_token';

export const useGetVerifyDependentInviteToken = (token: string) => {
  return useQuery(
    [VERIFY_DEPENDENT_QUERY_KEY],
    async () => getVerifyDependentInviteToken(token),
    {
      enabled: !!token,
      retry: 1,
    },
  );
};
