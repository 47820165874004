import { ActivityContractAttributes } from './contractSagas/activityContractSaga';
import { CommunityContractAttributes } from './contractSagas/communityContractSaga';
import { ExperimentContractAttributes } from './contractSagas/expirimentContractSaga';
import { ScreenViewContractAttributes } from './contractSagas/screenViewContractSaga';
import { ContractTypes, MParticleEventType } from './types';

export enum analyticEventActionsTypes {
  MPARTICLE_EVENT = '@analytics/MPARTICLE_EVENT',
}

export interface ExtraAttributes
  extends Partial<ActivityContractAttributes>,
    Partial<ScreenViewContractAttributes>,
    Partial<ExperimentContractAttributes>,
    Partial<CommunityContractAttributes> {}

export interface AnalyticAttributes {
  eventName: string;
  eventType?: MParticleEventType;
  extras?: ExtraAttributes; // used to pass direct data to Contract Sagas
  contractList: ContractTypes[]; // list of which contracts to use
  callback?: () => void; // any callback function that needs to be performed after the tracking is called
}

export interface AnalyticAction {
  type: analyticEventActionsTypes.MPARTICLE_EVENT;
  payload: AnalyticAttributes;
}

export const mParticleEventAction = ({
  eventName,
  eventType,
  contractList,
  extras,
  callback,
}: AnalyticAttributes): AnalyticAction => {
  return {
    payload: {
      callback,
      contractList,
      eventName,
      eventType,
      extras,
    },
    type: analyticEventActionsTypes.MPARTICLE_EVENT,
  };
};
