import axios, { AxiosPromise } from 'axios';
import * as Sentry from '@sentry/browser';
import { getB2BOrgIdUrl } from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import { responseType, HTTPMethod } from '../../utils/constants';
import { OrganizationAdministratorProps } from '../../state/types/organization';

export const createAdmin = ({
  orgId,
  ...options
}): AxiosPromise<OrganizationAdministratorProps> =>
  axios({
    method: HTTPMethod.POST,
    url: getB2BOrgIdUrl(orgId).ADMINS,
    data: options,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  }).catch((e) => {
    Sentry.addBreadcrumb({
      message: `Failed in createAdmin with Error:${e}`,
    });
    return e;
  });

export const editAdmin = ({
  orgId,
  id,
  ...options
}): AxiosPromise<OrganizationAdministratorProps> =>
  axios({
    method: HTTPMethod.PATCH,
    url: `${getB2BOrgIdUrl(orgId).ADMINS}/${id}`,
    data: options,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  }).catch((e) => {
    Sentry.addBreadcrumb({
      message: `Failed in editAdmin with Error:${e}`,
    });
    return e;
  });

export const deleteAdmin = ({
  orgId,
  id,
}): AxiosPromise<OrganizationAdministratorProps> => {
  return axios({
    method: HTTPMethod.DELETE,
    url: `${getB2BOrgIdUrl(orgId).ADMINS}/${id}`,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  }).catch((e) => {
    Sentry.addBreadcrumb({
      message: `Failed in deleteAdmin with Error:${e}`,
    });
    return e;
  });
};
