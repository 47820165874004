import * as React from 'react';
import add from 'assets/plus.svg';
import { Orange, White } from '@headspace/web-ui-components';
import { IconButtonPronounced } from '../../../../../../../shared-components/buttons/IconButtonPronounced';
import { panelEditStyle, panelHeadingStyle } from '../../styles';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { PanelTitle } from './PanelTitle';
import { PanelSubtitle } from './PanelSubtitle';

export interface PanelHeaderProps {
  view: boolean;
  title: string;
  subtitle?: string;
  displayEditButton?: boolean;
  useEditCta?: boolean;
  editCtaLabel?: string;
  handleToggleView: () => void;
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({
  view,
  title,
  subtitle,
  useEditCta,
  displayEditButton,
  editCtaLabel,
  handleToggleView,
}) => {
  const { translate } = useTranslate();

  return (
    <div css={panelHeadingStyle}>
      <div>
        <PanelTitle>{title}</PanelTitle>
        {subtitle ? <PanelSubtitle>{subtitle}</PanelSubtitle> : null}
      </div>
      {view && displayEditButton ? (
        <div
          className="panelEdit"
          data-testid="panelEdit"
          onClick={handleToggleView}
          aria-hidden="true"
          role="button"
          css={panelEditStyle}
        >
          {useEditCta && editCtaLabel ? (
            <IconButtonPronounced
              src={add}
              color={Orange[200]}
              backgroundColor={Orange[200]}
              fill={White['000']}
              label={editCtaLabel}
            />
          ) : (
            translate('edit')
          )}
        </div>
      ) : null}
    </div>
  );
};
