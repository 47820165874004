import { useQuery } from '@tanstack/react-query';
import { orgAPIAxios } from '../../../rest/axios';
import {
  responseType,
  HTTPMethod,
  GINGER_WEB_API,
} from '../../../utils/constants';
import { createAuthorizationHeader } from '../../../utils/auth';

export const importChildOrgsKey = 'importChildOrgs';

const importChildOrgs = async (gingerOrgId) => {
  const { data } = await orgAPIAxios({
    method: HTTPMethod.GET,
    url: `${GINGER_WEB_API.url}/headspace_for_work/org/${gingerOrgId}/children`,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });
  return data;
};

export const useImportChildOrgs = (gingerOrgId) =>
  useQuery([importChildOrgsKey], async () => importChildOrgs(gingerOrgId), {
    enabled: false,
  });
