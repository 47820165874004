import * as React from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../state/types';
import * as UserSelectors from '../state/selectors/userSelectors';
import { AUTH_REDIRECT_PARAMS } from '../auth/Auth';
import { dashboardRoutes } from './Routes';

export interface PrivateRouteReduxStateProps {
  isLoggingIn?: boolean;
  isCsm?: boolean;
}

export interface PrivateRouteReduxDispatchProps {}

export interface PrivateRouteOwnProps {
  restrictToCsm?: boolean;
}

export type PrivateRouteProps = PrivateRouteReduxStateProps &
  PrivateRouteOwnProps &
  RouteProps;

export const PrivateRouteComponent = ({
  isLoggingIn,
  isCsm,
  restrictToCsm = false,
  children,
}: PrivateRouteProps) => {
  const { LOGIN_PATH, ADMIN_ORGS_PATH, ORGS_PATH } = dashboardRoutes;
  const url = new URL(window.location.href);
  const redirectUrl = [LOGIN_PATH, ADMIN_ORGS_PATH, ORGS_PATH].includes(
    url.pathname,
  )
    ? LOGIN_PATH
    : `${LOGIN_PATH}?${AUTH_REDIRECT_PARAMS.REDIRECT_URL}=${url}`;

  const restricted = restrictToCsm && !isCsm;
  const renderRoute = isLoggingIn && !restricted;
  return renderRoute ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectUrl} replace={true} />
  );
};

const mapStateToProps = (state: RootState): PrivateRouteReduxStateProps => ({
  isCsm: UserSelectors.isCSM(state),
  isLoggingIn: UserSelectors.isLoggingIn(state),
});

export const PrivateRoute = connect<
  PrivateRouteReduxStateProps,
  PrivateRouteReduxDispatchProps,
  PrivateRouteOwnProps,
  RootState
>(mapStateToProps)(PrivateRouteComponent);
