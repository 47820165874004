export const useGetGeolocation = (
  onSuccess: PositionCallback,
  onError: PositionErrorCallback,
) => {
  const options = {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 60000,
  };

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        onSuccess(position);
      },
      onError,
      options,
    );
  }
};
