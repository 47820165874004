import { FC, useCallback, useEffect, useRef } from 'react';
import { toCanvas } from 'qrcode';
import { css, Interpolation } from '@emotion/core';
import {
  borderRadiusM,
  borderWidthS,
  fontFamilyCss,
  bodyMCss,
  WarmGrey,
} from '@headspace/web-ui-components';
import { visuallyHidden } from '../apps/dashboard/styles';
import { buttonResets, resets } from './text';

const canvasStyle = css({
  border: `${borderWidthS} solid ${WarmGrey[200]}`,
  borderRadius: borderRadiusM,
});
const downloadStyle = css(fontFamilyCss as Interpolation, {
  color: WarmGrey[700],
});
const wrapperStyle = css({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
});

const handleError = (error) => {
  if (error) console.error(error);
};

export interface QRCodeProps {
  data: string;
  filename?: string;
  id: string;
  isDownloadable?: boolean;
  margin?: number;
  scale?: number;
}

export const QRCode: FC<QRCodeProps> = ({
  data,
  id,
  filename,
  isDownloadable,
  margin,
  scale,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const aRef = useRef<HTMLAnchorElement>(null);
  const cleanFilename = filename
    ? `${filename.replace(/[^a-zA-Z0-9]/gi, '_').toLowerCase()}-qrcode.png`
    : 'qrcode.png';
  const handleDownload = useCallback(() => {
    if (!canvasRef?.current) {
      console.error('no canvas', canvasRef);
      return;
    }
    if (!aRef?.current) {
      console.error('no a tag', aRef);
      return;
    }
    let dt = canvasRef.current.toDataURL('image/png');
    dt = dt.replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
    dt = dt.replace(
      /^data:application\/octet-stream/,
      `data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=${cleanFilename}`,
    );
    aRef.current.href = dt;
    aRef.current.click();
  }, [data, filename, aRef, canvasRef]);
  useEffect(() => {
    if (canvasRef?.current) {
      toCanvas(canvasRef.current, data, { margin, scale }, handleError);
    }
  }, [data, canvasRef, margin, scale]);

  return (
    <div data-testid={`qrcode-${id}`} css={wrapperStyle}>
      <canvas css={canvasStyle} id={`qrcode-${id}`} ref={canvasRef} />

      {isDownloadable ? (
        <>
          <button
            type="button"
            css={[resets, buttonResets, bodyMCss, downloadStyle]}
            onClick={handleDownload}
            aria-label="Download QRCode"
          >
            <p>Download QRCode</p>
          </button>
          <a css={visuallyHidden} href="/" download={cleanFilename} ref={aRef}>
            Download
          </a>
        </>
      ) : null}
    </div>
  );
};
