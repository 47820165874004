import { css } from '@emotion/core';
import {
  CoolGrey,
  fontSizeM,
  fontSizeS,
  fontSizeXL,
  fontSizeXS,
  fontWeightRegular,
  letterSpacingWider,
  lineHeightHeading,
  Orange,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { bold } from '../../../styles/fonts';
import {
  alignContentFlexStart,
  alignItemsCenter,
  justifyContentSpaceBetween,
  rowFlex,
} from '../../../styles/flex';

export const labelStyle = css({
  fontSize: fontSizeXS,
  lineHeight: lineHeightHeading,
  margin: `${SpacingInRemBySize.L} 0 ${SpacingInRemBySize.XS}`,
});

export const panelStyle = css({
  fontSize: fontSizeM,
  padding: `${SpacingInRemBySize.XL} ${SpacingInRemBySize['2XL']}`,
  position: 'relative',
});

export const panelHeadingStyle = css(
  rowFlex,
  alignItemsCenter,
  justifyContentSpaceBetween,
);

export const panelHeadingTitleStyle = css(bold, {
  color: CoolGrey[500],
  fontSize: fontSizeXL,
});

export const panelTwoColumnStyle = css(
  rowFlex,
  alignContentFlexStart,
  justifyContentSpaceBetween,
  {
    '> div': {
      ':last-of-type': {
        marginRight: 0,
      },
      flex: 1,
      marginRight: SpacingInRemBySize['2XL'],
    },
  },
);

export const panelHeadingSubtitleStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeXS,
  fontWeight: fontWeightRegular,
  lineHeight: lineHeightHeading,
  marginTop: SpacingInRemBySize.XS,
});

export const panelEditStyle = css(bold, justifyContentSpaceBetween, {
  color: Orange[200],
  cursor: 'pointer',
  fontSize: fontSizeS,
  letterSpacing: letterSpacingWider,
  textTransform: 'uppercase',
});
