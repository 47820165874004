import { call, select, put } from 'redux-saga/effects';
import { PaymentType } from '@headspace/web-payments';
import { ModalActionsTypes } from '../../../../../state/types/modal';

import { postPayment, linkMemberAfterPayment } from '../../../rest-calls';
import { ErrorResponse, parseError } from '../../../../../utils/parseError';
import { logSentryRequestError } from '../../../../../utils/sentryHelpers';
import {
  getFirstName,
  getHsUserId,
  getJWT,
  getLastName,
} from '../../selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  getSlug,
  getVoucher,
} from '../../selectors/orgSectionSelector/orgSectionSelector';
import { getTaxMode } from '../../selectors/countryCodeSelector/countryCodeSelector';
import { router } from '../../../../../root-component/router';

const { OPEN_MODAL } = ModalActionsTypes;

export function* completePaymentSaga(paymentType: PaymentType) {
  switch (paymentType) {
    case PaymentType.ZUORA:
      return yield call(completeZuoraPaymentSaga);
    case PaymentType.PAYPAL:
      return yield call(completePaypalPaymentSaga);
    default:
      // Unsupported payment type
      return yield call(paymentFailure);
  }
}

export function* processPayment() {
  const hsUserId = yield select(getHsUserId);
  const token = yield select(getJWT);
  const voucher = yield select(getVoucher);
  const taxMode = yield select(getTaxMode);
  const firstName = yield select(getFirstName);
  const lastName = yield select(getLastName);
  const slug = yield select(getSlug);

  const paymentData = {
    userId: hsUserId,
    taxMode,
    voucherCode: voucher,
    upgrade: false,
  };

  try {
    yield call(postPayment, token, paymentData);
    yield call(linkMemberAfterPayment, token, slug);

    router.navigate(`/${slug}/member-enroll/processed-payment`);
  } catch (e) {
    const { message } = parseError(e as ErrorResponse);
    yield put({
      type: OPEN_MODAL,
      payload: {
        title: `Hey ${firstName} ${lastName},`,
        message,
      },
    });
    logSentryRequestError(
      e,
      'processPaymentSaga -> postPayment -> linkMemberAfterPayment',
    );
  }
}

export function* completeZuoraPaymentSaga() {
  const firstName = yield select(getFirstName);
  const lastName = yield select(getLastName);

  try {
    const response = yield call(processPayment);
    yield response;
  } catch (e) {
    const { message } = parseError(e as ErrorResponse);
    yield put({
      type: OPEN_MODAL,
      payload: {
        title: `Hey ${firstName} ${lastName},`,
        message,
      },
    });
    logSentryRequestError(e, 'completeZuoraPaymentSaga');
  }
}

export function* completePaypalPaymentSaga() {
  try {
    const response = yield call(processPayment);
    yield response;
  } catch (e) {
    const { message } = parseError(e as ErrorResponse);
    yield put({ type: OPEN_MODAL, payload: { message } });
  }
}

// Failure when unable to load supported payment type
export function* paymentFailure() {
  yield put({
    type: OPEN_MODAL,
    payload: { message: 'Could not process that payment type' },
  });
}
