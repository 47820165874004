import umdOnboardingTerribleEmotion from 'assets/umd-onboarding-slider-terrible-emotion.png';
import umdOnboardingBadEmotion from 'assets/umd-onboarding-slider-bad-emotion.png';
import umdOnboardingOkayEmotion from 'assets/umd-onboarding-slider-okay-emotion.png';
import umdOnboardingGreatEmotion from 'assets/umd-onboarding-slider-great-emotion.png';
import umdOnboardingAmazingEmotion from 'assets/umd-onboarding-slider-amazing-emotion.png';
import umdOnboardingBeMindful from 'assets/umd-onboarding-be-mindful.png';
import umdOnboardingFeelCalmAndRelax from 'assets/umd-onboarding-feel-calm-and-relax.png';
import umdOnboardingManageAnxiety from 'assets/umd-onboarding-manage-anxiety.png';
import umdOnboardingManageDepression from 'assets/umd-onboarding-manage-depression.png';
import umdOnboardingProcessGrief from 'assets/umd-onboarding-process-grief.png';
import umdOnboardingReduceStress from 'assets/umd-onboarding-reduce-stress.png';
import umdOnboardingSleepBetter from 'assets/umd-onboarding-sleep-better.png';
import umdOnboardingIdentityBelonging from 'assets/umd-onboarding-identity-belonging.png';
import umdOnboardingIncreaseSelfEsteem from 'assets/umd-onboarding-increase-self-esteem.png';
import umdOnboardingManageRelationships from 'assets/umd-onboarding-manage-relationships.png';
import umdOnboardingPersonalGoals from 'assets/umd-onboarding-personal-goals.png';
import umdOnboardingReframeBodyImage from 'assets/umd-onboarding-reframe-body-image.png';
import umdOnboardingSomethingElse from 'assets/umd-onboarding-something-else.png';
import umdOnboardingFamily from 'assets/umd-onboarding-family.png';
import umdOnboardingCareer from 'assets/umd-onboarding-career.png';
import umdOnboardingSchool from 'assets/umd-onboarding-school.png';
import umdOnboardingSocialLife from 'assets/umd-onboarding-social-life.png';
import umdOnboardingPhysicalHealth from 'assets/umd-onboarding-physical-health.png';
import umdOnboardingHobbies from 'assets/umd-onboarding-hobbies.png';
import umdOnboardingPersonalFinances from 'assets/umd-onboarding-personal-finances.png';

export const SLIDER_STEPS = [
  {
    emotion: 'Terrible',
    icon: umdOnboardingTerribleEmotion,
  },
  {
    emotion: 'Bad',
    icon: umdOnboardingBadEmotion,
  },
  {
    emotion: 'Okay',
    icon: umdOnboardingOkayEmotion,
  },
  {
    emotion: 'Great',
    icon: umdOnboardingGreatEmotion,
  },
  {
    emotion: 'Amazing',
    icon: umdOnboardingAmazingEmotion,
  },
];

export const SLIDER_INITIAL_STEP = 2;

export const CHOICE_ICONS = {
  'choice-mood-0': umdOnboardingTerribleEmotion,
  'choice-mood-1': umdOnboardingBadEmotion,
  'choice-mood-2': umdOnboardingOkayEmotion,
  'choice-mood-3': umdOnboardingGreatEmotion,
  'choice-mood-4': umdOnboardingAmazingEmotion,
  'choice-help-0': umdOnboardingBeMindful,
  'choice-help-1': umdOnboardingSleepBetter,
  'choice-help-2': umdOnboardingReduceStress,
  'choice-help-3': umdOnboardingManageAnxiety,
  'choice-help-4': umdOnboardingManageDepression,
  'choice-help-5': umdOnboardingProcessGrief,
  'choice-help-6': umdOnboardingFeelCalmAndRelax,
  'choice-help-7': umdOnboardingManageRelationships,
  'choice-help-8': umdOnboardingIncreaseSelfEsteem,
  'choice-help-9': umdOnboardingReframeBodyImage,
  'choice-help-10': umdOnboardingIdentityBelonging,
  'choice-help-11': umdOnboardingSomethingElse,
  'choice-importance-0': umdOnboardingBeMindful,
  'choice-importance-1': umdOnboardingSleepBetter,
  'choice-importance-2': umdOnboardingReduceStress,
  'choice-importance-3': umdOnboardingManageAnxiety,
  'choice-importance-4': umdOnboardingManageDepression,
  'choice-importance-5': umdOnboardingProcessGrief,
  'choice-importance-6': umdOnboardingFeelCalmAndRelax,
  'choice-importance-7': umdOnboardingManageRelationships,
  'choice-importance-8': umdOnboardingIncreaseSelfEsteem,
  'choice-importance-9': umdOnboardingReframeBodyImage,
  'choice-importance-10': umdOnboardingIdentityBelonging,
  'choice-importance-11': umdOnboardingSomethingElse,
  'choice-life_impact-0': umdOnboardingFamily,
  'choice-life_impact-1': umdOnboardingCareer,
  'choice-life_impact-2': umdOnboardingSchool,
  'choice-life_impact-3': umdOnboardingSocialLife,
  'choice-life_impact-4': umdOnboardingPhysicalHealth,
  'choice-life_impact-5': umdOnboardingHobbies,
  'choice-life_impact-6': umdOnboardingPersonalFinances,
  'choice-life_impact-7': umdOnboardingPersonalGoals,
  'choice-life_impact-8': umdOnboardingSomethingElse,
};

export const TEXT_REPRESENTATION_SINGLE_CHOICE_IDS = ['question-4-feel'];
