import { FC } from 'react';
import { TitleL, UIRegularL } from '../../../shared-components/text';
import { useTranslate } from '../../../hooks/useTranslate';
import {
  marginBottomXsmall,
  linkStyles,
  centerAlign,
  titleWrapper,
  paddingMedium,
} from './styles';
import { bold } from '../styles/fonts';

export const LoginHeader: FC = () => {
  const { translate } = useTranslate();
  return (
    <div css={titleWrapper}>
      <TitleL css={[marginBottomXsmall, centerAlign]} dataTestId="login-title">
        {translate('login.title')}
      </TitleL>
      <UIRegularL css={centerAlign} dataTestId="login-not-admin">
        <span>
          {translate('login.noAdmin')}
          <a
            href="https://www.headspace.com/login"
            css={[linkStyles, bold]}
            data-testid="login-not-admin-cta"
          >
            {translate('login.headspaceLogin')}
          </a>
        </span>
      </UIRegularL>
    </div>
  );
};

export const Loading: FC = () => {
  const { translate } = useTranslate();
  return <UIRegularL css={paddingMedium}>{translate('login.wait')}</UIRegularL>;
};
