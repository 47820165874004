import * as React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { SerializedStyles } from '@emotion/react';
import { format, parse as parseDate } from '../../utils/dates';
import { DateInputNavBar } from './DateInputNavbar';
import { DateInputOverlay } from './DateInputOverlay';
import { dayPickerInputStyle } from './styles';

export interface DateInputProps {
  onChange(event: any): void;
  id: string;
  value: string;
  inputError: { error: boolean; message: string };
  disabled?: boolean;
  hasError: boolean;
  placeholder?: string;
  customInputStyles: SerializedStyles;
  format?: string;
}

const formatDate = format as (
  date: Date,
  format: string,
  locale: string,
) => string;

export const DateInput: React.SFC<DateInputProps> = ({
  hasError,
  disabled = false,
  customInputStyles,
  ...passedProps
}) => {
  const { id, value, onChange, format = 'MM/DD/YYYY' } = passedProps;

  return (
    <div css={dayPickerInputStyle}>
      <DayPickerInput
        formatDate={formatDate}
        format={format}
        placeholder={format}
        parseDate={(input: string): Date => {
          if (Date.parse(input)) {
            return parseDate(input);
          }

          return parseDate(new Date());
        }}
        overlayComponent={DateInputOverlay}
        value={value || ''}
        onDayChange={(day: any, modifiers: any, input: any) => {
          const inputDay =
            input.getInput().value.length && input.getInput().value;
          const thisDay = inputDay || day;
          if (thisDay) {
            onChange({ target: { id, value: thisDay } });
          }
        }}
        inputProps={{
          autoComplete: 'off',
          readOnly: false,
          ...passedProps,
          disabled,
          id,
        }}
        dayPickerProps={{
          captionElement: (
            {
              /* We're intercepting some dom noise on passed props here */
            },
          ) => <span />,
          navbarElement: <DateInputNavBar />,
          showOutsideDays: true,
          weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        }}
      />
    </div>
  );
};
