import { FC, memo, useCallback } from 'react';
import umdOnboardingRadiobutton from 'assets/umd-onboarding-radiobutton.png';
import { useSelector } from 'react-redux';
import { Choice, QuestionId } from '../../../../../models/OnboardingQuestions';
import {
  stubRadioButtonStyle,
  textCheckMarkIconStyle,
  textChoiceContainerStyle,
  textChoiceLabelTextStyle,
  textChoicesContainerStyle,
  textLabelContainerStyle,
} from '../styles/textChoiceStyle';
import { choicesContainerStyle } from '../styles/choiceCardStyle';
import { getLocalizedAnswerText } from '../utils/localization';
import { RootState } from '../../../../../../../state/types';
import { getQuestionsAnswer } from '../../../../../state/slice/onboarding/onboardingSelector';
import { getLanguage } from '../../../../../state/selectors/languageSelector/languageSelector';

interface OnboardingSingleChoiceTextProps {
  choices: Choice[];
  questionId: QuestionId;
  title: string;
  onSelectAnswer: (questionId: string, title: string, id: string) => void;
}

interface OnboardingSingleChoiceTextRadioButtonProps {
  text: string;
  id: string;
  handleSelect: (id) => void;
  isSelected: boolean;
}

const OnboardingSingleChoiceTextRadioButton = memo(
  ({
    text,
    id,
    handleSelect,
    isSelected,
  }: OnboardingSingleChoiceTextRadioButtonProps) => {
    const handleClick = () => handleSelect(id);

    return (
      <button
        css={textChoiceContainerStyle(isSelected)}
        onClick={handleClick}
        type="button"
        role="radio"
        aria-checked={isSelected}
        data-testid="onboarding-single-choice-text"
      >
        <label htmlFor={text} css={textLabelContainerStyle}>
          <div>
            <span css={textChoiceLabelTextStyle}>{text}</span>
          </div>
          <div css={stubRadioButtonStyle}>
            <img
              src={umdOnboardingRadiobutton}
              css={textCheckMarkIconStyle(isSelected)}
              alt={text}
            />
          </div>
        </label>
      </button>
    );
  },
);

export const OnboardingSingleChoiceText: FC<OnboardingSingleChoiceTextProps> = ({
  choices,
  title,
  questionId,
  onSelectAnswer,
}) => {
  const answers = useSelector((state: RootState) =>
    getQuestionsAnswer(state, questionId),
  ) as string[];
  const language = useSelector(getLanguage);

  const handleSelect = useCallback((id) => {
    onSelectAnswer(questionId, title, id);
  }, []);

  return (
    <div
      css={[choicesContainerStyle, textChoicesContainerStyle]}
      data-testid="onboarding-single-choice-text-container"
    >
      {choices.map((choice) => {
        const { id, text } = choice;

        return (
          <OnboardingSingleChoiceTextRadioButton
            text={getLocalizedAnswerText(choice, language)}
            key={id}
            id={id}
            isSelected={answers.includes(text)}
            handleSelect={handleSelect}
          />
        );
      })}
    </div>
  );
};
