import { FC } from 'react';
import { Dropdown, DropdownParams } from './Dropdown';
import { dropdownItemStyle, dropdownLinkStyle } from './styles';

export interface LinkDropdownProps extends DropdownParams {
  links: { name: string; url: string }[];
}

export const LinkDropdown: FC<LinkDropdownProps> = ({ links, ...rest }) => (
  <Dropdown {...rest} minContentWidth={226}>
    {links.map((link) => (
      <div css={dropdownItemStyle} key={link.name}>
        <a href={link.url} css={dropdownLinkStyle}>
          {link.name}
        </a>
      </div>
    ))}
  </Dropdown>
);
