import { css } from '@emotion/react';
import { AuthConnections } from '@headspace/web-auth';
import { Blue, fontSizeS } from '@headspace/web-ui-components';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { accountLinkingScreenViewAction } from '../../../../../analytics/events/enrollmentEvents';
import { HealthProvider } from '../../../constants/healthProvider';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { Routes } from '../../../constants/routes';
import { getHsUserId } from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import PreLinkComponent from '../../HealthHubViews/VirginPulse/LinkPage/PreLinkComponent';
import { VPCareWrapper } from '../../HealthHubViews/VirginPulse/VPCareWrapper/VPCareWrapper';

const linkComponentStyleOverride = css({
  'button[data-testid="connect-virginPulse-auth-button"]': {
    backgroundColor: `${Blue[200]} !important`,
  },
  'div[data-testid="manage-connected-identities-description"]': {
    fontSize: fontSizeS,
    textAlign: 'center',
  },
  'div[data-testid="link-identities-container-card"]': {
    paddingTop: 0,
  },
});

export const VPCareConsent: FC = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const HS_USER_ID = useSelector(getHsUserId);

  useEffect(() => {
    if (!HS_USER_ID) {
      navigate(Routes.VP_CARE_LAND);
    }
  }, [HS_USER_ID]);

  const consentDetails = [
    translate('enrollment.vpCareConsent.detail1'),
    translate('enrollment.vpCareConsent.detail2'),
    translate('enrollment.vpCareConsent.detail3'),
    translate('enrollment.vpCareConsent.detail4'),
  ];

  useEffect(() => {
    dispatch(accountLinkingScreenViewAction(HealthProvider.VPCARE));
  }, [dispatch]);

  const connectionButtonOverrides = {
    [AuthConnections.VIRGINPULSE]: {
      connection: AuthConnections.VIRGINPULSE,
      title: translate('ssoEnrollment.connectToVirginPulse.header'),
    },
  };

  return (
    <VPCareWrapper skipMobileHero={true} skipLogout={true}>
      <div css={linkComponentStyleOverride}>
        <PreLinkComponent
          connectButtonOverrides={connectionButtonOverrides}
          overrideManageIdentitiesDescription={translate(
            'enrollment.vpCareConsent.disconnectNotification',
          )}
          consentDetails={consentDetails}
          title={translate('enrollment.vpCareConsent.title')}
          subtitle={translate('enrollment.vpCareConsent.subtitle')}
          description={translate('enrollment.vpCareConsent.description')}
          bigLogo={true}
        />
      </div>
    </VPCareWrapper>
  );
};
