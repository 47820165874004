import { useMutation } from '@tanstack/react-query';
import { sendSurvey } from '../../../../../rest/surveys/survey.api';

export const useSendSurvey = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
) =>
  useMutation({
    mutationFn: ({ orgId, surveyId }: { orgId: number; surveyId: string }) =>
      sendSurvey(orgId, surveyId),
    mutationKey: ['surveys'],
    onError,
    onSuccess,
  });
