import { MiddlewareAPI, Middleware, Dispatch, AnyAction } from 'redux';
import * as Sentry from '@sentry/browser';
import {
  getOrgByAttribute as getOrgByAttributeCall,
  createMemberByEmail,
  deactivateMembers,
} from '../../rest';

import * as organizationActions from '../actions/organizationActions';
import { Action } from '../types';
import {
  OrganizationActionsTypes,
  GetOrgByIdAction,
  GetOrgByAttributeAction,
  AddMemberByEmailAction,
} from '../types/organization';

import { logSentryRequestError } from '../../utils/sentryHelpers';
import { getOrganizationById } from '../../rest/organizations';
import { fetchMembers, removeMembers } from '../slices/members/membersSlice';
import { ApiErrors } from '../../utils/parseError';
import { Organization } from '../../types/organization';
import { router } from '../../root-component/router';

export type Next = (action: Action) => void;

export interface OrganizationQueryResult {
  data: {
    org: Organization;
  };
}

export const getOrgById = (store: any) => (next: Next) => (
  action: GetOrgByIdAction,
) => {
  const { payload } = action;
  if (action.type !== OrganizationActionsTypes.GET_ORG_BY_ID) {
    return next(action);
  }

  Sentry.addBreadcrumb({
    category: 'getOrgById',
    message: `actionPayload: {id: ${payload}}`,
    level: Sentry.Severity.Info,
  });
  return getOrganizationById(payload)
    .then((org: Organization) => {
      store.dispatch(organizationActions.selectOrganization(org));
    })
    .catch((error: ApiErrors) => {
      Sentry.addBreadcrumb({
        message: `getOrgById -> queryOrg failed with ${error}`,
      });
      throw error.response || error;
    });
};

export const getOrgByAttribute: Middleware = (store) => (next) => (
  action: GetOrgByAttributeAction | AnyAction,
) => {
  if (action.type !== OrganizationActionsTypes.GET_ORG_BY_ATTRIBUTE) {
    return next(action);
  }
  const { attributeName, attributeValue } = action.payload;

  Sentry.addBreadcrumb({
    category: 'getOrgByAttribute',
    message: `actionPayload: ${JSON.stringify(action.payload)}`,
    level: Sentry.Severity.Info,
  });

  return getOrgByAttributeCall(attributeName, attributeValue)
    .then((result) => {
      store.dispatch(
        organizationActions.selectOrganization(result.data.data.org),
      );
    })
    .catch((error: ApiErrors) => {
      logSentryRequestError(error, 'getOrgByAttributeCall');
      router.navigate('/404');
    });
};

export const removeMembersWithScroll = ({
  dispatch,
  getState,
}: // eslint-disable-next-line consistent-return
MiddlewareAPI) => (next: Dispatch) => async (action) => {
  if (action.type !== OrganizationActionsTypes.REMOVE_MEMBERS_WITH_SCROLL) {
    return next(action);
  }
  const {
    payload: { params },
  } = action;
  const { orgId, memberIds, options } = params;
  const { restrictBy } = getState().manageOrganization;
  Sentry.addBreadcrumb({
    category: 'removeMembers',
    message: `orgId: ${orgId} memberIds:${memberIds}`,
    level: Sentry.Severity.Info,
  });

  dispatch(fetchMembers(true));

  try {
    await deactivateMembers({ orgId, memberIds });

    dispatch(removeMembers({ memberIds, restrictBy }));
    dispatch(fetchMembers(false));
  } catch (error) {
    dispatch(fetchMembers(false));
    Sentry.addBreadcrumb({
      message: 'Failed deleteOrDeactivateMembers thunk',
    });
    logSentryRequestError(
      error,
      'removeMembers -> deleteOrDeactivateMembers thunk',
    );
  }
};

export const addMemberByEmail = (store: any) => (next: any) => (
  action: AddMemberByEmailAction,
) => {
  const { payload } = action;
  if (action.type !== OrganizationActionsTypes.ADD_MEMBER_BY_EMAIL) {
    return next(action);
  }

  Sentry.addBreadcrumb({
    category: 'addMemberByEmail',
    message: `{ slug: ${payload.slug}, email: ${payload.email} }`,
    level: Sentry.Severity.Info,
  });

  return createMemberByEmail({ slug: payload.slug, email: payload.email })
    .then((result: any) => {
      if (result) {
        store.dispatch(organizationActions.memberEnrollment(result));
      }
    })
    .catch((error: ApiErrors) => {
      console.error(error);
      Sentry.addBreadcrumb({
        message: 'Failed in addMemberByEmail -> createMemberByEmail thunk',
      });
      throw error.response.data.errors[0] || error;
    });
};
