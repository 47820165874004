import {
  Divider,
  fontSizeM,
  letterSpacingWider,
  lineHeightHeading,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { css } from '@emotion/react';
import {
  formContainerStyle,
  rowStyle,
} from '../styles/createOrganizationStyles';
import { useTranslate } from '../../../../hooks/useTranslate';
import { Input } from '../../../../shared-components/Input';
import { EAP_PROVIDERS } from '../../../../types/organization';
import { EAP_SERVICE_TYPES } from '../../constants';
import { getOrgManagement } from '../../../../state/selectors/organizationSelectors';
import {
  handleChange as handleChangeAction,
  updateImplicitState,
} from '../../../../state/actions/organizationActions';

const serviceLabelStyles = css({
  color: WarmGrey['800'],
  fontSize: fontSizeM,
  letterSpacing: letterSpacingWider,
  lineHeight: lineHeightHeading,
  margin: `${SpacingInRemBySize.M} 0 0`,
  textTransform: 'uppercase',
});

const dividerStyles = css({
  marginTop: SpacingInRemBySize.XL,
});

const TRANSLATION_KEY = 'organizationSettings.supplementalDetails.';
export const SupplementalDetails = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const {
    externalOrgId,
    externalOrgIdError,
    phoneNumber,
    phoneNumberError,
    provider,
    serviceType,
    coverageTotal,
    coverageTotalError,
  } = useSelector(getOrgManagement);

  const handleChange = useCallback(
    (event: ExtendedEvent) => dispatch(handleChangeAction(event)),
    [dispatch],
  );

  const handleError = useCallback(
    (event: ExtendedEvent) => dispatch(updateImplicitState(event)),
    [dispatch],
  );

  const eapTitle = translate(`${TRANSLATION_KEY}eap.title`);
  const clinicalServicesTitle = translate(
    `${TRANSLATION_KEY}clinicalServices.title`,
  );
  const externalOrgIdLabel = translate(
    `${TRANSLATION_KEY}eap.externalOrgIdLabel`,
  );
  const externalOrgIdPlaceholder = translate(
    `${TRANSLATION_KEY}eap.externalOrgIdPlaceholder`,
  );
  const phoneNumberLabel = translate(`${TRANSLATION_KEY}eap.phoneNumberLabel`);
  const providerLabel = translate(
    `${TRANSLATION_KEY}clinicalServices.provider`,
  );
  const serviceTypeLabel = translate(
    `${TRANSLATION_KEY}clinicalServices.serviceType`,
  );
  const serviceTypeTherapy = translate(
    `${TRANSLATION_KEY}clinicalServices.serviceType.therapy`,
  );
  const serviceTypePsychiatry = translate(
    `${TRANSLATION_KEY}clinicalServices.serviceType.psychiatry`,
  );
  const serviceTypePsychiatryTherapy = translate(
    `${TRANSLATION_KEY}clinicalServices.serviceType.psychiatryTherapy`,
  );
  const coverageTotalLabel = translate(
    'organizationSettings.basic.coverageTotalLabel',
  );

  return (
    <div data-testid="supplemental-details-page" css={formContainerStyle}>
      <div data-testid="eap-section">
        <h3 css={serviceLabelStyles}>{eapTitle}</h3>
        <div css={rowStyle}>
          <Input
            type="text"
            name="externalOrgId"
            label={externalOrgIdLabel}
            placeholder={externalOrgIdPlaceholder}
            onChange={handleChange}
            onBlur={handleError}
            value={externalOrgId}
            hasError={externalOrgIdError.error}
            errorMessage={externalOrgIdError.message}
            tabIndex="0"
          />
          <Input
            type="text"
            name="phoneNumber"
            label={phoneNumberLabel}
            placeholder="999-999-9999"
            onChange={handleChange}
            onBlur={handleError}
            value={phoneNumber}
            hasError={phoneNumberError.error}
            errorMessage={phoneNumberError.message}
            tabIndex="0"
          />
        </div>
      </div>
      <Divider css={dividerStyles} />
      <div data-testid="clinical-services-section">
        <h3 css={serviceLabelStyles}>{clinicalServicesTitle}</h3>
        <div css={rowStyle}>
          <Input
            type="select"
            name="serviceType"
            label={serviceTypeLabel}
            onChange={handleChange}
            onBlur={handleError}
            value={serviceType}
            tabIndex="0"
            options={[
              {
                title: serviceTypeTherapy,
                value: EAP_SERVICE_TYPES.THERAPY,
              },
              {
                title: serviceTypePsychiatry,
                value: EAP_SERVICE_TYPES.PSYCHIATRY,
              },
              {
                title: serviceTypePsychiatryTherapy,
                value: EAP_SERVICE_TYPES.PSYCHIATRY_THERAPY,
              },
            ]}
          />
          <Input
            type="select"
            name="provider"
            label={providerLabel}
            onChange={handleChange}
            onBlur={handleError}
            value={provider}
            tabIndex="0"
            options={[
              {
                title: EAP_PROVIDERS.CCA,
                value: EAP_PROVIDERS.CCA,
              },
              {
                title: EAP_PROVIDERS.WPO,
                value: EAP_PROVIDERS.WPO,
              },
            ]}
          />
        </div>
        <div css={rowStyle}>
          <Input
            type="text"
            name="coverageTotal"
            placeholder="0"
            label={coverageTotalLabel}
            value={coverageTotal}
            onChange={handleChange}
            onBlur={handleError}
            data-testid="coverageTotal"
            hasError={coverageTotalError.error}
            errorMessage={coverageTotalError.message}
          />
        </div>
      </div>
    </div>
  );
};
