import React from 'react';
import { css } from '@emotion/react';
import { WarmGrey, rem } from '@headspace/web-ui-components';
import { useTranslate } from '../../../../hooks/useTranslate';
import {
  alignItemsCenter,
  columnFlex,
  justifyContentCenter,
} from '../../../dashboard/styles/flex';

const joinMessageStyle = css(
  columnFlex,
  justifyContentCenter,
  alignItemsCenter,
  {
    color: WarmGrey[500],
    width: rem(16.875),
    height: '40vh',
    textAlign: 'center',
  },
);

export const RegistrationClosed: React.FC = () => {
  const { translate } = useTranslate();

  return (
    <div css={joinMessageStyle}>
      <h1 data-testid="registration-closed">
        {translate('registrationClosed.header')}
      </h1>
      <h5>{translate('registrationClosed.subHeader')}</h5>
    </div>
  );
};
