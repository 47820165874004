import * as R from 'ramda';
import { TextLink } from '@headspace/web-ui-components';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { Card } from './Card';
import { uniqueKey } from '../../../../../../utils/keyCounter';

import {
  sectionStyle,
  titleStyle,
  informationText,
  cardRow,
} from '../styles/sectionStyle';
import { regular } from '../../../../styles/fonts';

export interface SectionProps {
  children?: any;
  assets: any[];
  title?: string;
  message: string;
}

const TextOrLink = (message: string = '') => {
  const A_TAG_START = '<a href="';
  const END_TAG = '">';
  const A_TAG_END = '</a>';

  const TOOLKIT_URL_WHITELIST = [
    'www.headspace.com',
    'hal.headspace.com',
    'headspace.highspot.com',
  ];
  if (
    message.includes(A_TAG_START) &&
    message.includes(A_TAG_END) &&
    TOOLKIT_URL_WHITELIST.some((url) => message.includes(url))
  ) {
    const url = message.split(A_TAG_START)?.pop()?.split(A_TAG_END)[0] ?? '';
    const aTagStartIndex = message.indexOf(A_TAG_START);
    const aTagEndIndex = message.indexOf(A_TAG_END);
    const endTagIndex = url.indexOf(END_TAG);
    const messageStart = message.substring(0, aTagStartIndex);
    const messageEnd = message.substring(aTagEndIndex + 4, message.length);
    const linkText = url.substring(endTagIndex + END_TAG.length);

    let updatedURL = url.split(END_TAG)[0];
    updatedURL = sanitizeUrl(updatedURL);

    return (
      <span>
        {messageStart}
        <TextLink to={updatedURL} isNewWindow={true}>
          {linkText}
        </TextLink>
        {messageEnd}
      </span>
    );
  }
  return message;
};

export const Section = (props: SectionProps) => {
  const { assets, title, message } = props;
  const checkEmptyFields = (v: any) => v === undefined;
  const byTitle = R.prop('title');
  const sortedAssets =
    assets &&
    (R.compose(
      // @ts-ignore
      R.sortBy(byTitle),
      // @ts-ignore
      R.reject(checkEmptyFields),
      R.pluck('fields'),
    )(
      // @ts-ignore
      assets,
    ) as any[]);

  return (
    <div css={sectionStyle}>
      <div css={titleStyle}>{title}</div>
      <p css={[regular, informationText]}>{TextOrLink(message)}</p>
      <div css={cardRow}>
        {assets &&
          sortedAssets.map((item: any) => {
            const i = uniqueKey();
            return (
              <div key={i}>
                <Card
                  title={item.title}
                  information={item.description}
                  imageUrl={item.image.fields.file.url}
                  downloadableAsset={item.downloadableAsset}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
