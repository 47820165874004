import { AnyAction, Reducer } from 'redux';
import { ModalActionsTypes } from '../types/modal';

const INITIAL_STATE = {
  title: '',
  message: '',
  confirmCallback: null,
  isOpen: false,
  navigateTo: null,
  executeConfirm: false,
};

export const modalReducer: Reducer = (
  state = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case ModalActionsTypes.OPEN_MODAL:
      return { ...state, ...action.payload, isOpen: true };
    case ModalActionsTypes.EXECUTE_CONFIRM:
      return { ...state, executeConfirm: true };
    case ModalActionsTypes.CLOSE_MODAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};
