import { reject, equals } from 'ramda';

import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { captureException } from '@sentry/browser';
import { activityContractSaga } from './activityContractSaga';
import { b2bContractSaga } from './b2bContractSaga';
import { Contract, ContractTypes } from '../types';
import { ExtraAttributes } from '../analyticActions';
import { screenViewContractSaga } from './screenViewContractSaga';
import { phiContractSaga } from './phiContractSaga';
import { webContractSaga } from './webContractSaga';
import { experimentContractSaga } from './expirimentContractSaga';
import { communityContractSaga } from './communityContractSaga';

const {
  screenView,
  web,
  b2b,
  activity,
  experiment,
  phi,
  community,
} = ContractTypes;

export interface ContractAggregatorAttributes {
  contractList: ContractTypes[];
  extras?: ExtraAttributes;
}

const contractMap = {
  [activity]: activityContractSaga,
  [b2b]: b2bContractSaga,
  [experiment]: experimentContractSaga,
  [phi]: phiContractSaga,
  [screenView]: screenViewContractSaga,
  [web]: webContractSaga,
  [community]: communityContractSaga,
};

export function* contractDataAggregatorSaga({
  contractList,
  extras,
}: ContractAggregatorAttributes): SagaIterator<Contract> {
  let contractData = {};
  try {
    let contractSagaResult;
    const mapped = contractList.map((key) => contractMap[key]);

    for (let i = 0; i < mapped.length; i += 1) {
      contractSagaResult = yield call(mapped[i], extras);
      const refinedContractSagaResult = reject(
        equals(undefined),
        contractSagaResult,
      );

      contractData = {
        ...contractData,
        ...refinedContractSagaResult,
      };
    }
  } catch (err: any) {
    captureException({
      ...err,
      contractList,
      extras,
      hsName: 'contractDataAggregatorSaga error',
    });
  }
  return contractData;
}
