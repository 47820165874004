import * as React from 'react';
import { css } from '@emotion/react';
import {
  Blue,
  borderRadiusXS,
  fontSizeM,
  Grey,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { useSelector } from 'react-redux';
import { FieldLabel } from '../../../../../../../shared-components/text';
import { Step } from '../../../../../../../shared-components/Steps/Step';
import { STEP_STATUS } from '../../../../../constants';
import { RestrictByUploadButton } from './RestrictByUploadButton';
import { RestrictByMigrationReport } from './RestrictByMigrationReport';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import { selectRestrictByMigration } from '../../../../../../../state/slices/restrictByMigration';
import { getEligibilityFileTemplate } from '../../../../../../../rest/eligibillityFile/eligibilityFile.api';
import { visuallyHidden } from '../../../../../styles';
import {
  alignItemsCenter,
  flex,
  justifyContentSpaceBetween,
} from '../../../../../styles/flex';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

const headerWrapper = css(flex, justifyContentSpaceBetween, alignItemsCenter, {
  marginBottom: SpacingInRemBySize.L,
});

const downloadLinkStyle = css({
  background: 'none',
  border: 'none',
  color: Blue[200],
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: fontSizeM,
  marginTop: SpacingInRemBySize.M,
  padding: '0',
  textDecoration: 'underline',
});

const subtitleLinkStyle = css({
  color: WarmGrey[800],
});

const stepsWrapper = css({
  '& .ctaButton': {
    position: 'absolute',
    right: 40,
  },
});

const completeSubtitleWarningStyle = css({
  background: WarmGrey[200],
  borderRadius: borderRadiusXS,
  color: WarmGrey[800],
  padding: `${SpacingInRemBySize.XS} ${SpacingInRemBySize.M}`,
  width: 'max-content',
});

interface RestrictByMigrationUploadFileStepsProps {
  handleNextStep: () => void;
  steps: Record<string, any>;
  openErrorReportModal: () => void;
  onConfirmButtonClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
    options?: { restrictedDomains?: string[] },
  ) => void;
  onConfirmButtonKeyDown?: (
    event: React.KeyboardEvent<HTMLButtonElement>,
    options?: { restrictedDomains?: string[] },
  ) => void;
}

const DOWNLOAD_TEMPLATE_NAME = 'elf_template.csv';
const SUCCESS_RESPONSE_CODE = 200;

const DownloadTemplateLink: React.FC = () => {
  const { translate } = useTranslate();
  const fileRef = React.useRef<HTMLAnchorElement>(null);

  const handleClick = React.useCallback(async () => {
    const response = await getEligibilityFileTemplate();

    if (
      response.status === SUCCESS_RESPONSE_CODE &&
      fileRef &&
      fileRef.current
    ) {
      fileRef.current.href = URL.createObjectURL(new Blob([response.data]));
      fileRef.current.download = DOWNLOAD_TEMPLATE_NAME;
      fileRef.current.click();
    }
  }, []);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a ref={fileRef} css={visuallyHidden}>
        {translate('restrictByMigrationSteps.steps.step_1.download_template')}
      </a>
      <button css={downloadLinkStyle} onClick={handleClick} type="button">
        {translate('restrictByMigrationSteps.steps.step_1.download_template')}
      </button>
    </>
  );
};

export const RestrictByMigrationUploadFileSteps: React.FC<RestrictByMigrationUploadFileStepsProps> = ({
  steps,
  handleNextStep,
  openErrorReportModal,
  onConfirmButtonClick,
  onConfirmButtonKeyDown,
}) => {
  const { activeStep, elFileReport } = useSelector(selectRestrictByMigration);
  const { translate } = useTranslate();

  return (
    <div>
      <div css={headerWrapper}>
        <FieldLabel>
          {translate('restrictByMigrationSteps.steps.uploadFile.header')}
        </FieldLabel>
      </div>
      <div css={stepsWrapper}>
        <Step
          activeChildren={<DownloadTemplateLink />}
          showCTA={false}
          handleNextStep={handleNextStep}
          showDivider={activeStep > 2}
          showSubtitle={steps[0].status !== STEP_STATUS.COMPLETE}
          status={steps[0].status}
          step={steps[0].step}
          subtitle={
            <span css={subtitleLinkStyle}>
              {translate('restrictByMigrationSteps.steps.step_1.subtitle')}
            </span>
          }
          title={translate('restrictByMigrationSteps.steps.step_1.title')}
        >
          {steps[0].status === STEP_STATUS.ACTIVE ? (
            <RestrictByUploadButton
              text={translate(
                'restrictByMigrationSteps.steps.step_1.upload_file',
              )}
              onUpload={handleNextStep}
            />
          ) : null}
        </Step>
        <Step
          activeChildren={
            <RestrictByMigrationReport
              elFileReport={elFileReport}
              openErrorReportModal={openErrorReportModal}
            />
          }
          ctaText={translate('restrictByMigrationSteps.steps.step_2.next_step')}
          showCTA={true}
          handleNextStep={handleNextStep}
          showDivider={activeStep === 1 || activeStep > 3}
          showSubtitle={steps[1].status !== STEP_STATUS.COMPLETE}
          status={steps[1].status}
          step={steps[1].step}
          subtitle={
            <span css={subtitleLinkStyle}>
              {translate('restrictByMigrationSteps.steps.step_2.subtitle')}
            </span>
          }
          title={translate('restrictByMigrationSteps.steps.step_2.title')}
        >
          {steps[1].status === STEP_STATUS.ACTIVE ? (
            <RestrictByUploadButton
              text={translate(
                'restrictByMigrationSteps.steps.step_2.upload_file',
              )}
              className="ctaButton"
            />
          ) : null}
        </Step>
        <Step
          showCTA={false}
          handleNextStep={handleNextStep}
          showDivider={false}
          showSubtitle={steps[2].status !== STEP_STATUS.COMPLETE}
          status={steps[2].status}
          step={steps[2].step}
          subtitle={
            <span css={subtitleLinkStyle}>
              {translate('restrictByMigrationSteps.steps.step_3.subtitle')}
            </span>
          }
          completeSubtitle={
            <>
              <div>
                {translate(
                  'restrictByMigrationSteps.steps.step_3.completeSubtitle.message',
                )}
              </div>
              <div>
                <p css={completeSubtitleWarningStyle}>
                  {translate(
                    'restrictByMigrationSteps.steps.step_3.completeSubtitle.warning',
                  )}
                </p>
              </div>
            </>
          }
          title={translate('restrictByMigrationSteps.steps.step_3.title')}
        >
          {steps[2].status === STEP_STATUS.ACTIVE ? (
            <label htmlFor="confirm-button">
              <TextButton
                dataTestId="confirm-button"
                id="confirm-button"
                value={translate(
                  'restrictByMigrationSteps.steps.step_3.confirm_button_text',
                )}
                onClick={onConfirmButtonClick}
                onKeyDown={onConfirmButtonKeyDown}
              />
            </label>
          ) : null}
        </Step>
      </div>
    </div>
  );
};
