import { Divider } from '@headspace/web-ui-components';
import happyIcon from '../../../../../../assets/umd-skip-onboarding-module-happy.png';
import nextStepsIcon from '../../../../../../assets/umd-skip-onboarding-module-nextSteps.svg';
import meditationIcon from '../../../../../../assets/umd-skip-onboarding-module-meditation.svg';
import { dividerCss, moduleContainerCss, moduleHeaderCss } from '../styles';
import { NextStepsSection, ButtonType } from './NextStepsSection';
import { headspaceHubRoutes } from '../../../../../headspaceHub/constants/routes';
import { useTranslate } from '../../../../../../hooks/useTranslate';

export const NextStepsModule = () => {
  const { translate } = useTranslate();
  const title = translate('enrollment.skipOnboarding.nextStepsPage.title');
  return (
    <div css={moduleContainerCss} data-testid="next-step-module">
      <div css={moduleHeaderCss}>
        <img src={happyIcon} alt="module-icon" />
        <h2>{title}</h2>
      </div>
      <NextStepsSection
        translateKey="enrollment.skipOnboarding.nextStepsPage.headspaceHub.section"
        buttonType={ButtonType.Primary}
        imgSrc={nextStepsIcon}
        buttonLink={headspaceHubRoutes.BENEFITS_PATH}
      />
      <Divider css={dividerCss} />
      <NextStepsSection
        translateKey="enrollment.skipOnboarding.nextStepsPage.headspaceApp.section"
        buttonType={ButtonType.Tertiary}
        imgSrc={meditationIcon}
      />
    </div>
  );
};
