import * as R from 'ramda';
import { RootState } from '../../../../../state/types';
import { parseError } from '../../../../../utils/parseError';

export const getVerifyStatus = (state: RootState) =>
  R.pathOr(null, ['emailVerificationToken', 'data', 'status'], state);

export const getEmailVerificationToken = (state: RootState) =>
  R.pathOr(null, ['emailVerificationToken', 'verificationToken'], state);

type GetEmailVerificationError = (
  state: RootState,
) => ReturnType<typeof parseError> | null;

export const getEmailVerificationError: GetEmailVerificationError = (state) =>
  R.pathOr(null, ['emailVerificationToken', 'error'], state);

export const getEmailVerificationData = (state: RootState) =>
  R.pathOr(null, ['emailVerificationToken', 'data'], state);
