import { ConfirmModal, rem } from '@headspace/web-ui-components';
import { FC, ReactNode, useCallback, useRef } from 'react';
import { css } from '@emotion/react';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { visuallyHidden } from '../../../../../styles';
import { getErrorCsv } from '../../../../../../../rest/eligibillityFile/eligibilityFile.api';

const rowStyle = css({
  '> strong': {
    paddingRight: rem(0.25),
    whiteSpace: 'nowrap',
  },
  display: 'flex',
});

const modalStyle = css({
  maxWidth: rem(47),
});

interface ErrorRowProps {
  label: string;
  children?: ReactNode;
}

const ErrorRow: FC<ErrorRowProps> = ({ label, children }) => (
  <div css={rowStyle}>
    <strong>{label}</strong>
    <div>{children}</div>
  </div>
);

interface ErrorReportModalProps {
  errorCsvUrl: string | null;
  totalProcessed?: number;
  totalRemoved?: number;
  totalAdded?: number;
  status: string;
  errors: string[];
  handleClose: () => void;
}

export const ReportModal: FC<ErrorReportModalProps> = ({
  errorCsvUrl,
  totalProcessed,
  totalRemoved,
  totalAdded,
  status,
  errors,
  handleClose,
}) => {
  const { translate } = useTranslate();
  const fileRef = useRef<HTMLAnchorElement>(null);

  // errorCsvUrl takes the form of elf-record-errors.<env>.headspace.com/{orgId}/{fileName}?Expires=UNIX_TIMESTAMP
  let showErrorCsvUrl = false;
  const matches = errorCsvUrl?.match(/Expires=(\d+)/);
  if (matches) {
    const expiresDate = new Date(parseInt(matches[1], 10) * 1000);
    showErrorCsvUrl = new Date() < expiresDate;
  }

  const handleDownload = useCallback(async () => {
    const response = await getErrorCsv(errorCsvUrl);

    if (response.status === 200 && fileRef?.current) {
      fileRef.current.href = URL.createObjectURL(new Blob([response.data]));
      fileRef.current.download = 'error-report.csv';
      fileRef.current.click();
    }
  }, [errorCsvUrl]);

  const header = (
    <div>
      {translate('organizationSettings.eligibility.reportModal.header')}
    </div>
  );
  const [firstError, ...restErrors] = errors;
  const modalBody = (
    <div css={modalStyle}>
      {totalProcessed ? (
        <ErrorRow
          label={translate(
            'organizationSettings.eligibility.reportModal.totalProcessed',
          )}
        >
          {totalProcessed}
        </ErrorRow>
      ) : null}
      <ErrorRow
        label={translate('organizationSettings.eligibility.reportModal.status')}
      >
        {status}
      </ErrorRow>
      {errors.length > 0 ? (
        <>
          <ErrorRow
            label={translate(
              'organizationSettings.eligibility.reportModal.message',
            )}
          >
            {firstError}
          </ErrorRow>
          {restErrors.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </>
      ) : null}
      {totalAdded ? (
        <ErrorRow
          label={translate(
            'organizationSettings.eligibility.reportModal.totalAdded',
          )}
        >
          {totalAdded}
        </ErrorRow>
      ) : null}
      {totalRemoved ? (
        <ErrorRow
          label={translate(
            'organizationSettings.eligibility.reportModal.totalRemoved',
          )}
        >
          {totalRemoved}
        </ErrorRow>
      ) : null}
      <a ref={fileRef} css={visuallyHidden} href="/">
        {translate('download')}
      </a>
    </div>
  );

  return (
    <ConfirmModal
      body={modalBody}
      confirmButtonLabel={translate(
        'organizationSettings.eligibility.reportModal.downloadReport',
      )}
      onConfirm={handleDownload}
      onDismiss={handleClose}
      lockParentScroll={true}
      header={header}
      showConfirmButton={showErrorCsvUrl}
      showDismissButton={false}
    />
  );
};
