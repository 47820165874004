import { createAsyncThunk } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { ErrorResponse, parseError } from '../../../../utils/parseError';
import { openSettingsModal } from '../../settingsModal';
import { logSentryRequestError } from '../../../../utils/sentryHelpers';
import {
  buildMemberSearchQuery,
  MembersSearchQuery,
  MembersSearchResult,
  searchMembers,
} from '../../../../rest';
import { RootState } from '../../../types';
import { MEMBERS } from '../constants';

interface GetMembersThunkProps {
  options: MembersOptionsType;
  orgId: number;
  queryParams?: {
    [key: string]: string;
  };
}

export type MembersOptionsType = {
  search?: string | null;
  status?: string[];
  offset?: number;
  sort: { field: string; direction: string };
};

export const getMembersDataThunk = createAsyncThunk(
  `${MEMBERS}/getMembersDataThunk`,
  async (
    { orgId, options, queryParams }: GetMembersThunkProps,
    { dispatch, getState },
  ) => {
    try {
      const { membersDefault } = (getState() as RootState).members;
      let fetchFunc: Promise<any>;

      if (
        R.isEmpty(options) &&
        membersDefault.length &&
        !queryParams?.fetchPolicy
      ) {
        const value: MembersSearchResult = {
          members: membersDefault,
          total: membersDefault.length,
        };
        fetchFunc = Promise.resolve(value);
      } else {
        const query: MembersSearchQuery = buildMemberSearchQuery(
          orgId,
          options,
        );
        fetchFunc = searchMembers(query);
      }

      const data = await fetchFunc.then((data) => data);

      return { data, ...options };
    } catch (error) {
      const { message, title = '' } = parseError(error as ErrorResponse);
      dispatch(openSettingsModal({ message, title }));

      logSentryRequestError(error, 'getMembersData');
      throw error;
    }
  },
);
