import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AdminOrganizationsTableView } from '../apps/dashboard/AdminOrganizationsTableView';
import { ChallengesCreate } from '../apps/dashboard/ChallengesCreateView';
import { OrganizationSetup } from '../apps/dashboard/CreateOrganizationView';
import { CreateSuperAdministrator } from '../apps/dashboard/CreateSuperAdministratorView';
import { Login } from '../apps/dashboard/LoginView';
import { Organization } from '../apps/dashboard/OrganizationView';
import { OrganizationsTableView } from '../apps/dashboard/OrganizationsTableView';
import { HeadspaceHubRoutes } from '../apps/headspaceHub/HeadspaceHubRoutes';
import {
  RedirectJoinToMemberEnroll,
  V2MemberEnrollmentRoutes,
} from '../apps/memberEnrollmentV2';
import { Routes as RoutesConstants } from '../apps/memberEnrollmentV2/constants/routes';
import AuthCallback from '../apps/memberEnrollmentV2/views/AuthComponent/AuthCallback';
import AuthLogoutCallback from '../apps/memberEnrollmentV2/views/AuthComponent/AuthLogoutCallback';
import { DownloadGingerPage } from '../apps/memberEnrollmentV2/views/GingerBundlePage/DownloadGingerPage';
import { GingerBundlePage } from '../apps/memberEnrollmentV2/views/GingerBundlePage/GingerBundlePage';
import GingerSoleraErrorPage from '../apps/memberEnrollmentV2/views/GingerSolera/GingerSoleraErrorPage';
import { GingerSoleraLandingPage } from '../apps/memberEnrollmentV2/views/GingerSolera/GingerSoleraLandingPage';
import GingerSoleraSuccessPage from '../apps/memberEnrollmentV2/views/GingerSolera/GingerSoleraSuccessPage';
import HealthProviderErrorPage from '../apps/memberEnrollmentV2/views/HealthHubViews/HealthProvider/HealthProviderErrorPage';
import { HealthProviderLandingPage } from '../apps/memberEnrollmentV2/views/HealthHubViews/HealthProvider/HealthProviderLandingPage';
import HealthProviderSuccessPage from '../apps/memberEnrollmentV2/views/HealthHubViews/HealthProvider/HealthProviderSuccessPage';
import SoleraErrorPage from '../apps/memberEnrollmentV2/views/HealthHubViews/Solera/SoleraErrorPage';
import { SoleraFinishPage } from '../apps/memberEnrollmentV2/views/HealthHubViews/Solera/SoleraFinishPage';
import { LandingPage } from '../apps/memberEnrollmentV2/views/HealthHubViews/VirginPulse/LandingPage/LandingPage';
import { LinkPage } from '../apps/memberEnrollmentV2/views/HealthHubViews/VirginPulse/LinkPage/LinkPage';
import { SelectOrgPage } from '../apps/memberEnrollmentV2/views/HealthHubViews/VirginPulse/SelectOrgPage/SelectOrgPage';
import { RedirectToAppStore } from '../apps/memberEnrollmentV2/views/RedirectToAppStore/RedirectToAppStore';
import { VPCareConsent } from '../apps/memberEnrollmentV2/views/VPCare/Components/VPCareConsent';
import { PrivateRoute } from './PrivateRoute';

import PageNotFound from '../apps/dashboard/PageNotFoundView';
import { IdentityVerificationErrorPage } from '../apps/memberEnrollmentV2/views/UnifiedEligibilityViews/ExceptionPages/IdentityVerificationErrorPage';
import { UnifiedEligibilityRoutes } from '../apps/memberEnrollmentV2/views/UnifiedEligibilityViews/UnifiedEligibilityRoutes';
import { VPCareLandingPage } from '../apps/memberEnrollmentV2/views/VPCare';
import env from '../config/env';
import { VPCareConfirmation } from '../apps/memberEnrollmentV2/views/VPCare/Components/VPCareConfirmation';
import { headspaceHubRoutes } from '../apps/headspaceHub/constants/routes';

const isSoleraEnabled = env.FEATURE_FLAGS.includes('SOLERA_ENABLED');

export const dashboardRoutes = {
  ADMIN_ORGS_PATH: '/admin/orgs',
  CREATE_CHALLENGES_PATH: '/orgs/:id/create-challenge',
  CREATE_CSM_PATH: '/create-csm',
  CREATE_ORG_PATH: '/create-org',
  LOGIN_PATH: '/login',
  LOGOUT_PATH: '/logout',
  ORGS_PATH: '/orgs',
  ORG_PATH: '/orgs/:id/*',
  REDIRECT_TO_APP_STORE: '/redirect-to-app-store',
};

const {
  CREATE_CSM_PATH,
  CREATE_ORG_PATH,
  ADMIN_ORGS_PATH,
  ORGS_PATH,
  ORG_PATH,
  CREATE_CHALLENGES_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  REDIRECT_TO_APP_STORE,
} = dashboardRoutes;

export const MainRoutes: React.FC = () => (
  <Routes>
    <Route
      path={CREATE_ORG_PATH}
      element={
        <PrivateRoute restrictToCsm={true}>
          <OrganizationSetup />
        </PrivateRoute>
      }
    />
    <Route
      path={CREATE_CSM_PATH}
      element={
        <PrivateRoute restrictToCsm={true}>
          <CreateSuperAdministrator />
        </PrivateRoute>
      }
    />
    <Route
      path={CREATE_CHALLENGES_PATH}
      element={
        <PrivateRoute restrictToCsm={true}>
          <ChallengesCreate />
        </PrivateRoute>
      }
    />
    <Route
      path={ORG_PATH}
      element={
        <PrivateRoute>
          <Organization />
        </PrivateRoute>
      }
    />
    <Route
      path={ORGS_PATH}
      element={
        <PrivateRoute restrictToCsm={true}>
          <OrganizationsTableView />
        </PrivateRoute>
      }
    />
    <Route
      path={ADMIN_ORGS_PATH}
      element={
        <PrivateRoute>
          <AdminOrganizationsTableView />
        </PrivateRoute>
      }
    />
    <Route path={LOGIN_PATH} element={<Login />} />
    <Route path={LOGOUT_PATH} element={<Login loggingOut={true} />} />
    <Route
      path={RoutesConstants.VP_CARE_LAND}
      element={<VPCareLandingPage />}
    />
    <Route
      path={RoutesConstants.VP_CARE_ERROR}
      element={<IdentityVerificationErrorPage />}
    />
    <Route path={RoutesConstants.VP_CARE_CONSENT} element={<VPCareConsent />} />
    <Route
      path={RoutesConstants.VP_CARE_CONFIRMATION}
      element={<VPCareConfirmation />}
    />
    <Route path="/vp/land" element={<LandingPage />} />
    <Route path="/vp/link" element={<LinkPage />} />
    <Route path="/vp/select-org" element={<SelectOrgPage />} />
    {isSoleraEnabled && (
      <Route path="/solera/land/finish" element={<SoleraFinishPage />} />
    )}
    {isSoleraEnabled && (
      <Route path="/solera/land/error" element={<SoleraErrorPage />} />
    )}
    {isSoleraEnabled &&
      [
        RoutesConstants.GINGER_SOLERA_LAND,
        RoutesConstants.HEADSPACE_CARE_SOLERA_LAND,
      ].map((path) => (
        <Route path={path} element={<GingerSoleraLandingPage />} key={path} />
      ))}
    {isSoleraEnabled &&
      [
        RoutesConstants.GINGER_SOLERA_FINISH,
        RoutesConstants.HEADSPACE_CARE_SOLERA_FINISH,
      ].map((path) => (
        <Route path={path} element={<GingerSoleraSuccessPage />} key={path} />
      ))}
    {isSoleraEnabled &&
      [
        RoutesConstants.GINGER_SOLERA_ERROR,
        RoutesConstants.HEADSPACE_CARE_SOLERA_ERROR,
      ].map((path) => (
        <Route path={path} element={<GingerSoleraErrorPage />} key={path} />
      ))}
    <Route
      path="/:healthProvider/land"
      element={<HealthProviderLandingPage />}
    />
    <Route
      path="/:healthProvider/land/error"
      element={<HealthProviderErrorPage />}
    />
    <Route
      path="/:healthProvider/land/finish"
      element={<HealthProviderSuccessPage />}
    />
    <Route path="/callback" element={<Login oauthCallback={true} />} />
    <Route path="/auth" element={<AuthCallback />} />
    <Route path="/member-enroll/callback" element={<AuthLogoutCallback />} />
    <Route
      path="/:slugOrHeadspaceHealthId/join"
      element={<RedirectJoinToMemberEnroll />}
    />
    <Route path="/:slug/bundled" element={<GingerBundlePage />} />
    <Route path="/:slug/ginger-download" element={<DownloadGingerPage />} />
    <Route
      path="/:slugOrHeadspaceHealthId/member-enroll/*"
      element={<V2MemberEnrollmentRoutes />}
    />
    <Route
      path="/v3/:slugOrHeadspaceHealthId/member-enroll/*"
      element={<UnifiedEligibilityRoutes />}
    />
    <Route
      path={`${REDIRECT_TO_APP_STORE}/:app`}
      element={<RedirectToAppStore />}
    />
    <Route path={REDIRECT_TO_APP_STORE} element={<RedirectToAppStore />} />
    <Route
      path="/"
      element={
        <PrivateRoute restrictToCsm={true}>
          <OrganizationsTableView />
        </PrivateRoute>
      }
    />
    <Route
      path={`${headspaceHubRoutes.BENEFITS_PATH}/*`}
      element={<HeadspaceHubRoutes />}
    />
    <Route path="*" element={<Navigate to="/404" replace={true} />} />
    <Route path="/404" element={<PageNotFound />} />
  </Routes>
);
