import { createIntl } from 'react-intl';
import { dashboardMessages } from '../dashboardMessages';

const [locale] = navigator.language.split('-');

const intl = createIntl({
  locale,
  messages: dashboardMessages[locale],
});

// Since useTranslate() is a hook, we cannot use it outside of react component. This function allows
// us to use the react-intl translations outside of react components.
export const translate = (id) => {
  return intl.formatMessage({
    id,
  });
};
