import { getCookie, setCookie } from '@headspace/web-auth/dist/utils/cookie';
import { CookieKeys } from '../../../../constants';
import { SupportedLanguageCode } from '../../../../../../types/language';
import { getConfig } from '../../../../../../config';

const supportedLanguages = Object.values(SupportedLanguageCode);

const setLanguageCode = (): string => {
  const langCookie = getCookie(CookieKeys.LANGUAGE);
  const cookieConfiguration = getConfig().ENROLLMENT_AUTH0_COOKIE
    .COOKIE_CONFIGURATION;
  const cookieLangCode = langCookie
    ? getCookie(CookieKeys.LANGUAGE).split('-')[0]
    : null;
  // Browser language setting for IE and other browsers
  const BROWSER_LANGUAGE_PREFERENCE =
    // @ts-ignore
    window.navigator.userLanguage || window.navigator.language;
  const [browserLanguageCode] = BROWSER_LANGUAGE_PREFERENCE.split('-');

  if (langCookie && supportedLanguages.includes(cookieLangCode)) {
    setCookie(CookieKeys.LANGUAGE, cookieLangCode, null, cookieConfiguration);
    return langCookie;
  }
  if (supportedLanguages.includes(browserLanguageCode)) {
    setCookie(
      CookieKeys.LANGUAGE,
      SupportedLanguageCode.en,
      null,
      cookieConfiguration,
    );
    return browserLanguageCode;
  }

  setCookie(
    CookieKeys.LANGUAGE,
    SupportedLanguageCode.en,
    null,
    cookieConfiguration,
  );
  return SupportedLanguageCode.en;
};

const languageCode = setLanguageCode();

// removes language subregion to properly map to our supported languages eg. es-ar -> es
export const [LANG_CODE_NO_SUBREGION] = languageCode.split('-');
