import { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { requestDeauthenticate } from '@headspace/web-auth';
import { TurnOffAutoRenew } from './components/TurnOffAutoRenew';
import { TurnedOffMessage } from './components/TurnedOffMessage';
import { containerStyle } from './components/styles';
import { ZUORA } from '../../constants';
import {
  getCompanyName,
  getVoucher,
} from '../../state/selectors/orgSectionSelector/orgSectionSelector';
import { getExternalSource } from '../../state/selectors/memberSubscriptionDetailsSelector/memberSubscriptionDetailsSelector';

const TurnOffAutoPay: FC = () => {
  const companyName = useSelector(getCompanyName);
  const externalSource = useSelector(getExternalSource);
  const partialPayVoucher = useSelector(getVoucher);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { slugOrHeadspaceHealthId } = useParams<{
    slugOrHeadspaceHealthId: string;
  }>();

  const handleVerify = () => {
    if (partialPayVoucher) {
      navigate(`/${slugOrHeadspaceHealthId}/member-enroll/payment`);
    }
    navigate(`/${slugOrHeadspaceHealthId}/member-enroll/finish`);
  };

  return (
    <div css={containerStyle}>
      {externalSource === ZUORA ? (
        <TurnedOffMessage
          companyName={companyName}
          handleVerify={handleVerify}
        />
      ) : (
        <TurnOffAutoRenew
          partialPayVoucher={partialPayVoucher}
          requestDeauthenticate={() => dispatch(requestDeauthenticate)}
          companyName={companyName}
        />
      )}
    </div>
  );
};

export default TurnOffAutoPay;
