import type { FC } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { ChildRestrictedInput } from '../../../../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import { handleChange as handleChangeThunk } from '../../../../../../../state/actions/organizationActions';
import { handleNullString } from '../../../../../../../state/helpers';
import {
  getOrgManagement,
  useChangeOfEnrollmentWithHierarchy,
} from '../../../../../../../state/selectors/organizationSelectors';
import { RestrictBy } from '../../../../../../../types/organization';
import {
  PRIMARY_PRODUCT,
  TranslationPath,
  locale,
} from '../../../../../constants';
import { isContractFusionOrUnified } from '../../../../../utils/manage-organization';

interface RestrictBySelectComponentProps {
  view: boolean;
  isCSM?: boolean;
}

export const RestrictBySelect: FC<RestrictBySelectComponentProps> = ({
  view,
  isCSM = false,
}) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const {
    cachedOrg,
    current_contract_primary_product,
    gingerId,
    gingerIdError,
    restrictBy,
  } = useSelector(getOrgManagement);
  const canChangeEnrollment = useSelector(useChangeOfEnrollmentWithHierarchy);
  const restrictByTextVal = translate(
    `${locale}${handleNullString(restrictBy) || RestrictBy.UNRESTRICTED}`,
  );

  const enrollmentRestrictedBy = cachedOrg?.enrollment.restrictBy;
  const shouldDisplayEditMode = !view && isCSM;
  const showGingerBundling = gingerId?.length > 0 && !gingerIdError.error;
  const options = [
    {
      disabled:
        (enrollmentRestrictedBy !== RestrictBy.UNRESTRICTED &&
          enrollmentRestrictedBy !== RestrictBy.EMAIL_DOMAIN &&
          enrollmentRestrictedBy !== RestrictBy.EMPLOYEE_ID) ||
        (canChangeEnrollment
          ? false
          : current_contract_primary_product === PRIMARY_PRODUCT.UNIFIED),

      title: translate(TranslationPath.EMAIL_ELIGIBILITY_FILE),
      value: RestrictBy.EMAIL_ELIGIBILITY_FILE,
    },
    {
      disabled:
        (enrollmentRestrictedBy !== RestrictBy.UNRESTRICTED &&
          enrollmentRestrictedBy !== RestrictBy.EMAIL_DOMAIN &&
          enrollmentRestrictedBy !== RestrictBy.EMAIL_ELIGIBILITY_FILE) ||
        (canChangeEnrollment
          ? false
          : current_contract_primary_product === PRIMARY_PRODUCT.UNIFIED),

      title: translate(TranslationPath.EMPLOYEE_ID),
      value: RestrictBy.EMPLOYEE_ID,
    },
    {
      disabled:
        (enrollmentRestrictedBy !== RestrictBy.EMAIL_ELIGIBILITY_FILE &&
          enrollmentRestrictedBy !== RestrictBy.UNRESTRICTED) ||
        isContractFusionOrUnified(current_contract_primary_product),
      title: translate(TranslationPath.EMAIL_DOMAIN),
      value: RestrictBy.EMAIL_DOMAIN,
    },
    {
      disabled: true,
      title: translate(TranslationPath.UNRESTRICTED),
      value: RestrictBy.UNRESTRICTED,
    },
    {
      disabled: true,
      title: translate(TranslationPath.SSO),
      value: RestrictBy.SSO,
    },
  ];

  // Show the bundled restrictBy types if bundling is available or already selected
  if (
    showGingerBundling ||
    enrollmentRestrictedBy === RestrictBy.BUNDLED_EMAIL_ELIGIBILITY_FILE ||
    enrollmentRestrictedBy === RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE
  ) {
    options.push(
      {
        disabled:
          enrollmentRestrictedBy !==
            RestrictBy.BUNDLED_EMAIL_ELIGIBILITY_FILE &&
          enrollmentRestrictedBy !== RestrictBy.EMAIL_ELIGIBILITY_FILE,
        title: translate(TranslationPath.BUNDLED_EMAIL_ELIGIBILITY_FILE),
        value: RestrictBy.BUNDLED_EMAIL_ELIGIBILITY_FILE,
      },
      {
        disabled:
          enrollmentRestrictedBy !==
            RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE &&
          enrollmentRestrictedBy !== RestrictBy.EMPLOYEE_ID,
        title: translate(TranslationPath.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE),
        value: RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
      },
    );
  }

  const handleChange = useCallback(
    (event) => {
      dispatch(handleChangeThunk(event));
    },
    [dispatch],
  );

  return (
    <ChildRestrictedInput
      name="restrictBy"
      type={shouldDisplayEditMode ? 'select' : 'review'}
      label={translate('organizationSettings.enrollment.restrictByLabel')}
      value={shouldDisplayEditMode ? restrictBy : restrictByTextVal}
      data-testid="restrict-by-dropdown"
      disabled={restrictBy === RestrictBy.SSO}
      onChange={handleChange}
      options={options}
    />
  );
};
