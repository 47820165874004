import * as React from 'react';
import { Spinner } from '@headspace/web-ui-components';
import { SurveyTable } from './SurveyTable/SurveyTable';
import { CreateNewSurveySection } from './CreateNewSurveySection';
import {
  Survey,
  uuid,
} from '../../../../../../state/models/organization/Survey';
import { containerCentering } from '../../styles/styles';

interface PanelTableSectionPropTypes {
  surveys: Survey[];
  handleSelectSurvey: (selectedSurveyId: uuid) => void;
  totalActiveMembers: number;
  isInflight: boolean;
}

export const PanelTableSection: React.FC<PanelTableSectionPropTypes> = ({
  surveys,
  handleSelectSurvey,
  totalActiveMembers,
  isInflight,
}) => {
  if (isInflight) {
    return (
      <div css={containerCentering}>
        <Spinner />
      </div>
    );
  }

  return surveys.length ? (
    <div>
      <SurveyTable
        surveys={surveys}
        handleSelectSurvey={handleSelectSurvey}
        totalActiveMembers={totalActiveMembers}
      />
      <CreateNewSurveySection />
    </div>
  ) : null;
};
