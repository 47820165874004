import { css } from '@emotion/react';
import {
  fontSizeXL,
  fontWeightBold,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  justifyContentFlexEnd,
  rowFlex,
} from '../../styles/flex';

export const noResultsFoundStyle = css({
  color: WarmGrey[600],
  fontSize: fontSizeXL,
  fontWeight: fontWeightBold,
  paddingTop: rem(4.375),
  textAlign: 'center',
  width: '100%',
});

export const actionsStyle = css(
  rowFlex,
  alignItemsCenter,
  justifyContentFlexEnd,
  {
    '& button, & .dropdown': {
      marginLeft: SpacingInRemBySize.M,
    },
  },
);
