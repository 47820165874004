import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../shared-components/modal';
import {
  CloseModal,
  executeConfirmAction,
} from '../state/actions/modalActions';
import { DeleteCallbackTypes } from '../state/types/modal';
import { useTranslate } from '../hooks/useTranslate';

import { RootState } from '../state/types';

const KEY_REGEX = /(\w+\.)+(\w+)$/;

const {
  CONFIRM_DELETE,
  DELETE_ADMIN,
  DELETE_FUTURE_CONTRACT,
} = DeleteCallbackTypes;

const deleteTypes = [CONFIRM_DELETE, DELETE_ADMIN, DELETE_FUTURE_CONTRACT];

interface ModalHOCProps {
  modalState: {
    isOpen: boolean;
    title: string;
    message: string;
    navigateTo: any;
    confirmCallback: any;
  };
  CloseModal: (event?: React.MouseEvent) => void;
  navigateTo?: string;
  executeConfirmAction: () => void;
}

const ModalHOC: React.FC<ModalHOCProps> = ({
  modalState: { isOpen, title, message, navigateTo, confirmCallback },
  CloseModal,
  executeConfirmAction,
}) => {
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const handleConfirm = () => {
    if (deleteTypes.includes(confirmCallback)) {
      executeConfirmAction();
    }
    if (navigateTo) {
      navigate(navigateTo);
    }

    return CloseModal();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      if (confirmCallback === CONFIRM_DELETE) {
        executeConfirmAction();
        CloseModal();
      }
    }
  };
  window.addEventListener('keypress', handleKeyPress);
  return (
    <div className="app-modal">
      <Modal
        isOpen={isOpen}
        title={KEY_REGEX.test(title) ? translate(title) : title}
        message={KEY_REGEX.test(message) ? translate(message) : message}
        handleCancel={confirmCallback ? CloseModal : null}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    modalState: state.modal,
  };
};

export const ModalWithState = connect(mapStateToProps, {
  CloseModal,
  executeConfirmAction,
})(ModalHOC);
