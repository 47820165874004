import * as React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import {
  Spinner,
  Orange,
  White,
  borderWidthXS,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  CoolGrey,
} from '@headspace/web-ui-components';
import { Icon } from '../Icon';

const defaultColor = CoolGrey[500];

const buttonStyle = ({ color }) =>
  css({
    ':disabled': {
      backgroundColor: 'transparent',
      borderColor: WarmGrey[500],
      color: WarmGrey[500],
      cursor: 'no-drop',
    },
    ':hover': {
      backgroundColor: color,
      color: White['000'],
      cursor: 'pointer',
      fill: White['000'],
      filter: 'brightness(95%)',
      svg: {
        fill: White['000'],
      },
    },
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: `solid ${borderWidthXS} ${color || defaultColor}`,
    borderRadius: rem(0.313),
    boxShadow: '0',
    color: color || defaultColor,
    display: 'flex',
    fill: color,
    height: rem(1.75),
    justifyContent: 'space-around',
    paddingLeft: SpacingInRemBySize.S,
    paddingRight: SpacingInRemBySize.S,
  });

const childrenStyle = css({
  paddingRight: SpacingInRemBySize.S,
});

interface IconContainerPropTypes {
  iconSrc: string;
  isDisabled?: boolean;
  color?: string;
  className?: string;
}

const IconContainer: React.FC<IconContainerPropTypes> = ({
  iconSrc,
  isDisabled,
  color = defaultColor,
  className,
}) => {
  return (
    <div>
      <div css={childrenStyle} className={className}>
        <Icon
          src={iconSrc}
          width={14}
          height={14}
          fill={isDisabled ? WarmGrey[500] : color}
        />
      </div>
    </div>
  );
};

interface HollowButtonPropTypes {
  dataTestId?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  color?: string;
  children: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  icon?: {
    iconSrc: string;
  };
  styleAttributes?: SerializedStyles;
}

export const HollowButton: React.FC<HollowButtonPropTypes> = ({
  onClick,
  dataTestId,
  color,
  children,
  icon,
  isDisabled,
  isLoading,
  styleAttributes,
  className,
  ...dataAttributes
}) => {
  return (
    <button
      type="button"
      data-testid={dataTestId}
      css={buttonStyle({ color })}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      {...dataAttributes}
      className={className}
    >
      {icon && (
        <IconContainer
          iconSrc={icon.iconSrc}
          isDisabled={isDisabled}
          color={color}
        />
      )}
      {isLoading ? <Spinner progressColor={Orange[100]} size={16} /> : children}
    </button>
  );
};
