import { ScreenViewContract } from '../types';

export interface ScreenViewContractAttributes {
  screenName: string;
  screenVariant?: string;
  surveyCadence?: string;
  surveyName?: string;
  partnerName?: string;
}

export function screenViewContractSaga({
  screenName,
  screenVariant,
  surveyName,
  surveyCadence,
  partnerName,
}: ScreenViewContractAttributes): ScreenViewContract {
  const screenAttributes = {
    screen_name: screenName,
    screen_variant: screenVariant,
    screen_view_source: document.referrer,
    ...(partnerName && { partner_name: partnerName }),
  };

  if (surveyName) {
    return {
      survey_cadence: surveyCadence,
      survey_date: Date.now(),
      survey_name: surveyName,
      ...screenAttributes,
    };
  }
  return screenAttributes;
}
