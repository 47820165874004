import React, { useCallback, useEffect, useMemo } from 'react';
import { CloseButton } from '@headspace/web-ui-components';
import { useSelector, useDispatch } from 'react-redux';
import { paramCase } from 'change-case';

import {
  cancelOrganizationCreate,
  navigateTo,
  drain,
} from '../../../state/actions/organizationActions';
import { OrganizationContract } from './ContractPage/OrganizationContract';
import { Details } from './DetailsPage/Details';
import { Administrators } from './AdministratorsPage/Administrators';
import { Enrollment } from './EnrollmentPage/Enrollment';
import { Review } from './ReviewPage/Review';
import { ProgressNav } from './ProgressNav/ProgressNav';
import { Icon } from '../../../shared-components/Icon';
import { TitleL } from '../../../shared-components/text';
import {
  ORG_CREATION_STEPS,
  OrgCreationSteps,
  CHILD_ORG_STEPS,
  PARENT_ORG_STEPS,
  PARENT_ORG_EAP_STEPS,
  PRIMARY_PRODUCT,
  CHILD_ORG_EAP_STEPS,
} from '../constants';
import {
  getContinueDisabledStatus,
  getOrgManagement,
} from '../../../state/selectors/organizationSelectors';
import hsLogo from '../../../assets/new-logo.svg';
import {
  createOrgContainerStyle,
  createOrgHeaderStyle,
  textHeaderContainerStyle,
  viewBoxContainerStyle,
} from './styles/createOrganizationStyles';
import { OrgHierarchyPage } from './OrgHierarchyPage/OrgHierarchyPage';
import { useTranslate } from '../../../hooks/useTranslate';
import { useUpdateDocumentTitle } from '../../../hooks/useUpdateDocumentTitle';
import { SupplementalDetails } from './SupplementalDetailsPage/SupplementalDetails';
import { EapTypes } from '../../../types/organization';

const getStep = (currentStep: ORG_CREATION_STEPS) => {
  switch (currentStep) {
    case ORG_CREATION_STEPS.BASIC:
      return Details;
    case ORG_CREATION_STEPS.ORG_HIERARCHY:
      return OrgHierarchyPage;
    case ORG_CREATION_STEPS.CONTRACT:
      return OrganizationContract;
    case ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS:
      return SupplementalDetails;
    case ORG_CREATION_STEPS.ADMINISTRATORS:
      return Administrators;
    case ORG_CREATION_STEPS.ENROLLMENT:
      return Enrollment;
    case ORG_CREATION_STEPS.REVIEW:
    default:
      return Review;
  }
};

export const OrganizationSetup: React.FC = () => {
  const {
    currentStep,
    current_contract_primary_product,
    parentOrgId,
    current_contract_add_ons,
    parentOrg,
  } = useSelector(getOrgManagement);
  const continueDisabledStatus = useSelector(getContinueDisabledStatus);
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const Step = getStep(currentStep);
  let steps: OrgCreationSteps;
  const isSupplementalDetailsParentStepEnabled = useMemo(
    () =>
      current_contract_add_ons &&
      current_contract_primary_product === PRIMARY_PRODUCT.FUSION,
    [current_contract_add_ons, current_contract_primary_product],
  );

  const isSupplementalDetailsChildStepEnabled = useMemo(
    () =>
      parentOrg?.contracts[0]?.eapType === EapTypes.EAP_V2 &&
      current_contract_primary_product === PRIMARY_PRODUCT.FUSION,
    [current_contract_primary_product, parentOrg?.contracts],
  );

  if (parentOrgId === null) {
    steps = isSupplementalDetailsParentStepEnabled
      ? PARENT_ORG_EAP_STEPS
      : PARENT_ORG_STEPS;
  } else {
    steps = isSupplementalDetailsChildStepEnabled
      ? CHILD_ORG_EAP_STEPS
      : CHILD_ORG_STEPS;
  }

  useUpdateDocumentTitle('Create Organization');
  useEffect(() => {
    dispatch(drain());
  }, [dispatch]);

  const handleCancel = useCallback(() => dispatch(cancelOrganizationCreate()), [
    dispatch,
  ]);
  const handlePreviousStep = useCallback(() => {
    const { prevStep } = steps[currentStep];
    if (prevStep) {
      dispatch(navigateTo(prevStep));
    } else {
      handleCancel();
    }
  }, [dispatch, currentStep, steps, handleCancel]);
  const handleNextStep = useCallback(() => {
    const { nextStep } = steps[currentStep];
    dispatch(navigateTo(nextStep));
  }, [dispatch, steps, currentStep]);

  const locale = `organizationSettings.${steps[currentStep].name}`;

  return (
    <div css={createOrgContainerStyle}>
      <div css={createOrgHeaderStyle}>
        <Icon width={160} height={34} src={hsLogo} />
        <CloseButton
          onClick={handleCancel}
          data-testid="close-create-org-icon"
        />
      </div>

      <div css={viewBoxContainerStyle}>
        <div css={textHeaderContainerStyle}>
          {steps[currentStep].optional && (
            <span>
              {translate('organizationSettings.optionalStep').toUpperCase()}
            </span>
          )}
          <TitleL
            className="create-org-title"
            dataTestId={`${paramCase(translate(`${locale}.title`))}-title`}
          >
            {translate(`${locale}.title`)}
          </TitleL>
          <span>{translate(`${locale}.instruction`)}</span>
        </div>
        <div>
          <Step />
        </div>
      </div>
      <ProgressNav
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
        currentStep={currentStep}
        steps={steps}
        progressDisabled={continueDisabledStatus}
      />
    </div>
  );
};
