import * as React from 'react';

export interface EditIconProps {
  color: string;
}

export const DownLoadIcon: React.SFC<EditIconProps> = ({ color }) => {
  return (
    <div>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 0H9V12.59L14.29 7.3L15.7 8.71L8.7 15.71C8.51374 15.8947 8.26234 15.9989 8 16C7.7342 16.0015 7.47872 15.8972 7.29 15.71L0.29 8.71L1.71 7.29L7 12.5884V0ZM16 20V18H0V20H16Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
