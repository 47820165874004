import * as React from 'react';
import { FC, useState } from 'react';
import { White, Blue } from '@headspace/web-ui-components';
import {
  buttonContainerStyle,
  buttonDescriptionStyle,
  containerStyle,
  resendButtonStyle,
  subTitleStyle,
} from '../../styles/verificationStyles';
import { TextButton } from '../../../../../../shared-components/buttons/TextButton';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { VerificationMessage } from '../../../shared-components/VerifyBy/VerificationMessage';

interface EligibilityVerifyByEmailResendStepProps {
  email: string;
  errorMessage: string | null;
  handleResendVerification: (event) => void;
}

export const EligibilityVerifyByEmailResendStep: FC<EligibilityVerifyByEmailResendStepProps> = ({
  email,
  errorMessage,
  handleResendVerification,
}) => {
  const [resendSubmitted, setResendSubmitted] = useState(false);
  const { translate } = useTranslate();

  const buttonText = translate('enrollment.emailSent.button');
  const buttonPressedText = translate('enrollment.emailSent.buttonSent');

  const resendVerificationButtonText = resendSubmitted
    ? buttonPressedText
    : buttonText;

  const handleResendClick = (e) => {
    handleResendVerification(e);
    setResendSubmitted(true);
  };

  return (
    <div
      css={containerStyle}
      data-testid="eligibility-verify-by-email-resend-step-container"
    >
      <div css={subTitleStyle}>
        <span>{translate('enrollment.emailSent.message')}</span>
      </div>
      <div>
        <div css={buttonContainerStyle}>
          <span css={buttonDescriptionStyle}>
            {translate('enrollment.emailSent.subHeader')}
          </span>
          <TextButton
            textColor={White['000']}
            hoverColor={Blue[200]}
            backgroundColor={Blue[200]}
            value={resendVerificationButtonText}
            disabled={resendSubmitted}
            pill={true}
            width="100%"
            dataTestId="resend-verification-submit-button"
            onClick={handleResendClick}
            css={resendButtonStyle}
          />
        </div>
        <VerificationMessage errorMessage={errorMessage} verified={false} />
      </div>
    </div>
  );
};
