import * as React from 'react';
import env from '../../../../config/env';
import { HealthProvider } from '../../constants/healthProvider';
import { Routes } from '../../constants/routes';

export function getRedirectUrlForHealthProvider(
  healthProvider?: string,
): string {
  let redirectUrl = `${env.WEBSITE_HOST}/login`;
  if (healthProvider === HealthProvider.VP) {
    redirectUrl = `${env.HOST}${Routes.SSO_LAND}`;
  }
  if (healthProvider === HealthProvider.VPCARE) {
    redirectUrl = `${env.HOST}${Routes.VP_CARE_LAND}`;
  }
  if (healthProvider === HealthProvider.SOLERA) {
    redirectUrl = `${env.WEBSITE_HOST}/login`;
  }

  return redirectUrl;
}

const AuthLogoutCallbackPage: React.FC = () => {
  const orgSlug = window.location.search.substring(1);
  const healthProvider = new URLSearchParams(window.location.search).get(
    'healthProvider',
  );

  let redirectUrl;
  if (healthProvider !== null) {
    redirectUrl = getRedirectUrlForHealthProvider(healthProvider);
  } else if (orgSlug) {
    redirectUrl = `${env.HOST}/${orgSlug}/member-enroll/login`;
  } else {
    redirectUrl = '/login';
  }

  React.useEffect(() => window.location.replace(redirectUrl), [redirectUrl]);

  return <div data-testid="auth-logout-callback-page" />;
};

export default AuthLogoutCallbackPage;
