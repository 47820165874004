import * as React from 'react';
import { useSelector } from 'react-redux';
import { ReactElement } from 'react';
import { headspaceHubRoutes } from './constants/routes';
import { selectHSUserJWT } from './state/selectors/authenticationSelectors';
import { isValidJwtToken } from '../../auth/Auth';

export const PrivateHubRoute = ({ children }): ReactElement | null => {
  const userToken: string = useSelector(selectHSUserJWT);
  const isTokenValid: boolean = isValidJwtToken(userToken);

  if (!isTokenValid) {
    window.location.replace(headspaceHubRoutes.LOGIN_REDIRECT);
    return null;
  }

  return <>{children}</>;
};
