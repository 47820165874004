import { useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const appRoot = document.getElementById('root');

interface IPortal {
  children: JSX.Element;
}

export const Portal = ({ children }: IPortal) => {
  const div = useRef<HTMLDivElement>(document.createElement('div'));

  useLayoutEffect(() => {
    if (appRoot) {
      appRoot.appendChild(div.current);
    }

    return () => {
      if (appRoot) appRoot.removeChild(div.current);
    };
  }, []);

  return ReactDOM.createPortal(children, div.current);
};
