import * as R from 'ramda';
import { addDays, format } from '../../../../utils/dates';
import { validators } from './validators';
import { noop } from '../../../../utils';
import { TooltipParams } from '../../../../shared-components/Tooltip';
import {
  ChallengeField,
  ChallengeItem,
} from '../../OrganizationView/ChallengesView/types';

const { validateStart, required, isNumber } = validators;

export const getChange = R.pathOr(noop, ['validate', 'change']);
export const getBlur = R.pathOr(noop, ['validate', 'blur']);

export const validateChange = (
  field: FieldItem | ChallengeField,
  value: string,
  fields?: FormFields | ChallengeItem,
) => {
  // @ts-ignore
  const errorMessage = getChange(field)(value, fields);

  return {
    errorMessage,
    isValid: !errorMessage,
    hasError: !!errorMessage,
  };
};

export const validateBlur = (
  field: FieldItem | ChallengeField,
  value: string,
) => {
  // @ts-ignore
  const errorMessage = getBlur(field)(value) || field.errorMessage;

  return {
    errorMessage,
    isValid: !errorMessage,
    hasError: !!errorMessage,
  };
};

const anyFalse = (value: any) => !value;

export interface FieldItem {
  label: string;
  value: string;
  placeholder: string;
  validate?: {
    [key: string]: any;
  };
  required?: boolean;
  isValid?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  tooltip?: TooltipParams;
  options?: Option[];
}

export interface FormFields {
  challengeType: FieldItem;
  startDate: FieldItem;
  durationInWeeks: FieldItem;
  goalType: FieldItem;
  goalTarget: FieldItem;
  memberStatuses: FieldItem;
}

export const fieldsConfig: FormFields = {
  challengeType: {
    label: `challenges.createChallenge.challengeType.label`,
    placeholder: `challenges.createChallenge.challengeType.placeholder`,
    required: true,
    validate: {
      blur: required,
    },
    value: '',
  },
  durationInWeeks: {
    hasError: false,
    label: `challenges.createChallenge.durationInWeeks.label`,
    placeholder: `challenges.createChallenge.durationInWeeks.placeholder`,
    required: true,
    value: '',
  },
  goalTarget: {
    hasError: false,
    isValid: false,
    label: `challenges.createChallenge.goalTarget.label`,
    placeholder: `challenges.createChallenge.goalTarget.placeholder`,
    required: true,
    validate: {
      blur: required,
      change: isNumber,
    },
    value: '',
  },
  goalType: {
    hasError: false,
    label: `challenges.createChallenge.goalType.label`,
    placeholder: `challenges.createChallenge.goalType.placeholder`,
    required: true,
    value: '',
  },
  startDate: {
    hasError: false,
    label: `challenges.createChallenge.startDate.label`,
    placeholder: `challenges.createChallenge.startDate.placeholder`,
    required: true,
    validate: {
      blur: required,
      change: validateStart,
    },
    value: '',
  },
  memberStatuses: {
    label: `challenges.createChallenge.memberStatuses.label`,
    placeholder: `challenges.createChallenge.memberStatuses.placeholder`,
    value: '',
  },
};

const keys = Object.keys(fieldsConfig);

export const setDefaultFields = () => {
  const nextDate = addDays(new Date(), 1);
  const defaultStartDate = addDays(nextDate, 21);

  const defaultValues = {
    challengeType: 'meditation',
    durationInWeeks: '2',
    goalType: 'contentStarts',
    startDate: format(defaultStartDate, 'MM/DD/YYYY'),
    memberStatuses: 'ACTIVE',
  };
  return formFields(defaultValues);
};

export const formFields = (item: any) => {
  return keys.reduce((config: any, property: string) => {
    config[property].value = item[property] || '';
    return config;
  }, R.clone(fieldsConfig));
};

export const validateForm = (fields: any) => {
  const res = keys.map((item) => {
    let hasError = false;
    if (fields[item].required) {
      hasError = !!required(fields[item].value);
    }

    return (
      !hasError && (fields[item].isValid === undefined || fields[item].isValid)
    );
  });

  return res.some(anyFalse);
};

const addChallengesStateData = (items: any) => {
  return items.map((item: any) => ({
    ...formFields(item),
    status: item.status,
    deepLink: item.deepLink,
    hsChallengeId: item.hsChallengeId,
    view: true,
    settingsChanged: false,
  }));
};

export const addValidationState = (data: any) => {
  const challengesData = addChallengesStateData(data);
  return challengesData.map((item: any) => {
    const keys = Object.keys(item);

    return keys.reduce((config: any, field: string) => {
      if (typeof config[field] === 'object') {
        config[field] = {
          ...config[field],
          ...validateBlur(config[field], config[field].value),
        };
      }

      return config;
    }, R.clone(item));
  });
};

export const getOptions = (options: Array<{ value; label; disabled? }>) =>
  options.map(({ value, label, disabled }) => {
    return { title: label, value, disabled };
  });
