import { useGate } from 'statsig-react';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_org_list_filter
// Toggle organizations view filtering by contract/primary product
export const KEY = 'b2b_dashboard_org_list_filter';

export const useOrgListFiltering = (): [boolean, boolean] => {
  const { value, isLoading } = useGate(KEY);

  return [value, isLoading];
};
