import {
  OrganizationActionsTypes,
  NavigateToAction,
} from '../../types/organization';
import { ORG_CREATION_STEPS } from '../../../apps/dashboard/constants';
import { saveOrganizationAllFields } from './saveOrganizationAllFields';

const { NAVIGATE_TO } = OrganizationActionsTypes;

export const navigateTo = (
  destination: ORG_CREATION_STEPS | null,
): NavigateToAction => {
  if (destination === null) {
    // @ts-ignore
    return saveOrganizationAllFields();
  }

  return {
    type: NAVIGATE_TO,
    payload: { currentStep: destination },
  };
};
