import { configureStore as configure } from '@reduxjs/toolkit';
import reduxThunk from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import env from '../config/env';
import { rootReducer } from './reducers';
import { rootSaga } from './sagas';
import * as OrganizationMiddleware from './middleware/Organization';
import * as UserMiddleware from './middleware/User';

const sagaMiddleware = createSagaMiddleware();

export const configureStore = (preloadedState = {}, persistConfig = {}) => {
  const config = {
    storage,
    key: env.HOST || 'root',
    whitelist: ['user', 'onboarding', 'enrollmentVerification'],
    transforms: [
      expireReducer('enrollmentVerification', {
        expireSeconds: 1800,
        persistedAtKey: 'loadedAt',
        expiredState: {},
      }),
    ],
    ...persistConfig,
  };
  const persistedReducer = persistReducer(config, rootReducer);

  const definedStore = configure({
    reducer: persistedReducer,
    preloadedState,
    middleware: [
      OrganizationMiddleware.getOrgByAttribute,
      OrganizationMiddleware.getOrgById,
      OrganizationMiddleware.removeMembersWithScroll,
      OrganizationMiddleware.addMemberByEmail,
      UserMiddleware.middleware,
      reduxThunk,
      sagaMiddleware,
    ] as const,
  });

  sagaMiddleware.run(rootSaga);
  const persistedStore = persistStore(definedStore);

  return { persistor: persistedStore, store: definedStore };
};

export const { store, persistor } = configureStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
