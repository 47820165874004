import { css } from '@emotion/react';
import {
  borderRadiusCircle,
  borderWidthM,
  borderWidthS,
  borderWidthXS,
  CoolGrey,
  fontSizeM,
  fontSizeS,
  fontWeightBold,
  LegacyColors,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  flex,
  justifyContentCenter,
} from '../../../../styles/flex';

export const containerStyle = css(flex, justifyContentCenter, {
  height: rem(21.875),
  width: rem(20),
});

export const cardStyle = css({
  background: White['000'],
  borderBottom: `${borderWidthM} solid ${CoolGrey[100]}`,
  borderLeft: `${borderWidthS} solid ${CoolGrey[100]}`,
  borderRadius: rem(0.438),
  borderRight: `${borderWidthS} solid ${CoolGrey[100]}`,
  borderTop: `${borderWidthS} solid ${CoolGrey[100]}`,
  boxSizing: 'content-box',
  height: rem(19.125),
  width: rem(18.5),
});

export const topHalfStyle = css(flex, justifyContentCenter, alignItemsCenter, {
  borderLeft: `${borderWidthXS} solid ${CoolGrey[100]}`,
  borderRadius: `${borderWidthM} ${borderWidthM} 0 0`,
  borderRight: `${borderWidthXS} solid ${CoolGrey[100]}`,
  borderTop: `${borderWidthXS} solid ${CoolGrey[100]}`,
  boxSizing: 'content-box',
  height: rem(10.375),
});

export const bottomHalfStyle = css({
  borderRadius: `0 0 ${borderWidthM} ${borderWidthM}`,
  boxSizing: 'border-box',
  height: '45%',
  padding: `${SpacingInRemBySize['2XS']} ${rem(1.25)} ${
    SpacingInRemBySize.S
  } ${rem(1.25)}`,
  position: 'relative',
});

export const circleDiv = css(flex, justifyContentCenter, alignItemsCenter, {
  background: CoolGrey[500],
  borderRadius: borderRadiusCircle,
  color: White['000'],
  cursor: 'pointer',
  height: SpacingInRemBySize['2XL'],
  position: 'absolute',
  right: SpacingInRemBySize.S,
  top: rem(-1.375),
  width: SpacingInRemBySize['2XL'],
});

export const titleStyle = css({
  color: WarmGrey[600],
  fontSize: fontSizeM,
  fontWeight: fontWeightBold,
});

export const informationStyle = css({
  color: WarmGrey[400],
  fontSize: fontSizeS,
});

export const imageStyle = css({
  background: White['000'],
  borderRadius: `${borderWidthM} ${borderWidthM} 0 0`,
  boxSizing: 'border-box',
  maxWidth: '100%',
  width: rem(18.625),
});
