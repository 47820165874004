import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rem } from '@headspace/web-ui-components';
import { MAX_ENROLLMENT_PAGE_BODY_LENGTH } from '../../../../../constants';
import { Input } from '../../../../../../../shared-components/Input';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import {
  updateImplicitState,
  handleChange as handleChangeThunk,
} from '../../../../../../../state/actions/organizationActions';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { SetByParentLabel } from './SetByParentLabel';

interface BodyMessageInputProps {
  view: boolean;
}

export const BodyMessageInput: React.FC<BodyMessageInputProps> = ({ view }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const { body, bodyError, parentOrg, useSingleEnrollmentLink } = useSelector(
    getOrgManagement,
  );

  const bodyLength = body ? body.length : 0;
  const totalCharsText = `${translate(
    `organizationSettings.enrollment.actualBodyLabel`,
  )} - ${
    bodyLength > MAX_ENROLLMENT_PAGE_BODY_LENGTH
      ? 0
      : MAX_ENROLLMENT_PAGE_BODY_LENGTH - bodyLength
  } chars. left out of ${MAX_ENROLLMENT_PAGE_BODY_LENGTH}`;
  const restrictCharactersLength = view
    ? translate(`organizationSettings.enrollment.actualBodyLabel`)
    : totalCharsText;

  const isChildOrg = !!parentOrg;

  const updatedBody = parentOrg?.useSingleEnrollmentLink
    ? parentOrg?.enrollment?.body
    : body;

  const handleError = React.useCallback(
    (event) => {
      dispatch(updateImplicitState(event));
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    (event) => {
      dispatch(handleChangeThunk(event));
    },
    [dispatch],
  );

  return (
    <div>
      <Input
        type={view ? 'review' : 'textarea'}
        label={restrictCharactersLength}
        onChange={handleChange}
        onBlur={handleError}
        placeholder={`${MAX_ENROLLMENT_PAGE_BODY_LENGTH} Character Limit`}
        name="body"
        value={updatedBody}
        hasError={bodyError.error}
        errorMessage={bodyError.message}
        customInputStyles={{ minHeight: view ? rem(1.25) : rem(9) }}
        data-testid="body-text-area"
        disabled={useSingleEnrollmentLink && isChildOrg}
      />
      <SetByParentLabel display={useSingleEnrollmentLink && isChildOrg} />
    </div>
  );
};
