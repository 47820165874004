import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  cssWithMq,
  fontSizeM,
  rem,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import { handleLogoutFactory } from './handleLogoutFactory';
import { useTranslate } from '../../../../../hooks/useTranslate';
import {
  getHealthHub,
  getSlug,
} from '../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { getHsUserId } from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getHealthProvider } from '../../../state/selectors/healthProviderSelector/healthProviderSelector';

export const buttonStyle = (muted: boolean) =>
  cssWithMq({
    display: ['none', 'flex', 'flex'],
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: rem(1.25),
    paddingRight: rem(1.25),
    color: muted ? WarmGrey[500] : WarmGrey[400],
    border: 'none',
    backgroundColor: White['000'],
    fontSize: fontSizeM,
    ':hover': {
      cursor: 'pointer',
    },
    p: {
      margin: 0,
    },
  });

const LogOut: FC = () => {
  const slug = useSelector(getSlug);
  const hsUserId = useSelector(getHsUserId);
  const healthHub = useSelector(getHealthHub);
  const healthProvider = useSelector(getHealthProvider);
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const handleLogout = handleLogoutFactory(
    dispatch,
    slug,
    hsUserId,
    healthHub,
    healthProvider,
  );

  return slug && hsUserId ? (
    <button
      data-testid="sign-out-btn"
      type="button"
      aria-disabled={!hsUserId}
      css={buttonStyle(!hsUserId)}
      onClick={handleLogout}
    >
      <p>{translate('signOut')}</p>
    </button>
  ) : null;
};

export default LogOut;
