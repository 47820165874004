import { css } from '@emotion/react';
import UnifiedExceptionFooter from '../../../../../assets/unifed-exception-footer.svg';

const pageWrapperStyles = css({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
  height: '100vh',
  '@media (max-width: 768px)': {
    height: '90vh',
  },
});

const imageContainerStyles = css({
  position: 'relative',
  flexShrink: 0,

  marginTop: 'auto',
  width: '100%',
  height: '60vh',

  '@media (max-width: 768px)': {
    marginTop: 'auto',
    width: '100vw',
    height: '50vh',
  },
});

const imageStyles = css({
  width: '60vw',
  height: '20vh',
  '@media (max-width: 768px)': {
    width: '100vw',
  },
  '> img': {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
});

export const PageWrapper = ({ children }) => {
  return (
    <div css={pageWrapperStyles}>
      {children}

      <div css={imageContainerStyles}>
        <div css={imageStyles}>
          <img src={UnifiedExceptionFooter} alt="footer logo" />
        </div>
      </div>
    </div>
  );
};
