import { css } from '@emotion/react';
import {
  APERCU_FONT_NAME,
  fontSizeXS,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import {
  flex,
  justifyContentCenter,
  justifyContentSpaceBetween,
  rowFlex,
} from '../../../styles/flex';

export const panelStyle = css({
  padding: `${SpacingInRemBySize.XL} ${SpacingInRemBySize['2XL']}`,
  boxSizing: 'border-box',
});

export const firstColumnStyle = css({
  maxWidth: '70%',
  minWidth: '60%',
});

export const titleSectionStyle = css(rowFlex, justifyContentSpaceBetween, {
  margin: rem(0.313),
  '& h5': {
    marginBottom: SpacingInRemBySize.XS,
  },
});

export const createSurveySectionStyle = css(flex, justifyContentCenter);

export const hollowButtonStyle = css({
  fontFamily: APERCU_FONT_NAME,
  fontSize: fontSizeXS,
  letterSpacing: rem(0.156),
  lineHeight: rem(0.875),
  paddingLeft: SpacingInRemBySize.L,
  paddingRight: SpacingInRemBySize.L,
  textAlign: 'center',
  textTransform: 'uppercase',
});

export const createNewSurveyButtonStyle = css(hollowButtonStyle, {
  paddingLeft: SpacingInRemBySize.M,
  paddingRight: SpacingInRemBySize.M,
});

export const surveySectionStyle = css({
  width: '100%',
});

export const surveyContainerStyle = css(flex, {
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const containerCentering = css({
  display: 'grid',
  placeItems: 'center',
  width: '100%',
  height: SpacingInRemBySize['4XL'],
});
