import { QuestionId } from '../../apps/memberEnrollmentV2/models/OnboardingQuestions';
import { Product } from '../../apps/memberEnrollmentV2/constants';
import {
  AnalyticAction,
  ExtraAttributes,
  mParticleEventAction,
} from '../analyticActions';
import {
  ContractActivityStatus,
  ContractActivityType,
  ContractTypes,
  MParticleEventType,
} from '../types';
import { transformAnswerText } from '../utils';

const { activity, b2b, phi, web, screenView, community } = ContractTypes;

export enum OnboardingScreenNames {
  greetings = 'b2b onboarding greetings',
  recommendHeadspace = 'b2b onboarding recommend headspace',
  recommendGinger = 'b2b onboarding recommend ginger',
  gingerScheduling = 'b2b onboarding scheduling ginger',
  question = 'b2b onboarding question',
}

export enum OnboardingEventNames {
  onboardingButtonClickthrough = 'button onboarding clickthrough',
  onboardingFlowStartScreenView = 'b2b onboarding flow start screenview',
  onboardingFlowScreenView = 'b2b onboarding flow screenview',
  onboardingFlowCompletionScreenView = 'b2b onboarding flow completion screenview',
  onboardingHsAppQrScan = 'b2b onboarding hs app qr scan',
  onboardingGingerAppQrScan = 'b2b onboarding ginger app qr scan',
  onboardingQuestionHowAreYou = 'b2b onboarding how are you question',
  onboardingQuestionNeedState = 'b2b onboarding need state question',
  onboardingQuestionNeedStateHelp = 'b2b onboarding need state help question',
  onboardingQuestionNeedStateLifeAffect = 'b2b onboarding need state life affect question',
  onboardingQuestionMakingChange = 'b2b onboarding making change question',
  onboardingReliefMeditationComplete = 'b2b onboarding relief meditation complete',
  onboardingReliefMeditationStart = 'b2b onboarding relief meditation start',
}

const questionIdToEventMap = {
  [QuestionId.QUESTION_0_MOOD]:
    OnboardingEventNames.onboardingQuestionHowAreYou,
  [QuestionId.QUESTION_1_HELP]:
    OnboardingEventNames.onboardingQuestionNeedState,
  [QuestionId.QUESTION_2_IMPORTANCE]:
    OnboardingEventNames.onboardingQuestionNeedStateHelp,
  [QuestionId.QUESTION_3_LIFE_IMPACT]:
    OnboardingEventNames.onboardingQuestionNeedStateLifeAffect,
  [QuestionId.QUESTION_4_FEEL]:
    OnboardingEventNames.onboardingQuestionMakingChange,
};

const qrScanMap = {
  [Product.GINGER]: OnboardingEventNames.onboardingGingerAppQrScan,
  [Product.HEADSPACE]: OnboardingEventNames.onboardingHsAppQrScan,
};

type ClickthroughEventName = OnboardingEventNames.onboardingButtonClickthrough;

type ContentConsumedEventNames =
  | OnboardingEventNames.onboardingReliefMeditationComplete
  | OnboardingEventNames.onboardingReliefMeditationStart;

export const onboardingScreenViewAction = (
  eventName: OnboardingEventNames,
  extras?: ExtraAttributes,
): AnalyticAction =>
  mParticleEventAction({
    contractList: [b2b, web, screenView],
    eventType: MParticleEventType.Other,
    eventName,
    extras,
  });

export const onboardingButtonClickthrough = (
  ctaLabel: string,
  eventName: ClickthroughEventName = OnboardingEventNames.onboardingButtonClickthrough,
  activityStatus = ContractActivityStatus.complete,
): AnalyticAction =>
  mParticleEventAction({
    contractList: [activity, b2b, web],
    eventType: MParticleEventType.Other,
    eventName,
    extras: {
      activityStatus,
      ctaLabel,
    },
  });

export const onboardingContentConsumedEvent = (
  eventName: ContentConsumedEventNames,
  activityStatus: ContractActivityStatus,
): AnalyticAction =>
  mParticleEventAction({
    contractList: [activity, b2b, web],
    eventName,
    eventType: MParticleEventType.Other,
    extras: {
      activityStatus,
      activityType: ContractActivityType.contentConsumed,
    },
  });

export const onboardingQuestionAnsweredEvent = (
  questionId: QuestionId,
  answers: string[],
): AnalyticAction =>
  mParticleEventAction({
    contractList: [activity, b2b, phi, web],
    eventName: questionIdToEventMap[questionId],
    eventType: MParticleEventType.Other,
    extras: {
      activityType: ContractActivityType.survey,
      surveyAnswer: answers
        .map((answer) => transformAnswerText(answer))
        .join(','),
    },
  });

export const onboardingAppQrScanEvent = (product: Product): AnalyticAction =>
  mParticleEventAction({
    contractList: [activity, b2b, web],
    eventName: qrScanMap[product],
    eventType: MParticleEventType.Other,
    extras: {
      activityStatus: ContractActivityStatus.complete,
    },
  });

export const onboardingFlowStartGreetingsScreenViewAction = (): AnalyticAction =>
  onboardingScreenViewAction(
    OnboardingEventNames.onboardingFlowStartScreenView,
    {
      screenName: OnboardingScreenNames.greetings,
    },
  );

export const onboardingQuestionScreenViewAction = (): AnalyticAction =>
  onboardingScreenViewAction(OnboardingEventNames.onboardingFlowScreenView, {
    screenName: OnboardingScreenNames.question,
  });

export const onboardingRecommendHeadspaceViewAction = (): AnalyticAction =>
  onboardingScreenViewAction(
    OnboardingEventNames.onboardingFlowCompletionScreenView,
    {
      screenName: OnboardingScreenNames.recommendHeadspace,
    },
  );

export const onboardingSchedulingViewAction = (): AnalyticAction =>
  onboardingScreenViewAction(OnboardingEventNames.onboardingFlowScreenView, {
    screenName: OnboardingScreenNames.gingerScheduling,
  });

export const onboardingRecommendGingerViewAction = (): AnalyticAction =>
  onboardingScreenViewAction(
    OnboardingEventNames.onboardingFlowCompletionScreenView,
    {
      screenName: OnboardingScreenNames.recommendGinger,
    },
  );

export const onboardingDependentAddedEvent = (): AnalyticAction =>
  mParticleEventAction({
    contractList: [web, community],
    eventName: 'Family Plan Member Added',
    eventType: MParticleEventType.Other,
    extras: {
      groupType: 'fusion',
    },
  });
