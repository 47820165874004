import { FC } from 'react';
import {
  Blue,
  LegacyColors,
  rem,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { TextButton } from '../../../../shared-components/buttons/TextButton';
import { WEB_APP } from '../../../../utils/constants';
import {
  alignItemsCenter,
  columnFlex,
  justifyContentCenter,
} from '../../../dashboard/styles/flex';
import { useTranslate } from '../../../../hooks/useTranslate';

const outerContainerStyle = css(
  columnFlex,
  alignItemsCenter,
  justifyContentCenter,
  {
    '@media (max-width: 560px)': {
      height: '100%',
      maxWidth: rem(18.75),
    },
    color: WarmGrey[500],
    height: '40vh',
    textAlign: 'center',
    width: rem(43.625),
  },
);

const subMessageContainer = css({
  alignItems: 'center',
  color: WarmGrey[500],
  width: rem(16.875),
});

const ExistingSubscription: FC = () => {
  const { translate } = useTranslate();

  const handleClick = () => {
    window.location.assign(`${WEB_APP}/profile/account`);
  };

  return (
    <div css={outerContainerStyle}>
      <h1>{translate('existingPaidSubscription.header')}</h1>
      <div css={subMessageContainer}>
        <h5>{translate('existingPaidSubscription.subMessage')}</h5>
        <TextButton
          textColor={White['000']}
          hoverColor={Blue[200]}
          backgroundColor={Blue[200]}
          dataTestId="view-my-account"
          value={translate('existingPaidSubscription.viewMyAccount')}
          pill={true}
          width="100%"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default ExistingSubscription;
