import { css } from '@emotion/react';
import {
  cssWithMq,
  fontSize6XL,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';

export const containerStyle = css({
  alignItems: 'center',
  background: White['000'],
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  textAlign: 'center',
  width: '100%',
});

export const headerStyle = css({
  display: 'flex',
  height: rem(3.75),
  justifyContent: 'space-between',
  margin: SpacingInRemBySize['2XL'],
  position: 'absolute',
  width: '90%',
});

export const bodyContainerStyle = css({
  '> h1': {
    color: WarmGrey[800],
    fontSize: fontSize6XL,
  },
  '> h3': {
    width: rem(23.875),
  },
  alignItems: 'center',
  color: WarmGrey[600],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: '',
  paddingTop: SpacingInRemBySize['2XL'],
});

export const iconContainerStyle = cssWithMq({
  a: {
    padding: SpacingInRemBySize.XS,
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: ['column', 'row', 'row'],
  justifyContent: 'center',
  margin: `${rem(1.25)} 0 ${rem(0.875)}`,
  span: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: rem(0.063),
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: rem(0.063),
  },
});

export const verticalDividerStyle = css({
  backgroundColor: WarmGrey[100],
  height: rem(3.25),
  marginLeft: rem(1.688),
  marginRight: rem(1.688),
  width: rem(0.063),
});

export const orgLogoStyle = css({
  maxHeight: SpacingInRemBySize['4XL'],
  maxWidth: rem(12.5),
});

export const logosContainerStyle = css({
  alignItems: 'center',
  display: 'flex',
});
