import { paymentSagaFactory } from '@headspace/web-payments';
import { call, takeLatest } from 'redux-saga/effects';
import { PaymentActionTypes } from '../../../../../state/types/payment';
import paymentConfig from '../../../enrollment-config/paymentConfig';
import { fetchPaymentMethodSaga } from './fetchPaymentMethodSaga';
import { getVoucherInfoSaga } from './voucherInfoSaga';

// calls need to be serialized in order to work correctly
function* initializePayment() {
  yield call(getVoucherInfoSaga);
  const paymentVerificationSaga = paymentSagaFactory(paymentConfig);
  yield call(paymentVerificationSaga);
  yield call(fetchPaymentMethodSaga);
}

export function* waitToInitializePayment() {
  yield takeLatest(PaymentActionTypes.INITIALIZE_PAYMENT, initializePayment);
}
