import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from '@headspace/web-ui-components';
import { Container } from '../../../../../../shared-components/container/Container';
import { getOrgManagement } from '../../../../../../state/selectors/organizationSelectors';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { ForceRollover } from './components/ForceRollover';
import { SplitContractButton } from './components/SplitContractButton';
import { Panel } from '../../../../../../shared-components/Panel';
import { PanelWrapper } from '../shared-components/SettingsPanel/PanelWrapper';
import { PanelTitle } from '../shared-components/SettingsPanel/PanelTitle';
import { PanelSubtitle } from '../shared-components/SettingsPanel/PanelSubtitle';

export const OrganizationUtilitiesPanel: FC = () => {
  const { translate } = useTranslate();
  const { orgId } = useSelector(getOrgManagement);

  return (
    <Container>
      <Panel dataTestId="organization-utilities-panel">
        <PanelWrapper>
          <PanelTitle>
            {translate('organizationSettings.organizationUtilities.title')}
          </PanelTitle>
          <PanelSubtitle>
            {translate('organizationSettings.organizationUtilities.subtitle')}
          </PanelSubtitle>
          <ForceRollover orgId={orgId} />
          <Divider />
          <SplitContractButton />
        </PanelWrapper>
      </Panel>
    </Container>
  );
};
