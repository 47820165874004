import { css } from '@emotion/react';
import { cssWithMq, White, Blue, rem } from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../constants/breakpoint';

export const pageContainerStyle = (background?: string) =>
  cssWithMq({
    display: 'flex',
    marginTop: rem(1.25),
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: [White['000'], background, background],
    '& a': {
      color: `${Blue[200]} !important`,
    },
  });

export const confirmationPageContainerStyle = css(pageContainerStyle(), {
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
});

export const contentContainerStyle = cssWithMq({
  display: 'flex',
  marginTop: rem(1.25),
  width: '100%',
  justifyContent: ['start', 'center', 'center'],
  alignItems: ['start', 'center', 'center'],
  maxWidth: rem(100),
  flexDirection: 'row',
  [WebUIComponentsBreakPoint.Tablet]: {
    flexDirection: 'column',
  },
});

export const columnStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

export const childComponentColumnStyle = css(columnStyle, {
  flexDirection: 'column',
});

export const bottomImageStyle = cssWithMq({
  bottom: rem(-2.813),
  height: rem(8.188),
  left: 0,
  right: 0,
  width: '100%',
  '@media(max-height: 720px) and (orientation:landscape)': {
    display: 'none',
  },
  '@media (max-height: 680px) and (max-width: 330px)': {
    height: rem(6.25),
  },
  display: ['initial', 'none', 'none'],
});

export const verificationPrimaryContainerStyle = cssWithMq({
  display: ['flex', 'block'],
  flexDirection: 'column',
});
