const contentful = require('contentful');

const API_KEY =
  '2cac0d83d78edbe0bcbf00c99bfbed50d7b7873af840252d94be296eba48c438';
const SPACE_ID = 're0je2ce7cxi';

const checkDomainEnvironment =
  window.location.host === 'work.headspace.com' ? 'master' : 'staging';

export const getToolKitAssetsIsLoading = () => ({
  type: 'FETCH_TOOLKIT_ASSETS_IS_LOADING',
});

export const getToolKitAssetsSuccess = (payload: any) => ({
  payload,
  type: 'FETCH_TOOLKIT_ASSETS_SUCCESS',
});

export const getToolKitAssetsFail = (payload: any) => ({
  payload,
  type: 'FETCH_TOOLKIT_ASSETS_FAIL',
});

export const getToolKitAssets = () => (dispatch: (p: any) => void) => {
  dispatch(getToolKitAssetsIsLoading());

  const getResource = contentful
    .createClient({
      space: SPACE_ID,
      accessToken: API_KEY,
      environment: checkDomainEnvironment,
    })
    .getEntries({
      content_type: 'b2bLibraryResourceCategory',
      order: 'fields.categoryRankOrder',
    });

  getResource
    .then((res: any) => {
      dispatch(getToolKitAssetsSuccess(res.items));
    })
    .catch((err: any) => dispatch(getToolKitAssetsFail(err)));
};
