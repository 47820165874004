import { css } from '@emotion/react';
import * as React from 'react';
import { SpacingInRemBySize } from '@headspace/web-ui-components';
import {
  alignContentFlexStart,
  justifyContentSpaceBetween,
  rowFlex,
} from '../../../../../styles/flex';

interface TwoColumnProps {
  children: React.ReactNode;
}

const panelTwoColumnStyle = css(
  rowFlex,
  justifyContentSpaceBetween,
  alignContentFlexStart,
  {
    '> div': {
      ':last-of-type': {
        marginRight: 0,
      },
      flex: 1,
      marginRight: SpacingInRemBySize['2XL'],
    },
  },
);

export const TwoColumn: React.FC<TwoColumnProps> = ({ children }) => {
  return <div css={panelTwoColumnStyle}>{children}</div>;
};
