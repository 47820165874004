import { FC, useCallback } from 'react';
import { PrimaryButton } from '@headspace/web-ui-components';

import {
  landingPageHeroHeaderMobileStyle,
  landingPageHeroMobileStyle,
  landingPageHeroSubHeaderMobileStyle,
} from '../styles/verificationPageStyleUmdWfm';
import { useTranslate } from '../../../../../../hooks/useTranslate';

interface LandingMobileHeroProps {
  onClick: (boolean) => void;
  campaignDisplayName: string;
}

export const LandingMobileHero: FC<LandingMobileHeroProps> = ({
  onClick,
  campaignDisplayName,
}) => {
  const { translate } = useTranslate();
  const handleClick = useCallback(() => {
    onClick(true);
  }, [onClick]);

  return (
    <div data-testid="landing-mobile-hero">
      <div css={landingPageHeroMobileStyle}>
        <h2 css={landingPageHeroHeaderMobileStyle}>
          {translate('enrollment.landingPage.headlineUmd')}
        </h2>
        {campaignDisplayName ? (
          <p css={landingPageHeroSubHeaderMobileStyle}>
            {translate('enrollment.landingPage.subHeadlineUmd', {
              campaignDisplayName,
            })}
          </p>
        ) : null}
        <PrimaryButton onClick={handleClick} dataTestIdPrefix="cta">
          {translate('enrollment.verify.email.headlineUmd')}
        </PrimaryButton>
      </div>
    </div>
  );
};
