import {
  OrganizationActionsTypes,
  CancelUpdateAdminAction,
} from '../../types/organization';

const { CANCEL_UPDATE_ADMIN } = OrganizationActionsTypes;

export const cancelUpdateAdmin = (): CancelUpdateAdminAction => {
  return {
    type: CANCEL_UPDATE_ADMIN,
    payload: {
      admin_email: '',
      admin_name: '',
      admin_title: '',
      adminEditExisting: false,
      adminReviewView: true,
    },
  };
};
