import { EnvironmentVariables } from './env';

const HEADSPACE_GLOBAL_ENVIRONMENT_KEY = 'HEADSPACE_APP_CONFIG';

export function getConfig(): EnvironmentVariables {
  let config;

  if (typeof window !== 'undefined') {
    config = (window as Partial<Window>)[HEADSPACE_GLOBAL_ENVIRONMENT_KEY];
  } else if (typeof global !== 'undefined') {
    config = (global as any)[HEADSPACE_GLOBAL_ENVIRONMENT_KEY];
  } else {
    config = {} as EnvironmentVariables;
  }

  return config;
}
