import { HOST } from './constants';

export const getLogoEnv = (logo: string | undefined) => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV !== 'development') {
    return `${HOST}/${logo}`;
  }

  return `https://b2b.integration.headspace.com/${logo}`;
};
