import { css } from '@emotion/react';
import {
  cssWithMq,
  fontSizeM,
  fontSizeXL,
  Grey,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../../constants/breakpoint';

export const contentStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: rem(90),
  paddingBottom: rem(2.25),
});

export const secondColumnStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: rem(50),
  paddingBottom: rem(2.25),
  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    marginBottom: rem(6.25),
  },
  [WebUIComponentsBreakPoint.Mobile]: {
    width: '100%',
  },
});

export const contentContainerStyle = cssWithMq({
  alignItems: 'start',
  color: WarmGrey[800],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  minWidth: ['auto', 'auto', rem(69.813)],

  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    alignItems: 'center',
    minHeight: rem(41.25),
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    flexDirection: 'column',
  },

  [WebUIComponentsBreakPoint.Mobile]: {
    flexDirection: 'column',
    marginTop: SpacingInRemBySize.L,
  },
});

export const progressBarContainer = cssWithMq({
  margin: 'auto',
  maxWidth: rem(20),
  [WebUIComponentsBreakPoint.Mobile]: {
    width: '35%',
  },
  paddingBottom: rem(2.25),
});

export const dividerStyle = cssWithMq({
  display: ['none', 'flex', 'flex'],
  margin: `${SpacingInRemBySize.XS} 0 !important`,
});

export const audioPlayerContainer = cssWithMq({
  display: 'flex',
  justifyContent: 'center',
  marginLeft: [0, SpacingInRemBySize.M, SpacingInRemBySize.M],
  width: rem(20.438),
});

export const audioSectionStyle = (isDesktopView: boolean) =>
  cssWithMq({
    alignItems: 'center',
    display: 'flex',
    flexDirection: isDesktopView ? 'inherit' : 'column',
    justifyContent: 'start',
  });

export const footerContainerStyle = (isTabletOrDesktopView: boolean) =>
  css({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: isTabletOrDesktopView ? 'space-between' : 'center',
  });

export const sectionContainerStyle = cssWithMq({
  flexDirection: 'column',
  marginBottom: [SpacingInRemBySize['8XL'], 'inherit', 'inherit'],
  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    padding: `0 ${SpacingInRemBySize.XL} ${SpacingInRemBySize.XL} ${SpacingInRemBySize.XL}`,
  },
});

export const titleSectionContainer = cssWithMq({
  maxWidth: rem(20),
  width: '100%',
  [WebUIComponentsBreakPoint.Desktop]: {
    alignSelf: 'baseline',
    marginLeft: SpacingInRemBySize['5XL'],
    marginTop: SpacingInRemBySize['6XL'],
    paddingRight: rem(8.625),
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    maxWidth: rem(34.75),
    textAlign: 'center',
  },
  [WebUIComponentsBreakPoint.Mobile]: {
    maxWidth: '100%',
    p: {
      margin: rem(1.25),
    },
  },
});

export const titleSectionStyles = css({
  margin: 'auto',
  [WebUIComponentsBreakPoint.Mobile]: {
    padding: `0 ${SpacingInRemBySize.L}`,
    textAlign: 'center',
  },
});

export const footerSectionStyles = cssWithMq({
  backgroundColor: White['000'],
  bottom: 0,
  left: 0,
  position: ['relative', 'fixed', 'fixed'],
  right: 0,
  zIndex: ['inherit', '2', '2'],
});

export const subHeaderStyles = cssWithMq({
  color: [WarmGrey[600], WarmGrey[700], WarmGrey[700]],
  fontSize: [fontSizeM, fontSizeXL, fontSizeXL],
});
