import { getCookie } from '@headspace/web-auth/dist/utils/cookie';
import { MemberEnrollActionTypes } from '../../actions/memberEnrollActions/memberEnrollActionTypes';
import { CookieKeys } from '../../../constants';

const countryCodeCookie = getCookie(CookieKeys.COUNTRY_CODE);

const INITIAL_STATE = {
  countryCode: countryCodeCookie || 'US',
};

export const countryCodeReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case MemberEnrollActionTypes.SET_COUNTRY_CODE:
      return { ...state, countryCode: action.payload.countryCode };
    default:
      return INITIAL_STATE;
  }
};

export type CountryCodeState = ReturnType<typeof countryCodeReducer>;
