import { useCallback, useState, ReactNode, FC, useEffect } from 'react';
import { ShowConfirmOverLayComponent } from './ShowConfirmOverlay';
import { TranslatedEditingView } from './EditingView';
import { PanelHeader } from './PanelHeader';
import { PanelContainer } from './PanelContainer';
import { disablePointerEvents } from './styles';
import { noop } from '../../../../../../../utils';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

export interface SettingsPanelProps {
  children?: ReactNode;
  title: string;
  subtitle?: string;
  view: boolean;
  disabledView?: boolean;
  displayEditButton?: boolean;
  useEditCta?: boolean;
  editCtaLabel?: string;
  hideControls?: boolean;
  index: number;
  toggleView: (index: number) => void;
  cancelChanges: () => void;
  saveChanges: () => void;
  handleDelete?: () => void;
  settingsChanged?: boolean;
  hasErrors?: boolean;
  withDeleteButton?: boolean;
  dataTestId?: string;
  customSaveChangesMessage?: string;
}

export const SettingsPanel: FC<SettingsPanelProps> = ({
  settingsChanged,
  toggleView,
  index,
  title,
  subtitle,
  view,
  disabledView,
  hideControls,
  displayEditButton,
  useEditCta,
  editCtaLabel,
  hasErrors,
  withDeleteButton,
  handleDelete = noop,
  dataTestId,
  saveChanges,
  cancelChanges,
  customSaveChangesMessage,
  children,
}) => {
  const { translate } = useTranslate();

  const [showConfirm, toggleShowConfirm] = useState(false);
  const [saveChangesSelected, setSelected] = useState(false);

  const displaySaveChangesMessage =
    customSaveChangesMessage || translate('saveChangesMessage');

  const setSaveChangesSelected = useCallback(
    (selected: boolean) => {
      if (settingsChanged) {
        setSelected(selected);
        toggleShowConfirm(true);
      } else {
        toggleShowConfirm(false);
        toggleView(index);
      }
    },
    [settingsChanged, setSelected, toggleShowConfirm, toggleView, index],
  );

  const goBack = useCallback(() => {
    toggleShowConfirm(false);
  }, [toggleShowConfirm]);

  const confirmChanges = useCallback(() => {
    toggleShowConfirm(false);
    toggleView(index);
    if (saveChangesSelected) {
      saveChanges();
    } else {
      cancelChanges();
    }
  }, [saveChangesSelected, saveChanges, cancelChanges, toggleView, index]);

  const handleToggleView = useCallback(() => {
    toggleView(index);
  }, [toggleView, index]);

  return (
    <PanelContainer dataTestId={dataTestId} disabledView={disabledView}>
      {showConfirm ? (
        <ShowConfirmOverLayComponent
          saveChangesSelected={saveChangesSelected}
          displaySaveChangesMessage={displaySaveChangesMessage}
          goBack={goBack}
          confirmChanges={confirmChanges}
        />
      ) : null}

      <div className={`${disabledView ? disablePointerEvents : null}`}>
        <PanelHeader
          view={view}
          title={title}
          subtitle={subtitle}
          useEditCta={useEditCta}
          displayEditButton={displayEditButton}
          editCtaLabel={editCtaLabel}
          handleToggleView={handleToggleView}
        />

        {children}

        <TranslatedEditingView
          view={view}
          hideControls={hideControls}
          hasErrors={hasErrors}
          withDeleteButton={withDeleteButton}
          handleDelete={handleDelete}
          setSaveChangesSelected={setSaveChangesSelected}
        />
      </div>
    </PanelContainer>
  );
};
