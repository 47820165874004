import axios from 'axios';
import { getB2BOrgSubscriptionsUrl } from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import { responseType } from '../../utils/constants';
import {
  OrgSubscription,
  OrgSubscriptionBase,
} from './org-subscriptions.types';

export const addOrganizationSubscription = async (
  orgId: number,
  payload: OrgSubscriptionBase,
): Promise<OrgSubscription> => {
  try {
    const response = await axios.post<OrgSubscription>(
      getB2BOrgSubscriptionsUrl(orgId).ADD_ORG_SUBSCRIPTIONS,
      payload,
      {
        headers: createAuthorizationHeader(),
        responseType: responseType.JSON,
      },
    );
    return response.data;
  } catch (e) {
    console.error(`Failed in addOrganizationSubscription with Error:${e}`);
    throw e;
  }
};

export const updateOrganizationSubscription = async (
  subId: number,
  orgId: number,
  payload: OrgSubscriptionBase,
): Promise<OrgSubscription> => {
  try {
    const response = await axios.patch<OrgSubscription>(
      getB2BOrgSubscriptionsUrl(orgId, subId).UPDATE_ORG_SUBSCRIPTIONS,
      { id: subId, ...payload },
      {
        headers: createAuthorizationHeader(),
        responseType: responseType.JSON,
      },
    );
    return response.data;
  } catch (e) {
    console.error(`Failed in updateOrganizationSubscription with Error:${e}`);
    throw e;
  }
};

export const deleteOrganizationSubscription = async (
  subId: number,
  orgId: number,
): Promise<OrgSubscription> => {
  try {
    const response = await axios.delete<OrgSubscription>(
      getB2BOrgSubscriptionsUrl(orgId, subId).DELETE_ORG_SUBSCRIPTIONS,
      {
        headers: createAuthorizationHeader(),
        responseType: responseType.JSON,
      },
    );
    return response.data;
  } catch (e) {
    console.error(`Failed in deleteOrganizationSubscription with Error:${e}`);
    throw e;
  }
};
