import FileSaver from 'file-saver';
import { useMutation } from '@tanstack/react-query';

import {
  buildMemberExportQueryParams,
  getExportMembers,
  transformToURLSearchParams,
} from '../../../rest';

export type CSVOptions = {
  filterWith?: number[];
  sort: string[];
  count?: number;
  search?: string;
  status?: string[];
};

export enum ExportType {
  ALL = 0,
  FILTERED = 1,
  SELECTED = 2,
}

export interface ExportMembersParams {
  orgId: number;
  orgName: string;
  options: CSVOptions;
  exportType: ExportType;
}

const exportMembers = async ({
  orgId,
  orgName,
  options,
}: ExportMembersParams) => {
  const exportQuery = buildMemberExportQueryParams(orgId, options);
  const urlSearchParams = transformToURLSearchParams(exportQuery);
  const response = await getExportMembers(orgId, urlSearchParams);
  return { data: response.data, orgName };
};

export const useExportMembers = () => {
  return useMutation(exportMembers, {
    onError: (error) => {
      console.error('Error exporting members:', error);
    },
    onSuccess: ({ data, orgName }) => {
      const ymd = new Date().toISOString().split('T')[0];
      const filename = `${orgName.trim()}-${ymd}.csv`;
      FileSaver.saveAs(data, filename);
    },
  });
};
