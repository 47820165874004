import { css } from '@emotion/react';
import { fontFamilyCss, WarmGrey, White } from '@headspace/web-ui-components';
import { matchPathname } from '../../utils/matchPathname';
import { EnrollmentPathname, DashboardPathname } from '../../utils/constants';

const pathsToCheck = [
  DashboardPathname.CREATE_ORG,
  EnrollmentPathname.MEMBER_ENROLL,
  EnrollmentPathname.SOLERA,
  EnrollmentPathname.VP,
  EnrollmentPathname.JOIN,
  EnrollmentPathname.GYMPASS,
];

const windowHeight = document.body.clientHeight;
const windowWidth = document.body.clientWidth;
const conditionWidth = windowHeight > windowWidth ? 'auto 100vh' : '100vw auto';

export const appStyle = (pathname: string) =>
  css(fontFamilyCss, {
    '@media (min-width: 600px) and (orientation:landscape)': {
      backgroundSize: '100vw auto',
    },
    backgroundAttachment: 'fixed',
    backgroundColor: matchPathname(pathname, pathsToCheck)
      ? White['000']
      : WarmGrey[100],
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: conditionWidth,
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    minHeight: '100vh',
  });

export const routeContainerStyleCss = css({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
});
