import { css } from '@emotion/react';
import { ExportCSV } from '../../../apps/dashboard/OrganizationView/MembersView/types';
import { ExportType } from '../../../apps/dashboard/hooks/useExportMembers';
import { GenerateExportCsvActions } from './types';

const actionDropdownItemsStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});

const actionDropdownItem = (label: string, count: number): JSX.Element => {
  return (
    <div css={actionDropdownItemsStyle}>
      <div>{label}</div>
      <div>{count}</div>
    </div>
  );
};

export const generateExportCsvActions: GenerateExportCsvActions<ExportCSV> = ({
  datasetActions,
  selectedRowIds,
  totalMembers,
  filteredMembers,
  searchValue,
  sortBy,
  status,
  isLoading,
}) => {
  if (!datasetActions) return [];
  const selectedCount = selectedRowIds.length;
  return [
    {
      action: () =>
        datasetActions.all.action(
          { count: totalMembers, sort: sortBy },
          ExportType.ALL,
        ),
      display: actionDropdownItem(datasetActions.all.label, totalMembers),
      isDisabled: isLoading || datasetActions.all.isDisabled,
    },
    {
      action: () =>
        datasetActions.filtered.action(
          {
            count: filteredMembers,
            search: searchValue,
            sort: sortBy,
            status,
          },
          ExportType.FILTERED,
        ),
      display: actionDropdownItem(
        datasetActions.filtered.label,
        filteredMembers,
      ),
      isDisabled: isLoading || datasetActions.filtered.isDisabled,
    },
    {
      action: () =>
        datasetActions.selected.action(
          {
            count: selectedCount,
            filterWith: selectedRowIds,
            sort: sortBy,
          },
          ExportType.SELECTED,
        ),
      display: actionDropdownItem(datasetActions.selected.label, selectedCount),
      isDisabled: isLoading || datasetActions.selected.isDisabled,
      selectedMembers: selectedCount,
    },
  ];
};
