import { useCallback } from 'react';
import {
  Modal,
  Orange,
  SpacingInRemBySize,
  Spinner,
  WarmGrey,
  White,
  bodyMCss,
  headingLCss,
  remToPx,
} from '@headspace/web-ui-components';

import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { TextButton } from '../../../../../../../../../shared-components/buttons/TextButton';
import { useTranslate } from '../../../../../../../../../hooks/useTranslate';
import { getOrgManagement } from '../../../../../../../../../state/selectors/organizationSelectors';

const modalBodyStyle = css({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  height: '50vh',
  width: '50vw',
  justifyContent: 'center',
  textAlign: 'center',
  padding: 'inherit 2rem',
});

const titleStyle = css(headingLCss, {
  margin: 0,
});

const buttonGroupStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const backButtonStyle = css({
  ':hover': {
    backgroundColor: `${WarmGrey[100]} !important`,
    color: `${Orange[200]} !important`,
  },
  backgroundColor: `${White['000']} !important`,
  color: `${Orange[200]} !important`,
  marginRight: SpacingInRemBySize.M,
});

const spinnerContainerStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  justifyContent: 'center',
  width: '100%',
});

export const ImportChildConfirmModal = ({
  onCloseModal,
  onBack,
  onContinue,
  isLoading,
}) => {
  const { translate } = useTranslate();
  const { org_name: parentOrgName } = useSelector(getOrgManagement);
  const handleOnCloseModal = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const handleOnBack = useCallback(() => {
    handleOnCloseModal();
    onBack();
  }, [handleOnCloseModal, onBack]);

  const handleOnContinue = useCallback(() => {
    onContinue();
  }, [onContinue]);

  return (
    <Modal
      dataTestIdPrefix="import-ginger-children"
      body={
        <div css={modalBodyStyle}>
          {isLoading ? (
            <div css={spinnerContainerStyle}>
              <Spinner size={remToPx(4)} />
            </div>
          ) : (
            <div>
              <h2 css={titleStyle} data-testid="import-ginger-children-title">
                <FormattedMessage id="organizationSettings.orgHierarchy.import.confirm.title" />
              </h2>
              <p css={bodyMCss} data-testid="import-ginger-children-subtitle">
                <FormattedMessage
                  id="organizationSettings.orgHierarchy.import.confirm.subtitle"
                  values={{
                    parentOrgName,
                  }}
                />
              </p>

              <span css={buttonGroupStyle}>
                <TextButton
                  lg={true}
                  dataTestId="go-back"
                  value={translate(
                    'organizationSettings.orgHierarchy.import.confirm.back',
                  )}
                  css={backButtonStyle}
                  onClick={handleOnBack}
                />
                <TextButton
                  lg={true}
                  dataTestId="continue-import"
                  value={translate(
                    'organizationSettings.orgHierarchy.import.confirm.continue',
                  )}
                  onClick={handleOnContinue}
                />
              </span>
            </div>
          )}
        </div>
      }
      onClose={handleOnCloseModal}
      lockParentScroll={true}
    />
  );
};
