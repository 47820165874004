import { FC, useEffect, useRef } from 'react';
import {
  errorSubLabelStyle,
  anchorTagStyle,
  verifiedSubLabelStyle,
} from './styles';
import { useTranslate } from '../../../../../hooks/useTranslate';
import {
  EnrollmentErrors,
  translatedErrors,
} from '../../../../../types/organization';

interface VerifyErrorMessageProps {
  errorMessage: string | null;
}

const VerifyErrorMessage: FC<VerifyErrorMessageProps> = ({ errorMessage }) => {
  const { translate } = useTranslate();
  const verificationRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (verificationRef && verificationRef.current) {
      verificationRef.current.focus();
    }
  }, [verificationRef]);

  if (errorMessage === EnrollmentErrors.MEMBER_EMAIL_NOT_ELIGIBLE) {
    return (
      <div data-testid="error-email-not-eligible" css={errorSubLabelStyle}>
        {translate(`errors.MEMBER_EMAIL_NOT_ELIGIBLE`)}
        <a
          href="https://help.headspace.com/hc/en-us/articles/360058079153"
          target="_blank"
          rel="noopener noreferrer"
          css={anchorTagStyle}
        >
          {translate(`faqArticle`)}
        </a>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div
        data-testid="verification-error-message"
        tabIndex={-1}
        role="alert"
        ref={verificationRef}
        css={errorSubLabelStyle}
      >
        {translatedErrors.includes(errorMessage)
          ? translate(`errors.${errorMessage}`)
          : errorMessage}
      </div>
    );
  }

  return null;
};

interface VerificationMessageMessageProps {
  errorMessage: string | null;
  verified: boolean;
}

export const VerificationMessage: FC<VerificationMessageMessageProps> = ({
  verified,
  errorMessage,
}) => {
  const { translate } = useTranslate();
  const isSuccess = verified && !errorMessage;
  const verificationRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isSuccess && verificationRef && verificationRef.current) {
      verificationRef.current.focus();
    }
  }, [verificationRef, isSuccess]);

  if (isSuccess) {
    return (
      <div
        tabIndex={-1}
        ref={verificationRef}
        data-testid="verification-sent"
        css={verifiedSubLabelStyle}
      >
        {translate('verify.byEmail.verificationSent')}
      </div>
    );
  }

  if (errorMessage) {
    return <VerifyErrorMessage errorMessage={errorMessage} />;
  }

  return null;
};
