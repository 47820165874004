import React from 'react';
import { TextButton } from '../../../shared-components/buttons/TextButton';
import { useTranslate } from '../../../hooks/useTranslate';
import { flex, justifyContentSpaceBetween } from '../styles/flex';
import { adminRecordEditControlStyles } from './styles';

export interface RecordEditControlsProps {
  handleDelete(): void;
  handleCancel(): void;
  handleSave(): void;
  saveIsDisabled: boolean;
  deleteIsDisabled: boolean;
  cancelIsDisabled?: boolean;
  saveLabel: string;
  deleteLabel?: string;
  cancelLabel?: string;
}

export const RecordEditControls: React.FC<RecordEditControlsProps> = ({
  handleCancel,
  handleDelete,
  handleSave,
  saveIsDisabled,
  deleteIsDisabled,
  cancelIsDisabled = false,
  saveLabel,
  deleteLabel,
  cancelLabel,
}) => {
  const { translate } = useTranslate();
  return (
    <div css={[flex, justifyContentSpaceBetween, adminRecordEditControlStyles]}>
      <div>
        {!deleteIsDisabled ? (
          <TextButton
            reg={true}
            value={translate(`${deleteLabel || 'delete'}`)}
            className="deleteAdmin"
            onClick={handleDelete}
            disabled={deleteIsDisabled}
          />
        ) : null}
      </div>
      <div css={flex}>
        <TextButton
          reg={true}
          value={translate(`${cancelLabel || 'cancel'}`)}
          className="cancelAdmin"
          onClick={handleCancel}
          disabled={cancelIsDisabled}
        />
        <TextButton
          dataTestId="record-edit-controls-save-button"
          reg={true}
          value={translate(saveLabel)}
          className="saveAdmin"
          onClick={handleSave}
          disabled={saveIsDisabled}
        />
      </div>
    </div>
  );
};
