export enum SoleraErrorTypes {
  SOLERA_USER_ALREADY_CONNECTED_TO_ANOTHER_DOMAIN_USER = 'SOLERA_USER_ALREADY_CONNECTED_TO_ANOTHER_DOMAIN_USER',
  DOMAIN_USER_ALREADY_CONNECTED_TO_ANOTHER_SOLERA_USER = 'DOMAIN_USER_ALREADY_CONNECTED_TO_ANOTHER_SOLERA_USER',
  DOMAIN_USER_HAS_ACTIVE_PAID_SUBSCRIPTION = 'DOMAIN_USER_HAS_ACTIVE_PAID_SUBSCRIPTION',
  SOMETHING_WENT_WRONG_DURING_SOLERA_CONNECTION = 'SOMETHING_WENT_WRONG_DURING_SOLERA_CONNECTION',
}

export enum HealthProviderErrorTypes {
  HEALTH_PROVIDER_USER_ALREADY_CONNECTED_TO_ANOTHER_DOMAIN_USER = 'HEALTH_PROVIDER_USER_ALREADY_CONNECTED_TO_ANOTHER_DOMAIN_USER',
  DOMAIN_USER_ALREADY_CONNECTED_TO_ANOTHER_HEALTH_PROVIDER_USER = 'DOMAIN_USER_ALREADY_CONNECTED_TO_ANOTHER_HEALTH_PROVIDER_USER',
  DOMAIN_USER_ALREADY_CONNECTED_TO_ANOTHER_SOLERA_USER = 'DOMAIN_USER_ALREADY_CONNECTED_TO_ANOTHER_SOLERA_USER',
  DOMAIN_USER_HAS_ACTIVE_PAID_SUBSCRIPTION = 'DOMAIN_USER_HAS_ACTIVE_PAID_SUBSCRIPTION',
  SOMETHING_WENT_WRONG_DURING_HEALTH_PROVIDER_CONNECTION = 'SOMETHING_WENT_WRONG_DURING_HEALTH_PROVIDER_CONNECTION',
  SOLERA_USER_ALREADY_CONNECTED_TO_ANOTHER_DOMAIN_USER = 'SOLERA_USER_ALREADY_CONNECTED_TO_ANOTHER_DOMAIN_USER',
  SOMETHING_WENT_WRONG_DURING_SOLERA_CONNECTION = 'SOMETHING_WENT_WRONG_DURING_SOLERA_CONNECTION',
}
