import axios from 'axios';

import { getB2BOrgIdUrl, getB2BOrgUrl, B2B_API } from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import { HTTPMethod } from '../../utils/constants';
import {
  EligibilityFileStatus,
  EligibilityFileTemplate,
  PreUpdateRestrictByChange,
  PreUpdateRestrictByChangeStatus,
  UploadEligibilityFile,
} from './elegibilityFile.types';
import { orgAPIAxios } from '../axios';

export const uploadEligibilityFile: UploadEligibilityFile = ({
  formData,
  orgId,
}) =>
  axios({
    method: HTTPMethod.POST,
    url: getB2BOrgIdUrl(orgId).UPLOAD_ELIGIBILITY_FILE,
    data: formData,
    headers: createAuthorizationHeader(),
  });

export const getEligibilityFileTemplate: EligibilityFileTemplate = () =>
  orgAPIAxios({
    headers: createAuthorizationHeader(),
    method: HTTPMethod.GET,
    url: B2B_API.GET_ELF_FILE_TEMPLATE,
  });

export const getEligibilityFileStatusById: EligibilityFileStatus = (
  orgId,
  elfId,
) =>
  orgAPIAxios({
    headers: createAuthorizationHeader(),
    method: HTTPMethod.GET,
    url: getB2BOrgIdUrl(orgId, elfId).GET_ELIGIBILITY_FILE_STATUS_BY_ID,
  });

export const preUpdateRestrictByChange: PreUpdateRestrictByChange = (
  orgId,
  restrictBy,
  dryRun = true,
  options = {},
) =>
  orgAPIAxios({
    data: {
      dryRun,
      orgId,
      restrictBy,
      ...options,
    },
    headers: createAuthorizationHeader(),
    method: HTTPMethod.POST,
    url: getB2BOrgUrl().PREUPDATE_RESTRICT_BY_CHANGE,
  });

export const getPreUpdateRestrictByChangeStatus: PreUpdateRestrictByChangeStatus = (
  migrationIds,
) => {
  let url: string;

  if (migrationIds.length === 1) {
    url = getB2BOrgUrl(migrationIds[0]).PREUPDATE_RESTRICT_BY_CHANGE_STATUS;
  } else {
    const migrationIdsString = `?migrationIds=${migrationIds.join(
      '&migrationIds=',
    )}`;
    url = getB2BOrgUrl(undefined, migrationIdsString)
      .PREUPDATE_RESTRICT_BY_CHANGE_STATUS;
  }

  return orgAPIAxios({
    headers: createAuthorizationHeader(),
    method: HTTPMethod.GET,
    url,
  });
};

export const getErrorCsv = (url) =>
  orgAPIAxios({
    method: HTTPMethod.GET,
    url,
  });
