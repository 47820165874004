import { Choice, Question } from '../../../../../models/OnboardingQuestions';
import { languageToLocaleMapping } from '../constants/localization';

export const getLocalizedQuestionTitle = (
  question: Question,
  currentLanguage: string,
): string => {
  const locale = languageToLocaleMapping[currentLanguage];

  if (!locale) {
    return question.title;
  }

  const { translations = {} } = question;
  const { title: localizedTitle } = translations[locale];

  if (!localizedTitle) {
    return question.title;
  }

  return localizedTitle;
};

export const getLocalizedQuestionSubtitle = (
  question: Question,
  currentLanguage: string,
): string => {
  const locale = languageToLocaleMapping[currentLanguage];

  if (!locale) {
    return question.subtitle || '';
  }

  const { translations = {} } = question;
  const { subtitle: localizedSubtitle } = translations[locale];

  if (!localizedSubtitle) {
    return question.subtitle || '';
  }

  return localizedSubtitle || '';
};

export const getLocalizedAnswerText = (
  answer: Choice,
  currentLanguage: string,
): string => {
  const locale = languageToLocaleMapping[currentLanguage];

  if (!locale) {
    return answer.text;
  }

  const { translations = {} } = answer;
  const { text: localizedText } = translations[locale];

  return localizedText;
};
