import * as R from 'ramda';
import { RootState } from '../../../../../state/types';

export const getAuthenticationSection = (state: any): any =>
  state.authentication;

export const getJWT = (state: RootState) =>
  R.pathOr(
    null,
    ['authentication', 'tokens', 'accessToken', 'data', 'token'],
    state,
  );

export const getEmail = (state: RootState) =>
  R.pathOr(null, ['authentication', 'user', 'profile', 'data', 'email'], state);

export const getHsUserId = (state: RootState): string =>
  R.pathOr(null, ['authentication', 'user', 'profile', 'data', 'id'], state);

export const getPrivileges = (state: RootState) =>
  R.pathOr(
    [],
    ['authentication', 'user', 'profile', 'data', 'privileges'],
    state,
  );

export const getFirstName = (state: RootState) =>
  R.path(['authentication', 'user', 'profile', 'data', 'firstName'], state);

export const getLastName = (state: RootState) =>
  R.path(['authentication', 'user', 'profile', 'data', 'lastName'], state);

export const getAuthConnections = (state: RootState): Array<string> =>
  R.pathOr(
    null,
    ['authentication', 'user', 'profile', 'data', 'connections'],
    state,
  );
