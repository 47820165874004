import { css } from '@emotion/react';
import {
  Blue,
  cssWithMq,
  White,
  rem,
  WarmGrey,
} from '@headspace/web-ui-components';
import { TextButton } from '../../../../shared-components/buttons/TextButton';

import { useTranslate } from '../../../../hooks/useTranslate';
import {
  alignItemsCenter,
  columnFlex,
  justifyContentCenter,
} from '../../../dashboard/styles/flex';

const outOfRoomMessageStyle = cssWithMq(
  columnFlex,
  justifyContentCenter,
  alignItemsCenter,
  {
    color: WarmGrey[500],
    height: '40vh',
    textAlign: 'center',
    width: [rem(16.875), 'initial', 'initial'],
  },
);

const aTagStyle = css({
  textDecoration: 'none',
  width: '100%',
});

const subMessageStyle = cssWithMq({
  maxWidth: [undefined, '55%', '55%'],
});

interface OutOfRoomComponentProps {
  overflowUrl: string | null;
  title?: string;
  subHeader?: string;
}

export const OutOfRoom = ({
  overflowUrl,
  title,
  subHeader,
}: OutOfRoomComponentProps) => {
  const { translate } = useTranslate();

  return (
    <div css={outOfRoomMessageStyle}>
      <h1 data-testid="out-of-room">
        {title || translate('outOfRoomMessage.header')}
      </h1>
      <p css={subMessageStyle}>
        {subHeader || translate('outOfRoomMessage.waitListSubHeader')}
      </p>
      {overflowUrl ? (
        <a
          css={aTagStyle}
          target="_blank"
          rel="noopener noreferrer"
          href={`${overflowUrl}`}
        >
          <TextButton
            textColor={White['000']}
            hoverColor={Blue[200]}
            backgroundColor={Blue[300]}
            dataTestId="join-the-waitlist"
            value={translate('joinTheWaitlist')}
            pill={true}
            width="100%"
          />
        </a>
      ) : null}
    </div>
  );
};
