import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { getActiveOrg } from '../../../../../state/selectors/organizationSelectors';
import { OutOfRoom } from '../../OutOfRoomPage/OutOfRoom';
import { PageWrapper } from './PageWrapper';
import { useTranslate } from '../../../../../hooks/useTranslate';

export const SeatsFullPage = () => {
  const { translate } = useTranslate();
  const activeOrg = useSelector(getActiveOrg);
  const title = translate('enrollment.exceptionPage.seatsFull.headline');
  const subHeader = translate('enrollment.exceptionPage.seatsFull.message');
  const overflowUrl = R.pathOr(null, ['enrollment', 'overflowUrl'], activeOrg);

  return (
    <PageWrapper>
      <OutOfRoom
        overflowUrl={overflowUrl}
        title={title}
        subHeader={subHeader}
      />
    </PageWrapper>
  );
};
