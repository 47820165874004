import { ClassNames } from '@emotion/react';
import { Card, ProgressBar } from '@headspace/web-ui-components';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { logoutAndRedirect } from '../../../state/actions/memberEnrollActions/memberEnrollActions';
import { mfaCardContent, mfaRedirectCardStyle } from '../styles';
import { Routes } from '../../../constants/routes';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface MFARedirectCardProps {
  isDependentAccessOrg: boolean;
}
export const MFARedirectCard: React.FC<MFARedirectCardProps> = ({
  isDependentAccessOrg,
}) => {
  const { translate } = useTranslate();

  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((p) => {
        const nextProgress = p + 0.1;
        if (nextProgress >= 100) {
          clearInterval(interval);

          if (isDependentAccessOrg) {
            dispatch(
              logoutAndRedirect(
                `${Routes.HEADSPACE_LOGIN}?redirectOnSuccess=/family/manage`,
              ),
            );
          } else {
            dispatch(logoutAndRedirect(Routes.HEADSPACE_LOGIN));
          }
        }
        return nextProgress;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [setProgress, isDependentAccessOrg, dispatch]);

  return (
    <ClassNames>
      {({ css }) => (
        <Card
          className={css(mfaRedirectCardStyle)}
          dataTestIdPrefix="mfa-redirect"
        >
          <p css={mfaCardContent}>{translate('finish.mfaInfo')}</p>
          <ProgressBar progress={progress} />
        </Card>
      )}
    </ClassNames>
  );
};
