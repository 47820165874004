import { SyntheticEvent } from 'react';
import * as React from 'react';
import { fontSizeS, Red } from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { useSelector, useDispatch } from 'react-redux';
import reactHtmlParser from 'react-html-parser';
import {
  getOrgManagement,
  getAddDomainDisabledStatus,
} from '../../../../../../../state/selectors/organizationSelectors';
import { handleEmptyArray } from '../../../../../../../state/helpers';
import { domainSafe } from '../../../../../../../utils/validations';

import {
  deleteDomain,
  newDomain,
  updateImplicitState,
  handleChange as handleChangeThunk,
} from '../../../../../../../state/actions/organizationActions';
import { ChildRestrictedInput } from '../../../../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

const domainNudgeStyle = css({
  color: Red[100],
  fontSize: fontSizeS,
});

interface EmailDomainInputProps {
  view: boolean;
  onClick?: (e?: SyntheticEvent) => void;
}

export const RestrictByEmailDomainInput: React.FC<EmailDomainInputProps> = ({
  onClick,
  view,
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const { add_domain, add_domainError, restrictedDomains } = useSelector(
    getOrgManagement,
  );
  const noRestrictedDomains = restrictedDomains.length === 0;
  const addDomainIsDisabled = useSelector(getAddDomainDisabledStatus);
  const restrictedDomainsSanitized = handleEmptyArray(restrictedDomains);
  const domainCount = restrictedDomainsSanitized.length;
  const domainCountText = domainCount
    ? `${restrictedDomainsSanitized.join(', ')}`
    : translate(`organizationSettings.enrollment.noRestrictions`);

  const handleAdd = React.useCallback(() => {
    dispatch(newDomain());
  }, [dispatch]);

  const handleDelete = React.useCallback(
    (event) => {
      dispatch(deleteDomain(event));
    },
    [dispatch],
  );

  const handleError = React.useCallback(
    (event) => {
      dispatch(updateImplicitState(event));
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    (event) => {
      dispatch(handleChangeThunk(event));
    },
    [dispatch],
  );

  return (
    <>
      <ChildRestrictedInput
        label={translate(
          `organizationSettings.enrollment.restrictedDomainsLabel${
            view ? 'View' : ''
          }`,
        )}
        type={view ? 'review' : 'list'}
        name="add_domain"
        onChange={handleChange}
        onBlur={handleError}
        disabled={addDomainIsDisabled}
        onClick={onClick}
        coverValue={reactHtmlParser(
          translate(
            `organizationSettings.enrollment.domainCount${
              domainCount === 1 ? '' : '_plural'
            }`,
            {
              domainCount,
            },
          ),
        )}
        value={view ? domainCountText : add_domain.replace(domainSafe, '')}
        placeholder={translate(
          `organizationSettings.enrollment.addDomainPlaceholder`,
        )}
        addItem={handleAdd}
        addItemText={translate(`organizationSettings.enrollment.addDomain`)}
        list={restrictedDomainsSanitized}
        errorMessage={add_domainError.message}
        hasError={add_domainError.error}
        deleteItem={handleDelete}
      />
      {noRestrictedDomains && (
        <span css={domainNudgeStyle}>
          {reactHtmlParser(
            translate('organizationSettings.enrollment.domainNudge'),
          )}
        </span>
      )}
    </>
  );
};
