import { css } from '@emotion/react';
import {
  borderWidthXS,
  fontSizeS,
  lineHeightBody,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { bold } from '../../../dashboard/styles/fonts';
import {
  alignItemsCenter,
  flex,
  justifyContentCenter,
} from '../../../dashboard/styles/flex';

export const containerStyle = css({
  color: WarmGrey[500],
  padding: `0 ${rem(1.25)} ${SpacingInRemBySize['2XL']} ${rem(1.25)}`,
  textAlign: 'center',
});

export const errorContainerStyle = css(
  flex,
  justifyContentCenter,
  alignItemsCenter,
  {
    color: WarmGrey[500],
    height: rem(25),
  },
);

export const emailTextStyle = css(bold, {
  color: WarmGrey[500],
  fontSize: fontSizeS,
});

export const emailSubLabelStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeS,
  fontWeight: 'lighter',
  lineHeight: lineHeightBody,
});

export const dividerStyle = css({
  borderBottom: `solid ${borderWidthXS} ${WarmGrey[200]}`,
  marginBottom: rem(3.75),
  marginTop: SpacingInRemBySize['2XL'],
  width: '100%',
});

export const buttonContainerStyle = css({
  paddingBottom: rem(0.625),
  paddingTop: rem(2.188),
});

export const orgLogoStyle = css({
  height: rem(2.125),
  margin: SpacingInRemBySize.XS,
});

export const logoContainer = css(flex, justifyContentCenter, alignItemsCenter);
