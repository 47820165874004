import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from '../../../../../../../shared-components/Input';
import { isCSM as getIsCSM } from '../../../../../../../state/selectors/userSelectors';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import {
  updateImplicitState,
  handleChange as handleChangeThunk,
} from '../../../../../../../state/actions/organizationActions';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { SetByParentLabel } from './SetByParentLabel';

interface LearnMoreInputProps {
  view: boolean;
}

export const LearnMoreURLInput: React.FC<LearnMoreInputProps> = ({ view }) => {
  const { translate } = useTranslate();

  const isCSM = useSelector(getIsCSM);
  const {
    learn_more_url_path,
    learn_more_url_pathError,
    parentOrg,
    useSingleEnrollmentLink,
  } = useSelector(getOrgManagement);

  const isChildOrg = !!parentOrg;

  const updatedLearnMoreUrl = parentOrg?.useSingleEnrollmentLink
    ? parentOrg?.enrollment?.learnMoreUrl
    : learn_more_url_path;

  const dispatch = useDispatch();

  const handleError = React.useCallback(
    (event) => {
      dispatch(updateImplicitState(event));
    },
    [dispatch],
  );

  const handleChange = React.useCallback(
    (event) => {
      dispatch(handleChangeThunk(event));
    },
    [dispatch],
  );

  return (
    <div>
      <Input
        label={translate(`organizationSettings.enrollment.infoUrlLabel`)}
        type={view || !isCSM ? 'review' : 'url'}
        onChange={handleChange}
        onBlur={handleError}
        hasError={learn_more_url_pathError.error}
        errorMessage={learn_more_url_pathError.message}
        name="learn_more_url_path"
        value={updatedLearnMoreUrl || ''}
        placeholder={translate(
          `organizationSettings.enrollment.infoUrlPlaceholder`,
        )}
        hintLeft={
          true /* view */
            ? ''
            : translate(`organizationSettings.enrollment.infoUrlBase`)
        }
        data-testid="learn_more_url_path-input"
        disabled={useSingleEnrollmentLink && isChildOrg}
      />
      <SetByParentLabel display={useSingleEnrollmentLink && isChildOrg} />
    </div>
  );
};
