import * as Sentry from '@sentry/browser';
import type { ThunkDispatch } from '../../types';
import { ModalActionsTypes } from '../../types/modal';
import {
  UpdateSFTPUserAction,
  OrganizationActionsTypes,
} from '../../types/organization';
import { ErrorResponse, parseError } from '../../../utils/parseError';
import { setActiveSFTPStep } from './setActiveSFTPStep';
import { SFTP_STEPS } from '../../../apps/dashboard/constants';
import { updateSFTPUser as updateSFTPUserApi } from '../../../rest/sftp-user/sftp-user.api';

export const updateSFTPUser = (key: File) => {
  return async (dispatch: ThunkDispatch, getState: GetState) => {
    const {
      manageOrganization: { orgId, sftpUsers },
    } = getState();

    try {
      // The sftpUsers array (despite being an array), only has one member, which is the org's single sftpUser
      // so we need to access the first index. Originally, the intent was to support multiple sftpUsers per org, but
      // that was never implemented.
      // TODO: update the endpoint that returns sftpUsers to return a single user instead of an array
      // https://headspace.atlassian.net/browse/PTNR-5899
      const sftpUsername = sftpUsers[0].username;
      await updateSFTPUserApi({ orgId, username: sftpUsername, key });

      dispatch({
        type: OrganizationActionsTypes.UPDATE_SFTP_USER,
        payload: {
          sftpUser: { orgId, username: sftpUsername, fileName: key.name },
        },
      } as UpdateSFTPUserAction);
    } catch (error) {
      Sentry.captureException(error);
      const { message, title } = parseError(error as ErrorResponse);
      dispatch({
        payload: {
          message,
          title,
        },
        type: ModalActionsTypes.OPEN_MODAL,
      });
      dispatch(setActiveSFTPStep(+Object.keys(SFTP_STEPS)[0]));
    }
  };
};
