export enum HealthProviderActionTypes {
  SET_HEALTH_PROVIDER_CONNECTION_ERROR = 'SET_HEALTH_PROVIDER_CONNECTION_ERROR',
  SET_HEALTH_PROVIDER_CONNECTION_STARTED = 'SET_HEALTH_PROVIDER_CONNECTION_STARTED',
  SET_HEALTH_PROVIDER_CONNECTION_FINISHED = 'SET_HEALTH_PROVIDER_CONNECTION_FINISHED',
}

export interface SetHealthProviderConnectionActionErrorPayload {
  error: {
    code: string | null;
  };
}

export interface SetHealthProviderConnectionErrorAction {
  type: HealthProviderActionTypes.SET_HEALTH_PROVIDER_CONNECTION_ERROR;
  payload: SetHealthProviderConnectionActionErrorPayload;
}

export interface SetHealthProviderConnectionStartedAction {
  type: HealthProviderActionTypes.SET_HEALTH_PROVIDER_CONNECTION_STARTED;
}

export interface SetHealthProviderConnectionFinishedAction {
  type: HealthProviderActionTypes.SET_HEALTH_PROVIDER_CONNECTION_FINISHED;
}

export type HealthProviderActions =
  | SetHealthProviderConnectionErrorAction
  | SetHealthProviderConnectionFinishedAction
  | SetHealthProviderConnectionStartedAction;
