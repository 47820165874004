import { css } from '@emotion/react';
import {
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import * as React from 'react';

export interface ProgressBarProps {
  selected: boolean;
  barColor?: string;
}

const barStyle = css({
  display: 'inline-block',
  flex: '1 1 0',
  height: SpacingInRemBySize.XS,
  marginLeft: rem(0.063),
  marginRight: rem(0.063),
  opacity: '0.2',
});

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { barColor, selected } = props;
  const barStyleExtended = css({
    backgroundColor: barColor || WarmGrey[800],
    opacity: selected ? '1' : '0.2',
  });

  return (
    <li
      data-testid="progress-bar"
      aria-label={selected ? 'selected' : undefined}
      css={[barStyle, barStyleExtended]}
    />
  );
};
