import { Divider } from '@headspace/web-ui-components';
import * as React from 'react';
import { TextButton } from '../buttons/TextButton';
import { STEP_STATUS } from '../../apps/dashboard/constants';
import { StatusIndicator } from './StatusIndicator';
import {
  stepButtonStyles,
  nextWrapper,
  statusWrapper,
  stepContentWrapper,
  stepWrapper,
  titleStyles,
} from './styles';
import { useTranslate } from '../../hooks/useTranslate';

export interface StepProps {
  activeChildren?: JSX.Element | JSX.Element[] | null;
  children?: JSX.Element | JSX.Element[] | null;
  ctaText?: string;
  handleNextStep(): void;
  showCTA: boolean;
  showDivider?: boolean;
  showSubtitle?: boolean;
  status: STEP_STATUS;
  step: number;
  subtitle?: JSX.Element | string;
  completeSubtitle?: JSX.Element | string;
  title: string;
}

export const Step: React.FC<StepProps> = ({
  activeChildren,
  children,
  ctaText,
  handleNextStep,
  showCTA,
  showDivider,
  showSubtitle,
  status,
  step,
  subtitle,
  completeSubtitle,
  title,
}) => {
  const { translate } = useTranslate();
  const isActive = status === STEP_STATUS.ACTIVE;
  const isComplete = status === STEP_STATUS.COMPLETE;
  const cta =
    isActive && showCTA ? (
      <TextButton
        onClick={handleNextStep}
        dataTestId="step-next-button"
        value={ctaText || ''}
        className="thin"
      />
    ) : null;
  const completionSubtitleMessage = completeSubtitle ?? (
    <div>{translate('organizationSettingsSFTP.steps.done')}</div>
  );

  return (
    <div data-testid={`step-${step}-${status}`} css={stepWrapper(isActive)}>
      <div css={statusWrapper}>
        <StatusIndicator status={status} />
      </div>
      <div css={stepContentWrapper}>
        <div css={titleStyles(isActive)}>{title}</div>

        <div css={stepButtonStyles()}>
          {(isActive || showSubtitle) && <div>{subtitle}</div>}
          {isActive ? (
            <>
              {activeChildren}
              <div css={nextWrapper}>{cta}</div>
            </>
          ) : null}
        </div>
        {isComplete ? completionSubtitleMessage : null}
        {showDivider ? <Divider /> : null}
      </div>
      {children}
    </div>
  );
};
