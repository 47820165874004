import { useMutation, useQueryClient } from '@tanstack/react-query';
import { generateSurvey } from '../../../../../rest/surveys/survey.api';

export const useCreateSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (orgId: number) => generateSurvey(orgId),
    mutationKey: ['surveys'],
    onSuccess: () => queryClient.fetchQuery({ queryKey: ['surveys'] }),
  });
};
