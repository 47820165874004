import * as React from 'react';
import { Panel } from '../../../../../../../shared-components/Panel';
import { panelDisabledStyle } from './styles';
import { PanelWrapper } from './PanelWrapper';

export interface PanelContainerPros {
  dataTestId?: string;
  disabledView?: boolean;
  children: React.ReactNode;
}

export const PanelContainer: React.FC<PanelContainerPros> = ({
  dataTestId,
  disabledView,
  children,
}) => {
  return (
    <Panel>
      <PanelWrapper dataTestId={dataTestId}>
        {disabledView ? (
          <div className="disabledView" css={panelDisabledStyle} />
        ) : null}
        {children}
      </PanelWrapper>
    </Panel>
  );
};
