import { css } from '@emotion/react';
import * as React from 'react';
import {
  borderRadiusXS,
  borderWidthXS,
  CoolGrey,
  rem,
  rgba,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';

const tileStyles = css({
  backgroundColor: White['000'],
  border: `${borderWidthXS} solid ${WarmGrey[200]}`,
  borderRadius: borderRadiusXS,
  boxShadow: `${SpacingInRemBySize['3XS']} ${SpacingInRemBySize['3XS']} ${
    SpacingInRemBySize['3XS']
  } ${rgba(WarmGrey[800], 0.06)}`,
  color: CoolGrey[500],
  padding: SpacingInRemBySize.L,
  width: rem(16.563),
});
interface TilePropTypes {
  dataTestId?: string;
  children: React.ReactNode;
}

export const Tile: React.FC<TilePropTypes> = ({ dataTestId, children }) => {
  return (
    <div css={tileStyles} data-testid={dataTestId || 'tile-component'}>
      {children}
    </div>
  );
};
