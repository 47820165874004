import close16Svg from 'assets/close-16.svg';
import searchSvg from 'assets/search.svg';

import { CoolGrey } from '@headspace/web-ui-components';
import { regular } from '../../../apps/dashboard/styles/fonts';
import { Icon } from '../../Icon';
import { TableFilterBarProps } from './types';
import { clearSearchStyle, searchInputStyle } from './styles';
import { searchStyle } from '../styles';
import { noop } from '../../../utils';

export const TableFilterBar = ({
  handleSearch,
  searchValue,
  dataTestId,
  placeholder,
}: TableFilterBarProps) => {
  return (
    <div className="search" css={searchStyle}>
      <Icon width={24} height={24} fill={CoolGrey[500]} src={searchSvg} />
      <input
        tabIndex={0}
        data-testid={dataTestId}
        type="text"
        onChange={handleSearch}
        placeholder={placeholder}
        value={searchValue}
        css={[regular, searchInputStyle]}
      />
      {searchValue ? (
        <div
          tabIndex={0}
          aria-label="Clear search"
          onClick={handleSearch}
          onKeyDown={noop}
          className="clearSearch"
          role="button"
          css={clearSearchStyle}
        >
          <Icon width={16} height={16} src={close16Svg} fill={CoolGrey[500]} />
        </div>
      ) : null}
    </div>
  );
};
