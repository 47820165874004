import * as R from 'ramda';
import { currencyForCountryCode } from '../../../constants';
import { getCountryCode } from '../countryCodeSelector/countryCodeSelector';
import { RootState } from '../../../../../state/types';

const getCountryPrice = (state: RootState) => {
  const prices = R.pathOr(
    [],
    ['voucherInfo', 'data', 'attributes', 'zuoraProductRatePlan', 'prices'],
    state,
  );
  const countryCode = getCountryCode(state);
  const currency = currencyForCountryCode(countryCode);
  const countryPrice = (n) => n.currency === currency;

  return R.filter(countryPrice, prices);
};

export const getVoucherPrice = (state: RootState) =>
  R.pathOr('N/A', ['0', 'price'], getCountryPrice(state));

export const getVoucherSymbol = (state: RootState) =>
  R.pathOr('N/A', ['0', 'symbol'], getCountryPrice(state));

export const getCurrency = (state: RootState) =>
  R.pathOr('N/A', ['0', 'currency'], getCountryPrice(state));

export const getLastCardDigits = (state: RootState) =>
  R.pathOr(
    'N/A',
    ['paymentMethodInfo', 'data', 'attributes', 'creditCardMaskNumber'],
    state,
  );

export const getPaymentSection = (state: RootState) => state.payment;
