import * as R from 'ramda';

const stringToLower = (val: string | number): string | number => {
  if (val === 0 || val === null) return '';
  if (typeof val !== 'string') return val;
  return R.toLower(val);
};

export const sortRows = (sort: string[], rows: RowProps[]): RowProps[] => {
  const [key, dir] = sort;
  const nestedIndex = R.compose(
    stringToLower,
    // @ts-ignore
    R.prop(key),
    R.prop('sortIndex'),
  );
  const sortByKeyDir = R.sortBy(nestedIndex);
  const sortedRows = sortByKeyDir(rows);
  return dir === 'ASC' ? sortedRows : R.reverse(sortedRows);
};

export const filterRowsByColumnValues = (
  columnFilterValues: { [k: string]: unknown } | { [k: number]: unknown },
  rows: RowProps[],
): RowProps[] => {
  const columnFilters: [string, unknown][] = R.toPairs(columnFilterValues);
  if (!columnFilters.length) return rows;
  return filterRowsByColumnValues(
    columnFilters.slice(1),
    rows.filter((row: any) => {
      const { sortIndex } = row;
      const [column, include] = columnFilters[0];
      const filterWith = R.prop(column, sortIndex);
      // @ts-ignore
      return include.includes(filterWith);
    }),
  );
};

export const filterRowsByString = (
  filterOn: string,
  rows: RowProps[],
): RowProps[] => {
  return rows.filter((row) => {
    // @ts-ignore
    const { sortIndex } = row;
    return (
      R.prop('name', sortIndex) &&
      sortIndex.name
        .toLowerCase()
        .match(
          filterOn.toLowerCase().replace(/[\[\\\^\$\.\|\?\*\+\(\)]/g, '\\$&'),
        )
    );
  });
};

export const createIdArray = R.pluck('id');

export const selectRow = (selected: string[], id: string) => {
  return selected.includes(id)
    ? selected.filter((selectedId) => selectedId !== id)
    : selected.concat(id);
};

export const selectAllRows = (
  allRowIds: string[],
  selectedRowIds: string[],
) => {
  return allRowIds.length === selectedRowIds.length ? [] : allRowIds.slice();
};
