import { requestDeauthenticate } from '@headspace/web-auth';
import { Dispatch } from 'redux';
import { Routes } from '../../../constants/routes';
import { clearEnrollmentVerificationConfirmation } from '../../../state/actions/memberEnrollActions/memberEnrollActions';
import { resetEnrollmentOnboardingState } from '../../../state/slice/onboarding/onboardingReducer';

export const handleLogoutFactory = (
  dispatch: Dispatch,
  slug: string,
  hsUserId: string,
  healthHub: string,
  healthProvider: string,
) => (): void => {
  if (slug && hsUserId && !(healthHub || healthProvider)) {
    dispatch(clearEnrollmentVerificationConfirmation());
    dispatch(resetEnrollmentOnboardingState());
    dispatch(requestDeauthenticate(Routes.LOGOUT_CALLBACK_REDIRECT(slug)));
  } else {
    dispatch(
      requestDeauthenticate(
        Routes.SSO_LOGOUT_CALLBACK_REDIRECT(healthProvider),
      ),
    );
  }
};
