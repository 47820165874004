export type ModalState = {
  title: string;
  message: string;
  isOpen: boolean;
  confirm: void;
  cancel: void;
} | null;

export enum ModalActionsTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  CONFIRM_MODAL_ACTION = 'CONFIRM_MODAL_ACTION',
  EXECUTE_CONFIRM = 'EXECUTE_CONFIRM',
}

export interface ExecuteConfirm {
  type: string;
  payload: {
    executeConfirm: string;
  };
}
export interface OpenModalAction {
  type: ModalActionsTypes.OPEN_MODAL;
  payload: {
    title: string;
    message: string;
    navigateTo: any;
    confirmCallback: () => void;
  };
}

export interface ConfirmModalAction {
  type: ModalActionsTypes.CONFIRM_MODAL_ACTION;
  payload: Record<string, unknown>;
}

export interface CloseModalAction {
  type: ModalActionsTypes.CLOSE_MODAL;
  payload: Record<string, unknown>;
}

export enum DeleteCallbackTypes {
  DELETE_ADMIN = 'delete-admin',
  CONFIRM_DELETE = 'confirm-delete',
  DELETE_FUTURE_CONTRACT = 'delete-future-contract',
}
