import axios from 'axios';
import FileSaver from 'file-saver';
import * as Sentry from '@sentry/browser';
import { format } from '../../utils/dates';
import { getB2BOrgIdUrl } from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import { responseType, HTTPMethod } from '../../utils/constants';
import { DownloadEngagementReport } from './engagementReport.types';

export const downloadEngagementReport: DownloadEngagementReport = (
  orgId,
  name,
) =>
  axios({
    method: HTTPMethod.GET,
    url: getB2BOrgIdUrl(orgId).GET_ENGAGEMENT_REPORT,
    headers: createAuthorizationHeader(),
    responseType: responseType.BLOB,
  })
    .then((response) => {
      FileSaver.saveAs(
        response.data,
        `${name}_${format(new Date(), 'YYYY-MM-DDTHH_mm_ssZZ')}`,
      );
    })
    .catch((err) => {
      Sentry.addBreadcrumb({
        message: `Failed in downloadEngagementReport with Error:${err}`,
      });
      if (err.response?.status === 404) {
        throw new Error(
          'Your report isn’t available yet. Please check again next week.',
        );
      }
      throw err;
    });
