import { combineReducers, Reducer } from 'redux';

// Admin Dashboard Reducers
import * as OrganizationReducers from './organizationReducers';
import * as OrganizationsReducer from './organizationsReducer';
import * as UserReducer from './adminReducer';
import * as ManageOrganizationReducer from './manageOrganizationReducer';
import * as ToolkitReducer from './toolkitReducer';
import * as challengesReducer from './challengesReducer';
import { modalReducer } from './modalReducer';
import { createOrganizationSlice } from '../slices/createOrganization/createOrganizationSlice/createOrganizationSlice';

// Enrollment Reducers
import { paymentReducer } from '../../apps/memberEnrollmentV2/state/reducers/paymentReducer';
import { authReducer } from '../../apps/memberEnrollmentV2/state/reducers/hsAuthReducer';
import { paymentMethodInfoReducer } from '../../apps/memberEnrollmentV2/state/reducers/paymentMethodInfoReducer/paymentMethodInfoReducer';
import { voucherInfoReducer } from '../../apps/memberEnrollmentV2/state/reducers/voucherInfoReducer/voucherInfoReducer';
import { memberSubscriptionDetailReducer } from '../../apps/memberEnrollmentV2/state/reducers/memberSubscriptionDetailReducer/memberSubscriptionDetailsReducer';
import { emailVerificationTokenReducer } from '../../apps/memberEnrollmentV2/state/reducers/emailVerificationTokenReducer/emailVerificationTokenReducer';
import { geolocationVerificationReducer } from '../../apps/memberEnrollmentV2/state/reducers/geolocationReducer/geolocationReducer';
import { countryCodeReducer } from '../../apps/memberEnrollmentV2/state/reducers/countryCodeReducer/countryCodeReducer';
import { languageReducer } from '../../apps/memberEnrollmentV2/state/reducers/languageReducer/languageReducer';
import { ssoReducer } from '../../apps/memberEnrollmentV2/state/reducers/sso/ssoReducer';
import { soleraReducer } from '../../apps/memberEnrollmentV2/state/reducers/solera/soleraReducer';
import { gingerSoleraReducer } from '../../apps/memberEnrollmentV2/state/reducers/solera/gingerSoleraReducer';
import { healthProviderReducer } from '../../apps/memberEnrollmentV2/state/reducers/healthProviderReducer/healthProviderReducer';
import { restrictByMigrationSlice } from '../slices/restrictByMigration';
import { settingsModalSlice } from '../slices/settingsModal';
import { sessionTimeoutModalSlice } from '../slices/sessionTimeoutModal';
import { enrollmentVerificationReducer } from '../../apps/memberEnrollmentV2/state/reducers/enrollmentVerification/enrollmentVerificationReducer';
import onboardingReducer from '../../apps/memberEnrollmentV2/state/slice/onboarding/onboardingReducer';
import enrollmentReducer from '../../apps/memberEnrollmentV2/state/slice/enrollment/enrollmentReducer';
import eapServiceRequestReducer from '../../apps/headspaceHub/state/slice/serviceRequestReducer';
import { membersSlice } from '../slices/members/membersSlice';
import { eapAuthReducer } from '../../apps/headspaceHub/state/reducers/eapAuthReducer';

// States
import { UserState } from '../types/user';

export const rootReducer = combineReducers({
  // Dashboard reducers
  addMemberByEmail: OrganizationReducers.addMemberByEmail,
  challenges: challengesReducer.reducer,
  createOrganization: createOrganizationSlice.reducer,
  getOrgById: OrganizationReducers.getOrgById,
  manageOrganization: ManageOrganizationReducer.reducer,
  members: membersSlice.reducer,
  organizations: OrganizationsReducer.reducer,
  restrictByMigration: restrictByMigrationSlice.reducer,
  sessionTimeoutModal: sessionTimeoutModalSlice.reducer,
  settingsModal: settingsModalSlice.reducer,
  toolkit: ToolkitReducer.reducer,
  user: UserReducer.reducer as Reducer<UserState>,

  // Enrollment reducers
  activeOrg: OrganizationReducers.activeOrg,
  countryCode: countryCodeReducer,
  emailVerificationToken: emailVerificationTokenReducer,
  enrollment: enrollmentReducer,
  enrollmentVerification: enrollmentVerificationReducer,
  geolocation: geolocationVerificationReducer,
  gingerSolera: gingerSoleraReducer,
  healthProvider: healthProviderReducer,
  language: languageReducer,
  memberEnrollment: OrganizationReducers.memberEnrollment,
  memberSubscriptionDetails: memberSubscriptionDetailReducer,
  onboarding: onboardingReducer,
  payment: paymentReducer,
  paymentMethodInfo: paymentMethodInfoReducer,
  solera: soleraReducer,
  sso: ssoReducer,
  voucherInfo: voucherInfoReducer,

  // Headspace Hub reducers
  eapAuthentication: eapAuthReducer,
  eapRequestForm: eapServiceRequestReducer,

  // Shared
  authentication: authReducer,
  modal: modalReducer,
});
