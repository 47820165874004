import { css } from '@emotion/react';
import {
  borderWidthXS,
  CoolGrey,
  cssWithMq,
  fontSizeS,
  rem,
  SpacingInRemBySize,
  White,
} from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  flex,
  justifyContentCenter,
  rowFlex,
} from '../../../styles/flex';

export const gridStyle = cssWithMq(flex, {
  margin: 'auto',
  width: [rem(35), rem(64.5), rem(70.5)],
});

export const membersTitleStyle = css(justifyContentCenter, {
  backgroundColor: White['000'],
  borderBottom: `${borderWidthXS} solid ${CoolGrey[100]}`,
  paddingTop: SpacingInRemBySize.L,
});

export const dateStyle = css({
  fontSize: fontSizeS,
  marginBottom: SpacingInRemBySize['2XS'],
});

export const colStyle = css({
  width: 'inherit',
});

export const nameStyle = css({
  marginBottom: SpacingInRemBySize['2XS'],
});

export const membersTitleInfoWrapperStyle = css(rowFlex, alignItemsCenter, {
  marginBottom: rem(3.125),
});

export const membersTitleInfoStyle = css({
  marginLeft: SpacingInRemBySize.L,
});

export const membersTitleLogoStyle = css({
  height: SpacingInRemBySize['6XL'],
});

export const membersTitleNavigationStyle = css({
  height: SpacingInRemBySize['3XL'],
});
