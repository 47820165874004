import { css } from '@emotion/core';
import {
  letterSpacingWider,
  lineHeightHeading,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  justifyContentCenter,
  rowFlex,
} from '../../../../styles/flex';
import { boxSizingBorderBox } from '../../../../styles';

export const eligibilityPanelStyle = css(boxSizingBorderBox, {
  padding: rem(1.875),
});

export const eligibilityTableStyles = css({
  letterSpacing: letterSpacingWider,
  linHeight: lineHeightHeading,
  marginTop: SpacingInRemBySize.L,
});

export const eligibilityUploadWrapperStyle = css(
  rowFlex,
  justifyContentCenter,
  alignItemsCenter,
);
