import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';

import { GetState } from '../../types';
import { OrganizationActionsTypes } from '../../types/organization';
import { ModalActionsTypes } from '../../types/modal';
import { hydrate } from './hydrate';
import { ErrorResponse, parseError } from '../../../utils/parseError';
import { resetError } from '../../../utils/messageDefaults';
import {
  initialContractFamilyMembersPerEmployeeValue,
  initialContractNumberOfFamilyMembersSeatsValue,
} from '../../reducers/manageOrganizationReducer';
import { PRIMARY_PRODUCT } from '../../../apps/dashboard/constants';
import { deleteOrganizationSubscription } from '../../../rest/org-subscriptions/org-subscriptions.api';

const { DELETE_ORGANIZATION_CONTRACT } = OrganizationActionsTypes;
const { OPEN_MODAL } = ModalActionsTypes;

export const deleteOrganizationContract = (term: string): ThunkMiddleware => {
  // @ts-ignore
  return async (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: { cachedOrg, orgId, future_contract_id },
    } = getState();

    try {
      await deleteOrganizationSubscription(future_contract_id as number, orgId);

      dispatch({
        payload: {
          [`${term}_contract_end_date`]: '',
          [`${term}_contract_end_dateError`]: resetError(),
          [`${term}_contract_id`]: null,
          [`${term}_contract_is_pilot`]: false,
          [`${term}_contract_opportunity_id`]: '',
          [`${term}_contract_opportunity_idError`]: resetError(),
          [`${term}_contract_primary_product`]: PRIMARY_PRODUCT.HEADSPACE,
          [`${term}_contract_payment_type`]: '',
          [`${term}_contract_seats`]: '',
          [`${term}_contract_seatsError`]: resetError(),
          [`${term}_contract_start_date`]: '',
          [`${term}_contract_start_dateError`]: resetError(),
          [`${term}_contract_voucher`]: '',
          [`${term}_contract_status`]: '',
          [`${term}_contract_family_members_per_employee`]: initialContractFamilyMembersPerEmployeeValue,
          [`${term}_contract_family_members_per_employeeError`]: resetError(),
          [`${term}_contract_number_of_family_members_seats`]: initialContractNumberOfFamilyMembersSeatsValue,
          [`${term}_contract_number_of_family_members_seatsError`]: resetError(),
        },
        type: DELETE_ORGANIZATION_CONTRACT,
      });
    } catch (err) {
      const { message } = parseError(err as ErrorResponse);
      dispatch({
        payload: {
          message,
        },
        type: OPEN_MODAL,
      });
      if (cachedOrg) dispatch(hydrate(cachedOrg));
    }
  };
};
