import { useState, useEffect } from 'react';
import { getCookie, setCookie } from '@headspace/web-auth/dist/utils/cookie';
import { CookieKeys } from '../utils/constants';
import { getConfig } from '../config';
import { HubSupportedLanguageCode } from '../types/language';

export const useGetHubLangCookie = (): [
  HubSupportedLanguageCode,
  (lang: string) => void,
] => {
  const hubLang = getCookie(CookieKeys.HUB_LANGUAGE);
  const [hubLangCookie, setHubLangCookie] = useState(
    hubLang ?? HubSupportedLanguageCode.en,
  );
  const cookieConfiguration = getConfig().ENROLLMENT_AUTH0_COOKIE
    .COOKIE_CONFIGURATION;

  useEffect(() => {
    if (!hubLang) {
      setCookie(
        CookieKeys.HUB_LANGUAGE,
        HubSupportedLanguageCode.en,
        null,
        cookieConfiguration,
      );
    } else if (hubLang !== hubLangCookie) {
      setCookie(
        CookieKeys.HUB_LANGUAGE,
        hubLangCookie,
        null,
        cookieConfiguration,
      );
    }
  }, [hubLangCookie]);

  return [hubLangCookie, setHubLangCookie];
};
