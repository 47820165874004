import * as React from 'react';
import { Orange, White } from '@headspace/web-ui-components';
import download from 'assets/download.svg';
import {
  panelStyle,
  firstColumnStyle,
  titleSectionStyle,
  buttonColumnStyle,
} from './styles';
import { Container } from '../container/Container';
import { Panel } from '../Panel';
import { TitleXS, UIRegularM } from '../text';
import { IconButtonPronounced } from '../buttons/IconButtonPronounced';
import { useTranslate } from '../../hooks/useTranslate';

export interface DownloadReportProps {
  handleDownload(): void;
  messages: {
    title: string;
    message: string;
    label: string;
  };
}

export const DownloadReportView: React.FC<DownloadReportProps> = ({
  handleDownload,
  messages,
}) => {
  const { translate } = useTranslate();

  return (
    <Container>
      <Panel css={panelStyle}>
        <div css={titleSectionStyle}>
          <div css={firstColumnStyle}>
            <TitleXS>{translate(messages.title)}</TitleXS>
            <UIRegularM>{translate(messages.message)}</UIRegularM>
          </div>
          <div css={buttonColumnStyle}>
            <IconButtonPronounced
              src={download}
              onClick={handleDownload}
              color={Orange[200]}
              backgroundColor={Orange[200]}
              fill={White['000']}
              label={translate(messages.label)}
              cursor={undefined}
            />
          </div>
        </div>
      </Panel>
    </Container>
  );
};
