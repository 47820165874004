import { dashboardMessages } from '../i18n/dashboardMessages';
import { SupportedLanguageCode } from '../types/language';

export const translateError = (
  error: Error,
  locale: string = SupportedLanguageCode.en,
  id = '',
  value = '',
) =>
  dashboardMessages[locale][`validationErrors.${error.message}`]
    .replace('{inputName}', id)
    .replace('{inputValue}', `${value}`);
