import { WarmGrey, fontSize2XL } from '@headspace/web-ui-components';
import VirginPulseLogo from 'assets/virgin-pulse-logo.png';
import { FC } from 'react';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { CheckIcon } from '../../../../../../shared-components/Icons/CheckIcon';
import { ConfiguredLinkIdentitiesForm } from '../../../AuthComponent';
import { CollapsibleInfo } from '../../../VPCare/Components/CollapsibleInfo';
import { HorizontalDivider } from '../../../shared-components/Divider/Divider';
import { FormContainer } from '../../../shared-components/FormContainer/FormContainer';
import {
  activityDataSectionStyle,
  containerStyle,
  customIdentitiesComponentStyle,
  logo,
  logoContainer,
  sectionHeaderStyle,
  subHeaderStyle,
} from './styles/preLinkPageStyle';

interface ConsentProps {
  consentDetails?: string[];
  description?: string;
  title?: string;
  subtitle?: string;
  bigLogo?: boolean;
}

const PreLinkPageMessageBody: FC<ConsentProps> = ({
  consentDetails,
  description,
  subtitle,
  bigLogo = false,
}) => {
  const { translate } = useTranslate();

  return (
    <div css={containerStyle} data-testid="prelink-message-body-container">
      <div css={subHeaderStyle(bigLogo)}>
        <p data-testid="prelink-sub-header-text">
          {description ||
            translate('ssoEnrollment.connectToVirginPulse.subHeader')}
        </p>
      </div>
      <div css={logoContainer(bigLogo)} data-testid="virgin-pulse-logo">
        <img css={logo} src={VirginPulseLogo} alt="virgin-pulse-logo" />
      </div>

      <div css={sectionHeaderStyle}>
        <p>
          {subtitle ||
            translate('ssoEnrollment.connectToVirginPulse.messageBody')}
        </p>
      </div>

      {consentDetails && consentDetails.length > 0 ? (
        <CollapsibleInfo
          title={translate('enrollment.vpCareConsent.collapsibleTitle')}
          listItems={consentDetails}
        />
      ) : (
        <>
          <HorizontalDivider marginTop={20} marginBottom={5} />
          <div css={activityDataSectionStyle}>
            <p data-testid="data-access-text">
              {translate(
                'ssoEnrollment.connectToVirginPulse.accessDataMessage',
              )}
            </p>
            <CheckIcon color={WarmGrey[600]} height={12} width={12} />
          </div>
          <HorizontalDivider marginTop={5} marginBottom={1} />
        </>
      )}
    </div>
  );
};

type LinkIdentitiesFormOverrides = {
  connectButtonOverrides?: unknown;
  overrideManageIdentitiesDescription?: unknown;
};

const PreLinkComponent: FC<ConsentProps & LinkIdentitiesFormOverrides> = ({
  title,
  consentDetails,
  description,
  subtitle,
  bigLogo,
  overrideManageIdentitiesDescription,
  connectButtonOverrides,
}) => {
  const { translate } = useTranslate();
  const consentProps = {
    title,
    consentDetails,
    description,
    subtitle,
    bigLogo,
  };
  const linkIdentitiesFormOverrides = {
    overrideManageIdentitiesDescription,
    connectButtonOverrides,
  };
  const headerStyleOverrides = {
    fontSize: bigLogo ? fontSize2XL : undefined,
  };

  return (
    <FormContainer
      headerTag="h4"
      title={title || translate('ssoEnrollment.connectToVirginPulse.header')}
      headerStyleOverrides={headerStyleOverrides}
    >
      <PreLinkPageMessageBody {...consentProps} />
      <div css={customIdentitiesComponentStyle}>
        <ConfiguredLinkIdentitiesForm {...linkIdentitiesFormOverrides} />
      </div>
    </FormContainer>
  );
};

export default PreLinkComponent;
