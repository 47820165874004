import { useQuery } from '@tanstack/react-query';
import { getVerificationLink } from '../rest/organization/organization.api';
import { GetVerificationLinkResponse } from '../rest/organization/organization.types';
import { ApiErrors } from '../utils/parseError';

type UseGetVerificationLink = (
  orgId: number,
  email: string,
) => {
  isFetching: boolean;
  error: null | ApiErrors;
  data:
    | undefined
    | {
        [key: string]: GetVerificationLinkResponse;
      };
  refetch: () => void;
};

export const useGetVerificationLink: UseGetVerificationLink = (
  orgId: number,
  email: string,
) =>
  useQuery(
    ['verificationLink'],
    async () => getVerificationLink(orgId, email),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
