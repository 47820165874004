import * as R from 'ramda';
import { MembersOptionsType } from 'state/slices/members/getMembers/getMembersThunk';
import {
  MembersExportQueryParams,
  MembersExportSortableFields,
  MemberSortableFields,
  MembersSearchQuery,
  MemberStatus,
  SortDirection,
} from './members.types';
import { removeDoubleQuoteAndBackslash } from '../../utils/validations';
import { VISIBLE_MEMBER_STATUSES } from '../../apps/dashboard/constants';
import { CSVOptions } from '../../apps/dashboard/OrganizationView/MembersView/types';
import { handleEmptyArray } from '../../state/helpers';
import { getConfig } from '../../config';

const REQUEST_MEMBERS_LIMIT = 50;
const { BYPASS_ENROLLMENT_FILTERING_ORG_ID_ALLOWLIST } = getConfig();

export const getSearchInputQuery = (term) =>
  removeDoubleQuoteAndBackslash(term);

export const getStatusQueryPart = (statuses: string[] | undefined) =>
  (statuses && !R.isEmpty(statuses) ? statuses : VISIBLE_MEMBER_STATUSES).map(
    (s) => MemberStatus[s.toUpperCase()],
  );

export const buildMemberSearchQuery = (
  orgId: number,
  options: MembersOptionsType,
): MembersSearchQuery => {
  const { field, direction } = options.sort;
  const bypassStatusFilter = BYPASS_ENROLLMENT_FILTERING_ORG_ID_ALLOWLIST.includes(
    orgId,
  );
  const query: MembersSearchQuery = {
    limit: REQUEST_MEMBERS_LIMIT,
    offset: options.offset || 0,
    orderDirection: SortDirection[direction.toUpperCase()],
    orderField: MemberSortableFields[field.toUpperCase()],
    orgId,
    status: getStatusQueryPart(options.status),
    bypassStatusFilter,
  };

  if (options.search) {
    query.term = options.search;
  }
  return query;
};

export const buildMemberExportQueryParams = (
  orgId: number,
  options: CSVOptions,
): MembersExportQueryParams => {
  const bypassStatusFilter = BYPASS_ENROLLMENT_FILTERING_ORG_ID_ALLOWLIST.includes(
    orgId,
  );
  const search = getSearchInputQuery(options.search);
  const includeIds = options.filterWith && handleEmptyArray(options.filterWith);
  const query: MembersExportQueryParams = {
    orderDirection: SortDirection[options.sort[1]?.toUpperCase()],
    orderField: MembersExportSortableFields[options.sort[0]?.toUpperCase()],
    status: getStatusQueryPart(options.status),
    bypassStatusFilter,
  };

  if (includeIds) query.includeIds = includeIds;
  if (search) query.search = search;

  return query;
};

export const transformToURLSearchParams = (query: MembersExportQueryParams) => {
  const { status, includeIds, bypassStatusFilter, ...params } = query;
  const urlSearchParams = new URLSearchParams(Object.entries(params));
  if (bypassStatusFilter !== undefined) {
    urlSearchParams.append('bypassStatusFilter', String(bypassStatusFilter));
  }
  status?.forEach((val) => urlSearchParams.append('status', val));
  includeIds?.forEach((val) => urlSearchParams.append('includeIds', `${val}`));

  return urlSearchParams;
};
