import reactHtmlParser from 'react-html-parser';
import { Dispatch } from 'redux';

import { GetState, ThunkResult } from '../../types';
import { OrganizationActionsTypes } from '../../types/organization';
import { DeleteCallbackTypes, ModalActionsTypes } from '../../types/modal';
import { getLocale } from '../../selectors/getLocale';
import { dashboardMessages } from '../../../i18n/dashboardMessages';

const { HANDLE_UPDATE } = OrganizationActionsTypes;
const { OPEN_MODAL } = ModalActionsTypes;
const { CONFIRM_DELETE } = DeleteCallbackTypes;

export const deleteOrganization = (): ThunkResult<Promise<void>> => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { manageOrganization } = state;
    const { cachedOrg: { subscription } = {} } = manageOrganization;
    const locale = getLocale(state);
    const translationsForLocale = dashboardMessages[locale];

    if (subscription && subscription.activeMembers > 0) {
      dispatch({
        type: OPEN_MODAL,
        payload: {
          message: `${
            translationsForLocale['organizationSettings.delete.activeOrgError']
          }. ${
            translationsForLocale[
              'organizationSettings.delete.activeOrgErrorExplain'
            ]
          } ${reactHtmlParser(
            translationsForLocale[
              'organizationSettings.delete.activeOrgErrorMemberCount'
            ].replace('{memberTotal}', String(subscription.activeMembers)),
          )}`,
        },
      });
    } else {
      // get state for modal if there is a confirm action
      dispatch({
        type: OPEN_MODAL,
        payload: {
          title:
            translationsForLocale['organizationSettings.delete.deleteWarn'],
          message:
            translationsForLocale[
              'organizationSettings.delete.deleteWarnExplain'
            ],
          confirmCallback: CONFIRM_DELETE,
        },
      });
      // When the modal is confirmed another thunk is dispatch to execute the delete orgMutation
    }

    dispatch({ type: HANDLE_UPDATE, payload: {} });
  };
};
