import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { rem } from '@headspace/web-ui-components';
import VirginPulseLogo from 'assets/virgin-pulse-logo.png';
import { EnrollmentHeader } from '../../../shared-components/Header';
import JoinMessage from '../../../shared-components/JoinMessage/JoinMessage';
import { Footer } from '../../../../../../shared-components/Footer/Footer';
import { Footer as VPFooter } from '../../../shared-components/Footer/index';
import { HealthProvider } from '../../../../constants/healthProvider';
import { setHealthProvider } from '../../../../state/actions/memberEnrollActions/memberEnrollActions';
import {
  alignItemsCenter,
  alignItemsSpaceBetween,
  columnFlex,
  justifyContentCenter,
  justifyContentSpaceBetween,
  rowFlex,
} from '../../../../../dashboard/styles/flex';

const pageWrapperStyle = css(
  columnFlex,
  justifyContentSpaceBetween,
  alignItemsSpaceBetween,
  {
    height: '100%',
  },
);

const containerStyle = css(rowFlex, alignItemsCenter, justifyContentCenter, {
  minHeight: rem(40.625),
  '@media (max-width: 560px)': {
    minHeight: rem(11.25),
    margin: 0,
    padding: 0,
  },
  '@media (min-width: 1000px)': {
    paddingTop: rem(4.375),
  },
});

const joinContainerStyle = css({
  '@media (max-width: 800px)': {
    display: 'none',
  },
});

const Body = ({ children, showJoinMessage }) => (
  <div css={containerStyle} data-testid="page-body-container">
    {showJoinMessage ? (
      <div css={joinContainerStyle} data-testid="join-message-container">
        <JoinMessage />
      </div>
    ) : null}
    {children}
  </div>
);

interface PageWrapperProps {
  children: React.ReactNode;
  healthProvider?: HealthProvider;
  showJoinMessage?: boolean;
}

export const PageWrapper: FC<PageWrapperProps> = ({
  children,
  healthProvider,
  showJoinMessage = true,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHealthProvider(healthProvider));
  }, [dispatch, healthProvider]);

  return (
    <div css={pageWrapperStyle}>
      <EnrollmentHeader
        showStepComponent={true}
        healthProvider={healthProvider}
        healthProviderLogo={
          healthProvider === HealthProvider.VP ? VirginPulseLogo : ''
        }
      />
      <Body showJoinMessage={showJoinMessage}>{children}</Body>
      <VPFooter />
      <Footer />
    </div>
  );
};
