import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@headspace/web-ui-components';
import { Container } from '../../../../../../shared-components/container/Container';
import { Input } from '../../../../../../shared-components/Input';
import { SettingsPanel } from '../shared-components/SettingsPanel/SettingsPanel';
import {
  VIEW_PANEL_COUNT,
  EAP_SERVICE_TYPES,
  ORGANIZATION_SUPPLEMENTAL_DETAILS,
} from '../../../../constants';
import {
  getOrgManagement,
  getSettingsChangedStatus,
} from '../../../../../../state/selectors/organizationSelectors';
import {
  cancelOrganizationChanges,
  handleChange as handleChangeAction,
  saveOrganizationBasic,
  updateImplicitState,
  toggleEditPanelView,
} from '../../../../../../state/actions/organizationActions';
import {
  TranslateFn,
  useTranslate,
} from '../../../../../../hooks/useTranslate';
import { EAP_PROVIDERS, EapTypes } from '../../../../../../types/organization';

import {
  contractContainerStyle,
  rowStyle,
} from '../CurrentContractPanel/styles';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../constants/featureFlags';

export const SupplementalPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const [isEapV2Enabled] = useFeatureFlag(FEATURE_FLAG.ENABLE_EAP_V2);
  const {
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
    externalOrgId,
    externalOrgIdError,
    phoneNumber,
    phoneNumberError,
    provider,
    serviceType,
    coverageTotal,
    current_contract_eap_type,
    current_contract_end_date,
    future_contract_eap_type,
    future_contract_start_date,
    parentOrg,
    childOrgs,
  } = useSelector(getOrgManagement);
  const settingsChanged = useSelector(getSettingsChangedStatus);
  const title = translate('organizationSettings.supplementalDetails.title');
  const [isParentContractEap, setParentsContractEap] = useState(false);
  const subtitle = getSubtitleKey(
    current_contract_eap_type,
    current_contract_end_date,
    future_contract_eap_type,
    future_contract_start_date,
    translate,
    isParentContractEap,
  );

  useEffect(() => {
    if (parentOrg !== null) {
      const eap = parentOrg.contracts.find(
        (contract) => contract.eapType === EapTypes.EAP_V2,
      );
      setParentsContractEap(!!eap);
    }
  }, [parentOrg]);

  const contractHasEap =
    isParentContractEap ||
    current_contract_eap_type === EapTypes.EAP_V2 ||
    future_contract_eap_type === EapTypes.EAP_V2;
  const hasErrors = externalOrgIdError.error || phoneNumberError.error;
  const isPanelInView = viewPanels[ORGANIZATION_SUPPLEMENTAL_DETAILS];

  const handleChange = useCallback(
    (e) => dispatch(handleChangeAction(e, isEapV2Enabled)),
    [dispatch],
  );

  const handleError = useCallback(
    (e) => {
      dispatch(updateImplicitState(e, isEapV2Enabled));
    },
    [dispatch],
  );

  const handleCancel = useCallback(
    () => dispatch(cancelOrganizationChanges()),
    [dispatch],
  );

  const handleSaveChanges = useCallback(
    () => dispatch(saveOrganizationBasic()),
    [dispatch],
  );

  const handleToggleView = React.useCallback(
    (index) => {
      dispatch(toggleEditPanelView(index));
    },
    [dispatch],
  );

  const externalOrgIdLabel = translate(
    'organizationSettings.basic.externalOrgIdLabel',
  );
  const externalOrgIdPlaceholder = translate(
    'organizationSettings.basic.externalOrgIdPlaceholder',
  );
  const phoneNumberLabel = translate(
    'organizationSettings.basic.phoneNumberLabel',
  );
  const coverageTotalLabel = translate(
    'organizationSettings.basic.coverageTotalLabel',
  );
  const providerLabel = translate('organizationSettings.basic.provider');
  const serviceTypeLabel = translate('organizationSettings.basic.serviceType');
  const serviceTypeValue = serviceType
    ? translate(
        `organizationSettings.basic.serviceType.${serviceType.toLocaleLowerCase()}`,
      )
    : '';
  const serviceTypeTherapy = translate(
    `organizationSettings.basic.serviceType.therapy`,
  );
  const serviceTypePsychiatry = translate(
    `organizationSettings.basic.serviceType.psychiatry`,
  );
  const serviceTypePsychiatryTherapy = translate(
    `organizationSettings.basic.serviceType.psychiatry_therapy`,
  );

  if (
    !isEapV2Enabled ||
    !contractHasEap ||
    (parentOrg === null && childOrgs.length > 0) ||
    (parentOrg !== null && !isParentContractEap)
  ) {
    return null;
  }

  return (
    <Container>
      <SettingsPanel
        dataTestId="supplemental-information-panel"
        title={title}
        subtitle={subtitle}
        view={isPanelInView}
        toggleView={handleToggleView}
        cancelChanges={handleCancel}
        saveChanges={handleSaveChanges}
        index={ORGANIZATION_SUPPLEMENTAL_DETAILS}
        displayEditButton={viewPanels.indexOf(false) < 0}
        settingsChanged={settingsChanged}
        hasErrors={hasErrors}
      >
        <div css={contractContainerStyle}>
          <div css={rowStyle}>
            <Input
              type={isPanelInView ? 'review' : 'text'}
              name="externalOrgId"
              placeholder={externalOrgIdPlaceholder}
              label={externalOrgIdLabel}
              value={externalOrgId}
              onChange={handleChange}
              onBlur={handleError}
              hasError={externalOrgIdError.error}
              errorMessage={externalOrgIdError.message}
              data-testid="externalOrgId"
            />
            <Input
              type={isPanelInView ? 'review' : 'text'}
              name="phoneNumber"
              placeholder="000-000-0000"
              label={phoneNumberLabel}
              value={phoneNumber}
              onChange={handleChange}
              onBlur={handleError}
              hasError={phoneNumberError.error}
              errorMessage={phoneNumberError.message}
              data-testid="phoneNumber"
            />
          </div>
          <Divider />
          <div css={rowStyle}>
            <Input
              type={isPanelInView ? 'review' : 'select'}
              name="serviceType"
              label={serviceTypeLabel}
              value={isPanelInView ? serviceTypeValue : serviceType}
              onChange={handleChange}
              data-testid="serviceType"
              disabled={!isEapV2Enabled}
              options={[
                {
                  title: serviceTypeTherapy,
                  value: EAP_SERVICE_TYPES.THERAPY,
                },
                {
                  title: serviceTypePsychiatry,
                  value: EAP_SERVICE_TYPES.PSYCHIATRY,
                },
                {
                  title: serviceTypePsychiatryTherapy,
                  value: EAP_SERVICE_TYPES.PSYCHIATRY_THERAPY,
                },
              ]}
            />
            <Input
              type={isPanelInView ? 'review' : 'select'}
              name="provider"
              label={providerLabel}
              value={provider}
              onChange={handleChange}
              data-testid="provider"
              disabled={!isEapV2Enabled}
              options={[
                {
                  title: EAP_PROVIDERS.CCA,
                  value: EAP_PROVIDERS.CCA,
                },
                {
                  title: EAP_PROVIDERS.WPO,
                  value: EAP_PROVIDERS.WPO,
                },
              ]}
            />
          </div>
          <Divider />
          <div css={rowStyle}>
            <Input
              type={isPanelInView ? 'review' : 'number'}
              name="coverageTotal"
              placeholder="0"
              label={coverageTotalLabel}
              value={coverageTotal}
              onChange={handleChange}
              onBlur={handleError}
              data-testid="coverageTotal"
            />
          </div>
        </div>
      </SettingsPanel>
    </Container>
  );
};

const getSubtitleKey = (
  currentContractEapType: EapTypes,
  currentContractEndDate: string,
  futureContractEapType: EapTypes,
  futureContractStartDate: string,
  translate: TranslateFn,
  isParentContractEap: boolean,
): string => {
  if (
    (currentContractEapType === EapTypes.EAP_V2 &&
      futureContractEapType === EapTypes.EAP_V2) ||
    isParentContractEap
  ) {
    return translate(
      'organizationSettings.supplementalDetails.eap.subtitle.default',
    );
  }
  if (
    currentContractEapType === EapTypes.EAP_V2 &&
    (!futureContractEapType || futureContractEapType === EapTypes.NONE)
  ) {
    return translate(
      'organizationSettings.supplementalDetails.eap.subtitle.current',
      { currentContractEndDate },
    );
  }
  if (
    (!currentContractEapType || currentContractEapType === EapTypes.NONE) &&
    futureContractEapType === EapTypes.EAP_V2
  ) {
    return translate(
      'organizationSettings.supplementalDetails.eap.subtitle.future',
      { futureContractStartDate },
    );
  }

  return '';
};
