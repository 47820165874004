export enum SurveyStatusEnum {
  STAGED = 'STAGED',
  SENT = 'SENT',
  ENDED = 'ENDED',
}

export type SurveyStatus =
  | SurveyStatusEnum.STAGED
  | SurveyStatusEnum.SENT
  | SurveyStatusEnum.ENDED;

export type uuid = string;
export type Survey = {
  externalId: string;
  name: string;
  orgId: number;
  surveyProvider: string;
  surveyStatus: string;
  createdOn: Date;
  updatedOn: Date;
  endDate: Date;
  launchDate?: Date;
  id: uuid;
};

export type SurveyResponse = {
  surveys: Survey[] | [];
};
