import { TertiaryButton, WarmGrey } from '@headspace/web-ui-components';
import { CountryCode } from '@headspace/web-payments';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../../../state/selectors/languageSelector/languageSelector';
import { SupportedLanguageCode } from '../../../../../../types/language';
import { useGetCountryCode } from '../../../../../../hooks/useGetCountryCode';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  immediateCrisisButtonStyle,
  immediateCrisisContainerStyle,
  immediateCrisisLinkStyle,
} from './styles/immediateCrisisSupportStyle';
import { Icon } from '../../../../../../shared-components/Icon';
import caretRightSvg from '../../../../../../assets/caretRightTundora.svg';

export const URGENT_HELP_LINK = 'https://www.headspace.com/urgent-help';
export const MENTAL_HEALTH_RESOURCES_LINK =
  'https://www.headspace.com/mental-health-resources';

interface ImmediateCrisisSupportSectionProps {
  isMobile?: boolean;
}

export const ImmediateCrisisSupportSection = ({
  isMobile,
}: ImmediateCrisisSupportSectionProps) => {
  const { translate } = useTranslate();
  const language = useSelector(getLanguage);
  const { data: countryCode } = useGetCountryCode();

  const containerTestId = useMemo(
    () =>
      `immediate-crisis-support-container-${isMobile ? 'mobile' : 'desktop'}`,
    [isMobile],
  );

  const helpLink = useMemo(
    () =>
      countryCode === CountryCode.US && language === SupportedLanguageCode.en
        ? URGENT_HELP_LINK
        : MENTAL_HEALTH_RESOURCES_LINK,
    [countryCode, language],
  );

  return (
    <div css={immediateCrisisContainerStyle} data-testid={containerTestId}>
      <div>
        <h4>{translate('onboarding.footer.needHelp')}</h4>
      </div>
      <a
        href={helpLink}
        target="_blank"
        rel="noopener noreferrer"
        css={immediateCrisisLinkStyle}
      >
        <TertiaryButton css={immediateCrisisButtonStyle} onClick={() => null}>
          <span>
            {translate('onboarding.footer.immediateCrisisSupport')}
            <Icon
              src={caretRightSvg}
              width={12}
              height={12}
              fill={WarmGrey[700]}
            />
          </span>
        </TertiaryButton>
      </a>
    </div>
  );
};
