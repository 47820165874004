import {
  addDays,
  format,
  isFuture,
  isPast,
  isToday,
  subDays,
} from '../../../../utils/dates';

export enum statusValue {
  notStarted = 'Not Started',
  inProgress = 'In Progress',
  completed = 'Completed',
  canceled = 'Canceled',
}

const dateLocale = 'en-us';
const ISORegex = /(\d{4}-\d{2}-\d{2})T\d{2}:\d{2}:\d{2}.\d{3}Z/;
const secondsInMinute = 60;
const secondsInHour = 60 * secondsInMinute;
const timezoneOffsetRegex = /(\+|-)(\d{2}):(\d{2})/;

// MM/DD/YYYY
export const formatDateFromISO = (date: string): string => {
  return new Date(date).toLocaleDateString(dateLocale, {
    timeZone: 'utc',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export const datesToString = ({ start, end }): string => {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'utc',
    year: undefined,
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  const startDate = new Date(start);
  const endDate = new Date(end);
  const year = endDate.getUTCFullYear();
  const startDay = startDate.toLocaleTimeString(dateLocale, options);
  const endDay = endDate.toLocaleTimeString(dateLocale, options);

  return `${startDay} - ${endDay}, ${year}`;
};

// Converts MM/DD/YYYY to ISO format and resets time to the beginning of the day
export const formatDateToISO = (date: string): string => {
  const timeSuffix = 'T00:00:00+00:00';
  // ISO dates are just reset to beginning of day

  if (isISODate(date)) {
    const matches = date.match(ISORegex);
    return matches && matches.length ? `${matches[1]}${timeSuffix}` : date;
  }

  const arr: string[] = date.split('/');
  return `${arr[2]}-${arr[0]}-${arr[1]}${timeSuffix}`;
};

export const isISODate = (date: string): boolean => ISORegex.test(date);
export const timezoneOffsetToSeconds = (timezoneOffset = '+00:00') => {
  const matches = timezoneOffset.match(timezoneOffsetRegex);

  if (!matches || matches.length !== 4) return 0;

  const sign = matches[1] === '+' ? 1 : -1;
  const hours = parseInt(matches[2], 10);
  const minutes = parseInt(matches[3], 10);

  return sign * (hours * secondsInHour + minutes * secondsInMinute);
};

export const startDateToISO = (
  date,
  timezoneOffset = '+00:00',
  shouldInvertOffset = false,
): string => {
  // When challenges are created we need to subtract the timezone offset
  const brazeStartDate = format(subDays(date, 21), 'MM/DD/YYYY');
  const offsetInSeconds =
    timezoneOffsetToSeconds(timezoneOffset) * (shouldInvertOffset ? -1 : 1);
  const newDate = new Date(formatDateToISO(brazeStartDate));
  newDate.setSeconds(newDate.getSeconds() + offsetInSeconds);
  return newDate.toISOString();
};

export const endDate = (startDate, durationInWeeks): string => {
  const endDate = addDays(startDate, 7 * durationInWeeks);
  return endDate.toISOString();
};

export const getTimeZoneAbbr = (date: string): string | undefined => {
  const timezoneOffset = format(date, 'Z');
  const timezoneAbbr = timeZones.find((time) => timezoneOffset === time.value)
    ?.abbr;

  return timezoneAbbr;
};

export const timeZones = [
  { label: 'Midway Islands Time (-11:00)', value: '-11:00', abbr: 'SST' },
  { label: 'Hawaii Standard Time (-10:00)', value: '-10:00', abbr: 'HST' },
  { label: 'Alaska Standard Time (-09:00)', value: '-09:00', abbr: 'AKST' },
  { label: 'Pacific Standard Time (-08:00)', value: '-08:00', abbr: 'PST' },
  { label: 'Mountain Standard Time (-07:00)', value: '-07:00', abbr: 'MST' },
  { label: 'Central Standard Time (-06:00)', value: '-06:00', abbr: 'CST' },
  { label: 'Eastern Standard Time (-05:00)', value: '-05:00', abbr: 'EST' },
  { label: 'Puerto Rico Time (-04:00)', value: '-04:00', abbr: 'AST' },
  { label: 'Canada Newfoundland Time (-03:30)', value: '-03:30', abbr: 'NST' },
  { label: 'Brazil Eastern Time (-03:00)', value: '-03:00', abbr: 'BRT' },
  { label: 'Central African Time (-01:00)', value: '-01:00', abbr: 'CAT' },
  {
    label: 'Universal Coordinated Time (+00:00)',
    value: '+00:00',
    abbr: 'UTC',
  },
  { label: 'European Central Time (+01:00)', value: '+01:00', abbr: 'CET' },
  { label: 'Eastern European Time (+02:00)', value: '+02:00', abbr: 'EET' },
  { label: 'Eastern African Time (+03:00)', value: '+03:00', abbr: 'EAT' },
  { label: 'Near East Time (+04:00)', value: '+04:00', abbr: 'GST' },
  { label: 'Pakistan Lahore Time (+05:00)', value: '+05:00', abbr: 'PKT' },
  { label: 'India Standard Time (+05:30)', value: '+05:30', abbr: 'IST' },
  { label: 'Bangladesh Standard Time (+06:00)', value: '+06:00', abbr: 'BST' },
  { label: 'Vietnam Standard Time (+07:00)', value: '+07:00', abbr: 'ICT' },
  { label: 'China Taiwan Time (+08:00)', value: '+08:00', abbr: 'CST' },
  { label: 'Japan Standard Time (+09:00)', value: '+09:00', abbr: 'JST' },
  { label: 'Australia Central Time (+09:30)', value: '+09:30', abbr: 'ACST' },
  { label: 'Australia Eastern Time (+10:00)', value: '+10:00', abbr: 'AEST' },
  { label: 'Solomon Standard Time (+11:00)', value: '+11:00', abbr: 'SBT' },
];

export const challengeTypeOptions = [
  { label: 'Meditation', value: 'meditation' },
  { label: 'Mindfulness', value: 'all' },
];

export const challengeMemberStatusesOptions = [
  { label: 'Enrolled', value: 'ACTIVE' },
  { label: 'Eligible', value: 'ELIGIBLE' },
  { label: 'Enrolled + Eligible', value: 'ACTIVE, ELIGIBLE' },
];

export const challengeDurationOptions = [
  { label: '2 weeks', value: '2' },
  { label: '3 weeks', value: '3' },
  { label: '4 weeks', value: '4' },
];

export const challengeGoalTypeOptions = [
  { label: 'Content Starts', value: 'contentStarts' },
];

export const setChallengeStatus = (startDate, endDate, status) => {
  let value;

  switch (true) {
    case status === 'CANCELLED':
      value = statusValue.canceled;
      break;
    case (isPast(startDate) || isToday(startDate)) &&
      (isFuture(endDate) || isToday(endDate)):
      value = statusValue.inProgress;
      break;
    case isFuture(startDate):
      value = statusValue.notStarted;
      break;
    case isPast(endDate):
      value = statusValue.completed;
      break;
    default:
      value = '';
  }

  return value;
};
