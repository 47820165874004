import {
  OrganizationActionsTypes,
  HandleUpdateAction,
} from '../../types/organization';

const { HANDLE_UPDATE } = OrganizationActionsTypes;

export const handleUpdate = (): HandleUpdateAction => {
  return {
    type: HANDLE_UPDATE,
    payload: null,
  };
};
