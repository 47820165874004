import { Blue, rem, White } from '@headspace/web-ui-components';
import { buttonContainer } from './styles';
import {
  TextButton,
  TextButtonProps,
} from '../../../../../shared-components/buttons/TextButton';

export const SoleraErrorGoToButton = (props: TextButtonProps) => {
  return (
    <div css={buttonContainer}>
      <TextButton
        {...props}
        textColor={White['000']}
        hoverColor={Blue[200]}
        backgroundColor={Blue[200]}
        dataTestId="go-to-button"
        pill={true}
        width={rem(15.625)}
      />
    </div>
  );
};
