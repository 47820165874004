import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { en } from './en';
import { fr } from './fr';
import { de } from './de';
import { es_419 } from './es_419';
import { pt_br } from './pt_BR';
import { cs } from './cs';
import { zh_CN } from './zh_CN';
import { hi } from './hi';
import { hu } from './hu';
import { it } from './it';
import { ja } from './ja';
import { ms } from './ms';
import { nl } from './nl';
import { pl } from './pl';
import { ru } from './ru';

export const options = {
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
    wait: false,
  },
  resources: {
    de,
    en,
    es: es_419,
    fr,
    pt: pt_br,
    cs,
    hi,
    hu,
    it,
    ja,
    ms,
    nl,
    pl,
    ru,
    zh_CN,
  },
};

i18n.use(LngDetector).init(options as any);

export { i18n };
