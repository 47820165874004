import { createAsyncThunk } from '@reduxjs/toolkit';
import { RESTRICT_BY_MIGRATION_REDUCER_NAME } from './constants';
import { ErrorResponse, parseError } from '../../../utils/parseError';
import { openSettingsModal } from '../settingsModal';
import { preUpdateRestrictByChangeWithPolling } from './utils';

export const uploadDomains = createAsyncThunk(
  `${RESTRICT_BY_MIGRATION_REDUCER_NAME}/uploadDomains`,
  async ({ restrictedDomains, restrictBy, orgId }: any, { dispatch }) => {
    try {
      const elFileReport = await preUpdateRestrictByChangeWithPolling(
        orgId,
        restrictBy,
        true,
        {
          restrictedDomains,
        },
      );
      return elFileReport;
    } catch (error) {
      const { message, title = '' } = parseError(error as ErrorResponse);
      dispatch(openSettingsModal({ message, title }));

      throw error;
    }
  },
);
