import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import plus from '../../../assets/plus.svg';
import { useOrgListFiltering } from '../../../featureFlags/orgListFiltering';
import { useTranslate } from '../../../hooks/useTranslate';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Icon } from '../../../shared-components/Icon';
import { SpinnerWithBackGround } from '../../../shared-components/Spinner';
import { Toast } from '../../../shared-components/Toast';
import { TextButton } from '../../../shared-components/buttons/TextButton';
import { Container } from '../../../shared-components/container/Container';
import { TableFilterBar } from '../../../shared-components/tables/FilterBar';
import { TableSection } from '../../../shared-components/tables/TableSection';
import { tableStyle } from '../../../shared-components/tables/styles';
import { TableSectionProps } from '../../../shared-components/tables/types';
import { rendererRow } from '../../../shared-components/tables/utils';
import { drain } from '../../../state/actions/organizationActions';
import {
  createOrganizationAction,
  fetchOrganizationsAction,
  searchOrganizationsAction,
  selectOrganizationAction,
} from '../../../state/actions/organizationsActions';
import { getChallengeCreated } from '../../../state/selectors/challengesSelectors';
import { getOrganizations } from '../../../state/selectors/organizationSelectors';
import { resetMembers } from '../../../state/slices/members/membersSlice';
import { debounce } from '../../../utils';
import { useUpdateEffect } from '../../../utils/effects';
import {
  COLUMN_WIDTH_OFFSET,
  HEIGHT_OFFSET,
  PRIMARY_PRODUCT,
} from '../constants';
import { OrganizationFilterDropdown } from './OrganizationFilterDropdown';
import { OrganizationsTitle } from './OrganizationsTitle';
import { generateHeaderRow } from './generateHeaderRow';
import { generateRows } from './generateRows';
import {
  actionsStyle,
  noResultsFoundStyle,
} from './styles/organizationsPageStyle';

const searchOrganizations = (
  dispatch,
  searchTerm,
  offset = 0,
  primaryProduct?: PRIMARY_PRODUCT[],
) =>
  dispatch(
    fetchOrganizationsAction({
      query: {
        offset,
        primaryProduct: primaryProduct ?? [],
        search: searchTerm,
      },
      refresh: true,
    }),
  );

const searchOrganizationsDebounced = debounce(
  (dispatch, searchTerm, primaryProduct) => {
    searchOrganizations(dispatch, searchTerm, undefined, primaryProduct);
  },
  500,
);

export const Organizations: FC = () => {
  const { windowInnerHeight } = useWindowResize();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const challengeCreated = useSelector(getChallengeCreated);
  const {
    searchTerm,
    isFetching,
    totalOrgCount,
    OrganizationList: organizations = [],
  } = useSelector(getOrganizations);
  const [withOrgListFiltering] = useOrgListFiltering();
  const filterProps = {
    columnKey: 'offering',
    coverText: translate('organizations.offering'),
    defaultSelected: [],
    options: [PRIMARY_PRODUCT.FUSION].map((value: string) => ({
      title: value.charAt(0) + value.slice(1).toLowerCase(),
      value,
    })),
  };

  const [filterSelectedValues, setFilterSelectedValues] = useState<
    PRIMARY_PRODUCT[]
  >(filterProps.defaultSelected);
  const primaryProduct = withOrgListFiltering
    ? filterSelectedValues
    : undefined;
  useEffect(() => {
    dispatch(drain());
    dispatch(resetMembers());
  }, []);

  useEffect(() => {
    searchOrganizations(dispatch, searchTerm);
  }, [dispatch]);

  useUpdateEffect(() => {
    searchOrganizationsDebounced(dispatch, searchTerm, primaryProduct);
  }, [searchTerm, filterSelectedValues]);

  const handleSearch = useCallback(
    (event) => {
      const value = event?.target?.value || '';

      dispatch(searchOrganizationsAction(value));
    },
    [dispatch],
  );

  const handleCreateOrganization = useCallback(
    () => dispatch(createOrganizationAction()),
    [dispatch],
  );

  const handleSelectOrganization = useCallback(
    (orgId: number) => dispatch(selectOrganizationAction(orgId)),
    [dispatch],
  );

  const loadMoreRows = useCallback(
    ({ startIndex: offset }: { startIndex: number }) => {
      searchOrganizations(dispatch, searchTerm, offset, primaryProduct);
    },
    [dispatch, searchTerm, filterSelectedValues],
  );

  const rows = generateRows({
    handleSelectOrganization,
    organizations,
    t: translate,
  });
  const rowRenderer = (data: any) => rendererRow({ ...data });

  const tableSectionProps: TableSectionProps = {
    columnWidthOffset: COLUMN_WIDTH_OFFSET,
    headerRow: generateHeaderRow(translate),
    loadMoreRows,
    rowRenderer,
    rows,
  };

  return (
    <div className="organizations" data-testid="organizations-container">
      <OrganizationsTitle
        title={translate('organizations.title')}
        subtitle={`${totalOrgCount} ${translate('organizations.total')}`}
      />
      <Container data-testid="organizations-table">
        <div css={actionsStyle}>
          <TableFilterBar
            placeholder={translate('organizations.searchOrganizations')}
            handleSearch={handleSearch}
            searchValue={searchTerm}
            dataTestId="organizations-filter-input-bar"
          />

          <OrganizationFilterDropdown
            filterProps={filterProps}
            filterSelectedValues={filterSelectedValues}
            setFilterSelectedValues={setFilterSelectedValues}
          />

          <TextButton
            reg={true}
            icon={<Icon src={plus} width={14} height={14} fill="white" />}
            value="new"
            onClick={handleCreateOrganization}
            dataTestId="create-organization-button"
          />
        </div>
        <div
          className="table"
          css={tableStyle({ height: windowInnerHeight - HEIGHT_OFFSET })}
        >
          {isFetching && <SpinnerWithBackGround height="70px" width="70px" />}
          {rows.length ? (
            <TableSection {...tableSectionProps} />
          ) : (
            <div css={noResultsFoundStyle}>{translate('noResultsFound')}</div>
          )}
        </div>
      </Container>
      {challengeCreated && <Toast text={challengeCreated} />}
    </div>
  );
};
