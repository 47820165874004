/** Forked from react-virtualized defaultRowRenderer */
import * as React from 'react';
import { buildAllyProps } from './utils';
import { TableCheckbox } from './Checkbox';
import { selectRowStyle, rowMarginStyle } from './styles';
import { TableRowProps } from './types';

export const TableRow: React.FC<TableRowProps> = ({
  className,
  columns,
  index,
  selectedRowIds,
  onRowClick,
  onRowDoubleClick,
  onRowMouseOut,
  onRowMouseOver,
  onRowRightClick,
  onSelect,
  rowData,
  style,
}) => {
  const a11yProps = buildAllyProps({
    index,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
  });
  const { action, selectable, id } = rowData;
  const selected = selectedRowIds && selectedRowIds.includes(id);
  const rowStyle = selected ? selectRowStyle : {};

  return (
    <div
      {...a11yProps}
      className={className}
      key={id}
      role="row"
      style={style}
      css={rowStyle}
      data-testid="table-row"
    >
      {selectable && (
        <span className="leftRowMargin" css={rowMarginStyle}>
          <TableCheckbox
            id={id}
            selected={selected}
            onSelect={() => (onSelect ? onSelect(id) : {})}
          />
        </span>
      )}
      {columns}
      <span className="rightRowMargin" css={rowMarginStyle}>
        {action}
      </span>
    </div>
  );
};
