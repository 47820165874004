import { css } from '@emotion/react';
import {
  borderRadiusXS,
  borderWidthXS,
  fontSizeXS,
  fontWeightBold,
  letterSpacingWider,
  lineHeightHeading,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  justifyContentSpaceBetween,
  flex,
  justifyContentFlexEnd,
} from '../../../../../styles/flex';

export const surveyTableRowStyle = css({
  '& > div': {
    ':not(:first-of-type)': {
      justifySelf: 'center',
    },
    ':last-of-type': {
      justifySelf: 'end',
    },
    margin: 0,
  },
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  padding: SpacingInRemBySize.M,
});

export const dropDownContainerStyle = css(
  flex,
  alignItemsCenter,
  justifyContentSpaceBetween,
);

export const surveyTableHeaderRowStyle = css({
  '& > div': {
    ':not(:first-of-type)': {
      justifySelf: 'center',
    },
    color: WarmGrey[400],
    fontSize: fontSizeXS,
    fontWeight: fontWeightBold,
    letterSpacing: letterSpacingWider,
    lineHeight: lineHeightHeading,
    margin: 0,
  },
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  padding: `${SpacingInRemBySize.S} ${SpacingInRemBySize.M}`,
});

export const ctaContainerStyle = css(justifyContentFlexEnd, {
  ' *': {
    overflow: 'visible',
  },
});

export const statusCellStyle = (color: string) =>
  css(flex, alignItemsCenter, justifyContentSpaceBetween, {
    border: `${borderWidthXS} solid ${color}`,
    borderRadius: borderRadiusXS,
    boxSizing: 'border-box',
    color,
    fontSize: fontSizeXS,
    fontWeight: fontWeightBold,
    letterSpacing: rem(0.156),
    lineHeight: rem(0.875),
    padding: `${rem(0.375)} ${SpacingInRemBySize.XS}`,
    textAlign: 'center',
    textTransform: 'uppercase',
  });
