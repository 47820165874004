import { css, SerializedStyles } from '@emotion/react';
import {
  borderRadiusXS,
  borderWidthS,
  borderWidthXS,
  CoolGrey,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  fontSizeM,
  fontSizeS,
  fontSizeXL,
  fontSizeXS,
  Green,
  Orange,
  rem,
  rgba,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';

const TOAST_HEIGHT = 28;
const TOAST_BOTTOM_MARGIN = 14;

export const headerRowStyle = css({
  '.ReactVirtualized__Table__headerColumn': {
    '& .ReactVirtualized__Table__headerTruncatedText': {
      ':not(:only-child)': {
        color: WarmGrey[700],
      },
      display: 'flex',
      whitespace: 'normal !important',
      wordbreak: 'break-word',
    },
    '& span ~ svg': {
      fill: WarmGrey[700],
    },
    ':first-of-type': {
      paddingLeft: SpacingInRemBySize.XL,
    },
    ':last-of-type': {
      ' svg': {
        marginRight: 0,
      },
      borderRight: 'none',
      marginRight: 0,
    },
    alignItems: 'center',
    borderRight: `${borderWidthXS} solid ${WarmGrey[200]}`,
    color: WarmGrey[500],
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: fontSizeXS,
    fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.bold,
    height: SpacingInRemBySize.L,
    letterSpacing: rem(0.156),
    marginRight: SpacingInRemBySize.M,
    minWidth: 0,
    outline: 'none',
    textTransform: 'uppercase',
    wordbreak: 'break-all !important',
  },
  alignItems: 'center',
  outline: 'none',
  userSelect: 'none',
});

export const tableStyle = ({ height }) => {
  return css({
    '& .ReactVirtualized__Grid': {
      '.ReactVirtualized__Table__Grid': {
        // overflow: 'visible !important',
        ' .ReactVirtualized__Grid__innerScrollContainer': {
          overflow: 'visible !important',
        },
      },
      outline: 'none',
    },
    '& .ReactVirtualized__Table__headerRow': {
      ...headerRowStyle,
    },
    display: 'flex',
    flexDirection: 'column',
    height: height || `100vh`,
    minHeight: rem(43.75),
  });
};

export const iconColumnStyle = ({ icon }) => {
  return css(
    icon
      ? {
          ':hover': {
            cursor: 'pointer',
          },
          backgroundImage: `url(${icon})`,
          backgroundPosition: `right ${rem(1.75)} center`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: SpacingInRemBySize.XS,
          fill: WarmGrey[700],
          marginRight: 0,
        }
      : {},
  );
};

export const rowStyle = (options) =>
  // @ts-ignore
  css({
    ' .ReactVirtualized__Table__row': {
      ' .ReactVirtualized__Table__rowColumn > *:not(.dropdown), .ReactVirtualized__Table__rowColumn > *:not(.dropdown) *': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        wordBreak: 'break-word',
      },
      ' .ReactVirtualized__Table__rowColumn:first-of-type': {
        paddingLeft: SpacingInRemBySize.XL,
      },
      ' .rightRowMargin': {
        ...iconColumnStyle(options),
      },
      '.ReactVirtualized__Table__rowColumn': {
        marginRight: SpacingInRemBySize.M,
      },
      backgroundColor: White['000'],
      border: `${borderWidthXS} solid ${WarmGrey[200]} !important`,
      borderRadius: borderRadiusXS,
      boxShadow: `0 ${SpacingInRemBySize['3XS']} 0 0 rgba(${WarmGrey[800]}, 0.04)`,
      color: WarmGrey[700],
      fontSize: fontSizeS,
      height: `${rem(5.5)} !important`,
      outline: 'none',
      overflow: 'visible !important',
      position: 'relative !important',
      width: 'auto !important',
    },
    height: 0,
    outline: 'none',
  });

export const rowMarginStyle: SerializedStyles = css({
  '.leftRowMargin': {
    justifyContent: 'flex-end',
    marginRight: 0,
  },
  alignItems: 'center',
  display: 'flex',
  height: SpacingInRemBySize['5XL'],
  justifyContent: 'center',
  marginRight: SpacingInRemBySize.L,
  position: 'absolute',
  right: 0,
  width: SpacingInRemBySize['5XL'],
});

const vistaBlueOpacity10 = rgba(Green[100], 0.1);
export const selectRowStyle: SerializedStyles = css(rowStyle({}), {
  background: `linear-gradient(${vistaBlueOpacity10}, ${vistaBlueOpacity10}), linear-gradient(${White['000']}, ${White['000']})`,
  borderColor: Green[100],
});

export const cellSortStyle: SerializedStyles = css({
  display: 'none',
});

export const activeSortCellStyle: SerializedStyles = css({
  '.cell': {
    color: WarmGrey[700],
  },
  '> .cellSort': {
    '> .sortStyle svg': {
      fill: WarmGrey[700],
    },
    display: 'block',
  },
});

export const sortStyle: SerializedStyles = css({
  '> svg': {
    fill: WarmGrey[500],
    height: rem(0.875),
    marginRight: SpacingInRemBySize.M,
    width: rem(0.875),
  },
});

export const actionsStyle: SerializedStyles = css({
  '& button, & .dropdown': {
    marginLeft: SpacingInRemBySize.M,
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

export const searchStyle: SerializedStyles = css({
  alignItems: 'center',
  backgroundColor: WarmGrey[200],
  borderRadius: borderRadiusXS,
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  height: rem(1.25),
  padding: SpacingInRemBySize.S,
});

export const clearSearchStyle: SerializedStyles = css({
  outline: 'none',
});

export const searchInputStyle: SerializedStyles = css({
  ':focus': {
    '::-moz-placeholder': {
      color: 'transparent',
    },
    '::-ms-clear': {
      display: 'none',
    },
    '::-ms-input-placeholder': {
      color: 'transparent',
    },
    '::-webkit-input-placeholder': {
      color: 'transparent',
    },
  },
  background: 'transparent',
  border: 'none',
  flexGrow: 1,
  fontSize: fontSizeM,
  marginLeft: SpacingInRemBySize.M,
  outline: 'none',
});

export const filterDropdownStyle: SerializedStyles = css({});

export const filterDropdownItemStyle: SerializedStyles = css({
  display: 'inline-flex',
});

export const noResultsFoundStyle: SerializedStyles = css({
  alignItems: 'start',
  color: CoolGrey[500],
  display: 'flex',
  flexGrow: 1,
  fontSize: fontSizeXL,
  justifyContent: 'center',
  marginTop: rem(1.25),
  width: '100%',
});

export const toastWrapperStyle: SerializedStyles = css({
  bottom: rem(1.375),
  display: 'flex',
  height: 0,
  justifyContent: 'center',
  position: 'fixed',
});

export const toastStyle: SerializedStyles = css({
  alignItems: 'center',
  backgroundColor: CoolGrey[500],
  borderRadius: borderRadiusXS,
  color: White['000'],
  display: 'flex',
  flexDirection: 'row',
  fontSize: fontSizeM,
  height: TOAST_HEIGHT,
  justifyContent: 'space-between',
  padding: `${SpacingInRemBySize.XS} ${SpacingInRemBySize.M}`,
  transform: `translateY(-${TOAST_HEIGHT + TOAST_BOTTOM_MARGIN}px)`,
  width: '50%',
});

export const toastGroupStyle: SerializedStyles = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

export const closeToastStyle: SerializedStyles = css({
  cursor: 'pointer',
  height: SpacingInRemBySize.M,
  marginRight: SpacingInRemBySize.M,
  width: SpacingInRemBySize.M,
});

export const rowsCountStyle: SerializedStyles = css({
  alignItems: 'center',
  backgroundColor: White['000'],
  borderRadius: rem(0.125),
  color: CoolGrey[500],
  display: 'flex',
  flexDirection: 'row',
  height: SpacingInRemBySize.XL,
  justifyContent: 'center',
  marginRight: SpacingInRemBySize.XS,
  width: SpacingInRemBySize.XL,
});

export const unselectRowsStyle: SerializedStyles = css({
  cursor: 'pointer',
  fontSize: fontSizeXS,
  letterSpacing: rem(0.156),
  textTransform: 'uppercase',
});

export const clickableStyle: SerializedStyles = css({
  cursor: 'pointer',
});

export const noSelectRowClickableStyle: SerializedStyles = css({
  ':hover .cell:nth-of-type(1)': {
    ' div': {
      color: 'inherit',
    },
    color: Orange[200],
  },
});

export const hasSelectRowClickableStyle: SerializedStyles = css({
  ':hover .cell:nth-of-type(2)': {
    ' div': {
      color: 'inherit',
    },
    color: Orange[200],
  },
});

export const tableCheckboxStyleChecked: SerializedStyles = css({
  backgroundColor: Green[100],
  borderRadius: rem(0.125),
  cursor: 'pointer',
  fontSize: 0,
  height: SpacingInRemBySize.M,
  outline: 'none',
  userSelect: 'none',
  width: SpacingInRemBySize.M,
});

export const tableCheckboxStyleUnchecked: SerializedStyles = css({
  backgroundColor: White['000'],
  border: `${borderWidthS} solid ${WarmGrey[200]}`,
  borderRadius: rem(0.125),
  cursor: 'pointer',
  fontSize: 0,
  height: SpacingInRemBySize.S,
  outline: 'none',
  userSelect: 'none',
  width: SpacingInRemBySize.S,
});
