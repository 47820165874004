import { css } from '@emotion/react';

export const flex = css({
  display: 'flex',
});

export const columnFlex = css(flex, {
  flexDirection: 'column',
});

export const rowFlex = css(flex, {
  flexDirection: 'row',
});

export const justifyContentCenter = css({
  justifyContent: 'center',
});

export const justifyContentSpaceBetween = css({
  justifyContent: 'space-between',
});

export const justifyContentFlexEnd = css({
  justifyContent: 'flex-end',
});

export const justifyContentFlexStart = css({
  justifyContent: 'flex-start',
});

export const justifyContentAround = css({
  justifyContent: 'space-around',
});

export const alignItemsCenter = css({
  alignItems: 'center',
});

export const alignItemsSpaceBetween = css({
  alignItems: 'space-between',
});

export const alignItemsFlexStart = css({
  alignItems: 'self-start',
});

export const alignContentFlexStart = css({
  alignContent: 'flex-start',
});

export const flex1 = css(flex, {
  flex: '1 0 auto',
});
