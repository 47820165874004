import React, { useCallback, useState } from 'react';
import { Orange } from '@headspace/web-ui-components';
import { TextButton } from '../../../../shared-components/buttons/TextButton';
import { StepProgress } from '../../../../shared-components/progressBars/StepProgress';
import { OrgCreationSteps, ORG_CREATION_STEPS } from '../../constants';
import { progressNavStyle, stepStyles } from './progressNavStyle';
import { useTranslate } from '../../../../hooks/useTranslate';

export interface ProgressNavProps {
  currentStep: ORG_CREATION_STEPS;
  progressDisabled: boolean;
  steps: OrgCreationSteps;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

export const ProgressNav: React.FC<ProgressNavProps> = ({
  currentStep,
  handlePreviousStep,
  handleNextStep,
  progressDisabled,
  steps,
}) => {
  const { translate } = useTranslate();
  const [disableOnFinish, setDisableOnFinish] = useState(false);
  const index = Object.keys(steps).indexOf(currentStep) + 1;

  const handleOnClick = useCallback(() => {
    if (currentStep === ORG_CREATION_STEPS.REVIEW) {
      setDisableOnFinish(true);
    }

    handleNextStep();
  }, [handleNextStep]);

  return (
    <div css={progressNavStyle}>
      <TextButton
        lg={true}
        value={translate(
          currentStep === ORG_CREATION_STEPS.BASIC ? 'cancel' : 'back',
        )}
        className="cancel"
        onClick={handlePreviousStep}
        dataTestId="previous-button"
      />
      <div css={stepStyles(index)}>
        <StepProgress
          steps={Object.keys(steps).length}
          currentStep={index}
          barColor={Orange[200]}
        />
      </div>
      <TextButton
        lg={true}
        value={translate(
          currentStep === ORG_CREATION_STEPS.REVIEW ? 'finish' : 'continue',
        )}
        className="continue"
        disabled={disableOnFinish || progressDisabled}
        onClick={handleOnClick}
        dataTestId="next-button"
      />
    </div>
  );
};
