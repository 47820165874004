import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../types';
import {
  initialContractFamilyMembersPerEmployeeValue,
  initialTrulyContractFamilyMembersPerEmployeeValue,
} from '../../../reducers/manageOrganizationReducer';
import { handleChange } from '../handleChange';
import { processErrors } from '../processErrors';

export const handleInviteBasedDependentsChange = (
  event: ExtendedEvent,
  term: 'current' | 'expired' | 'future' = 'current',
) => (dispatch: ThunkDispatch<RootState, unknown, Action>): void => {
  const { value: disableInviteBasedDependents } = event.target;

  dispatch(handleChange(event));
  if (disableInviteBasedDependents === 'true') {
    dispatch(
      handleChange({
        target: {
          id: `${term}_contract_family_members_per_employee`,
          value: initialContractFamilyMembersPerEmployeeValue,
        },
      }),
    );
  }

  if (disableInviteBasedDependents === 'false') {
    dispatch(
      handleChange({
        target: {
          id: `${term}_contract_eligibility_file_based_dependents`,
          value: false,
        },
      }),
    );
    dispatch(
      handleChange({
        target: {
          id: `${term}_contract_family_members_per_employee`,
          value: initialTrulyContractFamilyMembersPerEmployeeValue,
        },
      }),
    );
  }

  dispatch(processErrors(event));
};
