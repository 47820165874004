import { HOST, WEBSITE_HOST } from '../../../utils/constants';

export const headspaceHubRoutes = {
  ACTIVITY_ERROR_PATH: 'activity/error',
  ACTIVITY_PATH: 'activity',
  ADD_FAMILY_MEMBER_PATH: `${WEBSITE_HOST}/family/manage`,
  APP_DETAILS_ERROR_PATH: 'app-details/error',
  APP_DETAILS_PATH: 'app-details',
  BENEFITS_PATH: '/hub',
  CRISIS_SUPPORT_ERROR_PATH: 'crisis-support/error',
  CRISIS_SUPPORT_PATH: 'crisis-support',
  FAMILY_RESOURCES_ERROR_PATH: 'family-resources/error',
  FAMILY_RESOURCES_PATH: 'family-resources',
  FAQ: '/faq',
  HOMEPAGE_ERROR_PATH: 'error',
  HOME_PATH: '/',
  INTAKE_PATH: 'intake',
  INTAKE_FORM_PATH: 'in-person-therapy/intake',
  INTAKE_FORM_REQUEST: 'in-person-therapy/request',
  INTAKE_VIRTUAL_FORM_PATH: 'virtual-therapy/intake',
  INTAKE_VIRTUAL_FORM_REQUEST: 'virtual-therapy/request',
  IN_PERSON_THERAPY_ERROR_PATH: 'in-person-therapy/error',
  IN_PERSON_THERAPY_PATH: 'in-person-therapy',
  LOGIN_REDIRECT: `${WEBSITE_HOST}/login?redirectOnSuccess=${HOST}/hub`,
  MANAGER_SERVICES_ERROR_PATH: 'manager-services/error',
  MANAGER_SERVICES_PATH: 'manager-services',
  NOT_FOUND: '/not-found',
  NO_ACCESS_ERROR_PATH: 'no-access',
  SERVICE_REQUEST_ERROR_PATH: ':service_type/request/error',
  SERVICE_REQUEST_FINISH_PATH: ':service_type/finish',
  SERVICE_REQUEST_PAGE_PATH: 'work-life-services/type',
  SERVICE_REQUEST_PATH: ':service_type/request',
  WORK_LIFE_SERVICES_ERROR_PATH: 'work-life-services/error',
  WORK_LIFE_SERVICES_INTAKE_PATH: 'work-life-services/intake',
  WORK_LIFE_SERVICES_PATH: 'work-life-services',
  VIRTUAL_THERAPY_ERROR_PATH: 'virtual-therapy/error',
  VIRTUAL_THERAPY_PATH: 'virtual-therapy',
};
