import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paymentFormComponentFactory } from '@headspace/web-payments';
import paymentConfig from '../../../enrollment-config/paymentConfig';
import { initializePayment } from '../../../state/actions/paymentActions/paymentActions';
import { getCountryCode } from '../../../state/selectors/countryCodeSelector/countryCodeSelector';
import { getVoucherPrice } from '../../../state/selectors/paymentSectionSelelector/paymentSectionSelector';

const PaymentModule: React.FC = () => {
  const dispatch = useDispatch();
  const countryCode = useSelector(getCountryCode);
  const voucherPrice = useSelector(getVoucherPrice);

  React.useEffect(() => {
    dispatch(initializePayment());
  }, []);

  if (countryCode && voucherPrice !== 'N/A') {
    const PaymentForm = paymentFormComponentFactory(paymentConfig);
    return (
      <div data-testid="member-pay-form">
        <PaymentForm />
      </div>
    );
  }

  return <div data-testid="member-pay-form" />;
};

export default PaymentModule;
