import { css } from '@emotion/react';
import {
  PrimaryButton,
  useActiveBreakpoint,
  Breakpoint,
  titleSCss,
  legalTextCss,
  headingMCss,
  TextLink,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import GingerIcon from '../../../../../../assets/umd-ginger-recommended-ginger.svg';
import TextWithCoachHero from '../../../../../../assets/umd-ginger-recommended-text-with-coach.svg';
import ThoughtBubbles from '../../../../../../assets/umd-ginger-recommended-thought-bubbles.svg';
import { QRCode } from '../../../../../../shared-components/qrcode';
import { PageWrapper } from '../onboarding-shared-components/OnboardingPageWrapper';
import {
  ContainerStyle,
  centerElementStyle,
  stackedColumns,
  ListRowStyle,
  ListRowItemStyle,
  downloadGingerMobile,
  downloadGinger,
  onlyOnMobile,
  textTopMargin,
  textBottomMargin,
} from './styles';
import {
  onboardingButtonClickthrough,
  onboardingRecommendGingerViewAction,
} from '../../../../../../analytics/events/onboardingEvents';
import { useGetAppStoreLinks } from '../../../../../../hooks/useAppStoreLinks';
import { Product } from '../../../../constants';
import { checkIfMobileOrTablet, checkIfTablet } from '../../utils/deviceUtils';
import { getEmail } from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';

export const RecommendGinger = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const activeBreakpoint = useActiveBreakpoint();
  const isMobileView = useMemo(() => activeBreakpoint === Breakpoint.MOBILE, [
    activeBreakpoint,
  ]);
  const email = useSelector(getEmail);
  const isMobileOrTablet = checkIfMobileOrTablet();

  const { appStoreLink, trackableAppStoreLink } = useGetAppStoreLinks({
    app: Product.GINGER,
  });

  useEffect(() => {
    dispatch(onboardingRecommendGingerViewAction());
  }, [dispatch]);

  const handleRecommendButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      const buttonText = event.target.innerText;
      dispatch(onboardingButtonClickthrough(buttonText));
    },
    [dispatch],
  );

  const isTablet = checkIfTablet();

  return (
    <PageWrapper
      progress={100}
      header={translate('onboarding.recommend.ginger.header')}
      subHeader={translate('onboarding.recommend.ginger.subHeader')}
      hideTitleSection={true && isMobileView}
    >
      <div css={ContainerStyle}>
        <h1
          css={css(centerElementStyle, onlyOnMobile, {
            margin: `${SpacingInRemBySize['2XS']} ${SpacingInRemBySize.M}`,
          })}
        >
          {translate('onboarding.recommend.ginger.header')}
        </h1>
        <p css={css(centerElementStyle, onlyOnMobile, textTopMargin)}>
          {translate('onboarding.recommend.ginger.subHeader')}
        </p>

        <img src={TextWithCoachHero} alt="" role="presentation" />

        <h2 css={[headingMCss, textBottomMargin]}>
          {translate('onboarding.recommend.ginger.list.header')}
        </h2>
        <div css={stackedColumns}>
          <div css={ListRowStyle}>
            <img src={ThoughtBubbles} alt="" role="presentation" />

            <div css={ListRowItemStyle}>
              <h2 css={[titleSCss, textBottomMargin]}>
                {translate('onboarding.recommend.ginger.list.question1')}
              </h2>
              <p css={textTopMargin}>
                {translate('onboarding.recommend.ginger.list.message1')}
              </p>
            </div>
          </div>

          <div css={ListRowStyle}>
            <img src={GingerIcon} alt="" role="presentation" />

            <div css={ListRowItemStyle}>
              <h2 css={[titleSCss, textBottomMargin]}>
                {translate('onboarding.recommend.ginger.list.question2')}
              </h2>
              <p css={textTopMargin}>
                {translate('onboarding.recommend.ginger.list.message2')}
              </p>
            </div>
          </div>
        </div>

        {isMobileOrTablet ? (
          <div css={downloadGingerMobile}>
            <a
              css={css({
                margin: 'auto',
              })}
              href={appStoreLink}
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButton
                onClick={handleRecommendButtonClick}
                isBlock={true}
              >
                {translate('onboarding.recommend.ginger.button')}
              </PrimaryButton>
            </a>

            <p css={legalTextCss}>
              {translate('onboarding.recommend.ginger.buttonInfo')}
            </p>
          </div>
        ) : (
          <div css={downloadGinger} data-testid="download-ginger-qrcode">
            <div css={stackedColumns}>
              <h2
                css={css({
                  margin: SpacingInRemBySize['2XS'],
                })}
              >
                {translate('onboarding.recommend.ginger.qrCode.header')}
              </h2>
              <p css={[textTopMargin, legalTextCss]}>
                {translate('onboarding.recommend.ginger.qrCode.subHeader', {
                  email,
                })}
              </p>
            </div>

            <QRCode
              data={trackableAppStoreLink}
              id="download-ginger-app"
              filename="download-ginger-app"
              margin={3}
              scale={3}
            />
            {isTablet ? (
              <TextLink
                to={appStoreLink}
                css={css(legalTextCss, {
                  margin: 'auto',
                })}
              >
                {translate('onboarding.recommend.ginger.button')}
              </TextLink>
            ) : null}
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
