import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import isTodayPlugin from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isTodayPlugin);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(calendar);

export const makeFriendlyDate = (date: string) => {
  if (Date.parse(date)) {
    const newDate = new Date(date);
    if (isValid(newDate)) {
      const dateArray = newDate.toISOString().split('T')[0].split('-');
      return dateArray.slice(1).concat(dateArray[0]).join('/');
    }
  }
  return '';
};

/**
 * Parses a string as a Date and resets timezone to UTC
 *
 * For example '8/12/2019' becomes Date('2019-08-27T00:00:00.000Z')
 */
export const parseDateUtc = (str: string): Date => {
  const date = new Date(str);
  return (subMinutes(date, date.getTimezoneOffset()) as unknown) as Date;
};

/** TODO: Remove after upgrading to date-fns 2+: https://headspace.atlassian.net/browse/PTNR-7240
 * The 'Z' in the iso date string is causing the end dates to be a day in the past when using date-fns format:  https://github.com/date-fns/date-fns/issues/376
 * We may be able to use parseISO once upgraded to date-fns 2+ https://stackoverflow.com/questions/58561169/date-fns-how-do-i-format-to-utc
 */
export const formatDateAndRemoveZ = (date: string = '') => {
  const newDate = date.replace('Z', '');
  return format(newDate);
};

/**
 * Day.js wrapper functions.
 */

export const nowInUtc = () => {
  return dayjs.utc();
};

export const format = (date, stringFormat = 'MM/DD/YYYY') => {
  return dayjs(date).format(stringFormat);
};

export const subMinutes = (date, amount) =>
  dayjs(date).subtract(amount, 'minute').toDate();

export const addMinutes = (date, amount) =>
  dayjs(date).add(amount, 'minute').toDate();

export const isValid = (date) => dayjs(date).isValid();

export const isPast = (date) =>
  dayjs(new Date(date)).isBefore(dayjs(new Date()));

export const isFuture = (date) =>
  dayjs(new Date(date)).isAfter(dayjs(new Date()));

export const isToday = (date) => dayjs(date).isToday();

export const differenceInMinutes = (start, end) =>
  Math.abs(dayjs(end).diff(start, 'minute'));

export const differenceInDays = (start, end) =>
  Math.abs(dayjs(end).diff(start, 'day'));

export const differenceInCalendarDays = (start, end) =>
  Math.abs(dayjs(start).diff(end, 'day'));

export const addDays = (date, amount) =>
  dayjs(date).add(amount, 'day').toDate();

export const subDays = (date, amount) =>
  dayjs(date).subtract(amount, 'day').toDate();

export const parse = (date) => dayjs(date).toDate();

export const distanceInWords = (start, end, includeSuffix = false) =>
  dayjs(end).fromNow(!includeSuffix);

/**
 * '6/15/2023'(00:00:00) becomes '6/15/2023, 4:00:00 AM'
 * @returns Date object
 */
export const convertDateToUtc = (strDate: string): Date => {
  const utcDateStr = new Date(strDate).toLocaleString('en-US', {
    timeZone: 'UTC',
  });
  return new Date(utcDateStr);
};
