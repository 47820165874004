import { css } from '@emotion/react';
import {
  cssWithMq,
  fontSize2XL,
  fontSize6XL,
  fontSizeXL,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../constants/breakpoint';

export const containerStyle = cssWithMq({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  padding: SpacingInRemBySize.XL,
  textAlign: 'center',
});

export const headerStyle = cssWithMq({
  textTransform: [null, 'uppercase', 'uppercase'],
  fontSize: [fontSize2XL, fontSizeXL, fontSizeXL],
  fontWeight: [null, 300, 300],
  wordSpacing: [null, rem(0.5), rem(0.5)],
});

export const subHeaderStyle = cssWithMq({
  fontSize: [null, fontSize6XL, fontSize6XL],
  marginBottom: [null, 0, 0],
});

export const iconTextStyle = css({
  display: 'flex',
  textAlign: 'start',
  alignItems: 'center',
});

export const iconStyle = css({
  width: SpacingInRemBySize['6XL'],
  marginRight: SpacingInRemBySize.M,
});

export const lottieContainerStyle = cssWithMq({
  [WebUIComponentsBreakPoint.Desktop]: {
    width: '25vw',
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    width: '40vw',
  },
  [WebUIComponentsBreakPoint.Mobile]: {
    width: '60vw',
  },
});
