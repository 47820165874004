import close16Svg from 'assets/close-16.svg';
import searchSvg from 'assets/search.svg';
import { CoolGrey } from '@headspace/web-ui-components';
import { Icon } from '../Icon';
import { TableFilterBarProps } from './types';
import { clearSearchStyle, searchInputStyle, searchStyle } from './styles';
import { noop } from '../../utils';
import { regular } from '../../apps/dashboard/styles/fonts';

export const TableFilterBar = ({
  handleSearch,
  searchValue,
  dataTestId,
  placeholder,
}: TableFilterBarProps) => {
  return (
    <div className="search" css={searchStyle}>
      <Icon width={24} height={24} fill={CoolGrey[500]} src={searchSvg} />
      <input
        tabIndex={0}
        data-testid={dataTestId}
        type="text"
        placeholder={placeholder}
        onChange={handleSearch}
        value={searchValue}
        css={[searchInputStyle, regular]}
      />
      {searchValue ? (
        <div
          tabIndex={0}
          aria-label="Clear search"
          onClick={handleSearch}
          onKeyDown={noop}
          className="clearSearch"
          role="button"
          css={clearSearchStyle}
        >
          <Icon width={16} height={16} fill={CoolGrey[500]} src={close16Svg} />
        </div>
      ) : null}
    </div>
  );
};
