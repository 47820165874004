import {
  Blue,
  Grey,
  Scrubber,
  ProgressBar,
  Orange,
  WarmGrey,
} from '@headspace/web-ui-components';
import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import {
  emotionImageContainerStyle,
  emotionImageStyle,
  thumbIconStyle,
  markStyle,
  sliderContainerStyle,
  stepsMarkingContainerStyle,
  stepsMarkingNarrowingContainerStyle,
} from '../styles/sliderStyle';
import { CHOICE_ICONS, SLIDER_INITIAL_STEP } from './constants';
import { Choice, QuestionId } from '../../../../../models/OnboardingQuestions';
import { getSingleChoiceQuestionAnswer } from '../../../../../state/slice/onboarding/onboardingSelector';
import { RootState } from '../../../../../../../state/types';

interface OnboardingSliderProps {
  choices: Choice[];
  questionId: QuestionId;
  title: string;
  onSelectAnswer: (questionId: string, title: string, id: string) => void;
}

const getSliderIcon = (choices, currentStep) => {
  const choiceId = choices[currentStep].id;
  return CHOICE_ICONS[choiceId];
};

export const OnboardingSlider: FC<OnboardingSliderProps> = ({
  choices,
  questionId,
  title,
  onSelectAnswer,
}) => {
  const currentAnswer = useSelector((state: RootState) =>
    getSingleChoiceQuestionAnswer(state, questionId),
  );
  const currentStep = currentAnswer
    ? currentAnswer.position
    : SLIDER_INITIAL_STEP;

  useEffect(() => {
    if (!currentAnswer) {
      onSelectAnswer(questionId, title, choices[SLIDER_INITIAL_STEP].id);
    }
  }, []);

  const onSliderMove = (step) =>
    onSelectAnswer(questionId, title, choices[step].id);

  return (
    <div css={sliderContainerStyle} data-testid="onboarding-slider-container">
      <div css={emotionImageContainerStyle}>
        <img
          css={emotionImageStyle}
          src={getSliderIcon(choices, currentStep)}
          alt={choices[currentStep].text}
        />
      </div>
      <Scrubber
        onScrubSeek={onSliderMove}
        maxValue={choices.length - 1}
        currentValue={currentStep}
      >
        {({ scrubberState, thumb, thumbContainer }) => (
          <>
            <ProgressBar
              progress={(100 / (choices.length - 1)) * currentStep}
              progressColor={Orange[200]}
            />
            <div
              css={thumbContainer.css}
              data-testid={thumbContainer.dataTestIdPrefix}
            >
              <div
                css={css(thumb.css, thumbIconStyle, {
                  borderColor: scrubberState.isHovered
                    ? Blue[200]
                    : WarmGrey[400],
                })}
                data-testid={thumb.dataTestIdPrefix}
              />
            </div>
          </>
        )}
      </Scrubber>
      <div css={stepsMarkingContainerStyle}>
        <div css={stepsMarkingNarrowingContainerStyle}>
          {choices.map(({ text }) => (
            <div key={text} css={markStyle} aria-label={text} />
          ))}
        </div>
      </div>
    </div>
  );
};
