import { ExperimentContract } from '../types';

export interface ExperimentContractAttributes {
  experimentName: string;
  experimentId: string;
  experimentVariation: string;
  experimentVariationId: string;
}

export function experimentContractSaga({
  experimentName,
  experimentId,
  experimentVariation,
  experimentVariationId,
}: ExperimentContractAttributes): ExperimentContract {
  return {
    experiment_name: experimentName,
    experiment_id: experimentId,
    experiment_variation: experimentVariation,
    experiment_variation_id: experimentVariationId,
  };
}
