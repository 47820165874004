import { css } from '@emotion/react';
import {
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';

export const containerStyle = css({
  background: White['000'],
  color: WarmGrey[700],
  height: '100%',
  textAlign: 'center',
});

export const spinnerContainerStyle = css({
  alignItems: 'center',
  background: White['000'],
  color: WarmGrey[700],
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  textAlign: 'center',
});

export const headerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  margin: [
    rem(1.25),
    `${rem(1.25)} ${SpacingInRemBySize['2XL']} 0`,
    `${rem(1.25)} ${SpacingInRemBySize['2XL']} 0`,
  ],
  paddingBottom: rem(1.25),
});

export const cardContainerStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

export const justifyContentRight = css({
  marginLeft: 'auto',
});
