import { getCookie } from '@headspace/web-auth';

import { LegalLinksItem } from 'shared-components/Footer/Footer';
import { CookieKeys } from './constants';

export type LegalLinks =
  | {
      specificLocale?: string[];
      display: string;
      link: string;
    }[]
  | LegalLinksItem[];

export const filterLegalLinksByLocale = ({ items }): LegalLinks => {
  const lang = getCookie(CookieKeys.LANGUAGE) || 'en';
  return (
    items.filter(
      (item) => !item.specificLocale || item.specificLocale.includes(lang),
    ) || []
  );
};
