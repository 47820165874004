import { ActionDropdown } from '../../dropdowns/ActionDropdown';
import { TableExportWithScrollDropdownProps } from './types';

export const TableExportDropdown = ({
  items,
  text,
}: TableExportWithScrollDropdownProps) => {
  return (
    <div
      className="actionDropdown"
      data-testid="shared-tablescroll-export-dropdown"
    >
      <ActionDropdown items={items} position="right" text={text} />
    </div>
  );
};
