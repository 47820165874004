import { css } from '@emotion/react';
import * as React from 'react';
import {
  White,
  borderWidthXS,
  rem,
  SpacingInRemBySize,
  CoolGrey,
} from '@headspace/web-ui-components';
import { TitleM, LabelRegularS } from '../../../shared-components/text';
import { columnFlex, flex, justifyContentCenter } from '../styles/flex';

const organizationsTitleStyle = css(columnFlex, justifyContentCenter, {
  backgroundColor: White['000'],
  borderBottom: `${borderWidthXS} solid ${CoolGrey[100]}`,
  height: rem(8),
});

const containerStyle = css(flex, {
  marginLeft: '10%',
  marginRight: 'auto',
  width: '90%',
});

const colStyle = css({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 'inherit',
});

const titleStyle = css({
  marginBottom: SpacingInRemBySize['2XS'],
});

export interface OrganizationsTitleProps {
  title: string;
  subtitle: string;
}

export const OrganizationsTitle: React.FC<OrganizationsTitleProps> = ({
  title,
  subtitle,
}) => (
  <div
    css={organizationsTitleStyle}
    data-testid="organizations-header-container"
  >
    <div css={containerStyle}>
      <div css={colStyle}>
        <TitleM css={titleStyle} dataTestId="organizations-header-text">
          {title}
        </TitleM>
        <LabelRegularS data-testid="total-organizations-text">
          {subtitle}
        </LabelRegularS>
      </div>
    </div>
  </div>
);
