import { useGate } from 'statsig-react';
import Statsig from 'statsig-js';
import env from '../config/env';
import { statsigOptions } from '../utils/statsig';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_fusion_scheduler_eligibility
// Feature flag to enable age and country validation for Coaching as part of Fusion Project
export const KEY = 'b2b_dashboard_fusion_scheduler_eligibility';

export const useFusionCoachingEligibility = (): boolean[] => {
  const { value, isLoading } = useGate(KEY);

  return [value, isLoading];
};

/**
 *  Since we can't use hooks in functions, this approach is needed to check the
 *  feature flag in from a ts/js function.
 *  https://reactjs.org/docs/hooks-rules.html#only-call-hooks-from-react-functions
 */
export const checkFusionCoachingEligibility = () => {
  Statsig.initialize(env.STATSIG_SDK_KEY, null, statsigOptions);
  return Statsig.checkGate(KEY);
};
