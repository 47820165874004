import * as React from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import {
  borderRadiusL,
  borderWidthS,
  cssWithMq,
  Grey,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import { Icon } from '../../../../../shared-components/Icon';
import { buttonClickthroughAction } from '../../../../../analytics/events/enrollmentEvents';
import {
  alignItemsCenter,
  columnFlex,
  justifyContentCenter,
} from '../../../../dashboard/styles/flex';

const cardStyle = cssWithMq(
  columnFlex,
  alignItemsCenter,
  justifyContentCenter,
  {
    background: WarmGrey[100],
    border: `${borderWidthS} ${WarmGrey[200]} solid`,
    borderRadius: borderRadiusL,
    boxSizing: 'border-box',
    margin: [rem(0.625), SpacingInRemBySize.L, SpacingInRemBySize.L],
    maxWidth: ['100vw', undefined, undefined],
    padding: [
      `${SpacingInRemBySize.M} ${SpacingInRemBySize.M} ${rem(3.75)}`,
      `${rem(1.875)} ${rem(1.875)} ${rem(3.75)}`,
      `${rem(1.875)} ${rem(1.875)} ${rem(3.75)}`,
    ],
  },
);

const headerStyle = css({
  color: Grey['900'],
  lineHeight: rem(1.25),
});

interface FinishCardProps {
  buttonText: string;
  dataTestId?: string;
  headerText: string;
  imageUrl: string;
  buttonRedirectUrl: string;
  subheaderText: string;
}

export const FinishCard: React.FC<FinishCardProps> = ({
  buttonRedirectUrl,
  buttonText,
  dataTestId,
  headerText,
  imageUrl,
  subheaderText,
}) => {
  const dispatch = useDispatch();

  const handleRedirect = React.useCallback(() => {
    dispatch(buttonClickthroughAction(buttonText));

    // Delay redirect to allow for analytics event to be fired
    setTimeout(() => {
      window.location.replace(buttonRedirectUrl);
    }, 500);
  }, [buttonClickthroughAction, buttonText, buttonRedirectUrl]);

  return (
    <div css={cardStyle} data-testid={dataTestId}>
      <div>
        <Icon width={357} height={240} src={imageUrl} />
      </div>
      <h1 css={headerStyle}>{headerText}</h1>
      <p>{subheaderText}</p>
      <div>
        <TextButton
          textColor="#FFFF"
          hoverColor="#0884FF"
          backgroundColor="#0884FF"
          dataTestId="join-message-next-button"
          value={buttonText}
          pill={true}
          width="250px"
          onClick={handleRedirect}
        />
      </div>
    </div>
  );
};
