import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../../../../../../shared-components/Input';
import { updateImplicitState } from '../../../../../../../state/actions/organizationActions';
import {
  getOrgManagement,
  getOrgMappingKey,
  getOrgMappingValue,
} from '../../../../../../../state/selectors/organizationSelectors';

const noop = () => {};

interface OrgMappingValueInputProps {
  editMode?: boolean;
  onChange?: (event: ExtendedEvent) => void;
  customLabelStyles?: Record<string, unknown>;
}

export const OrgMappingValueInput: React.FC<OrgMappingValueInputProps> = ({
  editMode,
  onChange = noop,
  customLabelStyles,
}) => {
  const dispatch = useDispatch();
  const orgMappingKey = useSelector(getOrgMappingKey) || '';
  const orgMappingValue = useSelector(getOrgMappingValue) || '';
  const { orgMappingValueError } = useSelector(getOrgManagement);

  const handleError = React.useCallback(
    (event: ExtendedEvent) => dispatch(updateImplicitState(event)),
    [dispatch],
  );
  const handleChange = React.useCallback(
    (event) => {
      return onChange({
        target: {
          id: 'orgMappingValue',
          value: event.target.value?.replace(',', ''),
        },
      });
    },
    [onChange],
  );

  return (
    <Input
      key="orgMappingValue"
      noLabel={false}
      label={orgMappingKey}
      type={editMode ? 'text' : 'review'}
      onChange={handleChange}
      onBlur={handleError}
      name="orgMappingValue"
      value={orgMappingValue}
      hasError={orgMappingValueError.error}
      errorMessage={orgMappingValueError.message}
      data-testid="org-mapping-value-input"
      customLabelStyles={customLabelStyles}
      tabIndex="0"
      autoFocus={!orgMappingValue}
      maxLength="30"
    />
  );
};
