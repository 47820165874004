import { TextLink } from '@headspace/web-ui-components';
import { useSelector } from 'react-redux';
import {
  getInfo,
  isCSM as getIsCSM,
} from '../../../../../../../state/selectors/userSelectors';
import { Input } from '../../../../../../../shared-components/Input';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { OrganizationSettingsRoutes } from '../../../../../constants/routes';

export const ParentOrgDisplayName = () => {
  const { parentOrgId, parentOrgName, parentOrg } = useSelector(
    getOrgManagement,
  );
  const isCSM = useSelector(getIsCSM);
  const { orgIds } = useSelector(getInfo);
  const hasAccessToParentOrg = orgIds?.includes(parentOrgId ?? -1);
  const showLinkParentLink = isCSM || hasAccessToParentOrg;
  const parentDisplayName =
    parentOrgName ?? parentOrg?.name ?? parentOrgId ?? '';

  if (!parentDisplayName) return null;

  return (
    <>
      {showLinkParentLink ? (
        <TextLink
          to={OrganizationSettingsRoutes.SETTINGS(parentOrgId ?? '')}
          dataTestIdPrefix="parent-org"
        >
          {parentDisplayName}
        </TextLink>
      ) : (
        <Input
          type="review"
          name="parentOrgId"
          noLabel={true}
          value={parentDisplayName}
          data-testid="parent-org-id"
        />
      )}
    </>
  );
};
