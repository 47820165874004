import { useParams } from 'react-router-dom';

export type UnifiedEligibilityParams = {
  slugOrHeadspaceHealthId?: string;
  slug?: string;
};

export const useUnifiedEligibilityBasePath = () => {
  const {
    slugOrHeadspaceHealthId,
    slug,
  } = useParams<UnifiedEligibilityParams>();
  const baseUrl = `/v3/${slugOrHeadspaceHealthId}/member-enroll`;

  return {
    baseUrl,
    slug: slugOrHeadspaceHealthId ?? slug,
  };
};
