import {
  Blue,
  WarmGrey,
  cssWithMq,
  headingLCss,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';

/** @deprecated WarmGrey[100] */
export const GINGER_PRIMARY_BG = '#F9EFD8';
/** @deprecated CoolGrey[500] */
export const GINGER_SECONDARY_BG = '#2E6867';
/** @deprecated WarmGrey[700] */
export const GINGER_TEXT_COLOR = '#272727';

export const contentCss = css({
  color: WarmGrey[700],
  maxWidth: rem(48),
  textAlign: 'center',
});

export const titleCss = css(
  {
    margin: `0 0 ${SpacingInRemBySize.XL} 0`,
  },
  headingLCss,
);

export const imageCss = cssWithMq({
  bottom: [rem(-3.594), rem(-6.25), rem(-6.25)],
  maxWidth: [rem(7.188), '100%', '100%'],
  position: 'absolute',
});

export const linkCss = css({
  color: Blue[200],
  textDecoration: 'none',
});
