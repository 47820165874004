import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@headspace/web-ui-components';
import { useNavigate } from 'react-router-dom';
import { Panel } from '../../../../../../shared-components/Panel';
import { panelStyle } from '../../../InsightsView/styles/styles';
import { Container } from '../../../../../../shared-components/container/Container';
import { ChallengesTitleSection } from './ChallengesTitleSection/ChallengesTitleSection';
import { ChallengesTableSection } from './ChallengesTableSection';
import { getOrgManagement } from '../../../../../../state/selectors/organizationSelectors';
import {
  getChallenges,
  getChallengesFetching,
} from '../../../../../../state/selectors/challengesSelectors';
import { flexCenterStyle } from './styles';
import { fetchChallenges } from '../../../../../../state/actions/challengesActions';

export const ChallengesList = () => {
  const challenges = useSelector(getChallenges);
  const { org_name: orgName, orgId } = useSelector(getOrgManagement);
  const isFetch = useSelector(getChallengesFetching);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchChallenges(orgId));
  }, []);

  const createChallenge = useCallback(() => {
    navigate(`/orgs/${orgId}/create-challenge`, { state: { orgName } });
  }, []);

  return (
    <Container>
      <Panel css={panelStyle}>
        {isFetch ? (
          <div css={flexCenterStyle}>
            <Spinner size={20} />
          </div>
        ) : (
          <>
            <ChallengesTitleSection
              hasChallenges={challenges.length > 0}
              createChallengeHandler={createChallenge}
            />
            {challenges.length > 0 && (
              <ChallengesTableSection
                challenges={challenges}
                createChallengeHandler={createChallenge}
              />
            )}
          </>
        )}
      </Panel>
    </Container>
  );
};
