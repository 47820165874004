import { Action } from '../../../../../state/types';
import { MemberEnrollActionTypes } from '../../actions/memberEnrollActions/memberEnrollActionTypes';
import { HealthProvider } from '../../../constants/healthProvider';
import { HealthProviderActionTypes } from '../../actions/healthProviderActions/healthProviderActionTypes';

export type HealthProviderState = {
  connectionInProgress: boolean;
  error: {
    code: string;
  } | null;
  healthProvider: HealthProvider | null;
};

const INITIAL_STATE: HealthProviderState = {
  connectionInProgress: false,
  error: null,
  healthProvider: null,
};

export const healthProviderReducer = (
  state: HealthProviderState = INITIAL_STATE,
  action: Action = { payload: {}, type: '' },
): HealthProviderState => {
  if (action.type === MemberEnrollActionTypes.SET_HEALTH_PROVIDER) {
    return { ...state, healthProvider: action.payload.healthProvider };
  }

  if (
    action.type ===
    HealthProviderActionTypes.SET_HEALTH_PROVIDER_CONNECTION_ERROR
  ) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (
    action.type ===
    HealthProviderActionTypes.SET_HEALTH_PROVIDER_CONNECTION_STARTED
  ) {
    return {
      ...state,
      connectionInProgress: true,
    };
  }

  if (
    action.type ===
    HealthProviderActionTypes.SET_HEALTH_PROVIDER_CONNECTION_FINISHED
  ) {
    return {
      ...state,
      connectionInProgress: false,
    };
  }

  return state;
};
