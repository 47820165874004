import { css } from '@emotion/react';
import {
  fontSizeS,
  LegacyColors,
  lineHeightHeading,
  rem,
  WarmGrey,
} from '@headspace/web-ui-components';
import { boxSizingBorderBox } from '../../../../styles';
import {
  columnFlex,
  flex,
  justifyContentSpaceBetween,
} from '../../../../styles/flex';

export const organizationColumnElementStyle = css(flex, boxSizingBorderBox, {
  flexBasis: '50%',
});

export const orgContractEditLeftColumnElementStyles = css([
  organizationColumnElementStyle,
  { paddingRight: rem(1.25) },
]);

export const orgContractEditRightColumnElementStyles = css([
  organizationColumnElementStyle,
  { paddingLeft: rem(1.25) },
]);

export const reviewInputSubtextStyles = css({
  color: WarmGrey[400],
  fontSize: fontSizeS,
  lineHeight: lineHeightHeading,
});

export const contractContainerStyle = css(columnFlex, {
  flex: 1,
});

export const rowStyle = css(flex, justifyContentSpaceBetween, {
  '> div': {
    flexBasis: '48%',
  },
  width: '100%',
});
