import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';

import { GetState } from '../../types';
import { DeleteAdminAction } from '../../types/organization';
import { OrganizationAdministratorProps } from '../../../apps/dashboard/types';
import { clearAdmin } from './clearAdmin';
import { DeleteCallbackTypes, ModalActionsTypes } from '../../types/modal';

const { OPEN_MODAL } = ModalActionsTypes;
const { DELETE_ADMIN } = DeleteCallbackTypes;

export const deleteAdmin = (
  event: ExtendedEvent,
  shouldShowConfirmation: boolean = true,
): ThunkMiddleware & DeleteAdminAction => {
  let email: string;
  // @ts-ignore
  return (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: { admin_email, org_admins },
    } = getState();
    email = (event && event.target && event.target.id) || admin_email;
    const index = org_admins.findIndex(
      (admin: OrganizationAdministratorProps) => admin.email === email,
    );
    const nextAdmins = org_admins
      .slice(0, index)
      .concat(org_admins.slice(index + 1));

    if (shouldShowConfirmation) {
      dispatch({
        type: OPEN_MODAL,
        payload: {
          nextAdmins,
          message:
            "Are you sure you want to delete this administrator? This can't be undone.",
          confirmCallback: DELETE_ADMIN,
        },
      });

      return;
    }

    dispatch(
      clearAdmin({
        adminReviewView: true,
        org_admins: nextAdmins,
      }),
    );
  };
};
