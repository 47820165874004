import { FC, useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import { requestDeauthenticate } from '@headspace/web-auth';
import { useSelector, useDispatch } from 'react-redux';
import { PrimaryButton, WarmGrey } from '@headspace/web-ui-components';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { PageWrapper } from './PageWrapper';
import { getEnrollmentError } from '../../../state/slice/enrollment/enrollmentSelector';
import { Routes } from '../../../constants/routes';
import { EnrollmentErrors } from '../../../../../types/organization';
import {
  getSlug,
  getWorkIdFieldPlaceholderText,
} from '../../../state/selectors/orgSectionSelector/orgSectionSelector';

const messageStyle = css({
  color: WarmGrey[600],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  margin: 'auto',
  zIndex: 1,
});

const SECONDS_DELAY = 1000 * 10;
export const CatchAllErrorPage: FC = () => {
  const dispatch = useDispatch();
  const slug = useSelector(getSlug);
  const error = useSelector(getEnrollmentError);

  const workIdFieldPlaceholderText = useSelector(getWorkIdFieldPlaceholderText);
  const interpolateObj = workIdFieldPlaceholderText
    ? {
        partnerIdName: workIdFieldPlaceholderText,
      }
    : undefined;

  useEffect(() => {
    setTimeout(() => {
      dispatch(requestDeauthenticate(Routes.LOGOUT_CALLBACK_REDIRECT(slug)));
    }, SECONDS_DELAY);
  }, [dispatch, slug]);

  const errorMessage = () => {
    if (error && error.errorStringCode) {
      return translate(
        `enrollment.errors.${error.errorStringCode}`,
        interpolateObj,
      );
    }
    if (error && error.message) {
      return error.message;
    }
    return translate('enrollment.errors.GENERIC_ERROR');
  };

  const handleContactUsClick = useCallback(() => {
    window.location.href = 'mailto:teamsupport@headspace.com';
  }, []);

  const { translate } = useTranslate();
  return (
    <PageWrapper>
      <div css={messageStyle}>
        <h2>{errorMessage()}</h2>
        {error?.errorStringCode ===
          EnrollmentErrors.MEMBER_EMAIL_ENROLLED_IN_OTHER_ORG && (
          <div>
            <PrimaryButton onClick={handleContactUsClick}>
              {translate('enrollment.contactUs')}
            </PrimaryButton>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
