import * as React from 'react';
import {
  buttonSizeStyle,
  buttonStyle,
  pillStyleCss,
  hollowStyleCss,
  suppressLetterSpacingStyleCss,
} from './buttonStyles';

export interface TextButtonProps {
  value: string;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  width?: string;
  onKeyDown?: (event?: any) => void;
  className?: string;
  sm?: boolean;
  reg?: boolean;
  lg?: boolean;
  icon?: React.ReactElement;
  id?: string | number;
  pill?: boolean;
  hollow?: boolean;
  suppressLetterSpacing?: boolean;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  hoverColor?: string;
  type?: 'button' | 'submit' | 'reset';
  dataTestId?: string;
}

export const TextButton: React.FC<TextButtonProps> = ({
  sm,
  reg,
  lg,
  icon,
  pill,
  width,
  type,
  hollow,
  suppressLetterSpacing,
  backgroundColor,
  borderColor,
  textColor,
  hoverColor,
  value: text,
  className,
  dataTestId,
  onClick,
  onKeyDown,
  ...otherProps // data-attributes
}) => {
  const buttonSize = sm || reg ? 40 : 48;
  const lineHeight = sm ? 14 : 16;
  const options = {
    fontSize: sm ? 12 : 16,
    leftRightPadding: reg || lg ? 24 : 16,
    lineHeight,
    topBottomPadding: (buttonSize - lineHeight) / 2,
  };

  const pillStyle = pill ? pillStyleCss(borderColor) : '';
  const hollowStyle = hollow
    ? hollowStyleCss({ backgroundColor, textColor })
    : '';
  const suppressLetterSpacingStyle = suppressLetterSpacing
    ? suppressLetterSpacingStyleCss
    : '';

  return (
    // @ts-ignore
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      data-testid={dataTestId}
      css={[
        buttonSizeStyle(options),
        buttonStyle({
          backgroundColor,
          hoverColor,
          textColor,
          width,
        }),
        pillStyle,
        hollowStyle,
        suppressLetterSpacingStyle,
      ]}
      className={className}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...otherProps} // spreading props is needed for controlled form onSubmit and any additional attributes
    >
      {icon}
      {icon && <span>&nbsp;</span>}
      {text}
    </button>
  );
};
