import * as React from 'react';
import { useSelector } from 'react-redux';
import { Blue, rem, White } from '@headspace/web-ui-components';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import JoinMessage from '../../shared-components/JoinMessage/JoinMessage';
import {
  joinMessageStyleContainer,
  mobileStyleSectionContainer,
  buttonContainer,
} from './styles';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { getActiveOrg } from '../../../state/selectors/orgSectionSelector/orgSectionSelector';

interface MobileViewJoinMessageProps {
  isOpen: boolean;
  handleClose: React.EffectCallback;
}

const JoinMessageComponent: React.FC<MobileViewJoinMessageProps> = ({
  isOpen,
  handleClose,
}) => {
  const { translate } = useTranslate();

  const activeOrg = useSelector(getActiveOrg);
  return (
    <div css={mobileStyleSectionContainer(isOpen)}>
      <div css={joinMessageStyleContainer}>
        <JoinMessage activeOrg={activeOrg} />
        <div css={buttonContainer}>
          <TextButton
            textColor={White['000']}
            hoverColor={Blue[300]}
            backgroundColor={Blue[300]}
            dataTestId="join-message-next-button"
            value={translate('next')}
            pill={true}
            width={rem(15.625)}
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinMessageComponent;
