import * as React from 'react';
import { Green, Orange, WarmGrey } from '@headspace/web-ui-components';
import { format } from '../../../../../../../utils/dates';
import { BasicTable } from '../../../../../../../shared-components/tables/BasicTable';
import { BasicTableCell } from '../../../../../../../shared-components/tables/BasicTable/BasicTableCell';
import { BasicTableRow } from '../../../../../../../shared-components/tables/BasicTable/BasicTableRow';
import { BasicTableHeaderRow } from '../../../../../../../shared-components/tables/BasicTable/BasicTableHeaderRow';
import { SurveyDropDown } from './SurveyDropDown';
import {
  ctaContainerStyle,
  surveyTableRowStyle,
  dropDownContainerStyle,
  statusCellStyle,
  surveyTableHeaderRowStyle,
} from './styles';
import { HollowButton } from '../../../../../../../shared-components/buttons/HollowButton';
import {
  Survey,
  SurveyStatus,
  SurveyStatusEnum,
  uuid,
} from '../../../../../../../state/models/organization/Survey';
import { hollowButtonStyle } from '../../../styles/styles';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { qualitricsRoutes } from '../../../../../constants/routes';

interface SurveyTablePropTypes {
  surveys: Survey[];
  handleSelectSurvey: (selectedSurveyId: uuid) => void;
  totalActiveMembers: number;
}

interface SurveyStatusCellPropTypes {
  survey: Survey;
}

type SurveyStatusToButtonStyleType = {
  [key in SurveyStatus]: {
    color: string;
    text: string;
  };
};
const surveyStatusToButtonStyle: SurveyStatusToButtonStyleType = {
  ENDED: {
    color: WarmGrey[300],
    text: 'organizationSettings.survey.status.surveyEnded',
  },
  SENT: {
    color: Green[200],
    text: 'organizationSettings.survey.status.surveySent',
  },
  STAGED: {
    color: Orange[200],
    text: 'organizationSettings.survey.status.preview',
  },
};

const StatusCell = ({ survey }: SurveyStatusCellPropTypes) => {
  const { translate } = useTranslate();
  const { surveyStatus } = survey;
  const { color, text } = surveyStatusToButtonStyle[surveyStatus];
  const statusText = translate(text).toUpperCase();

  const handlePreviewSurvey = React.useCallback((e) => {
    const previewUrl = e.currentTarget?.getAttribute('data-url');
    window.open(previewUrl, '_blank');
  }, []);

  if (
    surveyStatus === SurveyStatusEnum.SENT ||
    surveyStatus === SurveyStatusEnum.ENDED
  ) {
    return <div css={statusCellStyle(color)}>{statusText}</div>;
  }

  return (
    <HollowButton
      color={color}
      data-url={`${qualitricsRoutes.BASE_URL}${qualitricsRoutes.SURVEY(
        survey.externalId,
      )}`}
      onClick={handlePreviewSurvey}
      css={hollowButtonStyle}
    >
      {statusText}
    </HollowButton>
  );
};

export const SurveyTable = ({
  surveys,
  handleSelectSurvey,
  totalActiveMembers,
}: SurveyTablePropTypes) => {
  const { translate } = useTranslate();

  const tableHeadersName = translate(
    'organizationSettings.survey.tableHeaders.name',
  );
  const tableHeadersDateCreated = translate(
    'organizationSettings.survey.tableHeaders.dateCreated',
  );
  const tableHeadersRecipients = translate(
    'organizationSettings.survey.tableHeaders.recipients',
  );

  return (
    <BasicTable dataTestId="survey-table">
      <BasicTableHeaderRow css={surveyTableHeaderRowStyle}>
        <BasicTableCell>{tableHeadersName}</BasicTableCell>
        <BasicTableCell>{tableHeadersDateCreated}</BasicTableCell>
        <BasicTableCell>{tableHeadersRecipients}</BasicTableCell>
        <BasicTableCell />
      </BasicTableHeaderRow>

      {surveys.map((survey) => {
        const {
          id,
          name,
          launchDate,
          createdOn,
          surveyStatus,
          externalId,
        } = survey;
        const isStatusStaged = surveyStatus === SurveyStatusEnum.STAGED;
        const transformedDate =
          launchDate === undefined
            ? translate('organizationSettings.survey.dateAtTime', {
                date: format(createdOn, 'MM/DD/YYYY'),
                time: format(createdOn, 'h:mma'),
              })
            : translate('organizationSettings.survey.dateAtTime', {
                date: format(launchDate, 'MM/DD/YYYY'),
                time: format(launchDate, 'h:mma'),
              });

        return (
          <BasicTableRow key={id} css={surveyTableRowStyle}>
            <BasicTableCell>{name}</BasicTableCell>
            <BasicTableCell>{transformedDate}</BasicTableCell>
            <BasicTableCell>{totalActiveMembers}</BasicTableCell>
            <BasicTableCell css={ctaContainerStyle}>
              <div css={dropDownContainerStyle}>
                <StatusCell survey={survey} />
                <SurveyDropDown
                  onSelectSurvey={handleSelectSurvey}
                  surveyId={id}
                  showSendSurveySelection={isStatusStaged}
                  externalId={externalId}
                  surveyName={name}
                />
              </div>
            </BasicTableCell>
          </BasicTableRow>
        );
      })}
    </BasicTable>
  );
};
