import { css } from '@emotion/react';
import {
  Modal,
  rem,
  SpacingInRemBySize,
  Spinner,
} from '@headspace/web-ui-components';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useGetVerificationLink } from '../../../../../../../hooks/useGetVerificationLink';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import { Input } from '../../../../../../../shared-components/Input';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { resetError } from '../../../../../../../utils/messageDefaults';
import {
  emailCheck,
  emailError,
  emailDomainsCheck,
} from '../../../../../../../utils/validations';
import {
  columnFlex,
  flex,
  justifyContentSpaceBetween,
} from '../../../../../styles/flex';
import useVerificationErrorMessages from './useVerificationErrorMessages';
import { RestrictBy } from '../../../../../../../types/organization';
import { HOST } from '../../../../../../../utils/constants';

const modalStyle = css(flex, columnFlex, justifyContentSpaceBetween, {
  marginTop: rem(2),
  minHeight: rem(14.5),
});
const linkButtonStyle = css({ marginTop: SpacingInRemBySize.M });
const spinnerStlye = css({ margin: `${rem(1)} 0 ${rem(1)}` });

interface GenerateVerificationLinkModalProps {
  defaultEmail?: string;
  disabled?: boolean;
  onClose: (e: React.MouseEvent) => unknown;
}

export const GenerateVerificationLinkModal: React.FC<GenerateVerificationLinkModalProps> = ({
  defaultEmail,
  disabled,
  onClose,
}) => {
  const { translate } = useTranslate();
  const [isCopied, setCopied] = React.useState(false);
  const [email, setEmail] = React.useState(defaultEmail || '');
  const [validationError, setValidationError] = React.useState(resetError());
  const { orgId, restrictedDomains, restrictBy } = useSelector(
    getOrgManagement,
  );
  const { isFetching, error, data, refetch } = useGetVerificationLink(
    orgId,
    email,
  );
  const isDisabled = email.length === 0 || validationError.error || isFetching;
  const emailErrorMessage = translate(`validationErrors.${emailError.message}`);
  const errorMessagesMap = useVerificationErrorMessages();

  const generateErrorMessage = (error) => {
    const entriesArray = Array.from(errorMessagesMap.entries());

    for (const [key, value] of entriesArray) {
      if (value === error) {
        return key;
      }
    }
    return undefined;
  };

  const handleCloseModal = React.useCallback(
    (e) => {
      onClose(e);
      setEmail('');
      setCopied(false);
    },
    [onClose],
  );
  const handleChangeEmail = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      setCopied(false);
    },
    [setEmail],
  );
  const handleClickSubmit = React.useCallback(() => {
    if (isDisabled) return;

    refetch();
  }, [refetch, isDisabled]);
  const handleKeyDownEmail = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleClickSubmit();
      }
    },
    [handleClickSubmit],
  );

  React.useEffect(() => {
    let result;

    if (email.length === 0) {
      result = resetError();
    } else if (emailCheck(email)) {
      if (
        restrictedDomains.length === 0 ||
        restrictBy !== RestrictBy.EMAIL_DOMAIN ||
        (restrictedDomains.length > 0 &&
          emailDomainsCheck(email, restrictedDomains))
      ) {
        result = resetError();
      } else {
        result = {
          error: true,
          message: translate(
            'organizationSettings.enrollment.generateActivationLinkDomain',
          ),
          validated: false,
        };
      }
    } else {
      result = {
        error: true,
        message: emailErrorMessage,
        validated: false,
      };
    }
    setValidationError(result);
  }, [email, setValidationError, emailErrorMessage, restrictedDomains]);

  React.useEffect(() => {
    if (error) {
      const errorMessage = generateErrorMessage(
        error?.response?.data?.errors[0]?.detail,
      );

      setValidationError({
        error: true,
        message: errorMessage
          ? translate(`organizationSettings.enrollment.${errorMessage}`)
          : translate(
              'organizationSettings.enrollment.generateActivationLinkFailed',
            ),
        validated: false,
      });
    } else if (data?.data?.link) {
      // Fixing local links
      const { pathname, search } = new URL(data.data.link);
      const url = new URL(pathname, HOST);
      url.search = search;

      setCopied(true);

      if (navigator?.clipboard?.writeText) {
        navigator.clipboard.writeText(url.href);
      } else {
        /* eslint-disable-next-line no-console */
        console.log(`Verification Link: ${url.href}`);
      }
    }
  }, [error, data, setCopied]);

  const modalBody = (
    <div data-testid="verification-link-modal" css={modalStyle}>
      <Input
        hasError={validationError.error}
        errorMessage={validationError.message}
        name="verification-email"
        value={email}
        onChange={handleChangeEmail}
        onKeyDown={handleKeyDownEmail}
        type="text"
        label="Email for activation link"
        data-testid="verification-email-input"
        disabled={disabled}
      />
      {isFetching ? <Spinner css={spinnerStlye} /> : null}
      {isCopied ? (
        <div>
          {translate('organizationSettings.enrollment.activationLinkCopied')}
        </div>
      ) : null}
      <TextButton
        onClick={handleClickSubmit}
        type="button"
        value={translate(
          'organizationSettings.enrollment.generateActivationLink',
        )}
        css={linkButtonStyle}
        disabled={isDisabled}
        dataTestId="verification-email-submit"
      />
    </div>
  );

  return <Modal body={modalBody} onClose={handleCloseModal} />;
};
