import { FC, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingBox from './LoadingBox';
import ErrorBox from './ErrorBox';
import SuccessBox from './SuccessBox';
import { setEmailToken } from '../../../state/actions/memberEnrollActions/memberEnrollActions';
import { ZUORA } from '../../../constants';
import { buttonClickthroughAction } from '../../../../../analytics/events/enrollmentEvents';
import { useTranslate } from '../../../../../hooks/useTranslate';
import {
  getActiveOrg,
  getSlug,
  getVoucher,
} from '../../../state/selectors/orgSectionSelector/orgSectionSelector';
import {
  getEmail,
  getPrivileges,
} from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getExternalSource } from '../../../state/selectors/memberSubscriptionDetailsSelector/memberSubscriptionDetailsSelector';
import {
  getEmailVerificationError,
  getVerifyStatus,
} from '../../../state/selectors/emailVerificationSelector/emailVerificationSelector';

interface VerifiedAccountProps {
  urlParsedToken: string;
}

const AccountVerifiedByEmail: FC<VerifiedAccountProps> = ({
  urlParsedToken,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const activeOrg = useSelector(getActiveOrg);
  const slug = useSelector(getSlug);
  const email = useSelector(getEmail);
  const privileges = useSelector(getPrivileges);
  const externalSource = useSelector(getExternalSource);
  const verificationStatus = useSelector(getVerifyStatus);
  const verificationError = useSelector(getEmailVerificationError);
  const partialPayVoucher = useSelector(getVoucher);

  const PAID_THROUGH_ZUORA = externalSource === ZUORA;
  const SUBSCRIBER = privileges.includes('SUBSCRIBER');

  useEffect(() => {
    if (urlParsedToken) {
      dispatch(setEmailToken(urlParsedToken));
    }
  }, [setEmailToken, urlParsedToken]);

  const handleClick = useCallback(() => {
    dispatch(buttonClickthroughAction(translate('next')));

    if (partialPayVoucher) {
      if (SUBSCRIBER) {
        return navigate(`/${slug}/member-enroll/turn-off-auto-pay`);
      }
      return navigate(`/${slug}/member-enroll/payment`);
    }

    if (SUBSCRIBER) {
      if (PAID_THROUGH_ZUORA) {
        return navigate(`/${slug}/member-enroll/turn-off-auto-pay`);
      }
    }

    return navigate(`/${slug}/member-enroll/finish`);
  }, [PAID_THROUGH_ZUORA, SUBSCRIBER, partialPayVoucher]);

  const conditionalRender = () => {
    if (verificationStatus === 204) {
      return (
        <SuccessBox
          email={email}
          activeOrg={activeOrg}
          handleClick={handleClick}
        />
      );
    }

    if (verificationError) {
      return <ErrorBox verificationError={verificationError} />;
    }

    return <LoadingBox />;
  };

  return conditionalRender();
};

export default AccountVerifiedByEmail;
