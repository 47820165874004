import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';
import {
  OrganizationActionsTypes,
  NewDomainAction,
} from '../../types/organization';
import { GetState } from '../../types';
import { domainCheck } from '../../../utils/validations';

const { NEW_DOMAIN } = OrganizationActionsTypes;

export const newDomain = (): ThunkMiddleware & NewDomainAction => {
  // @ts-ignore
  return (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: { add_domain, restrictedDomains },
    } = getState();
    const newDomains = restrictedDomains.slice();

    if (
      add_domain &&
      domainCheck(add_domain) &&
      !newDomains.includes(add_domain)
    ) {
      newDomains.push(add_domain);
    }
    dispatch({
      type: NEW_DOMAIN,
      payload: {
        restrictedDomains: newDomains,
        add_domain: '',
      },
    });
  };
};
