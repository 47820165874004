import { PaymentActionTypes } from '../../actions/paymentActions/paymentActionTypes';

const {
  FETCH_PAYMENT_METHOD,
  FETCH_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHOD_ERROR,
} = PaymentActionTypes;

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
};

export function paymentMethodInfoReducer(
  state = INITIAL_STATE,
  action: Action,
) {
  switch (action.type) {
    case FETCH_PAYMENT_METHOD:
      return { ...state, isLoading: true };
    case FETCH_PAYMENT_METHOD_SUCCESS:
      return { ...state, data: action.payload.data, isLoading: false };
    case FETCH_PAYMENT_METHOD_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
}

export type PaymentMethodInfoState = ReturnType<
  typeof paymentMethodInfoReducer
>;
