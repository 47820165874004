import {
  bodyLCss,
  cssWithMq,
  fontSizeM,
  fontSizeL,
} from '@headspace/web-ui-components';
import { HealthProvider } from '../../constants/healthProvider';
import { PageWrapper } from './PageWrapper';
import { contentCss, linkCss, titleCss } from './styles';

const textCss = cssWithMq(bodyLCss, {
  fontSize: [fontSizeM, fontSizeL],
  margin: 0,
});

const GingerSoleraErrorPage = () => {
  return (
    <PageWrapper healthProvider={HealthProvider.SOLERA}>
      <div css={contentCss}>
        <h1 css={titleCss}>Sorry, an unexpected error occurred.</h1>
        <p css={textCss}>
          Please email &nbsp;
          <a css={linkCss} href="mailto:support@gosolera.com">
            support@gosolera.com
          </a>
        </p>
        <p css={textCss}>
          Or call 1 (866) 671-9644 and our team will be happy to help.
        </p>
      </div>
    </PageWrapper>
  );
};

export default GingerSoleraErrorPage;
