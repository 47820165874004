import { css } from '@emotion/react';
import { bodyXSCss, rem } from '@headspace/web-ui-components';
import { FormattedMessage } from 'react-intl';
import { TERMS_AND_CONDITIONS_LINK, PRIVACY_PAGE_LINK } from '../../constants';

const termsStyle = css({
  maxWidth: rem(14),
  textAlign: 'center',
  margin: 'auto',
  '> a': {
    textDecoration: 'underline',
  },
});

export const TermsOfService = () => {
  return (
    <div css={[bodyXSCss, termsStyle]}>
      <FormattedMessage
        id="enrollment.landingPage.terms"
        values={{
          termsLink: (chunks) => (
            <a
              css={css({
                display: 'inline-block',
              })}
              href={TERMS_AND_CONDITIONS_LINK}
              target="_blank"
              rel="noreferrer"
            >
              {chunks}
            </a>
          ),
          privacyLink: (chunks) => (
            <a href={PRIVACY_PAGE_LINK} target="_blank" rel="noreferrer">
              {chunks}
            </a>
          ),
        }}
      />
    </div>
  );
};
