import { css } from '@emotion/react';
import { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrganizationDetailHeader } from './Header/OrganizationDetailHeader';
import { TabRoutesComponent } from './TabRoutes';
import { drain } from '../../../state/actions/organizationActions';
import { getOrgManagement } from '../../../state/selectors/organizationSelectors';
import { isCSM as getIsCSM } from '../../../state/selectors/userSelectors';
import { getChallenges } from '../../../state/selectors/challengesSelectors';
import { Header } from '../Header/Header';

import { queryOrganization } from '../../../state/actions/organizationActions/queryOrganization';
import { useUpdateDocumentTitle } from '../../../hooks/useUpdateDocumentTitle';

const containerStyle = css({
  width: '100%',
});

export const Organization: FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const {
    cachedOrg,
    org_name,
    current_contract_is_pilot,
    current_contract_start_date,
    current_contract_end_date,
    future_contract_is_pilot,
    future_contract_start_date,
    future_contract_end_date,
    logo,
  } = useSelector(getOrgManagement);
  const isCSM = useSelector(getIsCSM);
  const challenges = useSelector(getChallenges);
  const hasChallenges = challenges.length > 0;

  const startDate = current_contract_start_date || future_contract_start_date;
  const endDate = current_contract_start_date
    ? current_contract_end_date
    : future_contract_end_date;
  const isPilot = current_contract_start_date
    ? current_contract_is_pilot
    : future_contract_is_pilot;

  useUpdateDocumentTitle('');
  // drain() will reset organization state
  useEffect(() => {
    dispatch(drain());
  }, []);

  useEffect(() => {
    if (params?.id) {
      const id = parseInt(params.id, 10);
      // this will call hydrate() and set current organization state
      dispatch(queryOrganization(id, isCSM));
    }
  }, [dispatch, params.id, isCSM]);

  return (
    <div className="organization" css={containerStyle}>
      <Header />
      <OrganizationDetailHeader
        isCSM={isCSM}
        hasChallenges={hasChallenges}
        orgId={params?.id ? +params?.id : cachedOrg?.id}
        name={org_name}
        startDate={startDate}
        endDate={endDate}
        logo={logo}
        isPilot={isPilot}
      />
      {cachedOrg ? <TabRoutesComponent /> : null}
    </div>
  );
};
