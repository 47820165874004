import * as React from 'react';
import { ChallengesTableTitle } from './ChallengesTableTitle';
import { ChallengeReceivedType } from '../../../types';
import { ChallengesTable } from './ChallengesTableList';
import { BasicTable } from '../../../../../../../shared-components/tables/BasicTable';
import { ChallengeCreateButton } from './ClallengeCreateButton';

interface ChallengesTableSectionPropTypes {
  challenges: Array<ChallengeReceivedType>;
  createChallengeHandler: () => void;
}

export const ChallengesTableSection: React.FC<ChallengesTableSectionPropTypes> = ({
  challenges,
  createChallengeHandler,
}) => {
  return (
    <BasicTable dataTestId="challenges-table">
      <ChallengesTableTitle />
      <ChallengesTable challenges={challenges} />
      <ChallengeCreateButton createChallengeHandler={createChallengeHandler} />
    </BasicTable>
  );
};
