import '@babel/polyfill';
import './assets/fonts.css';
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { StatsigProvider } from 'statsig-react';
import { store, persistor } from './state/store';
import { i18n } from './i18n';
import { SENTRY_CONFIG } from './utils/sentryConfig';
import { createStatsigConfig } from './utils/statsig';
import { router } from './root-component/router';

Sentry.init(SENTRY_CONFIG);

const statsigConfig = createStatsigConfig(store);
const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <StatsigProvider {...statsigConfig}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router} />
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </StatsigProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
