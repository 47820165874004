import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RESTRICT_BY_MIGRATION_STEPS } from '../../../apps/dashboard/constants';
import { RootState } from '../../types';
import { uploadELFFile } from './uploadELFileThunk';
import { confirmRestrictByChange } from './confirmRestrictByChangeThunk';
import { RESTRICT_BY_MIGRATION_REDUCER_NAME } from './constants';
import { uploadDomains } from './uploadDomainsThunk';

export interface ElFileReport {
  added: number | null;
  createdAt?: string;
  errors: string;
  errorCount: number;
  filename?: string;
  removed: number | null;
  restrictBy?: string;
  status?: string;
  updated: number | null;
}

export interface ElFileProcessingReport {
  createdAt: string;
  filename: string;
  restrictBy: string;
  status: string;
}

interface RestrictByMigrationState {
  activeStep: number;
  finished: boolean;
  isLoading?: boolean;
  isDataUploadFinished?: boolean;
  elFileReport?: ElFileReport;
}

const firstStep = +Object.keys(RESTRICT_BY_MIGRATION_STEPS)[0];
export const initialState: RestrictByMigrationState = {
  activeStep: firstStep,
  finished: false,
};

export const restrictByMigrationSlice = createSlice({
  initialState,
  name: RESTRICT_BY_MIGRATION_REDUCER_NAME,
  reducers: {
    nextStep: (state) => {
      state.activeStep++;
    },
    resetMigration: () => {
      return { ...initialState };
    },
    setFinished: (state, action: PayloadAction<boolean>) => {
      state.finished = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(confirmRestrictByChange.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(confirmRestrictByChange.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.finished = true;
      state.activeStep++;
    });
    builder.addCase(confirmRestrictByChange.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addMatcher(
      isAnyOf(uploadELFFile.pending, uploadDomains.pending),
      (state) => {
        state.isDataUploadFinished = false;
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(uploadELFFile.fulfilled, uploadDomains.fulfilled),
      (state, { payload }) => {
        state.isLoading = false;
        state.isDataUploadFinished = true;
        state.elFileReport = payload as ElFileReport;
      },
    );
    builder.addMatcher(
      isAnyOf(uploadELFFile.rejected, uploadDomains.rejected),
      (state) => {
        state.isLoading = false;
        state.isDataUploadFinished = false;
      },
    );
  },
});

export const {
  nextStep,
  resetMigration,
  setFinished,
} = restrictByMigrationSlice.actions;

export const selectRestrictByMigration = (state: RootState) =>
  state.restrictByMigration;

export const selectActiveStep = (state: RootState) =>
  selectRestrictByMigration(state).activeStep;

export const selectFinishedStep = (state: RootState) =>
  selectRestrictByMigration(state).finished;

export const selectDataUploadFinished = (state: RootState) =>
  selectRestrictByMigration(state).isDataUploadFinished;
