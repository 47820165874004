import { css, SerializedStyles } from '@emotion/react';
import { rem, labelSCss } from '@headspace/web-ui-components';

export const iconButtonPronouncedStyle = (
  color: string,
  backgroundColor: string,
  cursor?: string,
): SerializedStyles => {
  return css({
    '& .iconButton': {
      ':hover': {
        backgroundColor,
        cursor: cursor ?? 'pointer',
      },
      backgroundColor: color,
      outline: 'none',
    },
    ':hover': {
      color: backgroundColor,
      cursor: cursor ?? 'pointer',
    },
    color,
    display: 'flex',
  });
};

export const iconButtonPronouncedTextStyle: SerializedStyles = css(labelSCss, {
  letterSpacing: rem(0.156),
  lineHeight: rem(2),
  marginLeft: rem(0.938),
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});
