import Collapsible from 'react-collapsible';
import { FC, ReactNode, useCallback, useState } from 'react';
import { HorizontalDivider } from '../../shared-components/Divider/Divider';
import {
  collapsibleInfoExpandedStyle,
  collapsibleInfoListStyle,
  collapsibleInfoStyle,
  linkHelpEmailStyle,
} from '../styles';
import { HELP_EMAIL } from '../constants';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface CollapsibleInfoProps {
  children?: ReactNode;
  title: string;
  listItems?: string[];
}
export const CollapsibleInfo: FC<CollapsibleInfoProps> = ({
  children,
  title,
  listItems = [],
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleOpenCollapsibleInfo = useCallback(() => setIsExpanded(true), []);
  const handleCloseCollapsibleInfo = useCallback(
    () => setIsExpanded(false),
    [],
  );
  return (
    <div>
      <HorizontalDivider marginTop={10} marginBottom={10} />
      <Collapsible
        open={isExpanded}
        transitionTime={200}
        lazyRender={true}
        onTriggerOpening={handleOpenCollapsibleInfo}
        onTriggerClosing={handleCloseCollapsibleInfo}
        trigger={
          <p
            css={collapsibleInfoStyle(isExpanded)}
            data-testid="collapsible-info"
          >
            {title}
          </p>
        }
      >
        <div css={collapsibleInfoExpandedStyle}>
          <ul css={collapsibleInfoListStyle}>
            {listItems.map((item: string) => (
              <li key={item} data-testid="collapsible-info-list-item">
                {item}
              </li>
            ))}
          </ul>
          {children}
        </div>
      </Collapsible>
      <HorizontalDivider marginTop={10} marginBottom={10} />
    </div>
  );
};

export const ContactHelpEmail = () => {
  const { translate } = useTranslate();

  return (
    <p data-testid="collapsible-info-contact-help-email">
      {translate('enrollment.vpCareCollapsibleInfo.contactEmail1')}
      <a
        href="mailto:teamsupport@headspace.com"
        target="_blank"
        rel="noopener noreferrer"
        css={linkHelpEmailStyle}
      >
        {` ${HELP_EMAIL} `}
      </a>
      {translate('enrollment.vpCareCollapsibleInfo.contactEmail2')}
    </p>
  );
};
