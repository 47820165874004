import { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OrganizationAdministratorsEdit } from '../../OrganizationAdministrators/OrganizationAdministratorsEdit';
import { OrganizationAdministratorsReview } from '../../OrganizationAdministrators/OrganizationAdministratorsReview';
import {
  getOrgManagement,
  getAdminDeleteDisabledStatus,
  getAdminSaveDisabledStatus,
  getAdminCancelDisabledStatus,
} from '../../../../state/selectors/organizationSelectors';
import {
  handleChange as handleChangeAction,
  updateImplicitState,
  newAdmin,
  saveAdmin,
  updateAdmin,
  cancelUpdateAdmin,
  deleteAdmin,
} from '../../../../state/actions/organizationActions';
import { columnFlex, flex1 } from '../../styles/flex';

export const Administrators: FC = () => {
  const {
    adminReviewView,
    adminEditExisting,
    admin_name,
    admin_title,
    admin_email,
    org_admins,
    admin_nameError,
    admin_titleError,
    admin_emailError,
  } = useSelector(getOrgManagement);
  const adminSaveDisabledStatus = useSelector(getAdminSaveDisabledStatus);
  const adminDeleteDisabledStatus = useSelector(getAdminDeleteDisabledStatus);
  const adminCancelDisabledStatus = useSelector(getAdminCancelDisabledStatus);

  const dispatch = useDispatch();

  const handleChange = useCallback(
    (event: ExtendedEvent) => dispatch(handleChangeAction(event)),
    [dispatch],
  );

  const handleError = useCallback(
    (event: ExtendedEvent) => dispatch(updateImplicitState(event)),
    [dispatch],
  );

  const handleAdminSave = useCallback(() => dispatch(saveAdmin()), [dispatch]);

  const handleAdminAdd = useCallback(() => dispatch(newAdmin()), [dispatch]);

  const handleAdminDelete = useCallback(
    (event: ExtendedEvent) => dispatch(deleteAdmin(event, false)),
    [dispatch],
  );

  const handleAdminCancel = useCallback(() => dispatch(cancelUpdateAdmin()), [
    dispatch,
  ]);

  const handleAdminEdit = useCallback(
    (event: ExtendedEvent) => dispatch(updateAdmin(event)),
    [dispatch],
  );

  return (
    <div data-testid="admin-page" css={[columnFlex, flex1]}>
      {adminReviewView && (
        <OrganizationAdministratorsReview
          admins={org_admins}
          handleAdminAdd={handleAdminAdd}
          handleAdminDelete={handleAdminDelete}
          handleAdminEdit={handleAdminEdit}
        />
      )}
      <OrganizationAdministratorsEdit
        isEdit={adminEditExisting}
        admin_name={admin_name}
        admin_nameError={admin_nameError}
        admin_title={admin_title}
        admin_titleError={admin_titleError}
        admin_email={admin_email}
        admin_emailError={admin_emailError}
        adminSaveDisabledStatus={adminSaveDisabledStatus}
        adminDeleteDisabledStatus={adminDeleteDisabledStatus}
        adminCancelDisabledStatus={adminCancelDisabledStatus}
        handleAdminDelete={handleAdminDelete}
        handleAdminCancel={handleAdminCancel}
        handleAdminSave={handleAdminSave}
        handleChange={handleChange}
        handleError={handleError}
      />
    </div>
  );
};
