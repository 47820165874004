import { SecondaryButton, WarmGrey } from '@headspace/web-ui-components';
import { FC } from 'react';
import {
  Choice,
  Family,
  QuestionId,
} from '../../../../../models/OnboardingQuestions';
import { OnboardingMultipleChoiceCard } from './OnboardingMultipleChoiceCard';
import { choiceButtonStyle } from '../styles/questionCardStyle';
import { OnboardingImportanceSingleChoiceCard } from './OnboardingImportanceSingleChoiceCard';
import { TEXT_REPRESENTATION_SINGLE_CHOICE_IDS } from './constants';
import { OnboardingSingleChoiceText } from './OnboardingSingleChoiceText';
import { OnboardingSlider } from './OnboardingSlider';

interface OnboardingSelectionControlProps {
  choices: Choice[];
  questionId: QuestionId;
  title: string;
  onSelectAnswer: (questionId: string, title: string, id: string) => void;
  family: Family;
}

export const OnboardingSelectionControl: FC<OnboardingSelectionControlProps> = ({
  choices,
  onSelectAnswer,
  questionId,
  title,
  family,
}) => {
  if (family === Family.SLIDER) {
    return (
      <OnboardingSlider
        choices={choices}
        onSelectAnswer={onSelectAnswer}
        questionId={questionId}
        title={title}
      />
    );
  }
  if (family === Family.MULTIPLE_CHOICE) {
    return (
      <OnboardingMultipleChoiceCard
        choices={choices}
        onSelectAnswer={onSelectAnswer}
        questionId={questionId}
        title={title}
      />
    );
  }
  if (
    family === Family.SINGLE_CHOICE &&
    TEXT_REPRESENTATION_SINGLE_CHOICE_IDS.includes(questionId)
  ) {
    return (
      <OnboardingSingleChoiceText
        choices={choices}
        onSelectAnswer={onSelectAnswer}
        questionId={questionId}
        title={title}
      />
    );
  }
  if (family === Family.SINGLE_CHOICE) {
    return (
      <OnboardingImportanceSingleChoiceCard
        questionId={questionId}
        title={title}
        choices={choices}
        onSelectAnswer={onSelectAnswer}
      />
    );
  }

  return (
    <div>
      {choices.map(({ text, id }) => (
        <div css={choiceButtonStyle} key={id}>
          <SecondaryButton
            onClick={() => onSelectAnswer(questionId, title, id)}
            borderColor={WarmGrey[200]}
            id={id}
          >
            {text}
          </SecondaryButton>
        </div>
      ))}
    </div>
  );
};
