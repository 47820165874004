import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { MemberLogin } from './views/MemberLoginPage';
import Verify from './views/VerifyPage';
import VerifiedAccount from './views/VerifiedAccountPage';
import TurnOffAutoPay from './views/TurnOffAutoPayPage';
import Finish from './views/FinishPage/FinishPage';
import MemberPay from './views/MemberPayPage';
import PaymentProcessed from './views/PaymentProcessedPage';
import ExistingPaidSubscription from './views/ExistingPaidSubscriptionPage/ExistingPaidSubscription';

export const EnrollmentRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="login" element={<MemberLogin />} />
      <Route path="verify" element={<Verify />} />
      <Route path="verified-account" element={<VerifiedAccount />} />
      <Route path="finish" element={<Finish />} />
      <Route path="turn-off-auto-pay" element={<TurnOffAutoPay />} />
      <Route path="payment" element={<MemberPay />} />
      <Route path="processed-payment" element={<PaymentProcessed />} />
      <Route
        path="existing-paid-subscription"
        element={<ExistingPaidSubscription />}
      />
      <Route path="/" element={<MemberLogin />} />
    </Routes>
  );
};
