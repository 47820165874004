import { css } from '@emotion/react';
import { rem } from '@headspace/web-ui-components';

// TODO: Convert all one-off implementation to style guide standards in component

export const boxSizingBorderBox = css({
  boxSizing: 'border-box',
});

export const visuallyHidden = css({
  border: 0,
  clip: 'rect(0,0,0,0)',
  height: rem(0.063),
  margin: rem(-0.063),
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: rem(0.063),
});
