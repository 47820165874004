import {
  GetOrgByAttributeAction,
  OrganizationActionsTypes,
} from '../../types/organization';

export const getOrgBySlug = (slug: string): GetOrgByAttributeAction => {
  return {
    payload: {
      attributeName: 'slug',
      attributeValue: slug,
    },
    type: OrganizationActionsTypes.GET_ORG_BY_ATTRIBUTE,
  };
};

export const getOrgByHeadspaceHealthId = (
  headspaceHealthId: string,
): GetOrgByAttributeAction => {
  return {
    payload: {
      attributeName: 'headspaceHealthId',
      attributeValue: headspaceHealthId,
    },
    type: OrganizationActionsTypes.GET_ORG_BY_ATTRIBUTE,
  };
};
