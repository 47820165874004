import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { Section } from './components/Section';

import { getToolKitAssets } from './actions';
import { RootState } from '../../../../../state/types';

const rightPanelStyle = css({
  flexShrink: 1,
});

export const ComposedRightPanel: FC = () => {
  const assets = useSelector((state: RootState) => state.toolkit?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getToolKitAssets());
  }, [dispatch]);

  return (
    <div css={rightPanelStyle}>
      {assets.map((item: any) => (
        <div key={item.sys.id}>
          <Section
            assets={item.fields.resourceAssets}
            title={item.fields.categoryTitle}
            message={item.fields.categoryBlurb}
          />
        </div>
      ))}
    </div>
  );
};
