import axios from 'axios';
import * as Sentry from '@sentry/browser';
import FileSaver from 'file-saver';
import { format } from '../../utils/dates';
import { getB2BChallengesUrl, getB2BOrgIdUrl } from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import { HTTPMethod, responseType } from '../../utils/constants';
import {
  CreateChallengeUrl,
  DownloadChallengeReport,
  CancelChallenge,
  FetchingChallengeUrl,
} from './challenge.types';

export const createChallenge: CreateChallengeUrl = ({ orgId, ...options }) =>
  axios({
    method: HTTPMethod.POST,
    url: getB2BChallengesUrl(orgId).CREATE_CHALLENGE,
    data: options,
    headers: createAuthorizationHeader(),
  }).catch((e) => {
    Sentry.addBreadcrumb({
      message: `Failed in createChallenge with Error:${e}`,
    });

    throw e;
  });

export const downloadChallengeReport: DownloadChallengeReport = (orgId, name) =>
  axios({
    method: HTTPMethod.GET,
    url: getB2BOrgIdUrl(orgId).GET_CHALLENGES_REPORT,
    headers: createAuthorizationHeader(),
    responseType: responseType.BLOB,
  })
    .then((response) => {
      FileSaver.saveAs(
        response.data,
        `${name}_${format(new Date(), 'YYYY-MM-DDTHH_mm_ssZZ')}`,
      );
    })
    .catch((err) => {
      Sentry.addBreadcrumb({
        message: `Failed in downloadChallengeReport with Error:${err}`,
      });
      if (err.response?.status === 404) {
        throw new Error(
          'Your report isn’t available yet. Please check again next week.',
        );
      }
      throw err;
    });

export const getChallenges: FetchingChallengeUrl = (id: number) =>
  axios({
    method: HTTPMethod.GET,
    url: getB2BChallengesUrl(id).GET_CHALLENGES,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  }).catch((e) => {
    Sentry.addBreadcrumb({
      message: `Failed in fetchingChallenges with Error:${e}`,
    });

    throw e;
  });

export const cancelChallenge: CancelChallenge = (orgId, id) =>
  axios({
    method: HTTPMethod.PUT,
    url: getB2BChallengesUrl(orgId, id).CANCEL_CHALLENGE,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  }).catch((e) => {
    Sentry.addBreadcrumb({
      message: `Failed in cancelingChallenge with Error:${e}`,
    });

    throw e;
  });
