import { useCallback, useState } from 'react';

export const useModal = (startOpen = false) => {
  const [isOpen, setOpen] = useState(startOpen);

  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return { closeModal, isOpen, openModal };
};
