import { useGate } from 'statsig-react';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_edit_future_contract
// Feature flag to enable Project Blue Sky Rebrand
export const KEY = 'b2b_dashboard_edit_future_contract';

export const useEditFutureContract = (): [boolean, boolean] => {
  const { value, isLoading } = useGate(KEY);

  return [value, isLoading];
};
