import * as React from 'react';
import { cellStyle, ctaContainerStyle } from '../styles';
import { BasicTableHeaderRow } from '../../../../../../../shared-components/tables/BasicTable/BasicTableHeaderRow';
import { BasicTableCell } from '../../../../../../../shared-components/tables/BasicTable/BasicTableCell';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

export const ChallengesTableTitle = () => {
  const { translate } = useTranslate();

  return (
    <BasicTableHeaderRow>
      <BasicTableCell data-testid="challenges-table-title-id" css={cellStyle}>
        {translate(
          'challenges.challengesList.challengesTableTitles.challengeId',
        )}
      </BasicTableCell>
      <BasicTableCell
        data-testid="challenges-table-title-startDate"
        css={cellStyle}
      >
        {translate('challenges.challengesList.challengesTableTitles.startDate')}
      </BasicTableCell>
      <BasicTableCell
        data-testid="challenges-table-title-endDate"
        css={cellStyle}
      >
        {translate('challenges.challengesList.challengesTableTitles.endDate')}
      </BasicTableCell>
      <BasicTableCell
        data-testid="challenges-table-title-challengeType"
        css={cellStyle}
      >
        {translate(
          'challenges.challengesList.challengesTableTitles.challengeType',
        )}
      </BasicTableCell>
      <BasicTableCell data-testid="challenges-table-title-goal" css={cellStyle}>
        {translate('challenges.challengesList.challengesTableTitles.goal')}
      </BasicTableCell>
      <BasicTableCell
        data-testid="challenges-table-title-status"
        css={cellStyle}
      >
        {translate('challenges.challengesList.challengesTableTitles.status')}
      </BasicTableCell>
      <BasicTableCell css={ctaContainerStyle} />
    </BasicTableHeaderRow>
  );
};
