import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DependentsMessage } from './components/DependentsMessage';
import { FinishPageWrapper } from './components/FinishPageWrapper';
import { enrollmentSuccessScreenViewAction } from '../../../../analytics/events/enrollmentEvents';
import { subHeaderStyle } from './styles';
import { AppLinks } from './components/AppLinks';
import { InfoCard } from './components/InfoCard';
import { MFARedirectCard } from './components/MFARedirectCard';
import { useTranslate } from '../../../../hooks/useTranslate';
import { getEmail } from '../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  getDependentsPerMember,
  getHasDependentProgram,
  getMfaEnabled,
} from '../../state/selectors/orgSectionSelector/orgSectionSelector';

const Finish: React.FC = () => {
  const dispatch = useDispatch();
  const email = useSelector(getEmail);
  const isDependentAccessOrg = useSelector(getHasDependentProgram);
  const dependentsPerMember = useSelector(getDependentsPerMember);
  const isMfaEnabled = useSelector(getMfaEnabled);
  const { translate } = useTranslate();

  React.useEffect(() => {
    dispatch(enrollmentSuccessScreenViewAction());
  }, [dispatch]);

  return (
    <FinishPageWrapper>
      {isDependentAccessOrg ? (
        <DependentsMessage dependentsPerMember={dependentsPerMember} />
      ) : null}
      <h4 css={subHeaderStyle}>{translate('finish.subHeader')}</h4>
      <AppLinks />
      {isMfaEnabled ? (
        <MFARedirectCard isDependentAccessOrg={isDependentAccessOrg} />
      ) : (
        <InfoCard email={email} />
      )}
    </FinishPageWrapper>
  );
};

export default Finish;
