import { call, take, select, put } from 'redux-saga/effects';
import { postGingerSoleraConnect } from '../../../rest-calls';
import { ErrorResponse, parseError } from '../../../../../utils/parseError';
import {
  setGingerSoleraConnectionError,
  setGingerSoleraConnectionFinished,
  setGingerSoleraConnectionStarted,
} from '../../actions/soleraActions/gingerSoleraActions';
import { GingerSoleraActionTypes } from '../../actions/soleraActions/gingerSoleraActionTypes';
import { SoleraErrorTypes } from '../../../constants/errorTypes';
import { getLookupKey } from '../../selectors/lookupKeySelector/lookupKeySelector';
import { Routes } from '../../../constants/routes';
import { router } from '../../../../../root-component/router';

export function* verifyGingerSoleraConnectSaga() {
  yield take(GingerSoleraActionTypes.SET_GINGER_SOLERA_INIT);

  const lookupKey = yield select(getLookupKey);

  try {
    yield put(setGingerSoleraConnectionStarted());
    yield call(postGingerSoleraConnect, lookupKey);
    router.navigate(Routes.HEADSPACE_CARE_SOLERA_FINISH);
  } catch (error) {
    const soleraError = error as ErrorResponse;
    let errorStringCode: string | null;
    if (soleraError.response && !soleraError.response.data) {
      errorStringCode =
        SoleraErrorTypes.DOMAIN_USER_HAS_ACTIVE_PAID_SUBSCRIPTION;
    } else {
      const parsedError = parseError(soleraError);
      errorStringCode = parsedError.errorStringCode;
    }
    yield put(setGingerSoleraConnectionError(errorStringCode));
    yield router.navigate(Routes.HEADSPACE_CARE_SOLERA_ERROR);
  } finally {
    yield put(setGingerSoleraConnectionFinished());
  }
}
