import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import {
  rem,
  White,
  cssWithMq,
  WarmGrey,
  Green,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import HEADSPACE_CARE_LOGO from 'assets/headspace-care.svg';
import { Icon } from '../../../../shared-components/Icon';
import { HealthProvider } from '../../constants/healthProvider';
import { setHealthProvider } from '../../state/actions/memberEnrollActions/memberEnrollActions';
import {
  alignItemsCenter,
  alignItemsSpaceBetween,
  columnFlex,
  flex,
  justifyContentCenter,
  justifyContentSpaceBetween,
  rowFlex,
} from '../../../dashboard/styles/flex';
import {
  HEADSPACE_CARE_LOGO_HEIGHT,
  HEADSPACE_CARE_LOGO_WIDTH,
} from './utils/utils';
import { Footer } from '../../../../shared-components/Footer/Footer';

const pageWrapperStyle = css(
  columnFlex,
  alignItemsSpaceBetween,
  justifyContentSpaceBetween,
  {
    height: '100%',
  },
);

const headerCss = css(flex, alignItemsCenter, justifyContentCenter, {
  background: White['000'],
  height: rem(3.75),
});

function getLayoutFooterCss() {
  return css(flex, justifyContentCenter, {
    alignItems: 'flex-end',
    background: Green[400],
    height: '50%',
    textAlign: 'center',
  });
}

function getContainerStyle() {
  return cssWithMq(rowFlex, justifyContentCenter, {
    alignItems: ['flex-start', 'center', 'center'],
    background: WarmGrey[100],
    height: '50%',
    minHeight: [rem(11.25), 'auto', 'auto'],
    padding: [
      `${SpacingInRemBySize.L} ${SpacingInRemBySize.L} ${rem(3.75)} ${
        SpacingInRemBySize.L
      }`,
      0,
      0,
    ],
    position: 'relative',
  });
}

interface PageWrapperProps {
  children: React.ReactNode;
  healthProvider?: HealthProvider;
  showJoinMessage?: boolean;
  footerLayout?: React.ReactNode;
}

export const PageWrapper: FC<PageWrapperProps> = ({
  children,
  healthProvider,
  footerLayout,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHealthProvider(healthProvider));
  }, [dispatch, healthProvider]);

  return (
    <div css={pageWrapperStyle}>
      <header css={headerCss} data-testid="solera-header">
        <Icon
          width={HEADSPACE_CARE_LOGO_WIDTH}
          height={HEADSPACE_CARE_LOGO_HEIGHT}
          src={HEADSPACE_CARE_LOGO}
          dataTestid="solera-header-main-logo"
        />
      </header>
      <div css={getContainerStyle()}>{children}</div>
      <div css={getLayoutFooterCss()}>{footerLayout}</div>
      <Footer />
    </div>
  );
};
