export const OrganizationSettingsRoutes = {
  CHALLENGES: (orgId: number | string) => `/orgs/${orgId}/challenges`,
  INSIGHTS: (orgId: number | string) => `/orgs/${orgId}/insights`,
  MEMBERS: (orgId: number | string) => `/orgs/${orgId}/members`,
  SETTINGS: (orgId: number | string) => `/orgs/${orgId}/settings`,
  TOOLKIT: (orgId: number | string) => `/orgs/${orgId}/toolkit`,
};

export const qualitricsRoutes = {
  BASE_URL: 'https://sjc1.qualtrics.com',
  SURVEY: (surveyId: string) => `/jfe/form/${surveyId}`,
};
