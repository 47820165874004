export enum SoleraActionTypes {
  SET_SOLERA_CONNECTION_ERROR = 'SET_SOLERA_CONNECTION_ERROR',
  SET_SOLERA_CONNECTION_STARTED = 'SET_SOLERA_CONNECTION_STARTED',
  SET_SOLERA_CONNECTION_FINISHED = 'SET_SOLERA_CONNECTION_FINISHED',
}

export interface SetSoleraConnectionActionErrorPayload {
  error: {
    code: string | null;
  };
}

export interface SetSoleraConnectionErrorAction {
  type: SoleraActionTypes.SET_SOLERA_CONNECTION_ERROR;
  payload: SetSoleraConnectionActionErrorPayload;
}

export interface SetSoleraConnectionStartedAction {
  type: SoleraActionTypes.SET_SOLERA_CONNECTION_STARTED;
}

export interface SetSoleraConnectionFinishedAction {
  type: SoleraActionTypes.SET_SOLERA_CONNECTION_FINISHED;
}

export type SoleraActions =
  | SetSoleraConnectionErrorAction
  | SetSoleraConnectionStartedAction
  | SetSoleraConnectionFinishedAction;
