import { useMutation } from '@tanstack/react-query';
import { downloadSurvey } from '../../../../../rest/surveys/survey.api';

export const useDownloadSurveyResults = () =>
  useMutation(
    ['surveys'],
    ({
      orgId,
      surveyId,
      surveyName,
    }: {
      orgId: number;
      surveyId: string;
      surveyName: string;
    }) => downloadSurvey(orgId, surveyId, surveyName),
  );
