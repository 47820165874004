import { RootState } from '../types';
import { UserInfoInterface } from '../types/user';

export const getError = (state: RootState) => {
  return state.user.error;
};

export const getInfo = (
  state: RootState,
): UserInfoInterface | Record<string, never> => {
  return state.user.info;
};

export const isLoggingIn = (state: RootState): boolean => {
  return state.user.isLoggingIn;
};

export const isCSM = (state: RootState): boolean => {
  return state.user.isCSM;
};
