import * as React from 'react';
import { useSelector } from 'react-redux';
import { Input } from '../../../../../../../shared-components/Input';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

interface DependentEnrollmentFlowDisplayNameInputProps {
  editMode?: boolean;
  onChange: (event: ExtendedEvent) => void;
}

export const DependentEnrollmentFlowDisplayNameInput: React.FC<DependentEnrollmentFlowDisplayNameInputProps> = ({
  editMode = false,
  onChange,
}) => {
  const { dependentEnrollmentFlowDisplayName } = useSelector(getOrgManagement);
  const { translate } = useTranslate();

  const label = translate(
    'organizationSettings.enrollment.dependentEnrollmentFlowDisplayNameLabel',
  );
  const placeholder = translate(
    'organizationSettings.enrollment.dependentEnrollmentFlowDisplayNamePlaceholder',
  );

  return (
    <Input
      type={editMode ? 'text' : 'review'}
      name="dependentEnrollmentFlowDisplayName"
      placeholder={placeholder}
      label={label}
      value={dependentEnrollmentFlowDisplayName ?? ''}
      onChange={onChange}
    />
  );
};
