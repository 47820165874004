import { useGate } from 'statsig-react';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_csv_delimiter
// Feature flag to enable csv delimiter dropdown
export const KEY = 'b2b_dashboard_csv_delimiter';

export const useCsvDelimiter = (): readonly [
  value: boolean,
  isLoading: boolean,
] => {
  const { value, isLoading } = useGate(KEY);

  return [value, isLoading] as const;
};
