import { css } from '@emotion/react';
import {
  fontSizeL,
  fontSizeM,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../../constants/breakpoint';

export const choicesContainerStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '100%',
  margin: `0 ${SpacingInRemBySize.L}`,
  [WebUIComponentsBreakPoint.Mobile]: {
    justifyContent: 'center',
    margin: '0',
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    justifyContent: 'center',
    margin: '0',
    maxWidth: rem(34.375),
  },
});

export const choiceContainerStyle = (isSelected) =>
  css({
    position: 'relative',
    borderRadius: rem(0.75),
    border: 0,
    marginBottom: SpacingInRemBySize.M,
    padding: `${rem(1.25)} ${rem(0.625)} ${rem(0.625)}`,
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: isSelected ? WarmGrey[200] : WarmGrey[100],
    width: rem(12.75),
    height: rem(12.75),
    cursor: 'pointer',
    marginRight: SpacingInRemBySize.L,
    [WebUIComponentsBreakPoint.Mobile]: {
      width: rem(9.75),
      height: rem(9.75),
      marginRight: SpacingInRemBySize.XS,
      marginLeft: SpacingInRemBySize.XS,
      padding: `${rem(0.938)} ${rem(0.625)} ${rem(0.625)}`,
    },
    [WebUIComponentsBreakPoint.Tablet]: {
      width: rem(9.75),
      height: rem(9.75),
      marginRight: SpacingInRemBySize.XS,
      marginLeft: SpacingInRemBySize.XS,
      padding: `${rem(0.938)} ${rem(0.625)} ${rem(0.625)}`,
    },
  });

export const choiceIconContainerStyle = css({
  height: rem(4.75),
  marginBottom: rem(2.188),
  display: 'flex',
  alignItems: 'center',
  [WebUIComponentsBreakPoint.Mobile]: {
    height: rem(4.313),
    marginBottom: rem(0.938),
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    height: rem(4.313),
    marginBottom: rem(0.938),
  },
});

export const choiceIconStyle = css({
  objectFit: 'scale-down',
  maxWidth: '70%',
  maxHeight: '90%',
  margin: '0 auto',
});

export const checkMarkIconStyle = (isSelected) =>
  css({
    position: 'absolute',
    display: isSelected ? 'block' : 'none',
    top: rem(0.938),
    zIndex: 1,
    right: rem(0.938),
    width: SpacingInRemBySize.L,
    height: SpacingInRemBySize.L,
  });

export const choiceTextStyle = css({
  color: WarmGrey[800],
  fontSize: fontSizeL,
  fontWeight: 700,
  marginTop: SpacingInRemBySize.M,
  [WebUIComponentsBreakPoint.Mobile]: {
    fontSize: fontSizeM,
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    fontSize: fontSizeM,
  },
});

export const labelContainerStyle = css({
  position: 'relative',
  cursor: 'pointer',
  width: '100%',
});
