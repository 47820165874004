import axios, { AxiosResponse } from 'axios';
import { get as getCookie, set as setCookie } from 'js-cookie';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { getConfig } from '../config';
import { CookieKeys } from '../utils/constants';

const { COUNTRY_CODE, DEFAULT_COUNTRY_CODE } = CookieKeys;
const { LOCATION_API, DASHBOARD_ADMIN_AUTH0_COOKIE } = getConfig();
const GetCountryCodeKey = 'GetCountryCodeKey';

/**
 * This React hook will attempt to get the countryCode cookie. If the cookie
 * does not exist, then we will make an call to our location API endpoint.
 *
 * If the location API endpoint, for whatever reason, is unresponsive, we will
 * set the countryCode to 'US'.
 */
export const useGetCountryCode = (
  options?: UseQueryOptions<string | undefined, Error>,
): UseQueryResult<string | undefined, Error> => {
  const countryCodeCookie = getCountryCodeCookie();

  const setCountryCodeCookie = (code: string) => {
    setCookie(COUNTRY_CODE, code, {
      ...DASHBOARD_ADMIN_AUTH0_COOKIE.COOKIE_CONFIGURATION,
    });
  };

  const onSuccess = (result: string | undefined) => {
    if (result && !countryCodeCookie) {
      setCountryCodeCookie(result);
    }
  };

  const queryResult = useQuery<string | undefined, Error>(
    [GetCountryCodeKey],
    async () => {
      const response: AxiosResponse<string> = await axios.get(LOCATION_API);
      return response.data;
    },
    {
      cacheTime: Infinity,
      enabled: !countryCodeCookie,
      onSuccess,
      retry: false,
      ...options,
    },
  );

  if (queryResult.isError) {
    setCountryCodeCookie(DEFAULT_COUNTRY_CODE);
  }

  const countryCode =
    countryCodeCookie ?? queryResult.data ?? DEFAULT_COUNTRY_CODE;

  return {
    ...queryResult,
    data: countryCode.toUpperCase(),
  } as UseQueryResult<string | undefined, Error>;
};

function getCountryCodeCookie(): string | undefined {
  const countryCodeCookie = getCookie(COUNTRY_CODE);
  return countryCodeCookie === 'undefined' ? undefined : countryCodeCookie;
}
