import { Blue, White } from '@headspace/web-ui-components';
import VirginPulseLogo from 'assets/virgin-pulse-logo.png';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  accountLinkingSuccessScreenViewAction,
  buttonClickthroughAction,
} from '../../../../../analytics/events/enrollmentEvents';
import { HealthProvider } from '../../../constants/healthProvider';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import { getUnifiedEligibilityRoutes } from '../../../constants/routes';
import { getPreferredEnrollmentSlug } from '../../../state/selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';
import {
  getFirstName,
  getLastName,
} from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { logo } from '../../HealthHubViews/VirginPulse/LinkPage/styles/preLinkPageStyle';
import { FormContainer } from '../../shared-components/FormContainer/FormContainer';
import { PROFILE_SETTINGS } from '../constants';
import {
  outerContainerStyle,
  vpCareConfirmationFooter,
  vpCareConfirmationLinkFooter,
  vpCareLogoContainer,
} from '../styles';
import { AccountStatus } from './AccountStatus';

export const ConfirmationComponent = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const firstName = (useSelector(getFirstName) || '') as string;
  const lastName = (useSelector(getLastName) || '') as string;
  const accountHolderName = `${firstName} ${lastName}`;
  const navigate = useNavigate();
  const slug = useSelector(getPreferredEnrollmentSlug) as string;
  const { LOADING_PATH } = getUnifiedEligibilityRoutes(slug);

  const handleClick = useCallback(() => {
    dispatch(
      buttonClickthroughAction(translate('enrollment.vpCareComponent.button')),
    );
    return navigate(LOADING_PATH);
  }, [dispatch, translate, navigate, LOADING_PATH]);

  useEffect(() => {
    dispatch(accountLinkingSuccessScreenViewAction(HealthProvider.VPCARE));
  }, [dispatch]);

  return (
    <FormContainer
      headerTag="h4"
      title={translate('enrollment.vpCareConfirmation.title')}
      showTerms={false}
    >
      <div
        css={outerContainerStyle}
        data-testid="confirmation-component-container"
      >
        <p>{translate('enrollment.vpCareConfirmation.subtitle')}</p>
        <div
          css={vpCareLogoContainer}
          data-testid="confirmation-component-virgin-pulse-logo"
        >
          <img css={logo} src={VirginPulseLogo} alt="virgin-pulse-logo" />
        </div>
        <AccountStatus accountHolderName={accountHolderName} />
        <TextButton
          dataTestId="confirmation-component-start-now-button"
          textColor={White['000']}
          hoverColor={Blue[200]}
          backgroundColor={Blue[200]}
          pill={true}
          width="100%"
          lg={true}
          value={translate('enrollment.vpCareComponent.button')}
          onClick={handleClick}
          suppressLetterSpacing={true}
        />
        <p
          css={vpCareConfirmationFooter}
          data-testid="confirmation-component-footer"
        >
          {translate('enrollment.vpCareConfirmation.footer.body')}
          <a css={vpCareConfirmationLinkFooter} href={PROFILE_SETTINGS}>
            {translate('enrollment.vpCareConfirmation.footer.settings')}
          </a>
        </p>
      </div>
    </FormContainer>
  );
};
