import { ChangeEvent, FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  TranslateFn,
  useTranslate,
} from '../../../../../../../hooks/useTranslate';
import { Input } from '../../../../../../../shared-components/Input';
import {
  CountryCode,
  CountryCodes,
} from '../../../../../../../constants/country';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';

interface CountryOption {
  title: string;
  value: string;
}

const getOption = (
  translate: TranslateFn,
  value: CountryCode,
): CountryOption => ({
  title: translate(`country.${value}`),
  value,
});
const sortOption = (a: CountryOption, b: CountryOption) =>
  a.title < b.title ? -1 : 1;

interface OrgCountrySelectProps {
  editMode: boolean;
  onChange?: (event: ExtendedEvent) => void;
  disabled?: boolean;
}

export const OrgCountrySelect: FC<OrgCountrySelectProps> = ({
  editMode,
  onChange,
  disabled,
}) => {
  const { translate } = useTranslate();
  const { orgCountry, orgCountrySearch } = useSelector(getOrgManagement);
  const value = orgCountry ? translate(`country.${orgCountry}`) : '';
  const boundGetOption = getOption.bind(this, translate);

  const handleChangeSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange({ target: { id: 'orgCountrySearch', value: e.target.value } });
        if (e.target.value === '') {
          onChange({ target: { id: 'orgCountry', value: null } });
        }
      }
    },
    [onChange],
  );

  const options = [
    boundGetOption(CountryCode.US),
    ...CountryCodes.filter(
      (countryCode) =>
        countryCode !== CountryCode.US && countryCode !== CountryCode.UK,
    )
      .map(boundGetOption)
      .sort(sortOption),
  ].filter((option) =>
    option.title.toLowerCase().includes(orgCountrySearch.toLowerCase()),
  );
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);

        // We have to set the search input value to match the selected option to enable
        // clearing the value with the 'x' button. If the input is left empty, onChange
        // will never fire when 'x' is clicked after selecting an input.
        const option = options.filter(
          ({ value }) => value === e.target.value,
        )[0];
        if (option) {
          onChange({ target: { id: 'orgCountrySearch', value: option.title } });
        }
      }
    },
    [onChange, options],
  );

  return (
    <Input
      type={editMode ? 'select' : 'review'}
      dataTestId="orgCountry"
      name="orgCountry"
      label={translate('country')}
      value={value}
      onChange={handleChange}
      options={options}
      isSearchable={true}
      searchValue={orgCountrySearch}
      handleSearch={handleChangeSearch}
      noResultsFound={translate('noResultsFound')}
      disabled={disabled}
    />
  );
};
