import React from 'react';
import {
  FooterLinkListProps,
  FooterLinkProps,
  Footer as HeadspaceFooter,
  WarmGrey,
  cssWithMq,
  rem,
} from '@headspace/web-ui-components';
import { TranslateFn, useTranslate } from '../../hooks/useTranslate';
import { filterLegalLinksByLocale } from '../../utils/filterLegalLinksByLocale';

export const copyright = 'Headspace Inc.';
export interface LegalLinksItem extends FooterLinkProps {
  specificLocale?: string[];
}

export interface LegalLinks {
  items: LegalLinksItem[];
}

const getLegalLinks = (translate: TranslateFn): LegalLinks => ({
  items: [
    {
      cta: translate('legalLinks.termsAndConditions.cta'),
      id: 'terms-and-conditions',
      isNewWindow: true,
      overrideDynamicUrl: true,
      url: translate('legalLinks.termsAndConditions.url'),
    },
    {
      cta: translate('legalLinks.privacyPolicy.cta'),
      id: 'privacy-policy',
      isNewWindow: true,
      overrideDynamicUrl: true,
      url: translate('legalLinks.privacyPolicy.url'),
    },
    {
      cta: translate('legalLinks.cookiePolicy.cta'),
      id: 'cookie-policy',
      isNewWindow: true,
      overrideDynamicUrl: true,
      url: translate('legalLinks.cookiePolicy.url'),
    },
    {
      cta: translate('legalLinks.consumerHealthData.cta'),
      id: 'consumer-health-data',
      isNewWindow: true,
      overrideDynamicUrl: true,
      specificLocale: ['en'],
      url: translate('legalLinks.consumerHealthData.url'),
    },
    {
      cta: translate('legalLinks.privacyChoices.cta'),
      icon: {
        title: 'Privacy Choice Icon',
        url:
          'https://images.ctfassets.net/v3n26e09qg2r/7HWy4l0Kawd4lCCNr1VdMA/db1a655fbf86aafad316205303fbe712/privacyoptions.svg?h=300',
      },
      id: 'privacy-choices',
      isNewWindow: true,
      isOneTrust: true,
      overrideDynamicUrl: true,
      url: translate('legalLinks.privacyChoices.url'),
    },
    {
      cta: translate('legalLinks.ccpaNotice.cta'),
      id: 'ccpa-notice',
      isNewWindow: true,
      overrideDynamicUrl: true,
      url: translate('legalLinks.ccpaNotice.url'),
    },
  ],
});

const footerContainer = cssWithMq({
  '[data-testid=footer-bar-copyright]': {
    textAlign: ['left', 'center'],
  },
  '[data-testid=footer-bar-legal-links-container]': {
    alignItems: ['flex-start', 'center'],
    display: 'flex',
  },
  '[data-testid=footer-bar]': {
    alignItems: ['flex-start', 'center'],
    padding: [`${rem(2)} ${rem(3)}`, `${rem(1.5)} ${rem(2)}`],
  },
  backgroundColor: WarmGrey[600],
  display: 'flex',
  justifyContent: ['left', 'left', 'center'],
  width: '100%',
});

export const Footer: React.FC = () => {
  const { translate } = useTranslate();
  const legalLinks = getLegalLinks(translate);

  const specificLocaleLegalLinks = {
    items: filterLegalLinksByLocale(legalLinks),
  } as FooterLinkListProps;

  return (
    <div css={footerContainer}>
      <HeadspaceFooter
        copyright={copyright}
        legalLinks={specificLocaleLegalLinks}
      />
    </div>
  );
};
