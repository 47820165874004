import { Integrations as TracingIntegrations } from '@sentry/tracing';
import env from '../config/env';

export const SENTRY_CONFIG = {
  dsn: env.SENTRY_DSN,
  environment: env.NODE_ENV || process.env.NODE_ENV,
  integrations: [new TracingIntegrations.BrowserTracing()],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
    "'chrome' is undefined",
    "null is not an object (evaluating 'e.nodeName')",
    'We couldn’t find the {partnerIdName} you entered. Mind checking your entry and trying again?',
    'The last name you entered doesn’t match the {partnerIdName} you entered. Mind checking your entry and trying again?',
    'This {partnerIdName} already exists. Try logging into your existing account.',
    // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
    'Non-Error promise rejection captured',
  ],
  blacklistUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  sampleRate: 0.01,
  tracesSampleRate: 0.01,
};
