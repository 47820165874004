import { css } from '@emotion/react';
import {
  Red,
  SpacingInRemBySize,
  bodyXSCss,
} from '@headspace/web-ui-components';

export const panelStyle = css({
  padding: `${SpacingInRemBySize.XL} ${SpacingInRemBySize['2XL']}`,
  boxSizing: 'border-box',
});

export const firstColumnStyle = css({
  maxWidth: '70%',
  minWidth: '60%',
});

export const titleSectionStyle = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: SpacingInRemBySize['2XS'],
  '& h5': {
    marginBottom: SpacingInRemBySize.XS,
  },
});

export const buttonColumnStyle = css({
  alignItems: 'center',
  display: 'flex',
});

export const disabledReportMessageStyle = css([
  bodyXSCss,
  {
    color: Red[200],
  },
]);
