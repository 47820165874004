import { ThunkAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { PRIMARY_PRODUCT } from '../../../apps/dashboard/constants';
import { getConfig } from '../../../config';
import { getGingerOrganizationById } from '../../../rest';
import { resetError } from '../../../utils/messageDefaults';
import { translateError } from '../../../utils/translateError';
import {
  isValidGingerId,
  noOrganizationError,
  notGingerToFusionReadyError,
} from '../../../utils/validations';
import { getLocale } from '../../selectors/getLocale';
import { RootState } from '../../types';
import {
  ManageOrganizationState,
  OrganizationActionsTypes,
} from '../../types/organization';
import { updateImplicitState } from './updateImplicitState';
import { handleChange } from './handleChange';

export const orgInputsToSync = ['org_name', 'org_salesforce_id', 'orgCountry'];
export const orgInputsMap = {
  orgCountry: 'country',
  org_name: 'name',
  org_salesforce_id: 'salesforce_id',
};

export const handleGingerIdInput = (): ThunkAction<
  Promise<void>,
  RootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { gingerId } = state.manageOrganization;
    const locale = getLocale(state);
    const payload: Partial<ManageOrganizationState> = {
      gingerIdSynced: false,
    };

    // cleanup synced fields
    orgInputsToSync.forEach((input) => {
      payload[input] = '';
      payload[`${input}Error`] = resetError();
    });

    dispatch({ payload, type: OrganizationActionsTypes.HANDLE_CHANGE });

    if (!gingerId) return;

    // Validate GingerId within B2B DB orgs
    const validationError =
      !!gingerId && (await isValidGingerId(gingerId, undefined));

    if (validationError) {
      payload.gingerIdError = {
        error: true,
        message: translateError(validationError, locale, '', gingerId),
        validated: false,
      };

      dispatch({ payload, type: OrganizationActionsTypes.HANDLE_CHANGE });
      return;
    }

    // Check if enabled (staging vs integration)
    const data = getConfig().GINGER_WEB_API.enabled
      ? await getGingerOrganizationById(parseInt(gingerId, 10))
      : null;

    // Check if there's an existing organization with the given gingerId
    if (!data?.success) {
      payload.gingerIdError = {
        error: true,
        message: translateError(noOrganizationError, locale),
        validated: false,
      };

      dispatch({ payload, type: OrganizationActionsTypes.HANDLE_CHANGE });
      return;
    }

    if (
      !(
        data.current_primary_product === PRIMARY_PRODUCT.GINGER &&
        !data.future_primary_product
      )
    ) {
      payload.gingerIdError = {
        error: true,
        message: translateError(notGingerToFusionReadyError, locale),
        validated: false,
      };

      dispatch({ payload, type: OrganizationActionsTypes.HANDLE_CHANGE });
      return;
    }

    payload.gingerIdSynced = true;
    dispatch({ payload, type: OrganizationActionsTypes.HANDLE_CHANGE });

    orgInputsToSync.forEach((input) => {
      payload[input] = data[orgInputsMap[input]] ?? undefined;

      dispatch(handleChange({ target: { id: input, value: payload[input] } }));
    });

    dispatch(updateImplicitState({ target: { id: 'gingerId' } }, true));
  };
};
