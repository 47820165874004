import axios, { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { getConfig } from '../../../config';
import { createAuthorizationHeader } from '../../../utils/auth';

const { API_HOST } = getConfig();

export const useHealthHubs = () => {
  const { data, isLoading, isError, error } = useQuery(
    ['healthHubs'],
    async () => {
      const response: AxiosResponse = await axios.get(
        `${API_HOST}/b2b/orgs/health-hubs`,
        {
          headers: createAuthorizationHeader(),
        },
      );
      return response.data;
    },
  );

  return {
    data: [
      { healthHub: null, healthHubName: 'None' },
      ...(data?.healthHubs ?? []),
    ].map((hh) => ({
      title: hh.healthHubName,
      value: hh.healthHub,
    })),
    error,
    isError,
    isLoading,
  };
};
