import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Blue, White } from '@headspace/web-ui-components';
import { FormContainer } from '../shared-components/FormContainer/FormContainer';
import { TextButton } from '../../../../shared-components/buttons/TextButton';

import {
  outerContainerStyle,
  buttonContainer,
  headerText,
  mainMessageText,
  label,
  subLabel,
  priceContainer,
} from './styles';
import { useTranslate } from '../../../../hooks/useTranslate';
import { getSlug } from '../../state/selectors/orgSectionSelector/orgSectionSelector';
import {
  getLastCardDigits,
  getVoucherPrice,
  getVoucherSymbol,
} from '../../state/selectors/paymentSectionSelelector/paymentSectionSelector';

const PaymentProcessed: FC = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const slug = useSelector(getSlug);
  const price = useSelector(getVoucherPrice);
  const symbol = useSelector(getVoucherSymbol);
  const cardLastDigits = useSelector(getLastCardDigits);

  const handleVerify = () => {
    navigate(`/${slug}/member-enroll/finish`);
  };

  return (
    <FormContainer termsMessage={true}>
      <div css={outerContainerStyle}>
        <div>
          <h3 css={headerText} data-testid="processed-payment-title">
            {translate('processedPayment.header')}
          </h3>
          <p css={mainMessageText}>{translate('processedPayment.subHeader')}</p>

          <h3 css={headerText} data-testid="summary-text">
            {translate('processedPayment.summary')}
          </h3>
          <div css={priceContainer}>
            <p css={label} data-testid="price">
              {decodeURIComponent(symbol)}
              {price}
            </p>
            <p css={subLabel}>{translate('processedPayment.total')}</p>
          </div>

          <p css={label} data-testid="hidden-card-number">
            {translate('processedPayment.cardNumber')}
            {cardLastDigits.replace(/\*/gi, '')}
          </p>
          <p css={subLabel} data-testid="payment-method-text">
            {translate('processedPayment.paymentMethod')}
          </p>

          <div css={buttonContainer}>
            <TextButton
              dataTestId="processed-payment-next-button"
              textColor={White['000']}
              hoverColor={Blue[200]}
              backgroundColor={Blue[300]}
              value={translate('next')}
              pill={true}
              width="100%"
              onClick={handleVerify}
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default PaymentProcessed;
