import * as React from 'react';
import add from 'assets/plus.svg';
import { Orange, White } from '@headspace/web-ui-components';
import {
  TitleXS,
  UIRegularM,
} from '../../../../../../../shared-components/text';
import { IconButtonPronounced } from '../../../../../../../shared-components/buttons/IconButtonPronounced';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { buttonStyle, titleSectionStyle } from '../styles';

interface TitleSectionPropType {
  hasChallenges: boolean;
  createChallengeHandler: () => void;
}

export const ChallengesTitleSection: React.FC<TitleSectionPropType> = ({
  hasChallenges = false,
  createChallengeHandler,
}) => {
  const { translate } = useTranslate();

  return (
    <div css={titleSectionStyle}>
      <div>
        <TitleXS dataTestId="challenges-list-title">
          {translate('challenges.challengesList.challengesListTitle')}
        </TitleXS>
        <UIRegularM dataTestId="challenges-table-message">
          {hasChallenges
            ? translate('challenges.challengesList.challengesTableMessage')
            : translate('challenges.challengesList.challengesMessage')}
        </UIRegularM>
      </div>
      {!hasChallenges && (
        <div css={buttonStyle} data-testid="create-first-challenges-btn">
          <IconButtonPronounced
            src={add}
            onClick={createChallengeHandler}
            color={Orange[200]}
            backgroundColor={Orange[300]}
            fill={White['000']}
            label={translate('challenges.create.title')}
          />
        </div>
      )}
    </div>
  );
};
