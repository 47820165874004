import {
  OrganizationActionsTypes,
  NewAdminAction,
} from '../../types/organization';

const { NEW_ADMIN } = OrganizationActionsTypes;

export const newAdmin = (): NewAdminAction => {
  return {
    type: NEW_ADMIN,
    payload: {
      adminReviewView: false,
    },
  };
};
