import { put, select, call } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/core';
import { fetchVoucherInfo } from '../../../rest-calls';
import { PaymentActionTypes } from '../../../../../state/types/payment';
import { logSentryRequestError } from '../../../../../utils/sentryHelpers';
import { getVoucher } from '../../selectors/orgSectionSelector/orgSectionSelector';

const {
  GET_VOUCHER_INFO,
  GET_VOUCHER_INFO_SUCCESS,
  GET_VOUCHER_INFO_ERROR,
} = PaymentActionTypes;

export function* getVoucherInfoSaga(): SagaIterator {
  yield put({ type: GET_VOUCHER_INFO });

  const voucher = yield select(getVoucher);
  if (!voucher) {
    window.location.href = '/404';
  }

  try {
    const res = yield call(fetchVoucherInfo, voucher);
    yield put({ type: GET_VOUCHER_INFO_SUCCESS, payload: res });
  } catch (err: any) {
    yield put({ type: GET_VOUCHER_INFO_ERROR, payload: err.response });
    logSentryRequestError(err, 'getVoucherInfoSaga');
  }
}
