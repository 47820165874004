import * as React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import {
  fontSizeXS,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

import { useTranslate } from '../../hooks/useTranslate';
import { getParentOrgId } from '../../state/selectors/organizationSelectors';
import { Input, InputProps } from '../Input';

const setByStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeXS,
  lineHeight: rem(0.75),
  marginTop: SpacingInRemBySize.XS,
});

export interface ChildRestrictedInputProps extends InputProps {
  showAsTooltip?: boolean;
}

export const ChildRestrictedInput: React.FC<ChildRestrictedInputProps> = ({
  disabled,
  showAsTooltip = false,
  ...passedProps
}) => {
  const { translate } = useTranslate();
  const hasParentOrgId = !!useSelector(getParentOrgId);
  const shouldShowNote = hasParentOrgId && passedProps.type !== 'review';

  return (
    <div>
      <Input
        {...passedProps}
        disabled={disabled || hasParentOrgId}
        tooltip={
          showAsTooltip && shouldShowNote
            ? {
                message: translate(
                  'organizationSettings.orgHierarchy.setByParent',
                ),
              }
            : undefined
        }
      />
      {!showAsTooltip && shouldShowNote ? (
        <div data-testid="set-by-parent" css={setByStyle}>
          {translate('organizationSettings.orgHierarchy.setByParent')}
        </div>
      ) : null}
    </div>
  );
};
