export type UserAction =
  | UserLoginAction
  | UserLoginActionV2
  | UserAuthorizeAction
  | UserProcessAction
  | UserProcessActionV2
  | UserProcessIDPAction
  | UserLoginErrorAction
  | UserLoginErrorResetAction
  | UserLoginSuccessAction
  | UserResetPasswordAction
  | UserResetPasswordSuccessAction
  | UserResetPasswordErrorAction
  | UserFetchInfoAction
  | UserInfoAction
  | UserLogout
  | UserSetToken;

export enum UserActionsTypes {
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGIN_V2 = 'USER_LOGIN_V2',
  USER_AUTHORIZE = 'USER_AUTHORIZE',
  USER_PROCESS = 'USER_PROCESS',
  USER_PROCESS_V2 = 'USER_PROCESS_V2',
  USER_PROCESS_IDP = 'USER_PROCESS_IDP',
  USER_LOGIN_ERROR = 'USER_LOGIN_ERROR',
  USER_LOGIN_ERROR_RESET = 'USER_LOGIN_ERROR_RESET',
  USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
  USER_RESET_PASSWORD = 'USER_RESET_PASSWORD',
  USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR',
  USER_FETCH_INFO = 'USER_FETCH_INFO',
  USER_INFO = 'USER_INFO',
  USER_LOGOUT = 'USER_LOGOUT',
  AUTH_SET_TOKEN = 'AUTH_SET_TOKEN',
}

export interface UserState {
  authToken?: string;
  isLoggingIn: boolean;
  isCSM: boolean;
  error?: Error;
  info: UserInfoInterface | Record<string, never>;
  hasFetchedUser: boolean;
}

export interface HSAuthState {
  token: null;
  formSubmitted: boolean;
  error: null;
  clientId: null;
  parsedToken: string;
}
export interface UserInfoInterface {
  name: string;
  // CSM is a Customer Success Manager
  // CSM has elevated permissions to admins even though it should seem the opposite
  role: 'csm' | 'admin' | 'mock';
  orgIds?: number[] | null;
  title?: string | null;
  email?: string;
}

export interface UserLoginAction {
  type: UserActionsTypes.USER_LOGIN;
  email: string;
  password: string;
}

export interface UserLoginActionV2 {
  type: UserActionsTypes.USER_LOGIN_V2;
}

export interface UserAuthorizeAction {
  type: UserActionsTypes.USER_AUTHORIZE;
  connection: string;
}

export interface UserProcessAction {
  type: UserActionsTypes.USER_PROCESS;
  accessToken: string;
}

export interface UserProcessActionV2 {
  type: UserActionsTypes.USER_PROCESS_V2;
}

export interface UserProcessIDPAction {
  type: UserActionsTypes.USER_PROCESS_IDP;
  connection: string;
}

export interface UserLoginErrorAction {
  type: UserActionsTypes.USER_LOGIN_ERROR;
  error: Error;
}

export interface UserLoginErrorResetAction {
  type: UserActionsTypes.USER_LOGIN_ERROR_RESET;
}

export interface UserLoginSuccessAction {
  type: UserActionsTypes.USER_LOGIN_SUCCESS;
  authToken: string | null;
}

export interface UserResetPasswordAction {
  type: UserActionsTypes.USER_RESET_PASSWORD;
  email: string;
}

export interface UserResetPasswordSuccessAction {
  type: UserActionsTypes.USER_RESET_PASSWORD_SUCCESS;
}

export interface UserResetPasswordErrorAction {
  type: UserActionsTypes.USER_RESET_PASSWORD_SUCCESS;
  error: Error;
}

export interface UserFetchInfoAction {
  type: UserActionsTypes.USER_FETCH_INFO;
}

export interface UserInfoAction {
  type: UserActionsTypes.USER_INFO;
  // TODO make more specific
  resp: any;
}

export interface UserLogout {
  type: UserActionsTypes.USER_LOGOUT;
}

export interface UserSetToken {
  type: UserActionsTypes.AUTH_SET_TOKEN;
  payload: {
    token: string;
    parsedToken: Record<string, unknown>;
  };
}

export interface CreateCSMUser {
  name: string;
  email: string;
}
