import { css } from '@emotion/react';
import * as React from 'react';
import { SpacingInRemBySize, WarmGrey } from '@headspace/web-ui-components';
import { UIRegularM } from '../../text';

const tableRowStyle = css({
  ':last-of-type': {
    paddingBottom: 0,
  },
  alignItems: 'center',
  color: WarmGrey[700],
  display: 'flex',
  flexFlow: 'row',
  padding: `${SpacingInRemBySize.XS} ${SpacingInRemBySize.M}`,
});

interface BasicTableRowProps {
  children: React.ReactNode;
  className?: string;
}

export const BasicTableRow: React.FC<BasicTableRowProps> = ({
  children,
  className,
}) => (
  <UIRegularM
    dataTestId="basic-table-row"
    className={className}
    css={tableRowStyle}
  >
    {children}
  </UIRegularM>
);
