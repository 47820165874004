import { FC } from 'react';
import { ORG_CREATION_STEPS } from '../../constants';
import { reviewEditLabelStyle } from '../styles/createOrganizationStyles';
import { useTranslate } from '../../../../hooks/useTranslate';

interface EditButtonProps {
  id: ORG_CREATION_STEPS;
  onClick: (e) => void;
  onKeyPress: (e) => void;
}

export const EditButton: FC<EditButtonProps> = ({
  id,
  onClick,
  onKeyPress,
}) => {
  const { translate } = useTranslate();
  return (
    <div
      id={`${id}`}
      css={reviewEditLabelStyle}
      onClick={onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
    >
      {translate('edit')}
    </div>
  );
};
