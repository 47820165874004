import * as R from 'ramda';
import { RootState } from '../../../../../state/types';

export const getStatus = (state: RootState) =>
  R.pathOr(
    null,
    ['memberSubscriptionDetails', 'data', 'attributes', 'status'],
    state,
  );

export const getExternalSource = (state: RootState) =>
  R.pathOr(
    null,
    [
      'memberSubscriptionDetails',
      'data',
      'attributes',
      'subscriptionDetails',
      'platform',
    ],
    state,
  );

export const getIsV3Subscription = (state: RootState) =>
  R.pathOr(
    null,
    ['memberSubscriptionDetails', 'data', 'attributes', 'isV3Subscription'],
    state,
  );

export const getIsActiveB2CMember = (state: RootState): boolean => {
  const status = getStatus(state);
  const externalSource = getExternalSource(state);

  return externalSource === 'ZUORA' && status === 'ACTIVE';
};

export const getSubscriptionId = (state: RootState) =>
  R.pathOr(null, ['memberSubscriptionDetails', 'data', 'id'], state);
