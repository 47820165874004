import * as R from 'ramda';
import { RootState } from '../../../../../state/types';
import { SSOOrgs } from '../../actions/ssoEnrollmentActions/ssoEnrollmentActionsTypes';

export const getIsIdentityConnected = (state: RootState) =>
  R.path(['sso', 'isIdentityConnected'], state);

export const getHealthHubId = (state: RootState) =>
  R.path(['sso', 'healthHubId'], state);

export const getIsEnrolled = (state: RootState) =>
  R.path(['sso', 'enrolled'], state);

export const getSSOOrgs = (state: RootState): SSOOrgs | undefined =>
  R.path(['sso', 'orgs'], state);
