import * as React from 'react';
import { Blue, White } from '@headspace/web-ui-components';
import { FormContainer } from '../../../../shared-components/FormContainer/FormContainer';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { WEBSITE_HOST } from '../../../../../../../utils/constants';
import { outerContainerStyle, termsTextStyle, textAlign } from './styles';

interface ConnectVPComponentProps {
  closeConnectComponent: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConnectVPComponent: React.FC<ConnectVPComponentProps> = ({
  closeConnectComponent,
}) => {
  const { translate } = useTranslate();

  return (
    <div css={outerContainerStyle}>
      <FormContainer
        headerTag="h4"
        title={translate('ssoEnrollment.landingPage.header')}
        showTerms={false}
      >
        <div
          css={outerContainerStyle}
          data-testid="connect-vp-component-container"
        >
          <p>{translate('ssoEnrollment.landingPage.virginPulsesubHeader')}</p>
          <TextButton
            dataTestId="continue-button"
            textColor={White['000']}
            hoverColor={Blue[200]}
            backgroundColor={Blue[200]}
            pill={true}
            width="100%"
            lg={true}
            value={translate('ssoEnrollment.landingPage.continue')}
            onClick={() => closeConnectComponent(false)}
          />
        </div>
      </FormContainer>
      <div css={textAlign} data-testid="connect-vp-component-terms-section">
        <div css={termsTextStyle}>
          {translate('formContainer.formContainer.message1')}
          <a
            href={`${WEBSITE_HOST}/terms-and-conditions`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {` ${translate('formContainer.formContainer.link')} `}
          </a>
          {`${translate('formContainer.formContainer.message2')} `}
          <a
            href={`${WEBSITE_HOST}/privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('formContainer.formContainer.link2')}
          </a>
        </div>
      </div>
    </div>
  );
};
