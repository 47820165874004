import * as translation from './translation.json';
import * as memberEnrollmentV2 from './member-enrollment-v2.json';
import * as memberEnrollmentV3 from './member-enrollment-v3.json';
import * as headspaceHub from './headspace-hub.json';
import * as webAuth from './web-auth.json';

export const de = {
  translation,
  memberEnrollmentV2,
  memberEnrollmentV3,
  headspaceHub,
  webAuth,
};
