import { css } from '@emotion/react';
import { PrimaryButton, WarmGrey } from '@headspace/web-ui-components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUnifiedEligibilityRoutes } from '../../../constants/routes';
import { PageWrapper } from './PageWrapper';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { getSlug } from '../../../state/selectors/orgSectionSelector/orgSectionSelector';

const messageStyle = css({
  alignItems: 'center',
  color: WarmGrey[600],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto',
  textAlign: 'center',
});

export const ExpiredLinkPage = () => {
  const navigate = useNavigate();
  const slug = useSelector(getSlug);
  const { VERIFY_PATH } = getUnifiedEligibilityRoutes(slug);
  const { translate } = useTranslate();

  return (
    <PageWrapper>
      <div css={messageStyle}>
        <h1 data-testid="expired-link-header">
          {translate('enrollment.exceptionPage.expiredLink.headline')}
        </h1>
        <p data-testid="expired-link-sub-header">
          {translate('enrollment.exceptionPage.expiredLink.message')}
        </p>

        <PrimaryButton onClick={() => navigate(VERIFY_PATH)}>
          {translate('enrollment.exceptionPage.expiredLink.button')}
        </PrimaryButton>
      </div>
    </PageWrapper>
  );
};
