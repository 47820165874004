import { AnyAction } from 'redux';
import {
  SSOEnrollmentActionTypes,
  LinkedIdentitiesAction,
  EnrollmentStatusAction,
  EnrollmentSetSlugsAction,
} from '../../actions/ssoEnrollmentActions/ssoEnrollmentActionsTypes';

const INITIAL_STATE = {
  isIdentityConnected: null,
  enrolled: null,
  healthHubId: null,
  orgs: null,
};

export type SSOActionTypes =
  | LinkedIdentitiesAction
  | EnrollmentStatusAction
  | EnrollmentSetSlugsAction;

export const ssoReducer = (
  state = INITIAL_STATE,
  { type, payload }: AnyAction,
) => {
  if (type === SSOEnrollmentActionTypes.LINKED_VP_SSO_IDENTITY) {
    const { isIdentityConnected } = payload;
    return {
      ...state,
      isIdentityConnected,
    };
  }

  if (type === SSOEnrollmentActionTypes.CHECK_ENROLLMENT_STATUS) {
    const { enrolled, healthHubId } = payload;
    return {
      ...state,
      enrolled,
      healthHubId,
    };
  }

  if (type === SSOEnrollmentActionTypes.SET_SLUGS) {
    const { orgs } = payload;
    return {
      ...state,
      orgs,
    };
  }

  return state;
};

export type SSOReducerState = ReturnType<typeof ssoReducer>;
