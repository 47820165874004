import { MemberEnrollActionTypes } from '../../actions/memberEnrollActions/memberEnrollActionTypes';

const INITIAL_STATE = {
  data: null,
  error: null,
  isLoading: false,
};

export function memberSubscriptionDetailReducer(
  state = INITIAL_STATE,
  action: Action,
) {
  switch (action.type) {
    case MemberEnrollActionTypes.MEMBER_SUBSCRIPTION_DETAILS_IS_LOADING:
      return { ...state, isLoading: true };
    case MemberEnrollActionTypes.MEMBER_SUBSCRIPTION_DETAILS_SUCCESS:
      return { ...state, data: action.payload.data, isLoading: false };
    case MemberEnrollActionTypes.MEMBER_SUBSCRIPTION_DETAILS_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
}

export type MemberSubscriptionDetailState = ReturnType<
  typeof memberSubscriptionDetailReducer
>;
