import { css } from '@emotion/react';
import React from 'react';

import { TableFilterDropdownProps } from './FullTableWithScroll/types';
import { TableCheckbox } from './FullTableWithScroll/Checkbox';
import { ActionDropdown } from '../dropdowns/ActionDropdown';

/**
 * Creates a dropdown of checkboxes for the different input data.
 * @param filterProps: all available options and some metadata like display name
 * @param filterSelectedValues: array of currently selected options
 * @param setFilterSelectedValues: state hook to update array of selected options
 */
export const createFilterDropdown = ({
  filterProps,
  filterSelectedValues,
  setFilterSelectedValues,
  testId,
}: {
  filterProps: TableFilterDropdownProps;
  filterSelectedValues: string[];
  setFilterSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  testId?: string;
}): JSX.Element[] => {
  const { columnKey, coverText, options } = filterProps;

  const items = options.map(({ title, value }) => {
    const unselectIndex = filterSelectedValues.indexOf(value);
    return {
      action: () =>
        setFilterSelectedValues(
          unselectIndex >= 0
            ? filterSelectedValues
                .slice(0, unselectIndex)
                .concat(filterSelectedValues.slice(unselectIndex + 1))
            : filterSelectedValues.concat(value),
        ),
      display: (
        <span css={css({ display: 'inline-flex' })}>
          <TableCheckbox id={value} selected={unselectIndex >= 0} />
          &nbsp;
          {title ?? value}
        </span>
      ),
    };
  });

  const minContentWidth = options.reduce((acc, opt) => {
    const optLength = opt.title ? opt.title.length : opt.value.length;
    return acc > optLength ? acc : optLength;
  }, coverText.length);

  return [
    <ActionDropdown
      key={columnKey}
      position="left"
      text={coverText}
      minContentWidth={`${minContentWidth}em`}
      items={items}
      testId={testId}
    />,
  ];
};
