import { Button } from '@headspace/web-ui-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  ctaButtonCss,
  sectionBodyCss,
  sectionBoxCss,
  sectionDisclaimerCss,
  sectionTextCss,
} from '../styles';
import { checkIfMobileOrTablet } from '../../utils/deviceUtils';
import { Routes } from '../../../../constants/routes';
import { dashboardRoutes } from '../../../../../../root-component/Routes';
import { getEmail } from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

type NextStepsSectionProps = {
  translateKey: string;
  buttonType: ButtonType;
  buttonLink?: string;
  imgSrc: string;
};

export const NextStepsSection = ({
  translateKey,
  buttonType,
  buttonLink,
  imgSrc,
}: NextStepsSectionProps) => {
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const email = useSelector(getEmail);

  const title = translate(`${translateKey}.title`);
  const subTitle = translate(`${translateKey}.subTitle`);
  const body = translate(`${translateKey}.body`, { email });
  const disclaimer = translate(`${translateKey}.disclaimer`, {
    email,
  });
  const buttonText = translate(`${translateKey}.buttonText`);

  const handleClick = useCallback(() => {
    if (buttonLink) {
      navigate(buttonLink);
    } else {
      const isMobileOrTablet = checkIfMobileOrTablet();
      if (!isMobileOrTablet) {
        window.location.href = Routes.HEADSPACE_APP;
      } else {
        navigate(dashboardRoutes.REDIRECT_TO_APP_STORE);
      }
    }
  }, [buttonLink, navigate]);

  return (
    <section data-testid="next-step-section">
      <h3 css={sectionTextCss}>{title}</h3>
      <div css={sectionBodyCss}>
        <div>
          <img src={imgSrc} alt="section-icon" />
        </div>
        <div css={sectionBoxCss}>
          <h4 css={sectionTextCss}>{subTitle}</h4>
          <p css={sectionTextCss}>{body}</p>
          <p css={sectionDisclaimerCss}>{disclaimer}</p>
          <Button
            variant={buttonType}
            css={ctaButtonCss}
            onClick={handleClick}
            dataTestIdPrefix="next-step-section"
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </section>
  );
};
