import {
  Blue,
  CoolGrey,
  cssWithMq,
  fontSizeXL,
  rem,
  WarmGrey,
} from '@headspace/web-ui-components';

export const containerStyle = (isJoinMessageOpen: boolean) =>
  cssWithMq({
    '@media(min-width: 800px)': {
      marginTop: rem(1.875),
    },

    '@media(max-width: 800px)': {
      display: isJoinMessageOpen ? 'none' : 'flex',
    },

    ' > div': {
      width: [' 100vw', 'inherit', 'inherit'],
    },

    // Create an account / signup button
    '> div > div > div> div > form >  div > button': {
      background: Blue[200],
      ':hover': {
        background: Blue[300],
      },
    },

    // Third party button
    '> div > div > div> div > div > div > button': {
      background: CoolGrey[100],
      color: WarmGrey[800],
      ':hover': {
        background: CoolGrey[200],
        color: WarmGrey[800],
      },
      width: [rem(4), 'inherit', 'inherit'],
    },

    // Third party button container - Button padding from form wrapper
    '> div > div > div> div > div > div': {
      paddingBottom: rem(1.875),
      display: 'flex',
      justifyContent: 'center',
    },

    // removes built in 'terms and condition'
    '> div > div > div> div > .css-1dwgsq': {
      display: 'none',
    },

    // adjust title h3'
    '> div > div > div> div> div > h3': {
      lineHeight: 0,
      color: WarmGrey[500],
      fontSize: fontSizeXL,
    },

    '> div > div > div': {
      paddingTop: '0',
      paddingBottom: '0',
    },

    '> div .css-12pga5v': {
      boxShadow: 'none',
    },

    '> div > div > .css-9s1hn': {
      background: 'none',
    },

    '> div > div > div >.css-1cqgl9p': {
      background: 'none',
    },

    '@media(max-width: 360px)': {
      '> div > div > div> div > div .css-fgp26g': {
        width: rem(20),
      },
    },
  });
