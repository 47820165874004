import { css } from '@emotion/react';
import {
  borderRadiusXS,
  borderWidthXS,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  fontSizeM,
  fontSizeS,
  fontSizeXS,
  lineHeightBody,
  lineHeightHeading,
  Red,
  rem,
  SpacingInRemBySize,
  Green,
  Slate,
  Orange,
  WarmGrey,
  CoolGrey,
} from '@headspace/web-ui-components';

export const fileStyle = ({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) =>
  css({
    alignItems: 'center',
    borderRadius: borderRadiusXS,
    display: 'flex',
    flex: '1 0 auto',
    height: height || rem(11),
    justifyContent: 'center',
    width: width || '100%',
  });

export const filePreviewStyle = (url?: string) =>
  css({
    backgroundColor: WarmGrey[100],
    display: 'flex',
    ...(url
      ? {
          backgroundImage: `url(${url})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 80%',
        }
      : {}),
  });

export const fileFileStyle = css({
  display: 'none',
});

export const fileUploadButtonStyle = ({
  fontSize,
  letterSpacing,
  padding,
  border,
  backgroundColor,
  backgroundColorHover,
  disabled,
}: {
  fontSize?: string;
  letterSpacing?: string;
  padding?: string;
  border?: string;
  backgroundColor?: string;
  backgroundColorHover?: string;
  disabled?: boolean;
}) =>
  css({
    '&:hover': {
      backgroundColor: backgroundColorHover || WarmGrey[200],
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
    backgroundColor: backgroundColor || WarmGrey[100],
    border: border || `${borderWidthXS} dashed ${WarmGrey[200]}`,
    borderRadius: borderRadiusXS,
    cursor: disabled ? 'not-allowed' : 'pointer',
    fontSize: fontSize || fontSizeS,
    letterSpacing: letterSpacing || rem(0.156),
    outline: 'none',
    padding: padding || '0',
    opacity: disabled ? 0.5 : 1,
  });

export const errorWrapperStyle = css({
  border: `${borderWidthXS} solid ${Red[200]}`,
});

export const successWrapperStyle = css({
  border: `${borderWidthXS} solid ${Green[100]}`,
});

export const inputWrapperStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.bold,
  justifyContent: 'center',
});

export const fileUploadButtonTextStyle = css({
  '& strong': {
    color: Orange[200],
    fontWeight: 150,
  },
  color: CoolGrey[500],
  fontSize: fontSizeM,
  fontWeight: 150,
  lineHeight: lineHeightBody,
  textTransform: 'none',
});

export const fileUploadTextStyle = css({
  color: WarmGrey[500],
});

export const fileNotesStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeXS,
  fontWeight: 100,
  lineHeight: lineHeightHeading,
  textAlign: 'center',
});

export const baseMessageStyle = css({
  color: WarmGrey[600],
  fontSize: fontSizeXS,
  marginTop: SpacingInRemBySize.XS,
});

export const errorMessageStyle = css(baseMessageStyle, {
  color: Red[200],
});

export const successMessageStyle = css(baseMessageStyle, {
  color: CoolGrey[500],
});

export const labelStyle = css({
  color: WarmGrey[500],
  display: 'block',
  fontSize: fontSizeXS,
  letterSpacing: rem(0.156),
  lineHeight: lineHeightBody,
  margin: `${SpacingInRemBySize.L} 0 ${SpacingInRemBySize.XS}`,
  textTransform: 'uppercase',
});
