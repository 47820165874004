import { WebContract } from '../types';
import { getGTMSession } from '../utils';

export function webContractSaga(): WebContract {
  return {
    domain_userid: getGTMSession(),
    page_referrer: document.referrer,
    page_title: document.title,
    page_url: window.location.href,
  };
}
