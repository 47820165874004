import { css, SerializedStyles } from '@emotion/react';
import {
  borderRadiusS,
  borderWidthXS,
  CoolGrey,
  Red,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';

export const backgroundStyle: SerializedStyles = css({
  alignItems: 'center',
  background: `'rgba(${WarmGrey[600]} 0.53)'`,
  bottom: 0,
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100vw',
  zIndex: 10000,
});

export const hiddenStyle: SerializedStyles = css({
  display: 'none',
});

export const modalStyle: SerializedStyles = css({
  alignItems: 'center',
  background: White['000'],
  border: `${borderWidthXS} solid ${WarmGrey[200]}`,
  borderRadius: borderRadiusS,
  boxShadow: `0 ${SpacingInRemBySize['3XS']} 0 rgba(${WarmGrey[800]} 0.04)`,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  margin: rem(1.875),
  minHeight: rem(18.75),
  minWidth: rem(43.75),
  padding: rem(1.25),
});

export const titleStyle: SerializedStyles = css({
  color: CoolGrey[500],
  paddingBottom: rem(1.25),
  paddingTop: SpacingInRemBySize['2XL'],
});

export const messageStyle: SerializedStyles = css({
  color: WarmGrey[600],
  marginLeft: '15%',
  marginRight: '15%',
  paddingBottom: rem(3.125),
  textAlign: 'center',
});

export const buttonStyle: SerializedStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

export const actionButtonsSectionStyle: SerializedStyles = css({
  display: 'flex',
  justifyContent: 'space-around',
  paddingBottom: SpacingInRemBySize['2XL'],
  width: '50%',
});

export const confirmButtonStyle: SerializedStyles = css({
  ':hover': {
    cursor: 'pointer',
  },
  width: '48%',
});

export const cancelButtonStyle: SerializedStyles = css({
  ':hover': {
    background: Red[400],
    cursor: 'pointer',
  },
  background: WarmGrey[100],
  color: WarmGrey[200],
  width: '48%',
});
