import { Modal, rem, Spinner } from '@headspace/web-ui-components';
import * as React from 'react';
import { css } from '@emotion/react';
import { alignItemsCenter, flex } from '../../../../../styles/flex';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

interface RestrictByMigrationBlockingModalProps {
  isOpen?: boolean;
}

interface RestrictByMigrationBlockingModalBodyProps {
  text: string;
}

const modalBodyStyle = css(flex, alignItemsCenter);

const bodyMessageStyle = css({
  paddingRight: rem(1.25),
});

const ModalBody: React.FC<RestrictByMigrationBlockingModalBodyProps> = ({
  text,
}) => (
  <div
    css={modalBodyStyle}
    data-testid="restrict-by-migration-blocking-modal-body"
  >
    <span css={bodyMessageStyle}>{text}</span>
    <Spinner />
  </div>
);

export const RestrictByMigrationBlockingModal: React.FC<RestrictByMigrationBlockingModalProps> = ({
  isOpen,
}) => {
  const { translate } = useTranslate();

  const modalMessage = translate(
    'restrictByMigrationSteps.blockingModal.message',
  );

  return isOpen ? (
    <Modal body={<ModalBody text={modalMessage} />} showCloseButton={false} />
  ) : null;
};
