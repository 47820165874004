import * as R from 'ramda';
import { Dispatch } from 'redux';
import { ThunkMiddleware } from 'redux-thunk';
import {
  OrganizationActionsTypes,
  HandleLogoFileInputAction,
} from '../../types/organization';
import { resetError } from '../../../utils/messageDefaults';
import { processErrors } from './processErrors';

const { HANDLE_LOGO_FILE } = OrganizationActionsTypes;

export const handleLogoFileInput = (
  files: FileList,
): ThunkMiddleware & HandleLogoFileInputAction => {
  // @ts-ignore
  return (dispatch: Dispatch) => {
    const logoFile = files[0];
    const logoFileName = R.pathOr('logo name', ['0', 'name'], files);
    const logoFilePreview = window.URL.createObjectURL(logoFile);
    dispatch({
      type: HANDLE_LOGO_FILE,
      payload: {
        logoFileName,
        logoFilePreview,
        logoFile,
        logoFileError: resetError(),
      },
    });
    dispatch(
      processErrors({ target: { logoFile, id: 'logoFile', type: 'file' } }),
    );
  };
};
