import { useState, FC, ChangeEvent, FocusEvent } from 'react';
import { pathOr } from 'ramda';
import { useDispatch } from 'react-redux';
import { FormContainer } from '../../../shared-components/FormContainer/FormContainer';
import {
  buttonClickthroughAction,
  EnrollmentEventNames,
} from '../../../../../../analytics/events/enrollmentEvents';
import { ContractActivityStatus } from '../../../../../../analytics/types';
import { EMAIL_VERIFICATION_STEPS } from '../../constants';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { EligibilityVerifyByEmailInputStep } from './EligibilityVerifyByEmailInputStep';
import { EligibilityVerifyByEmailResendStep } from './EligibilityVerifyByEmailResendStep';
import { emailCheck } from '../../../../../../utils/validations';
import { Terms } from '../../VerificationPage/components/Terms';
import { FormHeader } from '../../VerificationPage/components/FormHeader';

const { VERIFY_EMAIL, RESEND_EMAIL } = EMAIL_VERIFICATION_STEPS;

interface EligibilityVerifyByEmailProps {
  handleVerify(email: string): any;
  step: number;
  setVerifyStep(step: number): void;
}

const initialState = {
  email: '',
};

const EligibilityVerifyByEmail: FC<EligibilityVerifyByEmailProps> = ({
  handleVerify,
  step,
  setVerifyStep,
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const [emailState, setEmailState] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { email } = emailState;

  const handleVerification = async (analytics: {
    errorEventName:
      | EnrollmentEventNames.verificationErrorView
      | EnrollmentEventNames.resendVerificationErrorView;
    buttonText: string;
  }) => {
    const { errorEventName, buttonText } = analytics;

    try {
      await handleVerify(email);
      setVerifyStep(RESEND_EMAIL);
    } catch (e) {
      const errorMessage = pathOr(
        e,
        ['response', 'data', 'errors', '0', 'data'],
        e,
      );
      dispatch(
        buttonClickthroughAction(
          buttonText,
          errorEventName,
          ContractActivityStatus.failed,
        ),
      );
      setErrorMessage(errorMessage);
    }
  };

  const handleInitialVerification = async (event) => {
    const buttonText = event.target.innerText;
    dispatch(buttonClickthroughAction(buttonText));

    if (email === '') {
      dispatch(
        buttonClickthroughAction(
          buttonText,
          EnrollmentEventNames.verificationErrorView,
          ContractActivityStatus.failed,
        ),
      );
      setErrorMessage(translate('errors.EMPTY_EMAIL'));
      return;
    }

    await handleVerification({
      buttonText,
      errorEventName: EnrollmentEventNames.verificationErrorView,
    });
  };

  const handleResendVerification = async (event) => {
    const buttonText = event.target.innerText;
    dispatch(buttonClickthroughAction(buttonText));

    await handleVerification({
      buttonText,
      errorEventName: EnrollmentEventNames.resendVerificationErrorView,
    });
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { id, value },
    } = e;
    setEmailState({ ...emailState, [id]: value.trim() });
    setErrorMessage(null);
  };

  const handleEmailBlur = (e: FocusEvent<HTMLInputElement>) => {
    const emailValue = e.target.value || '';

    if (!emailCheck(emailValue.trim())) {
      setErrorMessage(translate('errors.INVALID_EMAIL'));
    }
  };

  return (
    <div>
      <FormContainer
        headerComponentOverride={
          <FormHeader
            title={
              step === VERIFY_EMAIL
                ? translate('enrollment.verify.email.headline')
                : translate('enrollment.emailSent.headline')
            }
          />
        }
        termsComponentOverride={<Terms />}
      >
        {step === VERIFY_EMAIL && (
          <EligibilityVerifyByEmailInputStep
            email={email}
            errorMessage={errorMessage}
            handleChange={handleEmailChange}
            handleBlur={handleEmailBlur}
            handleVerification={handleInitialVerification}
          />
        )}
        {step === RESEND_EMAIL && (
          <EligibilityVerifyByEmailResendStep
            email={email}
            errorMessage={errorMessage}
            handleResendVerification={handleResendVerification}
          />
        )}
      </FormContainer>
    </div>
  );
};

export default EligibilityVerifyByEmail;
