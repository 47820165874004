import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '../../../../shared-components/container/Container';
import { Survey } from './components/Survey/Survey';
import { handleEngagementReportDownload } from '../../../../state/actions/organizationActions';
import { isCSM as getIsCSM } from '../../../../state/selectors/userSelectors';
import { DownloadReportView } from '../../../../shared-components/ReportButton/ReportButton';

export const ComposedInsightsView: React.FC = () => {
  const dispatch = useDispatch();
  const isCSM = useSelector(getIsCSM);

  const handleReportDownload = React.useCallback(() => {
    dispatch(handleEngagementReportDownload());
  }, [dispatch]);

  return (
    <Container>
      <DownloadReportView
        handleDownload={handleReportDownload}
        messages={{
          label: 'organizationSettings.engagementReport.downloadReport',
          message: 'organizationSettings.engagementReport.message',
          title: 'organizationSettings.engagementReport.title',
        }}
      />
      {isCSM ? <Survey /> : null}
    </Container>
  );
};
