import { SagaIterator } from 'redux-saga';
import { takeEvery, call } from 'redux-saga/effects';
import { AnalyticAction, analyticEventActionsTypes } from './analyticActions';
import { contractDataAggregatorSaga } from './contractSagas/contractorAggregatorSaga';
import { pushEventToMParticle } from './mParticle';
import { ContractTypes } from './types';

export function* processMParticleEventSaga(
  action: AnalyticAction,
): SagaIterator {
  const {
    eventName,
    eventType,
    extras,
    contractList,
    callback,
  } = action.payload;
  const aggregatedContractData = yield call(contractDataAggregatorSaga, {
    contractList,
    extras,
  });
  const isScreenViewEvent =
    contractList.indexOf(ContractTypes.screenView) !== -1;

  yield call(pushEventToMParticle, {
    attributes: aggregatedContractData,
    eventName,
    eventType,
    isScreenViewEvent,
  });

  if (typeof callback === 'function') {
    yield call(callback);
  }
}

export function* mParticleWatcherSaga(): SagaIterator {
  yield takeEvery(
    analyticEventActionsTypes.MPARTICLE_EVENT,
    processMParticleEventSaga,
  );
}
