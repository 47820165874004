import { css } from '@emotion/react';
import {
  SpacingInRemBySize,
  rem,
  titleSCss,
} from '@headspace/web-ui-components';

export const dobInputContainerStyle = css({
  margin: `${SpacingInRemBySize.L} 0 ${SpacingInRemBySize.M}`,
});

export const countryInputContainerStyle = css({
  marginBottom: `${SpacingInRemBySize.XL}`,
});

export const modalBodyStyle = css(titleSCss, {
  lineHeight: '120%',
  marginTop: `${SpacingInRemBySize['4XL']}`,
  maxWidth: rem(25.75),
  textAlign: 'center',
});

export const modalFooterStyle = css({
  button: {
    maxWidth: '100%',
  },
  'button:first-of-type': {
    marginBottom: `${SpacingInRemBySize.S}`,
  },
  display: 'flex',
  flexDirection: 'column',
});

export const modalContainerStyle = css({
  '[data-testid="modal-wrapper"]': {
    marginLeft: rem(1),
    marginRight: rem(1),
    borderRadius: rem(1),
  },
  '[data-testid="modal-close-button"]': {
    borderColor: 'transparent',
  },
});
