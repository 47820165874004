import 'react-virtualized/styles.css';
import { FC } from 'react';

import { TableExportDropdown } from './ExportDropdown';
import { TableSection } from './TableSection';
import { TableToast } from './Toast';
import { actionsStyle, tableStyle, noResultsFoundStyle } from './styles';
import { createIdArray } from './utils';
import { FullTableWithScrollProps } from './types';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { SpinnerWithBackGround } from '../../Spinner';
import { TableFilterBar } from './FilterBar';
import { generateExportCsvActions } from './generateExportCsvActions';
import { useTranslate } from '../../../hooks/useTranslate';

export const FullTableWithScroll: FC<FullTableWithScrollProps> = ({
  headerRow,
  rows,
  actionButton,
  showToastAction,
  toastAction,
  datasetActions,
  columnFilters,
  heightOffset = 0, // Generally the combined height of header elements
  loadMoreRows,
  isFetching,
  sort,
  search,
  status,
  selectRows,
  totalMembers,
  filteredMembers,
}) => {
  const { translate } = useTranslate();
  const { windowInnerHeight } = useWindowResize();
  const [selectedRowIds, setSelectedRowIds] = selectRows;

  const filteredRowIds = createIdArray(rows);
  const columnWidthOffset =
    85 /* Table Styling Buffer */ +
    88 /* ActionDropdown Buffer */ +
    (datasetActions ? 64 : 0); /* Selection Buffer */
  const tableProps = {
    rows,
    sort,
    selectedRowIds,
    datasetActions,
    setSelectedRowIds,
    headerRow,
    columnWidthOffset,
    loadMoreRows,
    isFetching,
  };

  const hasRows = rows.length;
  const [searchValue, setSearch] = search;
  return (
    <>
      <div
        className="table"
        css={tableStyle({ height: windowInnerHeight - heightOffset })}
      >
        <div css={actionsStyle}>
          <TableFilterBar
            placeholder={translate('members.searchMembers')}
            handleSearch={setSearch}
            searchValue={searchValue}
            dataTestId="organizations-filter-input-bar"
          />
          {columnFilters}
          {hasRows
            ? datasetActions && (
                <TableExportDropdown
                  items={generateExportCsvActions({
                    datasetActions,
                    filteredMembers,
                    filteredRowIds,
                    searchValue,
                    selectedRowIds,
                    sortBy: sort[0],
                    status,
                    totalMembers,
                  })}
                  text={datasetActions.actionText}
                />
              )
            : null}
          {actionButton}
        </div>
        {isFetching && <SpinnerWithBackGround height="70px" width="70px" />}
        {hasRows ? (
          <TableSection {...tableProps} />
        ) : (
          <div css={noResultsFoundStyle}>{translate('noResultsFound')}</div>
        )}
      </div>

      {!!selectedRowIds.length && (
        <TableToast
          selectedRowIds={selectedRowIds}
          onUnselect={() => setSelectedRowIds([])}
          showToastAction={showToastAction}
          toastAction={() => toastAction && toastAction.action(selectedRowIds)}
          toastActionText={toastAction ? toastAction.text : ''}
        />
      )}
    </>
  );
};
