import { columnStyle, rowMarginStyle } from './styles';
import { DependentsFunc } from './types';

const renderSubRowColumn = (columnWidth, element) => (
  <div
    className="ReactVirtualized__Table__rowColumn"
    css={columnStyle(columnWidth)}
  >
    {element}
  </div>
);

export const Dependents: DependentsFunc = ({
  columnWidth,
  dependents,
  showWhen,
}) => {
  if (!showWhen || !dependents) return null;

  return (
    <>
      {dependents.map(({ key, name, status, activeSince }) => (
        <div key={key} className="subRow" data-testid="dependent-subrow">
          <span className="leftRowMargin" css={rowMarginStyle} />
          {renderSubRowColumn(columnWidth, name)}
          {renderSubRowColumn(columnWidth, status)}
          {renderSubRowColumn(columnWidth, activeSince)}
          <span className="rightRowMargin" css={rowMarginStyle} />
        </div>
      ))}
    </>
  );
};
