import { css } from '@emotion/react';
import {
  borderWidthXS,
  cssWithMq,
  fontSize2XS,
  fontSize3XL,
  fontSizeXL,
  lineHeightBody,
  lineHeightHeading,
  Orange,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';

export const boxTitle = cssWithMq({
  color: WarmGrey[800],
  fontSize: fontSizeXL,
  fontWeight: 'bolder',
  lineHeight: lineHeightBody,
  margin: 'auto',
  textAlign: 'center',
  width: [rem(20), undefined, undefined],
});

export const outerContainerStyle = cssWithMq({
  alignItems: ['center', undefined, undefined],
  display: ['flex', undefined, undefined],
  flexDirection: ['column', undefined, undefined],
  justifyContent: ['center', undefined, undefined],
  marginBottom: [rem(1.875), undefined, undefined],
});

export const containerStyle = cssWithMq({
  background: White['000'],
  border: [
    `${White['000']} ${borderWidthXS} solid`,
    `${WarmGrey[200]} ${borderWidthXS} solid`,
    `${WarmGrey[200]} ${borderWidthXS} solid`,
  ],
  borderRadius: '24px',
  margin: [0, `${rem(2.625)} ${rem(2.625)} 0`, `${rem(2.625)} ${rem(2.625)} 0`],
  padding: [0, undefined, undefined],
  width: ['100%', rem(28.875), rem(28.875)],
});

export const textAlign = css({
  display: 'flex',
  justifyContent: 'center',
});

export const termsTextStyle = cssWithMq({
  '& a': {
    color: Orange[100],
    textDecoration: 'none',
  },
  alignItems: 'center',
  color: WarmGrey[500],
  display: 'flex',
  flexDirection: 'column',
  fontSize: fontSize2XS,
  gap: [rem(1.125), 0],
  marginTop: SpacingInRemBySize.S,
  paddingBottom: rem(2.25),
  textAlign: 'center',
  width: [rem(18.25), rem(15.625)],
});

export const headerStyle = (
  isTestVariant: boolean,
  headerStyleOverrides: Record<string, any>,
) =>
  css(
    isTestVariant
      ? {
          fontSize: fontSize3XL,
          lineHeight: lineHeightHeading,
          margin: `${SpacingInRemBySize['2XL']} ${rem(2.25)} ${
            SpacingInRemBySize.M
          }`,
          ...headerStyleOverrides,
        }
      : { fontSize: fontSizeXL, ...headerStyleOverrides },
  );
