import { select } from 'redux-saga/effects';
import { getSlug } from '../../selectors/orgSectionSelector/orgSectionSelector';
import { router } from '../../../../../root-component/router';

export function* verifiedAccountRedirectSaga(emailVerificationToken) {
  const slug = yield select(getSlug);
  yield router.navigate(
    `/${slug}/member-enroll/verified-account?verification=${emailVerificationToken}`,
  );
}
