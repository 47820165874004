import { setCookie } from '@headspace/web-auth/dist/utils/cookie';
import { MemberEnrollActionTypes } from '../memberEnrollActions/memberEnrollActionTypes';
import { CookieKeys } from '../../../constants';
import { getConfig } from '../../../../../config';

export const setCountryCode = (countryCode: string) => {
  setCookie(
    CookieKeys.COUNTRY_CODE,
    countryCode,
    null,
    getConfig().ENROLLMENT_AUTH0_COOKIE.COOKIE_CONFIGURATION,
  );

  return {
    payload: { countryCode },
    type: MemberEnrollActionTypes.SET_COUNTRY_CODE,
  };
};
