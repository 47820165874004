import { TextInput } from '@headspace/web-ui-components';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { isValidDob } from '../../../../../../utils/validations';

const regex = /^[\d\/]*$/;

interface DobInputProps {
  onChange: ({ value, isValid }: { value: string; isValid: boolean }) => void;
  overrideErrorMessage?: string;
}

export const DobInput: FC<DobInputProps> = ({
  onChange,
  overrideErrorMessage,
}) => {
  const { translate } = useTranslate();
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const isAddition = inputValue.length > value.length;

      // We only want numbers and /
      if (!regex.test(inputValue)) return;

      if (isAddition && (value.length === 1 || value.length === 4)) {
        // append /
        setValue(`${inputValue}/`);
      } else if (!isAddition && (value.length === 3 || value.length === 6)) {
        // remove /
        setValue(value.substring(0, value.length - 2));
      } else {
        setValue(inputValue);
      }
      setIsValid(true);
    },
    [value],
  );
  const handleBlur = useCallback(() => {
    const isValid = isValidDob(value);
    setIsValid(isValid);
    onChange({ isValid, value });
  }, [value, onChange]);

  const defaultErrorMessage =
    !isValid && value.length > 0
      ? translate('enrollment.verifyIdentity.dob.invalidDob')
      : null;
  const errorMessage = overrideErrorMessage ?? defaultErrorMessage;

  return (
    <TextInput
      dataTestIdPrefix="dob"
      inputMode="text"
      name="dob"
      onChange={handleChange}
      onBlur={handleBlur}
      label={translate('enrollment.verifyIdentity.dob.label')}
      required={true}
      value={value}
      infoMessage="MM/DD/YYYY"
      maxLength={10}
      errorMessage={errorMessage}
    />
  );
};
