import * as React from 'react';
import { css } from '@emotion/react';
import { CoolGrey, titleMCss } from '@headspace/web-ui-components';
import { boxSizingBorderBox } from '../styles';
import { alignItemsCenter, flex, justifyContentCenter } from '../styles/flex';
import { useTranslate } from '../../../hooks/useTranslate';

export interface PageNotFoundProps {
  children?: React.ReactNode;
}

const containerStyle = css(
  boxSizingBorderBox,
  flex,
  alignItemsCenter,
  justifyContentCenter,
  {
    color: CoolGrey[500],
    height: '100%',
    padding: '5%',
    textAlign: 'center',
    width: '100vw',
  },
);

const PageNotFound: React.FC<PageNotFoundProps> = () => {
  const { translate } = useTranslate();

  return (
    <div css={containerStyle}>
      <div>
        <h1 css={titleMCss}>{translate('pageNotFound.title')}</h1>
        <p>{translate('pageNotFound.message')}</p>
      </div>
    </div>
  );
};

export default PageNotFound;
