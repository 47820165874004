import React from 'react';
import { Orange, WarmGrey, rgba } from '@headspace/web-ui-components';

import { Dropdown, DropdownParams } from './Dropdown';
import {
  dropdownLinkStyle,
  dropdownItemStyle,
  disablePointerEventsStyle,
} from './styles';
import { uniqueKey } from '../../utils/keyCounter';

export interface ActionDropdownAction {
  action: () => void;
  display: JSX.Element | string;
  isDisabled?: boolean;
  selectedMembers?: number;
}

export interface ActionDropdownProps extends DropdownParams {
  items: ActionDropdownAction[];
  minContentWidth?: number | string;
}

export const ActionDropdown: React.FC<ActionDropdownProps> = ({
  items,
  minContentWidth,
  ...rest
}) => {
  const children = items.map((item) => {
    const i = uniqueKey();
    const onKeyPress = (e) => {
      if (e.key === 'Enter' && !item.isDisabled) {
        item.action();
      }
    };
    return (
      <div
        data-testid="dropdownItem"
        css={
          item.selectedMembers === 0 || item.isDisabled
            ? disablePointerEventsStyle
            : dropdownItemStyle
        }
        key={i}
        onClick={() => {
          if (!item.isDisabled) {
            item.action();
          }
        }}
        role="button"
        tabIndex={0}
        onKeyPress={onKeyPress}
      >
        <div css={dropdownLinkStyle}>{item.display}</div>
      </div>
    );
  });
  return (
    <Dropdown
      ctaColor={Orange[200]}
      ctaHoverBackgroundColor={rgba(WarmGrey[300], 0.1)}
      letterSpacing={true}
      uppercase={true}
      minContentWidth={minContentWidth}
      {...rest}
    >
      {children}
    </Dropdown>
  );
};
