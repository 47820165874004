import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../types';

const SETTINGS_MODAL_REDUCER_NAME = 'settingsModal';

interface SettingsModalState {
  isOpen: boolean;
  message: string | null;
  title: string | null;
}

interface OpenModalPayload {
  message: string | null;
  title: string | null;
}

const initialState: SettingsModalState = {
  isOpen: false,
  message: '',
  title: '',
};

export const settingsModalSlice = createSlice({
  initialState,
  name: SETTINGS_MODAL_REDUCER_NAME,
  reducers: {
    closeSettingsModal: () => {
      return { ...initialState };
    },
    openSettingsModal: (
      state,
      { payload: { message, title } }: PayloadAction<OpenModalPayload>,
    ) => {
      state.isOpen = true;
      state.message = message;
      state.title = title;
    },
  },
});

export const {
  closeSettingsModal,
  openSettingsModal,
} = settingsModalSlice.actions;

export const selectSettingsModal = (state: RootState): SettingsModalState =>
  state.settingsModal;
