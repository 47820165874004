import {
  fontSizeL,
  fontSizeXL,
  lineHeightBody,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { PanelTile } from '../../../../../../shared-components/tile/PanelTile';
import { setSFTPFinished } from '../../../../../../state/actions/organizationActions/setSFTPFinished';
import { visuallyHidden } from '../../../../styles';
import { justifyContentSpaceBetween, rowFlex } from '../../../../styles/flex';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { getPgpKey } from '../../../../../../rest/sftp-user/sftp-user.api';

export const TROUBLESHOOTING_URL =
  'https://headspace.highspot.com/items/5f3d6bbe628ba249d3770091?lfrm=srp.1';

const titleStyles = css({
  color: WarmGrey[800],
  fontSize: fontSizeXL,
  lineHeight: lineHeightBody,
  margin: `${SpacingInRemBySize['2XL']} 0 ${rem(0.625)}`,
});

const subtitleStyles = css({
  color: WarmGrey[600],
  fontSize: fontSizeL,
  lineHeight: lineHeightBody,
});

const tileWrapper = css(rowFlex, justifyContentSpaceBetween, {
  '> div': {
    marginRight: rem(2.188),
  },
  '> div:last-child': {
    marginRight: '0',
  },
  marginTop: SpacingInRemBySize.L,
});

export const SFTPFinished: React.FC = () => {
  const fileRef = React.useRef<HTMLAnchorElement>(null);
  const { translate } = useTranslate();

  const dispatch = useDispatch();
  const handleClickHelpCenter = React.useCallback(() => {
    window.location.href = TROUBLESHOOTING_URL;
  }, []);
  const handleUpload = React.useCallback(() => {
    dispatch(setSFTPFinished(false));
  }, [dispatch, setSFTPFinished]);
  const handleDownloadPgpKey = React.useCallback(async () => {
    const response = await getPgpKey();

    if (response.status === 200 && fileRef && fileRef.current) {
      fileRef.current.href = URL.createObjectURL(new Blob([response.data]));
      fileRef.current.download = 'headspace-pgp.asc';
      fileRef.current.click();
    }
  }, [getPgpKey]);

  return (
    <div data-testid="sftp-finished">
      <h2 css={titleStyles}>
        {translate('organizationSettingsSFTP.finished.title')}
      </h2>
      <div css={subtitleStyles}>
        {translate('organizationSettingsSFTP.finished.subtitle')}
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a ref={fileRef} css={visuallyHidden}>
        {translate('organizationSettingsSFTP.finished.tile2.body')}
      </a>
      <div css={tileWrapper}>
        <PanelTile
          actionLabel={translate(
            'organizationSettingsSFTP.finished.tile1.actionLabel',
          )}
          body={translate('organizationSettingsSFTP.finished.tile1.body')}
          title={translate('organizationSettingsSFTP.finished.tile1.title')}
          onClick={handleClickHelpCenter}
        />
        <PanelTile
          actionLabel={translate(
            'organizationSettingsSFTP.finished.tile2.actionLabel',
          )}
          body={translate('organizationSettingsSFTP.finished.tile2.body')}
          title={translate('organizationSettingsSFTP.finished.tile2.title')}
          onClick={handleDownloadPgpKey}
        />
        <PanelTile
          actionLabel={translate(
            'organizationSettingsSFTP.finished.tile3.actionLabel',
          )}
          body={translate('organizationSettingsSFTP.finished.tile3.body')}
          title={translate('organizationSettingsSFTP.finished.tile3.title')}
          onClick={handleUpload}
        />
      </div>
    </div>
  );
};
