import { getCookie } from '@headspace/web-auth';
import { captureException } from '@sentry/browser';
import { CookieKeys } from '../utils/constants';
import { CaseServiceIdNameMapping, CaseSubTypes, CaseTypes } from './types';
import { FORM_TYPE } from '../apps/headspaceHub/constants/eapServiceRequestConfig';

export const baseContractDateFormat = 'YYYY/MM/DD';
export const contractTimestampFormat = 'YYYY-MM-DDTHH:mm:ssZ';
export const GTMSessionId = 'clientId';

export const normalizeEmail = (email: string): string => {
  return email.trim().toLowerCase();
};

export const getGTMSession = (): string | undefined => {
  try {
    // taken from GTM session tag
    const sessionId = getCookie(CookieKeys.GTMSESSION);
    return sessionId ? sessionId.split('.').shift() : undefined;
  } catch (err) {
    captureException({
      err,
      hsName: 'pushToMParticle error',
    });
    return 'HS no session error';
  }
};

// anything not letters or numbers, replace with underscore
export const transformAnswerText = (text: string) =>
  text?.toLowerCase().replace(/[^a-z0-9]+/g, '_');

// get intake request case name
export const getCaseTypeName = (
  clientServiceId: number,
  serviceType: FORM_TYPE,
): { caseType: CaseTypes; caseSubType: CaseSubTypes } => {
  if (clientServiceId === 6 && serviceType === FORM_TYPE.IN_PERSON_THERAPY) {
    return {
      caseSubType: CaseSubTypes.InPersonTherapy,
      caseType: CaseTypes.Clinical,
    };
  }
  return {
    caseSubType: CaseServiceIdNameMapping[clientServiceId],
    caseType: CaseTypes.WorkLifeServices,
  };
};
