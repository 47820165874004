import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { rem } from '@headspace/web-ui-components';
import { useParams } from 'react-router-dom';
import { Input } from '../../../../../../shared-components/Input';
import { Panel } from '../../../../../../shared-components/Panel';
import { Container } from '../../../../../../shared-components/container/Container';
import env from '../../../../../../config/env';
import { SFTPSteps } from './SFTPSteps';
import { SFTP_STEPS } from '../../../../constants';
import {
  getSFTPProgress,
  removeSFTPProgress,
  setSFTPProgress,
} from '../../../../utils/sftp/sftpProgress';
import { SFTPCompletionModal } from './SFTPCompletionModal';
import {
  getActiveSFTPStep,
  getSFTPFinished,
  getSFTPUsers,
  getOrgManagement,
} from '../../../../../../state/selectors/organizationSelectors';
import { setSFTPFinished } from '../../../../../../state/actions/organizationActions/setSFTPFinished';
import { SFTPFinished } from './SFTPFinished';
import { setActiveSFTPStep } from '../../../../../../state/actions/organizationActions/setActiveSFTPStep';
import { fetchSFTPUsers } from '../../../../../../state/actions/organizationActions';
import { panelTwoColumnStyle } from '../styles';
import { useTranslate } from '../../../../../../hooks/useTranslate';

const panelStyle = css({
  padding: rem(1.875),
});

const host = env.ELIGIBILITY_FILE_HOST;

export const OrganizationSettingsSFTP: React.FC = () => {
  const { slug, orgId } = useSelector(getOrgManagement);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const sftpFinished = useSelector(getSFTPFinished);
  const activeStep = useSelector(getActiveSFTPStep);
  const sftpUsers = useSelector(getSFTPUsers);
  const steps = Object.keys(SFTP_STEPS);
  const stepProgress = getSFTPProgress(orgId);
  const params = useParams<{ id: string }>();

  const [showModal, setShowModal] = React.useState(false);
  const handleNextStep = React.useCallback(
    (override?: number) => {
      const newStep = override || activeStep + 1;
      dispatch(setActiveSFTPStep(newStep));

      if (newStep < steps.length + 1) {
        setSFTPProgress(orgId, newStep);
      } else {
        removeSFTPProgress(orgId);
        setShowModal(true);
      }
    },
    [activeStep, dispatch, orgId, steps.length],
  );
  const handleModalClose = React.useCallback(() => {
    setShowModal(false);
    dispatch(setSFTPFinished(true));
  }, [dispatch]);

  React.useEffect(() => {
    if (params?.id) {
      dispatch(fetchSFTPUsers(+params.id));
    }
  }, []);

  React.useEffect(() => {
    if (typeof stepProgress === 'number') {
      dispatch(setSFTPFinished(true));
      dispatch(setActiveSFTPStep(stepProgress));
    }
  }, [stepProgress, dispatch]);

  const showFinished = sftpFinished && stepProgress === null;
  const title = showFinished
    ? translate('organizationSettingsSFTP.title_finished')
    : translate('organizationSettingsSFTP.title');

  const displayUsername = sftpUsers.length > 0 ? sftpUsers[0].username : slug;

  return (
    <Container>
      <div>
        <Panel
          dataTestId="organization-settings-sftp"
          title={title}
          css={panelStyle}
        >
          <div css={panelTwoColumnStyle} data-id="123">
            <Input
              name="host_name"
              type="text"
              label={translate('organizationSettingsSFTP.host_name')}
              value={host}
              disabled={true}
            />
            <Input
              name="username"
              type="text"
              label={translate('organizationSettingsSFTP.username')}
              value={displayUsername}
              disabled={true}
            />
          </div>
          {showFinished ? (
            <SFTPFinished />
          ) : (
            <div data-testid="sftp-steps">
              <SFTPSteps
                host={host}
                username={displayUsername}
                activeStep={activeStep}
                handleNextStep={handleNextStep}
              />
            </div>
          )}
        </Panel>
        {showModal ? (
          <SFTPCompletionModal handleClose={handleModalClose} />
        ) : null}
      </div>
    </Container>
  );
};
