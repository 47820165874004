import { css } from '@emotion/react';
import {
  borderWidthXS,
  fontSize3XL,
  fontSizeM,
  fontSizeS,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  flex,
  justifyContentCenter,
} from '../../../../dashboard/styles/flex';
import { bold } from '../../../../dashboard/styles/fonts';

export const outerContainerStyle = css({
  '@media (max-width: 570px)': {
    border: 'none',
    margin: 0,
    padding: 0,
  },
  border: `${borderWidthXS} solid ${WarmGrey[200]}`,
  borderRadius: rem(1.25),
  color: WarmGrey[500],
  height: rem(7.5),
  marginBottom: rem(-1.875),
  marginLeft: SpacingInRemBySize['2XL'],
  marginRight: SpacingInRemBySize['2XL'],
  maxWidth: rem(28.875),
  padding: rem(1.25),
  textAlign: 'center',
});

export const pricingContainerStyle = css(flex, {
  '@media (max-width: 570px)': {
    justifyContent: 'space-between',
  },
  justifyContent: 'space-around',
});

export const priceStyle = css(bold, {
  fontSize: fontSize3XL,
});

export const subLabel = css({
  color: WarmGrey[500],
  fontSize: fontSizeS,
});

export const currencyStyle = css(
  bold,
  flex,
  alignItemsCenter,
  justifyContentCenter,
  {
    background: WarmGrey[200],
    borderRadius: rem(1.875),
    fontSize: fontSizeM,
    height: SpacingInRemBySize['3XL'],
    width: rem(7.625),
  },
);
