import { MemberEnrollActionTypes } from '../../actions/memberEnrollActions/memberEnrollActionTypes';
import { LANG_CODE_NO_SUBREGION } from '../../../views/shared-components/Header/TranslationTab/languages';
import { SupportedLanguageCode } from '../../../../../types/language';

const INITIAL_STATE = {
  language: LANG_CODE_NO_SUBREGION || SupportedLanguageCode.en,
};

export const languageReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case MemberEnrollActionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload.language };
    default:
      return state;
  }
};
