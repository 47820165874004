import { css } from '@emotion/react';
import {
  Blue,
  borderRadiusCircle,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  fontSizeS,
  Green,
  Grey,
  Red,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  Yellow,
} from '@headspace/web-ui-components';

const colors: { [key: string]: string } = {
  blue: Blue[100],
  green: Green[100],
  grey: WarmGrey[500],
  red: Red[200],
  yellow: Yellow[100],
};

const statusGroupStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

const statusStyle = (color: string) =>
  css({
    color: color === 'grey' ? WarmGrey[500] : WarmGrey[700],
    fontSize: fontSizeS,
    fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.normal,
  });

const statusColorStyle = (color: string) => {
  return css({
    backgroundColor: colors[color],
    borderRadius: borderRadiusCircle,
    height: SpacingInRemBySize.XS,
    marginRight: rem(0.625),
    width: SpacingInRemBySize.XS,
  });
};

export interface StatusTextProps {
  color: 'green' | 'red' | 'yellow' | 'grey' | 'blue';
  status: string;
}

export function StatusText(props: StatusTextProps): JSX.Element {
  const { color, status } = props;
  return (
    <div css={statusGroupStyle} data-testid="status-text-id">
      <div css={statusColorStyle(color)} />
      <div css={statusStyle(color)} data-testid="status-text">
        {status}
      </div>
    </div>
  );
}
