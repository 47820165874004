import { useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountVerifiedByEmail from './components/AccountVerifiedByEmail';
import AccountVerifiedById from './components/AccountVerifiedById';
import { getCheckAutoRenewStatus } from '../../rest-calls';
import {
  subscriptionDetailsSuccess,
  setEnrollmentVerificationConfirmation,
} from '../../state/actions/memberEnrollActions/memberEnrollActions';
import { verificationSuccessScreenViewAction } from '../../../../analytics/events/enrollmentEvents';
import { getUnifiedEligibilityRoutes } from '../../constants/routes';
import { RestrictBy } from '../../../../types/organization';
import { EnrollmentVerification } from '../../constants/enrollmentVerification';
import {
  getActiveOrg,
  getHasUnifiedOfferings,
  getIsGingerBundlingEnabled,
  getOrgRestrictedBy,
  getSlug,
} from '../../state/selectors/orgSectionSelector/orgSectionSelector';
import {
  getEmail,
  getHsUserId,
  getJWT,
  getPrivileges,
} from '../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';

const VerifiedAccount: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const activeOrg = useSelector(getActiveOrg);
  const privileges = useSelector(getPrivileges);
  const email = useSelector(getEmail);
  const userId = useSelector(getHsUserId);
  const JWT = useSelector(getJWT);
  const restrictedBy = useSelector(getOrgRestrictedBy);
  const searchString = location.search;
  const urlToken = searchString.split('=')[1];
  const urlParsedToken = decodeURIComponent(urlToken);
  const POTENTIAL_PAID_SUBSCRIBER =
    privileges && privileges.includes('SUBSCRIBER') && JWT;
  const isWorkId = [
    RestrictBy.EMPLOYEE_ID,
    RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
  ].includes(restrictedBy);

  const gingerBundlingEnabled = useSelector(getIsGingerBundlingEnabled);
  const hasUnifiedOfferings = useSelector(getHasUnifiedOfferings);
  const slug = useSelector(getSlug);
  const { V3_LOGIN_PATH } = getUnifiedEligibilityRoutes(slug);

  useEffect(() => {
    dispatch(verificationSuccessScreenViewAction());
  }, [dispatch]);

  useEffect(() => {
    // TODO: maybe refactor initialize verification ->  move to saga
    if (POTENTIAL_PAID_SUBSCRIBER) {
      getCheckAutoRenewStatus(JWT, userId)
        .then((res) => dispatch(subscriptionDetailsSuccess(res.data)))
        .catch((e) => {
          throw e;
        });
    }
  });

  useEffect(() => {
    if (
      urlParsedToken &&
      !isWorkId &&
      !gingerBundlingEnabled &&
      hasUnifiedOfferings
    ) {
      dispatch(
        setEnrollmentVerificationConfirmation({
          method: EnrollmentVerification.EMAIL,
          verificationToken: urlParsedToken,
        }),
      );
      navigate(V3_LOGIN_PATH);
    }
  }, [
    urlParsedToken,
    isWorkId,
    gingerBundlingEnabled,
    navigate,
    slug,
    dispatch,
    V3_LOGIN_PATH,
    hasUnifiedOfferings,
  ]);

  return (
    <div>
      {urlParsedToken && !isWorkId ? (
        <AccountVerifiedByEmail urlParsedToken={urlParsedToken} />
      ) : null}
      {isWorkId ? (
        <AccountVerifiedById activeOrg={activeOrg} email={email} />
      ) : null}
    </div>
  );
};

export default VerifiedAccount;
