import {
  Orange,
  SpacingInRemBySize,
  fontSizeXS,
  rem,
  fontSizeS,
  letterSpacingWider,
  CoolGrey,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import {
  alignItemsCenter,
  columnFlex,
  flex,
  justifyContentSpaceBetween,
} from '../../styles/flex';
import { bold } from '../../styles/fonts';

export const formContainerStyle = css(columnFlex, {
  flex: 1,
});

export const rowStyle = css(flex, justifyContentSpaceBetween, {
  '> div': {
    flexBasis: '48%',
  },
  width: '100%',
});

export const headerContainerStyle = css(
  flex,
  justifyContentSpaceBetween,
  alignItemsCenter,
  {
    width: '100%',
  },
);

export const subheaderStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeXS,
  marginBottom: SpacingInRemBySize.S,
});

export const reviewPageContainerStyle = css({
  marginBottom: rem(3.75),
  width: '100%',
});

export const reviewEditLabelStyle = css(bold, {
  ':hover': {
    color: Orange[200],
    cursor: 'pointer',
  },
  color: Orange[100],
  fontSize: fontSizeS,
  letterSpacing: letterSpacingWider,
  margin: 0,
  padding: 0,
  textTransform: 'uppercase',
});

export const createOrgContainerStyle = css(columnFlex, alignItemsCenter, {
  backgroundColor: White['000'],
  height: '100vh',
  width: '100%',
});

export const createOrgHeaderStyle = css(
  flex,
  justifyContentSpaceBetween,
  alignItemsCenter,
  {
    boxSizing: 'border-box',
    marginBottom: rem(1.875),
    padding: rem(2.625),
    width: '100%',
  },
);

export const viewBoxContainerStyle = css({
  marginBottom: SpacingInRemBySize.XL,
  width: rem(43.75),
});

export const textHeaderContainerStyle = css({
  color: WarmGrey[600],
  paddingBottom: rem(1.25),
  textAlign: 'center',
});
