import {
  OrganizationActionsTypes,
  CancelOrganizationCreateAction,
} from '../../types/organization';
import { router } from '../../../root-component/router';

const { CANCEL_ORGANIZATION_CREATE, HANDLE_UPDATE } = OrganizationActionsTypes;

export const cancelOrganizationCreate = (): CancelOrganizationCreateAction => {
  // TODO: CONFIRMATION MODAL
  // eslint-disable-next-line no-alert
  const confirm = window.confirm(
    'Are you sure you want to leave? Changes you made will not be saved.',
  );
  if (confirm) {
    router.navigate(-1);
    return {
      type: CANCEL_ORGANIZATION_CREATE,
    };
  }
  return { type: HANDLE_UPDATE };
};
