import { Red, Toast, rem } from '@headspace/web-ui-components';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';

import StatusWarning from 'assets/status-warning.svg';
import { ImportGingerChildOrgsModal } from './components/ImportGingerChildOrgsModal';
import { ImportChildExceptionModal } from './components/ImportChildExceptionModal';
import { ImportChildConfirmModal } from './components/ImportChildConfirmModal';
import { useTranslate } from '../../../../../../../../hooks/useTranslate';
import {
  getOrgManagement,
  getOrgMappingKey,
} from '../../../../../../../../state/selectors/organizationSelectors';
import { UpdatedChildOrg } from '../../../../../../hooks/useCreateChildOrgs';
import { slugSafe } from '../../../../../../../../utils/validations';
import { queryOrganization } from '../../../../../../../../state/actions/organizationActions/queryOrganization';
import { Organization } from '../../../../../../../../types/organization';

const toastIconStyle = css({ width: rem(2) });

export const ImportGingerChildOrgs = ({
  refetchImportChildOrgs,
  getOrganizationsDataRemoveFromCache,
  importChildOrgsDataRemoveFromCache,
  createChildOrgs,
  importChildOrgsIsError,
  importChildOrgsIsRefetchError,
  getOrganizationsIsError,
  getOrganizationsIsRefetchError,
  getOrganizationsData,
  importChildOrgsIsSuccess,
  importChildOrgsIsFetched,
  getOrganizationsIsSuccess,
  createChildOrgsIsError,
  importChildOrgsData,
  createChildOrgsIsLoading,
  scrollRef,
  isCSM,
}) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const [showImportModal, setShowImportModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmImportModal, setShowConfirmImportModal] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [parentOrgData, setParentOrgData] = useState<{
    children: Array<Organization>;
  }>();

  const {
    orgId,
    org_name,
    org_salesforce_id,
    org_type,
    sso_enabled,
    restrictBy,
    restrictedDomains,
    workIdFieldPlaceholderText,
  } = useSelector(getOrgManagement);

  const orgMappingKey = useSelector(getOrgMappingKey);

  const handleOnContinueCreateOrgs = useCallback(() => {
    const updatedChildOrgs = parentOrgData?.children.map(
      ({ name, country, id, orgMappingValue }): UpdatedChildOrg => {
        return {
          orgMappingKey,
          orgMappingValue,
          country,
          name,
          parentOrgName: org_name,
          rootOrgId: orgId,
          salesforceId: org_salesforce_id,
          type: org_type,
          ssoEnabled: sso_enabled,
          gingerId: `${id}`,
          enrollment: {
            slug: name.toLocaleLowerCase().replace(slugSafe, ''),
            restrictBy,
            restrictedDomains,
            workIdFieldPlaceholderText,
          },
        };
      },
    );

    createChildOrgs(
      {
        orgId,
        children: updatedChildOrgs ?? [],
      },
      {
        onSuccess: onCreateChildOrgSuccess,
      },
    );
  }, [setShowErrorModal, orgId, setShowConfirmImportModal, parentOrgData]);

  const handleShowImportModal = useCallback(
    (showModal) => setShowImportModal(showModal),
    [setShowImportModal],
  );

  const handleShowConfirmImportModal = useCallback(
    (showModal) => setShowConfirmImportModal(showModal),
    [setShowConfirmImportModal],
  );

  const handleShowErrorModal = useCallback(
    (showModal) => setShowErrorModal(showModal),
    [setShowErrorModal],
  );

  const handleShowErrorToast = useCallback(
    (showToast) => setShowErrorToast(showToast),
    [setShowErrorToast],
  );

  const handleShowSuccessToast = useCallback(
    (showToast) => setShowSuccessToast(showToast),
    [setShowSuccessToast],
  );

  const handleRefreshImportChildOrgs = useCallback(
    async (e) => {
      e.preventDefault();
      await refetchImportChildOrgs();
    },
    [refetchImportChildOrgs],
  );

  const handleOnContinueImportModal = useCallback(
    (updatedChildOrgs) => {
      const item = {
        ...parentOrgData,
        children: updatedChildOrgs,
      };
      setParentOrgData(item);
      handleShowConfirmImportModal(true);
    },
    [handleShowConfirmImportModal, parentOrgData, setParentOrgData],
  );

  const onCreateChildOrgSuccess = useCallback(() => {
    const TOAST_SUCCESS_TIMEOUT_SECONDS = 10 * 1000;

    handleShowImportModal(false);
    handleShowConfirmImportModal(false);
    handleShowErrorModal(false);
    setShowSuccessToast(true);
    dispatch(queryOrganization(orgId, isCSM));

    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    setTimeout(() => setShowSuccessToast(false), TOAST_SUCCESS_TIMEOUT_SECONDS);

    importChildOrgsDataRemoveFromCache();
    getOrganizationsDataRemoveFromCache();
  }, [
    dispatch,
    handleShowConfirmImportModal,
    handleShowImportModal,
    handleShowErrorModal,
    scrollRef,
    getOrganizationsDataRemoveFromCache,
    importChildOrgsDataRemoveFromCache,
  ]);

  useEffect(() => {
    if (createChildOrgsIsError) {
      handleShowConfirmImportModal(false);
      handleShowErrorModal(true);
    }
  }, [createChildOrgsIsError]);

  useEffect(() => {
    const TOAST_ERROR_TIMEOUT_SECONDS = 10 * 1000;
    const orgs = getOrganizationsData?.data?.orgs ?? [];

    if (
      getOrganizationsIsError ||
      getOrganizationsIsRefetchError ||
      orgs.length > 0
    ) {
      setShowErrorToast(true);
      setToastErrorMessage(
        translate(
          'organizationSettings.orgHierarchy.import.error.toast.alreadyExist',
        ),
      );
    } else if (importChildOrgsIsError || importChildOrgsIsRefetchError) {
      setShowErrorToast(true);
      setToastErrorMessage(
        translate(
          'organizationSettings.orgHierarchy.import.error.toast.notFound',
          {
            parentOrgName: org_name,
          },
        ),
      );
    }

    setTimeout(() => setShowErrorToast(false), TOAST_ERROR_TIMEOUT_SECONDS);
    importChildOrgsDataRemoveFromCache();
    getOrganizationsDataRemoveFromCache();
  }, [
    importChildOrgsIsError,
    importChildOrgsIsRefetchError,
    getOrganizationsIsError,
    getOrganizationsIsRefetchError,
    getOrganizationsData,
    org_name,
    translate,
    getOrganizationsDataRemoveFromCache,
    importChildOrgsDataRemoveFromCache,
  ]);

  useEffect(() => {
    const orgs = getOrganizationsData?.data?.orgs ?? [];

    if (
      importChildOrgsIsSuccess &&
      importChildOrgsIsFetched &&
      getOrganizationsIsSuccess &&
      orgs?.length === 0
    ) {
      handleShowImportModal(true);
    }
  }, [
    importChildOrgsIsSuccess,
    importChildOrgsIsFetched,
    getOrganizationsIsSuccess,
    getOrganizationsData,
  ]);

  useEffect(() => {
    if (importChildOrgsData) {
      setParentOrgData(importChildOrgsData);
    }
  }, [importChildOrgsData]);

  return (
    <>
      {showImportModal ? (
        <ImportGingerChildOrgsModal
          onCloseModal={() => handleShowImportModal(false)}
          onContinue={handleOnContinueImportModal}
          parentOrg={parentOrgData}
        />
      ) : null}

      {showErrorModal ? (
        <ImportChildExceptionModal
          onCloseModal={() => handleShowErrorModal(false)}
        />
      ) : null}

      {showConfirmImportModal ? (
        <ImportChildConfirmModal
          onCloseModal={() => handleShowConfirmImportModal(false)}
          onContinue={handleOnContinueCreateOrgs}
          onBack={() => handleShowImportModal(true)}
          isLoading={createChildOrgsIsLoading}
        />
      ) : null}

      {showErrorToast ? (
        <Toast
          backgroundColor={Red[300]}
          text={toastErrorMessage}
          handleClose={() => handleShowErrorToast(false)}
          closeButtonLabel="close"
          iconOverride={<img css={toastIconStyle} src={StatusWarning} alt="" />}
          dataTestIdPrefix="import-child-error"
        />
      ) : null}

      {showSuccessToast ? (
        <Toast
          text={translate('organizationSettings.orgHierarchy.import.success')}
          handleClose={() => handleShowSuccessToast(false)}
          closeButtonLabel="close"
          iconOverride={<img css={toastIconStyle} alt="" />}
          dataTestIdPrefix="import-child-success"
        />
      ) : null}
    </>
  );
};
