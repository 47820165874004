export enum BreakPoint {
  Mobile = '@media (max-width: 480px)',
  MobileWide = '@media (max-width: 650px)',
  Tablet = '@media (max-width: 1000px)',
}

export enum WebUIComponentsBreakPoint {
  Mobile = '@media (max-width: 767px)',
  Tablet = '@media (min-width: 768px) and (max-width: 1079px)',
  Desktop = '@media (min-width: 1080px)',
  DesktopAndTablet = '@media (min-width: 768px)',
  DesktopWide = '@media (min-width: 1700px)',
}
