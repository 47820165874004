import * as React from 'react';
import { css } from '@emotion/react';
import { Input } from '../../../shared-components/Input';
import { RecordEditControls } from './RecordEditControls';
import { OrganizationSetupAdministratorsEditProps } from '../types';
import { useTranslate } from '../../../hooks/useTranslate';
import {
  leftColumnPaddingStyle,
  rightColumnPaddingStyle,
  inputDisableStyle,
} from './styles';
import { flex } from '../styles/flex';

const locale = 'organizationSettings.administrators.';

export const flexBasis50Percent = css({
  '@media screen': { flexBasis: '50%' },
});

export const OrganizationAdministratorsEdit: React.FC<OrganizationSetupAdministratorsEditProps> = ({
  isEdit,
  handleChange,
  handleError,
  admin_name,
  admin_nameError,
  admin_title,
  admin_titleError,
  admin_email,
  admin_emailError,
  handleAdminDelete,
  handleAdminCancel,
  handleAdminSave,
  adminSaveDisabledStatus,
  adminDeleteDisabledStatus,
  adminCancelDisabledStatus,
  titleEnabled = true,
}) => {
  const { translate } = useTranslate();

  return (
    <div className="inputGroup">
      <div css={flex}>
        <div css={[flexBasis50Percent, leftColumnPaddingStyle]}>
          <Input
            type="text"
            name="admin_name"
            placeholder={translate(`${locale}namePlaceholder`)}
            label={translate(`${locale}nameLabel`)}
            onChange={handleChange}
            onBlur={handleError}
            value={admin_name}
            hasError={admin_nameError?.error}
            errorMessage={admin_nameError?.message}
            data-testid="organization-administrator-name-edit-input"
          />
        </div>
        <div css={[flexBasis50Percent, rightColumnPaddingStyle]}>
          {titleEnabled && (
            <Input
              type="text"
              name="admin_title"
              placeholder={translate(`${locale}titlePlaceholder`)}
              label={translate(`${locale}titleLabel`)}
              onChange={handleChange}
              onBlur={handleError}
              value={admin_title}
              hasError={admin_titleError && admin_titleError.error}
              errorMessage={admin_titleError ? admin_titleError.message : ''}
            />
          )}
        </div>
      </div>
      <div>
        <Input
          data-testid="organization-administrator-email-edit-input"
          type="email"
          name="admin_email"
          placeholder={translate(`${locale}emailPlaceholder`)}
          label={translate(`${locale}emailLabel`)}
          onChange={handleChange}
          onBlur={handleError}
          value={admin_email?.toLowerCase()}
          hasError={admin_emailError?.error}
          errorMessage={admin_emailError?.message}
          disabled={isEdit}
          css={inputDisableStyle}
        />
      </div>
      <div>
        <RecordEditControls
          data-testid="organization-administrator-edit-record-controls"
          handleDelete={handleAdminDelete}
          handleCancel={handleAdminCancel}
          handleSave={handleAdminSave}
          saveIsDisabled={adminSaveDisabledStatus}
          deleteIsDisabled={adminDeleteDisabledStatus}
          cancelIsDisabled={adminCancelDisabledStatus}
          saveLabel={`${locale}${isEdit ? 'saveAdmin' : 'addAdmin'}`}
        />
      </div>
    </div>
  );
};
