import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { Container } from '../../../../shared-components/container/Container';
import { handleDownloadChallengeReport } from '../../../../state/actions/challengesActions';
import { DownloadReportView } from '../../../../shared-components/ReportButton/ReportButton';
import { Toast } from '../../../../shared-components/Toast';
import { getChallengeCreated } from '../../../../state/selectors/challengesSelectors';
import { createChallengePanel } from './styles';
import { ChallengesList } from './components/ChallengesList';
import { isCSM } from '../../../../state/selectors/userSelectors';

export const ChallengesView = () => {
  const dispatch = useDispatch();
  const challengeCreated = useSelector(getChallengeCreated);
  const isUserCSM = useSelector(isCSM);
  const [
    isReportDownloadDisplayed,
    setReportDownloadDisplayed,
  ] = useState<boolean>(false);

  const handleReportDownload = useCallback(() => {
    dispatch(handleDownloadChallengeReport());
  }, [dispatch]);

  return (
    <Container>
      <div>
        <div>
          {isReportDownloadDisplayed && (
            <DownloadReportView
              css={createChallengePanel}
              handleDownload={handleReportDownload}
              messages={{
                label: 'challenges.downloadReport.label',
                message: 'challenges.downloadReport.message',
                title: 'challenges.downloadReport.title',
              }}
            />
          )}
          {isUserCSM && <ChallengesList />}
        </div>
      </div>
      {challengeCreated && <Toast text={challengeCreated} />}
    </Container>
  );
};
