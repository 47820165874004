import UAParser from 'ua-parser-js';
import { ANDROID_LINK, IOS_LINK } from '../../../constants';

export enum OS_TYPE {
  ANDRIOD = 'Android',
  IOS = 'iOS',
}

export enum DEVICE_TYPE {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}
export const getAppStoreLink = () => {
  const uaParser = new UAParser();
  const { name } = uaParser.getOS();
  if (name === OS_TYPE.ANDRIOD) {
    return ANDROID_LINK;
  }
  return IOS_LINK;
};

export const checkIfMobileOrTablet = (): boolean => {
  const uaParser = new UAParser();
  const { type } = uaParser.getDevice();
  const { name } = uaParser.getOS();

  return (
    (type === DEVICE_TYPE.MOBILE || type === DEVICE_TYPE.TABLET) &&
    (name === OS_TYPE.ANDRIOD || name === OS_TYPE.IOS)
  );
};

export const checkIfTablet = (): boolean => {
  const uaParser = new UAParser();
  const { type } = uaParser.getDevice();
  const { name } = uaParser.getOS();

  return (
    type === DEVICE_TYPE.TABLET &&
    (name === OS_TYPE.ANDRIOD || name === OS_TYPE.IOS)
  );
};
