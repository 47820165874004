import { css } from '@emotion/react';
import { ProgressBar } from './ProgressBar';
import { uniqueKey } from '../../utils/keyCounter';

export interface StepProgressProps {
  steps: number;
  currentStep: number;
  barColor?: string;
}

const stepProgressStyle = css({
  display: 'flex',
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

export function StepProgress(props: StepProgressProps): JSX.Element {
  const { steps, barColor, currentStep } = props;
  return (
    <ul className="step-progress" css={stepProgressStyle}>
      {/* create an array of empty strings to map over and create progress bars */}
      {Array(steps)
        .fill('')
        .map((e, index) => {
          const i = uniqueKey();
          return (
            <ProgressBar
              barColor={barColor}
              selected={currentStep > index}
              key={i}
            />
          );
        })}
    </ul>
  );
}
