import type { FC, KeyboardEvent, MouseEvent } from 'react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buildStepStatuses } from '../../../../../../../shared-components/Steps/utils';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import {
  nextStep,
  selectRestrictByMigration,
} from '../../../../../../../state/slices/restrictByMigration';
import { confirmRestrictByChange } from '../../../../../../../state/slices/restrictByMigration/confirmRestrictByChangeThunk';
import { RestrictBy } from '../../../../../../../types/organization';
import { RESTRICT_BY_MIGRATION_STEPS } from '../../../../../constants';
import { RestrictByMigrationBlockingModal } from './RestrictByMigrationBlockingModal';
import { RestrictByMigrationEmailDomainSteps } from './RestrictByMigrationEmailDomainSteps';
import { RestrictByMigrationErrorReportModal } from './RestrictByMigrationErrorReportModal';
import { RestrictByMigrationUploadFileSteps } from './RestrictByMigrationUploadFileSteps';

export const RestrictByMigrationSteps: FC = () => {
  const dispatch = useDispatch();
  const { restrictBy, orgId } = useSelector(getOrgManagement);
  const { activeStep, isLoading, elFileReport } = useSelector(
    selectRestrictByMigration,
  );
  const [isErrorReportModalOpen, setErrorReportModalOpen] = useState(false);

  const handleNextStep = useCallback(() => {
    dispatch(nextStep());
  }, [dispatch]);
  const openErrorReportModal = () => setErrorReportModalOpen(true);
  const closeErrorReportModal = () => setErrorReportModalOpen(false);
  const onConfirmButtonKeyDown = (
    event: KeyboardEvent<HTMLButtonElement>,
    options: Record<string, unknown> & { restrictedDomains?: string[] } = {},
  ) => {
    if (event.key === 'Enter') {
      dispatch(
        confirmRestrictByChange({
          options,
          orgId,
          restrictBy,
        }),
      );
    }
  };
  const onConfirmButtonClick = (
    _: MouseEvent<HTMLButtonElement>,
    options: Record<string, unknown> & { restrictedDomains?: string[] } = {},
  ) =>
    dispatch(
      confirmRestrictByChange({
        options,
        orgId,
        restrictBy,
      }),
    );

  const isEmailDomainFlow = restrictBy === RestrictBy.EMAIL_DOMAIN;
  const isUploadELFileFlow =
    restrictBy === RestrictBy.EMAIL_ELIGIBILITY_FILE ||
    restrictBy === RestrictBy.EMPLOYEE_ID;
  const steps = buildStepStatuses(RESTRICT_BY_MIGRATION_STEPS, activeStep);

  return (
    <>
      {isEmailDomainFlow && (
        <RestrictByMigrationEmailDomainSteps
          handleNextStep={handleNextStep}
          steps={steps}
          openErrorReportModal={openErrorReportModal}
          onConfirmButtonClick={onConfirmButtonClick}
          onConfirmButtonKeyDown={onConfirmButtonKeyDown}
        />
      )}
      {isUploadELFileFlow && (
        <RestrictByMigrationUploadFileSteps
          handleNextStep={handleNextStep}
          steps={steps}
          openErrorReportModal={openErrorReportModal}
          onConfirmButtonClick={onConfirmButtonClick}
          onConfirmButtonKeyDown={onConfirmButtonKeyDown}
        />
      )}
      <RestrictByMigrationBlockingModal isOpen={isLoading} />
      <RestrictByMigrationErrorReportModal
        elFileReport={elFileReport}
        isOpen={isErrorReportModalOpen}
        closeModal={closeErrorReportModal}
      />
    </>
  );
};
