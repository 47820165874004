import {
  cssWithMq,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import {
  alignItemsCenter,
  columnFlex,
  justifyContentCenter,
} from '../../../../dashboard/styles/flex';

export const containerStyle = cssWithMq(
  columnFlex,
  justifyContentCenter,
  alignItemsCenter,
  {
    color: WarmGrey[500],
    marginTop: [SpacingInRemBySize['4XL'], 0, 0],
    maxWidth: ['100vw', rem(46.875), rem(47.438)],
    minHeight: [rem(25), rem(31.25), rem(31.25)],
    width: rem(47.438),
  },
);
