import { AnyAction, Reducer } from 'redux';
import {
  SetSoleraConnectionErrorAction,
  SoleraActions,
  SoleraActionTypes,
} from '../../actions/soleraActions/soleraActionTypes';

interface SoleraState {
  error: {
    code: string;
  } | null;
  connectionInProgress: boolean;
}

const INITIAL_STATE: SoleraState = {
  error: null,
  connectionInProgress: false,
};

export const soleraReducer: Reducer = (
  state: SoleraState = INITIAL_STATE,
  action: SoleraActions,
) => {
  if (action.type === SoleraActionTypes.SET_SOLERA_CONNECTION_ERROR) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === SoleraActionTypes.SET_SOLERA_CONNECTION_STARTED) {
    return {
      ...state,
      connectionInProgress: true,
    };
  }

  if (action.type === SoleraActionTypes.SET_SOLERA_CONNECTION_FINISHED) {
    return {
      ...state,
      connectionInProgress: false,
    };
  }

  return state;
};
